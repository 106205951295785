import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadTitle, UploadText, ImportSubmitButton } from './ImportContentStyles';
import { ImportFinalList, RejectedItemList } from './FinalLists';
import { getClients } from 'api'
import { axiosClient } from 'store';
import { toast } from 'react-toastify';

export const FinalizeImport = ({data, importType, completeImport}) => {
  const [approvedData, setApprovedData] = useState([])
  const [rejectedData, setRejectedData] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const { clients } = useSelector(state => state.clients)

  useEffect(() => {
    const _filterData = () => {
      let approved = []
      let rejected = []
      data.forEach((item) => {
        if (!item.address || !item.locationType) {
          rejected.push(item)
        } else {
          approved.push(item)
        }
      })
      setApprovedData(approved)
      setRejectedData(rejected)
    }
    if (data.length > 0) {
      _filterData()
    }
  }, [data])

  useEffect(() => {
    if (approvedData.length > 0 && rejectedData.length === 0 && importType === 'location') {
      dispatch(getClients())
    }
  }, [approvedData, rejectedData, importType])

  const _updateRejectedItem = (item, index) => {
    const newRejectedData = [...rejectedData]
    newRejectedData.splice(index, 1)
    setRejectedData(newRejectedData)
    setApprovedData([...approvedData, item])
  }

  const _updateApprovedItem = (item, index) => {
    const newApprovedData = [...approvedData]
    newApprovedData[index] = item
    setApprovedData(newApprovedData)
  }

  const _onSubmit = async () => {
    let url = ''
    let body
    if (importType === 'client') {
      url = '/api/clients/import'
      body = { clients: approvedData }
    } else {
      url = '/api/locations/import'
      body = { locations: approvedData }
    }
    try {
      setSubmitting(true)
      await axiosClient.post(url, body)
      setSubmitting(false)
      completeImport()
    } catch (error) {
      console.log(error)
      toast.error('There was an error importing your data')
      setSubmitting(false)
    }
  }

  let renderedTitle = ''
  let renderedMessage = ''  
  if (rejectedData.length > 0) {
    renderedTitle = 'Some data was rejected'
    renderedMessage = 'Please review the following items and make any necessary changes'
  } else if (approvedData.length > 0) {
    if (importType === 'location') {
      renderedTitle = 'Your upload is complete!'
      renderedMessage = 'Please select clients for your locations'
    } else {
      renderedTitle = 'Your upload is complete!'
    }
  }

  const approvedNoClient = importType === 'location' && approvedData.filter(item => !item.client)

  const disableDoneButton = rejectedData.length > 0 || approvedData.length === 0 || approvedNoClient.length > 0 || submitting

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <UploadTitle>{renderedTitle}</UploadTitle>
      <UploadText>{renderedMessage}</UploadText>
      {rejectedData?.length > 0 ? (
        <RejectedItemList importType={importType} data={rejectedData} updateItem={_updateRejectedItem}  />
      ) : null}
      {rejectedData?.length === 0 && approvedData?.length > 0 ? (
        <ImportFinalList importType={importType} data={approvedData} updateItem={_updateApprovedItem} clients={clients} />
      ) : null}
      <ImportSubmitButton
        color="primary" 
        busy={submitting}
        disabled={disableDoneButton}
        onClick={() => {
          _onSubmit()
        }}
      >
        Done
      </ImportSubmitButton>
    </div>
  )
}