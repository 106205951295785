export const frequencyConversionsByType = {
  week: {
    default: 'monthly',
    weekly: 1,
    biweekly: 2,
    monthly: 4.33,
    quarterly: 13,
    yearly: 52
  },
  month: {
    default: 'monthly',
    weekly: 1 / 4.33,
    biweekly: 1 / 2.165,
    monthly: 1,
    quarterly: 3,
    yearly: 12
  },
  quarter: {
    default: 'quarterly',
    weekly: 1 / 13,
    biweekly: 1 / 6.5,
    monthly: 1 / 3,
    quarterly: 1,
    yearly: 4
  },
  year: {
    default: 'yearly',
    weekly: 1 / 52,
    biweekly: 1 / 26,
    monthly: 1 / 12,
    quarterly: 1 / 4,
    yearly: 1
  }
}
