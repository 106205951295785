import React, { useState, useRef } from 'react'
import { Modal, Button, Input } from 'reactstrap'
import styled from 'styled-components'
import SignaturePad from 'react-signature-canvas'
import { DatePicker } from 'react-widgets'
import moment from 'moment'
import { toast } from 'react-toastify'

import { axiosClient } from 'store'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
`

const SigContainer = styled.div`
  width: 100%;
  height: 150px;
  margin: 10px 0;
  border: 1px solid black;
  overflow: hidden;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`

const inputStyle = {
  width: '100%',
  margin: '0 0 10px 0',
}

const SignatureForm = ({ onSubmitSignature, toggle, proposalId, accessToken }) => {
  const [signatureData, setSignatureData] = useState({serviceStartDate: new Date()})
  const sigPad = useRef({})

  const clear = () => {
    sigPad.current.clear()
  }

  const _submit = async () => {
    const isEmpty = sigPad.current.isEmpty()
    const drawnSig = sigPad.current.getTrimmedCanvas()
    if (!signatureData?.printedName || !signatureData?.printedTitle || !signatureData?.serviceStartDate) {
      toast('Please fill out all fields.')
      return
    }
    if (isEmpty) {
      toast('Please sign the document.')
      return
    }
    try {
      drawnSig.toBlob(async (blob) => {
        const fd = new window.FormData()
        fd.append('file', blob, 'signature.png')    
        const url = accessToken ? `/api/proposals/${proposalId}/uploadSig/${accessToken}` : '/api/upload'
        const uploadRes = await axiosClient.post(url, fd, {
          timeout: 30000
        })

        const sigUrl = uploadRes.data[0].url
        
        onSubmitSignature({
          ...signatureData,
          dateSigned: new Date(),
          signatureUrl: sigUrl
        })
      })
    } catch (err) {
      toast('There was an error submitting signature.')
    }
  }
  
  return (
    <Modal isOpen={true}>
      <FormContainer>
        <InputLabel>Printed Name</InputLabel>
        <Input
          id="printedName"
          name="printedName"
          type="text"
          onChange={e => setSignatureData({ ...signatureData, printedName: e.target.value })}
          style={inputStyle}
        />
        <InputLabel>Printed Title</InputLabel>
        <Input
          id="printedTitle"
          name="printedTitle"
          type="text"
          onChange={e => setSignatureData({ ...signatureData, printedTitle: e.target.value })}
          style={inputStyle}
        />
        <InputLabel>Service Start Date</InputLabel>
        <DatePicker
          name="startDate"
          format={'LL'}
          value={signatureData?.serviceStartDate}
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault()
          }}
          onChange={value => {
            setSignatureData({
              ...signatureData,
              serviceStartDate: new Date(value)
            })
          }}
          style={inputStyle}
        />
        <SigContainer>
          <SignaturePad
            ref={sigPad}
            canvasProps={{
              style: {
                width:  '450px',
                height: '150px',
              }
            }}
            clearOnResize={false}
          />
        </SigContainer>
        <ButtonsContainer>
          <Button onClick={toggle}>Cancel</Button>
          <Button onClick={clear}>Clear Signature</Button>
          <Button onClick={_submit}>Submit</Button>
        </ButtonsContainer>
      </FormContainer>
    </Modal>
  )
}

export default SignatureForm