export const getDocumentTemplates = () => dispatch =>
  dispatch({
    type: 'GET_DOCUMENT_TEMPLATES',
    payload: {
      request: {
        url: `/api/library/documents`
      }
    }
  })

export const submitDocumentTemplate = template => {
  if (template._id) {
    return updateDocumentTemplate(template)
  } else {
    return createDocumentTemplate(template)
  }
}

export const createDocumentTemplate = template => dispatch =>
  dispatch({
    type: 'CREATE_DOCUMENT_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/documents`,
        method: 'post',
        data: template
      }
    }
  })

export const updateDocumentTemplate = template => dispatch =>
  dispatch({
    type: 'UPDATE_DOCUMENT_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/documents/${template._id}`,
        method: 'patch',
        data: template
      }
    }
  })

export const removeDocumentTemplate = templateId => dispatch =>
  dispatch({
    type: 'REMOVE_DOCUMENT_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/documents/${templateId}/delete`,
        method: 'patch'
      }
    }
  })
