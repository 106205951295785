export const required = value => (value ? undefined : 'This field is required.')
export const requiredWithMessage = message => value =>
  value ? undefined : message

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const phone = value =>
  value && !/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(value)
    ? 'Invalid phone number'
    : undefined

const validFilesRegex = /\.(jpg|jpeg|png|gif|svg|pdf|tiff)$/

export const image = files => {
  // console.log(files)
  if (!files || !files.length) {
    return undefined
  }

  if (files instanceof Array) {
    let errorFiles = []
    files.map(file => {
      // console.log(file)
      let errors
      if (!!file && typeof file === 'object' && file.name) {
        // console.log('it is an object', file)
        errors = !validFilesRegex.test(file.name.toLowerCase())
          ? errorFiles.push(file.name)
          : errorFiles
      } else if (!!file && typeof file === 'string') {
        // console.log('string', file)
        errors = !validFilesRegex.test(file.toLowerCase())
          ? errorFiles.push(file)
          : errorFiles
      } else {
        // console.log('something went wrong...')
        errors = errorFiles.push(`BROKEN: ${file}`)
      }

      return errors
    })

    return errorFiles && errorFiles.length ? errorFiles : undefined
  } else {
    if (files instanceof Object) {
      return files && !validFilesRegex.test(files.name.toLowerCase())
        ? `BROKEN: ${files}`
        : undefined
    } else {
      return files && !validFilesRegex.test(files.toLowerCase())
        ? `BROKEN: ${files}`
        : undefined
    }
  }
}

export const isBoolean = item =>
  typeof item === 'boolean' || item instanceof Boolean

export const isNumber = item =>
  typeof item === 'number' || item instanceof Number

export const isString = item =>
  typeof item === 'string' || item instanceof String

export const isFunction = item =>
  typeof item === 'function' || item instanceof Function

export const isArray = item => typeof item === 'object' && item instanceof Array

export const isObject = item =>
  typeof item === 'object' && item instanceof Object

export const isEmptyObject = item =>
  Object.values(item).every(values => values.length === 0)

export const passwordStrength = value =>
  value.length < 6
    ? 'Password must be at least 6 characters'
    : value.search(/[a-z]/i) < 0
    ? 'Password must contain at least one letter.'
    : value.search(/[0-9]/) < 0
    ? 'Password must contain at least one digit.'
    : undefined
