import React from 'react'
import { Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap'

const renderError = error =>
  error && <span className="small text-danger">{error}</span>
const renderWarning = warning =>
  warning && <span className="small text-warning">{warning}</span>

const InlineInputField = ({
  input,
  inputMaxWidth,
  label,
  type,
  children,
  placeholder,
  readOnly,
  prepend,
  append,
  control,
  rowStyle,
  meta: { touched, error, warning }
}) => {
  const element = control ? (
    React.createElement(control, {
      children,
      readOnly,
      ...input
    })
  ) : (
    <Input
      placeholder={placeholder}
      {...input}
      children={children}
      readOnly={readOnly}
      type={type}
      rows={String(type === 'textarea' && '4')}
    />
  )

  return (
    <Row style={{ marginTop: 30, marginBottom: 30, ...rowStyle }}>
      {label && (
        <Col>
          <div style={{ position: 'relative', top: 10 }}>{label}</div>
        </Col>
      )}
      <Col
        style={{ width: inputMaxWidth || 160, maxWidth: inputMaxWidth || 160 }}
      >
        <InputGroup>
          {prepend && (
            <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>
          )}
          {element}
          {append && (
            <InputGroupAddon addonType="append">{append}</InputGroupAddon>
          )}
        </InputGroup>
        {touched && (renderError(error) || renderWarning(warning))}
      </Col>
    </Row>
  )
}

export default InlineInputField
