import React, { Component } from 'react'
import DropdownList from 'react-widgets/lib/DropdownList'
import moment from 'moment-timezone'

import { TimezoneItem } from 'components/items'
import FieldSet from './FieldSet'

class TimezoneField extends Component {
  state = {
    isBlur: false
  }

  _onBlur() {
    this.setState({
      isBlur: true
    })
  }

  render() {
    let { name, input, type, label, meta } = this.props

    const timezones = moment.tz.names().map(name => ({
      name,
      text: `${name}: ${moment.tz(name).format('Z z')}`,
      zone: moment.tz(name).format('Z z')
    }))

    let value = ''
    if (input.value && input.value.length) {
      value = input.value
    } else {
      value = moment.tz.guess()
    }

    return (
      <FieldSet
        label={label}
        meta={meta}
        htmlFor={name}
        checkValidation={this.state.isBlur}
      >
        <DropdownList
          name={name}
          type={type}
          inputProps={input}
          data={timezones}
          itemComponent={TimezoneItem}
          valueField={'name'}
          textField={'text'}
          value={value}
          onChange={input.onChange}
          onBlur={() => this._onBlur()}
          {...this.props}
        />
      </FieldSet>
    )
  }
}

export default TimezoneField
