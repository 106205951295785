import { toast } from 'react-toastify'

export default function(response, altResponse) {
  if (!response) {
    return
  }
  const { error } = response
  console.log(' this is the error object ', error)
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    toast(error.response.data.message)
  } else if (
    error &&
    error.data &&
    typeof error.data === 'string'
  ) {
    toast(error.data)
  } else if (altResponse) {
    toast(altResponse)
  } else {
    console.log('@@@ Network error: ', error)
    toast('Unknown error.')
  }
}
