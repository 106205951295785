import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as actions from 'api'

class AcceptInvite extends Component {
  async componentDidMount() {
    if (this.props.profile) {
      return this.props.logout()
    }

    let companyId = this.props.location.pathname.split('/')[3]
    let employeeId = this.props.location.pathname.split('/')[5]

    const result = await this.props.acceptInvitation(companyId, employeeId)

    if (result.error && result.error.response && result.error.response.data) {
      alert(result.error.response.data.message)
    } else {
      await this.props.updateProfile()
      this.props.history.replace('/dashboard/')
    }
  }

  render() {
    let { profile, employment } = this.props
    let redirectAcceptedInvitation = null

    if (!profile) {
      redirectAcceptedInvitation = <Redirect to="/register" />
    }

    if (employment && !employment.archived) {
      redirectAcceptedInvitation = <Redirect to="/dashboard/jobs" />
    }
    return redirectAcceptedInvitation
  }
}

const mapStateToProps = (state, props) => ({
  profile: state.user && state.user.profile ? state.user.profile : null,
  employment: props.match.params.companyId
    ? state.user &&
      state.user.employment &&
      state.user.employment.find(
        e => e.company._id === props.match.params.companyId
      )
    : null
})

export default connect(
  mapStateToProps,
  actions
)(AcceptInvite)
