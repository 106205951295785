import React from 'react'
import styled from 'styled-components'
import { IoMdCloseCircle } from 'react-icons/io'
import { Button } from 'reactstrap'

const StyledButton = styled(Button)`
  &:hover svg {
    color: red !important;
  }
`

export default props => (
  <StyledButton close aria-label="Cancel" {...props}>
    <span aria-hidden>
      <IoMdCloseCircle id="delete-circle" size={22} style={{ color: '#999' }} />
    </span>
  </StyledButton>
)
