import React, { useEffect } from 'react'
const useZapier = () => {
  useEffect(() => {
    if (window && window.document) {
      const container = window.document.getElementById('zapier')
      const script = window.document.createElement('script')
      script.async = false
      script.charset = `utf-8`
      script.defer = false
      script.src = `https://zapier.com/apps/embed/widget.js?services=route-the-service-platform&html_id=zapier&limit=10`
      script.type = `text/javascript`
      container.appendChild(script)
    }
  }, [])
  return null
}
const Zapier = ({ style }) => {
  useZapier()
  return <div id={`zapier`} style={style}></div>
}
export default Zapier