import { createContext, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { store } from 'store';

// Helper function to get the user state from the Redux store
const getUserState = () => {
    const userStore = store.getState()?.user
    return userStore
}

// Create a context for Amplitude
const AmplitudeContext = createContext()

// AmplitudeProvider component responsible for initializing Amplitude instances and providing the context
const AmplitudeProvider = ({ children }) => {

    // Get the user state from the Redux store
    const userState = getUserState()

    // Extract auth0ID from the user state
    const auth0ID = !userState.employment ? '' : userState.employment[0].auth0_id

    // Return early if auth0ID is not available, preventing initialization
    if (auth0ID === '') return (<>{children}</>)

    // Generate a unique device ID based on the current timestamp
    const deviceID = useMemo(() => {
        return Date.now().toString()
    }, [auth0ID])

    // Function to initialize Amplitude instances
    const initAmplitude = useCallback(() => {

        // If there's no auth0ID, exit the initialization process
        if (!auth0ID) return;

        // Initialize the default Amplitude instance with session replay tracking
        const sesionReplayTrack = sessionReplayPlugin({
            sampleRate: 0.5 // Set sample rate to 50% for session replay
        })

        const defaultInstance = amplitude.createInstance()
        defaultInstance.setDeviceId(deviceID) // Set unique device ID
        defaultInstance.setUserId(auth0ID)    // Set user ID to auth0ID
        defaultInstance.add(sesionReplayTrack) // Add session replay plugin
        defaultInstance.init(process.env.REACT_APP_AMPLITUDE_API_KEY) // Initialize with API key from environment

        // Initialize the second Amplitude instance for autocapture
        const autocaptureInstance = amplitude.createInstance();
        
        // Log when autocapture instance is created
        console.log("Autocapture instance created");

        autocaptureInstance.init(process.env.REACT_APP_AMPLITUDE_AUTOCAPTURE_API_KEY, {
            autocapture: {
                elementInteractions: true,  // Enable autocapture for element interactions (clicks, changes)
            },
        });

        // Log after autocapture instance initialization
        console.log("Autocapture instance initialized with API Key:", process.env.REACT_APP_AMPLITUDE_AUTOCAPTURE_API_KEY);

        // Log when autocapture is enabled for element interactions
        console.log("Autocapture for element interactions enabled");

    }, [auth0ID, deviceID]) // Dependencies for useCallback to ensure it re-runs when auth0ID or deviceID changes

    // useEffect to initialize Amplitude when the component mounts
    useEffect(() => {
        initAmplitude(); // Call the initialization function

        // Cleanup function to remove the session replay tracking when component unmounts
        return () => {
            amplitude.remove('sessionReplayTracking');
        };

    }, [initAmplitude]) // Re-run the effect if initAmplitude changes

    // Provide the Amplitude context to children components
    return <AmplitudeContext.Provider value={{}}>{children}</AmplitudeContext.Provider>
}

export { AmplitudeProvider }
export default AmplitudeContext
