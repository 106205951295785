import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/browser'
import TagManager from 'react-gtm-module'

import { theme } from 'theme'

import appPackage from '../package.json'
import Root from './Root'
import App from './App'
import { unregister } from './registerServiceWorker'

import './styles/index.css'

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    release: 'roue-main-web@' + appPackage.version,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev'
  })
}

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
  }

  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <Root>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Root>,
  document.getElementById('root')
)
unregister()
