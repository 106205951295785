import React from 'react'

import AppStoreBadge from 'assets/img/download_on_the_app_store_badge.svg'
import GooglePlayBadge from 'assets/img/google_play_badge.png'

const AppLink = ({link, img, imgAlt, style, ...props}) => {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={link}
    >
      <img
        style={{ height: 64, ...style }}
        alt={imgAlt}
        src={img}
      />
    </a>
  )
}

export const GoogleDownloadLink = ({style}) => {
  return (
    <AppLink
      link={"https://play.google.com/store/apps/details?id=com.rozaroute.route&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"}
      img={GooglePlayBadge}
      imgAlt={"Get it on Google Play"}
      style={style}
    />
  )
}

export const AppleDownloadLink = ({style}) => {
  return (
    <AppLink
      link={"https://itunes.apple.com/us/app/route-the-service-platform/id1406939668?ls=1&mt=8"}
      img={AppStoreBadge}
      imgAlt={"Download on the App Store"}
      style={{padding: 10, ...style}}
    />
  )
}
            