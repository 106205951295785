import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import logo from 'assets/img/Pulse-Logo.png'
import { BodyTextAccount } from './BodyNoAccount';
import { browserHistory } from 'react-router';


const styleDivBody = {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    'row-gap': '2.5rem'

}

const styleImgBody = {
    width: '50%'
}

const handleClickButton = () => {
    window.location = `${process.env.REACT_APP_BILLING_BASE_URL}`
}

export const ModalVerify = ({ showModal, typeModal, handleToggle }) => {

    const [openModal, setOpenModal] = useState(showModal)

    console.log(typeModal)
    const toggleModal = () => {
        setOpenModal(!openModal)
        handleToggle()
    }


    return (
        <>
            <Modal isOpen={showModal} toggle={toggleModal} backdrop={true} keyboard={true} centered={true}>
                <ModalBody>
                    <div style={styleDivBody}>

                        <img src={logo} alt="Logo Pulse" style={styleImgBody} />

                        <BodyTextAccount typeTextModal={typeModal} handleClickButton={handleClickButton} />

                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
