import { createStore } from 'redux'
import { persistStore } from 'redux-persist'

import { axiosClient } from '../axios'
import persistReducers from '../persistor'
import middleware from '../middleware'

export default () => {
  const store = createStore(persistReducers, middleware(axiosClient))
  const persistor = persistStore(store)

  return { store, persistor, axiosClient }
}
