import { useState } from 'react'
import { Card, Button } from 'reactstrap'
import SearchBar from 'components/SearchBar'

export const EmploymentSelectCard = ({ employment, onSelect, inModal }) => {
  const [searchValue, setSearchValue] = useState('')

  const filteredEmployment = searchValue ? employment.filter(emp => {
    const name = emp?.company?.name
    return name.toLowerCase().includes(searchValue.toLowerCase())
  }) : employment

  const renderButtons = filteredEmployment.map(emp => (
    <Button
      block
      color="primary"
      key={emp.id}
      onClick={() => onSelect(emp)}
    >
      {emp?.company?.name || null}
    </Button>
  ))

  return (
    <Card
      body
      style={{
        height: 900,
        maxHeight: '80vh',
        maxWidth: inModal ? '100%' : '75%',
        padding: 40,
        margin: 0
      }}
    >
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h3 className="text-dark">{inModal ? 'Switch Company' : 'Choose your Team'}</h3>
        <SearchBar
          style={{ marginBottom: 20 }}
          background={'light'}
          onChange={val => setSearchValue(val)}
          value={searchValue}
        />
      </div>
      <div style={{ width: '100%', paddingTop: 30, overflowX: 'auto' }}>
        <div style={{ maxWidth: 320, height: '100%', columnWidth: 320 }}>
          {renderButtons}
        </div>
      </div>
    </Card>
  )
}