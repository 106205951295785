import React from 'react'
import { Input } from 'reactstrap'

import FieldSet from './FieldSet'

const InputField = ({
  input,
  label,
  type,
  children,
  placeholder,
  readOnly,
  meta
}) => (
  <FieldSet
    label={label}
    meta={meta}
    labelStyles={{ color: '#777' }}
    fieldSetStyles={{ flex: 4 }}
  >
    <Input
      placeholder={placeholder}
      {...input}
      readOnly={readOnly}
      type={type}
      rows={String(type === 'textarea' && '4')}
      style={{ resize: 'vertical' }}
    >
      {children}
    </Input>
  </FieldSet>
)

export default InputField
