import styled from 'styled-components'

export const ProgressBar = styled.ol`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-top: 2rem;
  padding: 3rem 1.5rem;
`

export const ProgressItem = styled.li`
  background-color: ${({ complete }) => (complete ? 'blue' : 'black')};
  border-radius: 50%;
  cursor: pointer;
  height: 1rem;
  margin-right: 1.5rem;
  padding: 0;
  width: 1rem;

  :last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 32rem) {
    margin-right: 2rem;
  }
`
