import React, { Component } from 'react'
import {
  Field,
  FieldArray,
  reduxForm,
  getFormValues,
  change,
  arrayPush
} from 'redux-form'
import { bindActionCreators } from 'redux'
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup
} from 'reactstrap'
import { FiMoreHorizontal } from 'react-icons/fi'

import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  CheckboxField,
  DropdownField,
  InlineInputField
} from 'components/fields'
import { required } from 'utils/validators'
import _ from 'lodash'
import DropdownList from 'react-widgets/lib/DropdownList'
import { connectConfirmation } from 'components/confirm'
import { toast } from 'react-toastify'

import chargeTypes from 'data/chargeTypes.json'
import floorTypeSubtotals from 'utils/floorTypeSubtotals'
import fixtureCounter from 'utils/fixtureCounter'
import TabularField from './TabularField'
import Workloader from './Workloader'
import InfoPopover from './InfoPopover'

export const serviceDefaultValues = {
  labor: {
    isAdvanced: false,
    recurringInterval: 'day'
  },
  materials: {
    recurringInterval: 'day'
  },
  billingMethod: 'hourly'
}

export const pullWalkthroughData = walkthrough => {
  if (!walkthrough) {
    return {}
  }

  let result = {
    billing: {
      perSqFt: {},
      perFixture: {}
    }
  }

  let floorTypeTotals = floorTypeSubtotals(walkthrough.areas)

  const advanced = Object.keys(floorTypeTotals.types).map(key => {
    const type = key
    const totalForThisType = floorTypeTotals.types[key]

    return {
      floorType: type,
      area: totalForThisType
    }
  })
  result.billing.perSqFt = {
    totalSqFt: floorTypeTotals.total,
    sheet: advanced
  }

  let fixtureTotals = fixtureCounter(walkthrough.areas)

  const advancedFixtures = Object.keys(fixtureTotals.types).map(key => {
    const type = key
    const totalFixtureType = fixtureTotals.types[key]

    return {
      name: type,
      qty: totalFixtureType
    }
  })

  result.billing.perFixture = {
    totalFixtureCount: fixtureTotals.total,
    sheet: advancedFixtures
  }

  return result
}

const SectionCard = styled(Card)`
  padding: 16px;

  .card {
    box-shadow: none;
    border: 1px solid #d3d3d3;
  }
`

const SectionTitle = styled.h4`
  font-weight: bold;
  color: ${props => props.theme.colors.clarity};
`

const InputField = ({ style, ...rest }) => (
  <InlineInputField
    rowStyle={{ marginTop: 0, marginBottom: 8, ...style }}
    {...rest}
  />
)

const SwitchField = props => <CheckboxField {...props} />


const CheckJobRepeat = props => {

  const { checked, label, input } = props

  return (<FormGroup
    check
    inline
  >
    <Input type="checkbox"
      checked={checked}
      onChange={input.onChange}
    />
    <Label check>
      {label}
    </Label>
  </FormGroup>)
}

const TinyInput = ({ input, ...rest }) => (
  <input
    className="form-control"
    maxLength={3}
    {...rest}
    {...input}
    style={{
      display: 'inline-block',
      width: 60,
      textAlign: 'right'
    }}
  />
)

const validNumber = val => (val ? parseInt(val) : null)
const validFloat = val => (val ? parseFloat(val) : null)

const TinySelect = ({ input, ...rest }) => (
  <select
    className="form-control"
    {...rest}
    {...input}
    style={{ display: 'inline-block', maxWidth: 120 }}
  />
)

class EstimatorForm extends Component {
  state = {
    showWorkloadModal: false,
    showServiceModal: false,
    checkJobRepeat: false
  }

  _openWorkloadModal = () => {
    this.setState({ showWorkloadModal: true })
  }

  _closeWorkloadModal = () => {
    this.setState({ showWorkloadModal: false })
  }

  addWorkloadingHours = (wlFinalEstimate, fieldName) => {
    if (!wlFinalEstimate || !fieldName) {
      return null
    }

    this.props.change('Estimator', fieldName, wlFinalEstimate)

    this._closeWorkloadModal()
  }

  handleChangeJob = () => {
    this.setState({ checkJobRepeat: !this.state.checkJobRepeat })
  }

  render() {
    const { serviceIndex, switchService } = this.props
    let activeService = serviceIndex || 0

    return (
      <>
        <form onSubmit={this.props.handleSubmit}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FieldArray
              name="services"
              component={this.renderServices}
              props={{ activeService, switchService }}
            />
            <div>{this.renderActiveService(activeService)}</div>
          </div>
        </form>
      </>
    )
  }

  renderServiceSelection = fields => {
    const { services } = this.props
    const { showServiceModal } = this.state
    const fieldName = `${fields.name}[${fields.length}].service`
    let selectedService = null

    return (
      <Modal
        isOpen={showServiceModal}
        toggle={() => this.setState({ showServiceModal: false })}
        className="small-modal"
      >
        <ModalHeader toggle={() => this.setState({ showServiceModal: false })}>
          Select a service
        </ModalHeader>
        <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
          <DropdownList
            name={fieldName}
            component={DropdownField}
            data={services}
            type="select"
            label={'Select a service'}
            textField="name"
            normalize={value => {
              if (!value) {
                return null
              }
              return value._id
            }}
            validate={[required]}
            warn={required}
            forceValidate
            onChange={value => {
              selectedService = value
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="danger"
            onClick={() => {
              this.setState({ showServiceModal: false })
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (selectedService) {
                const index = this.props.values.services.length
                this.props.arrayPush('Estimator', 'services', {
                  service: selectedService._id,
                  data: {
                    ...serviceDefaultValues,
                    billingMethod:
                      selectedService.chargeType ||
                      serviceDefaultValues.billingMethod,

                    ...pullWalkthroughData(this.props.walkthrough)
                  }
                })
                this.props.switchService(index)
                this.setState({ showServiceModal: false })
              } else {
                toast('You need to select a service.')
              }
            }}
          >
            Select
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderActiveService = index => {
    const field = `services[${index}].data`
    const values = _.get(this.props.values, field) || {}

    return (
      <>
        <SectionCard>
          <SectionTitle>
            Charge Method
            <InfoPopover section="chargeMethod" type="estimatorFaq" />
          </SectionTitle>

          <Field
            name={`${field}.billingMethod`}
            component={DropdownField}
            data={chargeTypes}
            textField="name"
            valueField="id"
            normalize={value => (value ? value.id : null)}
          />
        </SectionCard>

        <SectionCard>
          <SectionTitle>
            Job Frequency
            <InfoPopover section="jobFrequency" type="estimatorFaq" />
          </SectionTitle>
          {this.renderFrequencyArea(field, values)}
        </SectionCard>

        {this.renderLaborArea(field, values)}
        {this.renderMaterialsArea(field, values)}

        <SectionCard>
          <SectionTitle>
            Charges
            <InfoPopover section="charges" type="estimatorFaq" />
          </SectionTitle>
          {values &&
            values.billingMethod === 'hourly' &&
            this.renderHourlyBilling(field, values)}
          {values &&
            values.billingMethod === 'perSqFt' &&
            this.renderSqFtBilling(field, values)}
          {values &&
            values.billingMethod === 'perFixture' &&
            this.renderPerFixtureBilling(field, values)}
        </SectionCard>
      </>
    )
  }

  getServiceName = id => {
    const { services } = this.props
    if (!services || !services.length) {
      console.warn('Estimator form does not have the list of services.')
      return id
    }
    let service = services.find(service => id === service._id)
    if (service) {
      return service.name
    } else {
      return service
    }
  }

  renderServices = ({ fields, activeService, switchService }) => {
    return (
      <div style={{ marginRight: 24 }}>
        {this.renderServiceSelection(fields)}
        <Card>
          <CardBody>
            <SectionTitle>
              Services
              <InfoPopover section="services" type="estimatorFaq" />
            </SectionTitle>
            <Nav pills style={{ display: 'flex', flexDirection: 'column' }}>
              {fields.map((field, index) => {
                const values = this.props.values
                let serviceValues = {}
                if (values) {
                  serviceValues = _.get(values, field)
                }
                return (
                  <NavItem key={index} style={{ minWidth: 180 }}>
                    <NavLink
                      href=""
                      onClick={e => {
                        e.preventDefault()
                        switchService(index)
                      }}
                      active={index === activeService}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      {serviceValues && serviceValues.service
                        ? this.getServiceName(
                          serviceValues.service._id || serviceValues.service
                        )
                        : this.renderServiceSelection(field)}
                      <UncontrolledButtonDropdown direction='right'>
                        <DropdownToggle
                          block
                          style={{ textAlign: 'left', padding: 0, height: 'fit-content', marginLeft: 8 }}
                          color="link"
                        >
                          <FiMoreHorizontal color='white' style={{ verticalAlign: 'text-bottom' }} />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={async e => {
                              e.preventDefault()
                              e.stopPropagation()
                              const index = this.props.values.services.length
                              this.props.arrayPush('Estimator', 'services', serviceValues)
                              switchService(index)
                              this.setState({ showServiceModal: false })
                            }}
                          >
                            Clone Service
                          </DropdownItem>
                          <DropdownItem
                            onClick={e => {
                              this.props.confirm(
                                'Confirm Archive',
                                'Are you sure you want to archive this service estimate data?',
                                () => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  const needsReset = activeService === index
                                  fields.remove(index)

                                  if (needsReset) {
                                    this.props.change(
                                      'Estimator',
                                      'activeService',
                                      Math.max(activeService - 1, 0)
                                    )
                                  }
                                }
                              )
                            }}
                          >
                            Delete Service
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </NavLink>
                  </NavItem>
                )
              })}
              <Button
                style={{ marginTop: 20 }}
                type="button"
                onClick={() => this.setState({ showServiceModal: true })}
              >
                Add Service
              </Button>
            </Nav>
          </CardBody>
        </Card>
      </div>
    )
  }

  renderLaborArea = (field, values) => {
    const { walkthrough } = this.props
    const laborIsAdvanced = values && values.labor && values.labor.isAdvanced

    return (
      <SectionCard>
        <SectionTitle>
          Labor
          <InfoPopover section="labor" type="estimatorFaq" />
        </SectionTitle>

        <p style={{ marginTop: 16 }}>
          Man hours repeat every{' '}
          <Field
            name={`${field}.labor.recurringInterval`}
            component={TinySelect}
            validate={values && values.labor ? required : null}
          >
            <option value="day">day / shift</option>
            <option value="week">week</option>
            <option value="month">month</option>
            <option value="quarter">quarter</option>
            <option value="year">year</option>
          </Field>
        </p>
        <hr style={{ marginTop: 0 }} />
        <Field
          name={`${field}.labor.isAdvanced`}
          label="Advanced"
          component={CheckboxField}
        />
        {this.renderWorkloader(walkthrough, `${field}.labor.totalManHours`)}
        <Button
          color="link"
          style={{
            alignSelf: 'flex-end',
            paddingBottom: 0,
            paddingRight: 0
          }}
          onClick={() => {
            /*
            if (!walkthrough) {
              return toast(
                'You need an associated walkthrough to use the workloader!'
              )
            } else if (
              walkthrough &&
              (!walkthrough.areas || walkthrough.areas.length === 0)
            ) {
              return toast(
                'The associated walkthrough needs areas to use the workloader!'
              )
            }
            */
            this._openWorkloadModal()
          }}
        >
          Calculate
        </Button>
        <Field
          readOnly={laborIsAdvanced}
          name={`${field}.labor.totalManHours`}
          label="Total Man Hours"
          component={InputField}
          parse={validFloat}
          inputMaxWidth={220}
          type="number"
        />
        {!laborIsAdvanced && (
          <>
            <Field
              name={`${field}.labor.hourlyPay`}
              label="Hourly Pay"
              type="number"
              parse={validFloat}
              component={InputField}
              prepend="$"
              inputMaxWidth={220}
            />
            <FieldArray
              name={`${field}.labor.otherHourlyCosts`}
              component={TabularField}
              columns={[
                { name: 'name', placeholder: 'Name' },
                {
                  name: 'rate',
                  type: 'number',
                  placeholder: 'Hourly Rate',
                  width: 100,
                  props: {
                    prepend: '$',
                    parse: validFloat
                  }
                }
              ]}
              buttonLabel="+ Add Cost"
              title="Other Labor Costs"
            />
          </>
        )}
        {values && values.labor && values.labor.isAdvanced && (
          <FieldArray
            name={`${field}.labor.sheet`}
            component={TabularField}
            columns={[
              {
                name: 'description',
                placeholder: 'Description / Position',
                maxWidth: 400
              },
              {
                name: 'qty',
                type: 'number',
                placeholder: 'Qty.',
                maxWidth: 80
              },
              {
                name: 'hours',
                type: 'number',
                placeholder: 'Hours',
                maxWidth: 80
              },
              {
                name: 'hourlyPay',
                placeholder: 'Hourly Pay',
                type: 'number',
                maxWidth: 100
              }
            ]}
            buttonLabel="+ Job Role"
            title="Team Composition"
          />
        )}
      </SectionCard>
    )
  }

  renderMaterialsArea = (field, values) => {
    const isRecurring = values && values.frequency && values.frequency.repeats

    return (
      <SectionCard>
        <SectionTitle>
          Supplies, Materials & Equipment
          <InfoPopover section="suppliesMaterialsEquipment" type="estimatorFaq" />
        </SectionTitle>
        {isRecurring && (
          <FieldArray
            name={`${field}.materials.recurringCosts`}
            component={TabularField}
            columns={[
              { name: 'name', placeholder: 'Name' },
              {
                name: 'qty',
                type: 'number',
                placeholder: 'Qty.',
                width: 100,
                props: {
                  parse: validFloat
                }
              },
              {
                name: 'cost',
                type: 'number',
                placeholder: 'Cost',
                width: 160,
                props: {
                  parse: validFloat
                }
              }
            ]}
            buttonLabel="+ Recurring Costs"
            title="Recurring Costs"
            extra={
              <div style={{ marginTop: 16 }}>
                <p>
                  Recurring costs repeat every{' '}
                  <Field
                    name={`${field}.materials.recurringInterval`}
                    component={TinySelect}
                    validate={
                      values &&
                        values.materials &&
                        values.materials.recurringCosts &&
                        values.materials.recurringCosts.length > 0
                        ? required
                        : null
                    }
                  >
                    <option value={null} />
                    <option value="day">day / shift</option>
                    <option value="week">week</option>
                    <option value="month">month</option>
                    <option value="quarter">quarter</option>
                    <option value="year">year</option>
                  </Field>
                </p>
                <Field
                  name={`${field}.materials.billRecurringCosts`}
                  label="Recurring costs are billable"
                  component={SwitchField}
                />
                {values &&
                  values.materials &&
                  values.materials.billRecurringCosts && (
                    <>
                      <div>
                        Margin:{' '}
                        <Field
                          name={`${field}.materials.billRecurringMargin`}
                          format={val => (val ? val * 100.0 : null)}
                          parse={val => validFloat(val / 100)}
                          component={TinyInput}
                          type="number"
                        />{' '}
                        percent.
                      </div>
                    </>
                  )}
              </div>
            }
          />
        )}
        <FieldArray
          name={`${field}.materials.oneTimeCosts`}
          component={TabularField}
          columns={[
            { name: 'name', placeholder: 'Name' },
            {
              name: 'qty',
              type: 'number',
              placeholder: 'Qty.',
              width: 100,
              props: {
                parse: validFloat
              }
            },
            {
              name: 'cost',
              type: 'number',
              placeholder: 'Cost',
              width: 160,
              props: {
                parse: validFloat
              }
            }
          ]}
          buttonLabel="+ One Time Costs"
          title="One Time Costs"
          extra={
            <div style={{ marginTop: 16 }}>
              <Field
                name={`${field}.materials.billOneTimeCosts`}
                label="One time costs are billable"
                component={SwitchField}
              />
              {values && values.materials && values.materials.billOneTimeCosts && (
                <>
                  <div>
                    Margin:{' '}
                    <Field
                      name={`${field}.materials.billOneTimeMargin`}
                      format={val => (val ? val * 100.0 : null)}
                      parse={val => validFloat(val / 100)}
                      component={TinyInput}
                      type="number"
                    />{' '}
                    percent.
                  </div>

                  <div>
                    Spread one time costs over{' '}
                    <Field
                      name={`${field}.materials.oneTimeCostsMeta.spreadNumber`}
                      parse={validNumber}
                      component={TinyInput}
                      type="number"
                    />{' '}
                    payment(s).
                  </div>
                </>
              )}
            </div>
          }
        />
      </SectionCard>
    )
  }

  renderHourlyBilling = (field, values) => {
    return (
      <div>
        {values &&
          values.billing &&
          values.billing.perHour &&
          values.billing.perHour.marginBased ? (
          <Field
            name={`${field}.billing.perHour.laborMargin`}
            label="Percent Markup (Labor)"
            prepend="%"
            type="number"
            component={InputField}
            inputMaxWidth={220}
            validate={required}
            format={val => (val ? val * 100.0 : 0)}
            parse={val => validFloat(val / 100)}
          />
        ) : (
          <Field
            name={`${field}.billing.perHour.hourlyRate`}
            label="Charge per hour"
            type="number"
            prepend="$"
            component={InputField}
            inputMaxWidth={220}
            validate={required}
            parse={validFloat}
          />
        )}

        <Field
          name={`${field}.billing.perHour.marginBased`}
          label="Markup Based"
          component={SwitchField}
        />
      </div>
    )
  }

  renderSqFtBilling = (field, values) => {
    const isAdvanced =
      values &&
      values.billing &&
      values.billing.perSqFt &&
      values.billing.perSqFt.isAdvanced

    return (
      <div>
        <Field
          name={`${field}.billing.perSqFt.isAdvanced`}
          label="Advanced"
          component={CheckboxField}
        />
        {isAdvanced && (
          <FieldArray
            name={`${field}.billing.perSqFt.sheet`}
            component={TabularField}
            columns={[
              { name: 'floorType', placeholder: 'Floor Type' },
              {
                name: 'area',
                type: 'number',
                placeholder: 'Sq. Ft.',
                width: 100,
                props: {
                  parse: validNumber
                }
              },
              {
                name: 'price',
                type: 'number',
                placeholder: 'Price / Sq. Ft.',
                width: 100,
                props: {
                  parse: validFloat
                }
              }
            ]}
            buttonLabel="+ Add Floor Type"
            title="Floor Types"
          />
        )}
        {!isAdvanced && (
          <>
            <Field
              name={`${field}.billing.perSqFt.totalSqFt`}
              label="Total Square Footage"
              component={InputField}
              append="Sq. Ft."
              inputMaxWidth={220}
              type="number"
            />
            <Field
              name={`${field}.billing.perSqFt.rate`}
              label="Charge Per Sq. Ft."
              component={InlineInputField}
              type="number"
              parse={validFloat}
              prepend="$"
              rowStyle={{ marginBottom: 8 }}
            />
            <Field
              name={`${field}.billing.perSqFt.includeFrequency`}
              label="Include Frequency"
              component={CheckboxField}
            />
          </>
        )}
      </div>
    )
  }

  renderPerFixtureBilling = (field, values) => {
    const isAdvanced =
      values &&
      values.billing &&
      values.billing.perFixture &&
      values.billing.perFixture.isAdvanced
    return (
      <div>
        <Field
          name={`${field}.billing.perFixture.isAdvanced`}
          label="Advanced"
          component={CheckboxField}
        />
        {isAdvanced && (
          <FieldArray
            name={`${field}.billing.perFixture.sheet`}
            component={TabularField}
            columns={[
              { name: 'name', placeholder: 'Name' },
              {
                name: 'qty',
                type: 'number',
                placeholder: 'Qty.',
                width: 100,
                props: {
                  parse: validNumber
                }
              },
              {
                name: 'price',
                type: 'number',
                placeholder: 'Price',
                width: 100,
                props: {
                  parse: validFloat
                }
              }
            ]}
            buttonLabel="+ Add Fixture Type"
            title="Fixtures"
          />
        )}
        {!isAdvanced && (
          <>
            <Field
              name={`${field}.billing.perFixture.totalFixtureCount`}
              label="Total Fixture Count"
              component={InputField}
              inputMaxWidth={220}
              type="number"
            />
            <Field
              name={`${field}.billing.perFixture.rate`}
              label="Charge Per Fixture"
              component={InlineInputField}
              type="number"
              parse={validFloat}
              prepend="$"
            />
          </>
        )}
      </div>
    )
  }

  renderFrequencyArea = (field, values) => {
    return (
      <>
        {/* <Field
          name={`${field}.frequency.repeats`}
          label="Repeating Job"
          component={SwitchField}
          key={`${field}`}
        /> */}
        <Field
          name={`${field}.frequency.repeats`}
          onChange={this.handleChangeJob}
          label="Repeating Job"
          checked={this.state.checkJobRepeat}
          component={CheckJobRepeat}
        />
        <p>
          This job repeats{' '}
          <Field
            // disabled={values && values.frequency && !values.frequency.repeats}
            disabled={!this.state.checkJobRepeat}
            name={`${field}.frequency.number`}
            parse={validNumber}
            type="number"
            component={TinyInput}
            validate={
              values && values.frequency && values.frequency.repeats
                ? required
                : null
            }
          />{' '}
          times a{' '}
          <Field
            // disabled={values && values.frequency && !values.frequency.repeats}
            disabled={!this.state.checkJobRepeat}
            name={`${field}.frequency.interval`}
            component={TinySelect}
            validate={
              values && values.frequency && values.frequency.repeats
                ? required
                : null
            }
          >
            <option value={null} />
            <option value="week">week</option>
            <option value="month">month</option>
            <option value="quarter">quarter</option>
            <option value="year">year</option>
          </Field>
        </p>
      </>
    )
  }

  renderWorkloader = (walkthrough, fieldName) => {
    const { showWorkloadModal } = this.state

    /*
    if (!walkthrough) {
      return null
    }
    */

    return (
      <Modal
        isOpen={showWorkloadModal}
        toggle={this._closeWorkloadModal}
        className="big-modal"
        fade={false}
      >
        <>
          <ModalHeader toggle={this._closeWorkloadModal}>
            Workloading Calculator
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
            <Workloader
              walkthrough={walkthrough}
              onSubmit={wlFinal => this.addWorkloadingHours(wlFinal, fieldName)}
              onClose={this._closeWorkloadModal}
            />
          </ModalBody>
          <ModalFooter />
        </>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  values: getFormValues('Estimator')(state)
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ change, arrayPush }, dispatch)
})

export default reduxForm({ form: 'Estimator' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(connectConfirmation(EstimatorForm))
)
