import React from 'react'
import { Container } from 'reactstrap'

export default () => (
  <div style={{ backgroundColor: 'white', height: '100%', paddingTop: 200 }}>
    <Container style={{ height: '50vh' }}>
      <h1>Route: Privacy Policy</h1>
      <hr />
      <p>
        See our full privacy policy hosted at{' '}
        <a
          href="https://www.iubenda.com/privacy-policy/32463973"
          target="_blank"
          rel="noopener noreferrer"
        >
          this link.
        </a>
      </p>
      <p className="small text-muted" style={{ maxWidth: 450 }}>
        We use the Iubenda service to provide you with full transparency of our
        data practices; complete with an easy to read summary section.
      </p>
    </Container>
  </div>
)
