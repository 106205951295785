import React from 'react'
import { Button } from 'reactstrap'
import { withTheme } from 'styled-components'
import DeviceFamily from 'assets/img/device-family.svg'

const Page = ({ currentStep, numSteps, theme, onNext, amplitudeInstance }) => {
  amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Create Account Intro (Enter Page)')
  return (
  <div
    style={{
      backgroundColor: 'white',
      display: 'flex',
      width: '100%',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column'
    }}
  >
    <div style={{ marginBottom: 32 }}>
      <img
        src={DeviceFamily}
        style={{ height: 64 }}
        alt="responsive devices"
      />
    </div>
    <div style={{ marginBottom: 48 }}>
      <p style={{ textTransform: 'uppercase', marginBottom: 0 }}>
        Step <strong>{currentStep}</strong> of <strong>{numSteps}</strong>
      </p>
      <h1 style={{ fontSize: 24, marginBottom: 32 }}>Create your account.</h1>
      <div style={{ maxWidth: 320 }}>
        <p>
          Route accounts are for your whole company. Use your work email and
          create a password to use Route across web and mobile.
        </p>
      </div>
    </div>

    <Button
      color="primary"
      style={{
        textTransform: 'uppercase',
        borderRadius: 32,
        width: 300,
        paddingTop: 16,
        paddingBottom: 16
      }}
      onClick={onNext}
    >
      Continue
    </Button>
  </div>
)}

export default withTheme(Page)
