export const updateCompany = company => dispatch =>
  // console.log('action: ', company)
  dispatch({
    type: 'UPDATE_COMPANY',
    payload: {
      request: {
        url: `/api/companies/${company._id}`,
        method: 'patch',
        data: company
      }
    }
  })

export const getStripePlans = () => dispatch =>
  dispatch({
    type: 'FETCH_STRIPE_PLANS',
    payload: {
      request: {
        url: `/api/info/stripePlans`
      }
    }
  })

export const selectPlan = values => dispatch =>
  dispatch({
    type: 'SELECT_PLAN',
    payload: {
      request: {
        url: '/api/companies/billing',
        method: 'post',
        data: values
      }
    }
  })

export const transferOwnership = employeeId => dispatch =>
  dispatch({
    type: 'TRANSFER_OWNERSHIP',
    payload: {
      request: {
        url: `/api/companies/transfer-ownership`,
        method: 'post',
        data: {
          newOwnerId: employeeId
        }
      }
    }
  })

export const fetchSales = (startDate, endDate) => async dispatch =>
  dispatch({
    type: 'FETCH_SALES',
    payload: {
      request: {
        url: `/api/companies/dashboard2?${
          startDate ? 'startDate=' + startDate : ''
        }${endDate ? '&endDate=' + endDate : ''}`
      }
    }
  })

export const setSalesTarget = data => dispatch =>
  // console.log('action: ', company)
  dispatch({
    type: 'UPDATE_COMPANY',
    payload: {
      request: {
        url: '/api/companies/set-sales-target',
        method: 'patch',
        data
      }
    }
  })

  export const connectPulseAccount = data => dispatch =>
  // console.log('action: ', company)
  dispatch({
    type: 'UPDATE_COMPANY',
    payload: {
      request: {
        url: '/api/companies/connect-pulse-account',
        method: 'patch',
      }
    }
  })