import styled from 'styled-components'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'

import { pieChartColors } from '../util'

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 8px;
`

const LabelDot = styled.span`
  background-color: ${({color}) => color};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`

const Label = styled.p`
  color: ${({dark, theme}) => dark ? theme.dashboard.metric : theme.dashboard.text};
  font-size: 14px;
  margin: 0;
  padding: 0;
`
const LabelArray = ({ data, shortenTo }) => {
  const labelData = shortenTo ? data.slice(0, shortenTo) : data
  const labelArray = labelData?.map((entry, index) => (
    <Label key={`label-${index}`} dark={shortenTo}>
      <LabelDot color={pieChartColors[index % pieChartColors.length]}/>
      {entry.name}
    </Label>
  ))

  if (shortenTo && data.length > shortenTo) {
    const extras = data.length - shortenTo
    labelArray.push(<Label key={`label-${shortenTo}`} dark>+{extras} more</Label>)
  }
  return (
    <>
      {labelArray}
    </>
  )
}

export const PieLabelArray = ({ data, shortenTo, elementId }) => {
  return (
    <>
      <LabelRow id={elementId}>
        <LabelArray data={data} shortenTo={shortenTo} />
      </LabelRow>
      {data?.length > shortenTo ? (
        <UncontrolledPopover
          target={elementId}
          trigger="hover"
          placement="top"
          hideArrow
          //modifiers necessary to avoid a popover crash as of reactstrap 8.0.1
          modifiers={{ flip: { behavior: ['top'] } }}
        >
          <PopoverBody>
            <LabelArray data={data} />
          </PopoverBody>
        </UncontrolledPopover>
      ) : null}
    </>
  )
}
