import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import LazyImage from 'components/LazyImage'

const flickerAnimation = props => keyframes`
  0%   { border-color: #dddddd }
  50%  { border-color: ${props.theme.colors.clarity} }
  100% { border-color: #dddddd }
`

const Background = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.size ? props.size + 'px' : '32px')};
  height: ${props => (props.size ? props.size + 'px' : '32px')};
  border-radius: ${props => (props.size ? props.size / 2 + 'px' : '16px')};
  border: ${props =>
    props.border
      ? typeof props.border === 'string'
        ? props.border
        : '2px solid white'
      : 'none'};
  ${props =>
    props.border ? `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)` : null};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#3498db'};
  margin: 2px;
  vertical-align: middle;
  overflow: hidden !important;
  position: relative;
  padding: 0;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.1rem;
  ${props =>
    props.active
      ? css`
          animation: ${props => flickerAnimation} 1s ease-in-out 0s infinite;
          border: 2px solid black;
        `
      : null};
  ${props => (props.link ? 'cursor: pointer;' : null)}
`
const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#ecf0f1',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d'
]

export default ({
  id,
  url,
  name,
  active,
  border,
  children,
  size,
  link,
  ...rest
}) => {
  let initials = '',
    backgroundColor = '#DDD'
  if (!url && name) {
    const nameSplit = name.split(' ')
    if (nameSplit && nameSplit.length) {
      initials = nameSplit[0][0]
      if (nameSplit.length > 1) {
        initials += nameSplit[nameSplit.length - 1][0]
      }
    }

    initials.toUpperCase()

    if (initials.length) {
      // Randomize color based on initials
      const charIndex = initials.charCodeAt(0) - 65
      const colorIndex = charIndex % colors.length
      backgroundColor = colors[colorIndex]
    }
  }

  return (
    <Background
      active={active}
      size={size}
      border={border}
      link={link}
      backgroundColor={backgroundColor}
      id={id}
    >
      {url && url.length !== 0 ? (
        <LazyImage
          style={{
            width: '100%',
            height: '100%',
            margin: '0',
            padding: '0',
            borderRadius: size
          }}
          src={url}
          spinner={false}
          {...rest}
        />
      ) : initials ? (
        initials
      ) : null}
      {children}
    </Background>
  )
}
