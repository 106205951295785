import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import numeral from 'numeral'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import MotivationalMessage from './MotivationalMessage'
import StatsCard from './StatsCard'
import InfoPopover from 'screens/Dashboard/Estimates/Estimator/InfoPopover';

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
  height: calc(100% - 40px);
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-top: 4px;
  font-size: 40px;
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const MessageText = styled.p`
  color: ${props => props.theme.colors.light2};
  font-size: 20px;
  margin: 0;
  padding: 0;
  text-align: center;
`

const containerStyles = `
  grid-row-start: 1;
  grid-row-end: span 2;
  grid-column-start: 1;
  grid-column-end: span 2;

  @media (max-width: 700px) {
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column-start: 1;
    grid-column-end: span 1;
  }
`

const getDatesObject = (start, end) => {
  let datesObject = {}
  let dt = new Date(start)
  const endDate = new Date(end)

  while (dt <= endDate) {
    const formattedDate = moment(dt).format('L')
    datesObject[formattedDate] = {
      date: formattedDate
    }
    dt.setDate(dt.getDate() + 1)
  }

  return datesObject
}

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00')]

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
        {moment(payload.value).format('M/D/YY')}
      </text>
    </g>
  );
}

const formatYLabel = (value) => {

  const optionsFormatUSD = {
    notation: "compact",
    compactDisplay: "short",
    style: 'currency',
    currency: 'USD'
  }

  let formatValue = new Intl.NumberFormat('en-US', optionsFormatUSD).format(value)

  return formatValue
}

export const SalesTrend = ({ stats, startDate }) => {
  let formattedStartDate
  let datesObject = {}
  let finalData = []
  if (startDate) {
    formattedStartDate = moment(startDate).format('L')
  }
  if (formattedStartDate) {
    datesObject = getDatesObject(formattedStartDate, moment().format('L'))
  }

  if (datesObject && stats) {
    Object.values(stats).forEach(stat => {
      datesObject[stat.date] = stat
    })
  }
  finalData = Object.values(datesObject)

  return (
    <StatsCard
      title={'Sales Trend'}
      containerStyles={containerStyles}
      faqComponent={<InfoPopover section="salesTrend" type="dashboardFaq" key='salesTrend' />}
    >
      <StatsContainer>
        {finalData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={finalData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 35,
              }}
            >
              <YAxis dataKey='sales' type="number" tickFormatter={formatYLabel} />
              <XAxis dataKey='date' type="category" tick={<CustomizedAxisTick />} />
              <Tooltip
                label='date'
                labelFormatter={(value) => value}
                formatter={formatTooltipLabel}
              />
              <Line connectNulls dataKey="sales" stroke="#198754" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <MotivationalMessage message={`Keep on liftin' me higher and higher`} />
        )}
      </StatsContainer>
    </StatsCard>
  )
}
