import styled from 'styled-components'

export const FieldSet = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

export const FancyFieldSet = styled.fieldset`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 10px;
  padding: 15px 0 0;
  position: relative;
  width: 100%;

  .fancy-input {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #0a0a0a;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .fancy-label {
      font-size: 1.3rem;
      cursor: text;
      top: 20px;
    }

    /* reset input */
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }

  .fancy-label {
    position: absolute;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
  }

  fancy-input:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #0a5ad1, #38ef7d);
    border-image-slice: 1;

    ~ .fancy-label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 1rem;
      color: #0a5ad1;
      font-weight: 700;
    }
  }
`
