import { useState, useEffect } from 'react'

import { storage } from '../utils'

export const StorageState = (key, initialValue) => {
  const [state, setState] = useState(() => {
    let value

    try {
      value = JSON.parse(storage.getItem(key) || String(initialValue))
    } catch (error) {
      value = initialValue
    }

    return value
  })

  useEffect(value => storage.setItem(key, JSON.stringify(state)), [key, state])

  const reset = () => setState(initialValue)

  return [state, setState, reset]
}
