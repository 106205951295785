import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
import { Tooltip } from 'reactstrap'

import CompanyPin from 'assets/img/pin_company.svg'

const MapContainer = styled.div`
  width: calc(100% - 20px);
  margin-left: 10px;
  height: calc(100% - 20px);
`

const JobMarker = styled.span`
	position: absolute;
	padding: 0;
	margin: 0;
	width: ${props => props.width + 'px'}
	height: ${props => props.height + 'px'}
	top: ${props => -props.height + 'px'}
	left: ${props => -props.width / 2 + 'px'}
`

class MapDashboard extends Component {
  state = {
    center: {
      lat: 41.8781,
      lng: -87.6298
    },
    zoom: 11,
    hoverKey: null
  }

  onClickJob = job => {
    this.props.onOpen(job)
  }

  onHoverKeyChange = key => {
    this.setState({ hoverKey: key })
  }

  _onChildMouseEnter = (key /*, childProps */) => {
    this.onHoverKeyChange(key)
  }

  _onChildMouseLeave = (/* key, childProps */) => {
    this.onHoverKeyChange(null)
  }

  render() {
    const { jobs } = this.props
    if (!jobs) {
      return null
    }

    const renderedMarkers = (jobs || []).map(job => {
      if (
        !job.location ||
        !job.location.address ||
        !job.location.address.latitude ||
        !job.location.address.longitude
      ) {
        return null
      }
      return (
        <JobMarker
          key={job.id}
          onClick={() => this.onClickJob(job)}
          lat={job.location.address.latitude}
          lng={job.location.address.longitude}
          width={24}
          height={24}
          hover={this.state.hoverKey === job._id}
          id={'Tooltip-' + job._id}
        >
          <img src={CompanyPin} alt="client location marker" />
          <Tooltip
            placement="top"
            isOpen={this.state.hoverKey === job._id}
            target={'Tooltip-' + job._id}
            arrowClassName={'tooltip-arrow'}
          >
            <strong>{job.name}</strong>
            <span>
              {' '}
              -- {job.location.address && job.location.address.fullAddress}
            </span>
          </Tooltip>
        </JobMarker>
      )
    })

    return (
      <div style={{ height: '70vh' }}>
        <MapContainer>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
            hoverDistance={15}
            onChildMouseEnter={this._onChildMouseEnter}
            onChildMouseLeave={this._onChildMouseLeave}
          >
            {renderedMarkers}
          </GoogleMapReact>
        </MapContainer>
      </div>
    )
  }
}

export default MapDashboard
