import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import styled from 'styled-components'

import { DropdownField, ListInputField } from 'components/fields'
import { pluralize } from 'utils'

const TaskInputContainer = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 16px;
`

const InputAndFrequency = styled.div`
  display: flex;
  flex-direction: row;
`

const Tasks = ({
  fields,
  frequencies,
  handleKeyDown,
  getValues,
  isNewJob,
  setIsNewToFalse,
  serviceFrequency,
  areaTypeIndex
}) => {

  const addTask = () => {
    const values = getValues()

    const someEmptyTask = values.tasks.some(task => !task || !task.name)

    if (!someEmptyTask) {
      setIsNewToFalse()
      if (serviceFrequency) {
        fields.push({ frequency: serviceFrequency })
      } else {
        fields.push({})
      }
    }
  }

  const _handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      addTask()
    }
  }

  const _handleDeleteTask = index => {
    fields.remove(index)
  }

  const _handleAddTask = () => {
    addTask()
  }

  return (
    <TaskInputContainer>
      {fields.map((task, index) => (
        <InputAndFrequency key={index} id="taskContainer">
          <Field
            name={`${task}.name`}
            component={ListInputField}
            handleKeyDown={_handleKeyDown}
            onDeleteTask={() => _handleDeleteTask(index)}
            onAddTask={() => _handleAddTask()}
            type="text"
            isNewJob={isNewJob}
            placeholder="Press Enter to save"
            fieldsLength={fields.length}
          />
          {!!frequencies && (
            <div style={{ width: 150 }}>
              <Field
                name={`${task}.frequency`}
                component={DropdownField}
                data={frequencies}
                filter={false}
                type="select"
                isInsideTaskInput={true}
              />
            </div>
          )}
        </InputAndFrequency>
      ))}
    </TaskInputContainer>
  )
}


const AreaTypes = ({
  fields,
  frequencies,
  handleKeyDown,
  getValues,
  isNewJob,
  setIsNewToFalse,
  serviceFrequency,
  areaTypeCounts
}) => {
  return (
    fields.map((areaType, index) => {
      const type = Object.keys(areaTypeCounts)[index]
      const count = Object.values(areaTypeCounts)[index]
      const areaTypeName = (count > 1) ? pluralize(type) : type
      return (
        <div key={index}>
          <div style={{padding: 4}}><span>{areaTypeName}</span></div>
          <FieldArray
            name={`${areaType}.tasks`}
            component={Tasks} key={index}
            handleKeyDown={handleKeyDown}
            getValues={getValues}
            isNewJob={isNewJob}
            setIsNewToFalse={setIsNewToFalse}
            frequencies={frequencies}
            serviceFrequency={serviceFrequency}
            areaTypeIndex={index}
          />
        </div>
      )})
  )

}


const AreaTasksForm = ({
  areaTypeCounts,
  frequencies,
  handleKeyDown,
  getValues,
  isNewJob,
  serviceFrequency,
  setIsNewToFalse
}) => {

  return (
    <FieldArray
      name="areaTypes"
      component={AreaTypes}
      handleKeyDown={handleKeyDown}
      getValues={getValues}
      isNewJob={isNewJob}
      setIsNewToFalse={setIsNewToFalse}
      frequencies={frequencies}
      serviceFrequency={serviceFrequency}
      areaTypeCounts={areaTypeCounts}
    />
  )
}

export default reduxForm({
  form: 'AreaTasks'
})(AreaTasksForm)
