import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Navbar, NavbarBrand } from 'reactstrap'

import * as actions from 'api'
import { persistor } from 'store'

import Logo from 'assets/img/route-logo-full.png'

class Header extends Component {
  logout = async () => {
    await this.props.logout()

    await persistor.flush()
    await persistor.purge()

    window.location.href = `${process.env.REACT_APP_LOGIN_DOMAIN}/auth/logout`
  }

  render() {
    return (
      <>
        <Navbar fixed={'top'} dark color="dark" expand="md">
          <div className="container-fluid">
            <NavbarBrand>
              <img
                className={'logo'}
                src={Logo}
                alt={`Route Logo`}
                style={{ height: 20, marginTop: -6 }}
              />
            </NavbarBrand>

            <Button onClick={this.logout}>Sign Out</Button>
          </div>
        </Navbar>
      </>
    )
  }
}

const enhancedHeader = connect(null, actions)(Header)
export default withRouter(enhancedHeader)
