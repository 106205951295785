import React from 'react'
import { Route } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import PublicHeader from 'components/layout/PublicHeader'

import Login from './Login'
import LoginRedirect from './LoginRedirect'
import Signup from './Signup'

class Auth extends React.Component {
  render() {
    const renderedHeader = !isMobileOnly ? <PublicHeader /> : null
    return (
      <>
        <Route path="/login" render={routeProps => (
          <>
            {renderedHeader}
            <Login {...routeProps} />
          </>)} />
          <Route exact path="/login-redirect" render={() => (
            <>
              {!isMobileOnly ? <PublicHeader /> : null}
              <LoginRedirect />
            </>
          )} />
        <Route path="/signup" render={routeProps => (
          <>
            {renderedHeader}
            <Signup {...routeProps} />
          </>)} />
      </>
    )
  }
}
export default Auth
