export const getClients = () => dispatch =>
  dispatch({
    type: 'GET_CLIENTS',
    payload: {
      request: {
        url: `api/clients`,
        method: 'get'
      }
    }
  })

export const submitClient = client => {
  if (client._id) {
    return updateClient(client)
  } else {
    return newClient(client)
  }
}

export const newClient = client => dispatch =>
  dispatch({
    type: 'CREATE_CLIENT',
    payload: {
      request: {
        url: `/api/clients/new`,
        method: 'post',
        data: client
      }
    }
  })

export const createClient = client => dispatch =>
  dispatch({
    type: 'CREATE_CLIENT',
    payload: {
      request: {
        url: `/api/clients`,
        method: 'post',
        data: client
      }
    }
  })

export const updateClient = client => dispatch =>
  dispatch({
    type: 'UPDATE_CLIENT',
    payload: {
      request: {
        url: `/api/clients/${client._id}`,
        method: 'patch',
        data: client
      }
    }
  })

export const getClient = clientId => dispatch =>
  dispatch({
    type: 'GET_CLIENT',
    payload: {
      request: {
        url: `/api/clients/${clientId}`
      }
    }
  })

export const unArchiveClient = client => dispatch =>
  dispatch({
    type: 'UNARCHIVE_CLIENT',
    payload: {
      request: {
        url: `/api/clients/${client._id}/unarchive`,
        method: 'patch'
      }
    }
  })

export const cancelClient = clientId => dispatch =>
  dispatch({
    type: 'CANCEL_CLIENT',
    payload: {
      request: {
        url: `/api/clients/${clientId}/delete`,
        method: 'patch'
      }
    }
  })
