import React from 'react'

export default ({ color, pinColor, ...rest }) => (
  <svg
    width="37px"
    height="40px"
    viewBox="0 0 37 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <defs>
      <path
        d="M8.40513702,22.5787672 C3.55704856,21.2273104 0,16.7790518 0,11.5 C0,5.14872538 5.14872538,0 11.5,0 C17.8512746,0 23,5.14872538 23,11.5 C23,16.7790518 19.4429514,21.2273104 14.594863,22.5787672 L11.5,27 L8.40513702,22.5787672 Z"
        id="path-1"
      />
      <filter
        x="-54.3%"
        y="-31.5%"
        width="208.7%"
        height="192.6%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="3.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.371065444 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Pin/Job" transform="translate(7.000000, 3.000000)">
        <g id="pin_company">
          <g id="ic_pin" fillRule="nonzero">
            <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
            <use fill={color || 'black'} xlinkHref="#path-1" />
          </g>
          <path
            d="M12.1034483,12.9481154 L12.1034483,17.7391304 C12.1034483,17.8832047 11.9466532,18.261261 11.5172414,18.261261 C11.0878296,18.261261 10.9310345,17.8832047 10.9310345,17.7391304 L10.9310345,12.9540549 C9.26870992,12.6825136 8,11.2399751 8,9.500192 C8,7.56750233 9.56690064,6 11.4996161,6 C13.4323317,6 15,7.56750233 15,9.500192 C15,11.2276652 13.7485674,12.6620852 12.1034483,12.9481154 Z"
            id="Combined-Shape"
            fill={pinColor || 'white'}
          />
        </g>
      </g>
    </g>
  </svg>
)
