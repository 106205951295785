import * as Sentry from '@sentry/browser'

const sentryMiddleware = store => next => action => {
  const actionResult = next(action)
  const nextState = store.getState()

  switch (action.type) {
    case 'USER_LOGIN_SUCCESS':
    case 'USER_PROFILE_SUCCESS': {
      const team = nextState.user.currentEmployment
      if (!team) {
        return actionResult
      }

      Sentry.configureScope(function(scope) {
        scope.setUser({
          id: team._id,
          email: team.email,
          name: team.name ? team.name.full : null
        })
      })
      return actionResult
    }
    case 'USER_LOGOUT': {
      Sentry.configureScope(scope => scope.setUser(null))

      return actionResult
    }
    default: {
      return actionResult
    }
  }
}

export default sentryMiddleware
