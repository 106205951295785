import React from 'react'
import { LocationTypes } from 'data'
import Icon from 'components/icons'

export const LocationItem = ({ item }) => {
  if (item) {
    if (!item.icon && item.type) {
      let entry = LocationTypes.find(l => l.name === item.type)
      item.icon = entry ? entry.icon : null
    }

    return (
      <span>
        {item && item.icon && (
          <>
            <Icon icon={item.icon} />
            &nbsp;&nbsp;
          </>
        )}
        <strong>{item.name ? `${item.name}  ・  ` : null}</strong>
        {item.address && item.address.fullAddress}
      </span>
    )
  } else {
    return null
  }
}
