import React from 'react'

import {
  FieldSet,
  Input,
  Label,
  Legend,
  MaxLabel,
  MidLabel,
  MinLabel,
  Option,
  RadioGroup,
  RadioInput,
  RadioLabel,
  Select,
  Slider,
  SliderLabels
} from './ui'

const Field = ({
  children,
  id,
  label,
  labelStyle,
  max,
  maxColor,
  maxText,
  midColor,
  midText,
  min,
  minColor,
  minText,
  name,
  pattern,
  placeholder,
  onChange,
  options,
  required,
  step,
  style,
  type,
  value,
  ...rest
}) => {
  let field = null

  switch (type) {
    case 'email': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Input
            id={id}
            name={name}
            onChange={onChange}
            pattern={pattern}
            placeholder={placeholder}
            required={required}
            style={style}
            type={type}
            value={value}
            {...rest}
          />
        </>
      )

      break
    }
    case 'text': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Input
            id={id}
            name={name}
            onChange={onChange}
            pattern={pattern}
            placeholder={placeholder}
            required={required}
            step={step || 'any'}
            style={style}
            type={type}
            value={value}
            {...rest}
          />
        </>
      )

      break
    }
    case 'checkbox': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Input
            id={id}
            name={name}
            onChange={onChange}
            pattern={pattern}
            placeholder={placeholder}
            required={required}
            type={type}
            checked={value}
          />
        </>
      )

      break
    }
    case 'number': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Input
            id={id}
            name={name}
            onChange={onChange}
            pattern={pattern}
            placeholder={placeholder}
            required={required}
            step={step || 'any'}
            style={style}
            type={type}
            value={value}
            {...rest}
          />
        </>
      )

      break
    }
    case 'radio': {
      field = (
        <>
          <Legend style={labelStyle}>{label}</Legend>
          <RadioGroup>
            {options.map(option => (
              <RadioLabel htmlFor={option} key={option}>
                <RadioInput
                  id={option}
                  name={name}
                  onChange={onChange}
                  required={required}
                  style={style}
                  type={type}
                  checked={option === value}
                  value={option}
                />
                {option}
              </RadioLabel>
            ))}
          </RadioGroup>
        </>
      )

      break
    }
    case 'range': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <SliderLabels>
            {minText && <MinLabel color={minColor}>{minText}</MinLabel>}
            {midText && <MidLabel color={midColor}>{midText}</MidLabel>}
            {maxText && <MaxLabel color={maxColor}>{maxText}</MaxLabel>}
          </SliderLabels>
          <Slider
            id={id}
            list={'tickmarks'}
            max={max}
            min={min}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            step={step || 'any'}
            type={type}
            value={value}
          />

          {/* <datalist id={"tickmarks"}>
              <option label={0} value={0}></option>
              <option label={1} value={1}></option>
              <option label={2} value={2}></option>
              <option label={3} value={3}></option>
              <option label={4} value={4}></option>
              <option label={5} value={5}></option>
              <option label={6} value={6}></option>
              <option label={7} value={7}></option>
              <option label={8} value={8}></option>
              <option label={9} value={9}></option>
              <option label={10} value={10}></option>
          </datalist> */}
        </>
      )

      break
    }
    case 'select': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Select id={id} name={name} onChange={onChange} required={required} style={style} type={type} value={value}>
            <Option disabled value={``}>
              {placeholder || `Select type`}
            </Option>
            {options &&
              options.map(option => {
                if (option.name) {
                  return (
                    <Option key={option.name} value={option.name}>
                      {option.name}
                    </Option>
                  )
                } else {
                  return (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  )
                }
              })}
          </Select>
        </>
      )

      break
    }
    case 'switch': {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Input
            id={id}
            className={`switch`}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            style={style}
            type={type}
            checked={value}
            {...rest}
          />
        </>
      )

      break
    }
    default: {
      field = (
        <>
          <Label htmlFor={name} style={labelStyle}>
            {label}
          </Label>
          <Input
            id={id}
            name={name}
            onChange={onChange}
            pattern={pattern}
            placeholder={placeholder}
            required={required}
            step={step || 'any'}
            style={style}
            type={type}
            value={value}
            {...rest}
          />
        </>
      )

      break
    }
  }

  return (
    <FieldSet>
      {field}
      {children}
    </FieldSet>
  )
}

export default Field
