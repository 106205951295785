import React from 'react'
import Icon from './icons'
import { Card, CardHeader, CardBody, Button } from 'reactstrap'
import styled from 'styled-components'
import LazyImage from 'components/LazyImage'

const AttachmentLink = styled.a`
  cursor: pointer;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`

const FilePreview = ({ file, onDeleteClicked, ...rest }) => {
  if (!file || !file.type) {
    return null
  }

  const isImage = file.type.includes('image/')
  const isPDF = file.type.includes('/pdf')

  return (
    <Card {...rest} style={{ marginTop: 8, marginRight: 8, width: 160 }}>
      <CardHeader
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 112
        }}
      >
        {isImage && (
          <LazyImage
            file={file}
            className="img-fluid"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        )}
        {isPDF && <Icon size={64} icon="FaFilePdf" color="grey" />}
      </CardHeader>
      <CardBody style={{ padding: '4px 10px' }}>
        <AttachmentLink
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {file.name}
        </AttachmentLink>
      </CardBody>
      {onDeleteClicked && (
        <Button
          size="sm"
          color="link"
          onClick={() => onDeleteClicked(file)}
          style={{ marginLeft: 10, position: 'absolute', top: 0, right: 0 }}
        >
          <Icon icon="FaTimes" color="red" />
        </Button>
      )}
    </Card>
  )
}

export default ({ files, onDeleteClicked }) => {
  if (!files || !files.length) {
    return null
  }

  const renderedFiles = files.map((file, index) => (
    <FilePreview
      key={'file-' + index}
      file={file}
      onDeleteClicked={onDeleteClicked ? onDeleteClicked : null}
    />
  ))

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <h6>Attachments</h6>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {renderedFiles}
      </div>
    </div>
  )
}
