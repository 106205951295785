import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { getProposal } from 'api'
import { bindActionCreators } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'
import { connectConfirmation } from 'components/confirm'
import { getProposals } from 'store/slices/proposals'
import { ClapSpinner } from 'components/Loader/ClapSpinner'
import { useGetProposalQuery } from 'store/apis'


const ChooseProposalEditor = ({ match, history }) => {

  const { proposalId } = match.params
  const { data: proposal, isError, isLoading } = useGetProposalQuery(proposalId)

  if (proposal) {
    if (!proposal.version || proposal.version === 1) {
      return (
        <Redirect to={{
          pathname: `/dashboard/edit-legacy-proposal/${proposal._id}`,
          state: {
            proposal
          }
        }} />
      )
    } else {
      if (proposal?.signatureData?.clientSignature) {
        return <Redirect to={`/dashboard/completed-proposal/${proposal._id}`} />
      }
      return <Redirect to={`/dashboard/edit-new-proposal/${proposal._id}`} />
    }
  }

  return null
}

// const mapDispatchToProps = dispatch => ({
//   ...bindActionCreators(
//     {
//       getProposal
//     },
//     dispatch
//   )
// })

export default connectConfirmation(ChooseProposalEditor)
