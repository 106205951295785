import React, { useState, memo } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'
import { Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { capitalize } from 'utils'
import numeral from 'numeral';
import MotivationalMessage from './MotivationalMessage'
import { PieLabelArray } from './PieLabelArray'
import { pieChartColors, removeSmallData, renderCustomPieChartLabel, renderCustomPieChartLabelLine } from '../util'

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: calc(100% - 40px);
  padding: 0 0 10px 0;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
`

const containerStyles = `
  grid-row-start: 3;
  grid-row-end: span 2;
`

const locationTypes = ['cities', 'states', 'countries']

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00'), name]

export const SalesByLocation = memo(({ stats, merchantCity }) => {
  const [ locationType, setLocationType ] = useState('cities')

  let data = []
  if (stats && stats[locationType]) {
    data = Object.keys(stats[locationType]).map(key => {
      const value = stats[locationType][key] || 0
      return {name: key, value}
    })
    data = removeSmallData(data)
  }

  return (
    <StatsCard
      title={'Sales by Location'}
      containerStyles={containerStyles}
    >
      <StatsContainer>
        {data?.length ? (
          <>
            <DropdownContainer>
              <UncontrolledDropdown>
                <DropdownToggle style={{height: 20, fontSize: 12, padding: '0 10px'}} color='primary' caret>{capitalize(locationType)}</DropdownToggle>
                <DropdownMenu right={true}>
                  {locationTypes.map((type, index) => (
                    <DropdownItem key={`location-type-${index}`} onClick={() => setLocationType(type)}>{capitalize(type)}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </DropdownContainer>
            <ResponsiveContainer height="80%">
              <PieChart
                key={Math.random()}
              >
                <Pie
                  dataKey="value"
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={5}
                  outerRadius={80}
                  // fill="#8884d8"
                  label={renderCustomPieChartLabel}
                  labelLine={renderCustomPieChartLabelLine}
                  paddingAngle={0}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} stroke={pieChartColors[index % pieChartColors.length]} strokeWidth={1}/>
                  ))}
                </Pie>
                <Tooltip formatter={formatTooltipLabel} />
              </PieChart>
            </ResponsiveContainer>
            <PieLabelArray data={data} shortenTo={6} elementId={'sales-by-location-labels'} />
          </>
        ) : (
          <MotivationalMessage message={`Today ${merchantCity || 'your home town'}, tomorrow the world!`} />
        )}
      </StatsContainer>
    </StatsCard>
  )
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps.stats, nextProps.stats)
})