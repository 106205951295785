import _ from 'lodash'
import { handlerErrors } from 'utils'

const inititalState = {
  jobs: [],
  loading: false,
  error: null
}

const replaceJob = function(jobs, job) {
  if (!jobs || !jobs.length) {
    return []
  }

  let newList = jobs.slice(0)
  let index = _.findIndex(newList, {
    _id: job._id
  })
  if (index > -1) {
    newList[index] = job
  } else {
    newList.push(job)
  }
  return newList
}

const jobs = (state = inititalState, action) => {
  switch (action.type) {
    case 'FETCH_JOBS': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_JOBS_SUCCESS': {
      return {
        ...state,
        jobs: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'FETCH_JOBS_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting Jobs failed.')
      }
    case 'FETCH_ALL_JOBS': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_ALL_JOBS_SUCCESS': {
      return {
        ...state,
        jobs: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'FETCH_ALL_JOBS_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting Jobs failed.')
      }
    case 'FETCH_JOB_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting Job failed.')
      }
    }
    case 'UPDATE_JOB_SUCCESS': {
      return {
        ...state,
        jobs: replaceJob(state.jobs, action.payload.data),
        updatedJob: action.payload.data
      }
    }
    case 'ARCHIVE_JOB_SUCCESS':
      return {
        ...state,
        jobs: state.jobs.filter(job => job._id !== action.payload.data._id),
        loading: false,
        error: null
      }
    case 'UNARCHIVE_JOB_SUCCESS': {
      return {
        ...state,
        jobs: [...state.jobs, action.payload]
      }
    }
    case 'CANCEL_JOB_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Cancelling Job failed.')
      }
    default:
      return state
  }
}

export default jobs
