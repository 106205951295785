import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'

import animationData from 'assets/lottie/checked_done.json'

const CheckCard = styled.div`
  position: fixed;
  top: 50px;
  background: transparent;
  margin: 0 auto;
`

export default ({ complete }) => {
  const defaultOptions = {
    loop: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  let checkDone = (
    <CheckCard>
      <Lottie
        options={defaultOptions}
        height={500}
        width={500}
        eventListeners={[
          {
            eventName: 'complete',
            callback: complete
          }
        ]}
      />
    </CheckCard>
  )

  return checkDone
}
