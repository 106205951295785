import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import networkErrorToast from 'utils/networkErrorToast'
import {
  Button,
  ButtonGroup,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import styled from 'styled-components'
import DateWithTooltip from 'components/DateWithTooltip'
import {
  getInspection,
  createInspection,
  cloneInspection,
  cancelInspection,
  completeInspection,
  archiveInspection,
  startInspection,
  updateInspection,
  emailInspectionReport,
  updateLocation,
  getPlanControlInfo,
  getServiceTemplates
} from 'api'
import DataScreen from 'components/DataScreen'
import LockIcon from 'components/icons/LockIcon.js'
import { amplitudeInstance } from '../../../../store/middleware/analytics'
import LabelColumn from '../../components/LabelColumn'
import FilterForm from '../../components/forms/FilterForm'

import _ from 'lodash'

import { axiosClient } from 'store'

import {
  FaTrello,
  //  FaList,
  //  FaMap,
  FaCalendar,
  FaTable
} from 'react-icons/fa'

import InplaceForm from 'components/InplaceForm'
import StatusIcon from 'components/StatusIcon'

import JobDetailedView from '../../Jobs/components/JobDetailedView'
import CalendarView from '../../Jobs/components/CalendarView'
import KanbanView from '../../Jobs/components/KanbanView'
import ExportJobs from '../../Jobs/components/ExportJobs'

import ActionBar from '../../components/ActionBar'

import { ActionButtons } from 'components/lib/Button'

import * as acl from 'utils/acl.service'
import { fullName, jobStatus, fullAddress } from 'utils'


import { trackEvent } from 'api/clientHub'
import { 
  USER_INSPECTION_DOWNLOAD_SUCCESS,
  USER_INSPECTION_SUCCESS,
  INSPECTION_ARCHIVE_SUCCESS,
  USER_SCHEDULE_INSPECTION,
  INSPECTION_CLONE_SUCCESS
} from 'store/analyticsActions/types/inspectionsTypes'



const FileDownload = function (data, filename, mime) {
  var blob = new Blob([data], { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

const SpanLink = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

class InspectionList extends Component {
  state = {
    showModal: false,
    selectedInspection: null,
    viewMode: 'table'
  }

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  async componentDidMount() {
    await this.props.getServiceTemplates()
    await this.props.getPlanControlInfo('inspections.create')
    if (this.props.currentCompany) {
      const inspectionId = this.props.match.params.inspectionId
      if (inspectionId) {
        const selectedJob = await this.props.getInspection(inspectionId)
        if (selectedJob) {
          this._openInspectionModal(selectedJob)
        }
      }
    }
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.inspectionId
    ) {
      const res = await axiosClient.get(
        `/api/jobs/${this.props.match.params.inspectionId}`
      )
      if (res && res.data) {
        this.setState({
          showModal: true,
          selectedInspection: res.data
        })
      }
    }
  }

  _triggerForceFetch = () => {
    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  getInspections = async () => {
    const jobType = 'Inspection'
    let inspections = await this.props.getInspections(jobType)

    if (
      inspections.error &&
      inspections.error.response &&
      inspections.error.response.data
    ) {
      return networkErrorToast(inspections)
    } else {
      return inspections
    }
  }

  createInspection = () => {
    if (this.props.allowInspection.isAllowed) {
      trackEvent({
        typeEvent: USER_SCHEDULE_INSPECTION,
      })
      this.props.history.push('/dashboard/new-job/Inspection')
    } else {
      toast('Upgrade plan to create inspections.')
    }
  }

  editInspection = job => this.props.history.push(`/dashboard/jobs/${job._id}`)
  cancelInspection = async job => {
    let removed = await this.props.cancelInspection(job._id)

    if (removed.error) {
      return networkErrorToast(removed)
    } else {
      toast(`${job.name || job.code || 'Inspection'} was cancelled.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  archiveInspection = async job => {
    let archived = await this.props.archiveInspection(job._id)
    console.log('archving inspection')

    if (archived.error) {
      return networkErrorToast(archived)
    } else {
      toast(`${job.name || job.code || 'Inspection'} was archived.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
      trackEvent({
        typeEvent: INSPECTION_ARCHIVE_SUCCESS,
        params: {
          Job_ID: job._id
        }

      })
    }
  }

  completeInspection = async job => {
    let completed = await this.props.completeInspection(job._id)

    if (completed.error) {
      return networkErrorToast(completed)
    } else {

      trackEvent({
        typeEvent: USER_INSPECTION_SUCCESS,
        params: {
          Job_ID: job._id
        }
      })


      toast(`${job.name || job.code || 'Inspection'} was completed.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  startInspection = async job => {
    let started = await this.props.startInspection(job._id)

    if (started.error) {
      return networkErrorToast(started)
    } else {
      toast(`${job.name || job.code || 'Inspection'} was started.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  createService = job => {
    job._id = null
    job.type = 'Service'
    job.completedAt = null
    job.completedBy = null
    job.team = []

    this.props.history.push({
      pathname: '/dashboard/new-job/',
      state: { job }
    })
  }

  _cloneInspection = async includePictures => {
    const job = this.state.selectedInspection
    let cloned = await this.props.cloneInspection(job._id, includePictures)
    
    if (cloned.error) {
      return networkErrorToast(cloned)
    } else {
      toast(`${job.name || job.code || 'Inspection'} was cloned.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
      trackEvent({
        typeEvent: INSPECTION_CLONE_SUCCESS,
        params: {
          Job_ID: job._id
        }
      })
    }
  }

  _openInspectionModal = job => {
    this.setState({
      showModal: true,
      selectedInspection: job
    })
  }

  _toggleModal = () => {
    this.setState({
      showModal: false
    })
  }

  _toggleViewMode = viewMode => {
    this.setState({
      viewMode
    })
  }

  exportInspections = jobs => {
    this.setState({
      exportInspectionsComponent: <ExportJobs jobs={jobs} />
    })

    setTimeout(() => {
      this.setState({
        exportInspectionsComponent: null
      })
    }, 500)
  }

  renderInspections = columns => {
    // Avoid showing this message when jobs are being fetched from the server.

    switch (this.state.viewMode) {
      case 'table': {
        return (
          <DataScreen
            ref={this.dataScreen}
            entity="Inspection"
            url="/api/jobs/find?type=Inspection"
            onRowClick={job => this._openInspectionModal(job)}
            columns={columns}
            filterData={this.state.filterData}
          />
        )
      }
      case 'calendar': {
        return (
          <DataScreen
            ref={this.dataScreen}
            usePages={false}
            entity="Inspection"
            url="/api/jobs/find?type=Inspection&limit=1000"
            onRowClick={job => this._openInspectionModal(job)}
            columns={columns}
            component={
              <CalendarView onOpen={job => this._openInspectionModal(job)} />
            }
            filterData={this.state.filterData}
          />
        )
      }
      case 'kanban': {
        return (
          <DataScreen
            ref={this.dataScreen}
            usePages={false}
            entity="Inspection"
            url="/api/jobs/find?type=Inspection&limit=1000"
            onRowClick={job => this._openInspectionModal(job)}
            columns={columns}
            component={
              <KanbanView
                onOpen={job => this._openInspectionModal(job)}
                viewHeight="290px"
              />
            }
            filterData={this.state.filterData}
          />
        )
      }
      // case 'map': {
      //   return (
      //     <MapView
      //       currentCompany={this.props.currentCompany}
      //       onOpen={job => this._openInspectionModal(job)}
      //     />
      //   )
      // }
      // case 'cards': {
      //   return (
      //     <CardsView
      //       currentCompany={this.props.currentCompany}
      //       onOpen={job => this._openInspectionModal(job)}
      //       onClone={job => this.cloneInspection(job)}
      //       onEdit={job => this.editInspection(job)}
      //       onCancel={job => this.cancelInspection(job)}
      //       onRemove={job => this.archiveInspection(job)}
      //       onConvert={job => this.createService(job)}
      //     />
      //   )
      // }
      default: {
        return (
          <DataScreen
            ref={this.dataScreen}
            entity="Inspection"
            url="/api/jobs/find?type=Inspection"
            onRowClick={job => this._openInspectionModal(job)}
            columns={columns}
            filterData={this.state.filterData}
          />
        )
      }
    }
  }

  requestPDF = async job => {
    const fileName = `${this.props.currentCompany ? this.props.currentCompany.name + '-' : ''
      }Inspection${job && job.code ? '-' + job.code : ''}.pdf`
    await axiosClient
      .get(`api/jobs/${job._id}/pdf`, {
        responseType: 'blob'
      })
      .then(result => {
        FileDownload(result.data, fileName)
      })
  }

  requestInspectionReportPDF = async job => {
    const fileName = `${this.props.currentCompany ? this.props.currentCompany.name + '-' : ''
      }Inspection-Report${job && job.code ? '-' + job.code : ''}.pdf`
    await axiosClient
      .get(`api/jobs/${job._id}/ir-download`, {
        responseType: 'blob'
      })
      .then(result => {
        FileDownload(result.data, fileName)
      })

    trackEvent({
      typeEvent: USER_INSPECTION_DOWNLOAD_SUCCESS, params: {
        id: job._id
      }
    })
  }

  _emailReport = values => {
    const { selectedInspection } = this.state
    this.props.emailInspectionReport(selectedInspection, values)
  }

  updateInspectionName = async data => {
    if (!data || !data.jobName || !data.jobName.length) {
      return
    }

    await this.props.updateInspection({
      _id: this.state.selectedInspection._id,
      name: data.jobName
    })
    this.setState({
      selectedInspection: {
        ...this.state.selectedInspection,
        name: data.jobName
      }
    })
    this._triggerForceFetch()
  }

  updateLocationAreas = async job => {
    // safe check to ensure that the job is completed
    if (!job.completedAt || !job.areas || !job.areas.length) return

    let newAreas = job.areas.map(area => {
      let newArea = { ...area }

      delete newArea._id
      delete newArea.isAutoGenerated
      delete newArea.rating

      return newArea
    })
    let payload = {
      _id: job.location._id,
      areas: newAreas
    }

    let completed = await this.props.updateLocation(payload)

    if (completed.error) {
      return networkErrorToast(completed)
    } else {
      toast(
        `${job.name || job.code || 'Walkthrough'} areas were added to Location.`
      )
      this.setState({
        showModal: false
      })
    }
  }

  toggleMyJobFilter = () => {
    this.setState({ myJobsFilterOn: !this.state.myJobsFilterOn })
  }

  _showArchivedInspections = () => {
    this.props.history.push('/dashboard/inspections/archived/')
  }

  render() {
    let { profile, services } = this.props
    const { selectedInspection, showModal } = this.state

    const columns = [
      // {
      //   header: 'Labels',
      //   width: '5%',
      //   render: item => {
      //     const jobServices =
      //       item.categories &&
      //       item.categories.map(category =>
      //         _.find(services, {
      //           name: category
      //         })
      //       )

      //     return (
      //       <LabelColumn
      //         id={item._id}
      //         services={jobServices}
      //         labels={item.labels}
      //       />
      //     )
      //   }
      // },
      // {
      //   header: '',
      //   render: job => {
      //     return <StatusIcon status={jobStatus(job)} />
      //   }
      // },
      {
        header: 'Name',
        render: job => (job.name ? `${job.code} - ${job.name}` : `${job.code}`)
      },
      {
        header: 'Client',
        accessor: 'client.name',
        width: '15%'
      },

      {
        header: 'Location',
        render: job =>
          job.location &&
          job.location.address &&
          fullAddress(job.location.address)
      },

      {
        header: 'Score',
        render: job => {
          return job.inspectionPercent ? `${job.inspectionPercent}%` : null
        }
      },
      // {
      //   header: 'Area Totals',
      //   render: job => {
      //     let letsImproveAreas = 0
      //     let greatAreas = 0
      //     if (job.areas && job.areas.length > 0) {
      //       job.areas.forEach(area => {
      //         if (area.rating === 1) {
      //           letsImproveAreas++
      //         } else if (area.rating === 3) {
      //           greatAreas++
      //         }
      //       })
      //     }
      //     return letsImproveAreas || greatAreas
      //       ? `${letsImproveAreas} / ${greatAreas}`
      //       : null
      //   }
      // },
      {
        header: 'Assigned To',
        render: job => {
          const { team } = job
          if (team[0]) {
            if (team[0].name) {
              return fullName(team[0].name)
            }

            return ''
          } else {
            return null
          }
        }
      },
      {
        header: 'Date Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        }
      }
    ]

    const viewSwitcher = (
      <ButtonGroup style={{ marginRight: 8 }}>
        <Button
          color="dark"
          onClick={() => this._toggleViewMode('table')}
          active={this.state.viewMode === 'table'}
        >
          <FaTable />
        </Button>
        <Button
          color="dark"
          onClick={() => this._toggleViewMode('kanban')}
          active={this.state.viewMode === 'kanban'}
        >
          <FaTrello />
        </Button>
        {/* <Button
          color="dark"
          onClick={() => this._toggleViewMode('cards')}
          active={this.state.viewMode === 'cards'}
        >
          <FaList />
        </Button>

        <Button
          color="dark"
          onClick={() => this._toggleViewMode('map')}
          active={this.state.viewMode === 'map'}
        >
          <FaMap />
        </Button> */}
        <Button
          color="dark"
          onClick={() => this._toggleViewMode('calendar')}
          active={this.state.viewMode === 'calendar'}
        >
          <FaCalendar />
        </Button>
      </ButtonGroup>
    )

    const actionBar = (
      <ActionBar>
        <div />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FilterForm
            applyFilters={data =>
              this.setState({
                filterData: data
              })
            }
            locationFilters
            serviceFilters
          />
          <div>{viewSwitcher}</div>
          <ActionButtons
            buttons={[
              ...(acl.isAllowed('jobs', 'update')
                ? [
                  {
                    title: 'Schedule Inspection',
                    disabled: !profile,
                    onClick: this.createInspection,
                    icon: this.props.allowInspection.isAllowed ? null : (
                      <LockIcon color={'#FFF'} style={{ marginRight: 8 }} />
                    )
                  }
                ]
                : [])
            ]}
            options={[
              {
                title: 'See archived inspections',
                onClick: this._showArchivedInspections
              }
            ]}
          />
        </div>
      </ActionBar>
    )

    let renderedInspections = this.renderInspections(columns)

    const initialInspectionName =
      selectedInspection && selectedInspection.name
        ? selectedInspection.name.length
          ? selectedInspection.name
          : 'Untitled'
        : 'Inspection'

    return (
      <Container fluid style={{ marginTop: 20 }}>
        {selectedInspection && selectedInspection._id && (
          <Modal
            isOpen={showModal}
            toggle={this._toggleModal}
            className="big-modal"
            fade={false}
          >
            <>
              <ModalHeader toggle={this._toggleModal}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                  }}
                >
                  {' '}
                  <div style={{ paddingTop: 5 }}>
                    {selectedInspection.code
                      ? selectedInspection.code + ' - '
                      : null}{' '}
                  </div>
                  <InplaceForm
                    fieldName="jobName"
                    initialValues={{ jobName: initialInspectionName }}
                    header
                    onSubmit={this.updateInspectionName}
                  />
                </div>
                {selectedInspection.client && (
                  <>
                    <SpanLink
                      className="small"
                      onClick={() => {
                        this.props.history.push(
                          `/dashboard/client-details/${selectedInspection.client._id}`
                        )
                      }}
                    >
                      {selectedInspection.client.name}
                    </SpanLink>
                    <span className="small"> at </span>
                    <SpanLink
                      className="small"
                      onClick={() => {
                        this.props.history.push(
                          `/dashboard/location-details/${selectedInspection.location._id}`
                        )
                      }}
                    >
                      {selectedInspection.location.name ||
                        selectedInspection.location.address.fullAddress}
                    </SpanLink>
                  </>
                )}
              </ModalHeader>
              <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
                <JobDetailedView
                  jobId={selectedInspection && selectedInspection._id}
                  onStart={() => this.startInspection(selectedInspection)}
                  onOpen={() => this._openInspectionModal(selectedInspection)}
                  onClone={this._cloneInspection}
                  onEdit={() => this.editInspection(selectedInspection)}
                  onRemove={() => this.archiveInspection(selectedInspection)}
                  onCancel={() => this.cancelInspection(selectedInspection)}
                  onComplete={() => this.completeInspection(selectedInspection)}
                  onConvert={() => this.createService(selectedInspection)}
                  onPDF={() => this.requestPDF(selectedInspection)}
                  onDownloadReport={() =>
                    this.requestInspectionReportPDF(selectedInspection)
                  }
                  onEmailReport={this._emailReport}
                  showOptions={true}
                  onUpdateLocationAreas={this.updateLocationAreas}
                />
              </ModalBody>
              <ModalFooter />
            </>
          </Modal>
        )}

        {actionBar}
        <div style={{ marginBottom: 40 }} />
        {renderedInspections}
        {this.state.exportInspectionsComponent}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  currentEmployment: state.user.currentEmployment,
  currentCompany: state.user.currentCompany,
  profile: state.user.profile,
  error: state.inspections.error,
  allowInspection: state.pcl.inspections.create,
  services: state.library.services
})

export default connect(mapStateToProps, {
  // createReport,
  getInspection,
  emailInspectionReport,
  createInspection,
  cloneInspection,
  cancelInspection,
  completeInspection,
  startInspection,
  archiveInspection,
  updateInspection,
  updateLocation,
  getPlanControlInfo,
  getServiceTemplates
})(InspectionList)
