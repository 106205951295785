const convertIntervalValues = (value, interval, targetInterval) => {
  if (!interval) { return value }
  if (interval === targetInterval) { return value }
  if (interval === 'once') { return value }

  if (targetInterval === 'month') {
    if (interval === 'year') { return value / 12 }
    if (interval === 'week') { return value * 4 }
    if (interval === 'biweekly') { return value * 2 }
  }
  if (targetInterval === 'year') {
    if (interval === 'month') { return value * 12 }
    if (interval === 'week') { return value * 52 }
    if (interval === 'biweekly') { return value * 26 }
  }
}

export const calculateProposalTotals = (elements) => {
  let revenueTotals = {
    monthlyTotal: 0,
    yearlyTotal: 0,
    serviceTotals: {}
  }

  elements.forEach(element => {
    if (element.type === 'charges') {
      const { data } = element
      let { chargeSections } = data
      chargeSections.forEach(section => {
        let sectionTotal = 0
        section.charges.forEach(charge => {
          if (charge.price) {
            sectionTotal += parseFloat(charge.price)
          }
        })

        const monthlySectionTotal = convertIntervalValues(sectionTotal, section.interval, 'month')
        const yearlySectionTotal = convertIntervalValues(sectionTotal, section.interval, 'year')
        revenueTotals.monthlyTotal += monthlySectionTotal
        revenueTotals.yearlyTotal += yearlySectionTotal
        if (!revenueTotals.serviceTotals[section.name]) {
          revenueTotals.serviceTotals[section.name] = {
            monthlyTotal: monthlySectionTotal,
            yearlyTotal: yearlySectionTotal
          }
        } else {
          revenueTotals.serviceTotals[section.name].monthlyTotal += monthlySectionTotal
          revenueTotals.serviceTotals[section.name].yearlyTotal += yearlySectionTotal
        }
      })
    }
  })

  return revenueTotals
}