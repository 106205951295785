export const getAreaTemplates = () => dispatch =>
  dispatch({
    type: 'GET_AREA_TEMPLATES',
    payload: {
      request: {
        url: `/api/library/areas`
      }
    }
  })

export const submitAreaTemplate = template => {
  if (template._id) {
    return updateAreaTemplate(template)
  } else {
    return createAreaTemplate(template)
  }
}

export const createAreaTemplate = template => dispatch =>
  dispatch({
    type: 'CREATE_AREA_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/areas`,
        method: 'post',
        data: template
      }
    }
  })

export const updateAreaTemplate = template => dispatch =>
  dispatch({
    type: 'UPDATE_AREA_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/areas/${template._id}`,
        method: 'patch',
        data: template
      }
    }
  })

export const removeAreaTemplate = templateId => dispatch =>
  dispatch({
    type: 'REMOVE_AREA_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/areas/${templateId}/delete`,
        method: 'patch'
      }
    }
  })
