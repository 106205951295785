import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getServiceTemplates } from 'api'
import { capitalize } from 'utils'

import Boolean from './knobs/Boolean'
import Label from './knobs/Label'
import Text from './knobs/Text'
import Number from './knobs/Number'

import { Card, CardBody, Button, Input } from 'reactstrap'
import { DropdownList } from 'react-widgets'
import { CloseButton } from 'components/lib/Button'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'
import styled from 'styled-components'

const StyledDirectionButton = styled(Button)`
  &:hover svg {
    color: blue !important;
  }
`

let ListItem = ({ item }) => (
  <div style={{minHeight: 20}}>
    {item ? capitalize(item) : null}
  </div>
);


const DirectionButton = props => {
  return (
    <StyledDirectionButton close {...props}>
      <span aria-hidden>{props.children}</span>
    </StyledDirectionButton>
  )
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class Charges extends Component {
  state = {}

  async componentDidMount() {
    await this.props.getServiceTemplates()
  }

  _useEstimateResults = async () => {
    const { proposal, element, onChange } = this.props
    if (!proposal.estimate) {
      return
    }

    const isQuickBid = proposal.estimate.type && proposal.estimate.type === 'quickbid'
    const estimateServices =
      proposal && proposal.estimate && proposal.estimate.services
    let estimateResults = []

    if (isQuickBid) {
      const bid = proposal.estimate.bid
      const sTemplateName = 'Janitorial Cleaning'
    
      const createResult = ( index, name, frequency, price ) => {
        return {
          name: sTemplateName,
          id: `service${index}`,
          showService: true,
          interval: frequency,
          charges: [
            {
              name: name || 'Base Rate',
              price: price
            }
          ]
        }
      }
  
      if (bid.commercial) {
        const frequency = bid.frequency && bid.frequency.unit
        let result = createResult(0, null, frequency, bid.total)
        estimateResults.push(result)
      } else if (bid.residential) {
        estimateResults.push(createResult(0, 'One Time', 'once', bid.onetime))
        estimateResults.push(createResult(1, 'Weekly', 'week', bid.weekly))
        estimateResults.push(createResult(2, 'Biweekly', 'biweekly', bid.biweekly))
        estimateResults.push(createResult(3, 'Monthly', 'month', bid.monthly))
        estimateResults.push(createResult(3, 'Deep Clean', 'once', bid.deepclean))
        estimateResults.push(createResult(3, 'Move In/Out', 'once', bid.moveinout))
      }
  
    } else if (estimateServices && estimateServices.length) {
      estimateServices.forEach((service, index) => {
        if (service.result) {
          estimateResults.push({
            name: capitalize(service.service.name),
            id: `service${index}`,
            interval: service.result.jobCount.interval,
            charges: [
              {
                name: 'Base Rate',
                price: service.result.charges.baseRate
              },
              {
                name: 'Supplies and Materials',
                price: service.result.charges.suppliesAndMaterials
              }
            ]
          })
        }
      })
    }

    return onChange({
      ...element.data,
      chargeSections: estimateResults
    })
  }

  _renderCharges = (charges, sectionIndex) => {
    const { element, onChange } = this.props

    return (
      <table style={{ borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <th>Line Item</th>
            <th>Price</th>
            <th />
          </tr>
          {charges.map((charge, index) => (
            <tr key={`section-${sectionIndex}-charge-${index}`}>
              <td style={{ width: '65%', padding: '2px 4px 2px 0' }}>
                <Input
                  value={charge.name || ''}
                  onChange={event => {
                    let newData = {
                      ...element.data
                    }
                    let newCharges = charges
                    newCharges[index].name = event.target.value
                    newData.chargeSections[sectionIndex].charges = newCharges
                    return onChange(newData)
                  }}
                />
              </td>
              <td style={{ padding: '2px 4px 2px 0' }}>
                <Input
                  type="number"
                  value={charge.price || ''}
                  onChange={event => {
                    let newData = {
                      ...element.data
                    }
                    let newCharges = charges
                    newCharges[index].price = event.target.value
                    newData.chargeSections[sectionIndex].charges = newCharges
                    return onChange(newData)
                  }}
                />
              </td>
              <td>
                <CloseButton
                  onClick={() => {
                    let newData = { ...element.data }
                    let newSection = newData.chargeSections[sectionIndex]
                    let newCharges = [...newSection.charges]
                    newCharges.splice(index, 1)
                    newSection.charges = newCharges
                    newData.chargeSections[sectionIndex] = newSection
                    onChange(newData)
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  _renderServices = () => {
    const { element, services, onChange } = this.props

    if (
      !element.data ||
      !element.data.chargeSections ||
      !element.data.chargeSections.length
    ) {
      return null
    }

    return element.data.chargeSections.map((section, index) => (
      <Card key={`charge-section-${index}`}>
        <CardBody style={{ marginTop: 8 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '100%', marginRight: 8 }}>
              <Label text="Service" />
              <DropdownList
                data={services}
                textField="name"
                valueField="name"
                value={section.name}
                onChange={value => {
                  let newData = {
                    ...element.data
                  }
                  let newChargeSection = element.data.chargeSections[index]
                  newChargeSection.name = value.name || ''
                  newData.chargeSections[index] = newChargeSection
                  return onChange(newData)
                }}
              />
            </div>
            <div style={{ width: '60%' }}>
              <Label text="Charge Interval" />
              <DropdownList
                data={[null, 'week', 'biweekly', 'month', 'year', 'once']}
                itemComponent={ListItem}
                textField={value => (value ? capitalize(value): null)}
                value={section.interval}
                onChange={value => {
                  let newData = {
                    ...element.data
                  }
                  let newChargeSection = element.data.chargeSections[index]
                  newChargeSection.interval = value
                  newData.chargeSections[index] = newChargeSection
                  return onChange(newData)
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: 4 }}>
            {section.charges && section.charges.length > 0
              ? this._renderCharges(section.charges, index)
              : null}
            <Button
              style={{ marginTop: 8 }}
              size="sm"
              onClick={() => {
                let newData = { ...element.data }
                let newSection = section
                if (!newSection.charges || !newSection.charges.length) {
                  newSection.charges = []
                }
                newSection.charges.push({name: 'Line Item'})
                return onChange(newData)
              }}
            >
              Add Item
            </Button>
          </div>
          <Number
            min={0}
            max={200}
            field="spacer"
            label="Spacer after section"
            data={element.data.chargeSections[index]}
            onChange={data => {
              let newData = {
                ...element.data
              }
              newData.chargeSections[index] = data
              onChange(newData)
            }}
          />
        </CardBody>
        <div style={{ position: 'absolute', right: 2 }}>
          <CloseButton
            onClick={() => {
              let newSections = [...element.data.chargeSections]
              newSections.splice(index, 1)
              onChange({
                ...element.data,
                chargeSections: newSections
              })
            }}
          />
          {index < element.data.chargeSections.length - 1 && (
            <DirectionButton onClick={() => this._moveSectionDown(index)}>
              <FiArrowDown />
            </DirectionButton>
          )}
          {index > 0 && (
            <DirectionButton onClick={() => this._moveSectionUp(index)}>
              <FiArrowUp />
            </DirectionButton>
          )}
        </div>
      </Card>
    ))
  }

  renderActionDiv = () => {
    const { element, onChange, proposal } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          size="sm"
          onClick={() => {
            let newData = {
              ...element.data
            }
            let newChargeSections = newData.chargeSections || []
            newChargeSections.push({
              id: `service${newChargeSections.length}`,
              charges: [
                {
                  name: 'Base Rate'
                },
                {
                  name: 'Supplies and Materials'
                }
              ]
            })
            newData.chargeSections = newChargeSections
            return onChange(newData)
          }}
        >
          Add Service
        </Button>
        <div
          style={{
            display: 'flex'
          }}
        >
          <Button
            size="sm"
            style={{ marginRight: 4 }}
            onClick={() => {
              return onChange({
                ...element.data,
                chargeSections: []
              })
            }}
          >
            Clear
          </Button>
          {proposal && proposal.estimate && (
            <Button size="sm" onClick={() => this._useEstimateResults()}>
              Use Estimate
            </Button>
          )}
        </div>
      </div>
    )
  }

  _moveSectionUp = oldIndex => {
    const { element, onChange } = this.props
    const sections = element && element.data && element.data.chargeSections
    const newSections = reorder(sections, oldIndex, oldIndex - 1)

    return onChange({
      ...element.data,
      chargeSections: newSections
    })
  }

  _moveSectionDown = oldIndex => {
    const { element, onChange } = this.props
    const sections = element && element.data && element.data.chargeSections
    const newSections = reorder(sections, oldIndex, oldIndex + 1)

    return onChange({
      ...element.data,
      chargeSections: newSections
    })
  }

  render() {
    const { element, onChange } = this.props

    return (
      <>
        <Text
          placeholder="Element Name"
          field="name"
          data={element.data}
          onChange={onChange}
        />
        <Boolean
          label="Show Heading"
          data={element.data}
          field="showHeading"
          onChange={onChange}
        />
        {element.data && element.data.showHeading && (
          <Text field="headingText" data={element.data} onChange={onChange} />
        )}
        <Number
          min={6}
          max={20}
          field="textSize"
          label="Text size"
          data={element.data}
          onChange={onChange}
        />
        <Boolean
          label="Show Totals"
          data={element.data}
          field="showTotals"
          onChange={onChange}
        />
        <div style={{ marginBottom: 8 }}>{this.renderActionDiv()}</div>
        {element && element.data && element.data.chargeSections ? (
          <>
            {this._renderServices()}
            {element.data.chargeSections.length > 2 && this.renderActionDiv()}
          </>
        ) : null}
      </>
    )
  }
}

export default connect(
  state => ({
    services: state.library.services
  }),
  {
    getServiceTemplates
  }
)(Charges)
