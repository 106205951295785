import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  font-size: 1.5rem;
  position: relative;
  text-align: center;

  @media screen and (min-width: 32rem) {
    font-size: 2rem;
  }
`

export const Legend = styled.legend`
  display: block;
  font-size: 1.5rem;
  position: relative;
  text-align: center;

  @media screen and (min-width: 32rem) {
    font-size: 2rem;
  }
`
