import React from 'react'
import { withTheme } from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TagManager from 'react-gtm-module'
import { isMobileOnly } from 'react-device-detect'

import TrialClarification from './screens/TrialClarification'
import Plans from './screens/Plans'
import AccountClarification from './screens/AccountClarification'
import CompanyForm from './screens/CompanyForm'
import ServicesForm from './screens/ServicesForm'
import PaymentForm from './screens/PaymentForm'
import { Modal, Card } from 'reactstrap'

import { axiosClient } from 'store'
import { amplitudeInstance } from '../../../store/middleware/analytics'
import { atob, storage } from 'utils'
import { createToken, updateProfile, signUp } from '../../../api'

const Pages = [
  // {
  //   component: TrialClarification,
  //   step: 1
  // },
  {
    component: Plans,
    step: 1
  },
  {
    component: AccountClarification,
    step: 2
  },
  {
    component: CompanyForm,
    step: 2
  },
  {
    component: ServicesForm,
    step: 2
  },
  {
    component: PaymentForm,
    step: 3
  }
]

class SignupPage extends React.Component {
  state = {
    currentPage: 0,
    numSteps: 3,
    signupData: {},
    showPlanModal: false,
    busy: false
  }

  componentDidMount() {
    const { profile, history, location } = this.props
    if (!profile) {
      return history.push('/login')
    }
    
    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Start')
    const params = location && location.search
    if (params && params.length) {
      const decoded = atob(params)
      if (decoded) {
        if (decoded.id && decoded.id === 'qb') {
          storage.setItem('prevQuickBid', decoded.data)
        }
      }
    }
  }

  _reset = () => {
    this.setState({
      currentPage: 0,
      signupData: {},
      busy: false,
      error: null
    })
  }

  _onData = data => {
    let signupData = {
      ...this.state.signupData,
      ...data
    }
    this.setState({
      signupData,
      error: null
    })
  }

  _onError = error => {
    this.setState({
      error: error
    })
  }

  _onNext = async () => {
    const { profile, signUp, updateProfile } = this.props
    console.log('_onNext profile: ', profile)

    if (this.state.currentPage < Pages.length - 1) {
      this.setState({
        currentPage: this.state.currentPage + 1
      })
    } else {
      // Finish up.

      try {
        let data = this.state.signupData
        data.user = profile
        this.setState({
          busy: true,
          error: null
        })
      
        const result = await signUp(data)
        if (result) {
          // Ping this event on Amplitude.
          amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Complete')

          let transactionId = 'Not Found'
          if (result.data && result.data.merchant) {
            transactionId = result.data.merchant._id
          }

          // Send the event to Google Tag Manager.
          const tagManagerArgs = {
            dataLayer: {
              event: 'conversion',
              transactionId,
              transactionTotal: data.plan.price,
              transactionProducts: [
                {
                  sku: data.plan.id, // Stripe product?
                  name: data.plan.id, // Plan name
                  price: data.plan.price, // Plan price (1 month if monthly, 1 year if yearly)
                  quantity: 1
                }
              ]
            }
          }

          TagManager.dataLayer(tagManagerArgs)

          this.setState({
            busy: false,
            error: null
          })
          if (storage.getItem('prevQuickBid')) {
            window.location.replace('/dashboard/quickbid')
          } else {
            window.location.replace('/dashboard')
          }
        }
      } catch (ex) {
        let message = 'Could not complete sign up.'
        if (ex.message) {
          message = ex.message
        }
        if (ex && ex.response && ex.response.data && ex.response.data.message) {
          message = ex.response.data.message
        }
        console.error(
          message,
          ex,
          ex && ex.response ? ex.response : null,
          ex && ex.response && ex.response.data ? ex.response.data : null
        )
        this.setState({
          busy: false,
          error: message
        })
      }
    }
  }

  togglePlanModal = () => {
    this.setState({
      showPlanModal: !this.state.showPlanModal
    })
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.showPlanModal}
          toggle={this.togglePlanModal}
          style={{ 
            minWidth: isMobileOnly ? '100vw' : 700,
            marginLeft: isMobileOnly ? 0 : 'auto',
            marginRight: isMobileOnly ? 0 : 'auto',
          }}
        >
          <Card 
          style={{ 
            margin: 0,
            paddingLeft: isMobileOnly ? 0 : 20,
            paddingRight: isMobileOnly ? 0 : 20,
          }} 
          body
          >
            <Plans
              onData={this._onData}
              toggle={this.togglePlanModal}
              initialValues={
                this.state.signupData && this.state.signupData.plan
              }
              isMobileOnly={isMobileOnly}
            />
          </Card>
        </Modal>
        {this.renderPage(this.state.currentPage)}
      </>
    )
  }

  renderPage = pageIndex => {
    if (pageIndex > Pages.length - 1) {
      return null
    }

    const page = Pages[pageIndex]
    const PageClass = page.component
    const currentStep = page.step
    return (
      <PageClass
        numSteps="3"
        currentStep={currentStep}
        data={this.state.signupData}
        onNext={this._onNext}
        onData={this._onData}
        onError={this._onError}
        busy={this.state.busy}
        error={this.state.error}
        reset={this._reset}
        openModal={() => this.setState({ showPlanModal: true })}
        amplitudeInstance={amplitudeInstance}
        history={this.props.history}
        isMobileOnly={isMobileOnly}
      />
    )
  }
}

const mapStateToProps = state => ({
  profile: state.user.profile,
})


export default connect(
  mapStateToProps,
  {
    createToken,
    updateProfile,
    signUp
  }
)(withTheme(SignupPage))
