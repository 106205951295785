import produce from 'immer'

let initialState = {
  requesting: false,
  successful: false,
  error: null,
  stats: null
}

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'FETCH_SALES':
        draft.requesting = true
        break
      case 'FETCH_SALES_SUCCESS':
        draft.requesting = false
        draft.successful = true
        draft.stats = action.payload.data
        break
      case 'FETCH_SALES_FAIL':
        draft.requesting = false
        draft.successful = false
        draft.error = action.error
        break
      default:
        break
    }
  })

export default reducer
