import React from 'react'
import styled from 'styled-components'

import { PrimaryButton, Question, Title } from './ui'
// import Lottie from 'react-lottie'
// import animationData from '../../../../../assets/lottie/quickbid.json'

const Description = styled.p`
  font-size: 1.25rem;
  margin-top: 1rem;
  text-align: center;
`

// const AnimationContainer = styled.div`
//   width: 100%;

//   @media screen and (min-width: 32rem) {
//     width: 512px;
//   }
// `

// const defaultOptions = {
//   loop: false,
//   autoplay: true,
//   animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice'
//   }
// }

export const StartStage = ({ onStart }) => (
  <Question>
    {/* <AnimationContainer>
      <Lottie 
        options={defaultOptions}
        height={500}
        width={500}
      />
    </AnimationContainer> */}

    <Title>
      Quickbid Calculator
    </Title>
    <Description>
      Free janitorial bidding calculator for commercial and residential cleaning
      services.
    </Description>
    <Description>
      Get started below to start quoting projects at lightning speed.
    </Description>
    <PrimaryButton onClick={onStart} type={'button'}>
      Start
    </PrimaryButton>
  </Question>
)

export default StartStage
