import { UploadTitle } from './ImportContentStyles'
import MaterialSpinner from 'components/MaterialSpinner'

export const ImportBusy = () => {
  return (
    <div style={{marginTop: 20}}>
      <UploadTitle>Formatting data and verifying addresses...</UploadTitle>
      <div style={{ display: 'flex', justifyContent: 'center', margin: 30}}>
        <MaterialSpinner color='#6A6A6A' />
      </div>
    </div>
  )
}