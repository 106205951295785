import _ from 'lodash'
import { handlerErrors } from 'utils'

const inititalState = {
  clients: [],
  loading: false,
  error: null
}

const replace = function(items, item) {
  let newList = items.slice(0)
  let index = _.findIndex(newList, {
    _id: item._id
  })
  if (index > -1) {
    newList[index] = item
  } else {
    newList.push(item)
  }
  return newList
}

const clients = (state = inititalState, action) => {
  switch (action.type) {
    case 'CREATE_CLIENT_SUCCESS': {
      let clients = state.clients.slice(0)
      clients.push(action.payload.data)
      return {
        ...state,
        clients
      }
    }
    case 'UPDATE_CLIENT_SUCCESS': {
      return {
        ...state,
        clients: replace(state.clients, action.payload.data)
      }
    }
    case 'GET_CLIENTS': {
      return { ...state, loading: true }
    }
    case 'GET_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: action.payload.data,
        loading: false,
        error: null
      }
    case 'GET_CLIENTS_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting clients failed.')
      }
    case 'GET_CLIENT_SUCCESS':
      return {
        ...state,
        clients: state.clients.concat(action.payload.data),
        loading: false,
        error: null
      }
    case 'GET_CLIENT_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting clients failed.')
      }
    case 'CANCEL_CLIENT_SUCCESS':
      return {
        ...state,
        clients: state.clients.filter(
          client => client._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    case 'CANCEL_CLIENT_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Cancelling client failed.')
      }
    case 'UNARCHIVE_CLIENT_SUCCESS': {
      return {
        ...state,
        clients: [...state.clients, action.payload]
      }
    }
    default:
      return state
  }
}

export default clients
