import React from 'react'
import moment from 'moment'
import { UncontrolledTooltip } from 'reactstrap'

export default ({ id, date, placement, tooltipFormat }) => {
  return (
    <>
      <span id={`tooltip${id}`}>{moment(date).fromNow()}</span>
      <UncontrolledTooltip
        placement={placement || 'top'}
        target={`tooltip${id}`}
      >
        {moment(date).format(tooltipFormat || 'LLL')}
      </UncontrolledTooltip>
    </>
  )
}
