import React from 'react'
import { withTheme } from 'styled-components'
import { Input } from 'reactstrap'

const TextInput = ({
  input,
  meta: { valid, touched, invalid, error },
  style,
  placeholder,
  className,
  bsSize,
  autoFocus,
  type,
  theme
}) => (
  <div style={style}>
    <Input
      type={type}
      autoFocus={autoFocus}
      invalid={touched && invalid}
      valid={valid}
      style={{ marginBottom: 4 }}
      placeholder={placeholder}
      className={className}
      bsSize={bsSize}
      {...input}
    />
    <div
      style={{
        fontSize: 12,
        lineHeight: 1,
        minHeight: 12,
        marginBottom: 8,
        color: theme?.colors?.danger
      }}
    >
      {touched && error ? error : ' '}
    </div>
  </div>
)

export default withTheme(TextInput)
