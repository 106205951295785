const specialRuleDictionary = {
  lobby: 'lobbies',
  sheep: 'sheep',
  radius: 'radii',
  feet: 'feet',
  foot: 'feet',
  teeth: 'teeth',
  tooth: 'teeth'
}

const pluralize = word => {
  if (!word || !word.length) {
    return word
  }

  // TODO: Doesn't work well for capitalized multiple words.
  let result = specialRuleDictionary[word.toLowerCase()]
  if (result) {
    if (word.charAt(0) === word.charAt(0).toUpperCase()) {
      // Turn the first letter in result to upper case.
      result = result.charAt(0).toUpperCase() + result.slice(1)
    }
  }

  // This works fine with any input, any capitalization.
  if (!result) {
    result = word + 's'
  }

  return result
}

export default pluralize
