import React, { Component } from 'react'
import styled from 'styled-components'

import {
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'

import { FiMoreHorizontal } from 'react-icons/fi'

const Menu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

class ActionsCard extends Component {
  render() {
    const { actions, children } = this.props
    return (
      <Card body>
        {actions && (
          <Menu>
            <UncontrolledDropdown inNavbar>
              <DropdownToggle color="link">
                <FiMoreHorizontal size={24} />
              </DropdownToggle>
              <DropdownMenu right>{actions}</DropdownMenu>
            </UncontrolledDropdown>
          </Menu>
        )}
        {children}
      </Card>
    )
  }
}

export default ActionsCard
