import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'

// deps for viewer.
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';

const ViewerContainerRaw = styled.div`
  background-color: ${props =>
    props.color ? props.theme.colors[props.color] || props.color : null};
  padding: ${props => props.padding ? props.padding : '1px 16px'};
  border-radius: 4px;
  margin-top: 4px;
`

const ViewerContainer = withTheme(ViewerContainerRaw)

class MarkdownViewer extends Component {
  componentDidMount() {
    let viewer = new Viewer({
      el: document.querySelector(`#${this.props.id || 'viewMarkdown'}`),
      initialEditType: 'wysiwyg',
      previewStyle: 'vertical',
      height: 300,
      hideModeSwitch: true,
      initialValue: this.props.value
    })

    this.setState({ viewer })
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.value !== prevProps.value) {
      // const editorValue = this.state.viewer.getValue()
      // if (editorValue !== this.props.value) {
      //   console.log('fix markdown out-of-sync')
      this.state.viewer.setMarkdown(this.props.value)
      // }
    }
  }

  render() {
    const { padding } = this.props
    return (
      <ViewerContainer
        id={this.props.id || 'viewMarkdown'}
        color={this.props.color}
        padding={padding}
      />
    )
  }
}

export default MarkdownViewer
