import React from 'react'
import styled from 'styled-components'

import RouteLogoFull from 'assets/img/route-logo-full.png'
import { AppleDownloadLink, GoogleDownloadLink } from 'components/lib/Button'
import MobileHeader from 'components/layout/MobileHeader'

const MobileScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 15vh;
`

const Logo = styled.img`
  width: 70vw;
  height: auto;
`

const Text = styled.p`
  padding: 4rem 1rem;
  color: ${({theme}) => theme.colors.light1};
  text-align: center;
`

const imgStyles = {
  width: '50vw',
  height: 'auto'
}

const MobileRedirect = () => {
  return (
    <MobileScreen>
      <MobileHeader />
      <Logo src={RouteLogoFull} />
      <Text>
        Please log in to the web app on desktop.
        <br/>
        For the walkthrough builder, download the mobile app.
      </Text>
      <GoogleDownloadLink style={imgStyles} />
      <AppleDownloadLink style={{padding: 13, ...imgStyles}} />
    </MobileScreen>
  )
}

export default MobileRedirect