import React, { Component } from 'react'
import { connect } from 'react-redux'
import { axiosClient } from 'store'
import * as actions from 'api'

import LocationForm from './LocationForm'

class Location extends Component {
  state = {
    ready: false,
    location: null
  }

  componentDidMount() {
    const id = this.props.match.params.locationId
    if (id) {
      this._load(id)
    } else {
      this.setState({
        ready: true,
        location: {
          client: this.props.match.params.clientId
        }
      })
    }
  }

  _load = async id => {
    const result = await axiosClient.get(`/api/locations/${id}`)
    if (result && result.data) {
      this.setState({
        ready: true,
        location: result.data
      })
    } else {
      this.setState({
        ready: true,
        location: {
          client: this.props.match.params.clientId
        }
      })
    }
  }

  _save = async values => {
    await this.props.submitLocation(values)
    this.props.history.goBack()
  }

  render() {
    const { ready, location } = this.state

    if (!ready) {
      return null
    }

    const title = location && location._id ? `Update Location` : 'New Location'

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <h1 className="text-light">{title}</h1>
        <LocationForm onSubmit={this._save} initialValues={location} />
      </div>
    )
  }
}

export default connect(
  null,
  actions
)(Location)
