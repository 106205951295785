import { createSlice } from "@reduxjs/toolkit";
import { proposalsApi } from "store/apis";


const initialState = {
    proposal: null,
    isLoading: false,
}


export const completedProposalSlice = createSlice({
    name: 'completedProposal',
    initialState,
    reducers: {
        startLoadingProposal: (state) => {
            state.isLoading = true
            state.proposal = null
        },

        setProposal: (state, action) => {
            const { proposal } = action.payload
            state.proposal = proposal
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {

        builder.addMatcher(
            proposalsApi.endpoints.getProposal.matchFulfilled,
            (state, { payload }) => {
                state.proposal = payload
            }
        )

    }
})


export const { startLoadingProposal, setProposal } = completedProposalSlice.actions
