/* AREA TEMPLATES */
export * from './area.templates'
/* DOCUMENT TEMPLATES */
export * from './document.templates'
/* LOCATION TEMPLATES */
export * from './location.templates'
/* SERVICE TEMPLATES */
export * from './service.templates'

export const getLibrary = () => dispatch =>
	dispatch({
		type: 'GET_TEMPLATES',
		payload: {
			request: {
				url: `/api/library`
			}
		}
	})
