import React from 'react'
//import { Button } from 'reactstrap'
import Icon from 'components/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Boolean from './knobs/Boolean'
import Text from './knobs/Text'

import CloseButton from 'components/lib/Button/CloseButton'

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer

  //display: isDragging ? 'table' : null,

  // styles we need to apply on draggables
  ...draggableStyle,
  // position: isDragging ? 'absolute' : null,
  backgroundColor: isDragging ? 'white' : null,
  boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : null
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const getRenderItem = (areas, element, onChange) => (
  provided,
  snapshot,
  rubric
) => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Icon
        size={24}
        icon="IoIosReorder"
        color="grey"
        {...provided.dragHandleProps}
      />
      <div style={{ flex: 1, padding: '0 8px' }}>
        {areas[rubric.source.index].name}
      </div>
      <CloseButton
        onClick={() => {
          let newAreas = [...areas]
          newAreas.splice(rubric.source.index, 1)
          onChange({
            ...element.data,
            areas: newAreas
          })
        }}
      />
    </div>
  </div>
)

export default ({ element, proposal, onChange }) => {
  if (!element || !element.data) {
    return null
  }

  let areas = []
  let source
  if (element.data.areas) {
    areas = element.data.areas
  }
  if (element.data.source) {
    source = element.data.source
  }

  const renderArea = getRenderItem(areas, element, onChange)

  return (
    <div>
      <Text
        placeholder="Element Name"
        field="name"
        data={element.data}
        onChange={onChange}
      />
      {source && <p>Source: {source}</p>}

      {!areas ||
        (areas.length <= 0 && (
          <>
            {proposal && proposal.job ? (
              <p>
                The walkthrough and location does not have any areas defined.
              </p>
            ) : (
              <p>The location does not have any areas defined.</p>
            )}
          </>
        ))}

      {areas && areas.length > 0 && (
        <div>
          <Boolean
            data={element.data}
            label="Show Heading"
            onChange={onChange}
            field="showHeading"
          />
          {element.data && element.data.showHeading &&
            <Text
              field="headingText"
              data={element.data}
              onChange={onChange}
            />
          }
          <Boolean
            data={element.data}
            label="Include Square Footage"
            onChange={onChange}
            field="includeSquareFootage"
          />
          <Boolean
            data={element.data}
            label="Include Pictures"
            onChange={onChange}
            field="includeImages"
          />
          <Boolean
            data={element.data}
            label="Include Notes"
            onChange={onChange}
            field="includeNotes"
          />
          <Boolean
            data={element.data}
            label="Include Fixtures"
            onChange={onChange}
            field="includeFixtures"
          />
          <Boolean
            data={element.data}
            label="Show Fixture Totals"
            onChange={onChange}
            field="showFixtureAggregate"
          />
          <Boolean
            data={element.data}
            label="Show Square Footage Totals"
            onChange={onChange}
            field="showSquareFootageTotals"
          />
        </div>
      )}

      <DragDropContext
        onDragEnd={result => {
          // dropped outside the list
          if (!result.destination) {
            return
          }

          const newAreas = reorder(
            areas,
            result.source.index,
            result.destination.index
          )

          onChange({
            ...element.data,
            areas: newAreas
          })
        }}
      >
        <Droppable
          droppableId="areas-inspector-droppable"
          renderClone={renderArea}
        >
          {(provided, snapshot) => (
            <>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {areas.map((a, index) => (
                  <Draggable
                    key={`area-${index}`}
                    draggableId={`area-draggable-${index}`}
                    index={index}
                  >
                    {renderArea}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>

      {/*
        <Button color="danger" size="sm" style={{ marginTop: 16 }}>
          Reset
        </Button>

        */}
    </div>
  )
}
