import React from 'react'
import { Button } from 'reactstrap';

export const BodyTextAccount = ({ typeTextModal, handleClickButton }) => {



    return (
        <>
            {typeTextModal === "No Account"
                ? <>
                    <div>
                        <div>
                            <h4>Unify Operations and Boost Efficency</h4>
                        </div>
                        <div style={{ textAlign: 'justify' }}>
                            By consolodating essentials functions into a single platforms, you reduce administrative
                            tasks and optimize operational efficiency, Eliminate double entry, empower your team, and
                            manage your operation in PULSE.
                        </div>
                    </div>
                    <Button color='primary' onClick={handleClickButton}>Subscribed</Button>
                </>
                : <>
                    <div>
                        <h4>Upgrade your subscription to have access to this feature</h4>
                    </div>
                    <Button color='primary' onClick={handleClickButton}>Upgrade</Button>
                </>
            }

        </>
    )
}
