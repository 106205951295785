import React from 'react'
import styled from 'styled-components'

const statusColors = {
  default: '#454545',
  assigned: 'rgb(255,122,138)',
  'in progress': '#1FCBE2',
  scheduled: '#ffbe00',
  completed: '#7ECC2F'
}

const StatusTab = styled.span`
  display: inline-block;
  position: relative;
  width: 160px;
  text-align: center;
  padding: 5px;
  left: 40px;
  color: white;
  background-color: ${statusColors.default};
  border-radius: 4px 4px 0 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;

  ${props =>
    props.status
      ? `background-color ${statusColors[props.status] ||
          statusColors.default};`
      : null};
`

export default ({ status }) => {
  return (
    <StatusTab status={status && status.label}>
      {status && status.label ? status.label : ''}
    </StatusTab>
  )
}
