import React from 'react'
import ReactDOMServer from 'react-dom/server'
import {
  Button,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledButtonDropdown,
  DropdownMenu,
  Dropdown,
  DropdownToggle
} from 'reactstrap'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'
import _ from 'lodash'

import { axiosClient } from 'store'
import Empty from 'components/Empty'
import SideBar from 'components/SideBar'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import ActionBar from 'components/layout/ActionBar'
import {
  ActionButtons,
  BusyButton,
  SweptButton,
  ToastCloseButton
} from 'components/lib/Button'
import { connectConfirmation } from 'components/confirm'
import { toast } from 'react-toastify'
import { updateCompany, connectPulseAccount, connectSweptAccount } from 'api'

import CloneForm from '../../components/forms/CloneForm'
import EmailForm from '../../components/forms/EmailForm'
import SweptConnectForm from '../../components/forms/SweptConnectForm'
import PdfPreviewModal from '../../components/modals/PdfPreviewModal'

import FileDownload from '../components/FileDownload'
import TitleDisplay from '../components/TitleDisplay'

import Elements from './Elements'
import Templates from './Templates'

import Contents from './components/Contents'
import ElementsMenu from './components/ElementsMenu'
import ElementFrame from './components/ElementFrame'
import ElementInspector from './components/ElementInspector'
import PageOptions from './components/PageOptions'

import { calculateProposalTotals, scopeOfWorkToString } from './util'

import Icon from 'components/icons'
import { FiTag } from 'react-icons/fi'
import { FaRegFilePdf } from 'react-icons/fa'

import {
  blobToBase64,
  floorTypeSubtotals,
  getTimezone,
  htmlToBBCode
} from 'utils'
import SyncLocationDropdown from 'components/lib/Button/SyncLocationDropdown'
import PulseButton from 'components/lib/Button/PulseButton'
import { ModalVerify } from './components/ModalVerify'
import { trackEvent } from 'api/clientHub'
import { BID_PROPOSAL_DOWNLOAD_PROPOSAL } from 'store/analyticsActions/types/proposalTypes'

const gridSize = 8

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle, isDraggingOver) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  margin: `0 0 ${gridSize}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : isDraggingOver ? 'white' : null,

  // styles we need to apply on draggables
  ...draggableStyle
})

const AutoSaveIndicator = styled.span`
  color: ${props => props.theme.colors.grey};
  margin-left: 18px;
`

const DraggableList = styled.div`
  width: 100%;
  padding: ${gridSize}px;
  background: ${props => (props.isDraggingOver ? '#bfd0e6' : '#fff')};
`

const ElementPreview = styled.div`
  position: relative;
  &:hover {
    background-color: #ddd;
    .proposal-element-frame {
      display: flex;
    }
  }
`

const ScrollableColumn = styled.div`
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
`

class ProposalEditor extends React.Component {
  state = {
    options: {
      showPageNumbers: true,
      format: 'Letter',
      border: {
        top: 0.5,
        right: 0.5,
        bottom: 0.25,
        left: 0.5
      }
    },
    elements: [],
    idCount: 0,
    activeTab: '1',
    currentElementIndex: -1,
    hoverElementIndex: -1,
    proposal: null,
    showSideBar: false,
    showTemplatesModal: false,

    isSaving: false,
    showEmailForm: false,
    emailButtonBusy: false,
    showVerifyModal: false,
    detailAcountPulse: ''
  }

  constructor(props) {
    super()
    this.elementRefs = []
    this.previewColumn = null
  }

  async componentDidMount() {
    const proposalId = this.props.match.params.proposalId

    try {
      const { data } = await axiosClient.get(`/api/proposals/${proposalId}`)

      let idCount = 0
      if (data && data.data && data.data.idCount) {
        idCount = data.data.idCount
      }
      let elements = []
      if (data && data.data && data.data.elements) {
        elements = data.data.elements
        // Immediately get rid of elements that do not have a valid type.
        elements = elements.filter(e => Elements.find(o => o.type === e.type))
      }

      let useTemplateSelector = true
      if (elements && elements.length) {
        useTemplateSelector = false
      }

      let options = {
        ...this.state.options
      }
      if (data && data.data && data.data.options) {
        options = data.data.options || { ...this.state.options }
      }

      this.setState({
        proposalId,
        idCount,
        elements,
        options,
        proposal: data,
        showTemplatesModal: useTemplateSelector
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let elements = [...this.state.elements]

    let sourceIndex = result.source.index
    let idCount = this.state.idCount
    let showSideBar

    if (result.source.droppableId === 'element-menu-droppable') {
      const menuItem = Elements[result.source.index]
      let newElementData
      if (menuItem.create && typeof menuItem.create === 'function') {
        newElementData = menuItem.create(this.state.proposal)
      } else {
        newElementData = {
          ...menuItem.initialData
        }
      }

      let newElement = {
        type: menuItem.type,
        data: newElementData,
        id: `proposal-element-${(idCount += 1)}`
      }
      elements.push(newElement)
      sourceIndex = elements.length - 1
      showSideBar = true
    } else if (result.source.droppableId === 'custom-elements-droppable') {
      if (
        this.elementsMenu &&
        this.elementsMenu.state &&
        this.elementsMenu.state.customElements
      ) {
        const customElement = this.elementsMenu.state.customElements[
          result.source.index
        ]
        let newElement = {
          type: customElement.type,
          data: {
            ...customElement.data
          },
          id: `proposal-element-${(idCount += 1)}`
        }

        elements.push(newElement)
        sourceIndex = elements.length - 1
        showSideBar = true
      }
    }

    elements = reorder(elements, sourceIndex, result.destination.index)

    let currentElementIndex = result.destination.index

    this.setState(
      {
        elements,
        currentElementIndex,
        idCount,
        showSideBar
      },
      () => this._autoSave()
    )
  }

  onMoveUp = fromIndex => {
    const elements = reorder(this.state.elements, fromIndex, fromIndex - 1)

    let currentElementIndex = this.state.currentElementIndex
    if (currentElementIndex === fromIndex) {
      currentElementIndex = fromIndex - 1
    }

    this.setState(
      {
        elements,
        currentElementIndex
      },
      () => this._autoSave()
    )
  }

  onMoveDown = fromIndex => {
    const elements = reorder(this.state.elements, fromIndex, fromIndex + 1)

    let currentElementIndex = this.state.currentElementIndex
    if (currentElementIndex === fromIndex) {
      currentElementIndex = fromIndex + 1
    }

    this.setState(
      {
        elements,
        currentElementIndex
      },
      () => this._autoSave()
    )
  }

  _onAdd = config => {
    const configClone = _.cloneDeep(config)
    let elements = this.state.elements.slice()
    elements.push({
      ...configClone,
      id: `proposal-element-${this.state.idCount + 1}`
    })
    this.setState(
      {
        elements,
        idCount: this.state.idCount + 1,
        currentElementIndex: elements.length - 1,
        showSideBar: true
      },
      () => {
        this._autoSave()
        // Scroll to final element.
        const lastIndex = elements.length - 1
        if (this.elementRefs[lastIndex]) {
          this.elementRefs[lastIndex].scrollIntoView({ behavior: 'smooth' })
        }
      }
    )
  }

  _removeElement = (element, index) => {
    let { currentElementIndex, elements } = this.state

    if (
      currentElementIndex !== -1 &&
      elements[currentElementIndex].id &&
      element.id
    ) {
      currentElementIndex = -1
    }

    let newElements = this.state.elements.slice()
    newElements.splice(index, 1)

    this.setState(
      {
        elements: newElements,
        currentElementIndex
      },
      () => this._autoSave()
    )
  }

  _renderElementContent = (element, index) => {
    const { proposal } = this.state
    return (
      (Elements.find(e => e.type === element.type) &&
        Elements.find(e => e.type === element.type).render({
          element,
          proposal
        })) || <div />
    )
  }

  _editElement = (element, index, scrollToElement) => {
    if (scrollToElement) {
      this.elementRefs[index].scrollIntoView({ behavior: 'smooth' })
    }
    const currentElementIndex = index
    this.setState({
      currentElementIndex,
      showSideBar: true
    })
  }

  _renderElement = (element, index, totalCount) => {
    return (
      <ElementPreview
        onClick={() => this._editElement(element, index)}
        key={`proposal-element-preview-${index}`}
        ref={ref => (this.elementRefs[index] = ref)}
      >
        {this._renderElementContent(element, index)}
        <ElementFrame
          index={index}
          count={totalCount}
          elementType={element.type}
          elementName={element.data && element.data.name}
          onMoveUp={() => this.onMoveUp(index)}
          onMoveDown={() => this.onMoveDown(index)}
          onDelete={() => this._removeElement(element, index)}
          onMouseOver={() =>
            this.setState({
              hoverElementIndex: index
            })
          }
          onMouseOut={() =>
            this.setState({
              hoverElementIndex: -1
            })
          }
        />
      </ElementPreview>
    )
  }

  _save = async () => {
    const { elements, options, idCount, proposalId } = this.state

    let proposalData = {
      data: {
        idCount,
        elements,
        options
      }
    }

    // Save proposal formData.
    try {
      this.setState({
        isSaving: true
      })
      await axiosClient.patch(`/api/proposals/${proposalId}`, proposalData)

      this.setState({
        isSaving: false,
        isSaved: true
      })

      setTimeout(() => {
        this.setState({
          isSaved: false
        })
      }, 1000)
    } catch (ex) {
      console.error(ex)
      this.setState({
        isSaving: false
      })
    }
  }

  _autoSave = () => {
    if (this.state.isSaving) {
      return
    }

    const { proposal } = this.state
    if (!proposal) {
      return
    }

    if (this.saveDelay) {
      clearTimeout(this.saveDelay)
      this.saveDelay = null
    }

    this.saveDelay = setTimeout(this._save, 1000)
  }

  _updateProposalName = async values => {
    if (!values) {
      return null
    }

    let data = {
      name: values.name
    }

    try {
      this.setState({
        isSaving: true
      })
      await axiosClient.patch(`/api/proposals/${this.state.proposal._id}`, data)

      this.setState({
        isSaving: false
      })
      let newProposal = {
        ...this.state.proposal,
        name: values.name
      }
      this.setState({
        proposal: newProposal
      })
    } catch (ex) {
      console.error('Proposal could not be updated..', ex)
    }
  }

  createCustomElement = async (element, callback) => {
    let name = window.prompt('Please enter a name for your custom element.', '')

    if (!name || !name.trim().length) {
      return
    }

    let data = {
      ...element,
      name: name.trim()
    }

    delete data.id

    try {
      await axiosClient.post('/api/proposals/customElements', data)
      if (data.type === 'scope_of_work' && callback) {
        callback()
        return toast('Custom scope of work created.')
      }
      if (this.elementsMenu) {
        this.elementsMenu.fetchCustomElements()
      }
    } catch (ex) {
      console.error('Error creating custom element..', ex)
    }
  }

  _markAsWon = async () => {
    const { proposal, elements } = this.state
    if (!proposal) {
      return null
    }

    let data = {
      won: !proposal.won
    }

    if (data.won) {
      data.revenueData = {}
      data.revenueData = await calculateProposalTotals(elements)
      let areas = []
      elements?.forEach(element => {
        if (element.type === 'areas') {
          areas = areas.concat(element?.data?.areas)
        }
      })
      if (!areas?.length) {
        areas = proposal?.walkthrough?.areas
      }
      if (!areas?.length) {
        areas = proposal?.location?.areas
      }
      if (areas?.length) {
        const floorSubtotals = await floorTypeSubtotals(areas)
        data.revenueData.totalSqFt = floorSubtotals.total
      }
    }

    try {
      this.setState({
        isSaving: true
      })
      await axiosClient.patch(`/api/proposals/${proposal._id}`, data)

      this.setState({
        isSaving: false
      })
      let newProposal = {
        ...proposal,
        won: !proposal.won
      }
      this.setState({
        proposal: newProposal
      })
    } catch (ex) {
      console.error('Proposal could not be updated..', ex)
    }
  }

  _markAsSent = async () => {
    const { proposal } = this.state
    if (!proposal) {
      return null
    }

    let data = {
      sent: !proposal.sent
    }

    try {
      this.setState({
        isSaving: true
      })
      await axiosClient.patch(`/api/proposals/${proposal._id}`, data)

      this.setState({
        isSaving: false
      })
      let newProposal = {
        ...proposal,
        sent: !proposal.sent
      }
      this.setState({
        proposal: newProposal
      })
    } catch (ex) {
      console.error('Proposal could not be updated..', ex)
    }
  }

  _markAsDeclined = async () => {
    const { proposal } = this.state
    if (!proposal) {
      return null
    }

    let data = {
      declined: true
    }

    try {
      this.setState({
        isSaving: true
      })
      await axiosClient.patch(`/api/proposals/${proposal._id}`, data)
      this.setState({
        isSaving: false,
        isSaved: true
      })
    } catch (ex) {
      console.error('Proposal could not be archived..', ex)
    }
  }

  _archiveProposal = async () => {
    const { proposal } = this.state
    if (!proposal) {
      return null
    }

    let data = {
      archived: true
    }

    try {
      this.setState({
        isSaving: true
      })
      await axiosClient.patch(`/api/proposals/${proposal._id}`, data)

      this.props.history.push('/dashboard/proposals')
    } catch (ex) {
      console.error('Proposal could not be archived..', ex)
    }
  }

  _applyDocumentTemplate = async template => {
    let elementsToCreate = template.elements

    let idCount = 0
    let newElements = elementsToCreate
      .map(ec => {
        let original = Elements.find(o => o.type === ec.type)
        if (original) {
          let newElement = {
            id: `proposal-element-${idCount + 1}`,
            type: original.type
          }
          idCount++

          let data
          if (original.create && typeof original.create === 'function') {
            data = original.create(this.state.proposal)
          } else {
            data = {
              ...original.initialData
            }
          }

          if (ec.create) {
            data = {
              ...data,
              ...ec.create(this.state.proposal)
            }
          }

          if (ec.data) {
            data = {
              ...data,
              ...ec.data
            }
          }

          newElement.data = data

          return newElement
        }

        return null
      })
      .filter(e => e)

    this.setState(
      {
        idCount,
        elements: newElements,
        showTemplatesModal: false
      },
      () => this._autoSave()
    )
  }

  _toggleCloneModal = () => {
    const { showCloneModal } = this.state
    this.setState({
      showCloneModal: !showCloneModal
    })
  }

  _cloneProposal = async values => {
    try {
      const res = await axiosClient.post(
        `/api/proposals/clone/${values.proposal}`,
        values
      )

      this.setState(
        {
          proposal: res.data,
          showCloneModal: false
        },
        () => {
          this.props.history.push(`/dashboard/edit-proposal/${res.data._id}`)
          toast('Proposal successfully cloned.')
        }
      )
    } catch (ex) {
      console.error('Proposal could not be cloned..', ex)
    }
  }

  _toggleReviewModal = () => {
    const { showEmailReviewForm } = this.state
    this.setState({
      showEmailReviewForm: !showEmailReviewForm
    })
  }

  _emailReview = async (values, event) => {
    this.setState({
      emailReviewBusy: true
    })
    const { proposal } = this.state

    let emailList = []

    if (values.emailContact) {
      emailList.push(proposal.client.contact.email)
    }

    if (values.otherEmails) {
      emailList = emailList.concat(values.otherEmails.split(','))
    }

    emailList = emailList
      .map(email => (email = email.trim()))
      .filter(email => email.length > 0)
    values.emailList = emailList
    values.clientId = proposal.client._id
    values.proposalId = proposal._id

    await axiosClient.post(
      'api/proposals/emailreview',
      {
        emailList: values.emailList,
        clientId: values.clientId,
        proposalId: values.proposalId,
        message: values.message
      },
      {
        timeout: 30000
      }
    )

    // amplitudeInstance && amplitudeInstance.logEvent('Send Proposal', { id: proposal._id })

    this.setState({
      emailReviewBusy: false,
      showEmailReviewForm: false
    })

    toast('Proposal has been sent for review.', {
      className: 'toast-success'
    })
  }

  _downloadFile = async () => {
    let str = ReactDOMServer.renderToString(
      this.state.elements.map((e, i) => {
        let master = Elements.find(m => m.type === e.type)
        if (master && master.print) {
          // Don't know why but this needs to replaceclosing tags first then opening tags for the markdown text to remain intact.
          return master
            .print(e)
            .replace(/<\/.*>/g, '')
            .replace(/<.*>/g, '')
        } else {
          return this._renderElementContent(e, i)
        }
      })
    )

    const result = await axiosClient({
      url: 'api/proposals/pdf',
      method: 'post',
      data: {
        proposal: str,
        showPages: this.state.options.showPageNumbers
      },
      responseType: 'blob',
      timeout: 100000
    })

    return result
  }

  sendSweptLocation = async () => {
    const { proposal, elements } = this.state
    const { currentEmployment } = this.props
    console.log(elements)
    if (!proposal) {
      toast('The proposal is not ready for this.')
      return
    }

    const scopeOfWorkString = scopeOfWorkToString(elements)

    const { client, location } = proposal
    if (!currentEmployment) {
      return
    }

    if (!currentEmployment.sweptToken) {
      this.setState({
        showSweptConnect: true
      })
      return
    }

    if (!client || !location) {
      toast('Proposal needs a location and client to send data to Swept.')
    }

    const { address, sweptLocationId } = location

    let timezone
    if (!address.timezone) {
      timezone = await getTimezone(address.latitude, address.longitude)
    } else {
      timezone = address.timezone
    }

    let sweptData = {
      token: `Bearer ${currentEmployment.sweptToken}`,
      city: address.city,
      cleaning_instructions_language: 'en',
      country: address.country,
      is_verified: true, //all our addresses should be verified in Google
      name: client.name,
      postal_code: address.zipcode,
      state: address.state,
      street_address: address.streetNumber + address.route,
      timezone: timezone && timezone.timeZoneId
    }

    if (scopeOfWorkString) {
      sweptData['cleaning_instructions'] = scopeOfWorkString
    }

    if (sweptLocationId) {
      this.updateSweptLocation(location.id, sweptData)
    } else {
      this.createSweptLocation(location.id, sweptData)
    }
  }

  createSweptLocation = async (locationId, data) => {
    try {
      const res = await axiosClient.post(
        `/api/locations/${locationId}/createSweptLocation`,
        data
      )
      if (res && res.data) {
        toast('Your location was successfully created in Swept!', {
          className: 'toast-success',
          closeButton: ToastCloseButton
        })
        this.setState(state => ({
          proposal: {
            ...state.proposal,
            location: res.data
          }
        }))
      }
    } catch (err) {
      const { message, error } = err.response.data
      console.error('Swept error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  updateSweptLocation = async (locationId, data) => {
    try {
      await axiosClient.patch(
        `/api/locations/${locationId}/updateSweptLocation`,
        data
      )
      toast('Your Swept location was successfully updated!', {
        className: 'toast-success',
        closeButton: ToastCloseButton
      })
    } catch (err) {
      const { message, error, location } = err.response.data
      if (message === 'Swept location not found') {
        this.setState(state => ({
          proposal: {
            ...state.proposal,
            location
          }
        }))
      }
      console.error('Swept error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  handleSweptConnect = async values => {
    const { currentEmployment, connectSweptAccount } = this.props
    const { username, password } = values
    try {
      this.setState({
        sweptConnectBusy: true
      })
      let res = await connectSweptAccount(currentEmployment._id, {
        username,
        password
      })

      if (res && res.payload && res.payload.data) {
        toast('Successfully connected to your Swept account', {
          className: 'toast-success',
          closeButton: ToastCloseButton
        })
        this.setState({
          showSweptConnect: false,
          sweptConnectBusy: false
        })
      } else if (res && res.error && res.error) {
        throw res.error
      }
    } catch (err) {
      console.error('Swept error: ', err)
      const { message } = err && err.response && err.response.data
      this.setState({
        sweptConnectBusy: false
      })
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  _sendPulseLocation = async () => {
    const { proposal, elements } = this.state
    const { company } = this.props
    const details = ['No Account', 'No Sync', 'Free Plan']

    console.log(company)

    let verifyAcountPulse = await axiosClient.get(
      `/api/locations/verifyPulseAcount/${company._id}`
    )

    const { detail } = verifyAcountPulse.data

    console.log(detail)

    if (details.includes(detail)) {
      this.setState({
        showVerifyModal: true,
        detailAcountPulse: detail
      })

      return
    }

    if (!proposal) {
      toast('The proposal is not ready for this.')
      return
    }
    if (!proposal.merchant.pulseAccountId) {
      this.setState({
        showPulseConnect: true
      })
      return
    }

    this.setState({
      pulseConnectBusy: true
    })
    let revenueData = proposal.revenueData
    if (!revenueData) {
      revenueData = await calculateProposalTotals(elements)
      let areas = []
      elements?.forEach(element => {
        if (element.type === 'areas') {
          areas = areas.concat(element?.data?.areas)
        }
      })
      if (!areas?.length) {
        areas = proposal?.walkthrough?.areas
      }
      if (!areas?.length) {
        areas = proposal?.location?.areas
      }
      if (areas?.length) {
        const floorSubtotals = await floorTypeSubtotals(areas)
        revenueData.totalSqFt = floorSubtotals.total
      }
    }
    const scopeOfWorkString = scopeOfWorkToString(elements)

    const { location } = proposal

    const { address, pulseLocationId } = location

    let pulseData = {
      LocationAddress: {
        address: address.formattedAddress,
        lat: address.latitude,
        lng: address.longitude
      },
      LocationName: location.name,
      LocationPhoneNumber: location.contact?.phone,
      LocationEmail: location.contact?.email,
      addressCity: address.city,
      addressCountry: address.country,
      addressState: address.state,
      contractValue: revenueData.yearlyTotal,
      Area: revenueData.totalSqFt
    }

    if (scopeOfWorkString) {
      const bbcodeString = htmlToBBCode(scopeOfWorkString)
      console.log('scopeOfWorkString: ', scopeOfWorkString)
      console.log('bbcodeString: ', bbcodeString)
      pulseData['CleaningInstructions'] = bbcodeString
    }

    if (pulseLocationId) {
      this.updatePulseLocation(location.id, pulseData)
    } else {
      this.createPulseLocation(location.id, pulseData)
    }
  }

  createPulseLocation = async (locationId, data) => {
    try {
      const res = await axiosClient.post(
        `/api/locations/${locationId}/createPulseLocation`,
        data
      )
      if (res && res.data) {
        toast('Your location was successfully created in Pulse!', {
          className: 'toast-success',
          closeButton: ToastCloseButton
        })
        console.log('location res: ', res.data)
        this.setState(state => ({
          proposal: {
            ...state.proposal,
            location: res.data
          },
          pulseConnectBusy: false
        }))
      }
    } catch (err) {
      const { message, error } = err.response.data
      console.error('Pulse error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  updatePulseLocation = async (locationId, data) => {
    try {
      let res = await axiosClient.patch(
        `/api/locations/${locationId}/updatePulseLocation`,
        data
      )
      if (res && res.data) {
        if (res.data.error != null) {
          this.setState({})
          this.createPulseLocation(locationId, data)
          return
        }
        this.setState(state => ({
          pulseConnectBusy: false,
          proposal: {
            ...state.proposal,
            location
          }
        }))
      }
    } catch (err) {
      const { message, error, location } = err.response.data
      if (message === 'Pulse location not found') {
      }
      console.error('Pulse error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
      return
    }
    toast('Your Pulse location was successfully updated!', {
      className: 'toast-success',
      closeButton: ToastCloseButton
    })
  }

  handlePulseConnect = async values => {
    const { connectPulseAccount } = this.props
    const { proposal } = this.state
    try {
      this.setState({
        pulseConnectBusy: true
      })
      let res = await connectPulseAccount()

      if (res && res.payload && res.payload.data) {
        const { pulseAccountId } = res.payload.data
        this.setState({
          proposal: { ...proposal, merchant: { pulseAccountId } }
        })
        await this._sendPulseLocation()

        this.setState({
          pulseConnectBusy: false,
          showPulseConnect: false
        })
        toast('Successfully connected to your Pulse account', {
          className: 'toast-success',
          closeButton: ToastCloseButton
        })
      } else if (res && res.error && res.error) {
        throw res.error
      }
    } catch (err) {
      console.error('Pulse error: ', err)
      this.setState({
        pulseConnectBusy: false,
        showPulseConnect: false
      })
      toast('There was a problem connecting to Pulse.', {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  openPagePreview = async () => {
    this.setState({
      showPreviewModal: true
    })
    const pdfBlob = await this._downloadFile()
    if (!pdfBlob.error) {
      let blob = new Blob([pdfBlob.data], { type: 'application/pdf' })
      const pdfUrl = await blobToBase64(blob)

      this.setState({
        pdfBlob,
        pdfUrl
      })
    }
  }

  closePreview = () => {
    this.setState({
      showPreviewModal: false,
      pdfBlob: null,
      pdfUrl: null
    })
  }

  _handleToggleModal = () => {
    this.setState({
      showVerifyModal: false
    })
  }

  render() {
    const {
      activeTab,
      proposal,
      showPreviewModal,
      showSweptConnect,
      sweptConnectBusy,
      showPulseConnect,
      pulseConnectBusy,
      showEmailReviewForm,
      emailReviewBusy
    } = this.state
    const { company } = this.props
    // const betaMode = company && company.betaMode === true

    const pulseAccountId = proposal?.merchant?.pulseAccountId || null
    const sweptLocationExists = !!proposal?.location?.sweptLocationId
    const pulseLocationExists = !!proposal?.location?.pulseLocationId

    //add future integrations as buttons here
    const integrationOptions = [
      {
        component: (
          <PulseButton
            className={`cta btn`}
            onClick={this._sendPulseLocation}
            busy={pulseConnectBusy}
            isUpdate={pulseLocationExists}
            color={'light'}
          />
        )
      },
      // {
      //   component: (
      //     <SweptButton
      //       onClick={this.sendSweptLocation}
      //       isUpdate={this.state.sweptLocationExists}
      //       color={'light'}
      //     />
      //   )
      // }
    ]

    let sideBarWidth =
      this.previewColumn &&
      window.innerWidth - this.previewColumn.getBoundingClientRect().right - 8

    if (sideBarWidth < 100) sideBarWidth = 100
    if (sideBarWidth > 400) sideBarWidth = 400

    return (
      <Container
        fluid
        style={{ display: 'flex', flexDirection: 'column', rowGap: '0.7rem' }}
      >
        {proposal && proposal._id && (
          <CloneForm
            open={this.state.showCloneModal}
            toggle={this._toggleCloneModal}
            header={`Clone Proposal ${proposal.code}`}
            onSubmit={this._cloneProposal}
            initialValues={{
              proposal: proposal._id,
              client: proposal.client && proposal.client._id,
              location: proposal.location && proposal.location._id
            }}
          />
        )}
        <ActionBar
          left={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TitleDisplay
                proposal={proposal}
                onUpdate={this._updateProposalName}
                history={this.props.history}
              />
              {this.state.isSaving ? (
                <AutoSaveIndicator>Saving...</AutoSaveIndicator>
              ) : this.state.isSaved ? (
                <AutoSaveIndicator>Saved</AutoSaveIndicator>
              ) : null}
            </div>
          }
          right={
            <div>
              {proposal && proposal._id && (
                <span>
                  <Icon
                    icon="FaPaperPlane"
                    color={
                      proposal.sent ? '#09f415' : this.props.theme.colors.grey
                    }
                    size={30}
                    style={{ marginRight: 8 }}
                  />
                  <Icon
                    icon="FaMedal"
                    color={
                      proposal.won ? '#09f415' : this.props.theme.colors.grey
                    }
                    style={{ marginRight: 8 }}
                    size={30}
                  />
                </span>
              )}

              <SyncLocationDropdown options={integrationOptions} />
              <ActionButtons
                buttons={[
                  {
                    title: 'Save Proposal',
                    onClick: () => this._save()
                  }
                ]}
                options={[
                  ...(proposal && proposal._id
                    ? [
                      {
                        title: proposal.signatureToken
                          ? 'Already in Review'
                          : 'Send Proposal w/ E-Sign',
                        onClick: () => {
                          if (!proposal.signatureToken) {
                            this._toggleReviewModal()
                          }
                        }
                      },
                      {
                        title: proposal.won ? 'Undo Won Flag' : 'Mark as Won',
                        onClick: () => this._markAsWon(proposal)
                      },
                      {
                        title: proposal.sent
                          ? 'Undo Sent Flag'
                          : 'Mark as Sent',
                        onClick: () => this._markAsSent(proposal)
                      },
                      {
                        title: 'Clone Proposal',
                        onClick: () => this._toggleCloneModal()
                      },
                      {
                        title: 'Download PDF',
                        onClick: async () => {
                          const result = await this._downloadFile()
                          const fileName = `Proposal-${proposal.merchant ? proposal.merchant.name : ''
                            }-${proposal.client ? proposal.client.name : ''
                            }.pdf`

                          if (!result.error) {
                            FileDownload(result.data, fileName)
                            trackEvent({ typeEvent: BID_PROPOSAL_DOWNLOAD_PROPOSAL, params: { id: this.state.proposalId } })
                          } else {
                            console.error(result.error)
                          }
                        }
                      },
                      {
                        title: 'Declined by Client',
                        onClick: this._markAsDeclined
                      },
                      {
                        title: 'Trash Proposal',
                        divide: true,
                        color: 'danger',
                        onClick: () =>
                          this.props.confirm(
                            'Confirm Trash',
                            'Are you sure you want to trash this proposal?',
                            this._archiveProposal
                          )
                      }
                    ]
                    : [])
                ]}
              />
            </div>
          }
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
            // marginBottom: 12
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {proposal &&
              proposal.labels &&
              proposal.labels.length > 0 &&
              proposal.labels.map(label => {
                return (
                  <Label
                    key={label._id}
                    color={label.color}
                    style={{ marginRight: 8 }}
                  >
                    {label.name}
                  </Label>
                )
              })}
          </div>
          <div style={{ display: 'flex' }}>
            {proposal && (
              <UncontrolledButtonDropdown group={false}>
                <DropdownToggle
                  size="sm"
                  style={{ textAlign: 'left', marginRight: 8, height: 32 }}
                  color="secondary"
                >
                  <FiTag /> Labels
                </DropdownToggle>
                <DropdownMenu right={true}>
                  <LabelEditor
                    data={proposal.labels}
                    onChange={async data => {
                      const res = await axiosClient.patch(
                        `/api/proposals/${proposal._id}`,
                        { labels: data }
                      )

                      if (res && res.data) {
                        this.setState({
                          proposal: res.data
                        })
                      }
                    }}
                    refreshEntity={async () => {
                      const res = await axiosClient.get(
                        `/api/proposals/${proposal._id}`
                      )

                      if (res && res.data) {
                        this.setState({
                          proposal: res.data
                        })
                      }
                    }}
                  />
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
            <Button
              onClick={this.openPagePreview}
              size="sm"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FaRegFilePdf style={{ marginRight: 2 }} /> Preview
            </Button>
          </div>
        </div>
        {this.state.showSideBar && (
          <SideBar
            size={sideBarWidth}
            isOpen={
              this.state.showSideBar && this.state.currentElementIndex !== -1
            }
            onClose={() =>
              this.setState({
                showSideBar: false
              })
            }
            resizable
          >
            <>
              {this.state.currentElementIndex !== -1 && (
                <ElementInspector
                  element={this.state.elements[this.state.currentElementIndex]}
                  onChange={element => {
                    let elements = this.state.elements.slice()
                    elements[this.state.currentElementIndex] = element
                    this.setState(
                      {
                        elements
                      },
                      () => this._autoSave()
                    )
                  }}
                  onClose={() =>
                    this.setState({
                      showSideBar: false
                    })
                  }
                  onCreateCustomElement={callback =>
                    this.createCustomElement(
                      this.state.elements[this.state.currentElementIndex],
                      callback
                    )
                  }
                  proposal={proposal}
                />
              )}
            </>
          </SideBar>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <ScrollableColumn
            id="contents-column"
            style={{ minWidth: 208, width: 208 }}
          >
            <Contents
              onDragEnd={this.onDragEnd}
              elements={this.state.elements}
              activeIndex={this.state.currentElementIndex}
              hoverIndex={this.state.hoverElementIndex}
              editElement={this._editElement}
              removeElement={this._removeElement}
            />
          </ScrollableColumn>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <ScrollableColumn
              id="proposal-column"
              ref={ref => (this.previewColumn = ref)}
              style={{
                marginLeft: 8,
                marginRight: 8,
                minWidth: 640,
                width: 768
              }}
            >
              <Droppable droppableId="proposal-editor-area">
                {(provided, droppableSnapshot) => (
                  <DraggableList
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    isDraggingOver={droppableSnapshot.isDraggingOver}
                    className="proposal-new"
                  >
                    {!this.state.elements.length && (
                      <Empty
                        message="The proposal has no content."
                        style={{ color: '#aaa' }}
                      />
                    )}
                    {this.state.elements.map((item, index) => (
                      <Draggable
                        key={`draggable-index-${index}`}
                        draggableId={`draggable-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              droppableSnapshot.isDraggingOver
                            )}
                          >
                            {this._renderElement(
                              item,
                              index,
                              this.state.elements.length
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </DraggableList>
                )}
              </Droppable>
            </ScrollableColumn>
            <ScrollableColumn
              id="menu-column"
              style={{ minWidth: 240, width: 300 }}
            >
              <Nav tabs className="dark-mode-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.setState({
                        activeTab: '1'
                      })
                    }}
                  >
                    Elements
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.setState({
                        activeTab: '2'
                      })
                    }}
                  >
                    Options
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div style={{ padding: 16 }}>
                    <ElementsMenu
                      ref={e => (this.elementsMenu = e)}
                      onAdd={this._onAdd}
                      proposal={proposal}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <PageOptions
                    options={this.state.options}
                    onChange={val =>
                      this.setState(
                        {
                          options: val
                        },
                        () => this._autoSave()
                      )
                    }
                  />
                </TabPane>
              </TabContent>
            </ScrollableColumn>
          </DragDropContext>
        </div>
        <Modal isOpen={this.state.showTemplatesModal} centered>
          <ModalBody>
            <h3 style={{ textAlign: 'center' }}>Choose template</h3>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {Templates.map((t, i) => (
                <Button
                  key={`template-index-${i}`}
                  style={{ margin: 4 }}
                  onClick={() => this._applyDocumentTemplate(t)}
                >
                  {t.label}
                </Button>
              ))}
            </div>
          </ModalBody>
        </Modal>
        {this.state.showVerifyModal && (
          <ModalVerify
            showModal={this.state.showVerifyModal}
            typeModal={this.state.detailAcountPulse}
            handleToggle={this._handleToggleModal}
          />
        )}
        {showEmailReviewForm ? (
          <Modal isOpen={showEmailReviewForm} style={{ paddingTop: 50 }}>
            <ModalHeader
              style={{ justifyContent: 'center' }}
              toggle={this._toggleReviewModal}
            >
              {'Send Proposal for Review'}
            </ModalHeader>
            <ModalBody>
              <EmailForm
                onSubmit={this._emailReview}
                busy={emailReviewBusy}
                hideAttachments={true}
                hideSubject={true}
                hideCC={true}
                clientContact={
                  proposal?.client?.contact?.email
                    ? proposal.client.contact
                    : null
                }
              />
            </ModalBody>
          </Modal>
        ) : null}
        {showPreviewModal && (
          <PdfPreviewModal
            isOpen={showPreviewModal}
            toggle={this.closePreview}
            pdf={this.state.pdfUrl}
            download={
              this.state.pdfBlob
                ? async () => {
                  const { pdfBlob } = this.state

                  const fileName = `Proposal-${proposal.merchant ? proposal.merchant.name : ''
                    }-${proposal.client ? proposal.client.name : ''}.pdf`

                  FileDownload(pdfBlob.data, fileName)
                }
                : null
            }
            email={() => this.setState({ showEmailReviewForm: true })}
          />
        )}
        <Modal isOpen={showSweptConnect} style={{ paddingTop: 50 }}>
          <ModalHeader
            style={{ justifyContent: 'center' }}
            toggle={() => this.setState({ showSweptConnect: false })}
          >
            {'Connect to Swept'}
          </ModalHeader>
          <ModalBody>
            <p>Connect to your Swept account to share locations from Route</p>
            <SweptConnectForm
              initialValues={{ username: this.props.currentEmployment.email }}
              onSubmit={this.handleSweptConnect}
              busy={sweptConnectBusy}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={showPulseConnect} style={{ paddingTop: 50 }}>
          <ModalHeader
            style={{ justifyContent: 'center' }}
            toggle={() => this.setState({ showPulseConnect: false })}
          >
            {'Connect to Pulse'}
          </ModalHeader>
          <ModalBody>
            <p>Connect to your Pulse account to share proposals from Bid</p>
            <BusyButton
              className={`cta btn ${pulseAccountId ? 'disabled' : ''}`}
              size={'md'}
              onClick={pulseAccountId ? null : this.handlePulseConnect}
              color="primary"
              style={{ marginRight: 10 }}
              busy={pulseConnectBusy}
            >
              {pulseAccountId ? 'Connected to Pulse' : 'Connect Pulse Account'}
            </BusyButton>
          </ModalBody>
        </Modal>
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentEmployment: state.user.currentEmployment,
    company: state.user.currentCompany
  }),
  {
    connectSweptAccount,
    updateCompany,
    connectPulseAccount
  }
)(withTheme(connectConfirmation(ProposalEditor)))
