export default [
  'Asbestos',
  'Astroturf',
  'Carpet',
  'Ceramic Tile',
  'Concrete',
  'Cork',
  'Epoxy',
  'Granite',
  'Hardwood',
  'Laminate',
  'Linoleum',
  'LVT',
  'Marble',
  'Other',
  'Polished Concrete',
  'Rolled Vinyl',
  'Rubberized',
  'Sports Linoleum',
  'Stone',
  'Terrazo',
  'Textured Granite',
  'Tile & Carpet Mix',
  'Travertine',
  'VCT',
  'Wood Vinyl'
]
