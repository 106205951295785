import React from 'react'
import styled, { withTheme } from 'styled-components'
import InplaceForm from 'components/InplaceForm'

const SpanLink = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const TitleDisplay = ({ proposal, onUpdate, theme, history, ...rest }) => {
  if (!proposal) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'normal'
      }}
    >
      <div style={{ marginRight: 8 }}>
        Proposal {proposal.code && proposal.code + ' - '}
      </div>

      <InplaceForm
        initialValues={{ name: proposal && proposal.name }}
        enableReinitialize
        onSubmit={values =>
          onUpdate({
            name: values.name
          })
        }
        autoComplete="off"
        style={{
          backgroundColor: theme.colors ? theme.colors.dark0 : '#2E2F31',
          color: 'white'
        }}
      />

      <div style={{ marginLeft: 8 }}>
        {proposal.client && proposal.client.name && ' for '}
        {proposal.client && (
          <SpanLink
            onClick={() =>
              history.push(
                `/dashboard/client-details/${proposal.client._id}`
              )
            }
          >
            {proposal.client.name}
          </SpanLink>
        )}
      </div>
    </div>
  )
}

export default withTheme(TitleDisplay)
