export const getProposals = query => dispatch =>
  dispatch({
    type: 'GET_PROPOSALS',
    payload: {
      request: {
        url: `/api/proposals/${
          query && query.clientId ? '?clientId=' + query.clientId : ''
        }${query && query.limit ? '&limit=' + query.limit : ''}${
          query && query.skip ? '&skip=' + query.skip : ''
        }`
      }
    }
  })

export const createProposal = proposal => dispatch =>
  dispatch({
    type: 'CREATE_PROPOSAL',
    payload: {
      request: {
        url: `/api/proposals`,
        method: 'post',
        data: {
          ...proposal,
          version: 2 // Default new proposals to version 2
        }
      }
    }
  })

export const getProposal = proposalId => dispatch =>
  dispatch({
    type: 'GET_PROPOSAL',
    payload: {
      request: {
        url: `/api/proposals/${proposalId}`
      }
    }
  })

export const updateProposal = proposal => dispatch =>
  dispatch({
    type: 'UPDATE_PROPOSAL',
    payload: {
      request: {
        url: `/api/proposals/${proposal._id}`,
        method: 'patch',
        data: proposal
      }
    }
  })

export const cloneProposal = values => dispatch =>
  dispatch({
    type: 'CLONE_PROPOSAL',
    payload: {
      request: {
        url: `/api/proposals/clone/${values.proposal}`,
        method: 'post',
        data: values
      }
    }
  })

export const archiveProposal = proposalId => dispatch =>
  dispatch({
    type: 'ARCHIVE_PROPOSAL',
    payload: {
      request: {
        url: `/api/proposals/${proposalId}/delete`,
        method: 'patch'
      }
    }
  })

export const emailProposalPDF = data => dispatch =>
  dispatch({
    type: 'EMAIL_PROPOSAL_PDF',
    payload: {
      request: {
        url: 'api/proposals/emailpdf',
        method: 'post',
        data: data,
        timeout: 1000 * 45 // Higher timeout as the operation may take longer than others.
      }
    }
  })

export const createProposalPDF = proposalMarkdown => dispatch =>
  dispatch({
    type: 'CREATE_PROPOSAL_PDF',
    payload: {
      request: {
        url: 'api/proposals/pdf',
        method: 'post',
        data: proposalMarkdown,
        responseType: 'blob',
        timeout: 30000
      }
    }
  })
