import React from 'react'

export default props => (
  <svg width={18} height={18} {...props}>
    <title>{'F39FBDB0-8591-45C3-9AFA-FE2116EB703C'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1 0h18v18H-1z" />
      <path
        d="M14.965 0c.38 0 .69.296.69.662v15.882c0 .367-.31.662-.69.662H1.05a.676.676 0 0 1-.69-.662V.662C.36.297.67 0 1.05 0h13.914zM2.12 7v2h8.504V7H2.12zm0 4v2h5.128v-2H2.12zm0-8v2H14V3H2.12z"
        fill={props.color}
        fillRule="nonzero"
      />
    </g>
  </svg>
)
