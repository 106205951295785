import React from 'react'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'
import Icon from 'components/icons'
import { estimatorFaq, dashboardFaq } from 'data'
import { withTheme } from 'styled-components'

const TYPES_POPOVER = {
  'estimatorFaq' : estimatorFaq,
  'dashboardFaq' :dashboardFaq
}

const InfoPopover = ({ section, theme, size, type }) => {
  const typePopover = TYPES_POPOVER[type]

  if (!typePopover) {
    return null
  }

  if(!typePopover[section]){
    return null
  }


  const popoverContent = typePopover[section].map((info, index) => {
    return (
      <div key={`${info.name}${index}`}>
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            fontFamily: 'Arial'
          }}
        >
          <strong>{info.name}</strong>: {info.message}
          {info.example && (
            <>
              <br />
              <em dangerouslySetInnerHTML={{ __html: info.example }} />
            </>
          )}
        </pre>
      </div>
    )
  })

  return (
    <>
      <span
        id={`${section}`}
        style={{ marginLeft: 4, verticalAlign: 'text-top' }}
      >
        <Icon
          size={size || 16}
          icon="FaInfoCircle"
          color={theme.colors.grey || 'grey'}
          style={{ cursor: 'pointer' }}
        />
      </span>
      <UncontrolledPopover target={`${section}`} trigger="legacy">
        <PopoverBody>{popoverContent}</PopoverBody>
      </UncontrolledPopover>
    </>
  )
}

export default withTheme(InfoPopover)
