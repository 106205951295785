import React from 'react'
import moment from 'moment'

export default ({ element, proposal }) => {
  proposal = proposal || {}
  const showHeading = element && element.data && element.data.showHeading
  const headingText =
    (element && element.data && element.data.headingText) || 'Signatures'
  let serviceProvider = element.data.serviceProvider || proposal.merchant.name
  let client = element.data.client || proposal.client.name
  const clientSignature = proposal?.signatureData?.clientSignature
  const merchantSignature = proposal?.signatureData?.merchantSignature

  return (
    <div style={{ paddingBottom: 20, pageBreakInside: 'avoid!important' }}>
      {showHeading && <h2 className="section-title">{headingText}</h2>}
      <p>
        IN WITNESS WHEREOF, the parties hereto have executed this{' '}
        {proposal.name || 'Service Proposal'} Agreement by their duly authorized
        representatives on the dates set forth below.
      </p>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div style={{ width: '40%', display: 'inline-block' }}>
          <p className="company-signature">{serviceProvider}</p>
          {merchantSignature?.signatureUrl
            ? (
              <div className="sig-image-container">
                <img src={merchantSignature.signatureUrl} alt="Client Signature" />
              </div>
            ) : <hr className="horizontal-line" />
          }
          <p className="sig-italic-label">Signature</p>
          {merchantSignature?.printedName ? (
            <div className="sig-info-container">
              <p>{merchantSignature.printedName}</p>
            </div>
          ) : <hr className="horizontal-line" />}
          <p className="sig-italic-label">Print Name</p>
          {merchantSignature?.printedTitle ? (
            <div className="sig-info-container">
              <p>{merchantSignature.printedTitle}</p>
            </div>
          ) : <hr className="horizontal-line" />}
          <p className="sig-italic-label">Title</p>
          {merchantSignature?.serviceStartDate ? (
            <div className="sig-info-container">
              <p>{moment(merchantSignature.serviceStartDate).format('LL')}</p>
            </div>
          ) : <hr className="horizontal-line" />}
          <p className="sig-italic-label">Service Start Date</p>
        </div>
        <div style={{ width: '40%', display: 'inline-block', marginLeft: 32 }}>
          <p className="company-signature">{client}</p>
          {clientSignature?.signatureUrl
            ? (
              <div className="sig-image-container">
                <img src={clientSignature.signatureUrl} alt="Client Signature" />
              </div>
            ) : <hr className="horizontal-line" />
          }
          <p className="sig-italic-label">Signature</p>
          {clientSignature?.printedName ? (
            <div className="sig-info-container">
              <p>{clientSignature.printedName}</p>
            </div>
          ) : <hr className="horizontal-line" />}
          <p className="sig-italic-label">Print Name</p>
          {clientSignature?.printedTitle ? (
            <div className="sig-info-container">
              <p>{clientSignature.printedTitle}</p>
            </div>
          ) : <hr className="horizontal-line" />}
          <p className="sig-italic-label">Title</p>
          {clientSignature?.serviceStartDate ? (
            <div className="sig-info-container">
              <p>{moment(clientSignature.serviceStartDate).format('LL')}</p>
            </div>
          ) : <hr className="horizontal-line" />}
          <p className="sig-italic-label">Service Start Date</p>
        </div>
      </div>
    </div>
  )
}
