import React, { useState } from 'react'
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Alert,
  FormFeedback
} from 'reactstrap'
import DropdownList from 'react-widgets/lib/DropdownList'
import { MdDeleteForever } from 'react-icons/md'
import styled from 'styled-components'

const InputsContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

const initialErrors = {

  name: false,
  roomType: false,
  inputs: false,
  inputsMessage: 'test'

}



export const AddArea = ({
  modalOpen,
  toggleModal,
  roomTypes,
  submitData,
  dataToEdit,
  setRoomTypes
}) => {
  // console.log('dataToEdit', dataToEdit)
  const [inputCount, setInputCount] = useState(0)
  const [inputValues, setInputValues] = useState(dataToEdit?.tasks || [])
  const [nameValue, setNameValue] = useState(dataToEdit?.name || '')
  const [roomTypeValue, setRoomTypeValue] = useState(dataToEdit?.type || '')
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState(initialErrors)

  const [visibleAddRoom, setVisibleAddRoom] = useState(false)
  const [valueAddRoom, setValueAddRoom] = useState('');

  const onDismiss = () => setVisible(false);

  const handleAddInput = () => {
    setInputCount(inputCount + 1)
    setInputValues([...inputValues, ''])
  }

  const handleRemoveInput = index => {
    const newInputValues = [...inputValues]
    newInputValues.splice(index, 1)
    console.log(newInputValues)
    setInputCount(inputCount - 1)
    setInputValues(newInputValues)
  }

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues]
    newInputValues[index] = value
    setInputValues(newInputValues)
  }

  const renderInputs = () => {
    return inputValues.map((value, index) => {
      return (
        <InputsContainer key={index}>
          <Input
            type="text"
            value={value || ''}
            onChange={e => handleInputChange(index, e.target.value)}
          />
          <Button color="danger" onClick={() => handleRemoveInput(index)}>
            <MdDeleteForever />
          </Button>
        </InputsContainer>
      )
    })
  }

  const _handleSubmit = () => {

    const data = {
      name: nameValue,
      type: roomTypeValue,
      tasks: inputValues.filter(task => !!task)
    }

    let hasError = determinateInputError(data)

    if (hasError) return

    if (dataToEdit) {
      // dataToEdit.index = dataToEdit.index
      submitData(data, dataToEdit.index)
    } else {
      submitData(data)
    }
  }

  const determinateInputError = (data) => {

    setErrors({
      name: data.name == '',
      roomType: data.type == '',
      inputs: data.tasks.length == 0,
      inputsMessage: 'You need to add at least 1 task.'
    })

    if (data.name == '' || data.type == '' || data.tasks.length == 0) return true

    return false
  }

  const handleAddRoomType = () => {
    setVisibleAddRoom(true)
  }

  const handleKeyEvent = (e) => {
    if (e.key === 'Enter') {
      let newValuesRoomTypes = [...roomTypes, valueAddRoom]
      newValuesRoomTypes = [...new Set(newValuesRoomTypes)]

      setRoomTypes(newValuesRoomTypes)
      setVisibleAddRoom(false)
    }
  }

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {dataToEdit ? 'Edit' : 'Add New'} Area
        </ModalHeader>
        <ModalBody>
          <Col xs="12">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem'
              }}
            >
              <div>
                <span style={{ fontWeight: 800 }}>Name Area</span>
                <Input
                  id="name"
                  name="name"
                  placeholder="Area Name"
                  type="text"
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  invalid={errors.name}
                />
                <FormFeedback>
                  You need a name
                </FormFeedback>
              </div>

              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', flexDirection: 'column', paddingBottom: '1rem' }}>
                  <Col xs='12' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '0' }} >
                    <span style={{ fontWeight: 800 }}>Room Type</span>
                    {/* <Button color='link' onClick={handleAddRoomType} >+ add Room Type</Button> */}
                  </Col>
                  <Input type='test' style={{ display: `${visibleAddRoom ? '' : 'none'}` }} onKeyDown={handleKeyEvent} onChange={(e) => setValueAddRoom(e.target.value)}></Input>
                </div>
                <DropdownList
                  name="roomType"
                  data={roomTypes}
                  filter="contains"
                  value={roomTypeValue}
                  onChange={value => setRoomTypeValue(value)}
                />
                <span style={{ color: "#dc3545", display: `${errors.roomType ? '' : 'none'}`, fontSize: ' 0.875em' }} >You need to select a room type</span>
              </div>
              <hr />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline'
                }}
              >
                <span style={{ fontWeight: 800 }}>Tasks</span>
                <Button color="link" onClick={handleAddInput}>
                  + Add Task
                </Button>
              </div>
              {renderInputs()}
            </div>
            <span style={{ color: "#dc3545", display: `${errors.inputs ? '' : 'none'}`, fontSize: ' 0.875em' }} >{errors.inputsMessage}</span>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_handleSubmit}>
            {dataToEdit ? 'Update' : 'Add'} Area
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  )
}
