import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { Badge, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import DarkCard from './DarkCard'

const Day = styled.div`
  width: 96%;
  background-color: ${props => props.theme.colors.dark2};
  padding: 5px 14px;
  margin: 0 auto;
  border-radius: 5px;
`
const DescriptionLink = styled(Link)`
  padding: 4px 0 0 2px;
  color: white;
  &:hover {
    color: white;
  }
`

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 96%;
  margin: 0 auto;
  padding: 12px;
`

const Time = styled.div`
  flex: 1;
  color: #0b64eb;
  border-right: thin solid #555259;
`

const Job = styled.div`
  flex: 6;
  padding: 6px 0 6px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Schedule = ({ jobs }) => {
  const sevenDaysFromNow = []
  for (let i = 0; i < 7; i++) {
    sevenDaysFromNow.push(
      moment()
        .startOf('day')
        .add(i, 'days')
        .format('ddd MMM D')
    )
  }

  const groupByDate = _.groupBy(jobs, job =>
    moment(job.scheduledAt)
      .startOf('day')
      .format('ddd MMM D')
  )

  const DaysWithWork = Object.keys(groupByDate)

  const daySchedule = (day, id) => (
    <div key={id}>
      <Day>{day}</Day>
      {DaysWithWork.includes(day) ? (
        _.sortBy(groupByDate[day], job => job.scheduledAt).map((job, id) => (
          <ScheduleContainer
            key={id}
            style={{
              borderTopWidth: id > 0 ? 1 : 0,
              borderTopStyle: id > 0 ? 'solid' : 'none',
              borderTopColor: id > 0 ? '#404040' : 'transparent'
            }}
          >
            <Time>{moment(job.scheduledAt).format('h mm a')}</Time>
            <Job>
              <Badge
                color={job.type === 'Walkthrough' ? 'warning' : 'primary'}
                style={{
                  width: 100,
                  maxWidth: 100
                }}
              >
                {job.type}
              </Badge>
              <DescriptionLink
                to={`/dashboard/${
                  job.type === 'Walkthrough'
                    ? 'walkthroughs'
                    : job.type === 'Job'
                    ? 'services'
                    : 'inspections'
                }/${job._id}`}
              >
                {job.name
                  ? `${job.code}-${job.name} (${job.client.name})`
                  : `${job.code} (${job.client.name})`}
              </DescriptionLink>
            </Job>
          </ScheduleContainer>
        ))
      ) : (
        <ScheduleContainer key={id}>
          <Time style={{ color: 'white' }}>all day</Time>
          <Job style={{ color: '#999' }}>Day off</Job>
        </ScheduleContainer>
      )}
    </div>
  )

  const renderSchedule = () =>
    sevenDaysFromNow.map((day, id) => daySchedule(day, id))

  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <DarkCard>
        <CardBody>{renderSchedule()}</CardBody>
      </DarkCard>
    </div>
  )
}

export default Schedule
