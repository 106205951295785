import React from 'react'

// TODO: Baaaad!
import ServiceTemplateForm from '../../Library/ServiceTemplates/ServiceTemplate/ServiceTemplateForm'

export default ({ submitTemplate }) => {
  return (
    <div>
      <h2 style={{ paddingTop: 30, paddingLeft: 20 }}>New Category</h2>
      <div style={{ marginTop: 30, marginLeft: 20, marginRight: 20 }}>
        <ServiceTemplateForm onSubmit={submitTemplate} _id={null} />
      </div>
    </div>
  )
}
