import React, { useEffect } from 'react'
import styled from 'styled-components'

import Field from '../field'
import {
  defaultCommercialReview,
  educationMessages,
  frequencyConversionsByType
} from '../../data'
import { Button, Review, Tooltip } from '../ui'
import { calculateCommercialBid } from '../../utils'
import { InputState } from '../../../../../../hooks'
import { browserWindow } from '../../../../../../utils'

const capitalize = word =>
  typeof word === 'string' ? word.charAt(0).toUpperCase() + word.slice(1) : word

const calculatPricePerSqFt = (total, sqFt, billingFrequency) => {
  if (!total || !sqFt || !billingFrequency) { return }

  switch (billingFrequency) {
    case 'weekly':
      total = total * 4.33
      break;
  
    case 'biweekly':
      total = total * (4.33 / 2)
      break;

    case 'monthly':
      break;

    case 'quarterly':
      total = total / 3
      break;
  
    case 'yearly':
      total = total / 12
      break;
  
    default:
      break;
  }

  return total / sqFt
}

const EditButton = styled(Button)`
  background-color: transparent;
  color: #0b64eb;
  justify-content: flex-end;
  padding: 0;
  width: 50%;
`

const BreakdownContainer = styled.div`
  width: 45%;
  padding: 16px;
  background-color: rgb(178, 255, 0, 0.7);
  text-align: left;
  margin: auto;
  border-radius: 4px;
`

const BDList = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
`

const BDSpan = styled.span`
  float: right;
`

const Card = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto 0 auto;
  min-height: 6rem;
  justify-content: space-between;
  width: 100%;

  select {
    text-indent: 40%;
    width: 100%;
  }

  @media screen and (min-width: 32rem) {
    select {
      text-indent: 45%;
    }
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: center;
  width: 50%;
`

// const ContactInfo = styled.div`
//   margin-top: 1.5rem;
//   width: 100%;
// `

const Education = styled.div`
  align-items: center;
  background-color: rgb(17, 190, 96, 0.9);
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-style: italic;
  font-weight: 400;
  font-size: 12px
  justify-content: center;
  margin: 1rem auto 0 auto;
  padding: 1rem;
  text-align: center;
  width: 80%;
`

const BidText = styled.h1`
  display: block;
  font-size: 1.75rem;
  text-align: center;
`

const BidReviewContainer = styled.div`
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px;
  padding: 2rem;
  text-align: center;
  width: 100%;
`

const BidContainer = styled.div`
  text-align: center;
`

const BidPrice = styled.h2`
  color: #11be60;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
`

const StageTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
`

const StageResponse = styled.span`
  margin-top: 1.5rem;
  width: 100%;
`

// const DividerLine = styled.div`
//   width: 32px;
//   height: 4px;
//   background-color: #0a0a0a;
//   margin-top: 8px;
//   margin-bottom: 32px;
// `

export const CommercialReviewStage = ({
  bid,
  // contact,
  // current,
  fields,
  id,
  // onFinish,
  onResetService,
  onReviewUpdate,
  onStageChange,
  review
}) => {
  useEffect(() => {
    // console.log('scrolling...')
    browserWindow.scrollTo(0, 0)
  }, [])

  const isHourlyBid =
    bid.employees.employer === 'yes' && !bid.employees.useStateAverage

  const margin = 1 + review.margin / 100

  const isRecurring = bid.recurring === 'yes'

  const billingFrequency =
    typeof review.billingFrequency !== 'undefined'
      ? review.billingFrequency
      : defaultCommercialReview.billingFrequency

  const {
    total,
    costPerJob,
    hourlyRate,
    hoursPerJob,
    hourlyCostPerJob,
    adjustedProductionRate
  } = calculateCommercialBid(
    bid,
    billingFrequency,
    margin,
    isHourlyBid,
    isRecurring
  )

  const locationSize = bid.location && bid.location.size
  const pricePerSqFt = calculatPricePerSqFt(total, locationSize, billingFrequency)

  const reviewableFields = fields.map(
    ({ label, max, min, name, options, step, type }) => {
      if (type === 'range' && !isHourlyBid) {
        max = 30
        min = 0
        step = 5
      }

      let defaultValue = isRecurring
        ? frequencyConversionsByType[bid.frequency.unit].default
        : 1

      if (type === 'range') {
        defaultValue = isHourlyBid ? 25 : 15
      }

      const [value, setValue] = InputState(review[name] || defaultValue)

      const onChange = event => {
        setValue(event)
        onReviewUpdate({
          ...review,
          [event.target.name]: event.target.value
        })
      }

      if (name === 'total') {
        return false
      } else if (type === 'range') {
        return (
          <Field
            key={name}
            id={`${id}-${name}`}
            label={`${label}: ${value}%`}
            labelStyle={{
              fontSize: '1.25rem',
              fontWeight: 700,
              marginTop: '1rem'
            }}
            max={max}
            maxText={`High`}
            midText={`Standard`}
            min={min}
            minText={`Low`}
            name={name}
            onChange={onChange}
            step={step}
            style={{}}
            type={type}
            value={value}
          />
        )
      } else if (type === 'select') {
        if (isRecurring) {
          return (
            <Field
              key={name}
              id={`${id}-${name}`}
              label={label}
              labelStyle={{
                fontSize: '1.25rem',
                fontWeight: 700,
                marginTop: '1rem'
              }}
              max={max}
              maxText={`High`}
              midText={`Standard`}
              min={min}
              minText={`Low`}
              name={name}
              onChange={onChange}
              options={options}
              step={step}
              style={{}}
              type={type}
              value={value}
            />
          )
        } else {
          return false
        }
      } else {
        return (
          <Field
            key={name}
            id={`${id}-${name}`}
            label={label}
            labelStyle={{}}
            max={max}
            min={min}
            name={name}
            onChange={onChange}
            options={options}
            step={step}
            style={{}}
            type={type}
            value={value}
          />
        )
      }
    }
  )

  const formatNumber = number =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Review>
      <BidContainer>
        <BidText>You should charge</BidText>
        <BidPrice>${formatNumber(total)}</BidPrice>
        {isRecurring && <BidText>per {billingFrequency.slice(0, -2)}</BidText>}
        Your bid uses a pricing formula based on{' '}
        {isHourlyBid ? 'hourly rate' : 'square feet'}.
        <br />
        <br />
        {isHourlyBid &&
          `$${formatNumber(
            ((total / 1.03) * 0.03).toFixed(2)
          )} (3%) in supply costs have been included.`}
        <BreakdownContainer>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Tooltip size={'20'} margin={'0'} popoverWidth={700}>
              <ul>
                <li>
                  <strong>Estimated hours:</strong> This is the expected total man hours per shift
                  (the total number of hours required to clean per shift).
                </li>
                <li>
                  <strong>Hourly charge:</strong> This the charge amount per hour worked you would
                  bill the client for. 
                <li>
                 <strong>Production rate per hour:</strong> This is the
                  amount of square footage you can clean per hour. Higher numbers
                  = a faster cleaning rate.
                </li>
                </li>
                <li>
                  <strong>Cost per shift:</strong> This is the total amount it would cost you to
                  pay your employees per shift
                </li>
                <li>
                  <strong>Price per sq ft:</strong> If you charge based on a price per sq ft
                  cleaned, then this would be the rate.
                </li>
              </ul>
            </Tooltip>            
          </div>

          <BDList>
            <li>
              Estimated hours:
              <BDSpan>{formatNumber(hoursPerJob.toFixed(2))}</BDSpan>
            </li>
            <li>
              Hourly charge:
              <BDSpan>${formatNumber(hourlyCostPerJob.toFixed(2))}</BDSpan>
            </li>
            <li>
              Production rate per hour:
              <BDSpan>{formatNumber(adjustedProductionRate.toFixed(2))}</BDSpan>
            </li>
            <li>
              Cost per shift:
              <BDSpan>${formatNumber(costPerJob.toFixed(2))}</BDSpan>
            </li>
            <li>
              Price per square foot:
              <BDSpan>${formatNumber(pricePerSqFt.toFixed(2))}</BDSpan>
            </li>
          </BDList>
        </BreakdownContainer>
      </BidContainer>

      {reviewableFields}

      <BidReviewContainer>
        <BidText>Review your bid</BidText>
        {Object.keys(bid).map(item => {
          if (
            item === 'margin' ||
            item === 'billingFrequency' ||
            item === 'total' ||
            item === 'review' ||
            item === 'rooms' ||
            item === 'addons' ||
            item === 'weekly' ||
            item === 'biweekly' ||
            item === 'monthly' ||
            item === 'quarterly' ||
            item === 'yearly' ||
            item === 'onetime' ||
            item === 'deepclean' ||
            item === 'moveinout' ||
            item === 'commercial' ||
            item === 'residential' ||
            item === 'contact'
          ) {
            return false
          }

          if (item === 'employees') {
            // const { employer, rate } = bid[item]

            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>{capitalize(item)}:</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <StageResponse>{`$${hourlyRate} hourly rate`}</StageResponse>

                {isHourlyBid && (
                  <Education>{educationMessages[item]}</Education>
                )}
              </Card>
            )
          }

          if (item === 'floors') {
            const { percentHardFloors, percentSoftFloors } = bid[item]

            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>{capitalize(item)}:</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <StageResponse>Hard: {percentHardFloors}%</StageResponse>
                <br />
                <StageResponse>Soft: {percentSoftFloors}%</StageResponse>
                <br />
                <Education>{educationMessages[item]}</Education>
              </Card>
            )
          }

          if (item === 'frequency') {
            const { rate, unit } = bid[item]

            return (
              isRecurring && (
                <Card key={item}>
                  <CardHeader>
                    <StageTitle>{capitalize(item)}:</StageTitle>
                  </CardHeader>
                  <EditButton
                    onClick={() => onStageChange(item)}
                    type={'button'}
                  >
                    Edit
                  </EditButton>
                  <StageResponse>
                    {rate} times a {unit}
                  </StageResponse>
                  {!isHourlyBid && (
                    <Education>{educationMessages[item]}</Education>
                  )}
                </Card>
              )
            )
          }

          if (item === 'expectations') {
            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>{capitalize(item)}:</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <StageResponse>{capitalize(bid[item])}</StageResponse>
                <Education>{educationMessages[item]}</Education>
              </Card>
            )
          }

          if (item === 'location') {
            const { type, size } = bid[item]

            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>{capitalize(item)}:</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <StageResponse>
                  {type !== 'Other' ? type : ''} Facility, {size} sq ft
                </StageResponse>
                {bid.location.type === 'Healthcare' && (
                  <Education>{educationMessages[item]}</Education>
                )}
              </Card>
            )
          }

          if (item === 'place') {
            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>{capitalize(item)}:</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <StageResponse>{capitalize(bid[item])}</StageResponse>
                {!isHourlyBid && (
                  <Education>{educationMessages[item]}</Education>
                )}
              </Card>
            )
          }

          return (
            <Card key={item}>
              <CardHeader>
                <StageTitle>{capitalize(item)}:</StageTitle>
              </CardHeader>
              <EditButton
                onClick={() =>
                  item === 'service' ? onResetService() : onStageChange(item)
                }
                type={'button'}
              >
                Edit
              </EditButton>
              <StageResponse>{capitalize(bid[item])}</StageResponse>
            </Card>
          )
        })}
      </BidReviewContainer>
    </Review>
  )
}

export default CommercialReviewStage
