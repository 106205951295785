import React from 'react'
import styled from 'styled-components'
import { FiCheck } from 'react-icons/fi'

const LabelDiv = styled.div`
  position: relative;
  color: white;
  background-color: ${props => (props.color ? props.color : '#646464')};
  padding: 0 8px;
  border-radius: 4px;
  text-align: center;
  min-width: 64px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: default;
  font-size: 12px;
  ${props => (props.onClick ? `cursor: pointer` : null)};
`

const LabelCheck = styled(FiCheck)`
  position: absolute;
  right: 8px;
  top: 0;
  width: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = ({ checked, children, ...rest }) => {
  return (
    <LabelDiv {...rest}>
      {children}
      {checked ? <LabelCheck /> : null}
    </LabelDiv>
  )
}

export default Label
