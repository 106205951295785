import React, { useEffect, useState } from 'react';
import {
    Table,
    Badge,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { buttonStatesValues } from './utilsStatesValues';

import { RxDragHandleHorizontal } from "react-icons/rx";
import { GoPencil } from "react-icons/go";
import { FaMinus, FaXmark, FaCheck } from "react-icons/fa6";
import styled from 'styled-components'
import EditAreaModal from './EditAreaModal';

const DivRowContainer = styled.div`

    display : flex;
    column-gap : 1rem;
    min-height : 3rem;
    align-items : center;
`

const DragHandle = SortableHandle(() => <RxDragHandleHorizontal />);


const RowSortable = SortableElement(({ key, positionIndex, name, statusClean, tableMode, dropdownOpen, toggle, handleEditArea, handleCloneArea, handleDeleteArea }) => {

    const stateBadges = {

        0: () => <Badge color='danger'><FaXmark /></Badge>,
        1: () => <Badge color='success'><FaCheck /></Badge>,
        2: () => <Badge ><FaMinus /></Badge>,

    }

    return (
        <tr>
            <td>
                <DivRowContainer>
                    {tableMode === buttonStatesValues.ADD && <DragHandle />}
                    {name}
                </DivRowContainer>

            </td>


            <td>
                <DivRowContainer style={{ justifyContent: 'center' }}>
                    {tableMode === buttonStatesValues.EDIT && stateBadges[statusClean]()}

                    {tableMode === buttonStatesValues.ADD &&
                        <Dropdown key={key} isOpen={dropdownOpen[positionIndex]} toggle={() => toggle(positionIndex)}>
                            <DropdownToggle color='link'><GoPencil /></DropdownToggle>
                            <DropdownMenu >
                                <DropdownItem onClick={() => handleEditArea(positionIndex)}>Edit</DropdownItem>
                                <DropdownItem onClick={() => handleCloneArea(positionIndex)}>Clone</DropdownItem>
                                <DropdownItem onClick={() => handleDeleteArea(positionIndex)} style={{ color: '#c82333' }}>Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>}
                </DivRowContainer>

            </td>
        </tr>
    )
}
);


const BodySortable = SortableContainer(({ children }) => {
    return (
        <tbody>
            {children}
        </tbody>
    );
});




export const TableDnD = ({ tableMode, inspectionAreas, setInspectionAreas, addNewArea, setAddNewArea }) => {

    const [items, setItems] = useState([]);

    const [dropdownStates, setDropdownStates] = useState(new Array(inspectionAreas.length).fill(false));

    const [modal, setModal] = useState(false);

    const [dataToEdit, setDataToEdit] = useState(null)

    const [indexToEdit, setIndexToEdit] = useState(null)

    const toggleModal = () => setModal(!modal);

    const toggleDropdown = (index) => {
        const newDropdownStates = [...dropdownStates];
        newDropdownStates[index] = !newDropdownStates[index];
        setDropdownStates(newDropdownStates);
    };

    useEffect(() => {

        setItems(inspectionAreas)
    }, [])

    useEffect(() => {

        setItems(inspectionAreas)

    }, [inspectionAreas])

    useEffect(() => {

        if (addNewArea) {
            toggleModal()
            setDataToEdit(null)
            setAddNewArea(false)
        }

    }, [addNewArea])


    // Función para reordenar las filas
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems((prevItems) => {
            const newItems = [...prevItems];
            const [removed] = newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, removed);
            return newItems;
        });
    };

    const handleEditArea = (positionIndex) => {
        setDataToEdit(items[positionIndex])
        setIndexToEdit(positionIndex)
        toggleModal()
    }

    const submitArea = (newArea, indexToEdit) => {

        /* quizas aqui necesitamos el setInspection areas para salvar los cambios en el 
        componente principal para el reenvio al servicio*/
        const newItems = items.map((item, index) => index === indexToEdit ? newArea : item)
        setInspectionAreas(newItems)
        setDataToEdit(null)
        toggleModal()
    }

    const handleAddArea = (newArea) => {

        //deep clone
        let areaStructure = structuredClone(items[0])

        delete areaStructure._id

        areaStructure.name = newArea.name
        areaStructure.percent = 0
        areaStructure.statusClean = 2
        areaStructure.type = newArea.type
        areaStructure.tasks = []

        areaStructure.tasks = newArea.tasks.map(task => (
            {
                name: task,
                status: 2

            }
        ))


        let newItems = [...items, areaStructure]

        setInspectionAreas(newItems)
        toggleModal()

    }

    const handleCloneArea = (positionIndex) => {

        const { _id, ...rest } = items[positionIndex]
        setInspectionAreas([...items, rest])

    }

    const handleDeleteArea = (positionIndex) => {

        let newItems = [...items]

        newItems = newItems.toSpliced(positionIndex, 1)

        setInspectionAreas(newItems)
    }

    return (
        <>
            <Table>
                <tr>
                    <th>Name</th>
                    <th style={{ textAlign: 'center' }}>{tableMode === buttonStatesValues.EDIT ? 'Status' : 'Actions'}</th>
                </tr>
                <BodySortable onSortEnd={onSortEnd} useDragHandle={true} >
                    {
                        items.map((value, index) => {
                            const { name, statusClean } = value

                            return <RowSortable key={`item-${index}`}
                                index={index}
                                positionIndex={index}
                                name={name}
                                statusClean={statusClean}
                                tableMode={tableMode}
                                dropdownOpen={dropdownStates}
                                handleEditArea={handleEditArea}
                                handleCloneArea={handleCloneArea}
                                handleDeleteArea={handleDeleteArea}
                                toggle={toggleDropdown} />
                        }
                        )
                    }
                </BodySortable >
            </Table>
            {modal && <EditAreaModal modal={modal} toggle={toggleModal} dataToEdit={dataToEdit} submitArea={submitArea} handleAddArea={handleAddArea} indexToEdit={indexToEdit} />}
        </>
    )

}
