import React from 'react'
import { Input } from 'reactstrap'

const renderError = error =>
  error && <span className="small text-danger">{error}</span>
const renderWarning = warning =>
  warning && <span className="small text-warning">{warning}</span>

const InplaceInputField = ({
  input,
  header,
  type,
  children,
  placeholder,
  readOnly,
  handleBlur,
  autoComplete,
  style,
  meta: { touched, error, warning }
}) => {
  const headerStyles = header ? { fontSize: 20, fontWeight: 500 } : {}
  return (
    <>
      <Input
        placeholder={placeholder}
        {...input}
        readOnly={readOnly}
        type={type}
        rows={String(type === 'textarea' && '4')}
        onBlur={handleBlur}
        autoComplete={autoComplete}
        style={{
          resize: 'vertical',
          border: 'none',
          color: '#212529',
          paddingLeft: 6,
          ...headerStyles,
          ...style
        }}
      >
        {children}
      </Input>
      {touched && (renderError(error) || renderWarning(warning))}
    </>
  )
}

export default InplaceInputField
