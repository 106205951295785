import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import qs from 'query-string'

import withRouterAndRef from 'utils/withRouterAndRef'

import Empty from './Empty'
import MaterialSpinner from './MaterialSpinner'
import TableView from './TableView'

import { axiosClient } from 'store'

const BaseContainer = styled.div`
  min-height: 100%;
`

class DataScreen extends React.Component {
  state = {
    firstFetched: false,
    fetching: false,
    sortBy: null,
    sortAsc: null,
    limit: 20
  }

  _determineInitialPage = () => {
    const { location, usePages, useQueryForPages } = this.props

    let initialPage = 1

    if (useQueryForPages && usePages && location && location.search) {
      const query = qs.parse(location.search)
      if (query.page) {
        initialPage = query.page
      }
    }

    return initialPage
  }

  componentDidMount() {
    const { url } = this.props

    let initialPage = this._determineInitialPage()

    if (url) {
      this._fetch(initialPage)
    }
  }

  componentDidUpdate(prevProps) {
    const { location, useQueryForPages } = this.props

    // If the user changes the url:
    if (prevProps.url !== this.props.url) {
      this._fetch()
    }

    // When usePages property is toggled.
    if (prevProps.usePages !== this.props.usePages) {
      // When using pages, do a default fetch.
      if (this.props.usePages) {
        this._fetch()
      } // When not using pages
      else {
        // Replace the browser location with the default path (no query params).
        if (useQueryForPages) {
          this.props.history.push(`${location.pathname}`)
        }
        // Fetch the fist page.
        this._fetch(this._determineInitialPage())
      }
    }

    //If the user changes filters
    if (prevProps.filterData !== this.props.filterData) {
      this._fetch()
    }
  }

  _setSort = sort => {
    this.setState(
      {
        sortBy: sort.sortBy,
        sortAsc: sort.sortAsc,
        sortIndex: sort.sortIndex
      },
      () => {
        this._fetch()
      }
    )
  }

  _setLimit = limit => {
    const { pagination } = this.state
    let page = pagination.page
    const newTotalPages = Math.ceil(pagination.totalDocs / limit)
    if (page > newTotalPages) {
      page = newTotalPages
    }

    this.setState(
      {
        limit,
        pagination: {
          ...pagination,
          page
        }
      },
      () => {
        this._fetch()
      }
    )
  }

  _fetch = async page => {
    const { firstFetched, sortBy, sortAsc, limit } = this.state
    const { filterData } = this.props

    this.setState({
      fetching: true
    })

    let params = {}

    params.page =
      page ||
      (this.state.pagination
        ? this.state.pagination.page
        : this._determineInitialPage())

    if (sortBy) {
      params.sortBy = sortBy
      params.sortAsc = sortAsc
    }

    if (limit) {
      params.limit = limit
    }

    if (filterData) {
      params.filters = filterData
    }

    try {
      const response = await axiosClient.get(this.props.url, { params })
      const { data } = response
      const { data: items, pagination } = data

      this.setState({
        fetching: false,
        firstFetched: true,
        data: items,
        pagination: pagination,
        hideLoad: !firstFetched && items && items.length >= pagination.totalDocs
      })
    } catch (ex) {
      console.error('Error in DataScreen fetch', ex)
      this.setState({
        fetching: false,
        firstFetched: true
      })
    }
  }

  _onPageClick = page => {
    const { location } = this.props
    this.props.history.push(`${location.pathname}?page=${page}`)
    this._fetch(page)
  }

  _fetchMore = async () => {
    const { data } = this.state
    if (!data || !data.length) {
      return
    }

    this.setState({
      fetching: true
    })

    try {
      const res = await axiosClient.get(this.props.url, {
        params: {
          offset: data.length
        }
      })

      if (res && res.data) {
        this.setState({
          fetching: false,
          firstFetched: true,
          data: [...data, ...res.data.data],
          pagination: res.data.pagination,
          hideLoad:
            data.length + res.data.data.length >= res.data.pagination.totalDocs
              ? true
              : false
        })
      }
    } catch (ex) {
      console.error('Error in DataScreen fetch', ex)
      this.setState({
        fetching: false,
        firstFetched: true
      })
    }
  }

  forceFetch() {
    this._fetch()
  }

  render() {
    const {
      fetching,
      firstFetched,
      data,
      pagination,
      hideLoad,
      sortBy,
      sortAsc,
      sortIndex,
      limit
    } = this.state
    const {
      entity,
      columns,
      component,
      hideSpinner,
      hideBaseContainer,
      hideEmpty
    } = this.props

    let content = null
    if (fetching) {
      if (!hideSpinner) {
        return (
          <BaseContainer
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <MaterialSpinner />
          </BaseContainer>
        )
      } else {
        return null
      }
    }

    if (!hideEmpty && firstFetched && (!data || !data.length)) {
      const plural = entity.toLowerCase() + 's'
      content = <Empty message={`No ${plural} found.`} />
    } else if (hideEmpty && component && (!data || !data.length)) {
      content = component
    }

    if (data && data.length) {
      if (component) {
        content = cloneElement(component, {
          data,
          loadFunction: !hideLoad ? this._fetchMore : null
        })
      } else {
        content = (
          <TableView
            data={data}
            columns={columns}
            pagination={pagination}
            onRowClick={this.props.onRowClick}
            onPageClick={this._onPageClick}
            setSort={this._setSort}
            sortBy={sortBy}
            sortIndex={sortIndex}
            sortAsc={sortAsc}
            setLimit={this._setLimit}
            limit={limit}
          />
        )
      }
    }

    let finalRender = null
    if (hideBaseContainer) {
      finalRender = content
    } else {
      finalRender = <BaseContainer>{content}</BaseContainer>
    }

    return finalRender
  }
}

DataScreen.defaultProps = {
  usePages: true,
  useQueryForPages: true,
  columns: []
}

DataScreen.propTypes = {
  entity: PropTypes.string.isRequired, // The name of the entity which this screen presents.
  url: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  component: PropTypes.node,
  usePages: PropTypes.bool,
  useQueryForPages: PropTypes.bool
}

export default withRouterAndRef(DataScreen)