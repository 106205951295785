import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import SelectClientLocation from 'screens/Dashboard/components/forms/SelectClientLocation'

class SelectLocationForm extends Component {

  render() {
    const { showModal, toggle, headerTitle, ...rest } = this.props

    return (
      <Modal
        isOpen={showModal}
        toggle={toggle}
      >
        <ModalHeader>
          {headerTitle || 'Select Location'}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={this.props.handleSubmit}>
            <SelectClientLocation
              formName="SelectLocation"
              goBack={toggle}
              {...rest}
            />
          </form>
        </ModalBody>
      </Modal>
    )
  }
}

export default reduxForm({ form: 'SelectLocation' })(SelectLocationForm)
