import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Prompt } from 'react-router'
import EstimatorForm, {
  serviceDefaultValues,
  pullWalkthroughData
} from './EstimatorForm'
import { toast } from 'react-toastify'
import networkErrorToast from 'utils/networkErrorToast'
import {
  Row,
  Col,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { FiTag } from 'react-icons/fi'
import { getFormValues, isDirty, initialize } from 'redux-form'
import _ from 'lodash'
import { capitalize } from 'utils'
import styled, { withTheme } from 'styled-components'
import {
  createEstimate,
  updateEstimate,
  cloneEstimate,
  getEstimate,
  getWalkthrough,
  getServiceTemplates,
  getServiceTemplate,
  getClient,
  getLocation,
  archiveEstimate,
  createProposal
} from 'api'
import { connectConfirmation } from 'components/confirm'
import { ActionButtons } from 'components/lib/Button'
import InplaceForm from 'components/InplaceForm'
import CloneForm from '../../components/forms/CloneForm'
import LevelContainer from 'components/LevelContainer'
import EstimateResults from './EstimateResults'
import CalculateEstimate from './CalculateEstimate'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import { trackEvent } from 'api/clientHub'
import { USER_CREATE_ESTIMATE_SUCCESS } from 'store/analyticsActions/types/estimatesTypes'

const SpanLink = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const ActionBar = styled(LevelContainer)`
  background-color: ${props => props.theme.colors.dark1};
  width: 100%;
  padding: 8px 16px;
`

class Estimator extends Component {
  state = {
    ready: false,
    initialValues: {
      services: []
    },
    serviceIndex: 0,
    showCloneModal: false
  }

  async UNSAFE_componentWillMount() {
    await this.props.getServiceTemplates()

    if (
      (this?.props?.match?.params?.estimateId) ||
      this.state.estimateId
    ) {
      const estimateId =
        this.props.match.params.estimateId || this.state.estimateId
      const estimateRes = await this.props.getEstimate(estimateId)
      if (estimateRes?.payload?.data) {
        this.setState({
          estimateId: estimateRes.payload.data._id,
          walkthrough: estimateRes.payload.data.job,
          client: estimateRes.payload.data.client,
          location: estimateRes.payload.data.location,
          data: estimateRes.payload.data,
          ready: true
        })
      }
    } else if (this?.props?.history?.location?.state) {
      const hls = this.props.history.location.state

      let walkthrough
      if (hls.jobId) {
        const walkthroughRes = await this.props.getWalkthrough(hls.jobId)
        walkthrough = walkthroughRes.payload.data
        this.setState({
          walkthrough: walkthrough,
          client: walkthrough.client,
          location: walkthrough.location
        })
      }

      if (hls.clientId) {
        const clientRes = await this.props.getClient(hls.clientId)
        let client = clientRes.payload.data
        let selectedLocation = _.find(
          client.locations,
          location => location._id === hls.locationId
        )
        this.setState({
          client: client,
          location: selectedLocation
        })
      }

      if (hls.services) {
        const newData = hls.services.map(service => {
          const templateData = this.props.services.find(
            s => s._id === service.service
          )

          return {
            service: templateData,
            data: {
              ...serviceDefaultValues,
              ...service.data,

              billingMethod: templateData
                ? templateData.chargeType
                : serviceDefaultValues.billingMethod,
              ...pullWalkthroughData(walkthrough)
            }
          }
        })

        this.setState({
          initialValues: {
            services: newData
          }
        })
      }

      this.setState({
        ready: true
      })
    } else {
      this.setState({
        ready: true
      })
    }
  }

  componentDidUpdate() {
    const { dirty } = this.props
    if (dirty) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  calculateSingleService = values => {
    return CalculateEstimate(values)
  }

  calculateAll = values => {
    if (!values?.services?.length) {
      console.warn('There are no services in this estimate..')
      return values
    }

    let results = {
      ...values
    }

    results.services.forEach(service => {
      const result = this.calculateSingleService(service.data)
      if (result) {
        service.result = result
      }
    })

    return results
  }

  saveEstimate = async callback => {
    const { values } = this.props
    const { name } = this.state
    let messages = []

    if (values?.services?.length) {
      values.services.forEach((o, index) => {
        if (!o.service) {
          messages.push(`Select a service for service ${index + 1}.`)
        }
      })
    } else {
      return toast('Estimate needs some data.')
    }

    if (messages && messages.length) {
      messages.forEach(message => {
        toast(message)
      })
      return
    } else {
      let final = this.calculateAll(values)

      if (this.state.estimateId) {
        this._updateEstimate(final)
        if (callback) {
          callback()
        }
        return
      }

      let newEstimate = {
        merchant: this?.props?.merchant?._id,
        job: this?.state?.walkthrough?._id,
        labels: this?.state?.walkthrough?.labels,
        client: this?.state?.client?._id || this?.state?.walkthrough?.client,
        location: this?.state?.location?._id || this?.state?.walkthrough?.location,
        name,
        ...final
      }

      const res = await this.props.createEstimate(newEstimate)
      if (res.error) {
        toast('Estimate could not be saved.')
        return
      }
      if (res && res.payload && res.payload.data) {
        toast('Estimate saved.')
        let { _id, job } = res.payload.data

        trackEvent({
          typeEvent: USER_CREATE_ESTIMATE_SUCCESS,
          params: {
            ID: _id,
            Walkthrough: job
          }
        })

        this.setState(
          {
            estimateId: res.payload.data._id,
            data: res.payload.data
          },
          () => {
            this.props.initialize('Estimator', values)

            setTimeout(() => {
              this.props.history.push(
                `/dashboard/edit-estimate/${res.payload.data._id}`
              )
              if (callback) {
                callback()
              }
            }, 100)
          }
        )
      }
    }
  }

  _updateEstimate = async values => {
    const { estimateId, name } = this.state
    const estimate = {
      ...values,
      _id: estimateId,
      name
    }

    const res = await this.props.updateEstimate(estimate)
    if (res.error) {
      toast('Estimate could not be updated.')
      return
    }
    if (res?.payload?.data) {
      this.setState({ data: res.payload.data }, () =>
        this.props.initialize('Estimator', values)
      )
      toast('Estimate updated.')
    }
  }

  _onChangeName = name => {
    this.setState({
      name
    })
  }

  _cloneEstimate = async values => {
    const { cloneEstimate, history } = this.props
    const res = await cloneEstimate(values)
    if (res.error) {
      toast('Estimate could not be cloned.')
      return
    }
    if (res?.payload?.data) {
      this.setState({
        showCloneModal: false,
      }, () => {
        toast('Estimate cloned.')
        window.open(`/dashboard/edit-estimate/${res?.payload?.data?._id}`)
      })
    }
  }

  _createProposal = async () => {
    const { walkthrough, client, location, estimateId } = this.state
    const { values, services, merchant } = this.props

    if (!values || (!walkthrough && !client)) {
      return
    }

    let estimateResults = []
    if (values?.services?.length) {
      values.services.forEach((service, index) => {
        if (service.result) {
          const serviceTemplate = _.find(
            services,
            sTemplate =>
              sTemplate._id === service.service._id || service.service
          )
          const sTemplateName = serviceTemplate && serviceTemplate.name
          estimateResults.push({
            name: capitalize(sTemplateName),
            id: `service${index}`,
            showService: true,
            interval: service.result.jobCount.interval,
            charges: [
              {
                name: 'Base Rate',
                price: service.result.charges.baseRate
              },
              {
                name: 'Supplies and Materials',
                price: service.result.charges.suppliesAndMaterials
              }
            ]
          })
        }
      })
    }

    const proposal = {
      estimate: values._id || estimateId,
      job: walkthrough,
      client: client || (walkthrough?.client),
      location: location || (walkthrough?.location),
      merchant: merchant || (walkthrough?.merchant),
      labels: values.labels
    }

    const result = await this.props.createProposal(proposal)
    if (result.error) {
      return networkErrorToast(result, 'Unable to Create Proposal')
    }

    if (result.payload && result.payload.data) {
      this.props.history.push(
        `/dashboard/edit-proposal/${result.payload.data._id}`,
        {
          proposal: result.payload.data,
          estimateResults
        }
      )
    }
    toast('Proposal Created')
  }

  onTrash = async () => {
    const { estimateId } = this.state
    if (!estimateId) {
      return null
    }
    await this.props.archiveEstimate(estimateId)
    this.props.history.push('/dashboard/estimates')
  }

  _switchService = newIndex => {
    const { serviceIndex } = this.state
    if (serviceIndex === newIndex) {
      return
    }

    this.setState({
      serviceIndex: newIndex
    })
  }

  render() {
    const {
      ready,
      data,
      walkthrough,
      client,
      location,
      //      service,
      initialValues,
      estimateId,
      serviceIndex,
      showCloneModal
    } = this.state
    const { dirty, values, services, theme } = this.props
    if (!ready) {
      return null
    }

    const activeService = values?.services?.length && values.services[serviceIndex]
    const result = this.calculateSingleService(
      activeService ? activeService.data : null
    )

    const clientName = client?.name || walkthrough?.client?.name
    const locationAddress = location?.address?.fullAddress || walkthrough?.location?.address?.fullAddress

    const title = (
      <>
        <div style={{ marginRight: 8, color: 'white' }}>
          Estimate {(data?.code || 'Draft') + ' - '}
        </div>

        <InplaceForm
          initialValues={{ name: data?.name }}
          enableReinitialize
          onSubmit={values => this._onChangeName(values.name)}
          autoComplete="off"
          style={{
            backgroundColor: theme.colors ? theme.colors.dark0 : '#2E2F31',
            color: 'white'
          }}
        />
        <div style={{ color: 'white', marginLeft: 8 }}>
          {clientName && (
            <SpanLink
              onClick={() =>
                this.props.history.push(
                  `/dashboard/client-details/${client._id}`
                )
              }
            >
              {clientName}
            </SpanLink>
          )}
          {locationAddress && ' at '}
          {locationAddress && (
            <SpanLink
              onClick={() =>
                this.props.history.push(
                  `/dashboard/location-details/${location._id}`
                )
              }
            >
              {locationAddress}
            </SpanLink>
          )}
          {walkthrough && (
            <div style={{ color: 'white' }}>
              <SpanLink
                onClick={() =>
                  this.props.history.push(
                    `/dashboard/walkthroughs/${walkthrough._id}`
                  )
                }
              >
                {`Walkthrough - ${walkthrough.code}${walkthrough.name ? ' ' + walkthrough.name : ''
                  }`}
              </SpanLink>
            </div>
          )}
        </div>
      </>
    )

    const actionBar = (
      <ActionBar className="sticky-top">
        <div style={{ display: 'flex', alignItems: 'center' }}>{title}</div>

        <ActionButtons
          buttons={[
            ...(estimateId
              ? [
                {
                  component: (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        block
                        style={{ textAlign: 'left' }}
                        color="secondary"
                      >
                        <FiTag /> Labels
                      </DropdownToggle>
                      <DropdownMenu right={true}>
                        <LabelEditor
                          data={data.labels}
                          onChange={async data => {
                            const res = await this.props.updateEstimate({
                              _id: estimateId,
                              labels: data
                            })
                            this.setState({ data: res.payload.data })
                          }}
                          refreshEntity={async () => {
                            const res = await this.props.getEstimate(
                              estimateId
                            )
                            if (res && res.payload && res.payload.data) {
                              this.setState({
                                data: res.payload.data
                              })
                            }
                          }}
                        />
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )
                }
              ]
              : []),
            {
              title: 'Save Estimate',
              onClick: () => this.saveEstimate()
            }
          ]}
          options={[
            ...(walkthrough || client
              ? [
                {
                  title: 'New Proposal',
                  onClick: () => {
                    this.saveEstimate(this._createProposal)
                  }
                }
              ]
              : []),
            ...(estimateId
              ? [
                {
                  title: 'Clone Estimate',
                  onClick: () => this.setState({ showCloneModal: true })
                },
                {
                  title: 'Archive Estimate',
                  divide: true,
                  color: 'danger',
                  onClick: () =>
                    this.props.confirm(
                      'Confirm Archive',
                      'Are you sure you want to archive this estimate?',
                      this.onTrash
                    )
                }
              ]
              : [])
          ]}
        />
      </ActionBar>
    )

    return (
      <>
        {data?._id && (
          <CloneForm
            open={showCloneModal}
            toggle={() => this.setState({ showCloneModal: false })}
            header={`Clone Estimate ${data?.code}`}
            onSubmit={this._cloneEstimate}
            initialValues={{
              estimate: data._id,
              client: data.client?._id,
              location: data.location?._id
            }}
          />
        )}
        {actionBar}
        <div
          style={{ display: 'flex', flexDirection: 'row', padding: '0px 16px' }}
        >
          {data?.labels?.length > 0 &&
            data?.labels?.map(label => {
              return (
                <Label
                  key={label._id}
                  color={label.color}
                  style={{ marginRight: 8 }}
                >
                  {label.name}
                </Label>
              )
            })}
        </div>
        <Prompt
          when={dirty}
          message="Are you sure you want to leave the estimator without saving?"
        />
        <div
          className="container-fluid"
          style={{ minWidth: 1024, marginTop: 16 }}
        >
          <Row>
            <Col md={{ size: 7, order: 0 }} sm={{ size: 12, order: 1 }}>
              <EstimatorForm
                initialValues={data ? data : initialValues}
                onSubmit={this.saveEstimate}
                services={services}
                walkthrough={walkthrough}
                switchService={this._switchService}
                serviceIndex={serviceIndex}
              />
            </Col>
            <Col sm="12" md="5">
              <div>
                <EstimateResults values={activeService} result={result} />
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  values: getFormValues('Estimator')(state),
  services: state.library.services,
  merchant: state.user.currentCompany,
  dirty: isDirty('Estimator')(state)
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      createEstimate,
      updateEstimate,
      cloneEstimate,
      getEstimate,
      getWalkthrough,
      getServiceTemplate,
      getServiceTemplates,
      getClient,
      getLocation,
      initialize,
      archiveEstimate,
      createProposal
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectConfirmation(withTheme(Estimator)))
