export const rooms = [
  {
    name: 'bedrooms',
    label: 'Bedrooms',
    pattern: '[0-9]',
    rate: 15,
    required: true,
    step: 1,
    type: 'number'
  },
  {
    name: 'bathrooms',
    label: 'Bathrooms',
    pattern: '[0-9]',
    rate: 20,
    required: true,
    step: 0.5,
    type: 'number'
  },
  {
    name: 'kitchens',
    label: 'Kitchens',
    pattern: '[0-9]',
    rate: 15,
    required: true,
    step: 1,
    type: 'number'
  }
]

export default rooms
