import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col, Button } from 'reactstrap'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import networkErrorToast from 'utils/networkErrorToast'
import * as actions from 'api'
import createProposal from 'assets/img/create-proposal.svg'
import NewProposalSetupForm from './NewProposalSetupForm'
import _ from 'lodash'
import { trackEvent } from 'api/clientHub'
import { BID_PROPOSAL_CREATE_PROPOSAL } from 'store/analyticsActions/types/proposalTypes'

const StyledImg = styled.img`
  width: 100%;
`

class NewProposalSetup extends Component {
  state = {
    selectedField: null,
    submitBusy: false
  }

  async componentDidMount() {
    await this.props.getClients()
    await this.props.getWalkthroughs()
    await this.props.getEstimates()
  }

  _onSubmit = async values => {
    const { submitBusy } = this.state
    const { currentCompany } = this.props

    console.log("ingreso")

    console.log(values)


    if (!values || submitBusy) {
      return null
    }



    if (values.client && values.location) {
      this.setState({ submitBusy: true })
      const client = this.props.clients.find(
        client => client._id === values.client
      )
      const location = client.locations.find(
        location => location._id === values.location
      )

      let proposal = {}
      proposal.merchant = currentCompany._id
      proposal.client = client._id
      proposal.location = location._id

      const result = await this.props.createProposal(proposal)

      if (result.error) {
        this.setState({ submitBusy: false })
        return networkErrorToast(result, 'Unable to Create Proposal')
      }

      if (result.payload && result.payload.data) {
        trackEvent({ typeEvent: BID_PROPOSAL_CREATE_PROPOSAL, params: { id: result.payload.data._id } })
        this.setState({
          proposal: result.payload.data
        })
        // this way initial form data will be missing
        this.props.history.push(
          `/dashboard/edit-proposal/${result.payload.data._id}`
        )
      }
      toast('Proposal Created')
    }


    if (values.job) {
      let proposal = {}
      const job = await this.props.getJob(values.job)
      if (job && job.payload && job.payload.data) {
        proposal.job = job.payload.data
        proposal.client = job.payload.data.client
        proposal.location = job.payload.data.location
      }

      proposal.merchant = currentCompany._id

      const result = await this.props.createProposal(proposal)
      if (result.error) {
        this.setState({ submitBusy: false })
        return networkErrorToast(result, 'Unable to Create Proposal')
      }

      if (result.payload && result.payload.data) {
        trackEvent({ typeEvent: BID_PROPOSAL_CREATE_PROPOSAL, params: { id: result.payload.data._id } })
        this.setState({
          proposal: result.payload.data
        })
        this.props.history.push(
          `/dashboard/edit-proposal/${result.payload.data._id}`
        )
      }
      toast('Proposal Created')
    }

    if (values.estimate) {
      let proposal = {}

      proposal.estimate = values.estimate._id
      proposal.job = values.estimate.job
      proposal.client = values.estimate.client._id
      proposal.location = values.estimate.location._id

      proposal.merchant = currentCompany._id

      const result = await this.props.createProposal(proposal)
      if (result.error) {
        this.setState({ submitBusy: false })
        return networkErrorToast(result, 'Unable to Create Proposal')
      }

      if (result.payload && result.payload.data) {
        trackEvent({ typeEvent: BID_PROPOSAL_CREATE_PROPOSAL, params: { ID: result.payload.data._id } })
        this.setState({
          proposal: result.payload.data
        })
        this.props.history.push(
          `/dashboard/edit-proposal/${result.payload.data._id}`
        )
      }
      toast('Proposal Created')
    }
  }

  render() {
    const { clients, estimates } = this.props
    const { selectedField } = this.state

    const estimatesWithClients = _.filter(estimates, estimate => !!estimate.client)

    return (
      <div style={{ padding: 100 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'middle',
            flex: 1
          }}
        >
          <Card
            body
            style={{
              maxWidth: 800,
              paddingLeft: 50,
              paddingTop: 40,
              marginRight: -75,
              marginLeft: -75
            }}
          >
            <h3 className="text-dark">Create Proposal</h3>

            <Row style={{ marginTop: 30 }}>
              <Col xs="12" md="7" style={{ paddingTop: 10 }}>
                {!selectedField && (
                  <>
                    <Button
                      block
                      type="submit"
                      color="info"
                      style={{ marginBottom: 24 }}
                      onClick={() =>
                        this.setState({ selectedField: 'estimate' })
                      }
                    >
                      Use an estimate
                    </Button>
                    <h6 style={{ textAlign: 'center' }}> - OR - </h6>
                    <Button
                      block
                      type="submit"
                      color="info"
                      style={{ marginTop: 24, marginBottom: 24 }}
                      onClick={() =>
                        this.setState({ selectedField: 'walkthrough' })
                      }
                    >
                      Use a completed walkthrough
                    </Button>
                    <h6 style={{ textAlign: 'center' }}> - OR - </h6>
                    <Button
                      block
                      type="submit"
                      color="info"
                      style={{ marginTop: 24 }}
                      onClick={() => this.setState({ selectedField: 'client' })}
                    >
                      Select a client location
                    </Button>
                  </>
                )}
                {selectedField && (
                  <NewProposalSetupForm
                    clients={clients}
                    estimates={estimatesWithClients}
                    onSubmit={this._onSubmit}
                    selectedField={this.state.selectedField}
                    goBack={() => this.setState({ selectedField: null })}
                  />
                )}
              </Col>
              <Col xs="12" md="5">
                <StyledImg
                  src={createProposal}
                  alt="create proposal"
                  style={{ padding: 16 }}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    clients: state.clients.clients,
    currentCompany: state.user.currentCompany,
    estimates: state.estimates.estimates
  }),
  actions
)(NewProposalSetup)

