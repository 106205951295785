import React, { useState } from 'react'
import { DateTimePicker } from 'react-widgets'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import Label from './knobs/Label'
import Boolean from './knobs/Boolean'
import Text from './knobs/Text'

export default ({ element, onChange }) => {
  const [startOpen, setStartOpen] = useState(false)
  const [endOpen, setEndOpen] = useState(false)

  return (
    <>
      <Boolean
        label="Show Heading"
        data={element.data}
        field="showHeading"
        onChange={onChange}
      />
      {element.data && element.data.showHeading && (
        <Text
          placeholder="Service Dates"
          field="headingText"
          data={element.data}
          onChange={onChange}
        />
      )}
      <Label text="Start Date" />
      <DateTimePicker
        open={startOpen}
        onFocus={() => setStartOpen('date')}
        onBlur={() => setStartOpen(false)}
        style={{ marginBottom: 16 }}
        time={false}
        format={'LL'}
        value={moment(element.data.startDate).toDate()}
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault()
        }}
        onSelect={data => {
          let newData = {
            ...element.data,
            startDate: data
          }

          if (!newData.endDate || (newData.endDate < data)) {
            newData.endDate = data
          }

          return onChange(newData)
        }}
      />
      {element && element.data && element.data.startDate ? (
        <>
          <Label text="End Date" />
          <DateTimePicker
            open={endOpen}
            onFocus={() => setEndOpen('date')}
            onBlur={() => setEndOpen(false)}
            format={'LL'}
            value={moment(element.data.endDate).toDate()}
            time={false}
            onKeyPress={e => {
              if (e.key === 'Enter') e.preventDefault()
            }}
            onSelect={data => {
              if (!(data >= moment(element.data.startDate))) {
                return toast('End date must be on or later then the start date.')
              }
      
              let newData = {
                ...element.data,
                endDate: data
              }
              return onChange(newData)
            }}
          />
          <div style={{marginTop: 8}}>
            <Boolean
              label="Show Duration"
              data={element.data}
              field="showDuration"
              onChange={onChange}
            />
          </div>
        </>
      ) : null
      }
    </>
  )
}