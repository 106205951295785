import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Container
} from 'reactstrap'
// import ServiceTemplateCard from './ServiceTemplateCard'
import SearchBar from 'components/SearchBar'
import Empty from 'components/Empty'
import LevelContainer from 'components/LevelContainer'

import networkErrorToast from 'utils/networkErrorToast'
import * as actions from 'api'
import ServiceTemplate from '../ServiceTemplate'
import TableView from 'components/TableView'
import Icon from 'components/icons'
import stopEventBubbling from 'utils/stopEventBubbling'
//const showAlphaFeatures = process.env.REACT_APP_SHOW_ALPHA_FEATURES === '1'
import MaterialSpinner from 'components/MaterialSpinner'
import DateWithTooltip from 'components/DateWithTooltip'
const ActionContainer = styled(LevelContainer)`
  margin-bottom: 20px;
`

// const TemplateContainer = styled.div`
//   marginTop: 20px;
// `

const TableContainer = styled.div`
  color: white;
  margin-top: 20px;
`

const Title = styled.h3`
  color: white;
  margin: 10px;
`

const ContainerWrapper = styled(Container)`
  margin-top: 20px;
  max-width: 700px;
`

class ServiceTemplateList extends Component {
  state = {
    showModal: false,
    selectedTemplate: null,
    searchText: ''
  }

  async componentDidMount() {
    if (this.props.currentCompany) {
      await this.getTemplates()
    }

    this.setState({
      showModal: false
    })
  }

  getTemplates = async () => {
    let templates = await this.props.getServiceTemplates()

    if (templates.error) {
      return networkErrorToast(templates)
    } else {
      return templates
    }
  }

  submitTemplate = async template => {
    await this.props.submitServiceTemplate(template)
    await this.getTemplates()

    this._closeModal()
  }

  editTemplate = template => {
    this.setState({
      showModal: true,
      selectedTemplate: template
    })
  }

  removeTemplate = async template => {
    let archived = await this.props.removeServiceTemplate(template._id)

    if (archived.error) {
      return networkErrorToast(archived)
    } else {
      toast(`${template.name} was archived.`)
    }
  }

  _serviceDetails = serviceId => {
    console.log('_serviceDetails', serviceId)
    this.props.history.push(`/dashboard/library/services/${serviceId}`)
  }

  renderTemplates = templates => {
    const columns = [
      {
        header: 'Name',
        accessor: 'name'
      },
      {
        header: 'Rate',
        accessor: 'rate',
        isAlphaFeature: true
      },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        }
      },
      {
        header: 'Options',
        render: item => (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              onClick={e => {
                stopEventBubbling(e)
              }}
            >
              <Icon icon="IoIosMore" color="white" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={e => {
                  stopEventBubbling(e)
                  this.editTemplate(item)
                }}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={e => {
                  stopEventBubbling(e)
                  this.removeTemplate(item)
                }}
              >
                Archive
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    if (!templates || !templates.length) {
      return <Empty message="No services found." />
    }

    return (
      <TableView
        style={{ marginTop: 20 }}
        data={templates}
        columns={columns}
        onRowClick={template => this.editTemplate(template)}
      />
    )

    // return templates.map(template => (
    //   <ServiceTemplateCard
    //     key={template._id}
    //     service={template}
    //     submitTemplate={this.submitTemplate}
    //     editTemplate={this.editTemplate}
    //     removeTemplate={this.removeTemplate}
    //     detailsPage={this._serviceDetails}
    //   />
    // ))
  }

  _showModal = template => {
    this.setState({
      showModal: true,
      selectedTemplate: template
    })
    setTimeout(() => {
      if (window && window.userpilot && window.userpilot.reload) {
        window.userpilot.reload()
      }
    }, 1000)
  }

  _closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  _onSearchTextChange = value => {
    this.setState({ searchText: value })
  }

  _filterTemplate = services => {
    const { searchText } = this.state
    if (!searchText || !searchText.length) {
      return services
    }
    console.log('search text', searchText)
    const lowercaseSearchText = searchText.toLowerCase()
    return services.filter(s =>
      s.name.toLowerCase().includes(lowercaseSearchText)
    )
  }

  render() {
    let { services, loading } = this.props
    let { selectedTemplate, showModal } = this.state

    let renderedTemplates = <MaterialSpinner wrapped={true} />

    const filteredTemplate = this._filterTemplate(services)

    if (!loading) {
      renderedTemplates = this.renderTemplates(filteredTemplate)
    }

    return (
      <ContainerWrapper fluid>
        <Modal
          isOpen={showModal}
          toggle={this._closeModal}
          className="big-modal"
          fade={false}
        >
          {selectedTemplate && (
            <>
              <ModalHeader toggle={this._closeModal}>
                {selectedTemplate._id
                  ? `Update ${selectedTemplate.name}`
                  : 'New Service Type'}
              </ModalHeader>
              <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
                <ServiceTemplate
                  template={selectedTemplate}
                  submitTemplate={this.submitTemplate}
                  onClose={this._closeModal}
                />
              </ModalBody>
            </>
          )}
        </Modal>

        <ActionContainer>
          <Title>Services</Title>
          <Button
            className="cta btn"
            size={'md'}
            color={'primary'}
            onClick={this._showModal}
          >
            Add Service Type
          </Button>
        </ActionContainer>
        <SearchBar
          onChange={this._onSearchTextChange}
          value={this.state.searchText}
        />
        <TableContainer>{renderedTemplates}</TableContainer>
        {/* <TableContainer>
          {renderedTemplates.length && (
            <Table
              striped
              responsive
              className="table-hover table-dark"
              style={{ marginBottom: 75 }}
            >
              <thead>
                <tr>
                  <th
                    className="align-middle"
                    style={{ color: 'white', paddingLeft: 20 }}
                  >
                    Name
                  </th>
                  {showAlphaFeatures && (
                    <th className="align-middle" style={{ color: 'white' }}>
                      Rate
                    </th>
                  )}
                  <th
                    className="align-middle text-right"
                    style={{ color: 'white' }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{renderedTemplates}</tbody>
            </Table>
          )}
        </TableContainer> */}
      </ContainerWrapper>
    )
  }
}

const mapStateToProps = state => ({
  services: state.library.services,
  loading: state.library.loading,
  currentCompany: state.user.currentCompany
})

export default connect(mapStateToProps, actions)(ServiceTemplateList)
