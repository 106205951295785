import { store } from '../store'

// Needed to compare the role against
import employeeLevels from '../screens/Dashboard/Employees/EmployeeTags/EmployeeRoles'

const defaultAcl = {
  emp: '*',
  user: '*'
}

function evaluate(acl, employeeLevel) {
  if (!acl.emp && !acl.user) return true

  if (acl.emp && acl.emp !== '*') {
    return (
      employeeLevels.indexOf(employeeLevel) >= employeeLevels.indexOf(acl.emp)
    )
  }

  return true
}

export const isAllowed = (relationship) => {
  let [entity, action] = relationship.split('.')
  let { user, acl } = store.getState()

  let aclConfig = acl.acl
  let employeeRole = user.currentEmployment && user.currentEmployment.role

  if (!employeeRole || !aclConfig) return false

  // Resetting the variable for reuse
  acl = null
  let passes = true

  if (aclConfig[entity] && aclConfig[entity][action]) {
    acl = {
      ...defaultAcl,
      ...aclConfig[entity][action]
    }
  }

  if (acl) passes = evaluate(acl, employeeRole)

  return passes
}
