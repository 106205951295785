import { addons, frequencyConversionsByType, places, rooms, US_STATES } from '../data'

export const calculateCommercialBid = (bid, billingFrequency, margin, isHourlyBid, isRecurring) => {
  const standardIntensity = 5
  const getPlace = place => Object.keys(US_STATES).find(state => US_STATES[state].name === place)
  const expectationsCost = (standardIntensity - bid.expectations) * -200
  const productionRate = places.find(({ name }) => name === bid.location.type).rate
  const productionRateWithExpectations = productionRate - expectationsCost

  const adjustedProductionRate =
    productionRateWithExpectations * (bid.floors.percentHardFloors / 100) * 0.75 +
    productionRateWithExpectations * (bid.floors.percentSoftFloors / 100)

  const frequencyRate = isRecurring ? bid.frequency.rate : 1

  const frequencyConversion = isRecurring ? frequencyConversionsByType[bid.frequency.unit] : frequencyRate

  const hoursPerJob = bid.location.size / adjustedProductionRate
  const hourlyRate = isHourlyBid ? bid.employees.rate : US_STATES[getPlace(bid.place)].hourly

  const hourlyCostPerJob = hourlyRate * 1.5
  const costPerJob = hourlyCostPerJob * hoursPerJob
  const bidByHourlyRateWithFrequency =
  costPerJob *
    frequencyRate *
    (typeof frequencyConversion[billingFrequency] !== 'undefined'
      ? frequencyConversion[billingFrequency]
      : frequencyRate) *
    margin *
    1.03

  const total = bidByHourlyRateWithFrequency.toFixed(2)

  // console.log('man hours per job: ', hoursPerJob)
  // console.log('job cost by man hours: ', hourlyCostPerJob, costPerJob, hourlyRate)
  // console.log(hourlyCostPerJob, frequencyConversion[billingFrequency], billingFrequency, margin)
  // console.log('job cost by bid frequency with 3% supplies fee: ', bidByHourlyRateWithFrequency)
  // console.log('expectations cost: ', expectationsCost)
  // console.log('production rate with expectations: ', productionRateWithExpectations)
  // console.log('adjusted production rate: ', adjustedProductionRate)
  // console.log('frequency conversion: ', frequencyConversion)
  // console.log('total: ', total)

  return {total, costPerJob, hourlyRate, hoursPerJob, hourlyCostPerJob, adjustedProductionRate}
}

export const calculateResidentialBid = (bid, margin) => {
  const base = 50
  let roomCosts = 0
  let addonCosts = 0

  if (bid.rooms) {
    Object.keys(bid.rooms).forEach(room => (roomCosts += rooms.find(r => r.name === room).rate * bid.rooms[room]))
  }

  if (bid.addons) {
    Object.keys(bid.addons).forEach(addon => {
      const addonType = addons.find(a => a.name === addon)
      if (typeof addonType === 'undefined') {
        return addonCosts
      }
  
      return (addonCosts += addonType.rate * bid.addons[addon])
    })
  }

  let subtotal = margin * (base + roomCosts + addonCosts)

  const total = {
    onetime: {
      amount: subtotal.toFixed(2),
      label: 'One Time'
    },
    weekly: {
      amount: (subtotal * 0.8).toFixed(2),
      label: 'Weekly'
    },
    biweekly: {
      amount: (subtotal * 0.85).toFixed(2),
      label: 'Biweekly'
    },
    monthly: {
      amount: (subtotal * 0.9).toFixed(2),
      label: 'Monthly'
    },
    deepclean: {
      amount: (subtotal * 1.33).toFixed(2),
      label: 'Deep Clean'
    },
    moveinout: {
      amount: (subtotal * 1.5).toFixed(2),
      label: 'Move In/Out'
    }
  }

  // console.log('room costs: ', roomCosts)
  // console.log('addon costs: ', addonCosts)
  // console.log('subtotal: ', subtotal)
  // console.log('total: ', total)
  return total
}

/*
export const OLDcalculateCommercialBid = (bid, billingFrequency, margin, isHourlyBid, isRecurring) => {
  let total
  const standardIntensity = 5
  const getPlace = place => Object.keys(US_STATES).find(state => US_STATES[state].name === place)
  const expectationsCost = (standardIntensity - bid.expectations) * -200
  const productionRate = places.find(({ name }) => name === bid.location.type).rate
  const productionRateWithExpectations = productionRate - expectationsCost

  const adjustedProductionRate =
    productionRateWithExpectations * (bid.floors.percentHardFloors / 100) * 0.75 +
    productionRateWithExpectations * (bid.floors.percentSoftFloors / 100)

  const frequencyRate = isRecurring ? bid.frequency.rate : 1

  const frequencyConversion = isHourlyBid
    ? isRecurring
      ? frequencyConversionsByType[bid.frequency.unit]
      : frequencyRate
    : frequencyConversionsByType['month']

  if (isHourlyBid) {
    const hoursPerJob = bid.location.size / adjustedProductionRate
    const hourlyCostPerJob = hoursPerJob * bid.employees.rate * 1.5
    const bidByHourlyRateWithFrequency =
      hourlyCostPerJob *
      frequencyRate *
      (typeof frequencyConversion[billingFrequency] !== 'undefined'
        ? frequencyConversion[billingFrequency]
        : frequencyRate) *
      margin *
      1.03

    total = bidByHourlyRateWithFrequency.toFixed(2)

    console.log('man hours per job: ', hoursPerJob)
    console.log('job cost by man hours: ', hourlyCostPerJob)
    console.log(hourlyCostPerJob, frequencyConversion[billingFrequency], billingFrequency, margin)
    console.log('job cost by bid frequency with 3% supplies fee: ', bidByHourlyRateWithFrequency)
  } else {
    const state = US_STATES[getPlace(bid.place)]
    const monthlyRatePerSqFtByState = state.commercial[bid.location.type.toLowerCase()]
    const monthlyBidBySqFt = bid.location.size * monthlyRatePerSqFtByState
    const bidBySqFtWithFrequency = monthlyBidBySqFt * frequencyConversion[billingFrequency] * margin * 2

    total = bidBySqFtWithFrequency.toFixed(2)

    console.log('standard monthly sq ft rate: ', monthlyRatePerSqFtByState)
    console.log('bid per month by sq ft: ', monthlyBidBySqFt, billingFrequency, frequencyConversion['monthly'])
    console.log('bid by sq ft with frequency: ', bidBySqFtWithFrequency)
  }

  console.log('expectations cost: ', expectationsCost)
  console.log('production rate with expectations: ', productionRateWithExpectations)
  console.log('adjusted production rate: ', adjustedProductionRate)
  console.log('frequency conversion: ', frequencyConversion)

  return total
}
*/
