import React, { Component } from 'react'
import Multiselect from 'react-widgets/lib/Multiselect'
import FieldSet from './FieldSet'
import _ from 'lodash'

class MultiSelectField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBlur: false
    }
    this._onBlur = this._onBlur.bind(this)
  }

  _onBlur() {
    this.setState({
      isBlur: true
    })
  }

  render() {
    let { name, input, label, data, forceValidate, filterInputs, meta } = this.props

    let filteredInputs = _.intersection(data, input.value)

    return (
      <FieldSet
        label={label}
        meta={meta}
        checkValidation={forceValidate || this.state.isBlur}
        htmlFor={name}
      >
        <Multiselect
          {...this.props}
          {...input}
          value={(filterInputs && filteredInputs) || input.value || []}
          onBlur={() => this._onBlur()}
          name={name}
          onChange={value => {
            if (!value || !value.length) {
              return input.onChange([])
            }
            input.onChange(
              value.map(v =>
                this.props.valueField ? v[this.props.valueField] : v
              )
            )
          }}
        />
      </FieldSet>
    )
  }
}

export default MultiSelectField
