import areaSqFt from "./areaSqFt"

export default areas => {
  let result = {
    total: 0,
    types: {}
  }

  if (!areas || !areas.length) {
    return result
  }

  areas.forEach(area => {
    const sqFt = areaSqFt(area)
    if (sqFt) {
      result.total += sqFt

      if (area.floorType) {
        if (result.types[area.floorType]) {
          result.types[area.floorType] += sqFt
        } else {
          result.types[area.floorType] = sqFt
        }
      }
    }
  })

  return result
}
