import React from 'react'
import { CSVDownload } from 'react-csv'
import { toast } from 'react-toastify'

export default ({ jobs }) => {
  const finalData = jobs.map(job => {
    return {
      type: job.type,
      client: job.client ? job.client.name : null,
      status: job.status.label,
      locationName: job.location ? job.location.name : null,
      address: job.location ? job.location.address.fullAddress : null
    }
  })

  const headers = [
    {
      label: 'Type',
      key: 'type'
    },

    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Client',
      key: 'client'
    },
    {
      label: 'Location',
      key: 'locationName'
    },
    {
      label: 'Address',
      key: 'address'
    }
  ]

  if (finalData && finalData.length) {
    return <CSVDownload data={finalData} target="_blank" headers={headers} />
  } else {
    return toast('Could not complete export.')
  }
}
