import _ from 'lodash'
import { handlerErrors } from 'utils'

const inititalState = {
  inspections: [],
  loading: false,
  error: null
}

const replaceInspection = function(inspections, inspection) {
  if (!inspections || !inspections.length) {
    return []
  }

  let newList = inspections.slice(0)
  let index = _.findIndex(newList, {
    _id: inspection._id
  })
  if (index > -1) {
    newList[index] = inspection
  } else {
    newList.push(inspection)
  }
  return newList
}

const inspections = (state = inititalState, action) => {
  switch (action.type) {
    case 'FETCH_INSPECTIONS': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_INSPECTIONS_SUCCESS': {
      return {
        ...state,
        inspections: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'FETCH_INSPECTIONS_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting Inspections failed.')
      }
    case 'FETCH_INSPECTION_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting Inspection failed.')
      }
    }
    case 'UPDATE_INSPECTION_SUCCESS': {
      return {
        ...state,
        inspections: replaceInspection(state.inspections, action.payload.data)
      }
    }
    case 'ARCHIVE_INSPECTION_SUCCESS':
      return {
        ...state,
        inspections: state.inspections.filter(inspection => inspection._id !== action.payload.data._id),
        loading: false,
        error: null
      }
    case 'UNARCHIVE_INSPECTION_SUCCESS': {
      return {
        ...state,
        inspections: [...state.inspections, action.payload]
      }
    }
    case 'CANCEL_INSPECTION_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Cancelling Inspection failed.')
      }
    default:
      return state
  }
}

export default inspections
