import React, { useEffect } from 'react'
import styled from 'styled-components'

import Field from './field'
import { contact as contactInfo } from '../data'
import { PrimaryButton, Question, Title } from './ui'
import { InputState } from '../../../../../hooks'
import { browserWindow } from '../../../../../utils'

export const ContactPart = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  min-height: 6rem;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;

  label {
    font-weight: 500;
  }

  input,
  label,
  select {
    display: block;
    font-size: 1.25rem;
    text-align: left;
    width: 100%;
  }

  @media screen and (min-width: 32rem) {
    flex-flow: row wrap;

    input,
    label,
    select {
      display: inline-flex;
      font-size: 1.25rem;
      max-width: 50%;
      text-align: left;
    }
  }
`

export const ContactStage = ({
  contact,
  stageId,
  label,
  onClick,
  onContactChange
}) => {
  useEffect(() => {
    // console.log('scrolling...')
    browserWindow.scrollTo(0, 0)
  }, [])

  let disabled = false

  const fields = contactInfo.map(
    ({ id, label, name, placeholder, options, required, type }) => {
      const [value, setValue] = InputState(contact[id] || '')

      const isValid = value.trim().length > 0
      if (!isValid) {
        disabled = true
      }

      const onChange = event => {
        setValue(event)
        onContactChange({
          ...contact,
          [event.target.name]: event.target.value
        })
      }

      return (
        <ContactPart key={id}>
          <Field
            id={id}
            label={label}
            labelStyle={{ fontSize: '1.25rem', marginTop: '1rem' }}
            name={name}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            required={required}
            style={{ marginTop: '1rem' }}
            type={type}
            value={value}
          />
        </ContactPart>
      )
    }
  )

  return (
    <Question id={stageId}>
      <Title style={{ marginBottom: '1.5rem' }}>{label}</Title>

      {fields}
      <PrimaryButton disabled={disabled} type={'submit'}>
        Finish
      </PrimaryButton>
    </Question>
  )
}

export default ContactStage
