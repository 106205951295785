import React, { useState } from 'react'
import { Resizable } from 're-resizable'

import { CloseButton } from 'components/lib/Button'

const SideBar = ({ isOpen, onClose, children, size, resizable, ...rest }) => {
  const [ widthState, setWidthState ] = useState(size)

  return isOpen ? (
    <Resizable
      style={{
        position: 'fixed',
        top: 64,
        right: 0,
        bottom: 0,
        padding: 16,
        paddingTop: 32,
        backgroundColor: '#ddd',
        zIndex: 1033,
        overflowY: 'auto',
        boxShadow: isOpen ? '0 0 32px rgba(0, 0, 0, 0.5)' : 'none'
      }}
      size={{
        width: widthState
      }}
      onResizeStop={(e, direction, ref, d) => {
        setWidthState(widthState + d.width)
      }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: resizable ? true : false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
      {...rest}
    >
      <>
        <CloseButton
          onClick={onClose}
          style={{ position: 'absolute', top: 8, right: 8, fontSize: '1em' }}
        />
        <div style={{ position: 'relative' }}>{children}</div>
      </>
    </Resizable>
  ) : null
  }

SideBar.defaultProps = {
  size: 400
}

export default SideBar
