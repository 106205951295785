import { useState } from 'react';
import { HeaderRow, ItemValueContainer, ListBox } from './ListStyles';
import { UploadText } from '../ImportContentStyles';
import { ClientImportItem } from './ClientImportItem';
import { LocationImportItem } from './LocationImportItem';
import { acceptedClientColHeaders, acceptedLocationColHeaders } from './colHeaders';


export const ImportFinalList = ({ importType, data, updateItem, clients }) => {
  let renderedList = null

  if (importType === 'location') {
    renderedList = (
      <div>
        {data?.map((item, index) => (
          <LocationImportItem item={item} key={index} index={index} onSelectClient={updateItem} clients={clients} />
        ))}
      </div>
    )
  } else if (importType === 'client') {
    renderedList = (
      <div>
        {data?.map((item, index) => (
          <ClientImportItem item={item} key={index} />
        ))}
      </div>
    )
  }

  const colHeaders = importType === 'client' ? acceptedClientColHeaders : acceptedLocationColHeaders

  return (
    <div>
      <HeaderRow>
        {colHeaders.map((header, index) => (
          <ItemValueContainer key={index} width={header.width}>
            <UploadText style={{margin: 0}}>
              {header.name}
            </UploadText>
          </ItemValueContainer>
        ))}
      </HeaderRow>
      <ListBox>
        {renderedList}
      </ListBox>
    </div>
  )
}