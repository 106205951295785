import React from 'react'
import { Row, Col } from 'reactstrap'
import AreaCard from './AreaCard'

export default ({areas, ...rest}) => {
  const renderedAreas = (areas || []).map((area, index) => (
      <Col sm={12} md={6} lg={4} key={index}>
        <AreaCard area={area} areaIndex={index} {...rest} />
      </Col>
    ))
  return <Row>{renderedAreas}</Row>
}
