import React from 'react'

export default props => (
  <svg width={18} height={18} {...props}>
    <title>{'879DD759-A445-43AB-A30C-A2F374264CFF'}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M.527 8.473h7.418a.527.527 0 0 0 .528-.528V.527A.527.527 0 0 0 7.945 0H.527A.527.527 0 0 0 0 .527v7.418c0 .292.236.528.527.528zm2.145-4.746h1.055V2.714a.527.527 0 1 1 1.054 0v1.013h1.055a.527.527 0 1 1 0 1.054H4.78v1.097a.527.527 0 1 1-1.054 0V4.781H2.672a.527.527 0 1 1 0-1.054zM.527 18h7.418a.527.527 0 0 0 .528-.527v-7.418a.527.527 0 0 0-.528-.528H.527a.527.527 0 0 0-.527.528v7.418c0 .291.236.527.527.527zm2.265-4.987a.527.527 0 1 1 .746-.746l.746.746.716-.716a.527.527 0 1 1 .745.746l-.715.715.745.746a.527.527 0 1 1-.745.746l-.746-.746-.776.776a.527.527 0 1 1-.745-.746l.775-.776-.746-.745zM17.473 0h-7.418a.527.527 0 0 0-.528.527v7.418c0 .292.236.528.528.528h7.418A.527.527 0 0 0 18 7.945V.527A.527.527 0 0 0 17.473 0zm-2.11 4.781h-3.199a.527.527 0 1 1 0-1.054h3.2a.527.527 0 1 1 0 1.054zM17.473 9.527h-7.418a.527.527 0 0 0-.528.528v7.418c0 .291.236.527.528.527h7.418a.527.527 0 0 0 .527-.527v-7.418a.527.527 0 0 0-.527-.528zm-2.11 5.801h-3.199a.527.527 0 1 1 0-1.055h3.2a.527.527 0 1 1 0 1.055zm0-2.11h-3.199a.527.527 0 1 1 0-1.054h3.2a.527.527 0 1 1 0 1.055z"
        fill={props.color}
        fillRule="nonzero"
      />
      <path d="M0 0h18v18H0z" />
    </g>
  </svg>
)
