//import produce from 'immer'

/**
 * each action will look like
 * {
 *   isAllowed: Boolean,
 *   maxAllowed: Number,
 *   remaining: Number,
 *   currentCount: Number
 * }
 */
let initialState = {
  users: {
    create: {}
  },
  inspections: {
    create: {}
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS_CREATE_PCL_SUCCESS':
      return {
        ...state,
        users: {
          create: action.payload.data
        }
      }
    case 'GET_INSPECTIONS_CREATE_PCL_SUCCESS':
      return {
        ...state,
        inspections: {
          create: action.payload.data
        }
      }
    default:
      return state
  }
}

export default reducer
