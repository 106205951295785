import React from 'react'

export default props => (
  <svg width={18} height={18} {...props}>
    <title>{'7BC23C3C-75D4-45FF-8BF0-A64002B6CCB0'}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M17.928 7.994c-.029-.253-.323-.443-.578-.443a1.997 1.997 0 0 1-1.862-1.232 2.003 2.003 0 0 1 .503-2.224.5.5 0 0 0 .054-.678 8.898 8.898 0 0 0-1.424-1.439.5.5 0 0 0-.684.056c-.536.593-1.498.814-2.242.503A1.997 1.997 0 0 1 10.48.585a.499.499 0 0 0-.441-.525A8.977 8.977 0 0 0 8.016.055a.5.5 0 0 0-.446.514 2 2 0 0 1-1.23 1.919c-.736.3-1.692.081-2.227-.507a.501.501 0 0 0-.678-.057A8.932 8.932 0 0 0 1.98 3.362a.5.5 0 0 0 .054.683c.625.567.827 1.469.502 2.244-.31.74-1.078 1.216-1.956 1.216a.489.489 0 0 0-.52.441 9.008 9.008 0 0 0-.004 2.045c.029.254.332.442.59.442.783-.02 1.535.465 1.85 1.232a2 2 0 0 1-.502 2.224.5.5 0 0 0-.055.677 8.93 8.93 0 0 0 1.422 1.44.5.5 0 0 0 .685-.055c.538-.595 1.5-.815 2.241-.504A1.994 1.994 0 0 1 7.504 17.4a.5.5 0 0 0 .441.525 8.938 8.938 0 0 0 2.023.006.5.5 0 0 0 .446-.515 1.999 1.999 0 0 1 1.23-1.918c.74-.302 1.692-.081 2.227.507a.502.502 0 0 0 .678.056 8.952 8.952 0 0 0 1.455-1.438.5.5 0 0 0-.054-.683 1.993 1.993 0 0 1-.503-2.243 2.013 2.013 0 0 1 1.84-1.22l.11.003a.5.5 0 0 0 .527-.44 9 9 0 0 0 .004-2.045zM9.006 12.01c-1.654 0-2.999-1.346-2.999-3s1.345-3 3-3c1.653 0 2.999 1.346 2.999 3s-1.346 3-3 3z"
        fill={props.color}
        fillRule="nonzero"
      />
      <path d="M0 0h18v18H0z" />
    </g>
  </svg>
)
