import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setSalesTarget } from 'api'

import networkErrorToast from 'utils/networkErrorToast'

import moment from 'moment'

import { fetchSales } from 'api'
import { axiosClient } from 'store'

import {
  AverageContractValue,
  AverageInspectionRating,
  ConversionRate,
  DistributionOfRevenue,
  InspectionMetrics,
  MissedOpportunities,
  NewLeads,
  SalesByLocation,
  SalesByLocationType,
  SalesLeaderboard,
  SalesTarget,
  SalesTrend,
  Tally,
  TimeBar,
  TotalSalesCard,
  TotalSqFtCard
} from './components'
import { calculateRevenueStats } from './util'

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 160px;
  grid-gap: 12px;
  margin-top: 15px;
`

// const Banner = styled.img`
//   display: block;
//   margin: 0 auto 40px;
//   width: 50%;
// `

const dateRangeOptions = [
  {
    name: '1w',
    startDate: moment()
      .subtract(7, 'days')
      .format()
  },
  {
    name: '1m',
    startDate: moment()
      .subtract(1, 'months')
      .format()
  },
  {
    name: '3m',
    startDate: moment()
      .subtract(3, 'months')
      .format()
  },
  {
    name: '6m',
    startDate: moment()
      .subtract(6, 'months')
      .format()
  },
  {
    name: '1y',
    startDate: moment()
      .subtract(1, 'years')
      .format()
  },
  { name: 'All', startDate: null }
]

const SalesDashboard = ({ fetchSales, requesting, successful, stats, error }) => {
  const [currentTimeInterval, setCurrentTimeInterval] = useState(5)
  const [revenueStats, setRevenueStats] = useState({})
  const dispatch = useDispatch()
  const { currentCompany, currentEmployment } = useSelector(state => state.user)

  useEffect(() => {
    setRevenueStats(calculateRevenueStats(stats?.wonProposals))
  }, [stats?.wonProposals])

  const getStats = async (startDate, endDate) => {
    let stats = await fetchSales(startDate, endDate)

    if (stats.error) {
      return networkErrorToast(stats)
    } else {
      return stats
    }
  }

  useEffect(() => {
    const _initialLoad = async () => {
      let item = dateRangeOptions[5]
      await getStats(item.startDate, item.endDate)
    }

    _initialLoad()
  }, [])

  const _submitSalesTarget = async value => {
    dispatch(setSalesTarget({ salesTarget: value }))
  }

  const _changeDateRange = async item => {
    await getStats(item.startDate, item.endDate)
    setCurrentTimeInterval(dateRangeOptions
      .map(d => d.name)
      .indexOf(item.name)
    )
  }

  const conversionRateStats = {
    created: stats?.counts?.proposalCount?.created || 0,
    won: stats?.counts?.business?.dealsWon || 0,
  }

  return (
    <div style={{ padding: '8px 60px', display: 'flex', flexDirection: 'column' }}>
      <TimeBar
        data={dateRangeOptions}
        defaultValue={dateRangeOptions[currentTimeInterval]}
        onChange={_changeDateRange}
      />
      <StatsContainer>
        <NewLeads stat={stats?.counts?.leads} />
        <MissedOpportunities stat={stats?.counts?.declinedProposals} />
        <TotalSalesCard stat={revenueStats?.yearlyStats?.newSales} />
        <ConversionRate
          stats={conversionRateStats}
        />
        <SalesTarget
          monthlyContracts={stats?.monthlyContracts}
          updateSalesTarget={_submitSalesTarget}
          salesTarget={currentCompany?.analyticsSettings?.salesTarget}
          employeeRole={currentEmployment?.role}
        />
        <AverageContractValue average={revenueStats?.yearlyStats?.averageContractValue} count={stats?.wonProposals?.length} />
        <SalesByLocation
          stats={revenueStats?.yearlyStats?.salesByLocation}
          merchantCity={currentCompany?.address?.city}
        />
        <DistributionOfRevenue stats={revenueStats?.yearlyStats?.salesByService} />
        <SalesByLocationType
          stats={revenueStats?.yearlyStats?.salesByLocationType}
        />
        {/* <SalesTrend
          stats={revenueStats?.yearlyStats?.salesTrend}
          startDate={dateRangeOptions[currentTimeInterval].startDate || stats?.wonProposals[0]?.createdAt}
        /> */}
        <SalesLeaderboard stats={revenueStats?.yearlyStats?.salesLeaderboard} />
        <InspectionMetrics
          stats={stats?.counts?.inspectionCount}
        />
        <AverageInspectionRating inspections={stats?.completedInspections} />
        <TotalSqFtCard stat={revenueStats?.yearlyStats?.totalSqFt} />
        <Tally
          stats={stats?.counts?.walkthroughCount}
          title="Walkthrough"
        />
        <Tally
          stats={stats?.counts?.estimateCount}
          title="Estimates"
        />
        <Tally
          stats={stats?.counts?.proposalCount}
          title="Proposals"
        />
        <Tally
          stats={stats?.counts?.business}
          title="New Business"
        />
      </StatsContainer>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.sales.requesting,
  successful: state.sales.successful,
  stats: state.sales.stats,
  error: state.sales.error
})

export default connect(
  mapStateToProps,
  { fetchSales }
)(SalesDashboard)
