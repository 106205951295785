import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import styled, { css } from 'styled-components'
import { transparentize } from 'polished'


import Feature from './Feature'
import PlanSelectButton from './PlanSelectButton'

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Circle = styled.div`
  height: 128px;
  width: 128px;
  opacity: 0.06;
  background-color: ${props => props.theme.colors.clarity};
  border-radius: 128px;
  position: relative;
`

const ClarityText = styled.strong`
  color: ${props => props.theme.colors.clarity};
`

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.clarity} !important;
  color: white !important;
  width: 180px;
  height: 44px;
  font-weight: 300 !important;
  letter-spacing: 4px !important;
  margin-bottom: 8px;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: 0 2px 6px
    ${props => transparentize(0.6, props.theme.colors.clarity)} !important;
  transition: all 250ms ease;

  ${props =>
    props.disabled
      ? css`
          pointer-events: none !important;
        `
      : null};

  &:hover {
    box-shadow: 0 12px 24px
      ${props => transparentize(0.5, props.theme.colors.clarity)} !important;
  }
`

export default ({
  name,
  code,
  email,
  frequency,
  features,
  price,
  savings,
  isFeatured,
  isCurrent,
  onSelect
}) => (
  <Card style={isFeatured ? { transform: 'scale(1.2)', zIndex: 20 } : null}>
    <CardBody
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <h3 style={{ textAlign: 'center', margin: 8 }}>{code.toUpperCase()}</h3>
      <div style={{ position: 'relative', margin: '16px 0 24px' }}>
        <Circle />
        <img
          src={require(`assets/img/${code}-plan.svg`)}
          alt={`${code}-plan`}
          style={{ height: 80, position: 'absolute', top: 23, left: 23 }}
        />
      </div>

      <div style={{ fontSize: 14, padding: '6px 0 24px' }}>
        {features &&
          features.length > 0 &&
          features.map((feature, index) => {
            return <Feature key={index} feature={feature} />
          })}
      </div>

      {name !== 'free' ? (
        <>
          {frequency === 'year' ? (
            <ClarityText>{`Save $${
              savings ? Math.round(savings / 100) : null
            }`}</ClarityText>
          ) : (
            <div style={{ marginBottom: 24 }} />
          )}

          <PriceWrapper>
            <h4 style={{ margin: '2px 2px 0 0', fontWeight: 300 }}>$</h4>
            <h2>{price ? Math.round(price / 100) : null}</h2>
            <h2 style={{ marginLeft: 3, fontWeight: 300 }}>
              {frequency === 'year' ? '/yr' : '/mo'}
            </h2>
          </PriceWrapper>
        </>
      ) : (
        <div style={{ marginBottom: 66 }} />
      )}

      {/* <PlanSelectButton
        plan={name}
        amount={price}
        isCurrent={isCurrent}
        isFree={!price || price === 0}
        onSuccess={onSelect}
        email={email}
        planName={
          name.toUpperCase() +
          ' (' +
          (frequency === 'year' ? 'Yearly' : 'Monthly') +
          ')'
        }
      /> */}
      <StyledButton disabled={isCurrent} onClick={onSelect}>{isCurrent ? 'CURRENT' : 'SELECT'}</StyledButton>
    </CardBody>
  </Card>
)
