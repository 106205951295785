import React from 'react'

export default ({ element, proposal }) => {
  proposal = proposal || {}
  const showHeading = element && element.data && element.data.showHeading
  const headingText =
    (element && element.data && element.data.headingText) || 'Scope of Work'
  const textSize = (element && element.data && element.data.textSize) || 12

  const renderTasks = tasks => {
    return tasks.map((task, index) => (
      <tr key={`task-tr-${index}`}>
        <td className="text-left" style={{fontSize: textSize}}>{task.name}</td>
        <td className="text-right" style={{ width: '20%', fontSize: textSize }}>
          {task.frequency}
        </td>
      </tr>
    ))
  }

  const renderSections =
    element &&
    element.data &&
    element.data.taskSections &&
    element.data.taskSections.map((section, index) => {
      return (
        <div key={`scope-of-work-section-${index}`}>
          <h5 style={{ marginBottom: '5px', fontSize: textSize + 2 }}>
            {section.name || 'No section name'}
          </h5>
          {section.tasks && section.tasks.length && section.tasks.length > 0 ? (
            <>
              <table className="scope-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className="text-left" style={{fontSize: textSize}}>Task</th>
                    <th className="text-right" style={{ width: '20%', fontSize: textSize }}>
                      Frequency
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTasks(section.tasks)}</tbody>
              </table>
            </>
          ) : null}
          {section.spacer ? <div style={{height: section.spacer}} /> : null}
        </div>
      )
    })

  return (
    <div style={{ paddingBottom: 20 }}>
      {showHeading && <h2 className="section-title">{headingText}</h2>}
      <div className="task-section">{renderSections}</div>
    </div>
  )
}
