import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getServiceTemplates } from 'api'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import EmployeePermissions from '../../components/EmployeePermissions'

import { normalizePhone } from 'utils/normalizers'

import {
  InputField,
  //  MultiSelectField,
  GooglePlacesField
} from 'components/fields'
//import { SkillItem } from 'components/items'
import { capitalize, required } from 'utils'

import { EmployeeRoles, EmployeeSkills } from '../EmployeeTags'
// import EmployeeSchedule from './EmployeeSchedule'

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
`

const FieldContainer = styled.div`
  position: relative;
`

const StyledButton = styled(Button)`
  position: absolute;
  top: -7px;
  left: 40px;
`

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address.'
    : undefined

class EmployeeForm extends Component {
  state = {
    skills: [],
    showPermissionsModal: false
  }

  static propTypes = {
    skills: PropTypes.arrayOf(PropTypes.object),
    _id: PropTypes.string,
    defaultValues: PropTypes.object
  }

  static defaultProps = {
    skills: []
  }

  async componentDidMount() {
    await this.props.getServiceTemplates()
  }

  _togglePermissionsModal = () => {
    this.setState({
      showPermissionsModal: !this.state.showPermissionsModal
    })
  }

  render() {
    let { _id, skills, hideRoleInput } = this.props

    if (!skills || !skills.length) {
      skills = EmployeeSkills
    }

    let roles = EmployeeRoles.slice(0, EmployeeRoles.length - 1)

    const renderRoles = roles.map((role, index) => (
      <option value={role} key={index}>
        {capitalize(role)}
      </option>
    ))

    return (
      <>
        <Modal
          size="lg"
          isOpen={this.state.showPermissionsModal}
          toggle={this._togglePermissionsModal}
        >
          <ModalHeader toggle={this._togglePermissionsModal}>
            <strong>Employee Permissionss</strong>
          </ModalHeader>
          <ModalBody>
            <EmployeePermissions />
          </ModalBody>
        </Modal>
        <div className="card">
          <div className="card-body">
            <form onSubmit={this.props.handleSubmit}>
              {!_id && (
                <Field
                  name="email"
                  component={InputField}
                  type="text"
                  label="Email Address"
                  validate={[required, email]}
                  warn={required}
                />
              )}

              <Field
                name="name.first"
                component={InputField}
                type="text"
                label="First Name"
                validate={[required]}
                warn={required}
              />
              <Field
                name="name.last"
                component={InputField}
                type="text"
                label="Last Name"
                validate={[required]}
                warn={required}
              />
              <Field
                name="address"
                component={GooglePlacesField}
                type="text"
                label="Address"
              />
              <Field
                name="phone"
                component={InputField}
                type="text"
                label="Phone Number"
                normalize={normalizePhone}
              />
              {!hideRoleInput ?
                <FieldContainer>
                  <Field
                    name="role"
                    component={InputField}
                    type="select"
                    label="Role"
                    validate={[required]}
                    warn={required}
                  >
                    {renderRoles}
                  </Field>
                  <StyledButton
                    color="link"
                    onClick={this._togglePermissionsModal}
                  >
                    {' '}
                    Permissions List{' '}
                  </StyledButton>
                </FieldContainer>
                : null}
              {/*
                <Field
                  name="position"
                  component={InputField}
                  type="text"
                  label="Position"
                />
                <Field
                  name="hourlyRate"
                  component={InputField}
                  type="number"
                  label="Hourly Rate"
                />
                <Field
                  name="skills"
                  component={MultiSelectField}
                  data={skills}
                  itemComponent={SkillItem}
                  type="select"
                  label="Skills"
                  valueField="name"
                  textField="name"
                />
                */}

              {/* <EmployeeSchedule /> */}
              <SubmitContainer>
                <button type="submit" className="btn btn-primary">
                  Done
                </button>
              </SubmitContainer>
            </form>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  skills: state.library.services
})

export default compose(
  reduxForm({ form: 'Employee' }),
  connect(
    mapStateToProps,
    { getServiceTemplates }
  )
)(EmployeeForm)
