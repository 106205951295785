import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { amplitudeInstance, EVENTS } from 'store/middleware/analytics'

const getAuthToken = (state) => state.user.token

export const proposalsApi = createApi({
    reducerPath: 'proposalsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {

            const token = getAuthToken(getState())

            if (token) headers.set(`Authorization`, `Bearer ${token}`)

            return headers
        }
    }),
    endpoints: (builder) => ({
        getProposal: builder.query({
            query: (proposalId) => `/api/proposals/${proposalId}/proposalPortal`,
        }),

        getProposals: builder.query({

            query: (queryPamramString) => `/api/proposals/find?${queryPamramString}`,
            // query: (queryPamramString) => `/api/proposals/find?page=1&limit=20&filters=%7B%22proposal%22:[%22Sent%22],%22won%22:false%7D`,
            keepUnusedDataFor: 10,
            // queryKey: 'getPrroposalsCustomName',
            // async onQueryStarted(payload, { queryFulfilled }) {


            //     try {

            //         const { data } = await queryFulfilled

            //         console.log(EVENTS['loadProposals'](data))
            //     } catch (error) {

            //         console.log(error)
            //     }


            // }

        })

    })
})


export const { useGetProposalQuery, useGetProposalsQuery } = proposalsApi

