import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { NavLink as Link, withRouter } from 'react-router-dom'
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import styled from 'styled-components'

import Icon from 'components/icons'

//import { FaHome } from 'react-icons/fa'
import Avatar from 'components/lib/Avatar'
import UniversalSearch from 'components/UniversalSearch'
import { encryptJsonWithAES, decryptWithAES } from 'utils/encrypter'

import * as actions from 'api'
import { persistor } from 'store'

import Logo from 'assets/img/route-logo-full.png'

import * as acl from 'utils/acl.service'
import { useSelector } from 'react-redux'
import { AppRedirectMenu } from './AppRedirectMenu'
import { EmploymentSelectCard } from 'screens/Dashboard/EmploymentSelection/EmploymentSelectCard'

const DemoLink = styled.a`
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.8em;
  color: #0b64eb;
  text-decoration: none;
  background-color: transparent;
  display: block;
  padding: 0.5rem;
`

class Header extends Component {
  state = {
    isOpen: false,
    showSelectEmploymentModal: false
  }

  _homePage = () => {
    if (this.props.isLoggedIn) {
      this.props.history.push('/dashboard/')
    } else {
      this.props.history.push('/login/')
    }
  }

  useCompany = async company => {
    if (
      company &&
      company.company &&
      company.company._id !== this.props.company._id
    ) {
      await this.props.refreshToken(company.company._id)
      window.location.reload()
    }
  }

  logout = async () => {
    await this.props.logout()

    await persistor.flush()
    await persistor.purge()

    window.location.href = `${process.env.REACT_APP_LOGIN_DOMAIN}/auth/logout`
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  isActive = () => this.location.pathname === this.props.to

  // renderTeams = employment => {
  //   if (!employment) {
  //     return null
  //   }

  //   const renderedEmployment = employment.map(e => {
  //     return (
  //       e &&
  //       e.company &&
  //       e.company._id && (
  //         <DropdownItem onClick={() => this.useCompany(e)} key={e.company._id}>
  //           {e.company.name}
  //         </DropdownItem>
  //       )
  //     )
  //   })

  //   return <Fragment>{renderedEmployment}</Fragment>
  // }

  renderVerificationWarning = () =>
    this.props.profile &&
    !this.props.profile.verified && (
      <p className={'text-center text-danger'} style={{ marginTop: '2rem' }}>
        Please check your email to verify your account.
      </p>
    )

  render() {
    const {
      isLoggedIn,
      profile,
      employment,
      company,
      currentEmployment,
      hideSearch
    } = this.props

    const { showSelectEmploymentModal } = this.state

    let name
    if (
      currentEmployment &&
      currentEmployment.name &&
      currentEmployment.name.full
    ) {
      name = currentEmployment.name.full
    } else if (profile && profile.name && profile.name.full) {
      name = profile.name.full
    }

    const encryptedBillingData = encryptJsonWithAES({
      stripeCustomerId: company?.billing?.stripe?.customerId
    })

    const billingLink = `${process.env.REACT_APP_LOGIN_DOMAIN}/billing?billingData=${encryptedBillingData}`

    return (
      <>
        <Modal
          isOpen={showSelectEmploymentModal}
          toggle={() => this.setState({showSelectEmploymentModal: false})}
          size="lg"
        >
          <ModalBody style={{padding: 0, backgroundColor: 'transparent'}}>
            <EmploymentSelectCard 
              employment={employment}
              onSelect={this.useCompany}
              inModal
            />
          </ModalBody>
        </Modal>
        <Navbar fixed={'top'} dark color="dark" expand="md">
          <div className="container-fluid">
            <Button
              className="d-md-none"
              color="dark"
              size="sm"
              onClick={this.props.onMenuPress}
            >
              Menu
            </Button>
            <NavbarBrand onClick={this._homePage}>
              <img
                className={'logo'}
                src={Logo}
                alt={`Route Logo`}
                style={{ height: 20, marginTop: -6 }}
              />
            </NavbarBrand>
            {/* This is just to align center */}
            <div>&nbsp;&nbsp;</div>
            <NavbarToggler hidden={true} onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              {isLoggedIn && (
                <Nav className="ml-left" navbar>
                  {employment && company && (
                    <>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                         nav 
                         caret={employment.length > 1}
                         disabled={employment.length === 1}
                        >
                          {company.name}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.setState({showSelectEmploymentModal: true})}>
                            Switch Company
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {!hideSearch ? (
                        <UniversalSearch style={{ marginLeft: 40 }} />
                      ) : null}
                    </>
                  )}
                </Nav>
              )}

              <Nav className="ml-auto" navbar>
                {!isLoggedIn && (
                  <Fragment>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        activeClassName={'active'}
                        to="/login/"
                      >
                        Sign In
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <DemoLink
                        // tag={Link}
                        // activeClassName={'active'}
                        // to="/signup/"
                        href="https://getroute.com/questionnaire"
                      >
                        Sign Up
                      </DemoLink>
                    </NavItem>
                  </Fragment>
                )}

                <AppRedirectMenu />
                {isLoggedIn && (
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    style={{
                      padding: 0,
                      marginTop: -10,
                      marginBottom: -10
                    }}
                  >
                    <DropdownToggle
                      nav
                      caret
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          height: 42,
                          width: 42,
                          borderRadius: 21,
                          marginRight: 10
                        }}
                        className="d-none d-md-block"
                      >
                        <Avatar
                          size={42}
                          url={
                            profile && profile.avatar ? profile.avatar.url : ''
                          }
                          name={name}
                        />
                      </div>
                    </DropdownToggle>

                    <DropdownMenu right>

                      {!hideSearch ? (
                        <>
                          <DropdownItem href="/profile/">
                            Profile & Settings
                          </DropdownItem>
                          {/* <DropdownItem href="/changePassword">
                            Change Password
                          </DropdownItem> */}
                          {acl.isAllowed('billing.create') ? (
                            <DropdownItem href={billingLink}>
                              Billing
                            </DropdownItem>
                          ) : null}
                        </>
                      ) : null}
                      <DropdownItem className="logout" onClick={this.logout}>
                        Sign Out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Nav>
            </Collapse>
          </div>
        </Navbar >
        {/*
          <div>{this.renderVerificationWarning()}</div>
          */}
      </>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.token ? true : false,
  profile: state.user.profile,
  employment: state.user.employment,
  company: state.user.currentCompany,
  currentEmployment: state.user.currentEmployment
})

const enhancedHeader = connect(mapStateToProps, actions)(Header)
export default withRouter(enhancedHeader)
