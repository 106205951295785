import { fetchACL } from './acl'

export const register = (uuid, values) => async dispatch => {
  const regResult = await dispatch({
    type: 'USER_REGISTER',
    payload: {
      request: {
        url: `/api/employees/accept/${uuid}`,
        method: 'post',
        data: values
      }
    }
  })

  if (!regResult.error) {
    await fetchACL(dispatch)

    return dispatch({
      type: 'USER_PROFILE',
      payload: {
        request: {
          url: '/api/users/me'
        }
      }
    })
  } else {
    return regResult
  }
}

export const updateProfile = () => dispatch =>
  dispatch({
    type: 'USER_PROFILE',
    payload: {
      request: {
        url: '/api/users/me'
      }
    }
  })

export const modifyUserProfile = data => (dispatch, getState) =>
  dispatch({
    type: 'MODIFY_USER',
    payload: {
      request: {
        url: `/api/users/${getState().user.profile._id}`,
        method: 'patch',
        data: data
      }
    }
  })

export const logout = () => dispatch => dispatch({ type: 'USER_LOGOUT' })
