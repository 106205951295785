export const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)
export const lowercase = word => word.charAt(0).toLowerCase() + word.slice(1)
export const camelcase = (value) => {
  return (
    value.charAt(0).toLowerCase() +
    value.slice(1).replace(/([-_ ]\w)/g, group => group[1].toUpperCase())
  )
}
export const titlecase = (value) => {
  return value
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|&|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

/**
 * 
 * @param {string} textFormat Text to be formatted without excessive white spaces. 
 * @returns {string} format text.
 */  

export const formatText = (textFormat) => {
  return textFormat.split(' ').filter(val => val != '').join(' ')
}
