import React from 'react'
import LazyImage from 'components/LazyImage'

class MediaImage extends React.Component {
  render() {
    const { src, size } = this.props
    return (
      <LazyImage
        style={{
          width: size ? size + 'px' : '128px',
          height: size ? size + 'px' : '128px',
          marginRight: '4px',
          marginBottom: '4px',
          borderRadius: '8px',
          objectFit: 'cover',
          cursor: 'pointer'
        }}
        src={src}
        {...this.props}
      />
    )
  }
}

export default MediaImage
