import React from 'react'

export default ({ element }) => (
  <div style={{ paddingBottom: 20, textAlign: element.data.alignment || 'center' }}>
    {element.data.image && (
      <img
        src={element.data.image.url}
        style={{
          width: element.data.size ? `${element.data.size}%` : '100%'
        }}
        alt="proposal element"
      />
    )}
    {!element.data.image && <p>No image.</p>}
  </div>
)
