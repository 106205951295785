import React from 'react'

import Icon from 'components/icons'

export const JobItem = ({ item }) => {
  if (item) {
    const renderJobCategories = category =>
      category && category.length ? category : 'No Categories Listed'

    return (
      <span>
        {item.icon ? <Icon icon={item.icon} /> : null}
        &nbsp;&nbsp;
        {`${renderJobCategories(item.name)}`}
      </span>
    )
  } else {
    return null
  }
}
