import React, { useEffect } from 'react'
import {
  Card,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { toast } from 'react-toastify'

import { axiosClient } from 'store'
import { FiTag } from 'react-icons/fi'
import { storage } from 'utils'

import { ActionButtons, SpanLink } from 'components/lib/Button'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import { connectConfirmation } from 'components/confirm'

import {
  ActionBar,
  ButtonGroup,
  BackButton,
  RestartButton,
  Form,
  Progress,
  Questions,
  // ContactStage,
  // FinishedStage,
  PlaceStage,
  ServiceStage,
  // StartStage,
  CommercialEmployeesStage,
  CommercialExpectationsStage,
  CommercialFloorStage,
  CommercialFrequencyStage,
  CommercialLocationStage,
  CommercialRecurringStage,
  CommercialReviewStage,
  ResidentialAddonsStage,
  ResidentialLocationStage,
  ResidentialRecurringStage,
  ResidentialRoomsStage,
  ResidentialReviewStage
} from './components'
import {
  // defaultContact,
  defaultBid,
  defaultLocation,
  defaultCommercialReview,
  defaultResidentialReview,
  questionnaire
} from './data'
import { calculateCommercialBid, calculateResidentialBid } from './utils'
import { StorageState, ToggleState } from '../../../../hooks'
import { browserWindow } from '../../../../utils'
import SelectClientLocationModal from 'screens/Dashboard/components/modals/SelectClientLocationModal'

// const encode = data =>
//   Object.keys(data)
//     .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
//     .join('&')

const Calculator = props => {
  // state
  const [bid, onBidChange, resetBid] = StorageState('bid', defaultBid)
  const [location, onLocationChange, resetLocation] = StorageState(
    'location',
    defaultLocation
  )
  const [place, onPlaceChange, resetPlace] = StorageState('place', '')
  const [review, onReviewChange] = StorageState('review', {})
  const [service, onServiceChange, resetService] = StorageState('service', '')
  const [stage, onStageChange, resetStage] = StorageState('stage', 'place')
  const [completed, setCompleted] = ToggleState(false)
  const [submitted, setSubmitted] = ToggleState(false)
  const [showLocationModal, setShowLocationModal] = ToggleState(false)
  const [existingBid, setExistingBid] = ToggleState('')

  // other variables
  const estimateId = existingBid && existingBid._id
  const client = existingBid && existingBid.client
  const clientName =
    existingBid && existingBid.client && existingBid.client.name
  const labels = existingBid && existingBid.labels
  const existingLocation = existingBid && existingBid.location
  const existingLocationAddress =
    existingLocation &&
    existingLocation.address &&
    existingLocation.address.fullAddress

  useEffect(() => {
    if (
      props &&
      props.match &&
      props.match.params &&
      props.match.params.estimateId
    ) {
      const fetchQB = async () => {
        const res = await axiosClient.get(
          `/api/estimates/${props.match.params.estimateId}`
        )

        if (res.data && res.data.bid) {
          setExistingBid(res.data)
          let bid = res.data.bid
          bid.commercial
            ? onServiceChange('commercial')
            : onServiceChange('residential')
          onBidChange(bid)
          onReviewChange({
            billingFrequncy: bid.billingFrequency,
            margin: bid.margin
          })
          setCompleted(true)
          // onPlaceChange(bid.place)
          // onLocationChange(bid.location)
          onStageChange('review')
        }
      }
      fetchQB()
    }

    if (storage && storage.getItem('prevQuickBid')) {
      const previousBid = JSON.parse(storage.getItem('prevQuickBid'))
      storage.removeItem('prevQuickBid')

      onServiceChange(previousBid.service || 'commercial')
      onBidChange(previousBid)
      onReviewChange({
        billingFrequncy: previousBid.billingFrequency,
        margin: previousBid.margin
      })
      setCompleted(true)
      // onPlaceChange(bid.place)
      // onLocationChange(bid.location)
      onStageChange('review')
    }

    return () => {
      storage.removeItem('bid')
      storage.removeItem('location')
      storage.removeItem('place')
      storage.removeItem('review')
      storage.removeItem('stage')
    }
  }, [])

  let questions = []
  let currentIndex = 0

  if (questionnaire[service] && questionnaire[service].hasOwnProperty(stage)) {
    questions = Object.keys(questionnaire[service])
    currentIndex = questions.findIndex(question => question === stage)
  }

  // const submitBid = async bid => {
  //   const submission = Object.keys(bid).reduce((fields, field) => {
  //     if (typeof bid[field] === 'object') {
  //       if (field === 'contact') {
  //         return fields
  //       } else if (field === 'floors') {
  //         fields = {
  //           ...fields,
  //           [field]: bid[field].percentHardFloors
  //         }
  //       } else if (field === 'review') {
  //         const flattened = Object.keys(bid[field]).reduce(
  //           (fs, f) => ({
  //             ...fs,
  //             [f]: bid[field][f]
  //           }),
  //           {}
  //         )

  //         fields = {
  //           ...fields,
  //           ...flattened
  //         }
  //       } else {
  //         const flattened = Object.keys(bid[field]).reduce(
  //           (fs, f) => ({
  //             ...fs,
  //             [`${field}-${f}`]: bid[field][f]
  //           }),
  //           {}
  //         )

  //         fields = {
  //           ...fields,
  //           ...flattened
  //         }
  //       }
  //     } else {
  //       fields = {
  //         ...fields,
  //         [field]: bid[field]
  //       }
  //     }

  //     return fields
  //   }, {})

  // const body = encode({
  //   'form-name': name,
  //   ...submission,
  //   name: `${bid.firstname} ${bid.lastname}`
  // })

  // try {
  //   const request = new Request('/', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     body
  //   })

  //   const response = await fetch(request)

  //   if (response.status === 200 || response.ok) {
  //     console.log('submit was good: ', response)
  //     setSubmitted(true)

  //     return response
  //   } else {
  //     console.log('submit failed... ', response)
  //     return response
  //   }
  // } catch (error) {
  //   console.log(error)
  // }
  // }

  const onBack = event => {
    if (stage === 'place') {
      onStageChange('start')
    } else if (stage === 'service') {
      onStageChange('place')
    } else {
      onStageChange(questions[currentIndex - 1])
    }
  }

  const onPlace = (event, place) => {
    onPlaceChange(place)
    onBidChange({ ...bid, place })

    if (completed) {
      onStageChange('review')
    } else {
      onStageChange('service')
    }
  }

  const onReviewUpdate = review => {
    onReviewChange(review)
    onBidChange({ ...bid, ...review })
  }

  const onService = (event, service) => {
    onServiceChange(service)

    if (service === 'commercial') {
      onReviewChange(defaultCommercialReview)
      onBidChange({ ...bid, ...defaultCommercialReview, service })
    }

    if (service === 'residential') {
      onReviewChange(defaultResidentialReview)
      onBidChange({ ...bid, ...defaultResidentialReview, service })
    }

    questions = Object.keys(questionnaire[service])
    onStageChange(questions[currentIndex])
  }

  const onClick = (event, value) => {
    if (completed) {
      if (stage === 'recurring' && value === 'yes') {
        onStageChange(questions[currentIndex + 1])
      } else {
        onStageChange('review')
      }
    } else {
      if (stage === 'recurring' && value === 'no') {
        onBidChange({ ...bid, [stage]: value, frequency: {} })
        onStageChange(questions[currentIndex + 2])
      } else {
        onStageChange(questions[currentIndex + 1])
      }
    }

    if (stage === 'employees' || stage === 'addons') {
      onBidChange({ ...bid, [stage]: value })
      setCompleted(true)
      // onSubmit()

      // onBidChange({ ...bid, [stage]: contact })

      // let total
      // let data = { ...bid, ...contact, ...review }
      // const margin = 1 + review.margin / 100

      // if (service === 'commercial') {
      //   const isHourlyBid = bid.employees.employer === 'yes' && !bid.employees.useStateAverage

      //   const isRecurring = bid.recurring === 'yes'

      //   const billingFrequency =
      //     typeof review.billingFrequency !== 'undefined'
      //       ? review.billingFrequency.slice(0, -2)
      //       : defaultCommercialReview.billingFrequency.slice(0, -2)

      //   total = calculateCommercialBid(bid, billingFrequency, margin, isHourlyBid, isRecurring)
      //   data = { ...data, total }
      // }

      // if (service === 'residential') {
      //   total = calculateResidentialBid(bid, margin)
      //   const formattedTotal = Object.keys(total).reduce((all, next) => ({ ...all, [next]: total[next].amount }), {})
      //   data = { ...data, ...formattedTotal }
      // }

      // submitBid(data, event.target.getAttribute('name'))
      // setCompleted(true)

      // if (submitted) {
      //   setSubmitted(true)

      //   if (browserWindow.ga) {
      //     browserWindow.ga('send', {
      //       hitType: 'event',
      //       eventAction: 'Submit',
      //       eventCategory: 'Lead',
      //       eventLabel: 'QuickBid'
      //     })
      //   }
      // }
      // onSubmit(event)

      if (submitted) {
        setSubmitted(true)

        if (browserWindow.ga) {
          browserWindow.ga('send', {
            hitType: 'event',
            eventAction: 'Submit',
            eventCategory: 'Lead',
            eventLabel: 'QuickBid'
          })
        }
      }

      onStageChange('review')
    } else if (stage === 'location' && service === 'commercial') {
      onBidChange({ ...bid, [stage]: location })
    } else {
      onBidChange({ ...bid, [stage]: value })
    }
  }

  const createBidData = () => {
    let submittedTotal
    let data = { ...bid, ...review }
    const margin = 1 + review.margin / 100

    if (service === 'commercial') {
      const isHourlyBid =
        bid.employees.employer === 'yes' && !bid.employees.useStateAverage

      const isRecurring = bid.recurring === 'yes'

      const billingFrequency =
        typeof review.billingFrequency !== 'undefined'
          ? review.billingFrequency
          : defaultCommercialReview.billingFrequency

      const {
        total,
        hourlyRate
        // hoursPerJob,
        // hourlyCostPerJob,
        // adjustedProductionRate
      } = calculateCommercialBid(
        bid,
        billingFrequency,
        margin,
        isHourlyBid,
        isRecurring
      )
      submittedTotal = total
      data.employees.rate = hourlyRate
      data = { ...data, total: Number(submittedTotal), commercial: true }
    }

    if (service === 'residential') {
      const total = calculateResidentialBid(bid, margin)
      const formattedTotal = Object.keys(total).reduce(
        (all, next) => ({ ...all, [next]: total[next].amount }),
        {}
      )
      data = { ...data, ...formattedTotal, residential: true }
    }

    return data
  }

  const onSubmit = async values => {
    const data = createBidData()

    let quickbid = {
      type: 'quickbid',
      client: values && values.client,
      location: values && values.location,
      bid: data
    }

    const res = await axiosClient.post('/api/estimates/', quickbid)

    if (res.data) {
      setExistingBid(res.data)
      let bid = res.data.bid
      bid.commercial
        ? onServiceChange('commercial')
        : onServiceChange('residential')
      onBidChange(bid)
      onReviewChange({
        billingFrequncy: bid.billingFrequency,
        margin: bid.margin
      })
      setShowLocationModal(false)
      toast('Bid created successfully.')
      props.history.push(`/dashboard/quickbid/${res.data._id}`)
    }
  }

  const updateQuickBid = async callback => {
    if (!existingBid) {
      return
    }

    const data = createBidData()

    const quickbid = {
      _id: existingBid._id,
      bid: data
    }

    const res = await axiosClient.patch(
      `/api/estimates/${existingBid._id}`,
      quickbid
    )

    if (res.data) {
      setExistingBid(res.data)
      let bid = res.data.bid
      bid.commercial
        ? onServiceChange('commercial')
        : onServiceChange('residential')
      onBidChange(bid)
      onReviewChange({
        billingFrequncy: bid.billingFrequency,
        margin: bid.margin
      })
      toast('Bid updated successfully.')
      if (callback) {
        callback()
      }
    }
  }

  const _createProposal = async () => {
    if (!client || !existingBid) {
      return
    }

    const proposal = {
      estimate: estimateId,
      client: client,
      location: existingLocation,
      merchant: existingBid.merchant,
      version: 2
    }

    const result = await axiosClient.post('/api/proposals/', proposal)

    if (result && result.data) {
      props.history.push(`/dashboard/edit-proposal/${result.data._id}`, {
        proposal: result.data
      })
      toast('Proposal Created')
    } else {
      toast('Unable to create proposal')
    }
  }

  const onTrash = async () => {
    if (!existingBid) {
      return null
    }
    await axiosClient.patch(`/api/estimates/${existingBid._id}/delete`)
    toast('Bid archived successfully.')
    props.history.push('/dashboard/estimates')
  }

  const onFinish = () => {
    if (existingBid) {
      updateQuickBid()
    } else {
      setShowLocationModal(true)
    }
  }

  const onResetService = () => {
    setSubmitted(false)
    setCompleted(false)
    resetService()
    resetLocation()
    onStageChange('service')
  }

  const onStartOver = event => {
    setSubmitted(false)
    setCompleted(false)
    resetBid()
    resetPlace()
    resetService()
    resetLocation()
    resetStage()
  }

  let question = null

  switch (stage) {
    // case 'start': {
    //   question = <StartStage onStart={onStart} />

    //   break
    // }
    case 'place': {
      question = <PlaceStage current={bid.place} onPlace={onPlace} />

      break
    }
    case 'service': {
      question = (
        <ServiceStage
          current={bid.service}
          onService={onService}
          service={service}
        />
      )

      break
    }
    case 'employees': {
      question = (
        <CommercialEmployeesStage
          current={bid.employees}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )

      break
    }
    case 'expectations': {
      question = (
        <CommercialExpectationsStage
          current={bid.expectations}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )

      break
    }
    case 'floors': {
      question = (
        <CommercialFloorStage
          current={bid.floors}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )

      break
    }
    case 'recurring': {
      if (service === 'commercial') {
        question = (
          <CommercialRecurringStage
            current={bid.recurring}
            id={questions[currentIndex]}
            onClick={onClick}
            {...questionnaire[service][stage]}
          />
        )
      }

      if (service === 'residential') {
        question = (
          <ResidentialRecurringStage
            current={bid.recurring}
            id={questions[currentIndex]}
            onClick={onClick}
            {...questionnaire[service][stage]}
          />
        )
      }

      break
    }
    case 'frequency': {
      question = (
        <CommercialFrequencyStage
          current={bid.frequency}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )

      break
    }
    case 'location': {
      if (service === 'commercial') {
        question = (
          <CommercialLocationStage
            current={bid.location}
            id={questions[currentIndex]}
            location={location}
            onClick={onClick}
            onLocationChange={onLocationChange}
            {...questionnaire[service][stage]}
          />
        )
      }

      if (service === 'residential') {
        question = (
          <ResidentialLocationStage
            current={bid.location}
            id={questions[currentIndex]}
            onClick={onClick}
            {...questionnaire[service][stage]}
          />
        )
      }

      break
    }
    case 'rooms': {
      question = (
        <ResidentialRoomsStage
          current={bid.rooms}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )

      break
    }
    case 'addons': {
      question = (
        <ResidentialAddonsStage
          current={bid.addons}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )

      break
    }
    // case 'contact': {
    //   question = (
    //     <ContactStage
    //       contact={contact}
    //       current={bid.contact}
    //       id={questions[currentIndex]}
    //       onContactChange={onContactChange}
    //       onClick={onClick}
    //       onSubmit={onSubmit}
    //       {...questionnaire[service][stage]}
    //     />
    //   )

    //   break
    // }
    // case 'review': {
    //   if (service === 'commercial') {
    //     question = (
    //       <CommercialReviewStage
    //         bid={bid}
    //         contact={contact}
    //         current={bid.review}
    //         id={questions[currentIndex]}
    //         onFinish={onFinish}
    //         onFinishAndStartOver={onFinishAndStartOver}
    //         onReviewUpdate={onReviewUpdate}
    //         onStageChange={onStageChange}
    //         review={review}
    //         {...questionnaire[service][stage]}
    //       />
    //     )
    //   }

    //   if (service === 'residential') {
    //     question = (
    //       <ResidentialReviewStage
    //         bid={bid}
    //         contact={contact}
    //         current={bid.review}
    //         id={questions[currentIndex]}
    //         onFinish={onFinish}
    //         onFinishAndStartOver={onFinishAndStartOver}
    //         onReviewUpdate={onReviewUpdate}
    //         onStageChange={onStageChange}
    //         review={review}
    //         {...questionnaire[service][stage]}
    //       />
    //     )
    //   }

    //   break
    // }
    default: {
      question = <PlaceStage current={bid.place} onPlace={onPlace} />

      break
    }
  }

  const showProgress =
    place &&
    place.length > 0 &&
    (service === 'commercial' || service === 'residential')

  let quickbid = (
    <>
      {stage !== 'place' && stage !== 'review' && (
        <ButtonGroup>
          <BackButton onClick={onBack} type={'button'}>
            Back
          </BackButton>
          <RestartButton onClick={onStartOver} type={'button'}>
            Start Over
          </RestartButton>
        </ButtonGroup>
      )}
      <Form
        id={`quickbid`}
        method={'post'}
        name={`quickbid`}
        onSubmit={onSubmit}
      >
        <Questions>{question}</Questions>
        {showProgress && (
          <Progress
            currentIndex={currentIndex}
            completed={completed}
            onStageChange={onStageChange}
            questions={questions}
          />
        )}
      </Form>
    </>
  )

  if (stage === 'review') {
    if (service === 'commercial') {
      quickbid = (
        <CommercialReviewStage
          bid={bid}
          current={bid.review}
          id={questions[currentIndex]}
          onFinish={onFinish}
          onResetService={onResetService}
          onReviewUpdate={onReviewUpdate}
          onStageChange={onStageChange}
          review={review}
          {...questionnaire[service][stage]}
        />
      )
    }

    if (service === 'residential') {
      quickbid = (
        <ResidentialReviewStage
          bid={bid}
          current={bid.review}
          id={questions[currentIndex]}
          onFinish={onFinish}
          onResetService={onResetService}
          onReviewUpdate={onReviewUpdate}
          onStageChange={onStageChange}
          review={review}
          {...questionnaire[service][stage]}
        />
      )
    }
  }

  const title = (
    <>
      <div style={{ color: 'white' }}>
        <div style={{ display: 'inline-block', marginRight: 10 }}>
          {existingBid ? (
            <Badge pill color="primary">
              {'Estimate ' + existingBid.code}
            </Badge>
          ) : (
            <Badge pill>QuickBid Draft</Badge>
          )}
        </div>
        {clientName && (
          <SpanLink
            onClick={() =>
              props.history.push(`/dashboard/client-details/${client._id}`)
            }
          >
            {clientName}
          </SpanLink>
        )}
        {existingLocationAddress && ' at '}
        {existingLocationAddress && (
          <SpanLink
            onClick={() =>
              props.history.push(
                `/dashboard/location-details/${existingLocation._id}`
              )
            }
          >
            {existingLocationAddress}
          </SpanLink>
        )}
      </div>
    </>
  )

  const actionBar = (
    <ActionBar className="sticky-top">
      <div>{title}</div>

      <ActionButtons
        buttons={[
          ...(estimateId
            ? [
                {
                  component: (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        block
                        style={{ textAlign: 'left' }}
                        color="secondary"
                      >
                        <FiTag /> Labels
                      </DropdownToggle>
                      <DropdownMenu right={true}>
                        <LabelEditor
                          data={labels}
                          onChange={async data => {
                            await updateQuickBid({
                              _id: estimateId,
                              labels: data
                            })
                          }}
                          // refreshEntity={async () => {
                          //   const res = await props.getEstimate(estimateId)
                          //   if (res && res.payload && res.payload.data) {
                          //     this.setState({
                          //       data: res.payload.data
                          //     })
                          //   }
                          // }}
                        />
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )
                }
              ]
            : []),
          {
            title: 'Save QuickBid',
            onClick: () => onFinish(),
            disable: !completed && !(existingBid && !completed)
          }
        ]}
        options={[
          ...(existingBid && client
            ? [
                {
                  title: 'New Proposal',
                  onClick: () => {
                    updateQuickBid(_createProposal)
                  }
                }
              ]
            : []),
          ...(estimateId
            ? [
                {
                  title: 'Archive Estimate',
                  divide: true,
                  color: 'danger',
                  onClick: () =>
                    props.confirm(
                      'Confirm Archive',
                      'Are you sure you want to archive this estimate?',
                      () => onTrash()
                    )
                }
              ]
            : [])
        ]}
      />
    </ActionBar>
  )

  return (
    <>
      {actionBar}
      <div
        style={{ display: 'flex', flexDirection: 'row', padding: '0px 16px' }}
      >
        {labels &&
          labels.length > 0 &&
          labels.map(label => {
            return (
              <Label
                key={label._id}
                color={label.color}
                style={{ marginRight: 8 }}
              >
                {label.name}
              </Label>
            )
          })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 200px'
        }}
      >
        <SelectClientLocationModal
          showModal={showLocationModal}
          toggle={() => setShowLocationModal(false)}
          onSubmit={onSubmit}
          notRequired={true}
          headerTitle="Select Location (optional)"
          submitText="Submit"
          backText="Cancel"
        />
        <Card body>{quickbid}</Card>
      </div>
    </>
  )
}

export default connectConfirmation(Calculator)
