import React, { Component } from 'react'
import { connect } from 'react-redux'
import { submitServiceTemplate } from 'api'
import { axiosClient } from 'store'

import ServiceTemplateForm from './ServiceTemplateForm'

class ServiceTemplate extends Component {
  state = {
    ready: false,
    template: null
  }

  async componentDidMount() {
    const { template } = this.props

    if (template._id) {
      return this._load(template._id)
    } else {
      this.setState({
        ready: true
      })
    }
  }

  _load = async id => {
    const result = await axiosClient.get(`/api/library/services/${id}`)

    if (result && result.data) {
      this.setState({
        ready: true,
        template: result.data
      })
    } else {
      this.setState({
        ready: true
      })
    }
  }

  render() {
    const { ready, template } = this.state

    if (!ready) {
      return null
    }

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <ServiceTemplateForm
          onSubmit={this.props.submitTemplate}
          initialValues={template}
          _id={template ? template._id : null}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  company: state.user.currentCompany
})

export default connect(
  mapStateToProps,
  { submitServiceTemplate }
)(ServiceTemplate)
