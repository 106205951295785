import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import LocationForm from '../forms/LocationForm'

export default ({ isOpen, toggle, submitLocation, clientId }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" autoFocus={false}>
      <ModalHeader toggle={toggle}>
        New Location
      </ModalHeader>
      <ModalBody>
      <LocationForm
        onSubmit={submitLocation}
        initialValues={{ client: clientId }}
        closeModal={toggle}
      />
      </ModalBody>
    </Modal>  )
}