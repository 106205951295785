import React, { Component } from 'react'
import { connect } from 'react-redux'
import MediaImage from 'components/MediaImage'
import {
  ListGroupItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import TaskForm from './TaskForm'
import moment from 'moment'
import { getEmployees } from '../../../../api'
import { FiMoreHorizontal } from 'react-icons/fi'

class TaskListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  componentDidMount() {
    this.props.getEmployees()
  }

  editTask = () => {
    this.setState({
      showModal: true
    })
  }

  archiveTask = () => this.props.onTaskDeleted(this.props.task)

  _toggleModal = () => {
    this.setState({
      showModal: false
    })
  }

  _onSubmit = async values => {
    if (this.props.onTaskEdited) {
      this.props.onTaskEdited(values)
    } else {
      alert('Missing Prop: onTaskEdited')
    }
    this.setState({
      showModal: false
    })
  }

  renderEmployee = taskCompleter => {
    if (!taskCompleter) {
      return null
    }
    const renderTaskCompleterName = item => {
      // Prioritize Employee record.
      if (
        item.name &&
        item.name.full &&
        item.name.full.length &&
        item.name.full !== 'undefined'
      ) {
        return item.name.full
      }

      return 'Name not found'
    }

    return <>{renderTaskCompleterName(taskCompleter)}</>
  }

  render() {
    const { task, onImageClicked, employees } = this.props

    let renderedMedia = (task.attachments || []).map((image, index) => {
      return (
        <MediaImage
          onClick={() => onImageClicked(image.url)}
          size={64}
          src={image.url}
          key={index}
        />
      )
    })

    let completedTime
    let taskCompleter
    let completedDate

    if (task && task.completed) {
      completedTime = moment(task.completed.at).format('LT')
      completedDate = moment(task.completed.at).format('l')
      taskCompleter = employees.filter(employee => {
        return employee._id === task.completed.by
      })[0]
    }

    return (
      <>
        <Modal
          isOpen={this.state.showModal}
          toggle={this._toggleModal}
          className="big-modal"
          fade={false}
        >
          <ModalHeader toggle={this._toggleModal}>
            {task.name && <strong>{task.name}</strong>}
          </ModalHeader>
          <ModalBody className="bg-light1">
            <TaskForm
              initialValues={task}
              onImageClicked={onImageClicked}
              onSubmit={this._onSubmit}
              images={task.attachments}
            />
          </ModalBody>
        </Modal>
        <ListGroupItem
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 0,
            paddingTop: 4,
            paddingBottom: 4
          }}
        >
          {task.name && <span style={{ flex: 2 }}>{task.name}</span>}
          {task.completed && (
            <div style={{ marginLeft: 24, marginRight: 24 }}>
              <strong>Completed on </strong>
              {completedDate},<strong> at </strong>
              {completedTime},<strong> by </strong>
              {this.renderEmployee(taskCompleter)}
            </div>
          )}

          {renderedMedia && renderedMedia.length > 0 && (
            <div>{renderedMedia}</div>
          )}

          <UncontrolledDropdown inNavbar>
            <DropdownToggle color="link" style={{ padding: 0 }}>
              <FiMoreHorizontal size={24} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                className="text-danger"
                onClick={() => this.archiveTask()}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ListGroupItem>
      </>
    )
  }
}

const mapStateToProps = state => ({
  employees: state.employees.employees
})

export default connect(
  mapStateToProps,
  { getEmployees }
)(TaskListItem)
