import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isAllowed } from '../../utils/acl.service'

const PrivateRoute = ({
    isAuthenticated,
    failureRedirect,
    location,
    ...rest
}) => {
    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: failureRedirect || '/login',
                    state: { from: location }
                }}
            />
        )
    }

    let { controlAccess } = rest

    // TODO: Can we do something to remove these harcoded `entity` and `action` keys?
    if (controlAccess) {
        if (!isAllowed(controlAccess)) {
            return (
                <Redirect
                    to={{
                        // not passing in the state object to login because the user might end up in a circular loop
                        // we can redirect to Un-Authorized page
                        pathname: failureRedirect || '/login'
                    }}
                />
            )
        }
    }

    console.log({ location })
    return <Route location={location} {...rest} />
}

Route.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
}

const mapStateToProps = state => ({
    isAuthenticated: !!state.user.token
})

export default connect(
    mapStateToProps,
    null
)(PrivateRoute)
