export const storage =
  typeof window !== 'undefined'
    ? window && window.localStorage
      ? window.localStorage
      : false
    : false

export const browserDocument =
  typeof window !== 'undefined'
    ? window && window.document
      ? window.document
      : false
    : false

export const browserNavigator =
  typeof window !== 'undefined'
    ? window && window.navigator
      ? window.navigator
      : false
    : false

export const browserWindow =
  typeof window !== 'undefined' && window ? window : false
