import React from 'react'

export default props => (
  <svg width={18} height={18} {...props}>
    <title>{'597D4134-422F-4B04-95FE-A5A1A51B7258'}</title>
    <g fill="none" fillRule="evenodd">
      <g fill={props.color}>
        <path d="M11.803 3.79h-.645c-.833 0-1.51.64-1.51 1.43v.105l1.906-1.283.006-.002c-.002.001-.004.001-.005.003L9.038 6.235 6.457 7.277c-.382.153-.56.57-.397.932.122.27.4.432.69.432a.776.776 0 0 0 .294-.057l2.7-1.09a.744.744 0 0 0 .214-.13L12.57 5.09l.005-.007-.005.007-2.308 2.589a.64.64 0 0 1-.237.167l-.105.042-.272.11.001 8.333c0 .47.404.852.9.852a.921.921 0 0 0 .544-.177 1.352 1.352 0 0 1-.187-.675v-5.081h.6v5.08c0 .472.403.853.9.853.496 0 .9-.381.9-.852 0 0 .007-7.169.007-11.11 0-.79-.677-1.43-1.51-1.43zM11.5 3a1.5 1.5 0 1 0 .001-2.999A1.5 1.5 0 0 0 11.5 3zM2.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
        <path d="M4.736 6.294l-2.002-1.83 1.63 1.193V5.39c0-.79-.676-1.43-1.51-1.43H2.21C1.376 3.96.7 4.6.7 5.39.7 9.458.707 16.5.707 16.5c0 .471.403.853.9.853s.9-.382.9-.853v-5.08h.6v5.08c0 .246-.072.473-.188.675.152.11.34.178.544.178.497 0 .9-.382.9-.853l.001-8.343-.599-.403-.191-.13a.176.176 0 0 1-.03-.026l-1.851-2.11L3.764 7.38c.047.042.1.08.156.11l2.773 1.134a1.112 1.112 0 0 1-.16-.25 1.093 1.093 0 0 1 .315-1.295l-2.112-.786z" />
      </g>
      <path d="M-2 0h18v18H-2z" />
    </g>
  </svg>
)
