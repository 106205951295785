import React from 'react'
import { Route } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import PublicHeader from 'components/layout/PublicHeader'

import Login from '../Auth/Login'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfUse from './TermsOfUse'

export default () => (
  <>
    <Route exact path="/" render={() => (
      <>
        {!isMobileOnly ? <PublicHeader /> : null}
        <Login />
      </>
    )} />
    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
    <Route exact path="/terms-of-use" component={TermsOfUse} />
  </>
)
