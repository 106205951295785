import styled from 'styled-components'

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 184px);
  position: relative;
  width: 100%;

  @media screen and (min-width: 32rem) {
    max-width: 500px;
  }

  @media screen and (min-width: 48rem) {
    max-width: 48rem;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
      --active: #275efe;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, 0.3);
      --border: #bbc1e1;
      --border-hover: #275efe;
      --background: #fff;
      --disabled: #f6f8ff;
      --disabled-inner: #e1e6f9;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: bottom;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--status-border-color, var(--border));
      background: var(--status-border, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

      :after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(disable-transform-effect, ease), opacity var(--disable-opacity, 0.2s);
      }

      :checked {
        --status-border: var(--active);
        --status-border-color: var(--active);
        --disable-opacity: 0.3s;
        --disable-transform: 0.6s;
        --disable-transform-effect: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }

      :disabled {
        --status-border: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;

        :checked {
          --status-border: var(--disabled-inner);
          --status-border-color: var(--border);
        }

        + label {
          cursor: not-allowed;
        }
      }

      :hover {
        :not(:checked) {
          :not(:disabled) {
            --status-border-color: var(--border-hover);
          }
        }
      }

      :focus {
        box-shadow: 0 0 0 var(--focus);
      }

      :not(.switch) {
        width: 21px;

        :after {
          opacity: var(--opacity-after, 0);
        }

        :checked {
          --opacity-after: 1;
        }
      }

      + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }

    input[type='checkbox'] {
      :not(.switch) {
        border-radius: 7px;

        :after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--rotate, 20deg));
        }

        :checked {
          --rotate: 43deg;
        }
      }

      .switch {
        width: 38px;
        border-radius: 11px;

        :after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--active-border, var(--border));
          transform: translateX(var(--x-transform, 0));
        }

        :checked {
          --active-border: var(--active-inner);
          --x-transform: 17px;
        }

        :disabled {
          :not(:checked) {
            :after {
              opacity: 0.6;
            }
          }
        }
      }
    }

    input[type='radio'] {
      border-radius: 50%;
      margin-right: 0.5rem;

      :after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--scaler, 0.7));
      }

      :checked {
        --scaler: 0.5;
      }
    }
  }
`
