import { Button } from 'reactstrap'
import styled from 'styled-components'

export default styled(Button)`
  background-color: white !important;
  border: 1px solid #ddd !important;
  color: #444 !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.04);
  }
`
