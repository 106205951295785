import React, { Component } from 'react'
import ActionsCard from 'components/ActionsCard'
import MediaImage from 'components/MediaImage'
import {
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Badge
} from 'reactstrap'
import styled from 'styled-components'
import Shiitake from 'shiitake' // for overflow ellipsis to work in Firefox
import Icon from 'components/icons'
import AreaForm from './AreaForm'
import { isImage } from 'utils/imageHelpers'
import * as acl from 'utils/acl.service'
import areaSqFt from 'utils/areaSqFt'

const HorizontalSeparator = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${props => props.theme.colors.clarity2};
  opacity: 0.4;
  margin: 6px 0 12px;
  padding: 0;
`

const Label = styled.p`
  font-size: 0.7rem;
  color: ${props => props.theme.colors.grey};
  margin: 3px 0 0;
`

const AreaName = styled.strong`
  font-size: 0.9rem;
`

const Dimension = styled.strong`
  color: ${props => props.theme.colors.clarity};
  margin-top: 3px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px 0;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ShowMoreLess = styled.button`
  padding: 2px 0 !important;
  font-size: 0.8rem !important;
  border: none !important;
`

class AreaCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      isShitake: true,
      isTruncated: false,
      cloneQty: 1
    }
  }

  editArea = () => {
    this.setState({
      showModal: true,
      cloneQty: 1
    })
  }

  archiveArea = () => this.props.onAreaDeleted(this.props.area)

  _toggleModal = () => {
    this.setState({
      showModal: false
    })
  }

  _onSubmit = async values => {
    if (this.props.onAreaEdited) {
      if (values.rating) {
        values.rating = values.rating.id
      }
      this.props.onAreaEdited(values)
    } else {
      alert('Missing Prop: onAreaEdited')
    }
    this.setState({
      showModal: false
    })
  }

  showMore = () => {
    this.setState({
      isShitake: false
    })
  }

  showLess = () => {
    this.setState({
      isShitake: true
    })
  }

  _onTruncationChange = isTruncated => {
    this.setState({
      isTruncated
    })
  }

  _toggleCloneModal = () => {
    this.setState({
      showCloneModal: !this.state.showCloneModal
    })
  }

  cloneSubmit = () => {
    const { area, areaIndex, submitClone } = this.props
    const { cloneQty } = this.state

    this._toggleCloneModal()
    submitClone(area, areaIndex, cloneQty)
    this.setState({ cloneQty: 1 })
  }

  render() {
    const { area, onImageClicked, jobType, showOptions } = this.props
    const { isShitake, isTruncated, cloneQty } = this.state
    let dimensionsDisplay
    if (area.dimensions && area.dimensions.set && area.dimensions.set.length) {
      dimensionsDisplay = (
        <>
          {area.dimensions.set.map((dim, index) => {
            return (
              <Dimension key={index}>
                {parseFloat(dim.width).toFixed(2)} ft. x{' '}
                {parseFloat(dim.depth).toFixed(2)} ft.
              </Dimension>
            )
          })}
        </>
      )
    }

    let renderedMedia = (area.attachments || []).map((image, index) => {
      if (isImage(image.type)) {
        return (
          <MediaImage
            onClick={() => onImageClicked(image.url)}
            size={64}
            src={image.url}
            key={index}
          />
        )
      } else {
        return (
          <a
            href={image.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <Icon
              size={64}
              icon="FaFilePdf"
              color="black"
              style={{ marginLeft: -5 }}
            />
          </a>
        )
      }
    })

    let areaRatingText
    let areaRatingColor

    if (jobType && jobType === 'Inspection') {
      areaRatingText = 'Unreviewed'
      areaRatingColor = 'dark'

      if (area.rating === 1) {
        areaRatingText = "Let's Improve"
        areaRatingColor = 'warning'
      } else if (area.rating === 3) {
        areaRatingText = 'Great Job'
        areaRatingColor = 'success'
      }
    }

    const areaSquareFootage = areaSqFt(area)

    return (
      <>
        <Modal
          isOpen={this.state.showModal}
          toggle={this._toggleModal}
          className="big-modal"
          fade={false}
        >
          <ModalHeader toggle={this._toggleModal}>
            {area.name && <strong>{area.name}</strong>}
          </ModalHeader>
          <ModalBody className="bg-light1">
            <AreaForm
              initialValues={area}
              onImageClicked={onImageClicked}
              onSubmit={this._onSubmit}
              images={area.attachments}
              jobType={jobType}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showCloneModal}
          toggle={this._toggleCloneModal}
          className="big-modal"
          fade={false}
          style={{ maxWidth: 400, marginTop: 200 }}
        >
          <ModalHeader toggle={this._toggleCloneModal}>
            <strong>Clone {area.name ? area.name : 'Area'}</strong>
          </ModalHeader>
          <ModalBody className="bg-light1">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                type="number"
                value={cloneQty}
                min={1}
                max={100}
                step="1"
                onChange={event => {
                  this.setState({ cloneQty: event.target.value })
                }}
              />
              <Button onClick={this.cloneSubmit} style={{ marginLeft: 8 }}>
                Submit
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <ActionsCard
          actions={
            acl.isAllowed('jobs', 'update') && showOptions ? (
              <>
                <DropdownItem onClick={() => this.editArea()}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => this._toggleCloneModal()}>
                  Clone
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => this.archiveArea()}
                >
                  Delete
                </DropdownItem>
              </>
            ) : null
          }
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {area.type && (
              <Label style={{ marginBottom: 4, marginRight: 16 }}>
                {area.type.toUpperCase()}
              </Label>
            )}
            {areaRatingText && (
              <span>
                <Badge color={areaRatingColor} pill>
                  {areaRatingText}
                </Badge>
              </span>
            )}
          </div>
          {area.name && <AreaName>{area.name}</AreaName>}
          {dimensionsDisplay}

          <HorizontalSeparator />
          {renderedMedia && renderedMedia.length > 0 && (
            <div style={{ marginBottom: 6 }}>{renderedMedia}</div>
          )}

          {area.fixtures && area.fixtures.length > 0 && (
            <>
              <Label style={{ fontSize: '0.75rem' }}>FIXTURES</Label>
              <Row style={{ margin: 0 }}>
                <Column>
                  <Label>TYPE</Label>
                </Column>
                <Column>
                  <Label>QTY</Label>
                </Column>
              </Row>
              {area.fixtures.map((fixture, index) => {
                return (
                  <Row style={{ margin: 0 }} key={index}>
                    <Column>
                      <>{fixture && fixture.type}</>
                    </Column>
                    <Column>{fixture && fixture.quantity}</Column>
                  </Row>
                )
              })}
            </>
          )}

          <Row>
            <Column>
              <Label>FLOOR AREA</Label>
              <>
                {areaSquareFootage
                  ? areaSquareFootage.toFixed(2) + ' sq. ft.'
                  : '-'}
              </>
            </Column>

            <Column>
              <Label>FLOOR TYPE</Label>
              {area.floorType || '-'}
            </Column>
          </Row>
          
          
          {/* Hiding for 1.9.9 release
          <Row>
            <Column>
              <Label>DENSITY</Label>
              {area.density || '-'}
            </Column>
          </Row> */}

          {area.notes && (
            <div>
              <Label>NOTES</Label>
              {isShitake ? (
                <>
                  <Shiitake
                    lines={3}
                    onTruncationChange={this._onTruncationChange}
                  >
                    {area.notes}
                  </Shiitake>
                  {isTruncated && (
                    <ShowMoreLess
                      type="button"
                      className="btn btn-link btn-sm"
                      onClick={this.showMore}
                    >
                      Show more
                    </ShowMoreLess>
                  )}
                </>
              ) : (
                <>
                  <div>{area.notes}</div>
                  {isTruncated && (
                    <ShowMoreLess
                      type="button"
                      className="btn btn-link btn-sm"
                      onClick={this.showLess}
                    >
                      Show less
                    </ShowMoreLess>
                  )}
                </>
              )}
            </div>
          )}
        </ActionsCard>
      </>
    )
  }
}

export default AreaCard
