import _ from 'lodash'

// Interval helpers:

const monthsInYear = 12
//const weeksInYear = 52
const quartersInYear = 4
//const monthsInQuarter = 3
//const daysInYear = 365
const weeksInMonth = 4.33 // This seems to be the industry standard.
//const daysInWeek = 7

/*
const intervalConversions = {
  year: {
    year: 1,
    quarter: 1 / quartersInYear,
    month: 1 / monthsInYear,
    week: 1 / weeksInYear,
    day: 1 / daysInYear
  },

  quarter: {
    year: monthsInYear / monthsInQuarter,
    quarter: 1,
    month: 1 / monthsInQuarter,
    week: 1 / (monthsInQuarter * weeksInMonth),
    day: 1 / (daysInWeek * weeksInMonth * monthsInQuarter)
  },

  month: {
    year: monthsInYear,
    quarter: monthsInQuarter,
    month: 1,
    week: 1.0 / weeksInMonth,
    day: 1 / (daysInWeek * weeksInMonth)
  },

  week: {
    year: weeksInYear,
    quarter: weeksInMonth * monthsInQuarter,
    month: weeksInMonth,
    week: 1,
    day: 1 / daysInWeek
  },

  day: {
    year: daysInYear,
    quarter: weeksInMonth * daysInWeek * monthsInQuarter,
    month: daysInWeek * weeksInMonth,
    week: daysInWeek,
    day: 1
  }
}
*/

// Converts a number to a value in its preferred interval (in terms of billing).
// Month is most preferred, followed by Year.
// For example, converts 1 per week to 4.33 per month.
const toPreferredInterval = (qty, interval) => {
  switch (interval) {
    case 'week': {
      return {
        count: weeksInMonth * qty,
        interval: 'month'
      }
    }
    case 'month': {
      return {
        count: qty,
        interval: 'month'
      }
    }
    case 'quarter': {
      return {
        count: qty * quartersInYear,
        interval: 'year'
      }
    }
    case 'year': {
      return {
        count: qty,
        interval: 'year'
      }
    }
    default: {
      return {
        count: qty,
        interval: 'month'
      }
    }
  }
}

const preferredJobCount = (
  jobDoesRepeat,
  jobRepeatNumber,
  jobRepeatInterval
) => {
  if (!jobDoesRepeat) {
    return {
      interval: 'once',
      count: 1
    }
  }

  return toPreferredInterval(jobRepeatNumber || 1, jobRepeatInterval)
}

// Converts a value to preferred interval (months or years) and stores intermediate values.
// Supports non-repeating setups.
const singleToInterval = (
  singleValue,
  repeats,
  jobRepeatNumber,
  jobRepeatInterval
) => {
  if (!repeats) {
    return {
      single: singleValue,
      total: singleValue,
      interval: 'once',
      count: 1
    }
  }
  if (!jobRepeatInterval || !jobRepeatNumber) {
    return null
  }

  const res = toPreferredInterval(jobRepeatNumber, jobRepeatInterval)
  return {
    total: singleValue * res.count,
    interval: res.interval,
    single: singleValue,
    count: res.count
  }
}

// Totals up an array of costs (supports quantity)
const arrayTotal = (array, costField = 'cost', quantityField = 'qty') => {
  if (!array || !array.length) {
    return 0
  }

  let total = 0
  array.forEach(item => {
    if (!item) {
      return
    }

    let qty = item[quantityField] || 1
    if (item[costField]) {
      total += qty * item[costField]
    }
  })

  return total
}

const calculateLabor = values => {
  // Should we use a labor spreadsheet (advanced mode).
  const laborIsAdvanced = _.get(values, 'labor.isAdvanced', false)
  // User input for total man hours per (x)
  const totalManHours = _.get(values, 'labor.totalManHours', null)
  // If using advanced mode, the values for the labor spreadsheet.
  const teamTable = _.get(values, 'labor.sheet', [])

  // Hourly pay (simple mode)
  const hourlyPay = _.get(values, 'labor.hourlyPay', null)
  // Overhead for hourly (simple mode)
  const otherHourlyCosts = _.get(values, 'labor.otherHourlyCosts', null)

  // Indicated interval of labor (daily, weekly etc.)
  const laborInterval = _.get(values, 'labor.recurringInterval')

  // Single hourly cost (simple mode only.)
  let totalHourlyCost = 0

  let manHoursPerJob = 0
  let perJobLaborCost = 0
  let flatLaborCost = 0

  const jobDoesRepeat = _.get(values, 'frequency.repeats')
  const jobRepeatNumber = _.get(values, 'frequency.number')
  const jobRepeatInterval = _.get(values, 'frequency.interval')

  const jobCount = preferredJobCount(
    jobDoesRepeat,
    jobRepeatNumber,
    jobRepeatInterval
  )

  // Calculate man hours per job.
  if (laborIsAdvanced) {
    manHoursPerJob = 0
    perJobLaborCost = 0

    // Raw total of man hours per job and per job labor costs.
    teamTable.forEach(person => {
      if (!person || !person.hours) {
        return
      }
      let qty = person.qty || 1
      manHoursPerJob += qty * person.hours
      if (person.hourlyPay) {
        perJobLaborCost += qty * person.hours * person.hourlyPay
      }
    })

    // Convert per job totals based on job interval difference from labor interval.
    if (jobDoesRepeat && jobRepeatInterval && laborInterval) {
      if (laborInterval === 'day') {
        // Not needed.
        //        manHoursPerJob = manHoursPerJob
        //        perJobLaborCost = perJobLaborCost
      } else {
        manHoursPerJob =
          toPreferredInterval(manHoursPerJob, laborInterval).count /
          jobCount.count
        perJobLaborCost =
          toPreferredInterval(perJobLaborCost, laborInterval).count /
          jobCount.count
      }
    }
  } else {
    // Basic hourly pay.
    if (hourlyPay) {
      totalHourlyCost += hourlyPay
    }

    // Other hourly costs.
    if (otherHourlyCosts && otherHourlyCosts.length) {
      otherHourlyCosts.forEach(o => {
        if (!o) {
          return
        }
        if (o.rate) {
          totalHourlyCost += o.rate
        }
      })
    }

    // Convert total man hours based on job interval from labor interval.
    if (jobDoesRepeat && jobRepeatInterval && laborInterval) {
      if (laborInterval === 'day') {
        manHoursPerJob = totalManHours
      } else {
        manHoursPerJob =
          toPreferredInterval(totalManHours, laborInterval).count /
          jobCount.count
      }
    } else {
      manHoursPerJob = totalManHours
    }

    if (hourlyPay && manHoursPerJob) {
      perJobLaborCost = manHoursPerJob * totalHourlyCost
      flatLaborCost = hourlyPay * manHoursPerJob
    }
  }

  return {
    totalManHours,
    laborIsAdvanced,
    teamTable,
    hourlyPay,
    otherHourlyCosts,
    flatLaborCost,
    laborInterval,
    manHoursPerJob,
    perJobLaborCost
  }
}

// Massive calculation function, accepts per service values only.
const calculate = values => {
  if (!values || !values.billingMethod) {
    return null
  }

  // Repetition of job.
  const jobDoesRepeat = _.get(values, 'frequency.repeats')
  const jobRepeatNumber = _.get(values, 'frequency.number')
  const jobRepeatInterval = _.get(values, 'frequency.interval')

  // Number of jobs in a preferred interval. (Non repeating jobs will become "once")
  const jobCount = preferredJobCount(
    jobDoesRepeat,
    jobRepeatNumber,
    jobRepeatInterval
  )

  // Material costs.
  const oneTimeCostsArray = _.get(values, 'materials.oneTimeCosts')
  const recurringCostsArray = _.get(values, 'materials.recurringCosts')
  const recurringCostsInterval = _.get(values, 'materials.recurringInterval')
  const recurringCostsAreBillable = _.get(
    values,
    'materials.billRecurringCosts',
    false
  )
  const recurringMargin = _.get(values, 'materials.billRecurringMargin', 0)
  const oneTimeCostsAreBillable = _.get(
    values,
    'materials.billOneTimeCosts',
    false
  )
  const oneTimeMargin = _.get(values, 'materials.billOneTimeMargin', 0)

  // Calculate totals for one time costs.
  let oneTimeCostsTotal = arrayTotal(oneTimeCostsArray)
  // Total of all recurring costs.
  let recurringCostsTotal = arrayTotal(recurringCostsArray)

  const {
    totalManHours,
    laborIsAdvanced,
    teamTable,
    //    hourlyPay,
    otherHourlyCosts,
    flatLaborCost,
    perJobLaborCost,
    manHoursPerJob
  } = calculateLabor(values)

  let perJobCharge = 0,
    perJobMaterialsCost = 0,
    perJobProfit = 0,
    charges = {
      visible: true,
      baseRate: 0,
      suppliesAndMaterials: 0,
      total: null
    },
    costs = {
      visible: true,
      labor: 0,
      hours: 0,
      recurring: 0,
      oneTime: 0,
      total: null
    }

  // Break down recurring costs to per job.
  let recurringCostsPerJob = 0
  if (jobDoesRepeat && jobRepeatInterval && recurringCostsInterval) {
    if (recurringCostsInterval === 'day') {
      recurringCostsPerJob = recurringCostsTotal
    } else {
      recurringCostsPerJob =
        toPreferredInterval(recurringCostsTotal, recurringCostsInterval).count /
        (jobCount.count || 1)
    }

    perJobMaterialsCost += recurringCostsPerJob
  } else {
    perJobMaterialsCost += oneTimeCostsTotal
  }

  const billingMethod = values.billingMethod
  switch (billingMethod) {
    case 'hourly':
      {
        const marginBased = _.get(values, 'billing.perHour.marginBased', false)

        if (
          (!laborIsAdvanced && !totalManHours) ||
          (laborIsAdvanced && !teamTable.length)
        ) {
          return null
        }

        if (marginBased) {
          let margin = _.get(values, 'billing.perHour.laborMargin', null)
          if (!perJobLaborCost) {
            return null
          }

          perJobCharge = perJobLaborCost + perJobLaborCost * margin
          charges.baseRate = perJobCharge
        } else {
          let hourlyRate = _.get(values, 'billing.perHour.hourlyRate', null)
          if (!hourlyRate) {
            return null
          }

          perJobCharge = hourlyRate * manHoursPerJob
          charges.baseRate = perJobCharge
        }
      }
      break
    case 'perSqFt':
      {
        const useFloorTypesTable = _.get(values, 'billing.perSqFt.isAdvanced')

        if (useFloorTypesTable) {
          const floorTypesTable = _.get(values, 'billing.perSqFt.sheet')
          if (!floorTypesTable || !floorTypesTable.length) {
            return null
          }

          perJobCharge = 0
          floorTypesTable.forEach(line => {
            if (line.area && line.price) {
              perJobCharge += line.area * line.price
            }
          })
          charges.baseRate = perJobCharge
        } else {
          const ratePerSqFt = _.get(values, 'billing.perSqFt.rate')
          const totalSqFt = _.get(values, 'billing.perSqFt.totalSqFt')
          if (!ratePerSqFt || !totalSqFt) {
            return null
          }
          perJobCharge = totalSqFt * ratePerSqFt
          charges.baseRate = perJobCharge
        }
      }
      break
    case 'perFixture':
      {
        const useFixtureTable = _.get(values, 'billing.perFixture.isAdvanced')

        if (useFixtureTable) {
          const fixtureTable = _.get(values, 'billing.perFixture.sheet')
          if (!fixtureTable || !fixtureTable.length) {
            return null
          }

          perJobCharge = 0
          fixtureTable.forEach(line => {
            if (line.qty && line.price) {
              perJobCharge += line.qty * line.price
            }
          })
          charges.baseRate = perJobCharge
        } else {
          const ratePerFixture = _.get(values, 'billing.perFixture.rate')
          const totalFixtureCount = _.get(
            values,
            'billing.perFixture.totalFixtureCount'
          )
          if (!ratePerFixture || !totalFixtureCount) {
            console.log('Not enough info for fixture based charges..')
            return null
          }

          perJobCharge = ratePerFixture * totalFixtureCount
          charges.baseRate = perJobCharge
        }
      }
      break
    default: {
      return null
    }
  }

  // Add the cost if billable.
  if (recurringCostsAreBillable) {
    const perJobMaterialsCharge =
      perJobMaterialsCost + perJobMaterialsCost * recurringMargin
    perJobCharge += perJobMaterialsCharge
    charges.suppliesAndMaterials = perJobMaterialsCharge
  }

  if (oneTimeCostsAreBillable && !jobDoesRepeat) {
    const perJobMaterialsCharge =
      perJobMaterialsCost + perJobMaterialsCost * oneTimeMargin
    perJobCharge += perJobMaterialsCharge
    charges.suppliesAndMaterials = perJobMaterialsCharge
  }

  const perJobTotalCost = perJobLaborCost + perJobMaterialsCost

  if (perJobLaborCost && perJobCharge) {
    perJobProfit = perJobCharge - perJobTotalCost
  }

  costs.labor = perJobLaborCost * jobCount.count
  costs.flatLaborCost = flatLaborCost * jobCount.count
  costs.recurring = recurringCostsPerJob * jobCount.count
  costs.oneTime = oneTimeCostsTotal
  costs.total =
    jobCount && jobCount.interval && jobCount.interval === 'once'
      ? costs.labor + costs.oneTime
      : costs.labor + costs.recurring
  costs.hours = manHoursPerJob * jobCount.count
  if (otherHourlyCosts && otherHourlyCosts.length) {
    costs.otherHourly = []
    otherHourlyCosts.forEach(o => {
      if (!o || !o.rate) {
        return
      }
      costs.otherHourly.push({
        name: o.name,
        rate: o.rate * manHoursPerJob * jobCount.count
      })
    })
  }
  
  if (
    values.billingMethod === 'perSqFt' &&
    !_.get(values, 'billing.perSqFt.includeFrequency')
  ) {
    charges.suppliesAndMaterials = charges.suppliesAndMaterials * jobCount.count
  } else {
    charges.baseRate = charges.baseRate * jobCount.count
    charges.suppliesAndMaterials = charges.suppliesAndMaterials * jobCount.count
  }

  charges.total = charges.baseRate + charges.suppliesAndMaterials

  let finalProfits
  if (perJobTotalCost) {
    finalProfits = singleToInterval(
      perJobProfit,
      jobDoesRepeat,
      jobRepeatNumber,
      jobRepeatInterval
    )
  }


  // TODO: Don't like doing profit this way in case of perSqFt without frequency... should also consider not doing finalProfits like its done above, using singleToInterval by default.
  if (
    values.billingMethod === 'perSqFt' &&
    !_.get(values, 'billing.perSqFt.includeFrequency')
    && charges.total && costs.total && finalProfits
  ) {
    finalProfits.total = charges.total - costs.total
  }

  let result = {
    costs: costs,
    charges: charges,
    profits: finalProfits,
    jobCount: jobCount
  }

  let oneTimeCostPaymentSpread = _.get(
    values,
    'materials.oneTimeCostsMeta.spreadNumber',
    1
  )

  // Generate chart data for 1 year (if the job repeats)
  let chartData
  if (result && result.charges && result.jobCount.interval === 'month') {
    chartData = []
    for (var i = 0; i < monthsInYear; ++i) {
      const isFirstMonth = i === 0

      let dataPoint = {
        name: `${i + 1}`
      }

      dataPoint.invoice = result.charges.total
      if (
        oneTimeCostsAreBillable &&
        i < oneTimeCostPaymentSpread &&
        costs.oneTime > 0
      ) {
        dataPoint.invoice += costs.oneTime / oneTimeCostPaymentSpread
        if (oneTimeMargin) {
          dataPoint.invoice += costs.oneTime * oneTimeMargin
        }
        result.charges.adjustedOneTimeCostsTotal = dataPoint.invoice
        result.charges.oneTimePaymentSpread = oneTimeCostPaymentSpread
      }

      if (result.costs) {
        dataPoint.cost = result.costs.total
        if (isFirstMonth && result.costs.oneTime > 0) {
          dataPoint.cost += costs.oneTime
        }
      }

      dataPoint.profit = dataPoint.invoice - dataPoint.cost

      if (isFirstMonth) {
        dataPoint.invoiceSum = dataPoint.invoice
        dataPoint.costSum = dataPoint.cost
        dataPoint.profitSum = dataPoint.profit
      } else {
        let lastPoint = i > 0 ? chartData[i - 1] : null
        dataPoint.invoiceSum = lastPoint.invoiceSum + dataPoint.invoice
        dataPoint.costSum = lastPoint.costSum + dataPoint.cost
        dataPoint.profitSum = lastPoint.profitSum + dataPoint.profit
      }

      chartData.push(dataPoint)
    }
  }

  if (chartData) {
    result.chartData = chartData
  }

  return result
}

export default calculate
