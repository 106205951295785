import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// import Image from '../../image'
// import Link from '../../link'
import { Title } from './ui'

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  position: relative;
  width: auto;
`

const RestartContainer = styled.a`
  color: #0a0a0a;
  max-width: 320px;
  width: 100%;

  :active,
  :focus,
  :hover {
    color: #0a0a0a;
  }

  @media only screen and (min-width: 32rem) {
    max-width: 480px;
    :first-of-type {
      margin-right: 4rem;
    }
  }
`

// const LinkContainer = styled(Link)`
//   color: #0a0a0a;
//   margin-bottom: 2rem;
//   max-width: 320px;
//   width: 100%;

//   :active,
//   :focus,
//   :hover {
//     color: #0a0a0a;
//   }

//   @media only screen and (min-width: 32rem) {
//     max-width: 480px;
//   }
// `

const ThankYouCard = styled.div`
  align-items: center;
  display: flex;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 2rem auto 0 auto;
  border-radius: 22px;
  transition: box-shadow 250ms ease 0s;

  :active,
  :focus,
  :hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px;
  }

  .subtitle.is-4 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 32rem) {
    height: 480px;

    .subtitle.is-4 {
      font-size: 1.5rem;
    }
  }
`

const ThankYouCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 1rem 1.5rem;
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @media only screen and (min-width: 64rem) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`

const Description = styled.div`
  font-size: 1.25rem;
  text-align: center;
`

const Text = styled.p`
  background-color: #0b64e9;
  align-items: center;
  border-radius: 4px;
  box-shadow: none;
  color: white !important;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5rem;
  justify-content: center;
  line-height: 1.5;
  padding-bottom: calc(0.375rem - 1px);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: calc(0.375rem - 1px);
  position: relative;
  text-align: center;
  transition: transform 0.4s ease;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 144px;

  :active,
  :focus,
  :hover {
    transform: translateY(-4px);
  }

  @media only screen and (min-width: 45rem) {
    font-size: 1.25rem;
    height: 3rem;
    padding: 1rem;
    width: 12rem;
  }
`

export const FinishedStage = ({ onStartOver }) => {
  // const { cover, proposals } = useStaticQuery(graphql`
  //   query QuickBidQuery {
  //     cover: file(relativePath: { eq: "assets/mockups/estimator-projections.png" }) {
  //       ...SharpImage
  //     }
  //     proposals: file(relativePath: { eq: "assets/mockups/desktop-proposal.png" }) {
  //       ...SharpImage
  //     }
  //   }
  // `)

  return (
    <Container>
      <Title>You're On The Path To Winning More Bids</Title>
      <Description>The next step is submitting your bid with a formal Proposal.</Description>

      <Actions>
        <RestartContainer onClick={onStartOver}>
          <ThankYouCard>
            {/* <Image
              alt={`Use Quickbid again`}
              file={cover}
              style={{
                borderRadius: '22px',
                marginTop: '1rem',
                width: '90%'
              }}
            /> */}
            <ThankYouCardContent>
              <Description style={{ marginBottom: '1.5rem' }}>Create Another Bid</Description>

              <Text>New Bid</Text>
            </ThankYouCardContent>
          </ThankYouCard>
        </RestartContainer>
        {/* <LinkContainer to={`/features/proposal-generator`}>
          <ThankYouCard style={{ marginBottom: '2rem' }}>
            <Image
              alt={`route proposal generator illustration`}
              file={proposals}
              style={{
                marginTop: '1rem',
                width: '90%'
              }}
            />
            <ThankYouCardContent>
              <Description style={{ marginBottom: '1.5rem' }}>Try Proposal Generator</Description>

              <Text>Learn More</Text>
            </ThankYouCardContent>
          </ThankYouCard>
        </LinkContainer> */}
      </Actions>
    </Container>
  )
}

export default FinishedStage
