import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import teamSelection from 'assets/img/team-selection.svg'
import { refreshToken } from 'api'
import { EmploymentSelectCard } from './EmploymentSelectCard'

const StyledImg = styled.img`
  width: 100%;
`

class EmploymentSelection extends Component {

  _onSelectCompany = async emp => {
    await this.props.refreshToken(emp.company._id)
    await this.props.fetchBillingInfo()
    await this.props.didSelectCompany()
    // window reload causing problems, what problem did it solve before?
    // window.location.reload()
  }

  render() {

    return (
      <div style={{ padding: '50px 100px'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'middle',
            flex: 1,
          }}
        >
          <div style={{
            maxWidth: 400, 
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <StyledImg src={teamSelection} alt="team selection" />
          </div>
          <EmploymentSelectCard 
            employment={this.props.employment}
            onSelect={this._onSelectCompany}
          />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      refreshToken
    },
    dispatch
  ),
  didSelectCompany: () => {
    dispatch({
      type: 'DID_SELECT_COMPANY_AFTER_LOGIN'
    })
  }
})

export default connect(
  null,
  mapDispatchToProps
)(EmploymentSelection)
