import { handlerErrors } from '../utils'

const initialState = {
  loading: false,
  acl: null,
  error: null
}

const acl = (state = initialState, action) => {
  switch (action.type) {
    case 'ACL_LOAD':
      return {
        ...state,
        loading: true
      }
    case 'ACL_LOAD_SUCCESS':
      return {
        loading: false,
        acl: action.payload.data,
        error: null
      }
    case 'ACL_LOAD_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Loading ACL failed.')
      }
    default:
      return state
  }
}

export default acl
