const lowRating = 1
const highRating = 5

function generateRatings() {
  let ratings = ['']
  for (let i = lowRating; i <= highRating; i++) {
    ratings.push(String(i))
  }
  return ratings
}

export default generateRatings()
