import React from 'react'
import { CardBody, ListGroup, ListGroupItem, Badge } from 'reactstrap'
import CustomInfo from 'components/CustomInfo'
import DarkCard from './DarkCard'

const listStyle = {
  backgroundColor: 'transparent',
  color: '#808390',
  borderColor: 'rgba(255,255,255,0.1)',
  paddingLeft: 5,
  paddingRight: 5,
  display: 'flex',
  flexDirection: 'row'
}

const Info = ({
  employee,
  onSubmit,
  isOwner,
  currentEmployeeId,
  transferOwnership
}) => {
  if (!employee) {
    return null
  }

  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <DarkCard>
        <CardBody>
          <ListGroup flush style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Company</span>
              <span style={{ flex: 5, color: 'white' }}>
                {employee.company.name}
              </span>
            </ListGroupItem>
            {/*
              <ListGroupItem style={listStyle}>
                <span style={{ flex: 1 }}>Position</span>

                <span style={{ flex: 5, color: 'white' }}>
                  {employee.position}
                </span>
              </ListGroupItem>

              */}
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Address</span>
              <span style={{ flex: 5, color: 'white' }}>
                {employee.address
                  ? employee.address.fullAddress
                  : 'Not Available'}
              </span>
            </ListGroupItem>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Email</span>
              <span style={{ flex: 5, color: 'white' }}>{employee.email}</span>
            </ListGroupItem>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Phone</span>
              <span style={{ flex: 5, color: 'white' }}>
                {employee.phone ? employee.phone : 'Not Available'}
              </span>
            </ListGroupItem>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Labels</span>
              <span style={{ flex: 5, color: 'white' }}>
                {employee &&
                  employee.labels &&
                  employee.labels.length > 0 &&
                  employee.labels.map((label, index) => {
                    return (
                      <Badge
                        pill
                        key={index}
                        style={{
                          marginTop: 0,
                          marginRight: 2,
                          backgroundColor: label.color || 'lightgrey'
                        }}
                      >
                        {label.name}
                      </Badge>
                    )
                  })
                }
              </span>
            </ListGroupItem>
            <CustomInfo
              data={employee.customInfo}
              onEdit={data => onSubmit({ ...employee, customInfo: data })}
            />
            {isOwner && currentEmployeeId !== employee._id && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span
                  style={{
                    color: 'red',
                    cursor: 'pointer'
                  }}
                  onClick={() => transferOwnership(employee)}
                >
                  Transfer Ownership
                </span>
              </div>
            )}
          </ListGroup>
        </CardBody>
      </DarkCard>
    </div>
  )
}

export default Info
