import React from 'react'
import Color from './knobs/Color'
import Number from './knobs/Number'

export default ({ element, onChange }) => (
  <>
    <Color
      data={element.data}
      label="Line Color"
      field="color"
      onChange={onChange}
    />
    <Number
      label="Height"
      data={element.data}
      step={1}
      min={1}
      max={8}
      field="height"
      onChange={onChange}
    />
  </>
)
