import styled from 'styled-components'

export const Review = styled.form`
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 184px);
  position: relative;
  width: 100%;

  fieldset {
    text-align: center;
  }

  @media screen and (min-width: 32rem) {
    max-width: 500px;
  }

  @media screen and (min-width: 48rem) {
    max-width: 48rem;
  }
`
