// SUBMIT MERCHANDISE ORDER
export const orderMerchandise = body => async dispatch =>
  dispatch({ 
    type: 'SEND_MERCHANDISE_ORDER',
    payload: {
      request: {
        url: `/api/shop/merchandise`,
        method: 'post',
        data: body
      }
    }
  })

// SUBMIT SUPPLIES ORDER
export const orderSupplies = body => async dispatch =>
  dispatch({ 
    type: 'SEND_SUPPLIES_ORDER',
    payload: {
      request: {
        url: `/api/shop/supplies`,
        method: 'post',
        data: body
      }
    }
  })
