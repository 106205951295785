export const getLocationTemplates = () => dispatch =>
  dispatch({
    type: 'GET_LOCATION_TEMPLATES',
    payload: {
      request: {
        url: `/api/library/locations`
      }
    }
  })

export const submitLocationTemplate = template => {
  if (template._id) {
    return updateLocationTemplate(template)
  } else {
    return createLocationTemplate(template)
  }
}

export const createLocationTemplate = template => dispatch =>
  dispatch({
    type: 'CREATE_LOCATION_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/locations`,
        method: 'post',
        data: template
      }
    }
  })

export const updateLocationTemplate = template => dispatch =>
  dispatch({
    type: 'UPDATE_LOCATION_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/locations/${template._id}`,
        method: 'patch',
        data: template
      }
    }
  })

export const removeLocationTemplate = templateId => dispatch =>
  dispatch({
    type: 'REMOVE_LOCATION_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/locations/${templateId}/delete`,
        method: 'patch'
      }
    }
  })
