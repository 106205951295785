import React from 'react'
import Text from './knobs/Text'
import Boolean from './knobs/Boolean'

export default ({ element, onChange }) => (
  <div>
    <Boolean
      data={element.data}
      label="Show Heading"
      onChange={onChange}
      field="showHeading"
    />
    {element.data && element.data.showHeading &&
      <Text
        field="headingText"
        data={element.data}
        onChange={onChange}
      />
    }
    <Text
      label="Service Provider Name"
      field="serviceProvider"
      data={element.data}
      onChange={onChange}
    />
    <Text
      label="Client Name"
      field="client"
      data={element.data}
      onChange={onChange}
    />
  </div>
)
