import React from 'react'
import { FiCalendar, FiCheck, FiClock, FiX } from 'react-icons/fi'

export default ({ status, ...rest }) => {

  let icon
  switch (status) {
    case 'assigned':
      icon = <FiCalendar />

      break
    case 'in progress':
      icon = <FiClock />

      break
    case 'completed':
      icon = <FiCheck />

      break
    case 'cancelled':
    case 'canceled':
      icon = <FiX />
      break
    default:
      icon = <FiCalendar />
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {icon}
    </div>
  )
}
