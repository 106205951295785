import React, { useState } from 'react'
import { DateTimePicker } from 'react-widgets'
import moment from 'moment-timezone'
import momentLocalizer from 'react-widgets-moment'
import FieldSet from './FieldSet'

import 'react-widgets/dist/css/react-widgets.css'

moment.locale('en')
momentLocalizer(moment)

const DateField = ({
  input: { value, ...restInput },
  format,
  label,
  timezone,
  localTimezone,
  hideTimezoneWarning,
  ...rest

}) => {
  const [typePicker, setTypePicker] = useState('')
  let displayedTimezone
  if (localTimezone && localTimezone.timeZoneId) {
    displayedTimezone = localTimezone.timeZoneId
  } else {
    displayedTimezone = moment.tz.guess()
  }

  if (!timezone) {
    timezone = displayedTimezone
  }

  if (!value) {
    value = moment.tz(localTimezone)
  }

  let timezoneWarning = null
  if (timezone !== localTimezone) {
    const scheduledDate = moment.tz(value, timezone)
    const localScheduledDate = moment.tz(value, localTimezone)

    timezoneWarning = (
      <p style={{ margin: '1rem' }}>
        Your local time for this Job is set to{' '}
        <strong>
          {`${localScheduledDate.format(
            'MMMM Do YYYY, h:mm A'
          )} (${localTimezone}).`}
        </strong>
        <br />
        This Job will actually be scheduled on{' '}
        <strong>{`${scheduledDate.format(
          'MMMM Do YYYY, h:mm A'
        )} (${timezone}).`}</strong>
      </p>
    )
  }

  const handleClick = (event) => {
    const inputElement = event.target

    if (inputElement.tagName === 'INPUT') {
      const spanElement = inputElement.nextElementSibling
      const datePickerElement = spanElement.querySelector("button[title='Select date']")

      if (datePickerElement !== null) {
        datePickerElement.click()
      }
    }
  }

  const handleKeyDown = (event) => {

    event.preventDefault()


  }

  return (
    <FieldSet label={label} {...rest}>
      <DateTimePicker
        format={format || 'LLL'}
        value={moment(value).toDate()}
        {...restInput}
        {...rest}
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault()
        }}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      />
      {!hideTimezoneWarning ? timezoneWarning : null}
    </FieldSet>
  )
}

export default DateField
