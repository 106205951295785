import React, { Component } from 'react'
import { connect } from 'react-redux'
import { submitEmployee } from 'api'
import { axiosClient } from 'store'

import EmployeeForm from './EmployeeForm'
import { trackEvent } from 'api/clientHub'
import { USER_CREATE_EMPLOYEE_SUCCESS } from 'store/analyticsActions/types/employeeTypes'

class Employee extends Component {
  state = {
    ready: false,
    employee: {
      role: 'member'
    }
  }

  async componentDidMount() {
    const { match } = this.props
    const id = match.params.employeeId
    if (id) {
      return this._load(id)
    } else {
      this.setState({
        ready: true
      })
    }
  }

  _load = async id => {
    const result = await axiosClient.get(`/api/employees/${id}`)
    if (result && result.data) {
      this.setState({
        ready: true,
        employee: result.data
      })
    } else {
      this.setState({
        ready: true
      })
    }
  }

  _save = async values => {
    await this.props.submitEmployee(values)
    trackEvent({ typeEvent: USER_CREATE_EMPLOYEE_SUCCESS })
    this.props.history.push('/dashboard/employees/')
  }

  render() {
    const { ready, employee } = this.state
    if (!ready) {
      return null
    }

    const title =
      employee && employee._id
        ? `Update ${employee.name && employee.name.full !== 'undefined'
          ? employee.name.full
          : 'Unnamed'
        }`
        : 'New Employee'

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <h1 className="text-light">{title}</h1>
        <EmployeeForm
          onSubmit={this._save}
          initialValues={this.state.employee}
          hideRoleInput={this.state.employee?.role === 'owner'}
          _id={this.state.employee ? this.state.employee._id : null}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  company: state.user.currentEmployment.company
})

export default connect(
  mapStateToProps,
  { submitEmployee }
)(Employee)
