import React from 'react'

export default props => (
  <svg width={18} height={16} {...props}>
    <title>{'3E349208-AC55-428C-B965-9F0922B86018'}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.27 6.533a3.427 3.427 0 0 0 4.88-3.096A3.427 3.427 0 0 0 11.727.013c-.52 0-1.014.117-1.456.326a5.053 5.053 0 0 1 .783 4.75 5.053 5.053 0 0 1-.783 1.443zM11.122 8.525a7.976 7.976 0 0 1 1.637 1.723 7.862 7.862 0 0 1 1.423 4.52c0 .287-.05.562-.141.818h3.14a.818.818 0 0 0 .819-.818 6.28 6.28 0 0 0-6.878-6.243zM6.273 6.86A3.419 3.419 0 0 0 9 5.501c.436-.575.695-1.29.695-2.066 0-.775-.259-1.49-.695-2.065A3.419 3.419 0 0 0 6.273.014 3.427 3.427 0 0 0 2.85 3.436 3.427 3.427 0 0 0 6.273 6.86zM.818 15.586h10.91a.818.818 0 0 0 .817-.818 6.261 6.261 0 0 0-1.913-4.505 6.236 6.236 0 0 0-4.36-1.767A6.28 6.28 0 0 0 0 14.767c0 .452.366.818.818.818z"
        fill={props.color}
      />
      <path d="M0-1h18v18H0z" />
    </g>
  </svg>
)
