import DropdownList from 'react-widgets/lib/DropdownList'

let colors = [
    { id: 0, name: 'orange'},
    { id: 1, name: 'purple'},
    { id: 2, name: 'red' },
    { id: 3, name: 'blue' },
  ];

const styleSelect2 = {
    width : '100%'
}


const Select2 = ({
    data, 
    onChange, 
    placeholder = '', 
    filter = 'contains',
    handleChange
}) => {
    return (  
        <>
            <DropdownList
                style = {styleSelect2}
                data={data} 
                textField='name'
                filter = {filter}
                placeholder = {placeholder}
                onChange={handleChange}
            />
        </>
    );
}
 
export default Select2;