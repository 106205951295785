import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { axiosClient } from 'store'
import { CloseButton } from 'components/lib/Button'
import { connectConfirmation } from 'components/confirm'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { UncontrolledTooltip } from 'reactstrap'

import Elements from '../Elements'

const MenuTile = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 2px;
  font-size: 12px !important;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.dark0};
  color: ${props => props.theme.colors.light0};
  cursor: pointer;
  transition: background-color 250ms ease;
  ${props =>
    props.isDragging
      ? `
  border: dotted 2px #999;
  `
      : null}

  &:hover {
    background-color: ${props => lighten(0.03, props.theme.colors.dark0)};
  }
`

const Clone = styled(MenuTile)`
  ~ div {
    transform: none !important;
  }
`

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
`

const iconStyle = {
  fontSize: 24,

  marginRight: 8
}

class ElementsMenu extends React.Component {
  state = {
    customElements: []
  }

  componentDidMount() {
    this.fetchCustomElements()
  }

  fetchCustomElements = async () => {
    try {
      const result = await axiosClient.get('/api/proposals/customElements')

      if (result.data && result.data.data) {
        const filteredElements = result.data.data.filter(
          el => el.type !== 'scope_of_work'
        )
        this.setState({
          customElements: filteredElements
        })
      }
    } catch (ex) {
      console.error('Error fetching custom elements.', ex)
    }
  }

  archiveCustomElement = async element => {
    if (!element) {
      return
    }
    try {
      await axiosClient.patch(
        `/api/proposals/customElements/archive/${element._id}`
      )

      await this.fetchCustomElements()
    } catch (ex) {
      console.error('Error archiving custom element.', ex)
    }
  }

  render() {
    const { onAdd, proposal } = this.props
    const { customElements } = this.state

    return (
      <div>
        <Droppable isDropDisabled={true} droppableId="element-menu-droppable">
          {(provided, snapshot) => (
            <MenuList
              {...provided.droppableProps}
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {Elements.map((e, index) => {
                const contents = (
                  <>
                    <>
                      {React.createElement(e.icon, {
                        style: iconStyle
                      })}
                    </>
                    {e.label}
                  </>
                )

                return (
                  <Draggable
                    key={`draggable-menu-item-index-${index}`}
                    draggableId={`draggable-menu-item-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <MenuTile
                          id={`element-menu-item-${index}`}
                          key={e.type}
                          color="dark"
                          disabled={e.enabledIf ? !e.enabledIf(proposal) : null}
                          onClick={() => {
                            let newElement
                            if (e.create && typeof e.create === 'function') {
                              newElement = e.create(proposal)
                            } else {
                              newElement = {
                                ...e.initialData
                              }
                            }

                            return onAdd({
                              type: e.type,
                              data: newElement
                            })
                          }}
                          isDragging={snapshot.isDragging}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                        >
                          {contents}
                          {e.tooltip && (
                            <UncontrolledTooltip
                              delay={1000}
                              target={`element-menu-item-${index}`}
                              style={{ maxWidth: 300 }}
                            >
                              {e.tooltip}
                            </UncontrolledTooltip>
                          )}
                        </MenuTile>
                        {snapshot.isDragging && <Clone>{contents}</Clone>}
                      </>
                    )}
                  </Draggable>
                )
              })}
            </MenuList>
          )}
        </Droppable>
        {customElements && customElements.length > 0 && (
          <div>
            <p className="text-light" style={{ marginTop: 8, marginBottom: 4 }}>
              Custom Elements
            </p>
            <Droppable
              isDropDisabled={true}
              droppableId="custom-elements-droppable"
            >
              {(provided, snapshot) => (
                <MenuList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {customElements.map((e, index) => {
                    const origin = Elements.find(
                      origin => origin.type === e.type
                    )
                    let icon
                    if (origin) {
                      icon = React.createElement(origin.icon, {
                        style: iconStyle
                      })
                    }

                    const contents = (
                      <>
                        <CloseButton
                          style={{
                            position: 'absolute',
                            margin: 0,
                            top: 0,
                            right: 2
                          }}
                          onClick={evt => {
                            evt.cancelBubble = true
                            if (evt.stopPropagation) {
                              evt.stopPropagation()
                            }
                            this.props.confirm(
                              'Archive Custom Element',
                              'Are you sure you want to archive this custom element?',
                              () => this.archiveCustomElement(e)
                            )
                          }}
                        />
                        {icon && <div>{icon}</div>}
                        {e.name}
                      </>
                    )
                    return (
                      <Draggable
                        key={`draggable-custom-element-item-index-${index}`}
                        draggableId={`draggable-custom-element-item-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <MenuTile
                              key={e.name}
                              color="dark"
                              disabled={
                                e.enabledIf ? !e.enabledIf(proposal) : null
                              }
                              onClick={() => {
                                let data = {
                                  ...e.data
                                }

                                return onAdd({
                                  type: e.type,
                                  data
                                })
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              style={provided.draggableProps.style}
                            >
                              {contents}
                            </MenuTile>
                            {snapshot.isDragging && <Clone>{contents}</Clone>}
                          </>
                        )}
                      </Draggable>
                    )
                  })}
                </MenuList>
              )}
            </Droppable>
          </div>
        )}
      </div>
    )
  }
}

export default connectConfirmation(ElementsMenu)
