import React from 'react'
import styled from 'styled-components'
import { Button, Input } from 'reactstrap'
import { DropdownList } from 'react-widgets'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CloseButton } from './lib/Button'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Icon from 'components/icons'
import { axiosClient } from 'store'
import { toast } from 'react-toastify'

const Card = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
`

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer

  display: isDragging ? 'table' : null,

  backgroundColor: isDragging ? 'white' : null,

  borderStyle: isDragging ? 'solid' : null,
  borderColor: isDragging ? '#e2e2e2' : null,
  borderWidth: isDragging ? '1px' : null,

  // styles we need to apply on draggables
  ...draggableStyle
})

class CustomInfo extends React.Component {
  static propTypes = {
    allowEditing: PropTypes.bool,
    onEdit: PropTypes.func,
    data: PropTypes.object
  }

  static defaultProps = {
    allowEditing: true
  }

  state = {
    editing: false,
    formValues: null,
    templates: []
  }

  inputRefs = []

  componentDidMount() {
    this.getTemplates()
  }

  getTemplates = async () => {
    const templates = await axiosClient.get('/api/library/custom-info')
    this.setState({
      templates: templates.data
    })
  }

  createTemplate = async () => {
    const { formValues, templates } = this.state

    if (!formValues || !formValues.length) {
      return toast('Add some info rows to create a template.')
    }

    let name = window.prompt('Please enter a name for your template.', '')

    if (!name || !name.trim().length) {
      return
    }

    let data = {
      customInfo: {
        data: formValues
      },
      name: name.trim()
    }

    const newTemplate = await axiosClient.post('/api/library/custom-info', data)

    if (newTemplate.data) {
      this.setState({
        templates: [...templates, newTemplate.data]
      })

      toast('Custom info template successfully created.')
    }
  }

  applyTemplate = () => {
    const { selectedTemplate, formValues } = this.state

    if (!selectedTemplate) {
      return
    }

    const clonedTemplate = _.cloneDeep(selectedTemplate)

    this.setState({
      formValues: [...formValues, ...clonedTemplate.customInfo.data]
    })
  }

  updateTemplate = async () => {
    const { selectedTemplate, formValues, templates } = this.state
    if (!selectedTemplate) {
      return
    }

    let data = {
      ...selectedTemplate,
      customInfo: {
        data: formValues
      }
    }

    const updatedTemplate = await axiosClient.patch(
      `/api/library/custom-info/${data._id}`,
      data
    )

    if (updatedTemplate.data) {
      let newTemplates = _.filter(
        templates,
        t => t._id !== updatedTemplate.data._id
      )
      newTemplates.push(updatedTemplate.data)

      this.setState({
        templates: newTemplates
      })

      toast('Custom info template successfully updated.')
    }
  }

  deleteTemplate = async () => {
    const { selectedTemplate, templates } = this.state

    await axiosClient.patch(
      `/api/library/custom-info/${selectedTemplate._id}/delete`
    )

    let newTemplates = _.filter(templates, t => t._id !== selectedTemplate._id)

    this.setState({
      templates: newTemplates,
      selectedTemplate: null
    })

    toast('Custom info template successfully deleted.')
  }

  startEditing = () => {
    this.setState({
      editing: true,
      formValues:
        this.props.data && this.props.data.data
          ? _.cloneDeep(this.props.data.data)
          : []
    })
  }

  addOne = () => {
    let formValues = this.state.formValues
    formValues.push({})
    this.setState({
      formValues
    })
  }

  remove = index => {
    let formValues = this.state.formValues
    formValues.splice(index, 1)
    this.setState({
      formValues
    })
  }

  close = () => {
    this.setState({
      editing: false,
      formValues: null
    })
  }

  save = async () => {
    // Remove all "empty" rows.
    let final = this.state.formValues.filter(
      item => item.name && item.name.length
    )

    // Call the onEdit with the latest form values.

    if (this.props.onEdit) {
      try {
        this.setState({
          isSaving: true
        })
        await this.props.onEdit({
          ...this.props.data,
          data: final
        })
        this.setState({
          isSaving: false
        })
      } catch (ex) {
        return this.close()
      }
    }
  }

  _autoSave = () => {
    if (this.state.isSaving) {
      return
    }

    if (this.saveDelay) {
      clearTimeout(this.saveDelay)
      this.saveDelay = null
    }

    this.saveDelay = setTimeout(this.save, 1000)
  }

  updateForm = (index, field, value) => {
    let formValues = this.state.formValues
    formValues[index][field] = value
    this.setState({
      formValues
    })
    this._autoSave()
  }

  onDragEnd = async result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const formValues = reorder(
      this.state.formValues,
      result.source.index,
      result.destination.index
    )

    this.setState({
      formValues
    })
  }

  handleEnter = async (evt, index) => {
    if (evt.key === 'Enter') {
      if (this.inputRefs[index + 1]) {
        this._autoSave()
        this.inputRefs[index + 1].focus()
      } else {
        await this.addOne()
        this._autoSave()
        this.inputRefs[index + 1].focus()
      }
    }
  }

  render() {
    const { allowEditing, onEdit, style, data } = this.props
    const { editing, formValues, templates, selectedTemplate } = this.state

    console.log('inputRefs: ', this.inputRefs)

    return (
      <Card body style={{ marginBottom: 32, marginTop: 32, ...style }}>
        <div style={{ margin: '8px 16px' }}>
          <div className="float-right">
            {allowEditing && onEdit && (
              <>
                {this.state.editing ? (
                  <Button
                    size="sm"
                    color="primary"
                    onClick={async () => {
                      await this.save()
                      this.close()
                    }}
                  >
                    Done
                  </Button>
                ) : (
                  <Button size="sm" onClick={this.startEditing}>
                    Edit
                  </Button>
                )}
              </>
            )}
          </div>
          <div className="float-left">
            <h5>Custom Info</h5>
          </div>
          <div className="clearfix" />
        </div>

        {(!data || !data.data || !data.data.length) && !editing && (
          <div className="text-muted small text-center" style={{ padding: 16 }}>
            No custom information.
          </div>
        )}
        {!editing && data && data.data && data.data.length > 0 && (
          <table className="table table-striped">
            <tbody>
              {data.data.map((item, index) => (
                <tr>
                  <td style={{ width: 160 }}>
                    <span className="text-muted">{item.name}</span>
                  </td>
                  <td>
                    <span style={{ color: 'black' }}>{item.value}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {editing && (
          <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="customInfoDroppable">
                {(provided, snapshot) => (
                  <table
                    className="table"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <tbody>
                      {formValues.map((item, index) => (
                        <Draggable
                          key={`key${index}`}
                          draggableId={`customInfo${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <tr
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td
                                style={{
                                  width: 36,
                                  textAlign: 'center',
                                  verticalAlign: 'middle',
                                  padding: 0
                                }}
                              >
                                <Icon
                                  size={24}
                                  icon="IoIosReorder"
                                  color="grey"
                                  {...provided.dragHandleProps}
                                />
                              </td>
                              <td style={{ width: 160, paddingLeft: 0 }}>
                                <Input
                                  innerRef={ref =>
                                    (this.inputRefs[index] = ref)
                                  }
                                  type="text"
                                  value={item.name || ''}
                                  onChange={event =>
                                    this.updateForm(
                                      index,
                                      'name',
                                      event.target.value
                                    )
                                  }
                                  onKeyPress={evt =>
                                    this.handleEnter(evt, index)
                                  }
                                  onFocus={() => {
                                    const input = this.inputRefs[index]
                                    let endPosition = (input && input.value && input.value.length) || 0
                                    return setTimeout(() => input.setSelectionRange(0, endPosition), 10)
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={item.value || ''}
                                  onChange={event =>
                                    this.updateForm(
                                      index,
                                      'value',
                                      event.target.value
                                    )
                                  }
                                  onKeyPress={evt =>
                                    this.handleEnter(evt, index)
                                  }
                                />
                              </td>
                              <td style={{ width: 40 }}>
                                <CloseButton
                                  onClick={() => this.remove(index)}
                                />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '8px 16px'
              }}
            >
              <Button size="sm" onClick={this.addOne}>
                + Add row
              </Button>
              <div style={{ display: 'flex' }}>
                <Button
                  size="sm"
                  onClick={this.createTemplate}
                  style={{ marginRight: 4 }}
                >
                  Create template
                </Button>
                <DropdownList
                  style={{ minWidth: 220, marginRight: 4 }}
                  data={templates}
                  placeholder="Select template"
                  textField="name"
                  onChange={value => this.setState({ selectedTemplate: value })}
                />
                {selectedTemplate && (
                  <>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.applyTemplate}
                      style={{ marginRight: 4 }}
                    >
                      Apply
                    </Button>
                    <Button
                      color="warning"
                      size="sm"
                      onClick={this.updateTemplate}
                      style={{ marginRight: 4 }}
                    >
                      Update
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={this.deleteTemplate}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Card>
    )
  }
}

export default CustomInfo
