import React from 'react'
import { connect } from 'react-redux'
import { getProposals, archiveProposal, updateProposal, getServiceTemplates } from 'api'
import { ButtonGroup, Button, Container } from 'reactstrap'
import { toast } from 'react-toastify'
import { connectConfirmation } from 'components/confirm'
import { amplitudeInstance } from '../../../../store/middleware/analytics'
import _ from 'lodash'

import ActionBar from '../../components/ActionBar'
import LabelColumn from '../../components/LabelColumn'
import FilterForm from '../../components/forms/FilterForm'

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap'
import Icon from 'components/icons'
import networkErrorToast from 'utils/networkErrorToast'
import stopEventBubbling from 'utils/stopEventBubbling'
import DateWithTooltip from 'components/DateWithTooltip'
import { ActionButtons } from 'components/lib/Button'
import DataScreen from 'components/DataScreen'

import { fullAddress } from 'utils'
import { trackEvent } from 'api/clientHub'
import { BID_PROPOSAL_MARKED_WON } from 'store/analyticsActions/types/proposalTypes'

class ProposalsList extends React.Component {
  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  state = {
    tabSection: 0
  }

  async componentDidMount() {
    await this.props.getServiceTemplates()
  }

  createProposal = () => this.props.history.push('/dashboard/proposal-setup')

  _editProposal = item => {
    this.props.history.push(`/dashboard/edit-proposal/${item._id}`)
  }


  _markProposalAsWon = async id => {
    let result = await this.props.updateProposal({
      _id: id,
      won: true
    })

    if (result.error) {
      return networkErrorToast(result)
    } else {
      toast('Proposal marked as won.')

      trackEvent({ typeEvent: BID_PROPOSAL_MARKED_WON, params: { ID: id } })

      if (this.dataScreen.current) {
        this.dataScreen.current.forceFetch()
      }
    }
  }

  _archiveProposal = async id => {
    let removed = await this.props.archiveProposal(id)

    if (removed.error) {
      return networkErrorToast(removed)
    } else {
      toast('Proposal was archived.')
      if (this.dataScreen.current) {
        this.dataScreen.current.forceFetch()
      }
    }
  }

  _showArchivedProposals = () => {
    this.props.history.push('/dashboard/proposals/archived/')
  }

  render() {
    const { services } = this.props
    const { tabSection } = this.state

    const sentOrSignedColumn = tabSection === 0
      ? {
        header: 'Sent',
        render: item => {
          return item.sent ? (
            <Icon icon="IoIosCheckmark" color="#09f415" size={40} />
          ) : null
        },
        sortField: 'sent',
        sortable: true
      }
      : {
        header: 'Signed',
        render: item => {

          return item.signatureData?.merchantSignature?.dateSigned ? (
            <Icon icon="IoIosCheckmark" color="#09f415" size={40} />
          ) : null
        },
        sortField: 'signatureData.merchantSignature.dateSigned',
        sortable: true
      }

    const viewedOrSignedDateColumn = tabSection === 0
      ? {
        header: 'Viewed',
        render: item => {
          return item.viewed ? (
            <DateWithTooltip id={item?._id} date={item?.viewed} />
          ) : null
        },
        sortField: 'viewed',
        sortable: true
      }
      : {
        header: 'Date Signed',
        render: item => {
          return item.signatureData?.merchantSignature?.dateSigned ? (
            <DateWithTooltip id={item?._id} date={item?.signatureData?.merchantSignature?.dateSigned} />
          ) : null
        },
        sortField: 'signatureData.merchantSignature.dateSigned',
        sortable: true
      }

    const columns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          //TODO: figure out a more efficient way to do this
          const charges = item && item.data && item.data.elements && _.flatten(item.data.elements.map(el => {
            if (el.type === 'charges') {
              return el.data && el.data.chargeSections
            }
            return false
          })).filter(charge => !!charge)

          const proposalServices = charges && charges.map(charge =>
            _.find(services, {
              name: charge.name
            })
          )

          return (
            <LabelColumn id={item._id} services={proposalServices} labels={item.labels} />
          )
        }
      },
      {
        header: 'Name',
        render: item => `${item.code}${item.name ? ' - ' + item.name : ''}`,
        sortable: true,
        sortField: 'code'
      },
      {
        header: 'Client',
        accessor: 'client.name',
        sortable: true
      },
      {
        header: 'Location',
        render: item => item.location && item.location.address && fullAddress(item.location.address),
        sortField: 'location.address.formattedAddress',
        sortable: true
      },

      sentOrSignedColumn,
      viewedOrSignedDateColumn,
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortField: 'createdAt',
        sortable: true
      },
      {
        header: 'Options',
        render: item => (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              onClick={e => {
                stopEventBubbling(e)
              }}
            >
              <Icon icon="IoIosMore" color="white" />
            </DropdownToggle>
            <DropdownMenu right>
              {!item.won ? (
                <DropdownItem
                  onClick={e => {
                    stopEventBubbling(e)
                    this._markProposalAsWon(item._id)
                  }}
                >
                  Mark as won
                </DropdownItem>
              ) : null}
              <DropdownItem
                onClick={e => {
                  stopEventBubbling(e)
                  this._editProposal(item)
                }}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={e => {
                  stopEventBubbling(e)
                  this.props.confirm(
                    'Confirm Archive',
                    'Are you sure you want to archive this proposal?',
                    () => this._archiveProposal(item._id)
                  )
                }}
              >
                Archive
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    return (
      <Container fluid style={{ marginTop: 20 }}>
        <ActionBar>
          <div>
            <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={() => this.setState({ tabSection: 0 })}
                active={tabSection === 0}
              >
                Open
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => this.setState({ tabSection: 1 })}
                active={tabSection === 1}
              >
                Won
              </Button>
            </ButtonGroup>
          </div>
          <div>
            <FilterForm
              applyFilters={data =>
                this.setState({
                  filterData: data
                })
              }
              serviceFilters
              locationFilters
              proposalFilters={tabSection === 0 ? true : false}
            />
            <ActionButtons
              buttons={[
                {
                  title: 'Create Proposal',
                  onClick: this.createProposal
                }
              ]}
              options={[
                {
                  title: 'See archived proposals',
                  onClick: this._showArchivedProposals
                }
              ]}
            />
          </div>
        </ActionBar>
        <DataScreen
          ref={this.dataScreen}
          entity="Proposal"
          url="/api/proposals/find"
          onRowClick={item => this._editProposal(item)}
          columns={columns}
          filterData={tabSection === 0
            ? { ...this.state.filterData, won: false }
            : { ...this.state.filterData, won: true }
          }
        />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  proposals: state.proposals.proposals,
  loading: state.proposals.loading,
  profile: state.user.profile,
  services: state.library.services
})

export default connect(
  mapStateToProps,
  { getProposals, archiveProposal, updateProposal, getServiceTemplates }
)(connectConfirmation(ProposalsList))
