import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'reactstrap'
import moment from 'moment-timezone'

import { updateCompany, updateProfile } from 'api'
import CompanyForm from './CompanyForm'
import CheckedDone from 'components/CheckedDone'

class CompanyEdit extends Component {
  state = {
    animation: false
  }

  // async componentDidMount() {
  //   await this.props.updateProfile()
  // }

  _onSubmit = async values => {
    if (!values.timezone) {
      values.timezone = moment.tz.guess()
    }

    await this.props.updateCompany(values)
    await this.props.updateProfile()
    this.setState({
      animation: true
    })
  }

  finishAnimation = () => {
    this.setState({
      animation: false
    })
  }

  render() {
    const { currentCompany } = this.props

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'middle',
          flex: 1
        }}
      >
        <Card
          body
          style={{
            marginTop: 20,
            maxWidth: 800,
            paddingLeft: 50,
            paddingTop: 40,
            paddingRight: 50,
            marginRight: -75,
            marginLeft: -75
          }}
        >
          <h3 className="text-dark" style={{ marginBottom: 30 }}>
            Company Profile
          </h3>
          <CompanyForm
            onSubmit={this._onSubmit}
            initialValues={currentCompany}
          />
        </Card>
        {this.state.animation && (
          <CheckedDone complete={this.finishAnimation} />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: state.user.currentCompany,
  user: state.user
})

export default connect(
  mapStateToProps,
  { updateCompany, updateProfile }
)(CompanyEdit)
