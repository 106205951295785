import React from 'react'
import styled from 'styled-components'

import {Alert, Button} from 'reactstrap'
import { toast } from 'react-toastify'
import { encryptJsonWithAES } from 'utils/encrypter'

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DashboardAlertScreen = ({errorType, stripeCustomer, employeeRole}) => {
  let alertMessage

  if (errorType === 'BILLING_ERROR') {
    alertMessage = `There was an issue retrieving your account's billing info, please try refreshing the page. If the issue persists please contact support@getroute.com`
  } else if (errorType === 'SUBSCRIPTION_CANCELLED') {
    alertMessage = `This merchant's subscription was cancelled.`
  } else if (errorType === 'IS_DELINQUENT') {
    alertMessage = 'There was a billing issue with this merchant.'
  }

  const encryptedBillingData = encryptJsonWithAES({
    stripeCustomerId: stripeCustomer?.id
  })

  const billingLink = `${process.env.REACT_APP_LOGIN_DOMAIN}/billing?billingData=${encryptedBillingData}`

  // const _collectPaymentInfo = async () => {
    
  // }

  // const _renewSubscription = async () => {
  //   const paymentMethod = await _collectPaymentInfo()
  //   // with payment method and stripe.subscription.id and stripe customer id, make call to backend
  // }

  // const renewSubscriptionLink = (
  //   <Button color='link' onClick={_renewSubscription}>renew subscription</Button>
  // )

  const selectPlanLink = (
    <Button 
      color='link' 
      onClick={() => {
        if (employeeRole === 'owner') {
          window.location.href = billingLink
          return
        } else {
          return toast('Only company owner can start a subscription.')
        }
      }}
    >
      Start a new subscription
    </Button>
  )
  
  return (
    <div style={{ padding: '100px 300px 0' }}>
      <Alert color="danger">
        <AlertContainer>
          {alertMessage}
          {errorType !== 'BILLING_ERROR' ?
            <LinkRow>
              {selectPlanLink}
              {/* {`or`}
              {renewSubscriptionLink} */}
            </LinkRow>
          : null}
        </AlertContainer>
      </Alert>
    </div>
  )
}

export default DashboardAlertScreen