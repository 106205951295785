import React, { Component } from 'react'
import styled from 'styled-components'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

const StyledDropdown = styled(Dropdown)`
  &&& {
    margin-right: 5px;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
`
const StyledDropdownMenu = styled(DropdownMenu)`
  &&& {
    background-color: white;
    padding: 0;
  }
`

const StyledDivider = styled(DropdownItem)`
  &&& {
    height: 1px;
    padding: 0;
    justify-content: flex- !important;
    hover: disabled;
    div {
      color: #000;
      align-content: center;
      height: 1px;
      margin-left: 6%;
      margin-right: 6%;
      /* background-color: #616161; */
    }
  }
`
const StyledDropdownItem = styled(DropdownItem)``

class SyncLocationDropdown extends Component {
  state = {}

  toggle = () => {
    const { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  render() {
    const { options, color, ...rest } = this.props
    if (!options) {
      return null
    }

    return (
      <StyledDropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        background-color={color || 'light'}
      >
        <DropdownToggle caret color={color || 'primary'}>
          Export Location
        </DropdownToggle>
        <StyledDropdownMenu background-color={color || 'light'}>
          {options.map((option, index) => {
            // Render custom component directly if provided
            if (option.component) {
              return (
                <div key={`option${index}`}>
                  <React.Fragment key={`option${index}`}>
                    {option.divide && (
                      <StyledDivider disabled={true} onClick={option.onClick}>
                        <div></div>
                      </StyledDivider>
                    )}
                    <StyledDivider disabled={true} onClick={option.onClick}>
                      <div></div>
                    </StyledDivider>
                    {option.component}
                  </React.Fragment>
                </div>
              )
            }

            // Existing DropdownItem rendering
            return (
              <DropdownItem
                key={`option${index}`}
                disabled={option.disable}
                onClick={option.onClick}
              >
                <span className={`text-${option.color || 'default'}`}>
                  {option.title || `Option ${index + 1}`}
                </span>
              </DropdownItem>
            )
          })}
        </StyledDropdownMenu>
      </StyledDropdown>
    )
  }
}

export default SyncLocationDropdown
