import React, { Component } from 'react'
import _ from 'lodash'

import BigCheckbox from './BigCheckbox'

export default class ArrayCheckbox extends Component {
  onChange = event => {
    const { input, itemValue } = this.props

    var oldValues = input.value || []

    var newValues = _.without(oldValues, itemValue) // remove value

    if (event.target.checked) {
      // was checked
      newValues = oldValues.concat(itemValue)
    }

    input.onChange(newValues)
  }

  render() {
    const { input, itemValue, abbreviate, size } = this.props
    const abbreviation = itemValue.slice(0, 3)

    return (
      <>
        <label style={{ fontWeight: 'bold', fontSize: '0.8em' }}>
          {abbreviate ? abbreviation : itemValue}
        </label>
        {size === 'big' ?
          <BigCheckbox itemValue={itemValue} onChange={this.onChange} input={input} /> :
          <input
            type="checkbox"
            checked={input.value.indexOf(itemValue) >= 0}
            onChange={this.onChange}
          />
        }

      </>
    )
  }
}
