import React from 'react'
import MarkdownViewer from 'components/MarkdownViewer'

export default ({ element }) => {
  const { data } = element

  return (
    <MarkdownViewer
      id={`proposal-text-element-${element.id}`}
      value={data.markdown}
      padding='0 0 20px 0'
    />
  )
}
