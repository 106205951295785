import React from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import { FiArrowDown, FiArrowUp, FiTrash2 } from 'react-icons/fi'

import Elements from '../Elements'

const ElementFrameBG = styled.div`
  display: none;
  position: absolute;
  border: 1px solid black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const IconButton = styled(Button)`
  display: inline-flex;
  position: relative;
  padding: 0 !important;
  border: none !important;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 0 !important;
`

const ElementFrame = ({
  index,
  elementType,
  elementName,
  count,
  onDelete,
  onMoveUp,
  onMoveDown,
  ...rest
}) => (
  <ElementFrameBG className="proposal-element-frame" {...rest}>
    <div
      style={{
        display: 'flex',
        flex: 1,
        margin: '0 4px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}
    >
      <div
        style={{
          padding: '0 4px',
          fontSize: 10,
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '0 0 4px 4px'
        }}
      >
        {elementName || (Elements.find(e => e.type === elementType) &&
          Elements.find(e => e.type === elementType).label)}
      </div>
      <div
        onClick={e => {
          e.cancelBubble = true
          if (e.stopPropagation) {
            e.stopPropagation()
          }
        }}
        style={{ marginTop: 4 }}
      >
        {index > 0 && (
          <IconButton onClick={onMoveUp} style={{ marginRight: 4 }}>
            <FiArrowUp />
          </IconButton>
        )}
        {index < count - 1 && (
          <IconButton onClick={onMoveDown} style={{ marginRight: 4 }}>
            <FiArrowDown />
          </IconButton>
        )}
        <IconButton color="danger" onClick={onDelete}>
          <FiTrash2 />
        </IconButton>
      </div>
    </div>
  </ElementFrameBG>
)

export default ElementFrame
