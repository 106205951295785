export default [
  {
    brand: 'Gildan',
    category: 'work',
    colors: '41 Colors',
    label: 'Short Sleeves',
    name: 'Gildan 8000 Drybend Tee',
    picture: 'https://cdn.sportswearcollection.com/Images/Color/17927_f_fl.jpg',
    sizes: 'S - 5XL'
  },
  {
    brand: 'Hanes',
    category: 'work',
    colors: '9 Colors',
    label: 'Long Sleeves',
    name: 'Hanes Comfortwash Garment Dyed Long Sleeve',
    picture:
      'https://cdn.shopify.com/s/files/1/1394/2307/products/Hanes_ComfortWash_Garment_Dyed_Long_Sleeve_2296_f_fl_1024x1024.jpg?v=1618519885',
    sizes: 'S - 3XL'
  },
  {
    brand: 'AS Colour',
    category: 'work',
    colors: '14 Colors',
    label: 'Hoodies',
    name: 'ASColour 5101 - Supply Hood',
    picture:
      'https://cdn.shopify.com/s/files/1/1394/2307/products/supplyhood_17921_f_fl_1024x1024.jpg?v=1613147736',
    sizes: 'XS - 3XL'
  },
  {
    brand: 'Nike',
    category: 'business',
    colors: '4 Colors',
    label: 'Polos',
    name: 'Nike Dri-Fit Polo',
    picture:
      'https://cdn.shopify.com/s/files/1/1394/2307/products/Nike_Dri-fit_Polo_30672_f_fl_1024x1024.jpg?v=1528837414',
    sizes: 'XS - XL'
  },
  {
    brand: 'Adidas',
    category: 'business',
    colors: '8 Colors',
    label: 'Sport Polos',
    name: 'Adidas Cotton Blend Sport Shirt - A322',
    picture: 'https://cdn.sportswearcollection.com/Images/Color/71713_f_fm.jpg',
    sizes: 'S - 4XL'
  },
  {
    brand: 'Van Heusen',
    category: 'business',
    colors: '4 Colors',
    label: 'Button Downs',
    name: 'Van Heusen Flex 3 Shirt - 130461',
    picture: 'https://cdn.ssactivewear.com/Images/Color/73661_f_fl.jpg',
    sizes: 'S - 3XL'
  },
  {
    brand: 'YETI',
    category: 'swag',
    colors: '5 Colors',
    label: 'Tumblers',
    name: 'YETI Tumbler',
    picture: 'https://cdn.shopify.com/s/files/1/1394/2307/files/yeti_tumbler.jpg?v=1630511978',
    sizes: '18 Sizes / Styles'
  },
  {
    brand: 'Flexfit',
    category: 'swag',
    colors: '34 Colors',
    label: 'Hats',
    name: 'Flexfit 6277 - Cotton Blend Cap',
    picture:
      'https://cdn.shopify.com/s/files/1/1394/2307/products/Flexfit_Cap_6277_20234_f_fl_1024x1024.jpg?v=1487969527'
  },
  {
    brand: 'Champion',
    category: 'swag',
    colors: '4 Colors',
    label: 'Backpacks',
    name: 'Champion 21L Script Backpack',
    picture: 'https://cdn.sportswearcollection.com/Images/Color/71206_f_fl.jpg'
  }
]
