import React from 'react'
import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { AddButton, CloseButton } from '../lib/Button'

const renderButton = ({
  active,
  onAddTask,
  onDeleteTask,
  fieldsLength,
  fieldIndex,
  value
}) =>
  !!value.length && (active || fieldsLength === fieldIndex + 1) ? (
    <AddButton onClick={onAddTask} style={{ marginRight: 8 }} />
  ) : (
    fieldsLength > 1 && (
      <CloseButton onClick={onDeleteTask} style={{ marginRight: 8 }} />
    )
  )

class ListInputField extends React.Component {
  state = {
    inputActive: false
  }

  componentWillUnmount() {
    if (this.blurDelay) {
      clearTimeout(this.blurDelay)
    }
  }

  _onKeyDown = e => {
    this.props.handleKeyDown(e)
  }

  _onFocus = () => {
    this.setState({ inputActive: true })
  }

  _onBlur = () => {
    this.blurDelay = setTimeout(() => {
      this.setState({
        inputActive: false
      })
    }, 200)
  }

  render() {
    const {
      input: { onChange, value, ...restInput },
      type,
      children,
      placeholder,
      isNewJob,
      fieldsLength,
      fieldIndex,
      isInputAndFrequency
    } = this.props
    //prevent autofocusing the task input when loading a New Job page
    const isEmptyInputAndNotNewJob = !value.length && !isNewJob

    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend" id="list-input-prepend">
          {'\u2022'}
        </InputGroupAddon>
        <Input
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={value}
          {...restInput}
          rows={String(type === 'textarea' && '4')}
          style={{ resize: 'vertical' }}
          onKeyDown={this._onKeyDown}
          onFocus={this._onFocus}
          onBlur={this._onBlur}
          autoFocus={isEmptyInputAndNotNewJob}
          id="list-input-field"
        >
          {children}
        </Input>

        {(!!value.length || isInputAndFrequency) && (
          <InputGroupAddon addonType="append">
            {renderButton({
              onAddTask: this.props.onAddTask,
              onDeleteTask: this.props.onDeleteTask,
              active: this.state.inputActive,
              fieldsLength,
              fieldIndex,
              value
            })}
          </InputGroupAddon>
        )}
      </InputGroup>
    )
  }
}

export default ListInputField
