import React from 'react'
import moment from 'moment-timezone'

export default ({ element, proposal }) => {
  if (!element || !element.data) {
    return null
  }

  const showHeading = element.data.showHeading
  const startDate = element.data.startDate
  const endDate = element.data.endDate
  let headingText = 'Service Dates'

  let duration
  if (startDate && endDate) {
    duration = moment.duration(moment(endDate).diff(moment(startDate))).humanize()
    duration = duration.replace(/a few seconds/gi, '1 day')
    duration = duration.replace(/a /gi, '1 ')
  }

  return (
    <div style={{ paddingBottom: 20, textAlign: 'center' }}>
      {showHeading && (
        <h2 className="section-title">
          {element.data.headingText || headingText}
        </h2>
      )}
      <div style={{
        width: '100%'
      }}>
        <div style={{width: '33%', display: 'inline-block'}}>
          <h6>
            Start Date
          </h6>
          <p>{ startDate ? moment(startDate).format('LL') : 'TBD'}</p>
        </div>
        {endDate ? (
          <div style={{width: '33%', display: 'inline-block'}}>
            <h6>
              End Date
            </h6>
            <p>{moment(endDate).format('LL')}</p>
          </div>
        ) : null}
        {element.data.showDuration && duration ? (
          <div style={{width: '33%', display: 'inline-block'}}>
            <h6>
              Duration
            </h6>
            <p>{duration}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}
