import styled from 'styled-components'

export const Input = styled.input`
  background-color: rgba(243, 245, 247, 1);
  border: none;
  border-bottom: 0.3rem solid transparent;
  border-radius: 0.2rem;
  color: #0a0a0a;
  display: block;
  font-size: 1.25rem;
  margin: 0 auto;
  outline: none;
  padding: 1rem 2rem;
  text-align: center;
  transition: all ease-in-out 0.3s;
  width: 100%;

  &[type='number'] {
    padding: 1rem;

    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      /* Firefox */
      -moz-appearance: textfield;
      margin: 0;
    }
  }

  :focus {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px;
  }
`
