import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
  @media (min-width: 51.75em) {
    max-width: calc(51.75em - 2em);
  }

  @media (min-width: 75em) {
    max-width: calc(75em - 2em);
  }
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem;
  word-wrap: break-word;
  width: 100%;
`

const Link = styled.a`
  color: #ffffff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0.5rem 0 0 0;
  
  :-webkit-any-link,
  :-webkit-any-link:active,
  a:-webkit-any-link,
  a:-webkit-any-link:active,
  span:-webkit-any-link,
  span:-webkit-any-link:active {
    text-decoration: none;
  }
`

export const Shop = props => {
  return (
    <Section>
      <h1 style={{color: '#fff'}}>Shop</h1>
      <div style={{ position: 'relative', margin: '0.5rem 0', padding: '1rem' }}>
        <h3 style={{color: '#fff'}}>Merchandise</h3>
        <p style={{color: '#fff'}}>Purchase custom designed apparel for your team.</p>
        <div>
          <Link color={`action`} href={`/dashboard/shop/culture-studio`} weight={`medium`}>
            Shop Merchandise
          </Link>
        </div>
      </div>
      <div style={{ position: 'relative', margin: '0.5rem 0', padding: '1rem' }}>
        <h3 style={{color: '#fff'}}>Supplies</h3>
        <p style={{color: '#fff'}}>Purchase supplies and equipment for your work.</p>
        <div>
          <Link color={`action`} href={`/dashboard/shop/hdpro`} weight={`medium`}>
            Shop Supplies
          </Link>
        </div>
      </div>
    </Section>
  )
}
