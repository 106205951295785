import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const TopArea = styled.div`
  background-color: #e25152;
  line-height: 1.2em;
`

const BG = styled.div`
  display: inline-block;
  width: ${props => (props.size ? props.size + 'px' : '36px')};
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
`

const MonthText = styled.span`
  color: white;
  font-size: ${props =>
    props.monthTextSize ? props.monthTextSize + 'px' : '10px'};
  font-weight: 300;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const DateText = styled.span`
  color: #222;
  font-size: 20px;
  font-size: ${props =>
    props.dateTextSize ? props.dateTextSize + 'px' : '20px'};
  line-height: 1em;
`

export default ({ date, size, monthTextSize, dateTextSize }) => {
  if (!date) {
    return null
  }

  const m = moment(date)

  return (
    <BG size={size}>
      <TopArea>
        <MonthText monthTextSize={monthTextSize}>{m.format('MMM')}</MonthText>
      </TopArea>
      <DateText dateTextSize={dateTextSize}>{m.format('DD')}</DateText>
    </BG>
  )
}
