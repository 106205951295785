import React from 'react'
import moment from 'moment'
import styled, { withTheme } from 'styled-components'
import { Button, CustomInput, Table } from 'reactstrap'
import { IoIosCheckmark, IoIosClose } from 'react-icons/io'

import { capitalize } from 'utils'
import { axiosClient } from 'store'

const tierData = {
  // starter: {
  //   price: {
  //     month: 49,
  //     year: 490
  //   },

  //   employees: 3,
  //   unlimitedWalkthroughs: true,
  //   unlimitedEstimates: true,
  //   proposals: '5 per month',
  //   jobs: false,
  //   inspections: false,
  //   onboarding: true,
  //   cancelAnytime: true,
  //   freeTrial: true
  // },
  growth: {
    price: {
      month: 129,
      year: 1290
    },

    employees: 'Unlimited',
    unlimitedWalkthroughs: true,
    unlimitedEstimates: true,
    proposals: true,
    jobs: true,
    inspections: true,
    onboarding: true,
    cancelAnytime: true,
    freeTrial: true
  }
}

const CheckMarkRaw = ({ theme }) => (
  <IoIosCheckmark
    size={24}
    style={{
      minWidth: 24
    }}
  />
)
const CheckMark = withTheme(CheckMarkRaw)

const CloseRaw = ({ theme }) => (
  <IoIosClose
    size={24}
    style={{
      minWidth: 24
    }}
  />
)
const Close = withTheme(CloseRaw)

const HoverBaby = styled.div`
  position: absolute;
  top: -32px;
  right: 0px;
  color: white;
  background-color: ${props => props.theme.colors.clarity};
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  transform: rotate(5deg);
`

const ShoutOutLine = styled.div`
  position: absolute;
  width:11px;
  height:11px;
  top: 0px;
  right: 32px;
  margin:0 6px 0 0;
  transform:translate(0, -6px) scaleX(-1);
  background-repeat:none;
  background-image:url("data:image/svg+xml,%3Csvg width='11' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 10C5.4771525 10 1 5.522847 1 0' stroke='${props =>
    props.theme.colors
      .clarity}' fill='none' fill-rule='evenodd' stroke-linecap='round'/%3E%3C/svg%3E");
`

const MobileRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: solid 2px ${({theme}) => theme.colors.light2};
  p {
    max-width: 90vw;
    word-break: break-word;
    white-space: normal;
    margin: 0;
  }
  &:last-child {
    border-bottom: none;
  }
`

const SelectionCell = ({ tier, selectedTier, children }) => (
  <td className={`${tier === selectedTier ? 'normal' : 'inactive'}`}>
    {children}
  </td>
)

const MobileTextSpan = styled.span`
  text-align: right;
  transition: opacity 400ms ease;
  color: ${({theme}) => theme.colors.primary};
  ${({active}) => {
    return active ? '' : `
      opacity: 0.5;
      color: #777777;
    `
  }}
`

const MobileCell = ({ tier, selectedTier, children }) => (
  <MobileTextSpan active={tier === selectedTier}>
    {children}
  </MobileTextSpan>
)

const MobileCellsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const DesktopPlanView = ({selectPlan, selectedTier, selectedInterval}) => {
  const renderRow = topic => {
    const cells = Object.keys(tierData).map((key, index) => {
      const tier = tierData[key]

      return (
        <SelectionCell
          key={`${index}-${topic}`}
          tier={key}
          selectedTier={selectedTier}
        >
          {typeof tier[topic] !== 'boolean' ? tier[topic] : null}
          {typeof tier[topic] === 'boolean' ? (
            tier[topic] ? (
              <CheckMark />
            ) : (
              <Close />
            )
          ) : null}
        </SelectionCell>
      )
    })
    return cells
  }

  return <Table
    className="pricing-table-new"
  >
    <tbody>
      <tr>
        <td style={{ width: 388 }}>
          {selectedInterval === 'year' ? 'Yearly' : 'Monthly'} price
        </td>
        <SelectionCell
          key={`plan-price-growth`}
          tier={'growth'}
          selectedTier={selectedTier}
        >
          ${Intl.NumberFormat("en-US").format(tierData.growth.price[selectedInterval])}
        </SelectionCell>
      </tr>
      <tr>
        <td>Employees</td>
        {renderRow('employees')}
      </tr>
      <tr>
        <td>Walkthroughs</td>
        {renderRow('unlimitedWalkthroughs')}
      </tr>
      <tr>
        <td>Estimates</td>
        {renderRow('unlimitedEstimates')}
      </tr>
      <tr>
        <td>Proposals</td>
        {renderRow('proposals')}
      </tr>

      <tr>
        <td>Inspections</td>
        {renderRow('inspections')}
      </tr>
      <tr>
        <td>Dedicated onboarding</td>
        {renderRow('onboarding')}
      </tr>
      <tr>
        <td>Cancel anytime</td>
        {renderRow('cancelAnytime')}
      </tr>
    </tbody>
  </Table>
}

const MobilePlanView = ({selectPlan, selectedTier, selectedInterval}) => {
  const renderRow = topic => {
    const cells = Object.keys(tierData).map((key, index) => {
      const tier = tierData[key]

      return (
        <MobileCell
          key={`${index}-${topic}`}
          tier={key}
          selectedTier={selectedTier}
        >
          {typeof tier[topic] !== 'boolean' ? tier[topic] : null}
          {typeof tier[topic] === 'boolean' ? (
            tier[topic] ? (
              <CheckMark />
            ) : (
              <Close />
            )
          ) : null}
        </MobileCell>
      )
    })
    return cells
  }

  return <div>
    <div style={{width: '100vw', marginBottom: 16}}>
      <MobileRow>
        <p>
          {selectedInterval === 'year' ? 'Yearly' : 'Monthly'} price
        </p>
        <MobileTextSpan active>
          ${Intl.NumberFormat("en-US").format(tierData.growth.price[selectedInterval])}
        </MobileTextSpan>
      </MobileRow>
      <MobileRow>
        <p>Employees</p>
        {renderRow('employees')}
      </MobileRow>
      <MobileRow>
        <p>Walkthroughs</p>
        {renderRow('unlimitedWalkthroughs')}
      </MobileRow>
      <MobileRow>
        <p>Estimates</p>
        {renderRow('unlimitedEstimates')}
      </MobileRow>
      <MobileRow>
        <p>Proposals</p>
        {renderRow('proposals')}
      </MobileRow>
      <MobileRow>
        <p>Inspections</p>
        {renderRow('inspections')}
      </MobileRow>
      <MobileRow>
        <p>Dedicated onboarding</p>
        {renderRow('onboarding')}
      </MobileRow>
      <MobileRow>
        <p>Cancel anytime</p>
        {renderRow('cancelAnytime')}
      </MobileRow>
    </div>
  </div>
}
class PlanSelector extends React.Component {
  state = {
    selectedInterval: 'month',
    selectedTier: 'growth'
  }

  async UNSAFE_componentWillMount() {
    const { initialValues, amplitudeInstance } = this.props
    const { data } = await axiosClient.get('/api/info/stripePlans')
    if (
      !data.product ||
      !data.plans ||
      !data.plans.data ||
      !data.plans.data.length
    ) {
      // ERROR: Stripe data invalid.
      console.error('Stripe data insufficient.')
      this.setState({
        error: 'There was an issue fetching plans. Please try again.'
      })
      return
    }

    // Only show plans that have a tier specified in their metadata.
    const usablePlans = data.plans.data.filter(
      plan => plan.metadata && plan.metadata.tier
    )

    this.setState({
      product: data.product,
      plans: usablePlans
    })

    if (initialValues) {
      this.setState({
        selectedInterval: initialValues.interval,
        selectedTier: initialValues.name
      })
    }
    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Choose Plan (Enter Page)')
  }

  _selectPlan = plan => {
    const { amplitudeInstance } = this.props
    amplitudeInstance && amplitudeInstance.logEvent(`Sign Up - Change Plan To ${capitalize(plan)}`)
    this.setState({
      selectedTier: plan
    })
  }

  _findStripePlan = (selectedTier, selectedInterval) => {
    const { plans } = this.state
    return plans.find(
      plan =>
        plan.metadata &&
        plan.metadata.tier === selectedTier &&
        plan.interval === selectedInterval
    )
  }

  _submitPlan = () => {
    const { onData, onNext, toggle } = this.props
    const { selectedTier, selectedInterval } = this.state
    const planPrice = tierData[this.state.selectedTier].price
    const stripePlan = this._findStripePlan(selectedTier, selectedInterval)
    if (onData) {
      onData({
        plan: {
          name: selectedTier,
          interval: selectedInterval,
          price: planPrice[this.state.selectedInterval],
          id: stripePlan ? stripePlan.id : undefined
        }
      })
    }
    if (onNext) {
      this.props.onNext()
    }
    if (toggle) {
      toggle()
    }
  }

  render() {
    const { currentStep, numSteps, amplitudeInstance, isMobileOnly } = this.props
    const { selectedInterval, selectedTier, plans, error } = this.state

    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          minHeight: currentStep ? '100vh' : null,
          justifyContent: currentStep ? 'center' : 'flex-start',
          alignItems: 'center',
          textAlign: 'left',
          flexDirection: 'column',
          paddingTop: isMobileOnly ? 32 : 50
        }}
      >
        {error && <p className="text-danger">{error}</p>}
        {plans && (
          <div style={{ marginBottom: 12 }}>
            {currentStep && (
              <div style={{paddingLeft: isMobileOnly ? 12 : 0}}>
                <p style={{ textTransform: 'uppercase', marginBottom: 0 }}>
                  Step <strong>{currentStep}</strong> of{' '}
                  <strong>{numSteps}</strong>
                </p>
                <h1 style={{ fontSize: 24, marginBottom: 36 }}>
                  Choose the plan that's right for you
                </h1>
              </div>
            )}

            <div style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  style={{ width: 96, textAlign: 'right', cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({
                      selectedInterval: 'month'
                    })
                    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Toggle Yearly Off')
                  }}
                >
                  Monthly
                </div>
                <div
                  style={{
                    padding: 12,
                    paddingRight: 8
                  }}
                >
                  <CustomInput
                    checked={this.state.selectedInterval === 'year'}
                    onChange={() => {
                      amplitudeInstance && amplitudeInstance.logEvent(
                        `Sign Up - Toggle Yearly ${
                          this.state.selectedInterval === 'year' ? 'Off' : 'On'
                        }`
                      )
                      this.setState({
                        selectedInterval:
                          this.state.selectedInterval === 'year'
                            ? 'month'
                            : 'year'
                      })
                    }}
                    label=""
                    type="switch"
                    id="yearly-toggle-switch"
                  />
                </div>

                <div
                  style={{
                    width: 96,
                    position: 'relative',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    this.setState({
                      selectedInterval: 'year'
                    })
                    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Toggle Yearly On')
                  }}
                >
                  <span>Yearly</span>
                  <HoverBaby>Save ~17%</HoverBaby>
                  <ShoutOutLine />
                </div>
              </div>
            </div>

            {isMobileOnly ? (
              <MobilePlanView 
                selectPlan={this._selectPlan}
                selectedInterval={selectedInterval}
                selectedTier={selectedTier}
              />
            ) : (
              <DesktopPlanView 
                selectPlan={this._selectPlan}
                selectedInterval={selectedInterval}
                selectedTier={selectedTier}
              />
            )}

            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                style={{
                  textTransform: 'uppercase',
                  borderRadius: 32,
                  width: 300,
                  paddingTop: 16,
                  paddingBottom: 16
                }}
                onClick={this._submitPlan}
                disabled={!selectedTier}
              >
                Continue
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default PlanSelector
