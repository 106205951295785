import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {
  CardBody,
  Badge,
  Button,
  ButtonGroup,
  Container,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import moment from 'moment'
import numeral from 'numeral'
import { connectConfirmation } from 'components/confirm'
import { toast } from 'react-toastify'
import * as acl from 'utils/acl.service'

import * as actions from 'api'
import LevelContainer from 'components/LevelContainer'
import Avatar from 'components/lib/Avatar'
import { ActionButtons } from 'components/lib/Button'
import LabelEditor from 'components/LabelEditor'
import { FiTag } from 'react-icons/fi'
import Info from './Info'
import Schedule from './Schedule'
import Badges from './Badges'
import DarkCard from './DarkCard'

const showAlphaFeatures = process.env.REACT_APP_SHOW_ALPHA_FEATURES === '1'

const ProfileName = styled.h3`
  color: #fff;
  padding-top: 7px;
`

const ActionBar = styled(LevelContainer)`
  margin-left: 20px;
`

const EmploymentHistory = styled.p`
  color: #777;
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const buttonStyle = active => ({
  width: '33.3%',
  backgroundColor: active ? '#0b64eb' : 'transparent',
  borderColor: '#0b64eb',
  color: active ? '#FFF' : '#0b64eb'
})

class EmployeeDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rSelected: 1
    }
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this)
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.employeeId
    ) {
      const res = await this.props.getEmployee(
        this.props.match.params.employeeId
      )

      if (res && res.payload && res.payload.data) {
        this.setState({
          employee: res.payload.data
        })
      }
      const formatter = 'YYYY-MM-DDTHH:mm:ss'
      const startDate = moment()
        .startOf('day')
        .add(0, 'days')
        .format(formatter)

      const endDate = moment()
        .startOf('day')
        .add(7, 'days')
        .format(formatter)
      await this.props.getAllJobs(startDate, endDate)
    }
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected })
  }

  _updateEmployee = async employee => {
    await this.props.updateEmployee(employee)
    const res = await this.props.getEmployee(employee._id)
    if (res.payload && res.payload.data) {
      this.setState({
        employee: res.payload.data
      })
    }
  }

  _editEmployee = () => {
    const { employee } = this.state
    this.props.history.push(`/dashboard/employees/${employee._id}/edit`)
  }

  _transferOwnership = async employee => {
    const employeeName =
      employee && employee.name && employee.name.full
        ? employee.name.full
        : 'this employee'

    this.props.confirm(
      'Confirm Transfer of Ownership',
      `Are you sure you want to transfer ownership of the company to ${employeeName}?`,
      async () => {
        const res = await this.props.transferOwnership(employee._id)
        if (!res.error) {
          await this.props.refreshToken()
          toast(`Ownership succesfully transferred to ${employeeName}.`)
        } else {
          toast('Unable to transfer ownership.')
        }
      }
    )
  }

  render() {
    const { employee } = this.state

    const { numJobs, jobs } = this.props

    if (!employee) {
      return null
    }

    const { fitness } = employee

    const actionBar = (
      <ActionBar>
        <div style={{ width: 140 }} />
        <Title>
          <ProfileName>{employee.name && employee.name.full}</ProfileName>
          <EmploymentHistory>
            {`Started ${moment(employee.createdAt).fromNow()}`}
          </EmploymentHistory>
        </Title>
        <div style={{ alignSelf: 'flex-start', minWidth: 140 }}>
          {acl.isAllowed('employees', 'update') && (
            <ActionButtons
              buttons={[
                {
                  component: (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        block
                        style={{ textAlign: 'left' }}
                        color="secondary"
                      >
                        <FiTag /> Labels
                      </DropdownToggle>
                      <DropdownMenu right={true}>
                        <LabelEditor
                          data={employee.labels}
                          onChange={async data => {
                            this._updateEmployee({
                              _id: employee._id,
                              labels: data
                            })
                          }}
                          refreshEntity={async () => {
                            const res = await this.props.getEmployee(
                              this.props.match.params.employeeId
                            )

                            if (res && res.payload && res.payload.data) {
                              this.setState({
                                employee: res.payload.data
                              })
                            }
                          }}
                        />
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )
                },
                {
                  title: 'Update Employee',
                  onClick: () => this._editEmployee()
                }
              ]}
            />
          )}
        </div>
      </ActionBar>
    )

    const avatarBar = (
      <LevelContainer style={{ marginRight: '5%', marginBottom: 20 }}>
        <Avatar
          size={75}
          url={
            employee && employee.user && employee.user.avatar
              ? employee.user.avatar.url
              : ''
          }
          active={employee.currentShift}
          name={employee.name.full}
        />
        {showAlphaFeatures && (
          <InfoColumn>
            <p style={{ marginBottom: 2 }}>{numJobs}</p>
            <p style={{ marginBottom: 2 }}>Jobs</p>
          </InfoColumn>
        )}
        <InfoColumn>
          <p style={{ marginBottom: 2 }}>
            {fitness && fitness.calories
              ? numeral(fitness.calories).format('0a')
              : 0}
          </p>
          <p style={{ marginBottom: 2 }}>Calories</p>
        </InfoColumn>
        <InfoColumn>
          <p style={{ marginBottom: 2 }}>
            {fitness && fitness.steps ? numeral(fitness.steps).format('0a') : 0}
          </p>
          <p style={{ marginBottom: 2 }}>Steps</p>
        </InfoColumn>
      </LevelContainer>
    )

    const skillsBar = () => (
      <>
        <p style={{ marginBottom: 8 }}>Skills</p>
        {employee.skills.map((skill, index) => {
          return (
            <Badge
              pill
              color="primary"
              style={{
                padding: '3px 20px',
                margin: 4
              }}
              key={index}
            >
              {skill}
            </Badge>
          )
        })}
      </>
    )

    const contentContainer = () => {
      switch (this.state.rSelected) {
        case 1:
          return <Schedule jobs={assignedJobs} />
        case 2:
          return (
            <Info
              employee={employee}
              onSubmit={this._updateEmployee}
              isOwner={this.props.isOwner}
              currentEmployeeId={this.props.employmentId}
              transferOwnership={this._transferOwnership}
            />
          )
        case 3:
          return <Badges employee={employee} />
        default:
          return <Info employee={employee} onSubmit={this._updateEmployee} />
      }
    }

    const assignedJobs = (jobs || []).filter(
      job =>
        job.team.filter(teamMember => teamMember._id === employee._id).length >
        0
    )

    return (
      <div className="full-height">
        <Container fluid style={{ marginTop: 40 }}>
          {actionBar}
          <div className="container" style={{ maxWidth: 600 }}>
            <DarkCard>
              <CardBody
                style={{
                  paddingLeft: 50,
                  paddingRight: 50
                }}
              >
                {avatarBar}
                {!!employee.skills.length && skillsBar()}
              </CardBody>
            </DarkCard>
          </div>
          <div
            className="container"
            style={{ maxWidth: 500, marginBottom: 20 }}
          >
            <ButtonGroup className="full-width" style={{ width: '100%' }}>
              <Button
                onClick={() => this.onRadioBtnClick(1)}
                active={this.state.rSelected === 1}
                style={buttonStyle(1 === this.state.rSelected)}
              >
                Schedule
              </Button>
              <Button
                onClick={() => this.onRadioBtnClick(2)}
                active={this.state.rSelected === 2}
                style={buttonStyle(2 === this.state.rSelected)}
              >
                Info
              </Button>
              {/* {showAlphaFeatures && (
                <Button
                  onClick={() => this.onRadioBtnClick(3)}
                  active={this.state.rSelected === 3}
                  style={buttonStyle(3 === this.state.rSelected)}
                >
                  Badges
                </Button>
              )} */}
            </ButtonGroup>
          </div>
          {contentContainer()}
        </Container>
      </div>
    )
  }
}

EmployeeDetails.defaultProps = {
  numJobs: 100
}

const mapStateToProps = state => ({
  jobs: state.jobs.jobs,
  isOwner:
    state.user &&
    state.user.currentEmployment &&
    state.user.currentEmployment.role &&
    state.user.currentEmployment.role === 'owner',
  employmentId:
    state.user &&
    state.user.currentEmployment &&
    state.user.currentEmployment._id
})

export default connect(
  mapStateToProps,
  actions
)(connectConfirmation(EmployeeDetails))
