import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'reactstrap'
import { getServiceTemplate, updateServiceTemplate } from 'api'
import CustomInfo from 'components/CustomInfo'

const showAlphaFeatures = process.env.REACT_APP_SHOW_ALPHA_FEATURES === '1'

class ServiceTemplateDetails extends Component {
  state = {
    serviceTemplate: null
  }

  async componentDidMount() {
    const serviceTemplateId = this.props.match.params.serviceId
    const res = await this.props.getServiceTemplate(serviceTemplateId)
    if (res.payload && res.payload.data) {
      this.setState({
        serviceTemplate: res.payload.data
      })
    }
  }

  onSubmit = async values => {
    const res = await this.props.updateServiceTemplate(values)
    if (res.payload && res.payload.data) {
      this.setState({
        serviceTemplate: res.payload.data
      })
    }
  }

  render() {
    const { serviceTemplate } = this.state

    if (!serviceTemplate) {
      return null
    }

    return (
      <div
        style={{
          paddingLeft: 80,
          paddingRight: 80,
          marginTop: 40
        }}
      >
        <Card
          style={{
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 50,
            paddingRight: 50
          }}
        >
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <span><h3>{serviceTemplate.name}</h3></span>
            {showAlphaFeatures && 
              <span><h3>Rate: {serviceTemplate.rate}</h3></span>
            }
          </div>
          <CustomInfo
            style={{marginTop: 20}}
            data={serviceTemplate.customInfo}
            onEdit={data => this.onSubmit({ ...serviceTemplate, customInfo: data })}
          />
        </Card>
      </div>
    )
  }
}

export default connect(
  null,
  { getServiceTemplate, updateServiceTemplate }
)(ServiceTemplateDetails)
