import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const getAuthToken = (state) => state.user.token

export const estimatesApi = createApi({
    reducerPath: 'estimatesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {

            const token = getAuthToken(getState())

            if (token) headers.set(`Authorization`, `Bearer ${token}`)

            return headers
        }
    }),
    endpoints: (builder) => ({
        // getProposal: builder.query({
        //     query: (proposalId) => `/api/proposals/${proposalId}/proposalPortal`,
        // }),

        getEstimates: builder.query({

            query: (queryPamramString) => `/api/estimates/find?${queryPamramString}`,
            keepUnusedDataFor: 10

        })

    })
})

export const { useGetEstimatesQuery } = estimatesApi