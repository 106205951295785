import React from 'react'
import { Button } from 'reactstrap'
import { IoIosCheckmarkCircleOutline, IoIosCheckmark } from 'react-icons/io'
import { withTheme } from 'styled-components'

const Page = ({ currentStep, numSteps, theme, onNext, amplitudeInstance }) => {
  amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Choose Plan Intro (Enter Page)')
  return (
  <div
    style={{
      backgroundColor: 'white',
      display: 'flex',
      width: '100%',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column'
    }}
  >
    <div style={{ marginBottom: 32 }}>
      <IoIosCheckmarkCircleOutline size={'48px'} color={theme.colors.clarity} />
    </div>
    <div style={{ marginBottom: 48 }}>
      <p style={{ textTransform: 'uppercase', marginBottom: 0 }}>
        Step <strong>{currentStep}</strong> of <strong>{numSteps}</strong>
      </p>
      <h1 style={{ fontSize: 24 }}>Choose your plan.</h1>
    </div>

    <div
      style={{
        display: 'flex',
        textAlign: 'left',
        width: 256,
        marginBottom: 16,
        flexDirection: 'row'
      }}
    >
      <IoIosCheckmark
        color={theme.colors.clarity}
        size={32}
        style={{
          marginRight: 16,
          minWidth: 32
        }}
      />
      <p>14 days free trial</p>
    </div>
    <div
      style={{
        display: 'flex',
        textAlign: 'left',
        width: 256,
        marginBottom: 16,
        flexDirection: 'row'
      }}
    >
      <IoIosCheckmark
        size={32}
        color={theme.colors.clarity}
        style={{
          marginRight: 16,
          minWidth: 32
        }}
      />
      <p>We’ll remind you three days before your trial ends.</p>
    </div>
    <div
      style={{
        display: 'flex',
        textAlign: 'left',
        width: 256,
        marginBottom: 48,
        flexDirection: 'row'
      }}
    >
      <IoIosCheckmark
        size={32}
        color={theme.colors.clarity}
        style={{
          marginRight: 16,
          minWidth: 32
        }}
      />
      <p>No commitments, cancel at any time.</p>
    </div>

    <Button
      color="primary"
      style={{
        textTransform: 'uppercase',
        borderRadius: 32,
        width: 300,
        paddingTop: 16,
        paddingBottom: 16
      }}
      onClick={onNext}
    >
      See the plans
    </Button>
  </div>
)}

export default withTheme(Page)
