import _ from 'lodash'
import { handlerErrors } from 'utils'

const inititalState = {
  walkthroughs: [],
  pagination: null,
  loading: false,
  error: null
}

const replaceWalkthrough = function(walkthroughs, walkthrough) {
  if (!walkthroughs || !walkthroughs.length) {
    return []
  }

  let newList = walkthroughs.slice(0)
  let index = _.findIndex(newList, {
    _id: walkthrough._id
  })
  if (index > -1) {
    newList[index] = walkthrough
  } else {
    newList.push(walkthrough)
  }
  return newList
}

const walkthroughs = (state = inititalState, action) => {
  switch (action.type) {
    case 'FETCH_WALKTHROUGHS': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_WALKTHROUGHS_SUCCESS': {
      return {
        ...state,
        walkthroughs: action.payload.data.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: null
      }
    }
    case 'FETCH_WALKTHROUGHS_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Getting Walkthroughs failed.'
        )
      }
    case 'FETCH_WALKTHROUGH_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Getting Walkthrough failed.'
        )
      }
    }
    case 'UPDATE_WALKTHROUGH_SUCCESS': {
      return {
        ...state,
        walkthroughs: replaceWalkthrough(
          state.walkthroughs,
          action.payload.data
        )
      }
    }
    case 'ARCHIVE_WALKTHROUGH_SUCCESS':
      return {
        ...state,
        walkthroughs: state.walkthroughs.filter(
          walkthrough => walkthrough._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    case 'UNARCHIVE_WALKTHROUGH_SUCCESS': {
      return {
        ...state,
        walkthroughs: [...state.walkthroughs, action.payload]
      }
    }
    case 'CANCEL_WALKTHROUGH_FAIL':
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Cancelling Walkthrough failed.'
        )
      }
    default:
      return state
  }
}

export default walkthroughs
