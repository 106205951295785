import moment from 'moment-timezone'

import { handlerErrors } from 'utils'

const inititalState = {
  loading: false,
  token: null,
  profile: null,
  employment: null,
  currentEmployment: null,
  currentCompany: null,
  localTimezone: null,
  redirectPath: '/login/',
  timezone: null,
  error: null,
  isFreshLogin: true
}

const updateTimezone = (profile, company) => {
  let timezone
  if (company && company.timezone) {
    timezone = company.timezone
  } else if (profile && profile.timezone) {
    timezone = profile.timezone
  } else {
    timezone = {}
    const zone = moment.tz.guess()
    const now = moment.tz(zone)

    timezone.timeZoneId = zone.name
    const timeZoneName = now.zoneAbbr()
    const parsed = moment.tz.zone(zone).parse(now)
    const utc = moment.tz.zone(zone).utcOffset(now)
    const dstOffset = utc - parsed

    timezone.timeZoneName = timeZoneName
    timezone.dstOffset = dstOffset
    timezone.rawOffset = utc
  }

  return timezone
}

const selectTeam = (teams, reduxExistingTeam) => {
  if (reduxExistingTeam) {
    const existingTeamIsValid = teams.find(
      emp => emp._id === reduxExistingTeam._id
    )
    if (existingTeamIsValid) {
      return existingTeamIsValid
    }
  }

  if (teams && teams.length) {
    return teams[0]
  }

  return null
}

const user = (state = inititalState, action) => {
  switch (action.type) {
    case 'USER_REGISTER': {
      return {
        ...state,
        loading: true
      }
    }
    case 'USER_REGISTER_SUCCESS': {
      const token = action.payload.data.token
      const employment = action.payload.data.employee

      return {
        ...state,
        loading: false,
        currentEmployment: employment || state.currentEmployment,
        currentCompany: employment ? employment.company : state.currentCompany,
        token: token,
        error: null
      }
    }
    case 'USER_REGISTER_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Registration failed.')
      }
    }
    case 'SIGN_UP': {
      return {
        ...state,
        loading: true
      }
    }
    case 'SIGN_UP_SUCCESS': {
      const token = action.payload.data.token
      const employment = action.payload.data.employee
      const merchant = action.payload.data.merchant

      return {
        ...state,
        loading: false,
        currentEmployment: employment,
        currentCompany: merchant,
        token: token,
        error: null
      }
    }
    case 'SIGN_UP_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Registration failed.')
      }
    }
    case 'USER_LOGIN': {
      return {
        ...state,
        loading: true
      }
    }
    case 'USER_LOGIN_SUCCESS': {
      const { user } = action.payload.data
      console.log('USER_LOGIN_SUCCESS user: ', user)
      return {
        ...state,
        profile: user
      }
    }
    case 'CREATE_TOKEN_SUCCESS': {
      const { token, employment } = action.payload.data

      const currentEmployment = selectTeam(employment, state.currentEmployment)

      return {
        ...state,
        loading: false,
        token,
        employment,
        currentEmployment,
        currentCompany: currentEmployment ? currentEmployment.company : null,
        isFreshLogin: true
      }
    }
    case 'REFRESH_TOKEN_SUCCESS': {
      const token = action.payload.data.token
      const employment = action.payload.data.employment
      return {
        ...state,
        token,
        currentEmployment: employment || state.currentEmployment,
        currentCompany: employment ? employment.company : state.currentCompany
      }
    }
    case 'USER_LOGIN_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Login failed.')
      }
    }
    case 'USER_PROFILE_SUCCESS': {
      const { employment } = action.payload.data

      const currentEmployment = selectTeam(
        employment,
        state.currentEmployment
      )

      let company
      if (currentEmployment && currentEmployment.company) {
        company = currentEmployment.company
      }

      const timezone = updateTimezone(company)

      return {
        ...state,
        loading: false,
        employment,
        currentEmployment,
        currentCompany: currentEmployment ? currentEmployment.company : null,
        localTimezone: moment.tz.guess(),
        redirectPath: '/dashboard/',
        timezone,
        error: null
      }
    }
    case 'USER_PROFILE_FAIL': {
      return {
        ...state,
        loading: false,
        token: null,
        employment: null,
        currentEmployment: null,
        currentCompany: null
        // error: handlerErrors(action.error, 'User Profile failed.')
      }
    }
    case 'USE_COMPANY_SUCCESS': {
      const currentEmployment =
        action.payload.data && action.payload.data.company

      let company
      if (currentEmployment && currentEmployment.company) {
        company = currentEmployment.company
      }

      const timezone = updateTimezone(state.profile, company)

      return {
        ...state,
        currentEmployment,
        currentCompany: currentEmployment ? currentEmployment.company : null,
        timezone
      }
    }

    case 'USER_LOGOUT': {
      return {
        ...state,
        loading: false,
        token: null,
        profile: null,
        //        currentEmployment: null,  // MBS: Problem is, if we clear this out, when the user logs back in, it is gone.. However, when token expires or new user logs in, this really should be gone..
        employment: null
      }
    }
    case 'DID_SELECT_COMPANY_AFTER_LOGIN': {
      return {
        ...state,
        isFreshLogin: false
      }
    }
    case 'SELECT_PLAN_SUCCESS': {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          billing: action.payload.data
        },
        currentEmployment: {
          ...state.currentEmployment,
          company: {
            ...state.currentEmployment.company,
            billing: action.payload.data
          }
        }
      }
    }
    case 'UPDATE_COMPANY_SUCCESS': {
      return {
        ...state,
        currentEmployment: {
          ...state.currentEmployment,
          company: action.payload.data
        },
        currentCompany: action.payload.data
      }
    }
    case 'CONNECT_SWEPT_SUCCESS': {
      const newState = {
        ...state,
        currentEmployment: {
          ...action.payload.data,
          company: {
            ...state.currentEmployment.company,
          }
        }
      }
      return {...newState}
    }
    case 'DISCONNECT_SWEPT_SUCCESS': {
      const newState = {
        ...state,
        currentEmployment: {
          ...action.payload.data,
          company: {
            ...state.currentEmployment.company,
          }
        }
      }
      return {...newState}
    }
    default: {
      return state
    }
  }
}

export default user
