import amplitude from 'amplitude-js'
import Amplitude, { logEvent, logout, setUserId } from '@redux-beacon/amplitude'
import { createMiddleware } from 'redux-beacon'
import combineEvents from '@redux-beacon/combine-events'

// initialise amplitude
const amplitudeInstance = amplitude.getInstance()
amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_API_KEY)

const getCompany = team => {
  if (!team) {
    return null
  }

  if (typeof team.company === 'string') {
    return team.company
  }

  return team.company._id
}

const identify = setUserId((action, prevState, nextState) => {
  const team = nextState.user.currentEmployment
  if (!team) {
    return null
  }

  return team._id
})

const setCompany = (action, prevState, nextState) => {
  const team = nextState.user.currentEmployment
  if (!team) {
    return null
  }

  amplitudeInstance.setGroup('Company', [getCompany(team)])

  return null
}

const walkthroughCreated = logEvent(() => {
  return {
    type: 'create walkthrough'
  }
})

const jobCreated = logEvent(action => {
  const job = action.payload.data
  if (!job) {
    return null
  }
  const type = job.type
  return {
    type: `Create ${type}`
  }
})

const shareInspection = logEvent(action => {
  const inspection = action.payload.data
  if (!inspection) {
    return null
  }
  return {
    type: 'Share Inspection'
  }
})

const estimateCreated = logEvent(action => {
  const estimate = action.payload.data
  if (!estimate) {
    return null
  }
  let data = {
    ID: estimate._id,
    Walkthrough: estimate.job
  }

  return {
    type: 'Create Estimate',
    properties: data
  }
})

const proposalCreated = logEvent(action => {
  const proposal = action.payload.data
  if (!proposal) {
    return null
  }
  let data = {
    ID: proposal._id,
    Walkthrough: proposal.job
  }

  return {
    type: 'Create Proposal',
    properties: data
  }
})

const clientCreated = logEvent(action => {
  const client = action.payload.data
  if (!client) {
    return null
  }

  return {
    type: 'Create Client',
    properties: {
      ID: client._id
    }
  }
})

const locationCreated = logEvent(action => {
  const location = action.payload.data
  if (!location) {
    return null
  }

  return {
    type: 'Create Location',
    properties: {
      ID: location._id
    }
  }
})

const serviceCreated = logEvent(action => {
  const service = action.payload.data
  if (!service) {
    return null
  }

  return {
    type: 'Create Service',
    properties: {
      ID: service._id
    }
  }
})

const employeeCreated = logEvent(action => {
  const employee = action.payload.data
  if (!employee) {
    return null
  }

  return {
    type: 'Invite Team Member',
    properties: {
      ID: employee._id
    }
  }
})

const eventsMap = {
  USER_LOGIN_SUCCESS: combineEvents(identify, setCompany),
  USER_PROFILE_SUCCESS: combineEvents(identify, setCompany),
  USER_LOGOUT: logout,

  // CREATE_JOB_SUCCESS: jobCreated,
  CREATE_WALKTHROUGH_SUCCESS: walkthroughCreated,
  EMAIL_INSPECTION_REPORT: shareInspection,

  // CREATE_ESTIMATE_SUCCESS: estimateCreated,
  // CREATE_PROPOSAL_SUCCESS: proposalCreated,

  // CREATE_CLIENT_SUCCESS: clientCreated,
  CREATE_LOCATION_SUCCESS: locationCreated,
  CREATE_SERVICE_TEMPLATE_SUCCESS: serviceCreated,
  // CREATE_EMPLOYEE_SUCCESS: employeeCreated
}

const target = Amplitude({ instance: amplitudeInstance })

const amplitudeMiddleware = createMiddleware(eventsMap, target)
export { amplitudeInstance }

export default amplitudeMiddleware
