import React from 'react'
import Chips from 'react-chips'

const theme = {
  chipsContainer: {
    display: 'flex',
    position: 'relative',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    font: '13.33333px Arial',
    minHeight: 24,
    marginBottom: 16,
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '100%',
    padding: '2.5px',
    borderRadius: 5,
    ':focus': {
      border: '1px solid #aaa'
    }
  },
  container: {
    flex: 1
  },
  containerOpen: {},
  input: {
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%',
    padding: 5,
    margin: 2.5
  },
  suggestionsContainer: {},
  suggestionsList: {
    position: 'absolute',
    border: '1px solid #ccc',
    zIndex: 10,
    left: 0,
    top: '100%',
    width: '100%',
    backgroundColor: '#fff',
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  suggestion: {
    padding: '5px 15px'
  },
  suggestionHighlighted: {
    background: '#ddd'
  },
  sectionContainer: {},
  sectionTitle: {}
}

class ChipsInput extends React.Component {
  onChange = chips => {
    this.props.input.onChange(chips)
  }

  render() {
    const { input, suggestions } = this.props
    return (
      <div>
        <Chips
          value={input.value || []}
          onChange={this.onChange}
          className="form-control form-control-lg"
          theme={theme}
          suggestions={suggestions}
          createChipKeys={[13, 188]}
        />
      </div>
    )
  }
}

export default ChipsInput
