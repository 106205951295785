import React from 'react'

export default ({ element }) => {
  const style = { color: element.data.color || 'black' }
  let heading = <h2 style={style}>{element.data.text}</h2>

  if (element.data.type.size) {
    heading = (
      <element.data.type.size style={style}>
        {element.data.text}
      </element.data.type.size>
    )
  }

  return (
    <div
      style={{
        paddingBottom: 8,
        textAlign: element.data.alignment || 'center'
      }}
    >
      {heading}
    </div>
  )
}
