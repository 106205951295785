import React, { useState } from 'react'
import { Table, Button } from 'reactstrap'
import { TableRow } from './TableRow';


export const CrudTable = ({ data, deleteData, setDataToEdit }) => {

    return (
        <Table size="" striped style={{ textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>Name Area</th>
                    <th>Room Type</th>
                    <th>Actions</th>
                </tr>

            </thead>
            <tbody>
                {data.length === 0

                    ? (
                        <tr>
                            <td colSpan={3}>No Areas</td>
                        </tr>
                    )
                    : (data.map((el, index) => (
                        <TableRow
                            key={index}
                            element={el}
                            elementIndex={index}
                            deleteElement={() => deleteData(index)}
                            setDataToEdit={() => setDataToEdit({ ...el, index })}

                        />
                    )))
                }
            </tbody>
        </Table>
    )
}
