import React from 'react'
import JobCard from './JobCard'

export default ({ jobs, onOpen, onClone, onEdit, onConvert, onRemove }) => {
  if (!jobs || !jobs.length) {
    return null
  }

  const renderedJobs = jobs.map(job => {
    return (
      <JobCard
        data={job}
        key={job._id}
        onOpen={() => onOpen(job)}
        onConvert={() => onConvert(job)}
        onClone={() => onClone(job)}
        onEdit={() => onEdit(job)}
        onRemove={() => onRemove(job)}
      />
    )
  })

  return <>{renderedJobs}</>
}
