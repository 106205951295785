import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100% - 40px);
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-top: 4px;
  font-size: 40px;
`

export const AverageInspectionRating = ({ inspections }) => {
  const [finalAverage, setFinalAverage] = useState(0)
  useEffect(() => {
    if (inspections?.length > 0) {
      let totalScore = 0
      let inspectionsWithScores = 0
      inspections.forEach(inspection => {
        let score = null
        let letsImproveAreas = 0
        let greatAreas = 0
        if (inspection?.areas?.length > 0) {
          inspection?.areas?.forEach(area => {
            if (area.rating === 1) {
              letsImproveAreas++
            } else if (area.rating === 3) {
              greatAreas++
            }
          })
          score = Math.floor(
            (greatAreas / (letsImproveAreas + greatAreas)) * 100
          )
        }
        if (score) {
          totalScore += score
          inspectionsWithScores++
        }
      })
      if (totalScore) {
        setFinalAverage(Math.floor(totalScore / inspectionsWithScores))
      } else {
        setFinalAverage(0)
      }
    } else {
      setFinalAverage(0)
    }
  }, [inspections])


  return (
    <StatsCard title={'Average Inspection Rating'}>
      <StatsContainer>
          <Metric>{finalAverage}%</Metric>
      </StatsContainer>
    </StatsCard>
  )
}
