import React from 'react'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import PropTypes from 'prop-types'

export default class ConfirmationProvider extends React.Component {
  state = {
    showModal: false
  }

  static childContextTypes = {
    confirm: PropTypes.func
  }

  getChildContext() {
    return {
      confirm: (...args) => {
        const [title, message, func, positiveButtonClass, cancelFunc] = args
        this.setState({
          showModal: true,
          title,
          message,
          func,
          positiveButtonClass,
          cancelFunc
        })
      }
    }
  }

  onConfirm = () => {
    this.state.func()
    this.setState({
      showModal: false
    })
  }

  onCancel = () => {
    this.state.cancelFunc && this.state.cancelFunc()
    this.setState({
      showModal: false
    })
  }

  render() {
    const { showModal, title, message, positiveButtonClass } = this.state
    const buttonClass = positiveButtonClass || 'btn-danger'
    return (
      <React.Fragment>
        <Modal
          isOpen={showModal}
          toggle={this.onCancel}
          style={{ paddingTop: 200 }}
        >
          <ModalHeader className="d-flex justify-content-center">
            {title ? title : 'Confirm'}
          </ModalHeader>
          <ModalBody>
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: 30, textAlign: 'center' }}
            >
              {message
                ? message
                : 'Are you sure you want to complete this action?'}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                className="btn"
                style={{ marginRight: 20, width: 150 }}
                onClick={this.onCancel}
              >
                No
              </Button>
              <Button
                className={`btn ${buttonClass}`}
                style={{ width: 150 }}
                onClick={this.onConfirm}
              >
                Yes
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {React.Children.only(this.props.children)}
      </React.Fragment>
    )
  }
}
