import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Alert, Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import _ from 'lodash'

import Switch from 'components/lib/Switch'
import { axiosClient } from 'store'
import PlanCard from './components/PlanCard'
import { updateProfile } from 'api'
import { toast } from 'react-toastify'

const Level = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const IntervalBlock = styled.div`
  width: 160px;
  text-align: center;
  color: ${props => (props.onCard ? 'black' : 'white')};
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
`

const HoverBaby = styled.div`
  position: absolute;
  left: 30px;
  top: -24px;
  color: white;
  background-color: ${props => props.theme.colors.clarity};
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  transform: rotate(5deg);
  ${props =>
    props.onCard ? 'box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25)' : null}
`

const ShoutOutLine = styled.div`
  width:11px;
  height:11px;
  position: absolute;
  top: 8px;
  left: 60px;
  margin:0 6px 0 0;
  transform:translate(0, -6px) scaleX(-1);
  background-repeat:none;
  background-image:url("data:image/svg+xml,%3Csvg width='11' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 10C5.4771525 10 1 5.522847 1 0' stroke='${props =>
    props.onCard
      ? props.theme.colors.clarity
      : 'white'}' fill='none' fill-rule='evenodd' stroke-linecap='round'/%3E%3C/svg%3E");
`

const planFeatures = {
  starter: [
    { label: '3 Users' },
    { label: 'Walkthroughs' },
    { label: 'Estimates' },
    { label: '5 Proposals per month' },
    { label: 'Inspections', isIncluded: false }
  ],
  growth: [
    { label: 'Users' },
    { label: 'Walkthroughs' },
    { label: 'Estimates' },
    { label: 'Proposals' },
    { label: 'Inspections' }
  ]
}

class SelectPlan extends Component {
  state = {
    isYearly: false,
    product: null,
    plans: null
  }

  async UNSAFE_componentWillMount() {
    try {
      const { data } = await axiosClient.get('/api/info/stripePlans')
      this.setState({
        product: data.product,
        plans: data.plans.data
      })
    } catch (ex) {
      console.error('Error fetching plans..', ex)
    }
  }

  changePlan = async (plan, token) => {
    if (this.props.onSelect) {
      return this.props.onSelect(plan)
    }
    const { stripeCustomer, stripeSubscription } = this.props
    
    const session = await axiosClient.post('/api/companies/billing/checkout-session', {
      planId: plan.id,
      customerId: stripeCustomer?.id,
      subscriptionId: stripeSubscription?.subscription
    })
    console.log(session)
    if (session?.data?.url) {
      window.location.replace(session.data.url)
    }
    await this.props.updateProfile()
  }

  toggleSwitch = () => {
    this.setState({
      isYearly: !this.state.isYearly
    })
  }

  render() {
    const { isYearly, plans } = this.state
    const { currentCompany, currentEmployment, profile, onCard, stripeSubscription } = this.props

    if (!plans || !plans.length) {
      return null
    }

    const plansByName = _.keyBy(plans, 'nickname')

    // TODO: This is a static list.
    const displayList = isYearly
      ? [
          // plansByName.free,
          plansByName.growth_yearly,
          plansByName.starter_yearly
        ]
      : [
          // plansByName.free,
          plansByName.growth_monthly,
          plansByName.starter_monthly
        ]

    // displayList[0].code = 'free'
    displayList[0].code = 'growth'
    displayList[1].code = 'starter'

    // TODO: Static position..
    //    displayList[1].isFeatured = true

    // Calculate savings on the fly by using difference between monthly and yearly.
    if (isYearly) {
      displayList[0].savings = Math.round(
        plansByName.growth_monthly.amount * 12 -
          plansByName.growth_yearly.amount
      )
      displayList[1].savings = Math.round(
        plansByName.starter_monthly.amount * 12 -
          plansByName.starter_yearly.amount
      )
    }

    // Mess with it last chance.
    displayList.splice(1)
    displayList.reverse()

    return (
      <Container style={{ paddingTop: 0, minWidth: 400 }}>
        <Level style={{ marginTop: 20, marginBottom: 20 }}>
          <IntervalBlock
            style={{ textAlign: 'right' }}
            onCard={onCard}
            onClick={() => this.setState({ isYearly: false })}
          >
            Monthly
          </IntervalBlock>
          <Switch
            onChange={this.toggleSwitch}
            type="checkbox"
            id="yearly-toggle-switch"
            checked={this.state.isYearly}
          />
          <div style={{ position: 'relative' }}>
            <HoverBaby onCard={onCard}>Two months free!</HoverBaby>
            <ShoutOutLine onCard={onCard} />
            <IntervalBlock
              style={{ position: 'relative', textAlign: 'left' }}
              onCard={onCard}
              onClick={() => this.setState({ isYearly: true })}
            >
              Yearly
            </IntervalBlock>
          </div>
        </Level>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          {displayList.map((plan, index) => {
            return (
              <Col key={index} xs={4}>
                <PlanCard
                  name={plan.nickname}
                  code={plan.code}
                  email={
                    currentEmployment
                      ? currentEmployment.email
                      : profile.email || this.props.email
                  }
                  isFeatured={plan.isFeatured}
                  features={planFeatures[plan.code]}
                  price={plan.amount}
                  frequency={plan.interval}
                  savings={plan.savings}
                  isCurrent={plan.nickname === stripeSubscription?.plan?.nickname}
                  // onSelect={token => this.changePlan(plan, token)}
                  onSelect={() => {
                    if (stripeSubscription?.plan?.nickname?.includes('growth')) {
                      if (plan.nickname?.includes('starter')) {
                        toast('Please contact us if you wish to downgrade to a starter plan!')
                      } else {
                        this.changePlan(plan)
                      }
                    } else {
                      this.changePlan(plan)
                    }
                  }}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: state.user.currentCompany,
  currentEmployment: state.user.currentEmployment,
  profile: state.user.profile
})

export default connect(
  mapStateToProps,
  { updateProfile }
)(SelectPlan)
