import React from 'react'
import { CardBody, ListGroup, ListGroupItem } from 'reactstrap'
import Icon from 'components/icons'
import styled from 'styled-components'

import DarkCard from './DarkCard'

const StyledListGroupItem = styled(ListGroupItem)`
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  padding-left: 5px;
  padding-right: 5px;
  ${props => (!props.active ? `opacity: 0.5` : null)};
`

const BadgeText = styled.span`
  padding-left: 20px;
`

const Badges = ({ badges }) => {
  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <DarkCard>
        <CardBody>
          <ListGroup flush style={{ paddingLeft: 10, paddingRight: 10 }}>
            {badges.map((badge, index) => (
              <StyledListGroupItem active={badge.active} key={index}>
                <Icon icon={badge.icon} size={36} color={badge.iconColor} />
                <BadgeText>{badge.requirements}</BadgeText>
              </StyledListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </DarkCard>
    </div>
  )
}

Badges.defaultProps = {
  badges: [
    {
      label: 'Mr. Clean',
      requirements: 'Completed 100 janitorial cleaning jobs',
      icon: 'FaBroom',
      iconColor: '#0b64eb',
      active: true
    },
    {
      label: '6 Pack Jan',
      requirements: 'Burn 50,000 steps on janitorial cleaning jobs',
      icon: 'FaShoePrints',
      iconColor: '#6622ff',
      active: true
    },
    {
      label: 'Fit as a Fiddle',
      requirements: 'Burn 20,000 Calories',
      icon: 'FaFire',
      iconColor: '#B00020',
      active: true
    }
  ]
}

export default Badges
