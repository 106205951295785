import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, change, getFormValues } from 'redux-form'
import { Button } from 'reactstrap'
import * as actions from 'api'
import styled from 'styled-components'

import { DropdownField } from 'components/fields'
import { required } from 'utils/validators'
import { ClientItem, LocationItem } from 'components/items'
import ClientModal from '../modals/ClientModal'
import LocationModal from '../modals/LocationModal'

const StyledButton = styled(Button)`
  position: absolute;
  top: -7px;
  left: 60px;
`

class SelectClientLocation extends Component {
  state = {
    showClientModal: false,
    showLocationModal: false
  }

  async componentDidMount() {
    await this.props.getClients()
  }

  _locationsOfClient = clientId => {
    const { clients } = this.props
    const theClient = clients.find(client => client._id === clientId)
    if (theClient) {
      return theClient.locations
    } else {
      return []
    }
  }

  _toggleClientModal = () => {
    const { showClientModal } = this.state
    this.setState({
      showClientModal: !showClientModal
    })
  }

  _saveClient = async values => {
    const res = await this.props.submitClient(values)
    if (res && res.payload && res.payload.data && this.props.formName) {
      this.props.change(this.props.formName, 'client', res.payload.data._id)
    }
    const clientsRes = await this.props.getClients()
    const newClient =
      clientsRes &&
      clientsRes.payload &&
      clientsRes.payload.data.find(
        client => client._id === res.payload.data._id
      )
    if (newClient && newClient.locations && this.props.formName) {
      this.props.change(
        this.props.formName,
        'location',
        newClient.locations[0]._id
      )
    }
    this._toggleClientModal()
  }

  _toggleLocationModal = () => {
    this.setState({
      showLocationModal: !this.state.showLocationModal
    })
  }

  _saveLocation = async values => {
    const res = await this.props.submitLocation(values)
    await this.props.getClients()
    if (res && res.payload && res.payload.data && this.props.formName) {
      this.props.change(this.props.formName, 'location', res.payload.data._id)
    }
    this._toggleLocationModal()
  }

  render() {
    const { clients, values, goBack, onSubmit, notRequired, backText, submitText } = this.props
    let clientId = null

    if (values && values.client) {
      if (values.client._id) {
        clientId = values.client._id
      } else {
        clientId = values.client
      }
    }

    const validLocations = this._locationsOfClient(clientId)

    return (
      <>
        <ClientModal
          isOpen={this.state.showClientModal}
          toggle={this._toggleClientModal}
          submitClient={this._saveClient}
        />
        <LocationModal
          submitLocation={this._saveLocation}
          isOpen={this.state.showLocationModal}
          toggle={this._toggleLocationModal}
          clientId={clientId}
        />
        <>
          <div style={{ position: 'relative' }}>
            <Field
              name="client"
              component={DropdownField}
              data={clients}
              itemComponent={ClientItem}
              type="select"
              label="Client"
              textField={item => (item ? item.name : null)}
              valueField="_id"
              normalize={value => (value ? value._id : null)}
              validate={notRequired ? null : [required]}
              warn={notRequired ? null : required}
              filter={'contains'}
              onChange={newValue => {
                if (
                  newValue &&
                  newValue.locations &&
                  newValue.locations.length
                ) {
                  this.props.change(
                    'NewEstimate',
                    'location',
                    newValue.locations[0]._id
                  )
                }
              }}
            />
            <StyledButton color="link" onClick={this._toggleClientModal}>
              {' '}
              create new{' '}
            </StyledButton>
          </div>
          {clientId && (
            <div style={{ position: 'relative' }}>
              <Field
                name="location"
                component={DropdownField}
                itemComponent={LocationItem}
                data={validLocations}
                type="select"
                label="Location"
                textField={item =>
                  item && item.address ? item.address.fullAddress : null
                }
                normalize={value => (value ? value._id : null)}
                warn={notRequired ? null : required}
                validate={notRequired ? null : required}
                valueField="_id"
              />
              <StyledButton color="link" onClick={this._toggleLocationModal}>
                {' '}
                create new{' '}
              </StyledButton>
            </div>
          )}
        </>
        <Button
          block
          type={'button'}
          color="primary"
          style={{ marginTop: 24 }}
          onClick={() => onSubmit(values)}
        >
          {submitText || 'Next'}
        </Button>
        <Button
          block
          type="button"
          color="secondary"
          style={{ marginTop: 4 }}
          onClick={goBack}
        >
          {backText || 'Back'}
        </Button>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  values: getFormValues(ownProps.formName)(state),
  clients: state.clients.clients
})

export default connect(
  mapStateToProps,
  { ...actions, change }
)(SelectClientLocation)
