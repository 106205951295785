import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import Label from './Label'

const CheckRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
`

export default ({ label, data, field, onChange, ...rest }) => (
  <div style={{ marginBottom: 8 }}>
    <CheckRow>
      <Input
        type="checkbox"
        checked={data[field] || ''}
        onChange={event => {
          let newData = {
            ...data
          }
          newData[field] = event.target.checked
          return onChange(newData)
        }}
      />
      <Label text={label} />
    </CheckRow>
  </div>
)
