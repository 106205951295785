import React, { Component, createRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Modal, Button } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import {
  DateField,
  UploadField,
  InputField
} from 'components/fields'
import BigCheckbox from 'components/fields/BigCheckbox'
import { orderMerchandise } from 'api'
import { image, required } from 'utils'

import products from './CultureStudioProducts'

import CultureStudioLogo from 'assets/img/culture-studio-logo.png'

const ShopSection = styled.section`
  @media (min-width: 51.75em) {
    max-width: calc(51.75em - 2em);
  }

  @media (min-width: 75em) {
    max-width: calc(75em - 2em);
  }
  overflow: hidden;
  padding: 1rem;
  word-wrap: break-word;
  width: 100%;
  background-color: rgba(222, 226, 230, 0.05);
  margin: 0;
  max-width: none;
`

const Heading = styled.div`
  position: relative;
  background-color: #000000;
  border-radius: 5px;
  padding: 1rem;
`

const HeadingContent = styled.div`
  position: relative;
  @media (min-width: 37.5em) {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
  }
`

const HeadingCallToAction = styled.div`
  position: relative;
  @media (min-width: 37.5em) {
    margin-left: 1rem;
  }
`

const InverseBlock = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  color: #191919;
  padding: 1rem;
`

const InverseBlockTitle = styled.div`
  position: relative;
  margin: 0;

  @media (min-width: 51.75em) {
    max-width: 10rem;
  }
`

const InverseBlockContent = styled.div`
  position: relative;
  @media (min-width: 51.75em) {
    display: flex;
  }
`

const InverseBlockText = styled.div`
  position: relative;
  margin-top: 1rem;

  @media (min-width: 51.75em) {
    margin-left: 3rem;
    margin-top: 1.5rem;
  }
`

const Picture = styled.img`
  aspect-ratio: ${({ height, width }) => width / height};
  border-radius: 5px;
  opacity: 1;
  position: relative;
`

const Products = styled.div`
  position: relative;
`

const ProductGroup = styled.div`
  position: relative;
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1rem;

  @media (min-width: 67.5em) {
    flex-flow: row nowrap;
    overflow: auto;
    white-space: nowrap;
  }
`

const GroupTitle = styled.div`
  position: relative;
  align-items: center;
  background-color: #000000;
  display: flex;
  height: 6rem;
  justify-content: center;
  width: 100%;

  h3 {
    color: #191919;
    text-align: center;
    white-space: nowrap;
  }

  @media (min-width: 67.5em) {
    height: 20rem;
    width: 6rem;

    h3 {
      transform: rotate(270deg);
    }
  }
`

const GroupContent = styled.div`
  position: relative;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
  position: relative;
  width: 100%;

  @media (min-width: 37.5em) {
    flex-flow: row wrap;
    justify-content: center;
  }

  @media (min-width: 51.75em) {
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  @media (min-width: 67.5em) {
    align-items: center;
    flex-flow: row nowrap;
    height: 20rem;
    justify-content: center;
    min-width: max-content;
    overflow: auto;
    padding: 0 3rem;
    white-space: nowrap;
  }
`

const Product = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 8px, rgb(0 0 0 / 10%) 0px 3px 20px, rgb(0 0 0 / 10%) 0px 0px 2px inset;
  height: 18rem;
  margin: 1rem auto 0 auto;
  padding: 1rem;
  width: 18rem;

  :last-of-type {
    margin: 1rem auto;
  }

  @media (min-width: 37.5em) {
    margin: 1rem 1rem 0 1rem;

    :last-of-type {
      margin: 1rem 1rem 0 1rem;
    }
  }

  @media (min-width: 67.5em) {
    min-height: 18rem;
    margin: 0 1rem;
    min-width: 18rem;

    :last-of-type {
      margin: 0 1rem;
    }
  }
`

const WhiteP = styled.p`
  color: #ffffff
`

const Form = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 1rem;

  @media (min-width: 37.5em) {
    form {
      max-width: 500px;
    }
  }
`

const ThankYouMessage = styled.p`
  margin: 0.25rem 0 2rem 0;
  font-size: 1.25rem;

  @media (min-width: 37.5em) {
    font-size: 1.5rem;
  }
`

const CheckContainer = styled.div`
  display: flex;
`

const CheckLabel = styled.p`
  margin-left: 4px;
`

const BrandTag = styled.div`
  background-color: rgba(222, 226, 230, 0.3);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  color: #000;
  font-size: 0.75rem;
  font-weight: 500;
  left: 0.5rem;
  position: absolute;
  top: 0.5rem;
  width: fit-content;
  z-index: 1
`


const MERCHANDISE_PRODUCT_GROUPS = [
  { name: 'Work Uniforms (t-shirts, hats, sweatshirts, etc.)', value: 'work' },
  { name: 'Business Uniforms (polos, vests, etc.)', value: 'business' },
  { name: 'Swag (water bottles, bags, etc.)', value: 'swag' }
]

const ScrollableGroup = ({ items, title, ...props }) => {
  const ref = createRef()

  const group = items.map(({ brand, colors, label, name, picture, sizes, ...product }, index) => (
    <Product key={index} {...props}>
      <BrandTag>
        {brand}
      </BrandTag>
      <div style={{ textAlign: `center`, margin: `0.5rem 0` }}>
        <Picture alt={`Picture of ${name}`} height={180} src={picture} width={180} />
      </div>
      <h4 style={{ color: '#000', fontWeight: `400` }}>
        {label}
      </h4>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {colors ? (
          <p style={{ color: '#000', margin: `0.25rem 0 0 0`, fontWeight: 500}}>
            {colors}
          </p>
        ) : null}
        {sizes ? (
          <p style={{ color: '#000', margin: `0.25rem 0 0 0`, fontWeight: 500}}>
            {sizes}
          </p>
        ) : null}
      </div>
    </Product>
  ))

  return (
    <ProductGroup>
      <GroupTitle>
        <h3 style={{ color: '#ffffff', fontSize: `1.75rem`}}>
          {title}
        </h3>
      </GroupTitle>
      <GroupContent ref={ref}>{group}</GroupContent>
    </ProductGroup>
  )
}

class CultureStudioForm extends Component {

  state = {
    toggled: false,
    submitted: false,
    selectedProductGroups: []
  }

  onSubmit = async (event) => {
    event.preventDefault()
    const { selectedProductGroups } = this.state
    const { current_company, profile, orderMerchandise, values } = this.props

    const order = {
      artwork: (values.artwork && values.artwork[0].url) || 'Not provided',
      company: current_company._id,
      contact: profile.name.full_name,
      date: new Date(values.date).toLocaleDateString(),
      email: profile.email,
      name: current_company.name,
      phone: profile.phone,
      products: MERCHANDISE_PRODUCT_GROUPS.filter(({ value }) => selectedProductGroups.includes(value))
        .map(({ name }) => name)
        .join(', '),
      quantity: values.quantity.length ? values.quantity : 'Not sure'
    }

    // console.log('order: ', order)
    await orderMerchandise(order)
    this.setState({
      submitted: true
    })
  }

  toggle = value => this.setState({ toggled: value })

  setSelectedProductGroups = value => {
    const { selectedProductGroups } = this.state
    const selectedIndex = selectedProductGroups.indexOf(value)

    if (selectedIndex !== -1) {
      let newGroup = selectedProductGroups.filter(product => product !== value);
      this.setState({
        selectedProductGroups: newGroup
      })
    } else {
      this.setState({
        selectedProductGroups: [
          ...selectedProductGroups,
          value
        ]
      })
    }
  }

  render() {
    const {toggled, submitted, selectedProductGroups } = this.state

    const { business, swag, work } = products.reduce(
      (all, product) => ({
        ...all,
        [product.category]: [...all[product.category], product]
      }),
      {
        work: [],
        business: [],
        swag: []
      }
    )
    
    if (this.props.errors && this.props.errors.length > 0) {
      let serverError
      serverError = this.props.errors[0].message
      toast(serverError)
    }

    return (
      <ShopSection withSidebar={false}>
        {toggled ? (
          <Modal
            size={`md`}
            backdrop
            style={{ bottom: 'auto' }}
            isOpen={toggled}
            toggle={() => this.toggle(!toggled)}
          >
            {submitted ? (
              <div style={{padding: 10}}>
                <Picture
                  alt={`Culture Studio Logo`}
                  height={96}
                  src={CultureStudioLogo}
                  width={96}
                />
                <ThankYouMessage>
                  A Culture Studio account executive will reach out to you via email.
                </ThankYouMessage>
              </div>
            ) : (
              <Form>
                <form
                  onSubmit={this.onSubmit}
                  // encType="multipart/form-data"
                >
                  <div style={{ display: 'flex' }}>
                    <Picture
                      alt={`CS Logo`}
                      height={45}
                      src={CultureStudioLogo}
                      width={45}
                    />
                    <h1 style={{ margin: '0 0 0.25rem 0' }}>
                      Getting Started
                    </h1>
                  </div>
                  <p style={{ margin: '0.25rem 0', fontSize: '1rem' }}>
                    Please answer a few quick questions about your merchandise needs.
                  </p>

                  <p style={{ margin: '0.25rem 0', fontSize: '1rem' }}>
                    What type of merchandise do you need? (check all that apply)
                  </p>
                  {MERCHANDISE_PRODUCT_GROUPS.map((product, index) => (
                    <CheckContainer>
                      <BigCheckbox 
                        itemValue={`${product.value}`}
                        checked={selectedProductGroups.includes(product.value)}
                        onChange={event => this.setSelectedProductGroups(product.value)}
                      />
                      <CheckLabel>
                        {product.name}
                      </CheckLabel>
                    </CheckContainer>
                  ))}

                  {/* tooltip: `Leave this blank to discuss options with a representative` */}
                  <Field
                    component={InputField}
                    label='How many people are you shopping for?'
                    name='quantity'
                    type='text'
                    value=''
                    validate={required}
                  />

                  <Field 
                    component={UploadField}
                    label='Do you have artwork or a company logo?'
                    name='artwork'
                    type='image'
                    value={[]}
                    validate={image}
                  />
                  <Field 
                    label='When do you need your merchandise?'
                    // min={new Date().toISOString().split('T')[0]}
                    component={DateField}
                    name='date'
                    type='date'
                    value={new Date().toISOString().split('T')[0]}
                    validate={required}
                    hideTimezoneWarning
                  />
                  <Button color={'primary'} type={'submit'} variant={'filled'} size={'xs'} shape={'square'}>
                    Submit
                  </Button>
                </form>
              </Form>
            )}

          </Modal>
        ) : null}
        <Heading>
          <h1 style={{ color: '#ffffff', margin: '0 0 0.25rem 0'}}>
            Need Merch?
          </h1>
          <WhiteP style={{ margin: '0.25rem 0', fontSize: '1rem' }}>
            We've got you covered for basic uniforms, highly customized swag, or anything in between.
          </WhiteP>
          <HeadingContent dimensions={{ m: '0.5rem 0 0.25rem 0' }}>
            <Picture
              alt={`Culture Studio Logo`}
              height={96}
              src={CultureStudioLogo}
              width={96}
            />
            <HeadingCallToAction>
              <WhiteP style={{ margin: '0 0 0.5rem 0', fontSize: '1.25rem' }}>
                We trust our partners at Culture Studio to satisfy all your merchandising needs.
              </WhiteP>
              <WhiteP style={{ margin: '0.5rem 0', fontSize: '1.25rem' }}>
                Save 10% with code <strong>ROUTE</strong> when you order with Culture Studio.
              </WhiteP>
                <Button
                  color={`primary`}
                  type={`button`}
                  shape={`square`}
                  size={`xxs`}
                  onClick={() => this.toggle(true)}
                >
                  Get Started
                </Button>
            </HeadingCallToAction>
          </HeadingContent>
        </Heading>
  
        <InverseBlock dimensions={{ m: '1rem 0' }}>
          <InverseBlockContent>
            <InverseBlockTitle>
              <h2 style={{
                color: '#191919',
                fontSize: '2.5rem'
              }}>
                Design Your Way
              </h2>
            </InverseBlockTitle>
            <InverseBlockText>
              <p 
                style={{
                  color: '#191919',
                  margin: '0 0 1rem 0',
                  fontSize: '1.5rem'
                }}
              >
                Collaborate with a Culture Studios account executive to work through the details and
                get personalized assistance to bring your ideas to life.
              </p>
              <p
                style={{
                  color: '#191919',
                  margin: '0 0 1rem 0',
                  fontSize: '1.5rem'
                }}
              >
                <strong>OR</strong>
              </p>
              <p 
                style={{
                  color: '#191919',
                  margin: '0 0 2rem 0',
                  fontSize: '1.5rem'
                }}
              >
                Create your own designs with Culture Studio's design tool and then check in with an
                account executive to make sure you get exactly what you want.
              </p>
            </InverseBlockText>
          </InverseBlockContent>
          <Button color={`primary`} type={`button`} shape={`square`} size={`sm`} onClick={() => this.toggle(true)}>
            Get Started
          </Button>
        </InverseBlock>
  
        <Products>
          <h2 style={{ color: '#ffffff', margin: '0 0 0.25rem 0' }}>
            Some of our favorites
          </h2>
          <ScrollableGroup items={work} title={`WORK UNIFORMS`} />
          <ScrollableGroup items={business} title={`BUSINESS UNIFORMS`} />
          <ScrollableGroup items={swag} title={`SWAG`} />
        </Products>
      </ShopSection>
    )
  }
}

const mapStateToProps = state => ({
  current_company: state.user.currentCompany,
  profile: state.user.profile,
  errors: state.shop.errors,
  values: state.form.CultureStudio && state.form.CultureStudio.values
})

export const CultureStudio = compose(
  reduxForm({form: 'CultureStudio'}),
  connect(
    mapStateToProps,
    {
      orderMerchandise
    }
  )
)(CultureStudioForm)
