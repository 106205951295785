import React from 'react'
import StripeCheckout from 'react-stripe-checkout'
import { Button } from 'reactstrap'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.clarity} !important;
  color: white !important;
  width: 180px;
  height: 44px;
  font-weight: 300 !important;
  letter-spacing: 4px !important;
  margin-bottom: 8px;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: 0 2px 6px
    ${props => transparentize(0.6, props.theme.colors.clarity)} !important;
  transition: all 250ms ease;

  ${props =>
    props.disabled
      ? css`
          pointer-events: none !important;
        `
      : null};

  &:hover {
    box-shadow: 0 12px 24px
      ${props => transparentize(0.5, props.theme.colors.clarity)} !important;
  }
`

export default class StripePayment extends React.Component {
  onToken = token => {
    if (this.props.onSuccess) {
      this.props.onSuccess(token)
    }
  }

  render() {
    const { isFree, label, planName, isCurrent, ...rest } = this.props

    let buttonLabel = isCurrent ? 'CURRENT' : label || 'SELECT'

    return (
      <>
        <StyledButton disabled={isCurrent}>{buttonLabel}</StyledButton>
        {/* {isFree ? (
          <StyledButton disabled={isCurrent} onClick={() => this.onToken(null)}>
            {buttonLabel}
          </StyledButton>
        ) : (
          <StripeCheckout
            name="Route Platform"
            description={`Plan: ${planName || ''}`}
            image={require('../../../../assets/img/icon.png')}
            token={this.onToken}
            stripeKey={process.env.REACT_APP_STRIPE_API_KEY}
            {...rest}
          >
            <StyledButton disabled={isCurrent}>{buttonLabel}</StyledButton>
          </StripeCheckout>
        )} */}
      </>
    )
  }
}
