import React from 'react'
import Autocomplete from 'react-google-autocomplete'
import { GoogleApiWrapper } from 'utils/GoogleApiComponent'
import convertAddress from 'utils/convertAddress'

const AddressInput = ({
  input,
  meta: { valid, touched, invalid, error },
  ...rest
}) => (
  <>
    <Autocomplete
      className={`form-control form-control-lg ${valid ? 'is-valid' : ''} ${
        touched && invalid ? 'is-invalid' : ''
      }`}
      placeholder="Business address"
      onPlaceSelected={place => {
        const location = convertAddress(place)
        if (input) {
          input.onChange(location)
        }
      }}
      types={['address']}
      {...input}
      style={{ marginBottom: 4 }}
    />
    <div
      style={{
        fontSize: 12,
        lineHeight: 1,
        minHeight: 12,
        marginBottom: 8,
        color: 'red'
      }}
    >
      {touched && error ? error : ' '}
    </div>
  </>
)

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places']
})(AddressInput)
