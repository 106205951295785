import React from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'
import numeral from 'numeral'

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-top: 4px;
  font-size: 40px;
`

export const TotalSqFtCard = ({ stat }) => {

  return (
    <StatsCard title={'Total Sq. Ft. Managed'}>
      <StatsContainer>
          <Metric>{stat ? numeral(stat).format('0,0') : 0}</Metric>
      </StatsContainer>
    </StatsCard>
  )
}
