import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap'
import styled from 'styled-components'

import {
  FaArrowLeft,
  FaArrowRight
} from 'react-icons/fa'

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
`

const MonthSwitch = styled.div`
  display: flex;
  justify-content: center;
`

export default class CalendarToolbar extends Component {

  view = view => {
    this.props.onView(view)
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <Button
          key={name}
          size="sm"
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </Button>
      ))
    }
  }

	render() {
    let {
      localizer: { messages },
      label,
    } = this.props

		return (
      <ToolbarWrapper>
        <div style={{ width: 150 }}/>
        <MonthSwitch>
          <Button
            color={'light'}
            className="cta"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 8
            }}
            onClick={() => this.props.onNavigate('PREV')}
          >
            <FaArrowLeft />
          </Button>
          <h4 style={{marginRight: 8, marginLeft: 8}}>{label}</h4>
          <Button
            color={'light'}
            className="cta"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 8
            }}
            onClick={() => this.props.onNavigate('NEXT')}
          >
            <FaArrowRight />
          </Button>
        </MonthSwitch>
        <ButtonGroup style={{ marginBottom: 8 } }>{this.viewNamesGroup(messages)}</ButtonGroup>
      </ToolbarWrapper>
		);
	}
}
