import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Col,
  Row,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardText,
  Alert,
  FormFeedback,
  Input
} from 'reactstrap'
import { TripleStateSwitch } from './TripleStateSwitch'
import Dropzone from 'react-dropzone-uploader'
import styled from 'styled-components'
import 'react-dropzone-uploader/dist/styles.css'

import { isImage } from 'utils/imageHelpers'
import FilePreview from 'components/FilePreview'
import FullScreenGallery from 'components/FullScreenGallery'
import DropdownList from 'react-widgets/lib/DropdownList'

import { MdDeleteForever } from 'react-icons/md'

import RouteRoomTypes from 'data/RoomTypes'

const BodyEditAreaStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`
const InputsContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

const DropZoneStyle = {
  dropzone: { overflowX: 'hidden', overflowY: 'auto', maxHeight: '200px' },
  submitButtonContainer: { display: 'none' }
  // inputLabelWithFiles: { display: 'none' }
}


const initialErrors = {

  name: false,
  roomType: false,
  inputs: false,
  inputsMessage: 'test'

}


function EditAreaModal({ modal, toggle, dataToEdit, submitArea, handleAddArea, indexToEdit }) {

  const [areaTasks, setAreaTasks] = useState([])
  const [inputValue, setInputValue] = useState('')
  const headerToken = useSelector(state => state.user.token)
  const [files, setFiles] = useState([])
  const [showGallery, setShowGallery] = useState(false)
  const [galleryStartIndex, setGalleryStartIndex] = useState(0)
  const [galleryImages, setGalleryImages] = useState([])
  const [newRoomType, setNewRoomType] = useState('')
  const [inputTaskValues, setInputTaskValues] = useState([])
  const [inputCount, setInputCount] = useState(0)

  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState(initialErrors)
  const onDismiss = () => setVisible(false);

  const defaultRoomTypes = useMemo(() => {
    let values = Object.values(RouteRoomTypes)
    return values.map(value => value.label)
  }, [])

  useEffect(() => {
    // Want to use same modal to create new area, so make sure data to edit has data :p
    if (dataToEdit !== null) {
      // dataToEdit needs to be deep copied to avoid changing the original state of dataToEdit
      const areaDeepCopy = JSON.parse(JSON.stringify(dataToEdit))
      setFiles(areaDeepCopy.attachments)
      setAreaTasks(areaDeepCopy.tasks)
      setInputValue(areaDeepCopy.name)
    }
  }, [])

  const handleTaskStatus = (value, index) => {
    const newAreaTasks = [...areaTasks]
    newAreaTasks[index].status = value
    setAreaTasks(newAreaTasks)
  }

  const handleChangeInput = e => {
    setInputValue(e.target.value)
  }

  const handleFileDrop = acceptedFiles => {
    console.log('Archivos soltados:', acceptedFiles)
    // Aquí puedes hacer lo que quieras con los archivos soltados
  }

  const getUploadParams = ({ meta }) => ({
    url: `${process.env.REACT_APP_API_BASE_URL}/api/upload`,
    headers: {
      Authorization: `Bearer ${headerToken}`
    }
  })

  const _removeFile = file => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr, remove }, status) => {
    // called every time a files status changes
    if (status == 'done') {
      var json = JSON.parse(xhr.response)
      if (json.length > 0) {
        setFiles([...files, ...json])
        remove()
      }
    }
  }
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  const _openGallery = index => {
    let newGalleryImages = []
    files?.filter(file => isImage(file.type))
      .forEach((image, index) => {
        newGalleryImages.push({
          original: image.url,
          thumbnail: image.url,
          originalTitle: dataToEdit.name,
          description: dataToEdit.name + ' Image ' + (index + 1),
          note: image.note
        })
      })
    setGalleryImages(newGalleryImages)
    setGalleryStartIndex(index)
    setShowGallery(true)
  }

  const _onSave = () => {
    const deepTasks = JSON.parse(JSON.stringify(areaTasks))
    const deepFiles = JSON.parse(JSON.stringify(files))

    let newArea = { ...dataToEdit, name: inputValue, tasks: deepTasks, attachments: deepFiles }
    submitArea(newArea, indexToEdit)

  }

  const _onAddArea = () => {

    const data = {
      name: inputValue,
      type: newRoomType,
      tasks: inputTaskValues.filter(task => !!task)
    }

    let hasError = determinateInputError(data)
    if (hasError) return


    handleAddArea(data)

  }

  const handleAddInput = () => {
    setInputCount(inputCount + 1)
    setInputTaskValues([...inputTaskValues, ''])
  }

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputTaskValues]
    newInputValues[index] = value
    setInputTaskValues(newInputValues)
  }

  const handleRemoveInput = index => {
    const newInputValues = [...inputTaskValues]
    newInputValues.splice(index, 1)
    setInputCount(inputCount - 1)
    setInputTaskValues(newInputValues)
  }

  const determinateInputError = (data) => {

    setErrors({
      name: data.name == '',
      roomType: data.type == '',
      inputs: data.tasks.length == 0,
      inputsMessage: 'You need to add at least 1 task.'
    })

    if (data.name == '' || data.type == '' || data.tasks.length == 0) return true

    return false
  }

  const renderInputs = () => {
    return inputTaskValues.map((value, index) => {
      return (
        <InputsContainer key={index}>
          <Input
            type="text"
            value={value || ''}
            onChange={e => handleInputChange(index, e.target.value)}
          />
          <Button color="danger" onClick={() => handleRemoveInput(index)}>
            <MdDeleteForever />
          </Button>
        </InputsContainer>
      )
    })
  }

  return (
    <div>
      <FullScreenGallery
        isOpen={showGallery}
        items={galleryImages}
        startIndex={galleryStartIndex}
        onClose={() => setShowGallery(false)}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader tag={'h5'}>{dataToEdit !== null ? dataToEdit.name : 'New Area'}</ModalHeader>
        <ModalBody>
          <BodyEditAreaStyled>
            <div>
              <CardText tag={'h5'}>Name Area</CardText>
              <Input
                id="name"
                name="name"
                placeholder="Area Name"
                type="text"
                value={inputValue}
                onChange={handleChangeInput}
                invalid={errors.name}
              />
              <FormFeedback>
                You need a name
              </FormFeedback>
            </div>


            {dataToEdit === null &&

              <div>
                <DropdownList
                  name="roomType"
                  data={defaultRoomTypes}
                  filter="contains"
                  // value={roomTypeValue}
                  onChange={value => setNewRoomType(value)}
                />
                <span style={{ color: "#dc3545", display: `${errors.roomType ? '' : 'none'}`, fontSize: ' 0.875em' }} >You need to select a room type</span>
              </div>

            }

            <div>
              <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <CardText tag={'h5'}>Tasks</CardText>
                {
                  dataToEdit === null &&
                  <Button color="link" onClick={handleAddInput}>
                    + Add Task
                  </Button>}
              </div>
              {areaTasks.map((task, index) => (
                <>
                  {index > 0 && <DropdownItem divider />}
                  <Row>
                    <Col xs="10">{task.name}</Col>
                    <Col xs="2">
                      {' '}
                      <TripleStateSwitch
                        taskIndex={index}
                        handleTaskStatus={handleTaskStatus}
                        taskStatus={task.status}
                      />
                    </Col>
                  </Row>
                </>
              ))}
              {dataToEdit === null &&
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                  {renderInputs()}
                </div>
              }
              <span style={{ color: "#dc3545", display: `${errors.inputs ? '' : 'none'}`, fontSize: ' 0.875em' }} >{errors.inputsMessage}</span>
            </div>

            <div>
              {
                dataToEdit !== null &&
                <>

                  <CardText tag={'h5'}>Attach Photos</CardText>
                  <Dropzone
                    styles={DropZoneStyle}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    accept="image/*,audio/*,video/*"
                  />
                </>
              }
              {files?.length > 0 ? (
                <Row style={{ marginLeft: 0 }}>
                  {files.map((file, index) => (
                    <FilePreview
                      key={'file-' + index}
                      file={file}
                      onDeleteClicked={() => _removeFile(file)}
                      onClickImage={() => _openGallery(index)}
                    />
                  ))}
                </Row>
              ) : null}
            </div>
            {/* {
              dataToEdit === null &&

              <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                Oops! You need to create at least 1 area to complete this action.
              </Alert>
            } */}
          </BodyEditAreaStyled>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={dataToEdit !== null ? _onSave : _onAddArea}>
            Save Area
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default EditAreaModal
