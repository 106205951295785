import React from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100% - 40px);
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-top: 4px;
  font-size: 40px;
`

const SmallSpan = styled.span`
  font-size: 24px;
  color: ${props => props.theme.dashboard.metric};
`

export const TotalSalesCard = ({ stat }) => {

  return (
    <StatsCard title={'Total Sales'}>
      <StatsContainer>
          <Metric><SmallSpan>$</SmallSpan>{stat || 0}</Metric>
      </StatsContainer>
    </StatsCard>
  )
}
