import React from 'react'

export default props => (
  <svg width={18} height={18} {...props}>
    <title>{'CCD85FE3-C32B-4D4C-8679-FF663B1E8926'}</title>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#D5D5D5"
        fillRule="nonzero"
        d="M0 4l6-4v14l-6 4zM12 4l6-4v14l-6 4z"
      />
      <path fill={props.color} d="M12 4L6 0v14l6 4z" />
      <path d="M0 0h18v18H0z" />
    </g>
  </svg>
)
