import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { axiosClient } from 'store'

const CheckoutSessionHandler = ({}) => {
  const [ handled, setHandled ] = useState(false)

  useEffect(() => {
    const handleCheckout = async (sessionId) => {
      console.log('session id: ', sessionId)
      const handler = await axiosClient.post('/api/companies/billing/checkout-handler', {sessionId})
      setHandled(true)
    }
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const session_id = urlParams.get('session_id')
    handleCheckout(session_id)
  }, []);

  if (handled) {
    return <Redirect to="/dashboard" />
  }

  return null
}

export default CheckoutSessionHandler