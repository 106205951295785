import React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'

const Checkbox = styled.div`
  input {
    display: none;
  }
  label div {
    width: 20px;
    height: 20px;
    border: 2px solid ${props => props.theme.colors.light2};
    cursor: pointer;
    border-radius: 4px;
    background: #fff;
  }
  label svg {
    opacity: 0;
  }
  label:hover div {
    background: #f3f3f3;
  }
  input:checked + label svg {
    opacity: 1;
  }
  input:checked + label div {
    background: #007bff;
  }
`

export default ({ itemValue, onChange, input, checked, labelStyles }) => (
  <div style={{ position: 'relative' }}>
    <Checkbox>
      <input
        type="checkbox"
        checked={(input && input.value.indexOf(itemValue) >= 0) || checked}
        onChange={onChange}
        id={`check-${itemValue}`}
      />
      <label htmlFor={`check-${itemValue}`} style={labelStyles}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <FaCheck size={10} color="white" />
        </div>
      </label>
    </Checkbox>
  </div>
)
