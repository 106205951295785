import { places } from './commercial/places'
import { addons } from './residential/addons'
import { rooms } from './residential/rooms'

export const questionnaire = {
  commercial: {
    expectations: {
      label: `What are your prospect's expectations like for this account?`,
      type: 'range',
      max: 10,
      min: 0,
      step: 1
    },
    floors: {
      label: "What's the floor type breakdown?",
      type: 'range',
      max: 10,
      min: 0,
      step: 1
    },
    recurring: {
      label: 'Is this a repeating job?',
      type: 'radio',
      options: ['yes', 'no']
    },
    frequency: {
      fields: [
        {
          name: 'rate',
          label: 'This job is repeated',
          pattern: '[0-9]',
          required: true,
          type: 'number'
        },
        {
          name: 'unit',
          label: 'times a',
          options: ['week', 'month', 'quarter', 'year'],
          required: true,
          type: 'select'
        }
      ]
    },
    location: {
      fields: [
        {
          name: 'type',
          label: 'Location type',
          type: 'select',
          options: places.map(place => place.name)
        },
        {
          name: 'size',
          label: 'Total square footage',
          pattern: '[0-9]',
          required: true,
          type: 'number'
        }
      ]
    },
    employees: {
      fields: [
        {
          name: 'employer',
          label: 'Do you hire employees?',
          type: 'radio',
          options: ['yes', 'no'],
          required: true
        },
        {
          name: 'rate',
          label: 'How much do you pay them per hour?',
          pattern: '[0-9]',
          type: 'number'
        }
      ]
    },
    contact: {
      label: 'Tell us about your business'
    },
    review: {
      fields: [
        {
          label: 'Desired Margin',
          name: 'margin',
          type: 'range',
          max: 40,
          min: 10,
          step: 5
        },
        {
          label: 'Billing Frequency',
          name: 'billingFrequency',
          type: 'select',
          options: ['weekly', 'biweekly', 'monthly', 'quarterly', 'yearly'],
          required: true
        },
        {
          label: 'Total',
          name: 'total',
          type: 'hidden'
        }
      ]
    }
  },
  residential: {
    location: {
      name: 'location',
      options: ['Apartment', 'Home'],
      type: 'checkbox'
    },
    // recurring: {
    //   label: 'What is the frequency?',
    //   options: [
    //     'One time clean',
    //     'Weekly clean',
    //     'Biweekly clean',
    //     'Monthly clean',
    //     'Deep clean',
    //     'Move in/out clean'
    //   ],
    //   required: true
    // },
    rooms: {
      label: "What's the room breakdown?",
      fields: rooms
    },
    addons: {
      label: 'Any addon services?',
      fields: addons
    },
    contact: {
      label: 'Tell us about your business'
    },
    review: {
      fields: [
        {
          label: 'Desired Margin',
          name: 'margin',
          type: 'range',
          max: 25,
          min: 5,
          step: 5
        },
        {
          label: 'One Time',
          name: 'onetime',
          type: 'hidden'
        },
        {
          label: 'Weekly',
          name: 'weekly',
          type: 'hidden'
        },
        {
          label: 'Bieekly',
          name: 'biweekly',
          type: 'hidden'
        },
        {
          label: 'Monthly',
          name: 'monthly',
          type: 'hidden'
        },
        {
          label: 'Deep Clean',
          name: 'deepclean',
          type: 'hidden'
        },
        {
          label: 'Move In/Out',
          name: 'moveinout',
          type: 'hidden'
        }
      ]
    }
  }
}

export default questionnaire
