import styled from 'styled-components'

export const MaxLabel = styled.span`
  color: ${({ color }) => color || '#ff4141'};
  font-weight: 500;
  margin-right: 0.5rem;
  min-width: 33%;
  text-align: right;
  width: 100%;
`

export const MidLabel = styled.span`
  color: ${({ color }) => color || '#0b64eb'};
  font-weight: 500;
  min-width: 33%;
  text-align: center;
  width: 100%;
`

export const MinLabel = styled.span`
  color: ${({ color }) => color || '#11be60'};
  font-weight: 500;
  margin-left: 0.5rem;
  min-width: 33%;
  text-align: left;
  width: 100%;
`

export const Slider = styled.input`
  appearance: none;
  background: #f0f0f0;
  border-radius: 16px;
  font-size: 1.25rem;
  height: 1.5rem;
  margin-top: 1rem;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  width: 100%;

  :hover {
    opacity: 1;
  }

  ::-webkit-slider-thumb {
    appearance: none;
    background-color: #0b64eb;
    border-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    width: 25px;
  }

  ::-moz-range-thumb {
    background-color: #0b64eb;
    border-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
  }
`

export const SliderLabels = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`
