export default [
  {
    icon: 'FaSignLanguage',
    group: 'Janitorial',
    name: 'Janitorial Cleaning'
  },
  {
    icon: 'FaFutbolO',
    group: 'Floor Services',
    name: 'Astroturf Cleaning'
  },
  {
    icon: 'FaMagic',
    group: 'Floor Services',
    name: 'Carpet Cleaning'
  },
  {
    icon: 'FaSquareO',
    group: 'Floor Services',
    name: 'Concrete Polishing'
  },
  {
    icon: 'FaDelicious',
    group: 'Floor Services',
    name: 'Marble Polishing'
  },
  {
    icon: 'FaThLarge',
    group: 'Floor Services',
    name: 'Tile and Grout'
  },
  {
    icon: 'FaShield',
    group: 'Floor Services',
    name: 'VCT Strip and Wax'
  },
  {
    icon: 'FaCubes',
    group: 'Property Maintenance',
    name: 'Carpentry'
  },
  {
    icon: 'FaLightbulbO',
    group: 'Property Maintenance',
    name: 'Electrician'
  },
  {
    icon: 'FaPencil',
    group: 'Property Maintenance',
    name: 'Handyman'
  },
  {
    icon: 'FaPaintBrush',
    group: 'Property Maintenance',
    name: 'Painter'
  },
  {
    icon: 'FaBug',
    group: 'Special Services',
    name: 'Pest Control'
  },
  {
    icon: 'FaWrench',
    group: 'Property Maintenance',
    name: 'Plumber'
  },
  {
    icon: 'FaBuilding',
    group: 'Special Services',
    name: 'Post Construction'
  },
  {
    icon: 'FaTint',
    group: 'Floor Services',
    name: 'Upholstery Cleaning'
  },
  {
    icon: 'FaIndustry',
    name: 'Window Washing'
  }
]
