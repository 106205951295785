import React, { Component } from 'react'
import BusyButton from './BusyButton'
import styled from 'styled-components'

const StyledButton = styled(BusyButton)`
  display: inline-flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  svg,
  img {
    margin-right: 6% !important;
  }
  padding-left: 10% !important;
`

class IconButton extends Component {
  render() {
    const { children, ...rest } = this.props

    let content = children

    return <StyledButton {...rest}>{content}</StyledButton>
  }
}

export default IconButton
