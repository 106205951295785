import placeSchema from './placeSchema'

const formatLocation = location =>
  Object.keys(location).reduce(
    (formatted, key) => ({
      ...formatted,
      ...{ [placeSchema[key] || key]: location[key] }
    }),
    {}
  )

export default googlePlace => {
  let latitude = googlePlace.geometry.location.lat()
  let longitude = googlePlace.geometry.location.lng()

  let location = formatLocation(
    googlePlace.address_components.reduce(
      (loc, component) => ({
        ...loc,
        id: googlePlace.id,
        formattedAddress: googlePlace.formatted_address,
        latitude,
        longitude,
        googleMapLink: googlePlace.url,
        [component.types[0]]: component.short_name
      }),
      {}
    )
  )
  return location
}
