import React from 'react'
import { ProgressBar, ProgressItem } from './ui'

export const Progress = ({
  completed,
  currentIndex,
  onStageChange,
  questions
}) => (
  <ProgressBar>
    {questions.map((question, index) => (
      <ProgressItem
        key={question}
        complete={index <= currentIndex}
        onClick={() =>
          (index <= currentIndex || completed) && onStageChange(question)
        }
      />
    ))}
  </ProgressBar>
)

export default Progress
