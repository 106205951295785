import React from 'react'
import styled from 'styled-components'

import { Button, Question, Title } from '../ui'

const FrequencyButton = styled(Button)`
  background-color: transparent;
  color: #0b64eb;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1.5rem;
  padding: 0;
`

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`

export const ResidentialRecurringStage = ({ label, onClick, options }) => (
  <Question>
    <Title>{label}</Title>

    <Options>
      {options.map(option => (
        <FrequencyButton
          key={option}
          name={`frequency-${option
            .replace(/\s+/g, '-')
            .replace('/', '-')
            .toLowerCase()}`}
          onClick={event => onClick(event, option)}
          type={'button'}
        >
          {option}
        </FrequencyButton>
      ))}
    </Options>
  </Question>
)

export default ResidentialRecurringStage
