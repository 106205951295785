import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import numeral from 'numeral';
import { pieChartColors, removeSmallData, renderCustomPieChartLabel, renderCustomPieChartLabelLine } from '../util'
import { PieLabelArray } from './PieLabelArray'

import StatsCard from './StatsCard'
import MotivationalMessage from './MotivationalMessage'

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: calc(100% - 40px);
  padding: 0 0 10px 0;
`


const containerStyles = `
  grid-row-start: 3;
  grid-row-end: span 2;

  @media (max-width: 700px) {
    grid-row-start: 5;
    grid-row-end: span 2;
  }
`

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00'), name]

export const DistributionOfRevenue = memo(({ stats }) => {

  let data = []
  if (stats) {
    data = Object.keys(stats).map(key => {
      const value = stats[key] || 0
      return {name: key, value}
    })
    data = removeSmallData(data)
  }

  const shortLabelsLength = 3

  return (
    <StatsCard
      title={'Distribution of Revenue'}
      containerStyles={containerStyles}
    >
      <StatsContainer>
      {data.length ? (
        <>
          <div style={{height: '20px'}} />
          <ResponsiveContainer height="80%">
            <PieChart
              key={Math.random()}
            >
              <Pie
                dataKey="value"
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={5}
                outerRadius={80}
                // fill="#8884d8"
                label={renderCustomPieChartLabel}
                labelLine={renderCustomPieChartLabelLine}
                paddingAngle={0}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} stroke={pieChartColors[index % pieChartColors.length]} strokeWidth={1}/>
                ))}
              </Pie>
              <Tooltip formatter={formatTooltipLabel} />
            </PieChart>
          </ResponsiveContainer>
          <PieLabelArray elementId="dist-of-rev-labels" data={data} shortenTo={shortLabelsLength} />
        </>
        ) : (
          <MotivationalMessage message={'Nothing yet, go get those sales!'} />
        )}
      </StatsContainer>
    </StatsCard>
  )
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps.stats, nextProps.stats)
})
