import { useState, useEffect } from 'react'
import { usePapaParse } from 'react-papaparse';
import { LocationTypes } from '../../../../../data'
import { googlePlaceToAddress } from 'utils';
const clientTemplate = [
  'clientName',
  'address',
  'locationType',
  'locationName',
  'contactName',
  'contactPhone',
  'contactEmail',
]

const locationTemplate = [
  'locationName',
  'address',
  'locationType',
  'contactPhone',
]

const parseClient = (row, index) => {
  let parsedObject = {
    contact: {}
  }
  clientTemplate.forEach((key, i) => {
    if (key === 'contactName' || key === 'contactPhone' || key === 'contactEmail') {
      parsedObject.contact[key.replace('contact', '').toLowerCase()] = row[i]
    } else {
      parsedObject[key] = row[i]
    }
  })

  return parsedObject
}

const parseLocation = (row, index) => {
  let parsedObject = {
    contact: {}
  }
  locationTemplate.forEach((key, i) => {
    if (key === 'contactPhone') {
      parsedObject.contact['phone'] = row[i]
    } else {
      parsedObject[key] = row[i]
    }
  })

  return parsedObject
}

const validateAddress = (address, google) => {
  return new Promise(async (resolve, reject) => {
    const serviceQuery = {
      input: address,
      fields: ['place_id']
    }
    const placesService = new google.maps.places.PlacesService(document.createElement('div'))
    const autocompleteService = new google.maps.places.AutocompleteService()
    let placeId
    autocompleteService.getQueryPredictions(serviceQuery, (results, status) => {
      if (results) {
        placeId = results[0].place_id

        if (placeId) {
          placesService.getDetails({placeId, fields: ['ALL']}, (place, status) => {
            const finalPlace = googlePlaceToAddress(place)
            resolve(finalPlace)
          })
        } else {
          resolve(null)
        }
      } else {
        resolve(null)
      }
    })
  });
}

const parseAndValidateData = async ({data, importType, google}) => {
  const locationTypeArray = LocationTypes.map(type => type.name)
  const newData = await Promise.all(data.map(async (row, index) => {
    let parsedObject = {}

    if (importType === 'client') {
      parsedObject = parseClient(row, index)
    } else if (importType === 'location') {
      parsedObject = parseLocation(row, index)
    }

    if (parsedObject.locationType && !locationTypeArray.includes(parsedObject.locationType)) {
      parsedObject.locationType = null
    }

    const validatedAddress = await validateAddress(parsedObject.address, google)
    parsedObject.address = validatedAddress
    return parsedObject
  }))

  return newData
}

export const useParseAndValidateImport = ({ importType, setBusy, google }) => {
  const { readString } = usePapaParse()
  const [importData, setImportData] = useState([])
  const [fileString, setFileString] = useState('')
  const [parsedData, setParsedData] = useState([])
  const reader = new FileReader()

  useEffect(() => {
    reader.addEventListener("load", () => {
      setFileString(reader.result)
    })
    return () => {
      reader.removeEventListener("load")
    }
  }, [])
  
  useEffect(() => {
    if (fileString) {
      const parsedString = readString(fileString)
      setImportData(parsedString.data)
    }
  }, [fileString])

  useEffect(() => {
    const _parseData = async (data) => {
      const parsedDataRes = await parseAndValidateData({data, importType, google})
      setParsedData(parsedDataRes)
    }
    if (importData.length > 0 && google) {
      _parseData(importData)
    }
  }, [importData, google])

  const onUploadCSV = (file) => {
    const reader = new FileReader()
    setBusy()
    reader.addEventListener("load", () => {
      // this will then display a text file
      setFileString(reader.result)
    }, false);

    if (file) {
      reader.readAsText(file);
    }
  }

  return {
    parsedData,
    onUploadCSV,
  }
}
