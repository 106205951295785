import { handlerErrors } from 'utils'

const initialState = {
  templates: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TEMPLATES': {
      return { ...state, loading: true }
    }
    case 'GET_TEMPLATES_SUCCESS': {
      return {
        ...state,
        loading: false,
        error: null,
        templates: action.payload.data
      }
    }
    case 'GET_TEMPLATES_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting templates failed.')
      }
    }
    case 'USER_LOGOUT': {
      return { ...initialState }
    }
    default:
      return state
  }
}

export default reducer
