import React from 'react'
import styled from 'styled-components'

const SwitchStyled = styled.div`
  position: relative;
  height: 32px;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    width: 52px;
    height: 26px;
    background: ${props => props.theme.colors.grey};
    display: block;
    border-radius: 13px;
    border: 2px solid white;
    position: relative;
    bottom: 22px;
  }

  label:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 26px;
    height: 26px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 13px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${props => props.theme.colors.clarity};
  }

  input:checked + label:after {
    left: calc(100% + 2px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 30px;
  }
`

class Switch extends React.Component {
  render() {
    return (
      <SwitchStyled>
        <input
          id={this.props.key || this.props.id || 'switch'}
          type="checkbox"
          {...this.props}
        />
        <label htmlFor={this.props.key || this.props.id || 'switch'} />
      </SwitchStyled>
    )
  }
}

export default Switch
