import React from 'react'
import { Card } from 'reactstrap'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Icon from 'components/icons'
import Elements from '../Elements'
import CloseButton from 'components/lib/Button/CloseButton'

const ContentList = styled.ul`
  list-style: none;
  padding-left: 0;
`

const ContentItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: ${props => (props.active ? 1.0 : props.hover ? 0.9 : 0.6)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  ${props =>
    props.index === 0 ? 'border-top: 1px solid rgba(0, 0, 0, 0.2);' : null}
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  cursor: pointer;
  ${props => (props.active ? `font-weight: bold` : null)};
`

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer

  //display: isDragging ? 'table' : null,

  // styles we need to apply on draggables
  ...draggableStyle,
  // position: isDragging ? 'absolute' : null,
  backgroundColor: isDragging ? 'white' : null,
  boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : null
})

export default ({
  elements,
  hoverIndex,
  activeIndex,
  editElement,
  onDragEnd,
  removeElement
}) => (
  <Card>
    <div style={{ padding: '8px 16px' }}>
      <h5>Contents</h5>
    </div>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="areas-inspector-droppable">
        {(provided, snapshot) => (
          <ContentList {...provided.droppableProps} ref={provided.innerRef}>
            {elements.map((e, i) => (
              <Draggable
                key={`draggable-content-${i}`}
                draggableId={`draggable-element-content-${i}`}
                index={i}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <ContentItem
                      hover={hoverIndex === i}
                      active={activeIndex === i}
                      index={i}
                      onClick={() => editElement(e, i, true)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          size={24}
                          icon="IoIosReorder"
                          color="grey"
                          {...provided.dragHandleProps}
                          style={{ marginRight: 6 }}
                        />
                        {e.data && e.data.name ? e.data.name : (Elements.find(o => o.type === e.type) && Elements.find(o => o.type === e.type).label)}
                      </div>
                      <CloseButton
                        onClick={evt => {
                          evt.cancelBubble = true
                          if (evt.stopPropagation) {
                            evt.stopPropagation()
                          }
                          removeElement(e, i)
                        }}
                      />
                    </ContentItem>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ContentList>
        )}
      </Droppable>
    </DragDropContext>
  </Card>
)
