import React from 'react'
import Zapier from './Zapier'
import styled from 'styled-components'

const Title = styled.h2`
  margin: 0 30px;
  color: #fff;

`

const Integrations = (props) => {

  return (
    <div>
      <Title>Zapier Integrations</Title>
      <Zapier style={{margin: '0 30px 30px'}} />
    </div>
  )
}

export default Integrations