import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const EditLink = styled(Link)`
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }

  &.active {
    color: #ffffff;
  }
`

const AnchorButton = ({ color, size, style, to, children }) => (
  <EditLink to={to}>
    <Button color={color} size={size} style={style}>
      {children}
    </Button>
  </EditLink>
)

export default AnchorButton
