import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { PrivateRoute } from 'hoc'
import Account from './Account'
import AcceptInvite from './AcceptInvite'
// import { Companies } from './Companies'
import Profile from './Profile'
import Header from 'components/layout/Header'
import LeftNav from 'components/layout/LeftNav'

const ContentArea = styled.div`
  position: fixed;
  left: 200px;
  right: 0;
  top: 66px;
  bottom: 0;
  overflow: auto;
`

class Manage extends Component {
  render() {
    return (
      <>
        <Header />

        <LeftNav />
        <ContentArea className="with-left-nav">
          <Switch>

            <PrivateRoute exact path="/profile/edit/" component={Profile} />
            <PrivateRoute exact path="/profile/" component={Account} />
            <Redirect from="/profile/*" to="/profile/" />
          </Switch>
        </ContentArea>
      </>
    )
  }
}

export default Manage
