export const getServiceTemplates = () => dispatch =>
  dispatch({
    type: 'GET_SERVICE_TEMPLATES',
    payload: {
      request: {
        url: `/api/library/services`
      }
    }
  })

export const submitServiceTemplate = template => {
  if (template._id) {
    return updateServiceTemplate(template)
  } else {
    return createServiceTemplate(template)
  }
}

export const createServiceTemplate = template => dispatch =>
  dispatch({
    type: 'CREATE_SERVICE_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/services`,
        method: 'post',
        data: template
      }
    }
  })

export const getServiceTemplate = templateId => dispatch =>
  dispatch({
    type: 'GET_SERVICE_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/services/${templateId}`
      }
    }
  })

export const updateServiceTemplate = template => dispatch =>
  dispatch({
    type: 'UPDATE_SERVICE_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/services/${template._id}`,
        method: 'patch',
        data: template
      }
    }
  })

export const removeServiceTemplate = templateId => dispatch =>
  dispatch({
    type: 'REMOVE_SERVICE_TEMPLATE',
    payload: {
      request: {
        url: `/api/library/services/${templateId}/delete`,
        method: 'patch'
      }
    }
  })
