import React from 'react'
import { UploadTitle, UploadText, DropZoneStyle, ImportSubmitButton, SampleDownloadLink } from './ImportContentStyles'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import ClientBulkSample from 'assets/files/ClientBulkSample.xlsx'
import LocationBulkSample from 'assets/files/LocationBulkSample.xlsx'

export const ImportForm = ({ importType, onUpload }) => {
  const [file, setFile] = React.useState([])
  const _onUpload = () => {
    onUpload(file)
  }

  const handleChangeStatus = ({ meta, file, xhr, remove }, status) => {
    // called every time a files status changes
    if (status == 'done') {
      if (file) {
        setFile(file)
      }
    }
  }

  const linkText = importType === 'client' ? 'Download Client Template' : 'Download Location Template'
  const downloadLink = importType === 'client' ? ClientBulkSample : LocationBulkSample
  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <UploadTitle>Upload {importType}</UploadTitle>
      <UploadText>Please upload your list of Locations as a CSV File. Use the Template below as a reference.</UploadText>
      <UploadText><strong>Note: </strong>Make sure to fill the requires fields for your locations. (placeholder)</UploadText>
      <SampleDownloadLink href={downloadLink}>{linkText}</SampleDownloadLink>
      <UploadText>Max 200 Locations at a Time</UploadText>
      <Dropzone
        styles={DropZoneStyle}
        // getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        // onSubmit={handleSubmit}
        accept=".csv"
        inputContent="Drop files here"
        maxFiles={1}
      />
      <ImportSubmitButton color="primary" onClick={_onUpload}>Upload</ImportSubmitButton>
    </div>
  )
}