import React from 'react'
import styled from 'styled-components'

import MaterialSpinner from 'components/MaterialSpinner'
import { BusyButton } from 'components/lib/Button'

const Header = styled.h3`
  margin-bottom: 1rem;
  text-align: center;
`

const Label = styled.p`
  font-weight: bold;
  margin-bottom: .3rem;
`

const ValueSpan = styled.span`
  font-weight: 300;
  font-style: italic;
  color: hsla(0, 0%, 0%, .6);
  margin-left: 8px;
`

const CenterContainer = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SweptInfo = ({sweptData, isLoading, signout, showLoginForm, showModal, buttonBusy, ...props}) => {
  console.log('swept data: ', sweptData)
  let renderedElement = null
  if (isLoading){
    renderedElement = <CenterContainer>
        <MaterialSpinner />
      </CenterContainer>
  }
  if (sweptData) {
    const { company, email } = sweptData
    renderedElement = (
      <>
        <Label>
          User Email:
          <ValueSpan>{email}</ValueSpan>
        </Label>
        <Label> 
          Company Name:
          <ValueSpan>{company.name}</ValueSpan>
        </Label>
        <Label>
          You have {company.location_count}/{company.maximum_active_locations} Locations
        </Label>
        <CenterContainer>
            <BusyButton busy={buttonBusy} onClick={signout}>
              Disconnect from Swept
            </BusyButton>
        </CenterContainer>
      </>
    )
  }
  if (!isLoading && !sweptData) {
    renderedElement = (
      <>
        <CenterContainer>
          <h5>Looks like you haven't connected to a Swept account, please sign in.</h5>
          <div style={{width: '30%', marginTop: '20px'}}>
            <BusyButton className="w-100" color='primary' busy={buttonBusy} onClick={showModal}>
              Connect to Swept
            </BusyButton>
          </div>
        </CenterContainer>
      </>
    )
  }

  return (
    <>
      <Header>Swept Account Info</Header>
      {renderedElement}
    </>
  )
}
