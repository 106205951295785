import React from 'react'

export default ({ element }) => (
  <div style={{ paddingBottom: 8 }}>
    <hr
      style={{
        margin: 0,
        padding: 0,
        border: 0,
        height: element.data.height || 1,
        backgroundColor: element.data.color || 'black'
      }}
    />
  </div>
)
