import React from 'react'
import MaterialSpinner from './MaterialSpinner'
import styled from 'styled-components'

const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`
const LazyImageWrapper = styled.div`
  display: inline-block;
  position: ${props => (!props.position ? `relative` : props.position)};
  min-width: 32px;
  min-height: 32px;
`

class LazyImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      opacity: 0
    }
  }
  handleImageLoaded() {
    this.setState({ loading: false })
    setTimeout(() => {
      this.setState({ opacity: 1 })
    }, 200)
  }
  handleImageErrored() {
    this.setState({ loading: false })
  }

  render() {
    const { loading, opacity } = this.state
    const { src, alt, spinner, spinnerSize, file, position } = this.props
    let imageProps = { ...this.props }
    const style = {
      ...imageProps.style,
      opacity: opacity,
      transition: 'opacity 250ms'
    }
    if (file) {
      imageProps.src = imageProps.file.url || imageProps.file.preview
      imageProps.alt = alt || 'Image ' + imageProps.file.name
    }

    delete imageProps.spinnerSize
    delete imageProps.style
    delete imageProps.spinner

    return (
      <LazyImageWrapper position={position}>
        <img
          alt={imageProps.alt}
          src={file ? file.url : src}
          onLoad={this.handleImageLoaded.bind(this)}
          onError={this.handleImageErrored.bind(this)}
          style={style}
          {...imageProps}
        />

        {spinner && loading && (
          <SpinnerWrapper>
            <MaterialSpinner size={spinnerSize || undefined} />
          </SpinnerWrapper>
        )}
      </LazyImageWrapper>
    )
  }
}

LazyImage.defaultProps = {
  spinner: true
}

export default LazyImage
