import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import networkErrorToast from 'utils/networkErrorToast'

import { getEmployees, getLocations, submitJob, getJob } from 'api'

import JobForm from './JobForm'

import { trackEvent } from 'api/clientHub'
import { USER_CREATE_INSPECTION_SUCCESS } from 'store/analyticsActions/types/inspectionsTypes'
import { USER_CREATE_WALKTHROUGH_SUCCESS } from 'store/analyticsActions/types/walkthroughsTypes'
import InspectionList from '../Inspections/components/InspectionList'

class Job extends Component {
  state = {
    loading: true,
    job: null,
    jobType: null,
    initialInspectionValues: null,
  }

  async componentDidMount() {
    await this.loadChoices()

    let jobId = this.props.match.params.jobId
    let clientId
    let locationId
    let hls =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state

    if (hls) {
      clientId = hls.clientId
      locationId = hls.locationId
    }

    if (jobId) {
      await this.load(jobId)
    } else {
      this.setState({
        loading: false,
        job: this.createNewJob(clientId, locationId)
      })
    }

    let type = this.props.match.params.jobType

    if (type) {
      this.setState({
        jobType: type
      })
    }
  }

  createNewJob = (clientId, locationId) => {
    return {
      scheduledAt: moment()
        .add(1, 'day')
        .startOf('day')
        .hour(10)
        .toDate(),
      client: clientId,
      location: locationId
    }
  }

  loadChoices = async () => {
    await this.props.getLocations()
    await this.props.getEmployees()
  }

  load = async jobId => {
    let result = await this.props.getJob(jobId)

    if (!result.error) {
      this.setState({
        loading: false,
        job: result.payload.data
      })
    } else {
      this.setState({
        loading: false,
        job: this.createNewJob()
      })
    }
  }

  save = async values => {

    console.log("Inspection")
    if (values.timezone) {
      values.scheduledAt = moment(values.scheduledAt)
        .clone()
        .tz(values.timezone)
        .format()
    } else {
      values.scheduledAt = moment(values.scheduledAt).toDate()
    }

    const { jobType } = this.state

    const tasks = this.props.tasks?.filter(task => task && task.name)
    let job = {
      ...values
    }
    if (tasks) {
      job.tasks = tasks
    }

    if (!job.type) {
      job.type = jobType
    }

    console.log({ jobType, values })

    if (jobType === 'Inspection') {

      this.setState({
        initialInspectionValues: job
      })

    } else {
      const res = await this.props.submitJob(job)
      console.log('res.payload.data: ', res?.payload?.data)
      this.props.history.goBack()
      if (res.error) {
        return networkErrorToast(res, `Unable to Create ${jobType}`)
      }

      if (jobType === 'Walkthrough') trackEvent({ typeEvent: USER_CREATE_WALKTHROUGH_SUCCESS })

    }


    // if (jobType === 'Inspection') trackEvent({ typeEvent: USER_CREATE_INSPECTION_SUCCESS })


  }

  render() {
    if (this.state.loading) {
      return null
    }

    const jobId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.jobId

    let { job } = this.state

    let jobType
    if (job && job.type) {
      jobType = job.type
    } else {
      jobType = this.state.jobType
      job.type = jobType
    }

    const title = job.name
      ? `Update ${job.name}`
      : jobId
        ? `Update ${jobType || 'Job'}`
        : `New ${jobType || 'Job'}`



    if (this.state.initialInspectionValues) {
      return <InspectionList initialInspectionValues={this.state.initialInspectionValues} history={this.props.history} />
    } else {
      return (
        <div className="container" style={{ rowGap: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 className="text-light" style={{ alignSelf: 'start' }}>{title}</h1>
          <JobForm
            onSubmit={this.save}
            initialValues={job}
            job={job}
            {...this.props}
          />
        </div>
      )
    }

  }
}

const mapStateToProps = (state, props) => ({
  localTimezone: state.user.localTimezone.timeZoneId,
  company: state.user.currentEmployment.company,
  tasks:
    state.form.Tasks && state.form.Tasks.values
      ? state.form.Tasks.values.tasks
      : null
})

export default connect(
  mapStateToProps,
  {
    submitJob,
    getJob,
    getLocations,
    getEmployees
  }
)(Job)
