const initialState = {
  errors: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_MERCHANDISE_ORDER': {
      return { ...state, errors: action.payload.error }
    }
    case 'SEND_SUPPLIES_ORDER': {
      return {
        ...state,
        errors: action.payload.error,
      }
    }
    default:
      return state
  }
}

export default reducer
