import objectToQueryString from 'utils/objectToQueryString'

export const getWalkthroughs = options => dispatch => {
  let finalOptions = { ...options }

  finalOptions.type = 'Walkthrough'
  if (!finalOptions.limit) {
    finalOptions.limit = 10000
  }

  var queryString = objectToQueryString(finalOptions)

  return dispatch({
    type: 'FETCH_WALKTHROUGHS',
    payload: {
      request: {
        url: `/api/jobs/find?${queryString}`
      }
    }
  })
}

export const submitWalkthrough = walkthrough => {
  if (walkthrough._id) {
    return updateWalkthrough(walkthrough)
  } else {
    return createWalkthrough(walkthrough)
  }
}

export const createWalkthrough = walkthrough => dispatch =>
  dispatch({
    type: 'CREATE_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs`,
        method: 'post',
        data: walkthrough
      }
    }
  })

export const cloneWalkthrough = (walkthroughId, includePictures) => dispatch =>
  dispatch({
    type: 'CLONE_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthroughId}/clone`,
        method: 'post',
        data: { includePictures } 
      }
    }
  })

export const getWalkthrough = walkthroughId => dispatch =>
  dispatch({
    type: 'FETCH_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthroughId}`
      }
    }
  })

export const updateWalkthrough = walkthrough => dispatch =>
  dispatch({
    type: 'UPDATE_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthrough._id}`,
        method: 'patch',
        data: walkthrough
      }
    }
  })

export const cancelWalkthrough = walkthroughId => dispatch =>
  dispatch({
    type: 'CANCEL_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthroughId}/cancel`,
        method: 'patch'
      }
    }
  })

export const startWalkthrough = walkthroughId => dispatch =>
  dispatch({
    type: 'START_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthroughId}/start`,
        method: 'patch'
      }
    }
  })

export const completeWalkthrough = walkthroughId => dispatch =>
  dispatch({
    type: 'COMPLETE_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthroughId}/finish`,
        method: 'patch'
      }
    }
  })

export const archiveWalkthrough = walkthroughId => dispatch =>
  dispatch({
    type: 'ARCHIVE_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthroughId}/delete`,
        method: 'patch'
      }
    }
  })

export const unarchiveWalkthrough = walkthrough => dispatch =>
  dispatch({
    type: 'UNARCHIVE_WALKTHROUGH',
    payload: {
      request: {
        url: `/api/jobs/${walkthrough._id}/unarchive`,
        method: 'patch'
      }
    }
  })
