import createStore from './config'
export { amplitudeInstance } from './middleware/analytics'

const { store, persistor, axiosClient } = createStore()

axiosClient.interceptors.request.use(axios => {
  const state = store.getState()
  const token = state.user.token

  // Only add the bearer token if there is one or if not doing auth.
  if (
    !token ||
    (axios.url &&
      axios.url.length &&
      axios.url.includes('auth') &&
      !axios.url.includes('refreshToken'))
  ) {
    return axios
  }

  if (!axios.headers) {
    axios.headers = {}
  }

  axios.headers['Authorization'] = `Bearer ${token}`

  return axios
})

axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (
      error.resonse &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.code !== 'credentials_required'
    ) {
      persistor.flush()
      window.location.replace('/login')
    }
    return Promise.reject(error)
  }
)

export { store, persistor, axiosClient }
