import React, { PureComponent } from 'react'
import { GoogleApiWrapper } from '../../utils/GoogleApiComponent'
import { getTimezone } from 'utils'

import { placeSchema } from 'utils'
import FieldSet from './FieldSet'
import { formatText } from 'utils/formatters'

class GooglePlacesField extends PureComponent {
  constructor(props) {
    super(props)
    this.autocomplete = null
    this.state = { error: '' }
    this.renderAutocomplete = this.renderAutocomplete.bind(this)
    this.formatLocation = this.formatLocation.bind(this)
    //    this.geolocate = this.geolocate.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  // const valor = 10;
  componentDidMount() {
    const { google } = this.props
    if (!google) {
      // console.log('WAITING FOR AUTOCOMPLETE PROPS...')
      return
    }

    // console.log('AUTOCOMPLETE NODE FOUND', google, this.autocompleteRef)
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteRef,
      { types: ['geocode'] }
    )
    this.autocomplete.addListener('place_changed', this.renderAutocomplete)
    //  this.geolocate()
  }

  async renderAutocomplete() {
    const place = await this.autocomplete.getPlace()
    let countryCode
    let number

    if (!place.geometry) {
      return
    }

    if (place.hasOwnProperty('formatted_phone_number')) {
      countryCode = '+1'
      number = place.formatted_phone_number.replace('+1', '')
    } else {
      countryCode = ''
      number = ''
    }

    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()
    const timezone = await getTimezone(latitude, longitude)

    let location = this.formatLocation(
      place.address_components.reduce(
        (loc, component) => ({
          ...loc,
          id: place.id,
          formattedAddress: place.formatted_address,
          latitude,
          longitude,
          countryCode,
          number,
          googleMapLink: place.url,
          [component.types[0]]: component.short_name
        }),
        {}
      )
    )

    if (timezone) {
      location.timezone = timezone
    }

    await this.props.input.onChange(location)
  }

  formatLocation = location =>
    Object.keys(location).reduce(
      (formatted, key) => ({
        ...formatted,
        ...{ [placeSchema[key] || key]: location[key] }
      }),
      {}
    )

  /*
  geolocate() {
    const { google } = this.props

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        })

        this.autocomplete.setBounds(circle.getBounds())
      })
    }
  }
  */

  onChange() {
    if (this.props.meta.error) {
      this.setState({ error: `${this.props.meta.error}` })
    } else {
      this.setState({ error: '' })
    }
  }

  toggleAutocomplete = event => (event.target.autocomplete = 'hidden')

  toggleField = event => {
    event.preventDefault()
    let formattedAddress = document.getElementById('formattedAddress')
    let autocomplete = document.getElementById('autocomplete')
    event.target.hidden = true
    event.target.style.visibility = 'hidden'

    if (event.target === formattedAddress) {
      // console.log(event.target === formattedAddress, 'autocomplete showing')
      autocomplete.hidden = false
      autocomplete.style.visibility = 'visible'
      return autocomplete.focus()
    }

    if (event.target === autocomplete) {
      // console.log(event.target === autocomplete, 'formattedAddress showing')
      formattedAddress.hidden = false
      formattedAddress.style.visibility = 'visible'
      return formattedAddress
    }
  }

  aptChange = event => {
    let location = this.props.input.value
    if (!location) {
      // TODO: WTF
      return
    }
    location.aptSuite = event.target.value
    this.props.input.onChange(location)
    this.forceUpdate()
  }



  render() {
    const { label, meta, name, input } = this.props

    console.log(this.props);
    const placeholder = this.props.placeholder || 'Enter address'
    const hasLocation = input && input.value
    return (
      <>
        <FieldSet
          label={label}
          meta={meta}
          checkValidation={meta.submitFailed}
          htmlFor={name}
        >
          <input
            id={'formattedAddress'}
            autoComplete={'hidden'}
            className="form-control"
            onFocus={this.toggleField}
            placeholder={placeholder}
            readOnly
            style={{ background: 'white' }}
            type={this.props.type}
            value={this.props.input.value.formattedAddress || ''}
            hidden={false}
            onKeyPress={e => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
          <input
            {...this.props}
            id={'autocomplete'}
            autoComplete={'hidden'}
            className="form-control"
            name={name}
            loaded={this.props.loaded.toString()}
            onChange={this.onChange}
            placeholder={placeholder}
            ref={ref => (this.autocompleteRef = ref)}
            type={this.props.type}
            error={this.state.error}
            onFocus={this.toggleAutocomplete}
            onBlur={this.toggleField}
            style={{ visibility: 'hidden' }}
            hidden={true}
            onKeyPress={e => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
          {hasLocation && (
            <input
              id={'aptSuite'}
              className="form-control"
              autoComplete={'hidden'}
              placeholder={'Apt/Suite'}
              onChange={this.aptChange}
              value={hasLocation ? this.props.input.value.aptSuite || '' : ''}
              style={{ background: 'white', marginTop: 4 }}
              type={this.props.type}
              onBlur={() => {
                let text = this.props.input.value.aptSuite 
                this.props.input.value.aptSuite = formatText(text)
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') e.preventDefault()
              }}
            />
          )}
        </FieldSet>
      </>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places']
})(GooglePlacesField)
