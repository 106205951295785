export const fullName = name => {
  if (!name || !name.first) {
    return
  }

  let fullName = ''
  fullName += name.first
  if (name.last) {
    fullName += ' ' + name.last
  }
  return fullName
}