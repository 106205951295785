import React from 'react'
import styled from 'styled-components'
import { IoMdAddCircle } from 'react-icons/io'
import { Button } from 'reactstrap'

const StyledButton = styled(Button)`
  &:hover svg {
    color: ${props => props.theme.colors.clarity} !important;
  }
`

export default props => (
  <StyledButton close aria-label="Add" {...props}>
    <span aria-hidden>
      <IoMdAddCircle id="add-circle" size={22} style={{ color: '#999' }} />
    </span>
  </StyledButton>
)
