import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ProposalReview from './ProposalReview'

class ClientScreens extends React.Component {
  render() {
    return (
      <>
        <Route
          path="/:proposalId/proposalReview/:signatureToken"
          component={ProposalReview}
        />
      </>
    )
  }
}
export default ClientScreens
