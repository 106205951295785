import React, { Component } from 'react'
import DropdownList from 'react-widgets/lib/DropdownList'
import styled from 'styled-components'
import FieldSet from './FieldSet'

const StyledDropdownList = styled(DropdownList)`
  font-size: ${props =>
    props.isInsideTaskInput ? '0.9em !important' : 'initial'};
  margin: ${props => (props.isInsideTaskInput ? '2px !important' : 'initial')};
  input-height: margin: ${props =>
    props.isInsideTaskInput ? '1.2em !important' : 'initial'};
`

class DropdownField extends Component {
  state = {
    isBlur: false
  }

  _onBlur() {
    this.setState({
      isBlur: true
    })
  }

  render() {
    let {
      name,
      input,
      isInsideTaskInput,
      type,
      label,
      filter,
      data,
      groupBy,
      groupComponent,
      itemComponent,
      textField,
      valueField,
      forceValidate,
      fieldStyles,
      meta,
      ...rest
    } = this.props

    let value = ''
    if (input.value) {
      value = input.value
    }

    return (
      <FieldSet
        label={label}
        meta={meta}
        checkValidation={forceValidate || this.state.isBlur}
        htmlFor={name}
        fieldSetStyles={{ marginBottom: isInsideTaskInput ? 0 : 16, ...fieldStyles }}
      >
        <StyledDropdownList
          name={name}
          type={type}
          inputProps={input}
          filter={filter}
          data={data}
          groupBy={groupBy}
          groupComponent={groupComponent}
          itemComponent={itemComponent}
          valueField={valueField}
          textField={textField}
          value={value}
          onChange={input.onChange}
          onBlur={() => this._onBlur()}
          isInsideTaskInput={isInsideTaskInput}
          {...rest}
        />
      </FieldSet>
    )
  }
}

export default DropdownField
