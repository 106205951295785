import React, { Component } from 'react'
import { connect } from 'react-redux'

import ClientForm from './ClientForm'
import { submitClient, getClient } from 'api'

class Client extends Component {
  state = {
    loading: true,
    client: null
  }

  async componentDidMount() {
    let clientId = this.props.match.params.clientId

    if (clientId) {
      await this.load(clientId)
    } else {
      this.setState({
        loading: false,
        client: null
      })
    }
  }

  save = async values => {
    await this.props.submitClient(values)
    this.props.history.push('/dashboard/clients/')
  }

  load = async clientId => {
    let result = await this.props.getClient(clientId)

    if (!result.error) {
      this.setState({
        loading: false,
        client: result.payload.data
      })
    } else {
      this.setState({
        loading: false,
        client: null
      })
    }
  }

  render() {
    let { loading, client } = this.state

    if (loading) {
      return null
    }

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <ClientForm
          onSubmit={this.save}
          initialValues={client}
          _id={client ? client._id : null}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: state.user.currentCompany
})

export default connect(
  mapStateToProps,
  { submitClient, getClient }
)(Client)
