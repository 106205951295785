import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import RouteLogoFull from 'assets/img/route-logo-full.png'
import { AppleDownloadLink, GoogleDownloadLink } from 'components/lib/Button'
import Header from 'components/layout/Header'

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 15vh;
`

const Logo = styled.img`
  width: 300px;
  height: auto;
`

const Text = styled.p`
  padding: 4rem 1rem;
  color: ${({theme}) => theme.colors.light1};
  text-align: center;
`

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`

const imgStyles = {
  width: '200px',
  height: 'auto'
}

const MembersRedirect = () => {
  const { currentEmployment } = useSelector(state => state.user)
  if (currentEmployment?.role !== 'member') {
    return <Redirect to="/dashboard" />
  }
  
  return (
    <Screen>
      <Header hideSearch />
      <Logo src={RouteLogoFull} />
      <Text>
        It looks like this account has limited access.
        <br/>
        If you're looking for the walkthrough builder, download the mobile app.
      </Text>
      <LinkRow>
        <GoogleDownloadLink style={imgStyles} />
        <AppleDownloadLink style={{padding: 13, ...imgStyles}} />
      </LinkRow>
    </Screen>
  )
}

export default MembersRedirect