import React, { Component } from 'react'
import styled from 'styled-components'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { Button, Card } from 'reactstrap'

import {
  DropdownField,
  InputField,
  SmallInputField,
  UploadField
} from 'components/fields'

import ErrorMessage from 'components/ErrorMessage'
import { image, required } from 'utils'

import { FloorTypes, RoomTypes } from 'data'
import LazyImage from 'components/LazyImage'

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const min3 = value => (value && value.length >= 3 ? undefined : 'Too short')
const Dimensions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: solid #ede7f6;
  padding: 10px 10px 0;
  margin-bottom: 10px;
`

const ratings = [
  { id: 3, label: '👍  Great Job' },
  { id: 1, label: "💡  Let's Improve" }
  // { id: 2,
  //   label: 'Unreviewed?'
  // },
]

const renderDimensionSet = ({ fields }) => {
  return (
    <div style={{ marginBottom: 15 }}>
      {fields.map((dim, index) => (
        <Dimensions key={index} className="bg-light1">
          <Field
            name={`dimensions.set.${index}.width`}
            type="number"
            component={SmallInputField}
            keyboardType="numeric"
            format={val => (val ? val.toString() : '')}
            placeholder="Width (ft)"
            label="Width (ft)"
            validate={required}
          />
          <div style={{ width: 10 }} />
          <Field
            name={`dimensions.set.${index}.depth`}
            type="number"
            component={SmallInputField}
            keyboardType="numeric"
            format={val => (val ? val.toString() : '')}
            placeholder="Depth (ft)"
            label="Depth (ft)"
            validate={required}
          />
          <div style={{ width: 10 }} />
          <Button
            color="warning"
            onClick={() => fields.remove(index)}
            style={{ alignSelf: 'flex-end', marginBottom: 16 }}
          >
            Remove
          </Button>
        </Dimensions>
      ))}
      <Button onClick={() => fields.push({})} color="primary">
        Add Measurements
      </Button>
    </div>
  )
}

const renderFixtures = ({ fields }) => {
  return (
    <div style={{ marginBottom: 15 }}>
      {fields.map((fixture, index) => (
        <Dimensions key={index} className="bg-light1">
          <Field
            name={`fixtures.${index}.type`}
            type="text"
            component={SmallInputField}
            format={val => (val ? val.toString() : '')}
            label="Type"
            validate={required}
          />
          <div style={{ width: 50 }} />
          <Field
            name={`fixtures.${index}.quantity`}
            type="number"
            component={SmallInputField}
            keyboardType="numeric"
            format={val => (val ? val.toString() : '')}
            label="Quantity"
            validate={required}
          />
          <div style={{ width: 10 }} />
          <Button
            color="warning"
            onClick={() => fields.remove(index)}
            style={{ alignSelf: 'flex-end', marginBottom: 16 }}
          >
            Remove
          </Button>
        </Dimensions>
      ))}
      <Button onClick={() => fields.push({})} color="primary">
        Add Fixtures
      </Button>
    </div>
  )
}

class AreaForm extends Component {
  render() {
    const { error, images, onImageClicked, jobType, initialValues } = this.props
    let gallery = null
    if (images && images.length) {
      gallery = images.map((m, index) => (
        <LazyImage
          src={m.url}
          alt={m.name}
          width={300}
          height={300}
          key={index}
          onClick={() => onImageClicked(m.url)}
        />
      ))
    }
    let errorMessage = null
    if (error) {
      errorMessage = <ErrorMessage error={error} />
    }

    if (initialValues && initialValues.rating) {
      for (let rating of ratings) {
        if (rating.id === initialValues.rating) {
          initialValues.rating = rating
        }
      }
    }

    return (
      <Card body>
        <form onSubmit={this.props.handleSubmit}>
          <Field
            name="name"
            placeholder="Room Name"
            component={InputField}
            type="text"
            label="Room Name"
            validate={[required, min3]}
            warn={required}
          />
          <Field
            name="type"
            placeholder="Room Type"
            component={DropdownField}
            type="select"
            label="Room Type"
            data={RoomTypes}
            validate={[required, min3]}
            warn={required}
          />

          {/* Hiding for 1.9.9 release
          <Field
            name="density"
            component={DropdownField}
            type="select"
            label="Density"
            data={['low', 'medium', 'high']}
          /> 
          */}

          <FieldArray name={`dimensions.set`} component={renderDimensionSet} />
          <Field
            name="floorType"
            placeholder="Floor Type"
            component={DropdownField}
            type="select"
            label="Floor Type"
            data={FloorTypes}
          />
          <FieldArray name={`fixtures`} component={renderFixtures} />
          {jobType && jobType === 'Inspection' && (
            <Field
              name="rating"
              placeholder="Rating"
              component={DropdownField}
              type="select"
              label="Rating"
              textField="label"
              data={ratings}
              validate={[required]}
              warn={required}
            />
          )}
          <Field
            name="notes"
            component={InputField}
            type="textarea"
            label="Notes"
          />
          <Field
            name="attachments"
            component={UploadField}
            type="file"
            label="Attach Photos"
            multiple
            validate={image}
          />
          <SubmitContainer>
            {errorMessage}
            <button type="submit" className="btn btn-primary">
              Done
            </button>
          </SubmitContainer>
        </form>
        {gallery}
      </Card>
    )
  }
}

export default reduxForm({
  form: 'Area'
})(AreaForm)
