import React, { Component } from 'react'
import {
  Elements,
  StripeProvider,
  CardElement,
  injectStripe
} from 'react-stripe-elements'
import styled, { withTheme } from 'styled-components'
import { IoIosLock } from 'react-icons/io'
import { Alert, Button } from 'reactstrap'
import { BusyButton } from 'components/lib/Button'
import BigCheckbox from 'components/fields/BigCheckbox'
import { Input } from 'reactstrap'

const AgreementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`

const CheckLabel = styled.p`
  font-size: 14px;
  margin: 0 0 0 12px;
`

const PromoInput = styled(Input)`
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  margin-bottom: 16px;
`

class CheckoutForm extends Component {
  state = {
    agreementChecked: false,
    promoCode: ''
  }

  submit = async data => {
    try {
      let { token } = await this.props.stripe.createToken({ name: 'Name' })
      
      if (!token) {
        throw new Error('Payment information is not valid.')
      }

      let paymentData = {
        token: token
      }
      
      if (this.state.promoCode) {
        paymentData.promoCode = this.state.promoCode
      }
      
      this.props.onData(paymentData)
      
      this.props.onNext()
    } catch (ex) {
      console.error('Exception during Stripe payment', ex)
      this.props.onError(
        typeof ex === 'string' ? ex : ex.message || ex.description
      )
    }
  }

  render() {
    const { agreementChecked } = this.state

    return (
      <div className="checkout" style={{ textAlign: 'left' }}>

        <label>Credit / Debit Card</label>
        <CardElement />
        <div style={{ marginBottom: 16 }} />

        <PromoInput
          type="text"
          name="promoCode"
          bsSize="sm"
          placeholder="Promo Code"
          value={this.state.promoCode}
          onChange={event => this.setState({promoCode: event.target.value})}
        />
        <AgreementContainer>
          <BigCheckbox 
            itemValue={agreementChecked}
            checked={agreementChecked}
            onChange={event => this.setState({agreementChecked: !agreementChecked})}
          />
          <CheckLabel>
            I am over 18 and accept the<br />      
            <a
              href="https://www.iubenda.com/privacy-policy/32463973"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            {' '}
            &
            {' '}
            <a
              href="https://getroute.com/termsandconditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </CheckLabel>
        </AgreementContainer>

        <BusyButton
          color="primary"
          style={{
            textTransform: 'uppercase',
            borderRadius: 32,
            width: 300,
            paddingTop: 16,
            paddingBottom: 16
          }}
          busy={this.props.busy}
          type="submit"
          onClick={this.submit}
          disabled={!agreementChecked}
        >
          Start Using Route
        </BusyButton>
      </div>
    )
  }
}

CheckoutForm = injectStripe(CheckoutForm)

class CheckoutFormWrapper extends React.Component {

  componentDidMount() {
    const { amplitudeInstance } = this.props
    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Payment Form (Enter Page)')
  }

  render() {
    const { currentStep, numSteps, data, error, reset, theme, history } = this.props

    // Cannot render without having picked a plan..
    if (!data) {
      return null
    }

    const { plan } = data

    const interval = plan ? plan.interval : 'monthly'
    const planName = plan ? plan.name : 'growth'
    let price = plan ? plan.price : 129
    price = Intl.NumberFormat("en-US").format(price);

    const errorIsTimeout = error && error.toLowerCase().includes('timeout')
    const userExistsError = error && error === 'Email already exists in Route.'
    let postErrorMessage = <div>
      Please{' '}
      {
        // eslint-disable-next-line
        <a href="#" onClick={reset}>
          update your information
        </a>
      }{' '}
      and try again.
    </div>

    if (errorIsTimeout) {
      postErrorMessage = <div> Please try again. </div>
    } else if (userExistsError) {
      postErrorMessage = <div>
        Please{' '}
        {
          // eslint-disable-next-line
          <a href="#" onClick={() => history.push('/login')}>
            log in here
          </a>
        }.
      </div>
    }

    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            width: '100%',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column'
          }}
        >
          <div
            style={{ maxWidth: 320, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <IoIosLock
              size={48}
              color={theme.colors.primary}
              style={{ marginBottom: 32 }}
            />
            <p style={{ textTransform: 'uppercase', marginBottom: 0 }}>
              Step <strong>{currentStep}</strong> of <strong>{numSteps}</strong>
            </p>
            <h1 style={{ fontSize: 24, marginBottom: 32 }}>
              Provide payment information.
            </h1>
            {error && error.length && (
              <Alert color="danger">
                {error} {postErrorMessage}
              </Alert>
            )}

            <Alert color="warning">
              <div
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <div>
                    <strong>
                      <span style={{ textTransform: 'capitalize' }}>
                        {planName}
                      </span>{' '}
                      Plan
                    </strong>
                  </div>
                  <div>
                    ${price} per {interval}
                  </div>
                </div>
                <Button color="link" onClick={this.props.openModal}>
                  change
                </Button>
              </div>
            </Alert>
            <Elements>
              <CheckoutForm
                onData={this.props.onData}
                onNext={this.props.onNext}
                onError={this.props.onError}
                busy={this.props.busy}
              />
            </Elements>
          </div>
        </div>
      </StripeProvider>
    )
  }
}

export default withTheme(CheckoutFormWrapper)