import React from 'react'

import Field from '../field'
import { PrimaryButton, Question } from '../ui'
import { InputState } from '../../../../../../hooks'

export const CommercialRecurringStage = ({
  current,
  id,
  label,
  onClick,
  options,
  type
}) => {
  const [value, setValue] = InputState(current || 'yes')

  const onNext = event => onClick(event, value)

  return (
    <Question>
      <Field
        id={id}
        label={label}
        labelStyle={{}}
        name={id}
        onChange={setValue}
        options={options}
        style={{}}
        type={type}
        value={value}
      />

      <PrimaryButton onClick={onNext} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
}

export default CommercialRecurringStage
