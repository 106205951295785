import React from 'react'
import { Route } from 'react-router-dom'

import Auth from './Auth'
import ClientScreens from './ClientScreens'
import Public from './Public'
import User from './User'
import Dashboard from './Dashboard'
import MembersRedirect from './MembersRedirect'
import MobileRedirect from './MobileRedirect'
import CheckoutSessionHandler from './CheckoutSessionHandler'

export default () => (
  <>
    <Public />
    <Auth />
    <ClientScreens />
    <Route path="/profile" component={User} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/members-only" component={MembersRedirect} />
    <Route path="/mobile" component={MobileRedirect} />
    <Route path="/checkout_session" component={CheckoutSessionHandler} />
  </>
)
