import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Manage from './Manage'

class UserArea extends React.Component {
  render() {
    const { currentEmployment } = this.props
    if (!currentEmployment) {
      return <Redirect to="/dashboard" />
    }
    return <Manage />
  }
}

const mapStateToProps = state => ({
  currentEmployment: state.user.currentEmployment
})

export default connect(
  mapStateToProps,
  null
)(UserArea)
