import React from 'react'
import { axiosClient } from 'store'
import _ from 'lodash'
import { Button, Input } from 'reactstrap'
import { CompactPicker } from 'react-color'
import { toast } from 'react-toastify'
import { FiEdit2, FiChevronLeft } from 'react-icons/fi'
import { connectConfirmation } from 'components/confirm'

import Label from './Label'

class LabelEditor extends React.Component {
  state = {
    labelData: null,
    formOpen: false,
    selectedLabel: null,
    formValues: {}
  }

  async componentDidMount() {
    try {
      const { data } = await axiosClient.get('/api/labels')
      if (data) {
        this.setState({
          labelData: data
        })
      }
    } catch (ex) {
      console.error('Label Editor encountered error: ', ex)
    }
  }

  _toggleForm = () => {
    this.setState({
      formOpen: !this.state.formOpen
    })
  }

  _submitLabel = async () => {
    const { formValues, labelData } = this.state
    if (!formValues.color) {
      return toast('Label needs a color selection.')
    }

    if (formValues.name) {
      const isNameUnique = _.find(
        labelData,
        l => {
          if (formValues._id && l._id === formValues._id) {
            return false
          }
          return l.name && l.name.toLowerCase() === formValues.name.toLowerCase()
        }
      )

      if (isNameUnique) {
        return toast('Label name must be unique.')
      }
    }

    try {
      let data
      if (formValues._id) {
        data = await axiosClient.patch(
          `/api/labels/${formValues._id}`,
          formValues
        )
      } else {
        data = await axiosClient.post('/api/labels', formValues)
      }
      if (this.props.refreshEntity) {
        this.props.refreshEntity()
      }
      if (data && data.data) {
        this.setState({
          labelData: data.data,
          formValues: {},
          formOpen: false
        })
      }
    } catch (ex) {
      console.error('Label Editor encountered error: ', ex)
    }
  }

  _deleteLabel = async id => {
    if (!id) {
      return
    }

    try {
      let data = await axiosClient.patch(`/api/labels/${id}/delete`)
      if (data && data.data) {
        this.setState({
          labelData: data.data,
          formValues: {},
          formOpen: false
        })
      }
      if (this.props.onChange) {
        this.props.onChange(this.props.data)
      }
    } catch (ex) {
      console.error('Label Editor encountered error: ', ex)
    }
  }

  _toggle = id => {
    let newData = [...(this.props.data || [])]
    if (_.find(newData, l => l._id === id)) {
      newData = newData.filter(item => item._id !== id)
    } else {
      newData.push(id)
    }

    if (this.props.onChange) {
      this.props.onChange(newData)
    }
  }

  _updateFormValues = values => {
    this.setState({
      formValues: values
    })
  }

  render() {
    let { data } = this.props
    data = data || []
    const { labelData, formOpen, formValues } = this.state

    let contents = (
      <>
        <h6>Labels</h6>
        {labelData &&
          labelData.length > 0 &&
          labelData.map((label, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                key={index}
              >
                <div style={{ width: '100%', marginRight: 4 }}>
                  <Label
                    key={label._id || index}
                    color={label.color}
                    style={{ marginBottom: 4, justifyContent: 'flex-start' }}
                    checked={_.find(data, l => l._id === label._id)}
                    onClick={() => this._toggle(label._id)}
                  >
                    {label.name}
                  </Label>
                </div>
                <FiEdit2
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this._updateFormValues({ ...label })
                    this._toggleForm()
                  }}
                />
              </div>
            )
          })}
        <hr />
        <Button onClick={() => {
          this._updateFormValues({})
          this._toggleForm()
        }}>Add a label</Button>
      </>
    )

    if (formOpen) {
      contents = (
        <form style={{ paddingTop: 8 }}>
          <FiChevronLeft
            size={20}
            style={{
              position: 'absolute',
              top: 6,
              cursor: 'pointer'
            }}
            onClick={this._toggleForm}
          />
          <Input
            name="name"
            placeholder="Name"
            value={formValues.name || ''}
            onChange={e => {
              let newValues = formValues
              newValues.name = e.target.value
              this._updateFormValues(newValues)
            }}
            style={{ marginBottom: 8 }}
          />
          <CompactPicker
            name="color"
            color={formValues.color || ''}
            onChangeComplete={color => {
              let newValues = formValues
              newValues.color = color.hex
              this._updateFormValues(newValues)
            }}
          />
          <div style={{ display: 'flex', marginTop: 8 }}>
            <Button type="button" onClick={this._submitLabel}>
              {formValues._id ? 'Update' : 'Create Label'}
            </Button>
            {formValues._id && (
              <Button
                type="button"
                color="danger"
                onClick={() => {
                  this.props.confirm(
                    'Confirm Label Deletion',
                    'If you delete this label it will be removed everywhere, are you sure you want to delete it?',
                    () => {
                      this._deleteLabel(formValues._id)
                    }
                  )
                }}
                style={{ marginLeft: 4 }}
              >
                Delete
              </Button>
            )}
          </div>
        </form>
      )
    }

    return <div style={{ padding: 16 }}>{contents}</div>
  }
}

export default connectConfirmation(LabelEditor)
