import React from 'react'
import { transparentize } from 'polished'
import moment from 'moment'

export default ({ element, proposal }) => {
  const { data } = element
  // const { location: { address: { fullAddress } } } = proposal
  let headerColor =
    data && data.headerColor ? data.headerColor : `rgba(225, 225, 225, 1)`

  let letterheadHeight = data && data.letterheadHeight ? data.letterheadHeight : null

  let headerText =
    data && data.headerTextColor ? data.headerTextColor : `rgba(88, 88, 88, 1)`

  let headerTextLight = transparentize(0.2, headerText)

  // data.clientAddress = proposal?.location?.address?.fullAddress || ''

  return (
    <div style={{ paddingBottom: 20 }}>
      <div className="header-merchant-area">
        <div className="logo-placement">
          {data.showMerchantLogo &&
            data.merchantLogo &&
            data.merchantLogo.url && (
              <img
                src={data.merchantLogo.url}
                className="logo"
                width="42"
                height="42"
                alt="merchant logo"
              />
            )}
          <div>
            <div className="header-company-name">{data.merchantName}</div>
            <div className="header-company-address">{data.merchantAddress}</div>
          </div>
        </div>
      </div>
      <div
        className="header-background"
        style={{ backgroundColor: headerColor }}
      >
        <div className="header-content" style={{ color: headerText }}>
          <div style={{ fontSize: 30, fontWeight: 'bold' }}>{data.title}</div>
          <div className="hr" style={{ backgroundColor: headerText }} />
          {data.showProposalCode && data.code && (
            <div
              style={{
                fontSize: 16,
                textAlign: 'right',
                color: headerTextLight
              }}
            >
              <em>
                {data.customProposalCode || `Proposal Code: ${data.code}`}
              </em>
            </div>
          )}
          {data.showProposalDate && data.proposalDate && (
            <div
              style={{
                fontSize: 16,
                textAlign: 'right',
                color: headerTextLight
              }}
            >
              <em>
                {`Proposal Date: ${moment(data.proposalDate).format('LL')}`}
              </em>
            </div>
          )}
          <div style={{ height: letterheadHeight, width: '100%' }} />
          <span style={{ fontSize: 14, color: headerTextLight }}>
            prepared for
          </span>
          <br />
          <div className="logo-placement">
            {data.showClientLogo && data.clientLogo && data.clientLogo.url && (
              <img
                src={data.clientLogo.url}
                className="logo"
                width="64"
                height="64"
                alt="client logo"
              />
            )}
            <div style={{ paddingTop: 10 }}>
              <div
                className="header-company-name"
                style={{ color: headerText }}
              >
                {data.clientName}
              </div>
              <div
                className="header-company-address"
                style={{ color: headerTextLight }}
              >
                {data.clientAddress}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
