import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'reactstrap'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { camelcase, titlecase } from 'utils'
import { HomeDepotForm } from './HomeDepotForm'
import products from './HomeDepotProducts'

import HDSupplyTHDLogo from 'assets/img/hd-supply-thdp-logo.png'
import HDSupplyLogo from 'assets/img/hd-supply-logo.png'

const ShopSection = styled.section`
  @media (min-width: 51.75em) {
    max-width: calc(51.75em - 2em);
  }

  @media (min-width: 75em) {
    max-width: calc(75em - 2em);
  }
  overflow: hidden;
  padding: 1rem;
  word-wrap: break-word;
  width: 100%;
  background-color: rgba(222, 226, 230, 0.05);
  margin: 0;
  max-width: none;
`

const HeadingPicture = styled.img`
  aspect-ratio: ${({ height, width }) => width / height};
  border-radius: 5px;
  opacity: 1;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const HeadingContent = styled.div`
  position: relative;
  padding: 0.5rem 1rem 1rem 1rem;

  @media (min-width: 37.5em) {
    padding: 2rem;
  }
`

const HeadingTitle = styled.h1`
  color: #ffffff;
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0 0 1rem 0;

  @media (min-width: 37.5em) {
    font-size: 1.5rem;
  }

  @media (min-width: 67.5em) {
    font-size: 1.75rem;
  }
`

const Favorites = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.inverseBody};
  border-radius: 5px;
  color: ${({ theme }) => theme.inverseText};
  margin-top: 2rem;
  padding: 1rem;
`

const FavoritesContent = styled.div`
  margin: 1rem auto 0 auto;
`

const FavoriteLists = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 67.5em) {
    flex-flow: row nowrap;
  }
`

const FavoriteList = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba({ red: 0, green: 0, blue: 0, alpha: 0.1 }), 0px 3px 30px rgba({ red: 0, green: 0, blue: 0, alpha: 0.1 }), inset 0px 0px 2px rgba({ red: 0, green: 0, blue: 0, alpha: 0.1 });
  margin: 1rem 0.5rem;
  padding: 1rem;
  width: 100%;

  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }
`

const Favorite = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
`

const Filters = styled.div`
  position: relative;
  margin-top: 2rem;
`

const Categories = styled.div`
  position: relative;
  margin-top: 1rem;

  @media (min-width: 37.5em) {
    display: flex;
    flex-flow: column wrap;
    height: 6rem;
    justify-content: center;
    max-width: 64rem;
  }
`

const Products = styled.div`
  position: relative;
  margin-top: 2rem;
`

const Group = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0.5rem 0;
`

const Product = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba({ red: 0, green: 0, blue: 0, alpha: 0.1 }), 0px 3px 30px rgba({ red: 0, green: 0, blue: 0, alpha: 0.1 }), inset 0px 0px 2px rgba({ red: 0, green: 0, blue: 0, alpha: 0.1 });
  margin: 0.75rem;
  padding: 1rem;
  width: calc((100% - 1.5rem) / 2);

  :first-of-type {
    margin-left: 0;
  }

  :nth-of-type(2n) {
    margin-right: 0;
  }

  :nth-of-type(2n + 1) {
    margin-left: 0;
  }

  @media (min-width: 37.5em) {
    width: calc((100% - 4.5rem) / 4);

    :nth-of-type(2n) {
      margin-right: 0.75rem;
    }

    :nth-of-type(2n + 1) {
      margin-left: 0.75rem;
    }

    :nth-of-type(4n) {
      margin-right: 0;
    }

    :nth-of-type(4n + 1) {
      margin-left: 0;
    }
  }

  @media (min-width: 120em) {
    width: calc((100% - 7.5rem) / 6);

    :nth-of-type(4n) {
      margin-right: 0.75rem;
    }

    :nth-of-type(4n + 1) {
      margin-left: 0.75rem;
    }

    :nth-of-type(6n) {
      margin-right: 0;
    }

    :nth-of-type(6n + 1) {
      margin-left: 0;
    }
  }
`

const RemoteImage = styled.img.attrs(props => {
  const attrs = { loading: props && props.loading ? props.loading : 'lazy' }

  if (!(props && props.height) && !(props && props.width)) {
    attrs.height = 48
    attrs.width = 48
  } else {
    if (props && props.height) {
      attrs.height = props.height
    }

    if (props && props.width) {
      attrs.width = props.width
    }
  }

  return attrs
})`

  aspect-ratio: ${({ aspectRatio }) => (aspectRatio ? aspectRatio : `auto`)};
  display: inline-flex;
  margin: auto;
  object-fit: ${({ objectFit }) => (objectFit ? objectFit : 'contain')};
  object-position: ${({ objectPosition }) => (objectPosition ? objectPosition : 'center')};
  margin: 0 0.5rem 0 0;
`

const ThankYouMessage = styled.p`
  text-align: 'center';
  margin: '1rem 0 2rem 0';
  font-size: 1.25rem;

  @media (min-width: 37.5em) {
    font-size: 1.5rem;
  }
`

const Picture = styled.img`
  aspect-ratio: ${({ height, width }) => width / height};
  border-radius: 5px;
  opacity: 1;
  position: relative;
`

const Divider = styled.hr`
  border: none;
  border-top: 2px dotted rgba(222, 226, 230, 0.3);
  color: rgba(222, 226, 230, 0.3);
  margin: 1rem 0;
`

export const LOCATION_DELIVERY_TYPES = {
  Dock: 'Building with a dock',
  Gate: 'Building with a lift gate',
  Other: 'Other'
}

const favorites = products.reduce(
  (all, product) => {
    if (product.favoriteOf) {
      for (let list of product.favoriteOf) {
        /**
         * NOTE: This was done in case favorite lists are not formatted in camcel case,
         * such as when a non-developer provides them.
         */
        const isLabel = Object.keys(all).some(key => all[key].label === list)
        const name = isLabel
          ? Object.keys(all).find(key => all[key].label === list)
          : camelcase(list)

        if (!all[name]) {
          all[name] = {
            label: titlecase(list),
            products: []
          }
        }

        all[name].products.push(product)
      }
    }

    return all
  },
  {
    commercial: {
      label: 'Commercial Cleaning',
      products: []
    },
    disinfection: {
      label: 'COVID-19 Disinfection',
      products: []
    },
    residential: {
      label: 'Residential Cleaning',
      products: []
    }
  }
)

const categories = Array.from(new Set(products.map(({ category }) => category)))
const filters = categories.map(category => ({
  label: titlecase(category),
  value: category
}))

const ProductGroup = ({ products, ...props }) => {
  const ref = createRef()

  const group = products.map(({ sku, name, picture }, index) => (
    <Product key={index} {...props}>
      <div styled={{ position: 'relative', textAlign: `center`, margin: `0 0 0.5rem 0` }}>
        <RemoteImage alt={`Picture of ${name}`} aspectRatio={1} src={picture} width={`100%`} />
      </div>
      <h4 style={{fontWeight: '400'}}>
        {name}
      </h4>
      <p style={{ margin: `0.25rem 0 0 0`, fontWeight: '500' }}>
        Part {sku}
      </p>
    </Product>
  ))

  return <Group ref={ref}>{group}</Group>
}

class HomeDepotProForm extends Component {
  state = {
    toggled: false,
    isTHDPro: false,
    sameAddress: true,
    submitted: false,
    activeFilters: []
  }

  onStart = (event) => this.toggle(true)

  onDone = (event) => this.toggle(false)

  onFilter = (value) => {
    const { activeFilters } = this.state 
    if (activeFilters.includes(value)) {
      this.setState({
        activeFilters: activeFilters.filter(filter => filter !== value)
      })
    } else {
      this.setState({
        activeFilters: [...activeFilters, value]
      })
    }
  }
  
  toggle = value => this.setState({ toggled: value })

  setIsTHDPro = value => this.setState({ isTHDPro: value})

  setSameAddress = value => this.setState({ sameAddress: value })

  setSubmitted = value => this.setState({ submitted: value })

  render() {
    const { toggled, submitted, activeFilters } = this.state
    const { current_company, employment, errors } = this.props

    const hq = 
      current_company && 
      current_company.address
      
    const address1 = hq && (hq.streetNumber + ' ' + hq.route)
    const userName = employment && employment.name

    const initialValues = {
      id: current_company && current_company._id,
      name: current_company && current_company.name,
      first_name: userName && userName.first,
      last_name: userName && userName.last,
      email: employment && employment.email,
      phone: employment && employment.phone,
      position: employment && employment.position,
      billing_address_one: address1,
      billing_city: hq && hq.city,
      billing_state: hq && hq.state,
      billing_zipcode: hq && hq.zipcode
    }

    const filteredProducts = activeFilters && activeFilters.length
    ? products.filter(({ category }) => activeFilters.includes(category))
    : products

    if (errors && errors.length > 0) {
      let serverError
      serverError = errors[0].message
      toast(serverError)
    }

    return (
      <ShopSection withSidebar={false}>
        {toggled ? (
          <Modal
            size={`md`}
            style={{ bottom: 'auto' }}
            isOpen={toggled}
            toggle={() => this.toggle(!toggled)}
          >
            {submitted ? (
              <div style={{ position: 'relative', textAlign: 'center', margin: '1rem 0' }}>
                <Picture
                  alt={`HD Supply Logo`}
                  height={35}
                  src={HDSupplyLogo}
                  width={262}
                />
                <ThankYouMessage>
                  Your application was successfully submitted.
                </ThankYouMessage>
                <Button
                  color={`action`}
                  type={`button`}
                  shape={`square`}
                  size={`xs`}
                  variant={`filled`}
                  onClick={event => this.onDone(event)}
                >
                  Done
                </Button>
              </div>
            ) : (
              <HomeDepotForm 
                initialValues={initialValues}
                orderSupplies={this.props.orderSupplies}
                setSubmitted={this.setSubmitted}
              />  
            )}
          </Modal>
        ) : null}

          <HeadingPicture
            alt={`Home Depot Pro and HD Supply Logo`}
            height={137}
            src={HDSupplyTHDLogo}
            width={'100%'}
          />
          <HeadingContent>
            <HeadingTitle>
              A partnership bringing your business the supplies it needs at a great price.
            </HeadingTitle>
            <Button
              color={`primary`}
              type={`button`}
              shape={`square`}
              size={`xs`}
              variant={`filled`}
              onClick={event => this.onStart(event)}
            >
              Join Now
            </Button>
          </HeadingContent>

        <Divider />
        <Filters>
          <h2 style={{ color: '#ffffff', fontSize: '1.25rem', fontWeight: '400' }} size={`sm`}>
            Top Categories
          </h2>
          <Categories>
            {filters.map(({ label, value }) => (
              <p
                key={value}
                onClick={event => this.onFilter(value)}
                style={{ 
                  cursor: 'pointer',
                  margin: '0.25rem',
                  fontWeight: '500',
                  color: activeFilters.includes(value) ? '#0b64e9' : '#ffffff'
                }}
              >
                {label}
              </p>
            ))}
          </Categories>
        </Filters>
        <Divider />

        <Favorites>
          <h2 style={{color: '#ffffff'}}>Not sure where to start?</h2>
          <p style={{ color: '#ffffff', fontSize: '1.25rem'}}>
            Try one of sample our kits.
          </p>
          <FavoritesContent>
            <FavoriteLists>
              {Object.keys(favorites).map(key => (
                <FavoriteList key={key}>
                  <h4 style={{
                    fontWeight: '500',
                    color: '#191919'
                  }}>
                    {favorites[key].label}
                  </h4>
                  <Divider style={{color: '#000' }} />
                  {favorites[key].products.map(({ name, picture }, index) => (
                    <Favorite key={index}>
                      <RemoteImage
                        alt={`Picture of ${name}`}
                        aspectRatio={1}
                        src={picture}
                        width={48}
                      />
                      <p style={{
                        color: '#191919'
                      }}>{name}</p>
                    </Favorite>
                  ))}
                </FavoriteList>
              ))}
            </FavoriteLists>
            <Button
              color={`primary`}
              dimensions={{ m: '1rem 0 0 0' }}
              type={`button`}
              shape={`square`}
              size={`xs`}
              onClick={event => this.onStart(event)}
            >
              Get Started
            </Button>
          </FavoritesContent>
        </Favorites>

        <Products>
          <h2 style={{ color: '#ffffff', margin: '0 0 0.25rem 0' }}>
            All Products
          </h2>
          <ProductGroup products={filteredProducts} />
        </Products>

        <Button
          color={`primary`}
          type={`button`}
          shape={`square`}
          size={`xs`}
          onClick={event => this.onStart(event)}
        >
          Join Now
        </Button>
      </ShopSection>
    )
  }
}

const mapStateToProps = state => ({
  current_company: state.user.currentCompany,
  employment: state.user.currentEmployment,
  errors: state.shop.errors
})

export const HomeDepotPro = compose(
  reduxForm({form: 'HomeDepotPro'}),
  connect(
    mapStateToProps,
    null
  )
)(HomeDepotProForm)
