import { useState } from 'react';
import { ItemRow, ItemValueContainer, StyledDropdown } from './ListStyles';

export const LocationImportItem = ({ item, index, onSelectClient, clients }) => {
  const [selectedClient, setSelectedClient] = useState();
  const _selectClient = (value) => {
    let newItem = { ...item, client: value._id };
    setSelectedClient(value);
    onSelectClient(newItem, index);
  }
  return (
    <ItemRow>
      <ItemValueContainer width='200px'>{item.locationName}</ItemValueContainer>
      <ItemValueContainer width='400px'>{item.address.formattedAddress}</ItemValueContainer>
      <ItemValueContainer width='250px'>
        <StyledDropdown
          name="client"
          data={clients}
          textField={item => (item ? item.name : null)}
          placeholder="Select client"
          value={selectedClient}
          onChange={(value) => {
            _selectClient(value);
          }}
        />
      </ItemValueContainer>
    </ItemRow>
  )
}