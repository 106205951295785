import Icon from 'components/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import MPIcon from 'assets/img/MP-Transparent.png'
import PLIcon from 'assets/img/Pulse-Transparent.png'


const iconStyle = {

    width: '2rem',
    'margin-right': '1rem'

}

export const AppRedirectMenu = () => {

    const token = useSelector(state => state.user?.token)

    const handleRedirect = (param) => {

        const APP_MARK = `${process.env.REACT_APP_MARKET_BASE_URL}/auth-verification`
        const APP_PULSE = `${process.env.REACT_APP_PULSE_BASE_URL}/oauth_redirection`

        const authToken = window.localStorage.getItem('AuthToken')


        if (param === 1) {
            window.open(`${APP_MARK}?access_token=${authToken}`, '_self')
            return
        }

        window.open(`${APP_PULSE}#access_token=${authToken}`, '_self')

        return
    }


    return (
        <>
            <UncontrolledDropdown
                nav
                inNavbar
                style={{
                    padding: 0,
                    marginTop: 0,
                    marginBottom: 0
                }}>

                <DropdownToggle
                    nav
                    caret={false}
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Icon
                        size={30}
                        icon="TbGridDots"
                        color={'white'}
                        style={{ cursor: 'pointer' }}
                    />

                </DropdownToggle>


                <DropdownMenu right>
                    <DropdownItem className="logoutNew" onClick={() => handleRedirect(1)}>
                        <img src={MPIcon} alt="" style={iconStyle} />
                        Route Marketplace
                    </DropdownItem>
                    <DropdownItem className="logoutNew" onClick={() => handleRedirect(2)}>
                        <img src={PLIcon} alt="" style={iconStyle} />
                        Route Pulse
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown >
        </>
    )
}
