export const addons = [
  {
    name: 'interiorWindows',
    label: 'Interior Windows',
    pattern: '[0-9]',
    rate: 5,
    required: true,
    step: 1,
    type: 'number'
  },
  {
    name: 'exteriorWindows',
    label: 'Exterior Windows',
    pattern: '[0-9]',
    rate: 5,
    required: true,
    step: 1,
    type: 'number'
  },
  {
    name: 'cabinets',
    label: 'Cabinet Deep Clean',
    pattern: '[0-9]',
    rate: 25,
    required: true,
    step: 1,
    type: 'number'
  },
  {
    name: 'fridges',
    label: 'Fridge Deep Clean',
    pattern: '[0-9]',
    rate: 25,
    required: true,
    step: 1,
    type: 'number'
  },
  {
    name: 'ovens',
    label: 'Oven Deep Clean',
    pattern: '[0-9]',
    rate: 25,
    required: true,
    step: 1,
    type: 'number'
  }
]

export default addons
