import React from 'react'
import { Container } from 'reactstrap'

export default () => (
  <div style={{ backgroundColor: 'white', height: '100%', paddingTop: 80 }}>
    <Container>
      <h1>Route: Terms Of Use</h1>
    </Container>
  </div>
)
