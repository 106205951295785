import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

import Avatar from './lib/Avatar'

class UserMiniCard extends React.Component {
  state = {
    uid: _.uniqueId('person-mini-card-')
  }

  render() {
    const { employee, showName, size, style } = this.props
    const { uid } = this.state
    if (!employee || !employee.name) {
      return null
    }

    return (
      <span style={style}>
        <Avatar
          border
          url={employee.user?.avatar?.url || ''}
          id={uid}
          link={true}
          size={size}
          name={employee.name?.full}
          onClick={() =>
            this.props.history.push(`/dashboard/employees/${employee._id}`)
          }
        >
          <UncontrolledTooltip target={uid} delay={250}>
            {employee?.name?.full}
            <br />
            {employee.email}
          </UncontrolledTooltip>
        </Avatar>{' '}
        {showName && employee.name?.full}
      </span>
    )
  }
}

UserMiniCard.defaultProps = {
  showName: false
}

export default withRouter(UserMiniCard)
