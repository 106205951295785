import styled from "styled-components";
import { Button } from "reactstrap";

export const UploadTitle = styled.h2`
  font-size: 23px;
  font-weight: 700px;
  color: #6A6A6A;
  lint-height: 27.84px;
`;

export const UploadText = styled.p`
  font-size: 13px;
  font-weight: ${({fw}) => fw ? fw : '400'};
  color: ${({color}) => color ? color : '#808080'};
`;

export const DropZoneStyle = {
  dropzone: { overflowX: 'hidden', overflowY: 'auto', maxHeight: '200px', border: '1px dashed #D9D9D9', borderRadius: '10px', padding: '20px', textAlign: 'center' },
  submitButtonContainer: { display: 'none' },
  preview: { border: 'none' },
  inputLabel: { color: '#A0ABB0', fontSize: '13px', fontWeight: '600' },
  inputLabelWithFiles: { display: 'none' }
}

export const ImportSubmitButton = styled(Button)`
  margin-top: 20px;
  width: 100px;
  align-self: flex-end
`;

export const SampleDownloadLink = styled.a`
  font-size: 13px;
  font-weight: 500;
  color: #445CE9;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 10px;
`;