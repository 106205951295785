import React, { Component } from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'reactstrap'
import _ from 'lodash'

import { DropdownField } from 'components/fields'

export const JobItem = ({ item }) => {
  if (item) {
    return (
      <span>
        {item.code && item.code + ' - '}
        {item.client && item.client.name}
      </span>
    )
  } else {
    return null
  }
}

class SelectWalkthroughForm extends Component {
  render() {
    const { jobs, goBack } = this.props

    const sortedWalkthroughs = _.orderBy(jobs, ['createdAt'], ['desc'])

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          name="job"
          component={DropdownField}
          data={sortedWalkthroughs}
          itemComponent={JobItem}
          type="select"
          label="Walkthrough"
          textField={item => (item ? `${item.code} - ${item.client.name}` : null)}
          normalize={value => (value ? value : null)}
          filter={'contains'}
        />
        <Button block type="submit" color="primary" style={{ marginTop: 24 }}>
          Next
        </Button>
        <Button
          block
          type="button"
          color="secondary"
          style={{ marginTop: 4 }}
          onClick={goBack}
        >
          Back
        </Button>
      </form>
    )
  }
}

export default compose(
  reduxForm({
    form: 'NewEstimate',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
)(SelectWalkthroughForm)
