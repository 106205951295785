import React, { Component } from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'

import SelectClientLocation from 'screens/Dashboard/components/forms/SelectClientLocation'

class SelectClientForm extends Component {

  render() {
    const { goBack, onSubmit } = this.props

    return (
      <form>
        <SelectClientLocation
          formName="NewEstimate"
          onSubmit={onSubmit}
          goBack={goBack}
        />
      </form>
    )
  }
}

export default compose(
  reduxForm({
    form: 'NewEstimate',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
)(SelectClientForm)
