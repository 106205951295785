import styled, { css } from "styled-components";
import { DropdownList } from "react-widgets";

const borderStyle = '1px solid #808080;'

const listLayoutStyle = css`
  display: flex;
  justify-content: space-between;
`;


export const ListHeaderRow = styled.div`
  ${listLayoutStyle}
`;

export const ItemRow = styled.div`
  ${listLayoutStyle}
  border-bottom: ${borderStyle};
  border-left: ${borderStyle};
  border-right: ${borderStyle};
`;

export const HeaderRow = styled.div`
  display: flex;
`;

export const ListBox = styled.div`
  max-height: 300px;
  border-top: ${borderStyle};
  min-width: fit-content;
`;

export const ItemValueContainer = styled.div`
  padding: 5px 10px;
  width: ${({width}) => width ? width : 'auto'};
  align-self: center;
`;

export const StyledDropdown = styled(DropdownList)`
  border-radius: 5;
`;

export const ErrorMessageText = styled.p`
  font-size: 11px;
  font-weight: ${({fw}) => fw ? fw : '400'};
  color: #d24b4b;
  margin: 0;
`;