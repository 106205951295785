export const getPlanControlInfo = relationship => async dispatch => {
  let [action, entity] = relationship.split('.')
  return dispatch({
    type: `GET_${action.toUpperCase()}_${entity.toUpperCase()}_PCL`,
    payload: {
      request: {
        url: `/api/pcl/isAllowed/${action}/${entity}`
      }
    }
  })
}
