import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BusyButton } from 'components/lib/Button'

import InputField from 'components/fields/InputField'
import ErrorMessage from 'components/ErrorMessage'
import { requiredWithMessage } from 'utils/validators'

const usernameRequired = requiredWithMessage('Please enter your username')
const passwordRequired = requiredWithMessage('Please enter your password')

class SweptConnectForm extends Component {

  render() {
    const { handleSubmit, busy } = this.props
    let errorMessage = null
    if (this.props.error) {
      errorMessage = <ErrorMessage error={this.props.error} />
    }

    return (
      <div className="card">
        <div className="card-body" style={{ padding: 50 }}>
          <form onSubmit={handleSubmit}>
            <Field
              name="username"
              component={InputField}
              placeholder="Username"
              type="text"
              validate={[usernameRequired]}
            />
            <Field
              name="password"
              component={InputField}
              placeholder="Password"
              type="password"
              validate={passwordRequired}
            />
            <div className="form-group">
              {errorMessage}
              <BusyButton block color="primary" type="submit" busy={busy}>
                Connect to Swept
              </BusyButton>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentEmployment: state.user.currentEmployment
})

export default reduxForm({ form: 'sweptConnect' })(connect(mapStateToProps)(SweptConnectForm))
