import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { axiosClient } from 'store'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { toast } from 'react-toastify'

import { SweptInfo } from './components'
import SweptConnectForm from '../components/forms/SweptConnectForm'
import { connectSweptAccount, disconnectSweptAccount } from '../../../api/employees'

const ContainerCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: .5em .5em .4em #000;
  min-height: 40px;
  min-width: max-content;
  max-width: 60vw;
  margin: 16px auto;
  padding: 16px;
`


export const Swept = () => {
  const { currentEmployment } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [ showModal, setShowModal ] = useState(false)
  const [ sweptData, setSweptData ] = useState(null)
  const [ buttonBusy, setButtonBusy ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const connectSwept = (id, data) => dispatch(connectSweptAccount(id, data))
  const disconnectSwept = (id, data) => dispatch(disconnectSweptAccount(id, data))

  useEffect(() => {
    const getSweptInfo = async () => {
      let response = await axiosClient.get(
        `/api/employees/${currentEmployment._id}/sweptInfo`
      )

      if (response && response.data) {
        setSweptData(response.data)
        setLoading(false)
      }
    }
    if (currentEmployment && currentEmployment.sweptToken) {
      getSweptInfo()
    } else {
      setLoading(false)
    }
  }, [currentEmployment['sweptToken']])
  
  const sweptSignOut = async () => {
    setButtonBusy(true)
    disconnectSwept(currentEmployment._id)
    setSweptData(null)
    toast('You have signed out from swept')
    setButtonBusy(false)
  }
  
  const handleSweptConnect = async (values) => {
    const { username, password } = values
    try {
      setButtonBusy(true)
      let res = await connectSwept(
        currentEmployment._id,
        {
          username,
          password
        }
      )

      if (res && res.payload && res.payload.data) {
        toast('Successfully connected to your Swept account')
        setShowModal(false)
        setButtonBusy(false)
      } else if (res && res.error && res.error) {
        throw res.error
      }
    } catch (err) {
      console.error('Swept error: ', err)
      const { message } = err && err.response && err.response.data
      setButtonBusy(false)
      toast(message)
    }
  }

  return (
    <>
      <ContainerCard>
        <Modal isOpen={showModal} style={{ paddingTop: 50 }}>
          <ModalHeader
            style={{ justifyContent: 'center' }}
            toggle={() => setShowModal(false)}
          >
            {'Connect to Swept'}
          </ModalHeader>
          <ModalBody>
            <p>Connect your Swept and Route accounts</p>
            <SweptConnectForm
              initialValues={{ username: currentEmployment.email }}
              onSubmit={handleSweptConnect}
              busy={buttonBusy}
            />
          </ModalBody>
        </Modal>
        <SweptInfo 
          sweptData={sweptData} 
          isLoading={loading} 
          signout={sweptSignOut} 
          showModal={() => setShowModal(true)} 
          buttonBusy={buttonBusy}
        />
      </ContainerCard>
    </>
  )
}