import _ from 'lodash'
import { capitalize } from './formatters'

export default areas => {

  let result = {
    total: 0,
    types: {}
  }
  
  if (!areas || !areas.length) {
    return result
  }

  let allFixtures = _.flatten(_.map(areas, 'fixtures'))
  // compact is necessary to remove all undefined within the array as flat removed only holes
  allFixtures = _.compact(allFixtures)
  let fixturesGrouped = _.groupBy(allFixtures, fixture =>
    fixture.type.toLowerCase().trim()
  )

  let fixturesGroupedKeys = Object.keys(fixturesGrouped)
  fixturesGroupedKeys = _.orderBy(fixturesGroupedKeys, 'key', 'asc')
  fixturesGroupedKeys.forEach(key => {
    const quantity = fixturesGrouped[key].reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.quantity, 10),
      0
    )
    
    result.total += quantity
    result.types[capitalize(key)] = quantity
  })

  return result
}