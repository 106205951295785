import React from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";


const ActionsContainer = styled.div`
    display : flex;
    column-gap : 1rem;
    justify-content : center;
`

export const TableRow = ({ element, setDataToEdit, deleteElement }) => {
    return (
        <tr>
            <td>
                {element.name}
            </td>
            <td>
                {element.type}
            </td>
            <td>
                <ActionsContainer>
                    <Button color='primary' onClick={setDataToEdit}><FaEdit /></Button>
                    <Button color='danger' onClick={deleteElement}><MdDeleteForever /></Button>
                </ActionsContainer>
            </td>
        </tr>
    )
}
