export default {
  street_number: 'streetNumber',
  route: 'route',
  floor: 'floor',
  room: 'room',
  id: 'placeId',
  neighborhood: 'neighborhood',
  locality: 'city',
  administrative_area_level_1: 'state',
  administrative_area_level_2: 'zone',
  administrative_area_level_3: 'region',
  postal_code: 'zipcode',
  postal_code_suffix: 'zipcodeSuffix',
  country: 'country',
  latitude: 'latitude',
  longitude: 'longitude',
  fullAddress: 'fullAddress',
  googleMapLink: 'googleMapLink',
  phone: 'phone'
}
