import React, { Component } from 'react'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import styled from 'styled-components'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import CalendarToolBar from './CalendarToolBar'
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
// or globalizeLocalizer

const CalendarWrapper = styled.div`
  max-height: calc(100vh - 260px) !important;
  height: calc(100vh - 260px) !important;
`

class JobsCalendar extends Component {
  eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      fontSize: '0.8em',
      backgroundColor: '#0B64EB',
      opacity: 0.8,
      display: 'block',
      borderRadius: 3
    }
    return {
      style
    }
  }

  render() {
    const { onOpen, data, month } = this.props

    const localizer = BigCalendar.momentLocalizer(moment)

    const renderCalendar = (
      <CalendarWrapper>
        <BigCalendar
          defaultDate={(month && month.toDate()) || moment().toDate()}
          localizer={localizer}
          views={['month', 'week', 'day']}
          defaultView={'month'}
          events={data}
          startAccessor={job => {
            if (job.startedAt) {
              return moment(job.startedAt).toDate()
            }
            return moment(job.scheduledAt).toDate()
          }}
          endAccessor={job => {
            if (job.completedAt) {
              return moment(job.completedAt).toDate()
            }
            return moment(job.scheduledAt)
              .add(1, 'hour')
              .toDate()
          }}
          onSelectEvent={onOpen}
          titleAccessor={job =>
            `${job.code ? job.code + ' - ' : null}${job.name ||
              (job.client && job.client.name)}`
          }
          scrollToTime={moment()
            .hours(6)
            .minutes(0)
            .seconds(0)
            .toDate()}
          popup={true}
          eventPropGetter={this.eventStyleGetter}
          components={{
            toolbar: CalendarToolBar
          }}
        />
      </CalendarWrapper>
    )

    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: 20,
          borderRadius: 12
        }}
      >
        {renderCalendar}
      </div>
    )
  }
}

export default JobsCalendar
