const parseString = paramStr => {
  paramStr = paramStr.slice(1)
  let data = paramStr.split(/=(.+)/)
  data[1] = data[1].replace(/%3D/g, '')
  return data
}

export const atob = (paramStr) => {
  const data = parseString(paramStr)
  try {
    const decodedStr = window && window.atob(data[1])
    return {
      id: data[0],
      data: decodedStr
    }
  } catch (error) {
    console.error('atob decoding error: ', error)
  }
}