import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
import * as api from 'api'
import { connect } from 'react-redux'
import { Col, FormGroup, Label, Input, Row, Tooltip } from 'reactstrap'
import Autocomplete from 'react-google-autocomplete'

import { GoogleApiWrapper } from 'utils/GoogleApiComponent'
import Avatar from 'components/lib/Avatar'
import Pin from 'components/MapPin'
import CustomInfoMiniView from 'components/CustomInfoMiniView'

const MapControls = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
  width: 80%;
  max-width: 600px;
  height: 100px;
  padding: 10px 20px;
  background-color: white;
  z-index: 100;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
`

const MapContainer = styled.div`
  position: relative;
  margin-left: 10px;
  height: calc(100% - 20px);
`

const ClientMarker = styled.div`
  position: absolute;
  top: ${props => (props.logoAvailable ? '50%' : 'calc(50% - 7px)')};
  left: ${props => (props.logoAvailable ? '50%' : 'calc(50% + 2px)')};
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  transform: translate(-50%, -50%);
`

const PinMarker = styled.div`
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% + 2px);
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  transform: translate(-50%, -50%);
`

const EmployeeMarker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  transform: translate(-50%, -50%);
`

const getEmployeeLocation = employee => {
  if (
    employee.address &&
    employee.address.latitude &&
    employee.address.longitude
  ) {
    return employee.address
  }

  if (
    employee.user &&
    employee.user.address &&
    employee.user.address.latitude &&
    employee.user.address.longitude
  ) {
    return employee.user.address
  }
}

class BigMap extends Component {
  state = {
    ready: false,
    center: {
      lat: 41.888643049292845,
      lng: -88.12566309551859
    },
    zoom: 11,
    showEmployeeMarkers: true,
    showClientMarkers: true,
    pin: null,
    hoverKey: null,
    inputAddress: null
  }

  async componentDidMount() {
    const company = this.props.company

    if (company && company.address) {
      this.setState({
        ready: true,
        center: {
          lat: company.address.latitude,
          lng: company.address.longitude
        }
      })
    }
    // Get company's location.
    await this.props.getEmployees()
    // Get all clients.
    await this.props.getClients()
    // Get all employees.
  }

  onHoverKeyChange = key => {
    this.setState({ hoverKey: key })
  }

  _onChildMouseEnter = (key /*, childProps */) => {
    this.onHoverKeyChange(key)
  }

  _onChildMouseLeave = (/* key, childProps */) => {
    this.onHoverKeyChange(null)
  }

  render() {
    const { google } = this.props
    if (!google) {
      // console.log('WAITING FOR AUTOCOMPLETE PROPS...'
      return
    }

    const { clients, company, employees } = this.props
    const { pin, showEmployeeMarkers, showClientMarkers } = this.state
    //let employeesNotPending = (employees || []).filter(employee =>  employee.user)
    let employeesWithAddress = (employees || []).filter(employee =>
      getEmployeeLocation(employee)
    )
    const pinMarker = pin ? (
      <PinMarker
        key={'thePin'}
        lat={pin.lat}
        lng={pin.lng}
        width={37}
        height={40}
        id={'PinMarker'}
      >
        <Pin color={'green'} />
        <Tooltip
          placement="top"
          isOpen={this.state.hoverKey === 'thePin'}
          target={'PinMarker'}
          arrowClassName={'tooltip-arrow'}
        >
          <strong dangerouslySetInnerHTML={{ __html: pin.adr_address }} />
        </Tooltip>
      </PinMarker>
    ) : null

    const clientMarkers = (clients || []).map(client => {
      return (client.locations || []).map(location => {
        if (
          !location.address ||
          !location.address.latitude ||
          !location.address.longitude
        ) {
          return null
        }
        return (
          <ClientMarker
            key={location._id}
            lat={location.address.latitude}
            lng={location.address.longitude}
            width={client.logo ? 24 : 37}
            height={client.logo ? 24 : 40}
            logoAvailable={client.logo && client.logo.url}
            hover={this.state.hoverKey === location._id}
            id={'ClientMarker-' + location._id}
            onClick={() =>
              this.props.history.push(
                `/dashboard/location-details/${location._id}`
              )
            }
          >
            {client.logo ? (
              <Avatar
                url={client.logo.url}
                size={24}
                border={'2px solid black'}
              />
            ) : (
              <Pin color={'#F01D1D'} />
            )}
            <Tooltip
              placement="top"
              isOpen={this.state.hoverKey === location._id}
              target={'ClientMarker-' + location._id}
              arrowClassName={'tooltip-arrow'}
            >
              <strong>{client.name}</strong>
              <br />
              <span>{location.address && location.address.fullAddress}</span>
              <span> -- {location.type}</span>
              <CustomInfoMiniView dark data={client.customInfo} />
              <CustomInfoMiniView dark data={location.customInfo} />
            </Tooltip>
          </ClientMarker>
        )
      })
    })

    const employeeMarkers = employeesWithAddress.map(employee => {
      const location = getEmployeeLocation(employee)
      const name =
        employee.name && employee.name.full
          ? employee.name.full
          : 'Name not found'
      return (
        <EmployeeMarker
          key={employee._id}
          onClick={() =>
            this.props.history.push(`/dashboard/employees/${employee._id}`)
          }
          lat={location.latitude}
          lng={location.longitude}
          id={'EmployeeMarker-' + employee._id}
          width={32}
          height={32}
        >
          <Avatar
            url={
              employee.user && employee.user.avatar
                ? employee.user.avatar.url
                : ''
            }
            size={32}
            name={name}
            border={'2px solid #0060ff'}
          />
          <Tooltip
            placement="top"
            isOpen={this.state.hoverKey === employee._id}
            target={'EmployeeMarker-' + employee._id}
            arrowClassName={'tooltip-arrow'}
          >
            <strong>{name}</strong>
            <br />
            <span>{location.fullAddress}</span>
            <CustomInfoMiniView dark data={employee.customInfo} />
          </Tooltip>
        </EmployeeMarker>
      )
    })

    const companyMarker =
      company && company.address ? (
        <ClientMarker
          key={company._id}
          lat={company.address.latitude}
          lng={company.address.longitude}
          width={37}
          height={40}
          hover={this.state.hoverKey === company._id}
          id={'Tooltip-' + company._id}
        >
          <Pin />
          <Tooltip
            placement="top"
            isOpen={this.state.hoverKey === company._id}
            target={'Tooltip-' + company._id}
            arrowClassName={'tooltip-arrow'}
          >
            <strong>Your Location</strong>
            <CustomInfoMiniView dark data={company.customInfo} />
          </Tooltip>
        </ClientMarker>
      ) : null

    return (
      <MapContainer>
        <MapControls>
          <Row>
            <Col xs="4">
              <h4>Show</h4>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.showClientMarkers}
                    onChange={() =>
                      this.setState({
                        showClientMarkers: !this.state.showClientMarkers
                      })
                    }
                  />{' '}
                  Client Locations
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.showEmployeeMarkers}
                    onChange={() =>
                      this.setState({
                        showEmployeeMarkers: !this.state.showEmployeeMarkers
                      })
                    }
                  />{' '}
                  Employees
                </Label>
              </FormGroup>
            </Col>
            <Col xs="8">
              <h4>Add a Pin</h4>
              <Autocomplete
                style={{ width: '90%' }}
                onPlaceSelected={place => {
                  const lat =
                    place.geometry && place.geometry.location
                      ? place.geometry.location.lat()
                      : null
                  const lng =
                    place.geometry && place.geometry.location
                      ? place.geometry.location.lng()
                      : null
                  let pin = { ...place }
                  pin.lat = lat
                  pin.lng = lng
                  this.setState({
                    pin,
                    center: {
                      lat: lat,
                      lng: lng
                    }
                  })
                }}
                types={['address']}
                componentRestrictions={{ country: 'us' }}
              />
            </Col>
          </Row>
        </MapControls>

        <GoogleMapReact
          center={this.state.center}
          defaultCenter={this.state.center}
          defaultZoom={this.state.zoom}
          hoverDistance={15}
          onChildMouseEnter={this._onChildMouseEnter}
          onChildMouseLeave={this._onChildMouseLeave}
          yesIWantToUseGoogleMapApiInternals
        >
          {showClientMarkers && clientMarkers}
          {showEmployeeMarkers && employeeMarkers}
          {pinMarker}
          {companyMarker}
        </GoogleMapReact>
      </MapContainer>
    )
  }
}

const mapStateToProps = state => ({
  employees: state.employees.employees,
  clients: state.clients.clients,
  company: state.user.currentCompany
})

export default connect(
  mapStateToProps,
  api
)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  })(BigMap)
)
