import React, { useState } from 'react'
import { FaMinus, FaXmark, FaCheck } from 'react-icons/fa6'
import './switchStyles.css'
import { taskStateValues, switchStateValues } from './utilsStatesValues'

const initialValues = {
  labels: {
    left: {
      title: 'left',
      value: 'left'
    },
    center: {
      title: 'center',
      value: 'center'
    },
    right: {
      title: 'right',
      value: 'right'
    }
  },
  onChange: value => console.log('value:', value)
}

export const TripleStateSwitch = ({ handleTaskStatus, taskIndex, taskStatus }) => {
  const position = switchStateValues[taskStatus]
  const [labels, setLabels] = useState(initialValues.labels)
  const [switchPosition, setSwitchPosition] = useState(position)
  const [animation, setAnimation] = useState(null)
  const [showIcon, setShowIcon] = useState(true)

  const getSwitchAnimation = (value, index) => {
    // const { switchPosition } = this.state;
    let animation = null
    if (value === 'center' && switchPosition === 'left') {
      animation = 'left-to-center'
    } else if (value === 'right' && switchPosition === 'center') {
      animation = 'center-to-right'
    } else if (value === 'center' && switchPosition === 'right') {
      animation = 'right-to-center'
    } else if (value === 'left' && switchPosition === 'center') {
      animation = 'center-to-left'
    } else if (value === 'right' && switchPosition === 'left') {
      animation = 'left-to-right'
    } else if (value === 'left' && switchPosition === 'right') {
      animation = 'right-to-left'
    }
    setAnimation(animation)
    setSwitchPosition(value)
    setShowIcon(false)
    setTimeout(() => {
      setShowIcon(true)
    }, 400)
    handleTaskStatus(taskStateValues[value], index)
    // this.setState({ switchPosition: value, animation });
  }

  const leftId = `left${taskIndex}`
  const centerId = `center${taskIndex}`
  const rightId = `right${taskIndex}`
  return (
    <>
      <div id={labels} className={`main-container ${switchPosition}-position`}>
        <div className={`switch ${animation} ${switchPosition}-position`}></div>
        <input
          onChange={e => getSwitchAnimation(e.target.value, taskIndex)}
          name="map-switch"
          className="left-button"
          id={leftId}
          type="radio"
          value="left"
        />
        <label
          className={`left-label ${switchPosition === 'left' && 'black-font'}`}
          htmlFor={leftId}
        >
          {showIcon && switchPosition === 'left' && <FaCheck />}
        </label>

        <input
          defaultChecked={true}
          // onChange={(e) => getSwitchAnimation(e.target.value)}
          name="map-switch"
          type="radio"
          value="center"
          className='center-button'
          id={centerId}
          disabled
        />
        <label
          className={`center-label ${showIcon && switchPosition === 'center' &&
            'black-font'}`}
          htmlFor={centerId}
        >
          {showIcon && switchPosition === 'center' && <FaMinus />}{' '}
        </label>

        <input
          onChange={e => getSwitchAnimation(e.target.value, taskIndex)}
          name="map-switch"
          type="radio"
          id={rightId}
          value="right"
          className="right-button"
        />
        <label
          className={`right-label ${switchPosition === 'right' &&
            'black-font'}`}
          htmlFor={rightId}
        >
          {' '}
          {showIcon && switchPosition === 'right' && <FaXmark />}
        </label>
      </div>
    </>
  )
}
