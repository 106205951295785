import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, withRouter } from 'react-router-dom'
import qs from 'query-string'
import styled from 'styled-components'

import RouteLogoFull from 'assets/img/route-logo-full.png'
import Header from 'components/layout/Header'
import * as api from 'api'

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 15vh;
`

const Logo = styled.img`
  width: 300px;
  height: auto;
`

const Text = styled.p`
  padding: 4rem 1rem;
  color: ${({ theme }) => theme.colors.light1};
  text-align: center;
`

class Login extends Component {
  async componentDidMount() {
    if (
      this.props.location.search &&
      this.props.location.search.includes('token=')
    ) {
      const query = qs.parse(this.props.location.search)
      const token = query.token

      if (token) {
        await this.props.OAuthLogin(token)
        this.props.history.push('/dashboard')
      }
    }
  }

  _redirectAfterLogin() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from
    ) {
      this.props.history.push(this.props.location.state.from)
    } else {
      this.props.history.push('/dashboard')
    }
  }

  handleSubmit = async values => {
    const result = await this.props.login(values)
    if (result.error) {
      console.log(
        'There was an error logging in. Please check your email / password and try again.'
      )
    } else {
      this._redirectAfterLogin()
    }
  }

  render() {
    const { token, currentEmployment } = this.props

    if (token && currentEmployment) {
      return <Redirect to="/dashboard/" />
    } else {
      window.location.href = `${process.env.REACT_APP_LOGIN_DOMAIN}/auth/login`
    }

    return (
      <Screen>
        <Header hideSearch />
        <Logo src={RouteLogoFull} />
        <Text>Redirecting...</Text>
      </Screen>
    )
  }
}

const mapStateToProps = state => ({
  token: state.user.token,
  error: state.user.error,
  currentEmployment: state.user.currentEmployment
})

export default connect(mapStateToProps, api)(withRouter(Login))
