import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'reactstrap'

import {
  GooglePlacesField,
  InputField,
  TimezoneField,
  CropAndUploadField
} from 'components/fields'
import { required, image } from 'utils/validators'

class CompanyForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          name="name"
          component={InputField}
          label="Company Name"
          placeholder="ie: McDonald's Inc"
          validate={[required]}
          warn={required}
        />
        <Field
          name="address"
          component={GooglePlacesField}
          label="Address"
          placeholder="ie: 123 Sesame St."
          type="text"
          validate={[required]}
          warn={required}
        />
        <Field
          name="timezone"
          component={TimezoneField}
          type="select"
          label="Timezone"
          normalize={value => (value ? value.name : null)}
        />
        <Field
          name="logo"
          component={CropAndUploadField}
          type="file"
          label="Logo"
          multiple={false}
          validate={image}
        />
        <Button
          block
          type="submit"
          color="primary"
          style={{ maxWidth: 200, marginTop: 30, marginBottom: 20 }}
        >
          Update Profile
        </Button>
      </form>
    )
  }
}

export default reduxForm({ form: 'companyEdit' })(CompanyForm)
