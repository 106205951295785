import React from 'react'
import { DropdownList } from 'react-widgets'
import { daysOfWeek, ProposalFrequencies } from 'data'

import Boolean from './knobs/Boolean'
import Label from './knobs/Label'
import Text from './knobs/Text'

import BigCheckbox from 'components/fields/BigCheckbox'

export default ({ element, onChange }) => {
  const { data } = element

  const renderDayBooleans = daysOfWeek.map((day, index) => {
    const dayAbbreviation = day.slice(0, 3)
    return (
      <div
        key={`day-boolean-${index}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Label text={dayAbbreviation} />
        <div>
          <BigCheckbox 
            itemValue={day}
            checked={(element.data.selectedDays && element.data.selectedDays[day]) || false}
            onChange={evt => {
              const val = evt.target.checked
              let newData = {
                ...element.data
              }
              if (!newData.selectedDays) {
                newData.selectedDays = {}
              }
              newData.selectedDays[day] = val
              return onChange(newData)
            }
            }
          />
        </div>
      </div>
    )
  })

  return (
    <div>
      <Text
        placeholder="Element Name"
        field="name"
        data={element.data}
        onChange={onChange}
      />
      <Boolean
        label="Show Heading"
        data={element.data}
        field="showHeading"
        onChange={onChange}
      />
      {element.data && element.data.showHeading && (
        <Text
          placeholder="Service Days"
          field="headingText"
          data={element.data}
          onChange={onChange}
        />
      )}
      <Label text="Frequency" />
      <DropdownList
        data={ProposalFrequencies}
        value={data && data.serviceFrequency}
        onChange={value => {
          let newData = {
            ...element.data
          }
          newData.serviceFrequency = value
          return onChange(newData)
        }}
      />
      {element.data &&
        element.data.serviceFrequency &&
        element.data.serviceFrequency === 'Other' && (
          <div style={{ marginTop: 8 }}>
            <Text
              label="Frequency Description"
              textArea
              field="frequencyDescription"
              data={element.data}
              onChange={onChange}
            />
          </div>
        )}
      {element.data &&
        element.data.serviceFrequency &&
        element.data.serviceFrequency === 'Daily' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: 8
            }}
          >
            {renderDayBooleans}
          </div>
        )}
    </div>
  )
}