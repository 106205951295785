import React from 'react'

export default ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <div>
      <strong>{text}</strong>
    </div>
  )
}
