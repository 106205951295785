import React from 'react'
import { Badge, Card, UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import { jobStatus } from 'utils'

import TinyTeam from 'components/TinyTeam'
import TinyDate from 'components/TinyDate'

const statusColors = {
  default: '#dedede',
  assigned: 'rgb(255,122,138)',
  'in progress': '#1FCBE2',
  scheduled: '#ffbe00',
  completed: '#7ECC2F'
}

const KanbanContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`

const CardColumn = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.dark0};
    /*
  ${props =>
    props.status
      ? `background-color ${statusColors[props.status] ||
          statusColors.default};`
      : null};*/
`

const CardScrollArea = styled.div`
  max-height: calc(100vh - ${props => props.vh ? props.vh : '330px'});
  overflow-y: auto;
`

const ColTitle = styled.div`
  margin-top: 0;
  margin-bottom: 8px;
  color: white;
  text-transform: capitalize;
`

const JobCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 8px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  padding: 8px !important;
  font-size: 0.8em;
  overflow: hidden;
`

const NewArea = styled.div`
  position: absolute;
  right: -20px;
  top: 4px;
  width: 64px;
  background-color: #4a9ade;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  transform: rotate(45deg);
`

const New = () => {
  return (
    <NewArea>
      <span>new</span>
    </NewArea>
  )
}

class KanbanView extends React.Component {
  _getJobsForColumn = (columnName, jobs) => {
    const result = _.filter(jobs, job => {
      return jobStatus(job) === columnName
    })
    const sorted = _.orderBy(result, ['scheduledAt', 'updatedAt'], ['desc', 'desc'])
    return sorted || []
  }

  render() {
    const { data, onOpen, viewHeight } = this.props

    if (!data || !data.length) {
      return null
    }

    const columns = [
      {
        title: 'assigned',
        jobs: [],
        tooltipMessage: 'Jobs scheduled with employees.'
      },
      {
        title: 'in progress',
        jobs: [],
        tooltipMessage: 'Jobs that are in progress.'
      },
      {
        title: 'completed',
        jobs: [],
        tooltipMessage: 'Jobs that have been done.'
      },
      {
        title: 'cancelled',
        jobs: [],
        tooltipMessage: 'Jobs that were cancelled.'
      }
    ]

    const renderedColumns = columns.map((col, index) => {
      const isLast = index === columns.length - 1
      return (
        <div
          key={index}
          style={{
            minWidth: 290,
            maxWidth: 290,
            marginRight: isLast ? 0 : 10,
            marginBottom: 30
          }}
        >
          <CardColumn status={col.title}>
            <ColTitle id={'Tooltip-Col-Title-' + index}>
              {col.title}
              <UncontrolledTooltip
                target={'Tooltip-Col-Title-' + index}
                placement="top"
                delay={1000}
              >
                {col.tooltipMessage}
              </UncontrolledTooltip>
            </ColTitle>
            <CardScrollArea vh={viewHeight}>
              {this._getJobsForColumn(col.title, data).map(j => {
                const team = j.team
                const isNew = moment().diff(j.createdAt, 'days') < 1

                return (
                  <JobCard body key={j._id} onClick={() => onOpen(j)}>
                    {isNew && <New />}
                    <Badge
                      color={j.type === 'Walkthrough' ? 'warning' : 'primary'}
                      style={{
                        width: 100,
                        maxWidth: 100
                      }}
                    >
                      {j.type}
                    </Badge>
                    {!!j.code && <strong>{j.code}</strong>}
                    {j.client && j.client.name}
                    <br />

                    {j.name}
                    {j.scheduledAt && <TinyDate date={j.scheduledAt} />}
                    {j.completedAt && (
                      <div>
                        <strong>
                          Completed: {moment(j.completedAt).format('MM/DD')}
                        </strong>
                      </div>
                    )}
                    <div
                      style={{
                        position: 'absolute',
                        right: 4,
                        bottom: 4,
                        textAlign: 'right'
                      }}
                    >
                      <TinyTeam team={team} />
                    </div>
                  </JobCard>
                )
              })}
            </CardScrollArea>
          </CardColumn>
        </div>
      )
    })

    return (
      <div style={{ position: 'relative' }}>
        <KanbanContainer>{renderedColumns}</KanbanContainer>
      </div>
    )
  }
}

export default KanbanView
