import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { Button, Modal, Card, CardTitle } from 'reactstrap'
import * as actions from 'api'
import styled from 'styled-components'

import { DropdownField } from 'components/fields'
import { ClientItem, LocationItem } from 'components/items'
import ClientModal from '../modals/ClientModal'

import { required } from 'utils/validators'

const StyledButton = styled(Button)`
  position: absolute;
  top: -7px;
  left: 60px;
`

class CloneForm extends Component {
  state = {
    showClientModal: false
  }

  componentDidMount() {
    this.props.getClients()
  }

  _locationsOfClient = clientId => {
    const { clients } = this.props
    const theClient = clients.find(client => client._id === clientId)
    if (theClient) {
      return theClient.locations
    } else {
      return []
    }
  }

  _toggleClientModal = () => {
    const { showClientModal } = this.state
    this.setState({
      showClientModal: !showClientModal
    })
  }

  _saveClient = async values => {
    const res = await this.props.submitClient(values)
    if (res && res.payload && res.payload.data) {
      this.props.change('CloneForm', 'client', res.payload.data._id)
    }
    const clientsRes = await this.props.getClients()
    const newClient =
      clientsRes &&
      clientsRes.payload &&
      clientsRes.payload.data.find(
        client => client._id === res.payload.data._id
      )
    if (newClient && newClient.locations) {
      this.props.change('CloneForm', 'location', newClient.locations[0]._id)
    }
    this._toggleClientModal()
  }

  render() {
    const { open, toggle, header, clients, values, cloningMessage } = this.props
    let clientId = null

    if (values && values.client) {
      if (values.client._id) {
        clientId = values.client._id
      } else {
        clientId = values.client
      }
    }

    const validLocations = this._locationsOfClient(clientId)

    return (
      <Modal isOpen={open} toggle={toggle}>
        <Card body style={{ marginBottom: 0 }}>
          <CardTitle>
            <h4>{header || 'Clone'}</h4>
          </CardTitle>
          <form onSubmit={this.props.handleSubmit}>
            <ClientModal
              isOpen={this.state.showClientModal}
              toggle={this._toggleClientModal}
              submitClient={this._saveClient}
            />
            <>
              <div style={{ position: 'relative' }}>
                <Field
                  name="client"
                  component={DropdownField}
                  data={clients}
                  itemComponent={ClientItem}
                  type="select"
                  label="Client"
                  textField={item => (item ? item.name : null)}
                  valueField="_id"
                  normalize={value => (value ? value._id : null)}
                  validate={[required]}
                  warn={required}
                  filter={'contains'}
                  onChange={newValue => {
                    if (
                      newValue &&
                      newValue.locations &&
                      newValue.locations.length
                    ) {
                      this.props.change(
                        'CloneForm',
                        'location',
                        newValue.locations[0]._id
                      )
                    }
                  }}
                />
                <StyledButton color="link" onClick={this._toggleClientModal}>
                  {' '}
                  create new{' '}
                </StyledButton>
              </div>
              {clientId && (
                <Field
                  name="location"
                  component={DropdownField}
                  itemComponent={LocationItem}
                  data={validLocations}
                  type="select"
                  label="Location"
                  textField={item =>
                    item && item.address ? item.address.fullAddress : null
                  }
                  normalize={value => (value ? value._id : null)}
                  warn={required}
                  validate={required}
                  valueField="_id"
                />
              )}
            </>
            {cloningMessage ? (
              <p>{cloningMessage}</p>
            ) : null}
            <div
              style={{ display: 'flex', flexDirection: 'row', marginTop: 24 }}
            >
              <Button
                block
                type="button"
                color="secondary"
                onClick={toggle}
                style={{ marginRight: 16 }}
              >
                Cancel
              </Button>
              <Button block type="submit" color="primary" style={{ margin: 0 }}>
                Clone
              </Button>
            </div>
          </form>
        </Card>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  values: state.form.CloneForm ? state.form.CloneForm.values : null,
  clients: state.clients.clients
})

export default compose(
  reduxForm({
    form: 'CloneForm'
  }),
  connect(
    mapStateToProps,
    { ...actions, change }
  )
)(CloneForm)
