import React, { Component } from 'react'
import ActionsCard from 'components/ActionsCard'
import { Badge, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
import Attachments from 'components/Attachments'
import CustomInfo from 'components/CustomInfo'
//import Switch from 'react-switch'
import TemplateClientInfo from 'components/TemplateClientInfo'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import { FiTag } from 'react-icons/fi'
import AreaForm from '../../Jobs/components/AreaForm'
import SimpleAreaView from '../../Jobs/components/SimpleAreaView'
import ReorderAreasModal from '../../Jobs/components/ReorderAreasModal'
import _ from 'lodash'
import FullScreenGallery from 'components/FullScreenGallery'

const CompanyMarker = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  top: ${props => -props.size / 2 + 'px'};
  left: ${props => -props.size / 2 + 'px'};
  border-radius: ${props => props.size / 2 + 'px'};
  background-color: ${props => props.theme.colors.clarity};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`

const MapContainer = styled.div`
  margin-top: 16px;
  margin-left: 10px;
  height: 300px;
  width: 50%;
`

class LocationCard extends Component {
  state = {
    sliderValue: null,
    showAddAreaModal: false
  }

  toggleSwitch = async () => {
    const { location, onSubmit } = this.props
    location.geofence.active = !location.geofence.active
    if (location.geofence.active === true) {
      this.setupCircle()
    } else {
      this.removeCircle()
    }
    await onSubmit({ ...location })
    //    window.location.reload()
  }

  submitRange = async event => {
    const { location, onSubmit } = this.props
    location.geofence.radius = event.target.value
    if (this.circle) {
      this.circle.setRadius(location.geofence.radius * 1609.34)
    }
    await onSubmit({ ...location })
    //    window.location.reload()
  }

  // setupCircle = () => {
  //   const { location } = this.props
  //   const map = this.map

  //   this.removeCircle()
  //   this.circle = new this.maps.Circle({
  //     strokeColor: '#FF0000',
  //     strokeOpacity: 0.8,
  //     strokeWeight: 2,
  //     fillColor: '#FF0000',
  //     fillOpacity: 0.3,
  //     map,
  //     center: {
  //       lat: location.address.latitude,
  //       lng: location.address.longitude
  //     },
  //     radius:
  //       location.geofence &&
  //       location.geofence.active &&
  //       location.geofence.radius * 1609.34
  //   })
  // }

  // removeCircle = () => {
  //   if (this.circle) {
  //     this.circle.setMap(null)
  //     delete this.circle
  //   }
  // }

  rangeChange = event => {
    this.setState({
      sliderValue: event.target.value
    })
  }

  _openGallery = url => {
    const { location } = this.props

    let galleryImages = (location.attachments || []).map(image => {
      return {
        original: image.url,
        thumbnail: image.url
      }
    })

    galleryImages = galleryImages.filter(
      image =>
        image.original
          .split('.')
          .pop()
          .toLowerCase() !== 'pdf'
    )

    let areas = location.areas || []
    areas.forEach(area => {
      area.attachments.forEach((image, index) => {
        galleryImages.push({
          original: image.url,
          thumbnail: image.url,
          originalTitle: area.name,
          description: area.name + ' Image ' + (index + 1),
          note: image.note
        })
      })
    })

    const startIndex = _.findIndex(galleryImages, { original: url })

    this.setState({
      showGallery: true,
      galleryImages,
      galleryStartIndex: startIndex > 0 ? startIndex : 0
    })
  }

  _onAreaDeleted = area => {
    if (!area) {
      return
    }

    let { location } = this.props
    let areas = _.pull(this.props.location.areas, area)
    this.props.onSubmit({
      ...location,
      areas
    })
  }

  _onAreaEdited = area => {
    if (!area) {
      return
    }
    let { location } = this.props

    area.squareFootage = 0
    if (
      area &&
      area.dimensions &&
      area.dimensions.set &&
      area.dimensions.set.length
    ) {
      area.dimensions.set.forEach(
        dimension => (area.squareFootage += dimension.width * dimension.depth)
      )
    }
    let areas = [...this.props.location.areas]
    if (area._id) {
      const index = _.findIndex(areas, a => {
        return a._id === area._id
      })
      areas[index] = area
    } else {
      areas.push(area)
    }

    this.props.onSubmit({
      ...location,
      areas
    })

    this.setState({
      showAddAreaModal: false
    })
  }

  _toggleReorderModal = () => {
    this.setState({
      showReorderModal: !this.state.showReorderModal
    })
  }

  _areasReordered = areas => {
    let { location } = this.props

    this.props.onSubmit({
      ...location,
      areas
    })
  }

  _showAddAreaModal = () => {
    this.setState({
      showAddAreaModal: !this.state.showAddAreaModal
    })
  }

  render() {
    const { location, actions } = this.props
    const { showReorderModal } = this.state

    if (!location) {
      return null
    }

    return (
      <>
        <Modal
          isOpen={this.state.showAddAreaModal}
          toggle={this._showAddAreaModal}
          className="big-modal"
          fade={false}
        >
          <ModalHeader toggle={this._showAddAreaModal}>
            Add New Area
          </ModalHeader>
          <ModalBody className="bg-light1">
            <AreaForm
              onImageClicked={this._openGallery}
              onSubmit={this._onAreaEdited}
            />
          </ModalBody>
        </Modal>

        <FullScreenGallery
          isOpen={this.state.showGallery}
          items={this.state.galleryImages}
          startIndex={this.state.galleryStartIndex}
          onClose={() =>
            this.setState({
              showGallery: false
            })
          }
        />


        {showReorderModal && (
          <ReorderAreasModal
            areas={location.areas}
            areasReordered={this._areasReordered}
            toggleReorderModal={this._toggleReorderModal}
          />
        )}
        <ActionsCard actions={actions}>
          <div>
            <h4>
              {location.name && `${location.name} - `}
              {location.address && location.address.fullAddress}
            </h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Badge pill color="primary">
                {location.type}
              </Badge>
            </div>
            <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 12
                }}
              >
                <UncontrolledDropdown>
                  <DropdownToggle
                    block
                    style={{ textAlign: 'left'}}
                    size='sm'
                  >
                    <FiTag /> Labels
                  </DropdownToggle>
                  <DropdownMenu>
                    <LabelEditor
                      data={location.labels}
                      onChange={async data =>
                        this.props.onSubmit({
                          _id: location._id,
                          labels: data
                        })
                      }
                      refreshEntity={() => this.props.refreshLocation(location._id)}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
                {location &&
                  location.labels &&
                  location.labels.length > 0 &&
                  location.labels.map(label => {
                    return (
                      <Label
                        key={label._id}
                        color={label.color}
                        style={{ marginLeft: 8 }}
                      >
                        {label.name}
                      </Label>
                    )
                  })
                }
              </div>

            {/* <div
              style={{
                marginTop: 16,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <h5 style={{ marginRight: 8 }}>Location Geofencing:</h5>
              <Switch
                onColor={'#0B64EB'}
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={() => this.toggleSwitch()}
                id="geofencing-switch"
                checked={location.geofence && location.geofence.active}
              />
              {location.geofence && location.geofence.active && (
                <>
                  <h5 style={{ marginLeft: 32, marginRight: 8 }}>Radius:</h5>
                  <input
                    type="range"
                    className="custom-range"
                    min={0}
                    max={2}
                    step={0.1}
                    id="geofenceRange"
                    style={{ maxWidth: 160, marginRight: 8 }}
                    value={
                      this.state.sliderValue ||
                      (location.geofence && location.geofence.radius) ||
                      0
                    }
                    onChange={e => this.rangeChange(e)}
                    onMouseUp={e => this.submitRange(e)}
                  />
                  <h6>
                    {this.state.sliderValue ||
                      (location.geofence && location.geofence.radius) ||
                      '0'}{' '}
                    mi.
                  </h6>
                </>
              )}
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <MapContainer>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                  }}
                  defaultCenter={{
                    lat: location.address.latitude,
                    lng: location.address.longitude
                  }}
                  defaultZoom={11}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) => {
                    this.map = map
                    this.maps = maps
                    // if (location.geofence.active) {
                    //   this.setupCircle()
                    // }
                  }}
                >
                  <CompanyMarker
                    lat={location.address.latitude}
                    lng={location.address.longitude}
                    size={16}
                  />
                </GoogleMapReact>
              </MapContainer>
              {!!location.contact && (
                <div style={{ marginTop: 16, marginLeft: 24 }}>
                  <TemplateClientInfo
                    title="Primary Contact Information"
                    name={location.contact.name}
                    phone={location.contact.phone}
                    email={location.contact.email}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h5>Areas</h5>
                <button
                  style={{
                    color: '#0B64EB',
                    background: 'none',
                    border: 'none',
                    fontSize: 12,
                    outline: 'none',
                    cursor: 'pointer'
                  }}
                  onClick={this._showAddAreaModal}
                >
                  Add Area
                </button>
                <button
                  style={{
                    color: '#0B64EB',
                    background: 'none',
                    border: 'none',
                    fontSize: 12,
                    outline: 'none',
                    cursor: 'pointer'
                  }}
                  onClick={this._toggleReorderModal}
                >
                  Reorder Areas
                </button>
              </div>
              {location.areas && location.areas.length > 0 && (
                <>
                  <SimpleAreaView
                    onImageClicked={this._openGallery}
                    onAreaDeleted={this._onAreaDeleted}
                    onAreaEdited={this._onAreaEdited}
                    areas={location.areas}
                    showOptions={true}
                  />
                </>
              )}
            </div>
            <CustomInfo
              data={location.customInfo}
              onEdit={data =>
                this.props.onSubmit({ ...location, customInfo: data })
              }
            />
            {location.attachments && (
              <Attachments files={location.attachments} />
            )}
          </div>
        </ActionsCard>
      </>
    )
  }
}

export default LocationCard
