import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DropdownItem } from 'reactstrap'
import { toast } from 'react-toastify'
import { connectConfirmation } from 'components/confirm'

import * as actions from 'api'
import { axiosClient } from 'store'

import networkErrorToast from 'utils/networkErrorToast'

import ClientCard from './ClientCard'
import ClientModal from 'screens/Dashboard/components/modals/ClientModal'
import MaterialSpinner from 'components/MaterialSpinner'
import EntityLists from 'screens/Dashboard/components/EntityLists'

class ClientDetails extends Component {
  state = {
    ready: false,
    client: null
  }

  async componentDidMount() {
    await this.getClient()
  }

  getClient = async () => {
    const clientId = this.props.match.params.clientId
    const client = await axiosClient.get(`/api/clients/${clientId}`)

    if (client.error || !client.data) {
      networkErrorToast(client)
      this.setState({
        ready: true,
        client: null
      })
    } else {
      this.setState({
        client: client.data,
        ready: true
      })
    }
  }

  addLocation = async id => {
    this.props.history.push(`/dashboard/${id}/new-location`)
  }

  _updateClientInfo = async client => {
    await this.props.submitClient(client)
    await this.getClient()
  }

  _activateService = async id => {
    await this.props.activateService(id)
    await this.getClient()
    toast('Service was activated.')
  }

  _deactivateService = id => {
    this.props.confirm(
      'Confirm Deactivation',
      'Are you sure you want to deactivate this service?',
      async () => {
        await this.props.deactivateService(id)
        await this.getClient()
        toast('Service was deactivated.')
      }
    )
  }

  _sendDocuments = async id => {
    let result = await this.props.sendDocuments(id)

    if (result.error) {
      networkErrorToast(result)
    } else {
      toast('Documents have been sent.')
    }
  }

  _closeLocation = locationId => {
    this.props.confirm(
      'Confirm Archive',
      'Are you sure you want to archive this location?',
      () => {
        this.props.closeLocation(locationId)

        if (!this.state.client) {
          return
        }

        this.setState({
          client: {
            ...this.state.client,
            locations: this.state.client.locations.filter(
              location => location._id !== locationId
            )
          }
        })
      }
    )
  }

  cancelClient = async id => {
    await this.props.cancelClient(id)
    this.props.history.push('/dashboard/clients/')
  }

  _toggleClientModal = () => {
    const { showClientModal } = this.state
    this.setState({
      showClientModal: !showClientModal
    })
  }

  _saveClient = async values => {
    const res = await this.props.submitClient(values)
    if (res && res.payload && res.payload.data) {
      this.setState({
        client: res.payload.data
      })
    }
    this._toggleClientModal()
  }

  render() {
    const { ready, client, showClientModal } = this.state
    if (!ready) {
      return <MaterialSpinner wrapped={true} />
    }

    return (
      <div className="container" style={{ marginTop: 20 }}>
        {client && (
          <ClientModal
            isOpen={showClientModal}
            toggle={this._toggleClientModal}
            submitClient={this._saveClient}
            initialValues={client}
            _id={client ? client._id : null}
          />
        )}
        <ClientCard
          actions={
            <>
              <DropdownItem onClick={() => this.addLocation(client._id)}>
                Add Location
              </DropdownItem>

              <DropdownItem onClick={() => this._toggleClientModal()}>
                Edit
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={() =>
                  this.props.confirm(
                    'Confirm Archive',
                    `Are you sure you want to archive ${
                      client && client.name ? client.name : 'this account'
                    }?`,
                    () => this.cancelClient(client._id)
                  )
                }
              >
                Archive
              </DropdownItem>
            </>
          }
          history={this.props.history}
          key={client._id}
          client={client}
          onSubmit={this._updateClientInfo}
          onServiceActivated={this._activateService}
          onServiceDeactivated={this._deactivateService}
          onSendDocuments={this._sendDocuments}
          removeLocation={this._closeLocation}
          refreshClient={this.getClient}
        />
        <EntityLists
          history={this.props.history}
          entity={{
            type: 'client',
            data: client
          }}
          showAddress={true}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: state.user.currentCompany
})

export default connect(
  mapStateToProps,
  actions
)(connectConfirmation(ClientDetails))
