import React from 'react'
import { Input } from 'reactstrap'
import Label from './Label'

export default ({ label, data, field, onChange, placeholder, textArea, ...rest }) => (
  <div style={{ marginBottom: 8 }} {...rest}>
    <Label text={label} />{' '}
    <Input
      type={textArea ? 'textarea' : 'text'}
      style={{height: textArea ? '150px' : null}}
      placeholder={placeholder}
      value={data[field]}
      onChange={event => {
        let newData = {
          ...data
        }
        newData[field] = event.target.value
        onChange(newData)
      }}
    />
  </div>
)
