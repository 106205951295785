import React, { Component } from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  max-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

class BusyButton extends Component {
  render() {
    const { busy, children, small, ...rest } = this.props

    let content = children

    if (busy) {
      content = (
        <SpinnerContainer>
          <div
            className={`spinner-border${small ? ` spinner-border-sm` : ''}`}
          />
        </SpinnerContainer>
      )
    }

    return (
      <Button disabled={busy} {...rest}>
        {content}
      </Button>
    )
  }
}

export default BusyButton
