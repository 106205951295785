import React from 'react'
import { Field, reduxForm, isValid } from 'redux-form'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'

import { required } from 'utils/validators'
import ChipsInput from '../components/ChipsInput'

const arrayNotEmpty = value => {
  if (!value || !value.length) {
    return 'Please add services that your company provides.'
  }
}

class ServicesForm extends React.Component {
  render() {
    const { handleSubmit, valid } = this.props
    return (
      <form
        onSubmit={handleSubmit}
        style={{ textAlign: 'left', width: '100%', maxWidth: 512, padding: 40 }}
      >
        <Field
          type="text"
          name="services"
          bsSize="lg"
          component={ChipsInput}
          placeholder="Services"
          validate={[required, arrayNotEmpty]}
          style={{ marginBottom: 16 }}
          suggestions={[
            'Janitorial Cleaning',
            'Carpet Cleaning',
            'VCT Strip & Wax',
            'Tile & Grout',
            'Concrete Polishing',
            'Marble Floor Polishing'
          ]}
          required
        />

        <div style={{ textAlign: 'center' }}>
          <Button
            color="primary"
            style={{
              textTransform: 'uppercase',
              borderRadius: 32,
              width: 300,
              paddingTop: 16,
              paddingBottom: 16
            }}
            disabled={!valid}
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
    )
  }
}

ServicesForm = reduxForm({
  form: 'SignupServices'
})(ServicesForm)

class ServicesFormHandler extends React.Component {

  componentDidMount() {
    const { amplitudeInstance } = this.props
    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Services Form (Enter Page)')
  }

  onSubmit = values => {
    const { onData, onNext, amplitudeInstance } = this.props
    onData({
      services: values.services
    })
    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Services Form Submitted', {
      'services': values.services
    })
    onNext()
  }

  render() {
    const { currentStep, numSteps, valid } = this.props
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          width: '100%',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column'
        }}
      >
        <p style={{ textTransform: 'uppercase', marginBottom: 0 }}>
          Step <strong>{currentStep}</strong> of <strong>{numSteps}</strong>
        </p>
        <h1 style={{ fontSize: 24, marginBottom: 32 }}>
          What services do you provide?
        </h1>
        <ServicesForm
          onSubmit={this.onSubmit}
          valid={valid}
          initialValues={{
            services: ['Janitorial Cleaning', 'Carpet Cleaning']
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  valid: isValid('SignupServices')(state)
})

export default connect(
  mapStateToProps,
  null
)(ServicesFormHandler)
