import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import reducers from 'reducers'

const key = 'root'
const stateReconciler = autoMergeLevel2
const whitelist = ['user', 'acl', 'alerts', 'sales']
const config = { key, storage, stateReconciler, whitelist }

export default persistReducer(config, reducers)
