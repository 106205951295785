const areaSqFt = (area) => {
  if (!area?.dimensions?.set?.length) {
    return null;
  }

  let result = 0;
  area.dimensions.set.forEach(dim => {
    if (dim.width && dim.depth) {
      result += dim.width * dim.depth;
    }
  });

  if (area.quantity && area.quantity > 1) {
    result *= area.quantity;
  }

  return result;
}

export default areaSqFt;