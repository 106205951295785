import React from 'react'
import Icon from './icons'

const TemplateClientInfo = ({ title, name, phone, email }) => {
  return (
    <>
      <h6>{title}</h6>
      {!!name && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            icon="FaUser"
            color="black"
            style={{ marginLeft: 4, marginRight: 4 }}
          />
          <span>{name}</span>
        </div>
      )}
      {!!phone && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            icon="FaPhoneSquare"
            color="black"
            style={{ marginLeft: 4, marginRight: 4 }}
          />
          <span>{phone}</span>
        </div>
      )}
      {!!email && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            icon="FaEnvelope"
            color="black"
            style={{ marginLeft: 4, marginRight: 4 }}
          />
          <span>{email}</span>
        </div>
      )}
    </>
  )
}
export default TemplateClientInfo
