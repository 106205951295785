import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import {
  DropdownField,
  InputField
} from 'components/fields'
import BigCheckbox from 'components/fields/BigCheckbox'
import { required, email, phone } from 'utils'
import { orderSupplies } from 'api'

import THDProLogo from 'assets/img/thd-pro-logo.png'

const Form = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 1rem;

  @media (min-width: 37.5em) {
    form {
      max-width: 500px;
    }
  }
`

const Picture = styled.img`
  aspect-ratio: ${({ height, width }) => width / height};
  border-radius: 5px;
  opacity: 1;
  position: relative;
`

const CheckContainer = styled.div`
  display: flex;
`

const CheckLabel = styled.p`
  margin-left: 4px;
`

export const LOCATION_DELIVERY_TYPES = {
  Dock: 'Building with a dock',
  Gate: 'Building with a lift gate',
  Other: 'Other'
}

class HDForm extends Component {
  state = {
    isTHDPro: false,
    sameAddress: true
  }

  setIsTHDPro = value => this.setState({ isTHDPro: value})

  setSameAddress = value => this.setState({ sameAddress: value })

  onSubmit = async (event) => {
    event.preventDefault()
    const { isTHDPro, sameAddress } = this.state
    const { values } = this.props

    const order = {
      ...values,
      thd_pro: isTHDPro ? `Yes - ${values.thd_pro}` : 'No',
      shipping_address_one: sameAddress ? values.billing_address_one : values.shipping_address_one,
      shipping_address_two: sameAddress ? values.billing_address_two : values.shipping_address_two,
      shipping_city: sameAddress ? values.billing_city : values.shipping_city,
      shipping_state: sameAddress ? values.billing_state : values.shipping_state,
      shipping_zipcode: sameAddress ? values.billing_zipcode : values.shipping_zipcode,
      delivery: LOCATION_DELIVERY_TYPES[values.delivery]
    }

    await this.props.orderSupplies(order)
    this.props.setSubmitted(true)
  }

  render() {
    const { isTHDPro, sameAddress } = this.state

    return (
      <Form>
        <div style={{ display: 'flex' }}>
          <Picture
            alt={`HD Supply Logo`}
            height={40}
            src={THDProLogo}
            width={105}
          />
          <h1 style={{ margin: '0 0 0.25rem 0' }}>
            Getting Started
          </h1>
        </div>
        <p style={{ margin: '0.25rem 0', fontSize: '1rem' }}>
          Please tell us more about your company.
        </p>

        <form
          onSubmit={this.onSubmit}
        >
          <Field
            component={InputField}
            type='text'
            label='Company Name'
            name='name'
            value=''
            validate={[required]}
          />
          <CheckContainer>
            <BigCheckbox 
              itemValue={'is_thd_pro'}
              checked={isTHDPro}
              onChange={event => this.setIsTHDPro(!isTHDPro)}
            />
            <CheckLabel>
              I have a Home Depot Pro account
            </CheckLabel>
          </CheckContainer>

          {isTHDPro ? (
            <Field
              component={InputField}
              type='text'
              label='Home Depot Pro Username'
              name='thd_pro'
              value=''
            />
          ) : null}

          <Field
            component={InputField}
            type='text'
            label='First Name'
            name='first_name'
            value=''
            validate={required}
          />
          <Field
            component={InputField}
            type='text'
            label='Last Name'
            name='last_name'
            value=''
            validate={required}
          />
          <Field
            component={InputField}
            type='text'
            label='Email'
            name='email'
            value=''
            validate={[required, email]}
          />
          <Field
            component={InputField}
            type='text'
            label='Phone'
            name='phone'
            value=''
            validate={[required, phone]}
          />
          <Field
            component={InputField}
            type='text'
            label='Position'
            name='position'
            value=''
          />
          <Field
            name="billing_address_one"
            component={InputField}
            type="text"
            label="Address 1"
            validate={required}
          />
          <Field
            name="billing_address_two"
            component={InputField}
            type="text"
            label="Address 2"
          />
          <Field
            name="billing_city"
            component={InputField}
            type="text"
            label="City"
            validate={required}
          />
          <Field
            name="billing_state"
            component={InputField}
            type="text"
            label="State"
            validate={required}
          />
          <Field
            name="billing_zipcode"
            component={InputField}
            type="text"
            label="Zipcode"
            validate={required}
          />

          <CheckContainer>
            <BigCheckbox 
              itemValue={'same_address'}
              checked={sameAddress}
              onChange={event => this.setSameAddress(!sameAddress)}
            />
            <CheckLabel>
              Shipping address is the same as billing
            </CheckLabel>
          </CheckContainer>
          {sameAddress ? null : (
            <>
              <Field
                name="shipping_address_one"
                component={InputField}
                type="text"
                label="Address 1"
                validate={required}
              />
              <Field
                name="shipping_address_two"
                component={InputField}
                type="text"
                label="Address 2"
              />
              <Field
                name="shipping_city"
                component={InputField}
                type="text"
                label="City"
                validate={required}
              />
              <Field
                name="shipping_state"
                component={InputField}
                type="text"
                label="State"
                validate={required}
              />
              <Field
                name="shipping_zipcode"
                component={InputField}
                type="text"
                label="Zipcode"
                validate={required}
              />
            </>
          )}

          <Field 
            component={DropdownField}
            label='Location Delivery Type'
            name='delivery'
            data={Object.keys(LOCATION_DELIVERY_TYPES).map(name => ({
              label: LOCATION_DELIVERY_TYPES[name],
              value: name
            }))}
            textField='label'
            value={{ label: LOCATION_DELIVERY_TYPES.Dock, value: 'Dock' }}
          />
          <Field 
            component={InputField}
            name='notes'
            type='textarea'
            label='Notes'
          />

          <Button
            color={'primary'}
            type={'submit'}
            variant={'filled'}
            size={'xs'}
            shape={'square'}
          >
            Submit
          </Button>
        </form>
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  values: state.form.HomeDepotForm && state.form.HomeDepotForm.values
})

export const HomeDepotForm = compose(
  reduxForm({form: 'HomeDepotForm'}),
  connect(
    mapStateToProps,
    { orderSupplies }
  )
)(HDForm)
