import { axiosClientHub } from "./clientHub";
import { store } from "store";


function dataUser(params) {

    const { user } = store.getState()


    return {

        "user_id": user.currentEmployment.auth0_id,
        "event_props": {
            ["Company ID"]: user?.currentCompany?._id || '',
            ...params
        }
    }

}


export const trackEvent = ({ typeEvent, params = {} }) => {

    let data = dataUser(params)
    axiosClientHub.post('/api/amplitude/track-event', {
        ...data,
        "session_id": parseInt(window.localStorage.getItem('SessionID')),
        "event": typeEvent,
    })
}