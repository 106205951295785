import React from 'react'
import numeral from 'numeral'
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import InfoPopover from './InfoPopover'

const SummaryDots = styled.span`
  border-bottom: 1px dotted black;
  flex: 1;
  position: relative;
  bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
`

const SummaryTotal = styled.div`
  display: flex;
  border-top: 1px solid black;
  justify-content: space-between;
  flex-direction: row;
`

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SummaryTable = ({ rows, total }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {(rows || []).map((row, index) => (
        <div key={`summary-row-` + index}>
          <SummaryRow>
            <strong>{row.title}</strong>
            <SummaryDots />
            <span>{row.value}</span>
          </SummaryRow>
          {row.subRow && <div style={{ paddingLeft: 30 }}>{row.subRow}</div>}
        </div>
      ))}
      {total && (
        <SummaryTotal>
          <strong>Total</strong>
          <strong>{total}</strong>
        </SummaryTotal>
      )}
    </div>
  )
}

const EstimateResults = ({ values, result, debug }) => {
  let chartData = result ? result.chartData : null
  let recurring =
    result && result.jobCount && result.jobCount.interval !== 'once'
  const otherHourly = result && result.costs && result.costs.otherHourly
  let renderedOtherHourly
  if (otherHourly && otherHourly.length) {
    renderedOtherHourly = (
      <div>
        {result.costs.flatLaborCost && (
          <SummaryRow>
            {`Labor Cost`}
            <SummaryDots />
            <span>
              {numeral(result.costs.flatLaborCost).format('$0, 0.00')}
            </span>
          </SummaryRow>
        )}
        {otherHourly.map((o, index) => {
          if (!o || !o.rate) {
            return null
          }
          return (
            <SummaryRow key={`summary-row-` + index}>
              {o.name || `Other Hourly Cost ${index + 1}`}
              <SummaryDots />
              <span>{numeral(o.rate).format('$0, 0.00')}</span>
            </SummaryRow>
          )
        })}
      </div>
    )
  } else {
    renderedOtherHourly = null
  }

  return (
    <>
      {debug && (
        <>
          <h4>Form Values</h4>
          <pre>{JSON.stringify(values, null, 2)}</pre>
          <h4>Result</h4>
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </>
      )}
      {result && result.charges && (
        <Card>
          <CardBody>
            <div>
              <div className="float-left">
                <h4 className="text-purple">
                  Charges
                  <InfoPopover section="summaryCharges" />
                </h4>
              </div>
              <div className="float-right">{result.jobCount.interval}</div>
              <div className="clearfix" />
            </div>
            <SummaryTable
              rows={[
                {
                  title: 'Base Rate',
                  value: numeral(result.charges.baseRate).format('$0,0.00')
                },
                {
                  title: 'Supplies Materials & Equipment',
                  value: numeral(result.charges.suppliesAndMaterials).format(
                    '$0,0.00'
                  )
                }
              ]}
              total={numeral(result.charges.total).format('$0,0.00')}
            />
            {result.charges.adjustedOneTimeCostsTotal &&
              result.charges.oneTimePaymentSpread && (
                <>
                  <strong style={{ color: 'red' }}>
                    After adjusting for billable one time costs, the first{' '}
                    {result.charges.oneTimePaymentSpread > 1
                      ? result.charges.oneTimePaymentSpread + ' payments '
                      : 'payment '}{' '}
                    will be{' '}
                    {numeral(result.charges.adjustedOneTimeCostsTotal).format(
                      '$0,0.00'
                    )}
                    .
                  </strong>
                </>
              )}
          </CardBody>
        </Card>
      )}
      {result && result.costs && (
        <Card>
          <CardBody>
            <div>
              <div className="float-left">
                <h4 className="text-danger">
                  Costs
                  <InfoPopover section="summaryCosts" />
                </h4>
              </div>
              <div className="float-right">{result.jobCount.interval}</div>
              <div className="clearfix" />
            </div>
            <SummaryTable
              rows={[
                {
                  title: 'Total Labor Costs',
                  value: numeral(result.costs.labor).format('$0,0.00'),
                  subRow: renderedOtherHourly
                },
                {
                  title: 'Total Hours',
                  value: numeral(result.costs.hours).format('0,0.00')
                },
                {
                  title: recurring ? 'Recurring Costs' : 'Other Costs',
                  value: numeral(
                    recurring ? result.costs.recurring : result.costs.oneTime
                  ).format('$0,0.00')
                }
              ]}
              total={numeral(result.costs.total).format('$0,0.00')}
            />
            {recurring && (
              <SummaryRow style={{ marginTop: 40 }}>
                <strong>One Time Costs</strong>
                <SummaryDots />
                <span>{numeral(result.costs.oneTime).format('$0,0.00')}</span>
              </SummaryRow>
            )}
          </CardBody>
        </Card>
      )}

      {result && result.profits && (
        <Card>
          <CardBody>
            <div>
              <div className="float-left">
                <h4 className="text-success">
                  Profits
                  <InfoPopover section="summaryProfits" />
                </h4>
              </div>
              <div className="float-right">{result.jobCount.interval}</div>
              <div className="clearfix" />
            </div>

            <SummaryTable
              rows={[
                {
                  title: 'Overall',
                  value: numeral(result.profits.total).format('$0,0.00')
                },
                {
                  title: 'Net Profit Margin',
                  value: numeral(
                    result.profits.total / result.charges.total
                  ).format('%0,0.00')
                }
              ]}
            />
          </CardBody>
        </Card>
      )}
      {chartData && chartData.length > 0 && (
        <Card>
          <CardBody>
            <h4>
              One Year Billings
              <InfoPopover section="oneYearBillings" />
            </h4>
            <ResponsiveContainer width={'100%'} height={300}>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Bar
                  type="monotone"
                  dataKey="invoice"
                  fill="#8884d8"
                  isAnimationActive={false}
                />
                {result.costs && (
                  <Bar
                    type="monotone"
                    dataKey="cost"
                    fill="#d03223"
                    isAnimationActive={false}
                  />
                )}
                {result.profits && (
                  <Bar
                    type="monotone"
                    dataKey="profit"
                    fill="#00b423"
                    isAnimationActive={false}
                  />
                )}
                <Tooltip
                  cursor={{ fill: 'rgb(211, 211, 211,0.5)' }}
                  formatter={(value, name, props) =>
                    numeral(value).format('$0,0.00')
                  }
                />
              </BarChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      )}
      {chartData && chartData.length > 0 && (
        <Card>
          <CardBody>
            <h4>
              One Year Projection
              <InfoPopover section="oneYearProjection" />
            </h4>
            <ResponsiveContainer width={'100%'} height={300}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Line
                  type="monotone"
                  dataKey="invoiceSum"
                  stroke="#8884d8"
                  strokeWidth={2}
                  isAnimationActive={false}
                />
                {result.costs && (
                  <Line
                    type="monotone"
                    dataKey="costSum"
                    stroke="#d03223"
                    strokeWidth={2}
                    isAnimationActive={false}
                  />
                )}
                {result.profits && (
                  <Line
                    type="monotone"
                    dataKey="profitSum"
                    stroke="#00b423"
                    strokeWidth={2}
                    isAnimationActive={false}
                  />
                )}
                <Tooltip
                  formatter={(value, name, props) =>
                    numeral(value).format('$0,0.00')
                  }
                />
              </LineChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default EstimateResults
