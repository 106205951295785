import React from 'react'
import styled from 'styled-components'

const ItemContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const TimezoneItem = ({ item }) => (
  <ItemContainer>
    <span>{item.name}:</span>
    <span>
      <strong>{item.zone}</strong>
    </span>
  </ItemContainer>
)

export default TimezoneItem
