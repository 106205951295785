import React, { useEffect, useState } from 'react'
import MarkdownEditor from 'components/MarkdownEditor'
import Text from './knobs/Text'
import { Button } from 'reactstrap'
import styled from 'styled-components'


const AlertMessage = styled.span`
  font-weight : 400;
  color : red;
`

export default ({ element, onChange, walkthroughNotes }) => {
  if (!element || !element.data) {
    return null
  }

  const [imagePaste, setImagePaste] = useState(false);

  useEffect(() => {

    setTimeout(() => {
      if (imagePaste) setImagePaste(false)
    }, 3000);
  }, [imagePaste]);

  return (
    <div>
      <Text
        placeholder="Element Name"
        field="name"
        data={element.data}
        onChange={onChange}
      />
      {walkthroughNotes &&
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 4 }}
        >
          <Button
            size="sm"
            onClick={() => {
              let newData = {
                ...element.data
              }
              newData.markdown = walkthroughNotes
              onChange(newData)
            }}
          >
            Use Walkthrough Notes
          </Button>
        </div>
      }
      <MarkdownEditor
        editorId={`proposal-element-markdown-editor`}
        height={'auto'}
        value={element.data.markdown}
        onChange={data => {
          let newData = {
            ...element.data
          }
          newData.markdown = data
          onChange(newData)
        }}
        handlerPaste={setImagePaste}

      />
      <div style={{ minHeight: '50px' }}>
        {
          imagePaste && <AlertMessage>Please use the Image element if you want to add it.</AlertMessage>
        }
      </div>
    </div>
  )
}
