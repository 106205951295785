import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

class CustomInfoMiniView extends React.Component {
  static propTypes = {
    data: PropTypes.object
  }

  render() {
    const { data, dark, style, ...rest } = this.props

    return (
      <div {...rest} style={{ ...style, maxWidth: 300 }}>
        {data && data.data && data.data.length > 0 && (
          <Table dark={dark} striped size="sm" style={{ fontSize: 10 }}>
            <tbody>
              {data.data.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: 100 }}>
                    <span>{item.name}</span>
                  </td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    )
  }
}

export default CustomInfoMiniView
