import styled from 'styled-components'

export const Select = styled.select`
  background-color: rgba(243, 245, 247, 1);
  border: none;
  border-bottom: 0.3rem solid transparent;
  border-radius: 0.2rem;
  color: #0a0a0a;
  cursor: pointer;
  display: block;
  font-size: 1.25rem;
  margin: 1rem auto 0 auto;
  outline: none;
  padding: 1rem;
  position: relative;
  transition: all ease-in-out 0.3s;
  width: 100%;
`
