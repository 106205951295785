export const getShift = shiftId => dispatch =>
  dispatch({
    type: 'FETCH_SHIFT',
    payload: {
      request: {
        url: `/api/shifts/${shiftId}`
      }
    }
  })

export const clockOut = employeeId => async dispatch => {
  return dispatch({
    type: 'CLOCK_OUT',
    payload: {
      request: {
        url: `/api/shifts/end`,
        method: 'post',
        data: {
          employeeId
        }
      }
    }
  })
}
