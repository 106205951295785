import _ from 'lodash'
import { handlerErrors } from 'utils'

const inititalState = {
  areas: [],
  documents: [],
  locations: [],
  services: [],
  loading: false,
  error: null
}

const replaceTemplate = function(collection, template) {
  let newList = collection.slice(0)
  let index = _.findIndex(newList, {
    _id: template._id
  })
  if (index > -1) {
    newList[index] = template
  } else {
    newList.push(template)
  }
  return newList
}

const library = (state = inititalState, action) => {
  switch (action.type) {
    case 'GET_TEMPLATES_SUCCESS': {
      let areas
      let documents
      let locations
      let services

      if (action.payload && action.payload.data) {
        areas = action.payload.data.areas
          ? action.payload.data.areas
          : state.areas
        documents = action.payload.data.documents
          ? action.payload.data.documents
          : state.documents
        locations = action.payload.data.locations
          ? action.payload.data.locations
          : state.locations
        services = action.payload.data.services
          ? action.payload.data.services
          : state.services
      }

      return {
        ...state,
        areas,
        documents,
        locations,
        services,
        loading: false,
        error: null
      }
    }
    case 'GET_AREA_TEMPLATES_SUCCESS': {
      return {
        ...state,
        areas: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'GET_AREA_TEMPLATES_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Getting Area Templates failed.'
        )
      }
    }
    case 'CREATE_AREA_TEMPLATE_SUCCESS': {
      return {
        ...state,
        areas: state.areas.concat(action.payload.data)
      }
    }
    case 'CREATE_AREA_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Creating Area Template failed.'
        )
      }
    }
    case 'UPDATE_AREA_TEMPLATE_SUCCESS': {
      return {
        ...state,
        areas: replaceTemplate(state.areas, action.payload.data)
      }
    }
    case 'UPDATE_AREA_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Updating Area Template failed.'
        )
      }
    }
    case 'REMOVE_AREA_TEMPLATE_SUCCESS': {
      return {
        ...state,
        areas: state.areas.filter(
          template => template._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    }
    case 'REMOVE_AREA_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Removing Area Template failed.'
        )
      }
    }
    case 'GET_DOCUMENT_TEMPLATES_SUCCESS': {
      return {
        ...state,
        documents: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'GET_DOCUMENT_TEMPLATES_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Getting Document Templates failed.'
        )
      }
    }
    case 'CREATE_DOCUMENT_TEMPLATE_SUCCESS': {
      return {
        ...state,
        documents: state.documents.concat(action.payload.data)
      }
    }
    case 'CREATE_DOCUMENT_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Creating Document Template failed.'
        )
      }
    }
    case 'UPDATE_DOCUMENT_TEMPLATE_SUCCESS': {
      return {
        ...state,
        documents: replaceTemplate(state.documents, action.payload.data)
      }
    }
    case 'UPDATE_DOCUMENT_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Updating Document Template failed.'
        )
      }
    }
    case 'REMOVE_DOCUMENT_TEMPLATE_SUCCESS': {
      return {
        ...state,
        documents: state.documents.filter(
          template => template._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    }
    case 'REMOVE_DOCUMENT_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Removing Document Template failed.'
        )
      }
    }
    case 'GET_LOCATION_TEMPLATES_SUCCESS': {
      return {
        ...state,
        locations: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'GET_LOCATION_TEMPLATES_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Getting Location Templates failed.'
        )
      }
    }
    case 'CREATE_LOCATION_TEMPLATE_SUCCESS': {
      return {
        ...state,
        locations: state.locations.concat(action.payload.data)
      }
    }
    case 'CREATE_LOCATION_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Creating Location Template failed.'
        )
      }
    }
    case 'UPDATE_LOCATION_TEMPLATE_SUCCESS': {
      return {
        ...state,
        locations: replaceTemplate(state.locations, action.payload.data)
      }
    }
    case 'UPDATE_LOCATION_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Updating Location Template failed.'
        )
      }
    }
    case 'REMOVE_LOCATION_TEMPLATE_SUCCESS': {
      return {
        ...state,
        locations: state.locations.filter(
          template => template._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    }
    case 'REMOVE_LOCATION_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Removing Location Template failed.'
        )
      }
    }
    case 'GET_SERVICE_TEMPLATES_SUCCESS': {
      return {
        ...state,
        services: action.payload.data,
        loading: false,
        error: null
      }
    }
    case 'GET_SERVICE_TEMPLATES_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Getting Service Templates failed.'
        )
      }
    }
    case 'CREATE_SERVICE_TEMPLATE_SUCCESS': {
      return {
        ...state,
        services: state.services.concat(action.payload.data)
      }
    }
    case 'CREATE_SERVICE_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Creating Service Template failed.'
        )
      }
    }
    case 'UPDATE_SERVICE_TEMPLATE_SUCCESS': {
      return {
        ...state,
        services: replaceTemplate(state.services, action.payload.data)
      }
    }
    case 'UPDATE_SERVICE_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Updating Service Template failed.'
        )
      }
    }
    case 'REMOVE_SERVICE_TEMPLATE_SUCCESS': {
      return {
        ...state,
        services: state.services.filter(
          template => template._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    }
    case 'REMOVE_SERVICE_TEMPLATE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(
          action.error.message,
          'Removing Service Template failed.'
        )
      }
    }
    case 'FETCH_STRIPE_PLANS_SUCCESS': {
      return {
        ...state,
        stripePlans: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}

export default library
