import React, { useEffect, useState } from 'react'
import { Col, Row, Card, CardBody, CardSubtitle, CardText, Input, Button } from 'reactstrap'
import { TableDnD } from './TableDnD'
import { FaCircleArrowLeft } from "react-icons/fa6";

import { buttonStatesValues } from './utilsStatesValues';
import './btnStyles.css'

import styled from 'styled-components'
import { proposalData } from 'screens/Dashboard/components/forms/FilterForm/filterData';
import { toast } from 'react-toastify';

const MainContainer = styled.div`
    display : flex;
    flex-direction : column;
    row-gap : 2rem;
`

const HeadersContainer = styled.div`
    display : flex;
    flex-direction : column;
    row-gap : 1rem;
`

const TableHeader = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : baseline;

`

const ButtonsContainer = styled.div`
    display : flex;
    
`


export const JobDetailedView2 = ({ job, updateInspection }) => {

    const buttonComponents = {

        EDIT: () => <Button color='link' onClick={handleEdit}>Edit</Button>,
        ADD: () => <Button color='link' onClick={handleAddArea}>+ Add Area</Button>,
        MAIN: () => <Button color='link' onClick={handleMain}>Save Changes</Button>,

    }

    const [inspectionAreas, setInspectionAreas] = useState([])

    const [buttonState, setButtonState] = useState('EDIT')

    const [addNewArea, setAddNewArea] = useState(false)

    const [inputNotes, setInputNotes] = useState("")

    const [isDisable, setIsDisable] = useState(true)

    const [compleUpdate, setCompleUpdate] = useState(false)



    useEffect(() => {


        let areasInspection = buildAreaInspection(job)

        setInspectionAreas(areasInspection)
        if (job?.notes) setInputNotes(job.notes)
    }, [])

    useEffect(() => {

        if (compleUpdate) {

            setCompleUpdate(false)
            let newAreasInspection = buildAreaInspection(job)
            setInspectionAreas(newAreasInspection)
        }
    }, [compleUpdate])


    const buildAreaInspection = (job) => {

        const auxInspectionAreas = []
        const { proposalAreas } = job

        for (const area of proposalAreas) {

            const { type, detailAreas } = area

            for (const detailArea of detailAreas) {
                auxInspectionAreas.push({ ...detailArea, type })
            }
        }

        return auxInspectionAreas

    }

    const handleEdit = () => {

        setButtonState(buttonStatesValues.ADD)
        setIsDisable(false)

    }

    const handleMain = async () => {

        const { proposalAreas, _id } = job
        let newProposalAreas = []
        let existAreas = proposalAreas.map(proposalArea => proposalArea.type)

        let areasAdded = inspectionAreas.filter(inspectionArea => !existAreas.includes(inspectionArea.type))

        for (const proposalArea of proposalAreas) {

            const { type, detailArea } = proposalArea

            let auxProposalArea = structuredClone(proposalArea)
            auxProposalArea.detailAreas = []

            let areaDetailsType = inspectionAreas.filter(inspectionArea => inspectionArea.type === type)


            areaDetailsType.forEach(newAreaDetail => {
                auxProposalArea.detailAreas.push({ ...newAreaDetail, message: true })

            })

            newProposalAreas.push(auxProposalArea)

        }

        if (areasAdded.length > 0) {

            for (const areaAdded of areasAdded) {

                const { type, ...rest } = areaAdded

                let indexFind = 0

                let findRoomType = newProposalAreas.find((proposalArea, index) => {

                    if (proposalArea.type === type) {

                        indexFind = index
                        return proposalArea
                    }

                })

                if (!findRoomType) {

                    let areasAddedStructure = structuredClone(proposalAreas[0])
                    areasAddedStructure.detailAreas = []
                    areasAddedStructure.type = type
                    areasAddedStructure.detailAreas.push(rest)
                    newProposalAreas.push(areasAddedStructure)

                } else {
                    newProposalAreas[indexFind].detailAreas.push(rest)
                }

            }

        }



        //objeto a enviar al servicio para actualizar
        let updatedJob = { _id, proposalAreas: newProposalAreas, notes: inputNotes }
        try {
            await updateInspection(updatedJob)
            toast.success('Inspection updated')
            setCompleUpdate(true)
            setIsDisable(true)
            setButtonState(buttonStatesValues.EDIT)
        } catch (error) {
            toast.error('Error updating inspection')
        }
    }

    const handleAddArea = () => {
        setAddNewArea(true)
    }

    return (


        <Col>
            <MainContainer>

                <HeadersContainer>

                    <h5>Inspector</h5>
                    <CardSubtitle className="text-muted" tag="h6">
                        {job.team[0].name.first}
                    </CardSubtitle>

                </HeadersContainer>

                <HeadersContainer>
                    <CardText tag="h5">Notes</CardText>
                    <Input
                        id="notesInspection"
                        name="notes"
                        placeholder="No notes"
                        type="text"
                        value={inputNotes}
                        onChange={(e) => setInputNotes(e.target.value)}
                        disabled={isDisable}
                    />

                </HeadersContainer>


                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                    <TableHeader>
                        <h5>Areas</h5>
                        <ButtonsContainer>
                            {buttonState === buttonStatesValues.ADD && buttonComponents[buttonStatesValues.MAIN]()}
                            {buttonComponents[buttonState]()}
                        </ButtonsContainer>
                    </TableHeader>
                    <Card color='dark' outline>
                        <CardBody>

                            {inspectionAreas.length > 0 &&
                                <TableDnD
                                    tableMode={buttonState}
                                    inspectionAreas={inspectionAreas}
                                    setInspectionAreas={setInspectionAreas}
                                    addNewArea={addNewArea}
                                    setAddNewArea={setAddNewArea} />}

                        </CardBody>
                    </Card>
                </div>
            </MainContainer>
        </Col>


    )
}
