import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Card, Button, FormGroup } from 'reactstrap'

import {
  DropdownField,
  GooglePlacesField,
  InputField,
  CropAndUploadField
} from 'components/fields'
import { LocationItem } from 'components/items'
import { LocationTypes } from 'data'
import { required, image, email } from 'utils/validators'
import { normalizePhone } from 'utils/normalizers'

class ClientForm extends Component {
  render() {
    return (
      <Card body>
        <form onSubmit={this.props.handleSubmit}>
          <FormGroup>
            <Field
              name={'name'}
              component={InputField}
              type="text"
              label="Name"
              validate={required}
              warn={required}
              props={{
                autoFocus: true
              }}
            />
          </FormGroup>
          <Field
            name="address"
            component={GooglePlacesField}
            type="text"
            label="Client Address"
            validate={[required]}
            warn={required}
          />
          {!this.props._id && (
            <Field
              name="locationType"
              component={DropdownField}
              data={LocationTypes}
              itemComponent={LocationItem}
              type="select"
              label="Location Type"
              textField="name"
              normalize={value => {
                if (!value) {
                  return null
                }
                return value.name
              }}
              validate={[required]}
              warn={required}
            />
          )}

          <Field
            name="nameLocation"
            component={InputField}
            type="text"
            label="Location Name"
            validate={required}
            warn={required}
          />

          <Field
            name="contact.name"
            component={InputField}
            type="text"
            label="Contact Name"
          />
          <Field
            name="contact.phone"
            component={InputField}
            type="text"
            label="Contact Phone"
            normalize={normalizePhone}
          />
          <Field
            name="contact.email"
            component={InputField}
            type="text"
            label="Contact Email"
            validate={email}
          />
          <Field
            name="logo"
            component={CropAndUploadField}
            type="file"
            label="Logo"
            multiple={false}
            validate={image}
          />
          <Button
            onClick={this.props.closeModal}
            type="submit"
            size="sm"
            color="danger"
            style={{ marginRight: 4 }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.props.handleSubmit}
            type="submit"
            size="sm"
            color="primary"
          >
            Finish
          </Button>
        </form>
      </Card>
    )
  }
}

export default reduxForm({ form: 'Client' })(ClientForm)
