import React from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100% - 40px);
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-top: 4px;
  font-size: 40px;
`


export const MissedOpportunities = ({ stat }) => {
  return (
    <StatsCard title={'Missed Opportunities'}>
        <StatsContainer>
          <Metric>{stat}</Metric>
        </StatsContainer>
    </StatsCard>
  )
}
