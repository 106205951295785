import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Row } from 'reactstrap'
import _ from 'lodash'

import { axiosClient } from 'store'

import FilePreview from '../FilePreview'

const StyledDropzone = styled.div`
  align-items: center;
  border: 2px dotted rgba(200, 200, 200, 0.5);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5%;
  text-align: center;
  width: 100%;

  ${props => props.isDragActive && StyledActive};
  ${props => props.isDragAccept && StyledAccept};
  ${props => props.isDragReject && StyledReject};
`

const StyledActive = css`
  background: rgba(230, 230, 230, 0.2);
  border: 2px solid rgba(2, 205, 194, 1);
`

const StyledAccept = css`
  background: rgba(230, 230, 230, 0.2);
  border: 2px solid #45eba5;
`

const StyledReject = css`
  background: rgba(230, 230, 230, 0.2);
  border: 2px solid #dc3545;
`

const FilesMessage = styled.p`
  font-size: 0.6rem;
`

const Progress = props => {
  return (
    <div
      style={{
        background: 'rgba(2, 205, 194, 1)',
        border: '3px solid rgba(2, 205, 194, 1)',
        borderRadius: '5px',
        width: '100%'
      }}
    >
      <p style={{ display: 'none', color: 'white', textAlign: 'center' }}>
        {props.progress}%
      </p>
    </div>
  )
}

class UploadField extends PureComponent {
  state = {
    progress: 0,
    loading: false
  }

  static propTypes = {
    onChange: PropTypes.func,
    onDrop: PropTypes.func,
    validate: PropTypes.func,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    value: PropTypes.array
  }

  static defaultProps = {
    multiple: true,
    name: 'Upload Files',
    type: 'file'
  }

  upload = async files =>
    await axiosClient.post('/api/upload', files, {
      timeout: 30000,
      onUploadProgress: progressEvent => this.onProgress(progressEvent)
    })

  onProgress = progressEvent => {
    const content = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')

    if (content !== null) {
      this.setState({
        loading: true,
        progress: Math.round((progressEvent.loaded * 100) / content)
      })
    }
  }

  renderWarning = warning =>
    warning && <span className="small text-warning">{warning}</span>

  renderUploadErrors = errors => (
    <ul>
      {errors.map((error, i) => (
        <li key={i} className="small text-danger">
          Failed: {`${error || error.name}`}
        </li>
      ))}
    </ul>
  )

  renderStringErrors = error => (
    <span className="small text-danger">{error}</span>
  )

  renderError = error =>
    error && Array.isArray(error)
      ? this.renderUploadErrors(error)
      : this.renderStringErrors(error)

  onDrop = async (acceptedFiles, rejectedFiles) => {
    let accepted = new FormData()

    for (let file in acceptedFiles) {
      accepted.append('files', acceptedFiles[file])
    }

    try {
      let files = this.props.input.value
      let uploaded = await this.upload(accepted)

      if (files && files.length) {
        files = files.concat(uploaded.data)
      } else {
        files = uploaded.data
      }

      this.setState({ loading: false, progress: 0 })
      return this.props.input.onChange(files)
    } catch (error) {
      console.error(error)
      this.setState({ loading: false, progress: 0 })
      return rejectedFiles && this.props.input.onChange(rejectedFiles)
    }
  }

  onLocalDrop = async (acceptedFiles, rejectedFiles) => {
    try {
      let files = this.props.input.value

      if (files && files.length) {
        files = files.concat(acceptedFiles)
      } else {
        files = acceptedFiles
      }

      return this.props.input.onChange(files)
    } catch (error) {
      console.error(error)
      return rejectedFiles && this.props.input.onChange(rejectedFiles)
    }
  }

  removeFile = file => {
    let files = _.without(this.props.input.value, file)
    this.props.input.onChange(files)
    this.setState({
      files
    })
  }

  render() {
    let { input, label, name, meta, persistLocally } = this.props
    const value = input.value
    let progressBar
    if (this.state.loading) {
      progressBar = <Progress progress={this.state.progress} />
    }

    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Dropzone
          accept="image/jpg, image/png, image/jpeg, application/pdf"
          name={name}
          onDrop={persistLocally ? this.onLocalDrop : this.onDrop}
          style={{}}
          value={input.value}
        >
          {({
            isDragActive,
            isDragAccept,
            isDragReject,
            acceptedFiles,
            rejectedFiles
          }) => (
            <StyledDropzone
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              acceptedFiles={acceptedFiles}
              rejectedFiles={rejectedFiles}
              style={
                this.props?.padding ? { padding: `${this.props.padding}%` } : {}
              }
            >
              <div className="small text-secondary">
                Drop files here or click to upload. Supports images and
                documents.
                <FilesMessage>
                  (Accepted files types: jpeg, png, pdf)
                </FilesMessage>
              </div>
            </StyledDropzone>
          )}
        </Dropzone>
        {progressBar}
        {value && value.length > 0 && (
          <Row style={{ marginLeft: 0 }}>
            {value.map((file, index) => (
              <FilePreview
                key={'file-' + index}
                file={file}
                onDeleteClicked={() => this.removeFile(file)}
              />
            ))}
          </Row>
        )}
        {this.renderError(meta.error) || this.renderWarning(meta.warning)}
      </div>
    )
  }
}

export default UploadField
