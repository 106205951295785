import React, { Component } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker, CompactPicker } from 'react-color'

const white = {
  r: 255,
  g: 255,
  b: 255,
  a: 1
}

class ColorPickerField extends Component {
  state = {
    displayColorPicker: false
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange = color => {
    const colorValue = this.props.useHex ? color.hex : color.rgb

    return (
      (this.props.input && this.props.input.onChange(colorValue)) ||
      this.props.onChange(colorValue)
    )
  }

  render() {
    let existingValue = white
    if (this.props.input && this.props.input.value) {
      existingValue = this.props.input.value
    } else if (this.props.value) {
      existingValue = this.props.value
    }

    // HACK: When the existing value is just a string, prevent a crash by resetting it.
    if (!existingValue) {
      existingValue = white
    }

    let colorString =
      typeof existingValue === 'string'
        ? existingValue
        : `rgba(${existingValue.r}, ${existingValue.g}, ${existingValue.b}, ${existingValue.a})`

    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: colorString,
          border: '1px solid #9B9B9B'
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    })

    return (
      <>
        <label>{this.props.label}</label>
        <div>
          <div style={styles.swatch} onClick={this.handleClick}>
            <div style={styles.color} />
          </div>
          {this.state.displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={this.handleClose} />
              {this.props.compact ? (
                <CompactPicker
                  color={existingValue}
                  onChange={this.handleChange || null}
                />
              ) : (
                <SketchPicker
                  color={existingValue}
                  onChange={this.handleChange || null}
                />
              )}
            </div>
          ) : null}
        </div>
      </>
    )
  }
}

export default ColorPickerField
