import React from 'react'

import Alignment from './knobs/Alignment'
import Color from './knobs/Color'
import Text from './knobs/Text'
import Label from './knobs/Label'
import { DropdownList } from 'react-widgets'

const headingSizes = [
  {
    label: 'Heading 1',
    size: 'h1'
  },
  {
    label: 'Heading 2',
    size: 'h2'
  },
  {
    label: 'Heading 3',
    size: 'h3'
  },
]


export default ({ element, onChange }) => (
  <div>
    <Text
      placeholder="Element Name"
      field="name"
      data={element.data}
      onChange={onChange}
    />
    <Label
      text="Size"
    />
    <DropdownList
      data={headingSizes}
      value={element.data && element.data.type}
      textField='label'
      onChange={value => {
        let newData = {
          ...element.data,
          type: value
        }
        return onChange(newData)
      }}
      style={{marginBottom: 8}}
    />
    <Alignment
      label="Text Alignment"
      field="alignment"
      data={element.data}
      onChange={onChange}
    />
    <Text
      label="Heading Text"
      field="text"
      data={element.data}
      onChange={onChange}
    />
    <Color
      label="Text Color"
      field="color"
      data={element.data}
      onChange={onChange}
    />
  </div>
)
