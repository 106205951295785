import React, { useState } from 'react'
import { Input } from 'reactstrap'
import Label from './Label'

const NumberKnob = ({
  label,
  data,
  field,
  onChange,
  step,
  min,
  max,
  ...rest
}) => {
  const [timer, setTimer] = useState(null)

  const validateNum = (num) => {
    let newData = { ...data }
    if (min && num < min) {
      newData[field] = min
      return onChange(newData)
    } else if (max && num > max) {
      newData[field] = max
      return onChange(newData)
    }
  }

  const timeout = (num) => {
    if (timer) {
      clearTimeout(timer)
    }
    setTimer(setTimeout(() => {
      validateNum(num)
    }, 1000))
  }

  return (
    <div style={{ width: '50%' }}>
      <Label text={label} />
      <Input
        type="number"
        className="number-knob"
        min={min}
        max={max}
        value={data[field]}
        step={step || '1'}
        onChange={async event => {
          let newNum = Number(event.target.value)
          let newData = { ...data }
          newData[field] = newNum
          timeout(newNum)
          return onChange(newData)
        }}
      />
    </div>
  )
}

NumberKnob.defaultProps = {
  showSteppers: true
}

export default NumberKnob
