import React, { useEffect } from 'react'
import styled from 'styled-components'

import Field from '../field'
import { PrimaryButton, Question, Title, Tooltip } from '../ui'
import { InputState } from '../../../../../../hooks'
import { browserWindow } from '../../../../../../utils'

export const RoomPart = styled.div`
  margin-top: 1rem;
  width: 100%;

  fieldset {
    text-align: left;

    input,
    label,
    select {
      display: inline-flex;
      font-size: 1.25rem;
    }

    input {
      margin-right: 0;
      width: 128px;
    }

    label {
      width: calc(100% - 144px);
    }
  }

  @media screen and (min-width: 32rem) {
    width: 80%;
  }
`

export const ResidentialRoomsStage = ({
  current,
  fields,
  id,
  label,
  onClick
}) => {
  useEffect(() => {
    // console.log('scrolling...')
    browserWindow.scrollTo(0, 0)
  }, [])

  const rooms = current || {}
  let disabled = false

  const onNext = event => onClick(event, rooms)

  const inputs = fields.map(({ label, name, required, step, type }, index) => {
    const [value, setValue] = InputState(rooms[name] || 0)
    rooms[name] = value

    const isValid = (value / step) % 1 === 0
    if (!isValid) {
      disabled = true
    }

    return (
      <RoomPart key={name}>
        <Field
          id={name}
          label={label}
          labelStyle={{}}
          name={`${id}-${name}`}
          onChange={setValue}
          required={required}
          step={step}
          style={{}}
          type={type}
          value={value}
        >
          {!isValid && (
            <span style={{ paddingLeft: '1rem' }}>
              Please use a multiple of {step}.
            </span>
          )}
        </Field>
      </RoomPart>
    )
  })

  return (
    <Question>
      <Tooltip>
        Offices count as bedrooms. Bathrooms can be entered in increments of 0.5
        to account for half-baths.
      </Tooltip>
      <Title style={{ marginTop: '3rem' }}>{label}</Title>
      {inputs}

      <PrimaryButton disabled={disabled} onClick={onNext} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
}

export default ResidentialRoomsStage
