export const rejectedClientColHeaders = [
  { name: 'Client Name', width: '200px' },
  { name: 'Address', width: '400px' },
  { name: 'Location Type', width: '250px' },
];

export const rejectedLocationColHeaders = [
  { name: 'Location Name', width: '200px' },
  { name: 'Address', width: '400px' },
  { name: 'Location Type', width: '250px' },
];

export const acceptedClientColHeaders = [
  { name: 'Client Name', width: '200px' },
  { name: 'Address', width: '400px' }
];

export const acceptedLocationColHeaders = [
  { name: 'Location Name', width: '200px' },
  { name: 'Address', width: '400px' },
  { name: 'Client', width: '250px' }
];