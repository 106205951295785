const inititalState = {}

const reducer = (state = inititalState, action) => {
  switch (action.type) {
    case 'CLOSE_ALERT': {
      let newState = { ...state }
      newState[action.payload] = true // Alert is "closed". Easier to evaluate instead of chacking against null/undefined
      return newState
    }

    default: {
      return state
    }
  }
}

export default reducer
