import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor, amplitudeInstance } from 'store'
import { fetchACL } from './api'

class Root extends Component {
  componentDidMount() {
    amplitudeInstance && amplitudeInstance.logEvent('Open Web App')

    // TODO: How to know when Redux Persist is done loading??
    setTimeout(async () => {
      const token = await store.getState().user.token


      // console.log("cargo la data")

      window.localStorage.setItem('SessionID', new Date().getTime())

      if (token) {
        await fetchACL(store.dispatch)

        store.dispatch({
          type: 'USER_PROFILE',
          payload: {
            request: {
              url: '/api/users/me'
            }
          }
        })
      }
    }, 100)
  }

  render() {
    const { children } = this.props

    const root = (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    )

    return root
  }
}

export default Root
