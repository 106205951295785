import styled from 'styled-components'

export const Title = styled.h1`
  display: block;
  font-size: 2rem;
  font-weight: 700;
  margin: 1rem 0;
  text-align: center;

  @media screen and (min-width: 32rem) {
    font-size: 2.5rem;
  }
`
