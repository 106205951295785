import React from 'react'
import styled from 'styled-components'

const TooltipAnchor = styled.a`
  align-items: center;
  background-color: rgba(243, 245, 247, 1);
  border-radius: 100%;
  color: #0a0a0a;
  display: inline-flex;
  height: ${props => props.size ? props.size + 'px' : '40px'};
  justify-content: center;
  margin: ${props => props.margin ? props.margin + 'px' : '1rem 0'} ;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;
  width: ${props => props.size ? props.size + 'px' : '40px'};

  :before {
    color: #0a0a0a;
    font-size: ${props => props.size ? (props.size * 0.7) + 'px' : '1.25rem'};
    content: '?';
  }

  :active,
  :focus,
  :hover {
    background-color: #4183d7;

    :before {
      color: #ffffff;
    }
  }

  :active p,
  :focus p,
  :hover p {
    bottom: 50px;
    visibility: visible;
    opacity: 1;
  }
`

const TooltipText = styled.p`
  background-color: rgba(243, 245, 247, 1);
  bottom: 0;
  color: #0a0a0a;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0;
  opacity: 0;
  padding: 0.75rem;
  position: absolute;
  transition: all 0.4s;
  visibility: hidden;
  width: ${props => props.width ? props.width + 'px' : '320px'};

  :before {
    border-left: 4px solid transparent;
    border-radius: 4px;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(243, 245, 247, 1);
    bottom: -4px;
    content: '';
    left: calc(50% - 4px);
    position: absolute;
  }
`

export const Tooltip = props => {
  return (
    <TooltipAnchor size={props.size} margin={props.margin} href={`#`}>
      <TooltipText width={props.popoverWidth}>{props.children}</TooltipText>
    </TooltipAnchor>
  )
}

export default Tooltip
