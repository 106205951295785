import React from 'react'
import FieldSet from './FieldSet'

import MarkdownEditor from '../MarkdownEditor'

const InputField = ({ input, label, meta, ...rest }) => (
  <FieldSet label={label} meta={meta}>
    <MarkdownEditor {...input} {...rest}/>
  </FieldSet>
)

export default InputField
