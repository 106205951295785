import styled from 'styled-components'
import { Button } from 'reactstrap'

import Icon from './icons'
import LazyImage from './LazyImage'

const FileArea = styled.div`
  display: inline-flex;
  position: relative;
  background-color: #ddd;
  border-radius: 2px;
  height: 64px;
  margin-top: 8px;
  margin-right: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`

const FilePreview = ({ file, onClickImage, onDeleteClicked, ...rest }) => {
  console.log('file preview file', file)
  if (!file || !file.type) {
    return null
  }

  const isImage = file.type.includes('image/')
  const isPDF = file.type.includes('/pdf')

  return (
    <FileArea {...rest}>
      {isImage && (
        <LazyImage file={file} style={{ maxHeight: 48, marginRight: 10, cursor: onClickImage ? 'pointer' : 'default' }} onClick={onClickImage}/>
      )}
      {isPDF && (
        <Icon icon="FaFilePdf" color="black" style={{ marginRight: 10 }} />
      )}
      {!onClickImage ? (
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.name}
        </a>
      ) : null}
      <Button
        size="sm"
        color="link"
        style={{ position: 'absolute', top: -4, right: -4, padding: 0 }}
        onClick={onDeleteClicked}
      >
        <Icon icon="FaTimes" color="red" />
      </Button>
    </FileArea>
  )
}

export default FilePreview
