import { useState } from 'react'

export const InputState = initialState => {
  const [value, setValue] = useState(initialState)

  const onInputChange = event =>
    setValue(
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    )

  const reset = () => setValue(initialState)

  return [value, onInputChange, reset]
}
