export const fullAddress = address => {
  let addressString = ''
  if (address.streetNumber && address.streetNumber.length > 0) {
    addressString += address.streetNumber
  }
  if (address.route && address.route.length > 0) {
    addressString += ' ' + address.route
  }
  if (address.aptSuite && address.aptSuite.length > 0) {
    addressString += ' ' + address.aptSuite
  }
  if (addressString && addressString.length > 0) {
    addressString += ','
  }
  if (address.city && address.city.length > 0) {
    addressString += ' ' + address.city + ','
  }
  if (address.state && address.state.length > 0) {
    addressString += ' ' + address.state
  }
  if (address.zipcode && address.zipcode.length > 0) {
    addressString += ' ' + address.zipcode
  }
  if ((address.state && address.state.length > 0) || (address.zipcode && address.zipcode.length > 0)) {
    addressString += ','
  }
  if (address.country && address.country.length > 0) {
    addressString += ' ' + address.country
  }

  return addressString
}