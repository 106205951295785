const RoomData = {
  amenity: {
    label: 'Amenity'
  },
  bathroom: {
    label: 'Bathroom',
    taskNotes: {
      janitorial: 'Test'
    }
  },

  bar: {
    label: 'Bar'
  },
  basement: {
    label: 'Basement'
  },
  bedroom: {
    label: 'Bedroom'
  },
  commonArea: {
    label: 'Common Area'
  },
  conferenceRoom: {
    label: 'Conference Room'
  },
  diningRoom: {
    label: 'Dining Room'
  },
  elevators: {
    label: 'Elevators'
  },
  examRoom: {
    label: 'Exam Room'
  },
  exterior: {
    label: 'Exterior'
  },
  garage: {
    label: 'Garage'
  },
  gymFloor: {
    label: 'Gym Floor'
  },
  hallway: {
    label: 'Hallway'
  },
  kitchen: {
    label: 'Kitchen'
  },
  lab: {
    label: 'Lab'
  },
  livingRoom: {
    label: 'Living Room'
  },
  lobby: {
    label: 'Lobby'
  },
  mailRoom: {
    label: 'Mail Room'
  },
  office: {
    label: 'Office'
  },
  other: {
    label: 'Other'
  },
  patio: {
    label: 'Patio'
  },
  retailFloor: {
    label: 'Retail Floor'
  },
  showRoom: {
    label: 'Show Room'
  },
  stairwells: {
    label: 'Stairwells'
  },
  supplyCloset: {
    label: 'Supply Closet'
  },
  vestibule: {
    label: 'Vestibule'
  },
  warehouseFloor: {
    label: 'Warehouse Floor'
  }
}

let roomTypes = []
for (let property in RoomData) {
  roomTypes.push(RoomData[property].label)
}

export const RoomTypes = roomTypes
export default RoomData
