import React, { Component } from 'react'
import { connect } from 'react-redux'
import { axiosClient } from 'store'
import { connectConfirmation } from 'components/confirm'
import { closeLocation, updateLocation } from 'api'
import LocationCard from './LocationCard'
import { DropdownItem } from 'reactstrap'
import EntityLists from 'screens/Dashboard/components/EntityLists'

class Location extends Component {
  state = {
    ready: false,
    location: null
  }

  componentDidMount() {
    const id = this.props.match.params.locationId
    if (id) {
      this._load(id)
    }
  }

  _load = async id => {
    const result = await axiosClient.get(`/api/locations/${id}`)
    if (result && result.data) {
      this.setState({
        ready: true,
        location: result.data
      })
    }
  }

  _closeLocation = async locationId => {
    await this.props.closeLocation(locationId)

    this.props.history.goBack()
  }

  _updateLocation = async location => {
    await this.props.updateLocation(location)
    await this._load(location._id)
  }

  render() {
    const { ready, location } = this.state

    if (!ready) {
      return null
    }

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <LocationCard
          location={location}
          onSubmit={this._updateLocation}
          refreshLocation={this._load}
          actions={
            <>
              <DropdownItem
                onClick={() =>
                  this.props.history.push(
                    `/dashboard/locations/${location._id}`
                  )
                }
              >
                Edit
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                size="sm"
                className="text-danger"
                onClick={() =>
                  this.props.confirm(
                    'Confirm Archive',
                    `Are you sure you want to archive ${
                      location && location.name
                        ? location.name
                        : 'this location'
                    }?`,
                    () => this._closeLocation(location._id)
                  )
                }
              >
                Archive
              </DropdownItem>
            </>
          }
        />
        <EntityLists
          history={this.props.history}
          entity={{
            type: 'location',
            data: location
          }}
        />
      </div>
    )
  }
}

export default connect(null, { closeLocation, updateLocation })(
  connectConfirmation(Location)
)
