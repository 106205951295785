import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { InplaceInputField } from 'components/fields'

class InplaceForm extends Component {
  render() {
    const { fieldName, header, style, autoComplete } = this.props
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          name={fieldName || 'name'}
          component={InplaceInputField}
          type="text"
          handleBlur={this.props.handleSubmit}
          header={header}
          autoComplete={autoComplete}
          style={style}
        />
      </form>
    )
  }
}

export default reduxForm({ form: 'Inplace' })(InplaceForm)
