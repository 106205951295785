import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { GoogleApiWrapper } from '../../../../../utils/GoogleApiComponent'
import { ImportBusy, ImportForm, FinalizeImport } from './ImportModalContent'
import { usePapaParse } from 'react-papaparse';
import { useParseAndValidateImport } from './useParseAndValidateImport';
import { connectConfirmation } from 'components/confirm'
import { MdClose } from 'react-icons/md';

const BulkImportModal = ({ importType, isOpen, toggle, google, confirm }) => {
  const [importTypeState, setImportTypeState] = useState(importType)
  const { readString } = usePapaParse()
  const [currentScreen, setCurrentScreen] = useState('Upload')
  const { parsedData, onUploadCSV } = useParseAndValidateImport(
    {
      importType: importTypeState, 
      setBusy: () => setCurrentScreen('Busy'),
      google
    }
  )

  useEffect(() => {
    if (parsedData.length > 0) {
      setCurrentScreen('Finalize')
    }
  }, [parsedData])

  const _switchToLocationMode = () => {
    setImportTypeState('location')
    setCurrentScreen('Upload')
  }

  const completeImport = () => {
    if (importTypeState === 'client') {
      confirm(
        'Upload Locations?',
        'Do any of your clients have multiple locations?',
        _switchToLocationMode,
        'btn-primary',
        () => {
          console.log('No locations')
          toggle()
          setCurrentScreen('Upload')
        }
      )
    } else {
      toggle()
      setCurrentScreen('Upload')
    }
  }

  const SCREENS = {
    'Upload': <ImportForm importType={importTypeState} onUpload={onUploadCSV} />,
    'Busy': <ImportBusy />,
    'Finalize': <FinalizeImport data={parsedData} importType={importTypeState} completeImport={completeImport} />,
  }

  return (
    <Modal 
      isOpen={isOpen} 
      toggle={toggle} 
      backdrop="static" 
      autoFocus={false} 
      style={{
        margin: 'auto',
        marginTop: '10vh',
        maxWidth: 'none',
        width: 'fit-content'
      }}
    >
      <ModalBody>
        {SCREENS[currentScreen]}
      </ModalBody>
      <div style={{position: 'absolute', top: '5px', right: '5px', cursor: 'pointer'}}>
        <MdClose onClick={toggle} size={25} />
      </div>
    </Modal>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places']
})(connectConfirmation(
  BulkImportModal
))
