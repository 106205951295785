import React from 'react'
import { Field, reduxForm, isValid } from 'redux-form'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'

import { required } from 'utils/validators'
import TextInput from '../components/TextInput'
import AddressInput from '../components/AddressInput'

const validateName = value => {
  if (!value) {
    return 'Cannot be empty'
  }

  if (value.length < 2) {
    return 'Not long enough'
  }

  return undefined
}

const validateAddress = value => {
  if (!value) {
    return 'Cannot be empty'
  }

  if (typeof value === 'string') {
    return 'Select a valid Google Place from the dropdown.'
  }

  if (typeof value === 'object' && !value.formattedAddress) {
    return 'Invalid address selected, try again.'
  }

  return undefined // No error.
}

class CompanyForm extends React.Component {
  render() {
    const { handleSubmit, valid } = this.props
    return (
      <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
        <Field
          type="text"
          name="name"
          bsSize="lg"
          component={TextInput}
          placeholder="Company name"
          validate={[required, validateName]}
          style={{ marginBottom: 16 }}
          required
        />
        <Field
          type="text"
          name="address"
          bsSize="lg"
          component={AddressInput}
          placeholder="Office address"
          validate={[required, validateAddress]}
          style={{ marginBottom: 16 }}
          format={value =>
            value && typeof value === 'object'
              ? value.formattedAddress || ''
              : value || ''
          }
          required
        />

        <Button
          color="primary"
          style={{
            textTransform: 'uppercase',
            borderRadius: 32,
            width: 300,
            paddingTop: 16,
            paddingBottom: 16
          }}
          disabled={!valid}
          type="submit"
        >
          Continue
        </Button>
      </form>
    )
  }
}

CompanyForm = reduxForm({
  form: 'SignupCompany'
})(CompanyForm)

class CompanyFormHandler extends React.Component {

  componentDidMount() {
    const { amplitudeInstance } = this.props
    amplitudeInstance && amplitudeInstance.logEvent('Sign Up - Company Info Form (Enter Page)')
  }

  onSubmit = values => {
    const { onData, onNext } = this.props
    onData({
      company: values
    })
    onNext()
  }

  render() {
    const { currentStep, numSteps, valid } = this.props
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          width: '100%',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column'
        }}
      >
        <p style={{ textTransform: 'uppercase', marginBottom: 0 }}>
          Step <strong>{currentStep}</strong> of <strong>{numSteps}</strong>
        </p>
        <h1 style={{ fontSize: 24, marginBottom: 32 }}>
          Let's set up your company.
        </h1>
        <CompanyForm onSubmit={this.onSubmit} valid={valid} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  valid: isValid('SignupCompany')(state)
})

export default connect(
  mapStateToProps,
  null
)(CompanyFormHandler)
