import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { Field } from 'redux-form'
import styled from 'styled-components'
import InputField from './InputField'
import { CloseButton, MiniButton } from 'components/lib/Button'

const ScrollDiv = styled.div`
  ${props =>
    props.maxHeight
      ? 'max-height: ' +
        props.maxHeight +
        '; overflow-y: auto; overflow-x: hidden; padding: 16px; margin-bottom: 8px;'
      : null}
`

const TabularField = ({
  fields,
  columns,
  title,
  maxHeight,
  onChange,

  meta: { error, submitFailed },
  buttonLabel,
  extra
}) => {
  return (
    <Card>
      <CardBody>
        <ScrollDiv maxHeight={maxHeight}>
          {title && <h5>{title}</h5>}
          <table className="table table-sm">
            {fields && fields.length > 0 && (
              <thead>
                <tr>
                  {(columns || []).map((col, index) => (
                    <th key={index}>{col.placeholder}</th>
                  ))}
                  <th />
                </tr>
              </thead>
            )}
            <tbody>
              {fields.map((line, index) => (
                <tr key={index}>
                  {(columns || []).map((col, cIndex) => {
                    return (
                      <td key={cIndex}>
                        <Field
                          //placeholder={col.placeholder}
                          name={`${line}.${col.name}`}
                          type={col.type || 'text'}
                          component={InputField}
                          props={{ bsSize: 'sm' }}
                          {...col.props}
                          fieldStyle={{ marginBottom: 0 }}
                          onChange={onChange}
                        />
                      </td>
                    )
                  })}
                  <td>
                    <CloseButton
                      onClick={() => {
                        fields.remove(index)
                        if (onChange) {
                          onChange()
                        }
                      }}
                      style={{ marginTop: 4 }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ScrollDiv>
        <MiniButton color="primary" onClick={() => fields.push({})}>
          {buttonLabel || 'Add Item'}
        </MiniButton>

        {extra}
      </CardBody>
    </Card>
  )
}

export default TabularField
