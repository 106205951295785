import React from 'react'
import styled from 'styled-components'
import ImageGallery from 'react-image-gallery'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import LazyImage from 'components/LazyImage'

const Container = styled.div``

const NoteView = styled.div`
  background: rgba(0, 0, 0, 0.4);
  bottom: 110px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
`

function _customImageRenderer(item) {
  return (
    <div className="image-gallery-image">
      <LazyImage
        src={item.original}
        alt={item.originalAlt}
        srcSet={item.srcSet}
        sizes={item.sizes}
        position="initial"
      />
      {item.description && (
        <span className="image-gallery-description">
          {item.description}
          {item.link && item.linkLabel && (
            <a className="my-own-class" href={item.link}>
              {item.linkLabel}
            </a>
          )}
        </span>
      )}

      {item.note && (
        <NoteView>
          <strong>Note: </strong>
          {item.note}
        </NoteView>
      )}
    </div>
  )
}

export default ({ isOpen, onClose, ...rest }) => {
  return (
    <Modal isOpen={isOpen} className="big-modal">
      <ModalHeader
        toggle={onClose}
      />
      <ModalBody>
        <Container>
          <ImageGallery
            showIndex={true}
            renderItem={_customImageRenderer}
            {...rest}
          />
        </Container>
      </ModalBody>
    </Modal>
  )
}
