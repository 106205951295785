export const buttonStatesValues = {
    EDIT: 'EDIT',
    ADD: 'ADD',
    MAIN: 'MAIN'
}


export const switchStateValues = {

    0: 'right',
    1: 'left',
    2: 'center'

}

export const taskStateValues = {

    'right': 0,
    'left': 1,
    'center': 2
}