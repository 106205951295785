import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import alerts from './alerts'
import clients from './clients'
import library from './library'
import employees from './employees'
import jobs from './jobs'
import walkthroughs from './walkthroughs'
import inspections from './inspections'
import proposals from './proposals'
import templates from './templates'
import user from './user'
import acl from './acl'
import pcl from './pcl'
import estimates from './estimates'
import sales from './sales'
import shop from './shop'
import { completedProposalSlice } from 'store/slices/proposals'
import { clientsApi, employeesApi, estimatesApi, inspectionsApi, locationssApi, proposalsApi, walkthroughsApi } from 'store/apis'


export default combineReducers({
  alerts,
  form,
  clients,
  library,
  employees,
  jobs,
  proposals,
  user,
  walkthroughs,
  inspections,
  acl,
  pcl,
  estimates,
  templates,
  sales,
  shop,
  completedProposal: completedProposalSlice.reducer,
  [proposalsApi.reducerPath]: proposalsApi.reducer,
  [estimatesApi.reducerPath]: estimatesApi.reducer,
  [inspectionsApi.reducerPath]: inspectionsApi.reducer,
  [walkthroughsApi.reducerPath]: walkthroughsApi.reducer,
  [locationssApi.reducerPath]: locationssApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer
})
