export const getEstimates = query => dispatch =>
  dispatch({
    type: 'GET_ESTIMATES',
    payload: {
      request: {
        url: `/api/estimates${
          query && query.clientId ? '?clientId=' + query.clientId : ''
        }${query && query.limit ? '&limit=' + query.limit : ''}${
          query && query.skip ? '&skip=' + query.skip : ''
        }`
      }
    }
  })

export const createEstimate = estimate => dispatch =>
  dispatch({
    type: 'CREATE_ESTIMATE',
    payload: {
      request: {
        url: `/api/estimates`,
        method: 'post',
        data: estimate
      }
    }
  })

export const getEstimate = estimateId => dispatch =>
  dispatch({
    type: 'GET_ESTIMATE',
    payload: {
      request: {
        url: `/api/estimates/${estimateId}`
      }
    }
  })

export const updateEstimate = estimate => dispatch =>
  dispatch({
    type: 'UPDATE_ESTIMATE',
    payload: {
      request: {
        url: `/api/estimates/${estimate._id}`,
        method: 'patch',
        data: estimate
      }
    }
  })

export const archiveEstimate = estimateId => dispatch =>
  dispatch({
    type: 'ARCHIVE_ESTIMATE',
    payload: {
      request: {
        url: `/api/estimates/${estimateId}/delete`,
        method: 'patch'
      }
    }
  })

export const cloneEstimate = values => dispatch =>
  dispatch({
    type: 'CLONE_ESTIMATE',
    payload: {
      request: {
        url: `/api/estimates/${values.estimate}/clone`,
        method: 'post',
        data: values
      }
    }
  })