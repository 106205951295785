import React from 'react'
import { Input } from 'reactstrap'
import FieldSet from './FieldSet'

const InputField = ({
  input,
  label,
  type,
  children,
  placeholder,
  readOnly,
  meta,
  fieldStyle,
  style,
  props,
  ...rest
}) => (
  <FieldSet label={label} meta={meta} style={fieldStyle}>
    <Input
      placeholder={placeholder}
      {...input}
      readOnly={readOnly}
      type={type}
      rows={String(type === 'textarea' && '4')}
      style={{ resize: 'vertical', ...style }}
      onKeyPress={e => {
        if (type !== 'textarea' && e.key === 'Enter') e.preventDefault()
      }}
      {...rest}
    >
      {children}
    </Input>
  </FieldSet>
)

export default InputField
