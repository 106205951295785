import React, { useState } from 'react';
import { FormGroup, Label, Modal, ModalBody, Button, Input } from 'reactstrap'
import styled from 'styled-components'
import { IoIosClose, IoMdArrowRoundForward } from 'react-icons/io'
import { BusyButton } from 'components/lib/Button'
import BigCheckbox from 'components/fields/BigCheckbox'

import RouteLogo from 'assets/img/route-logo.png'

const ProposalLosses = [
  'Scope of Work',
  'Terms & Conditions'
]

const WalkthroughLosses = [
  'Notes',
  'Photos',
  'Square Footage',
  'Floor Type',
  'Fixtures'
]

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  margin-bottom: 20px;
  border-bottom: 4px solid #000;
  
  img {
    width: 100%;
    max-width: 200px;
    padding: 5px;
    background-color: black;
    border-radius: 5px;
}
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledInfoCard = styled.div`
  background-color: #f4f4f4;
  border-radius: 20px;
  padding: 20px;
  width: 49%;
  margin-bottom: 20px;
  box-shadow: 2px 4px 11px 0 rgba(0,0,0,0.14);
  min-height: 100%;
`;

const InfoCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 85%;
`;

const InfoCardCol = styled.div`
  width: 45%;
`;

const StyledCardTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
`;

const StyledSubTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
  `;

const StyledLineItem = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 8px;
  margin-left: ${({indent}) => indent || '0'}px;
`;

const StatisticContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50%;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const StatisticIcon = styled.div`
  background-color: #20e40024;
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: 5px;

  svg {
    color: #20e400;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const FarewellSpan = styled.p`
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`;

const StyledClose = styled(IoIosClose)`
  font-size: 25px;
  color: ${({theme}) => theme.colors.danger};
  margin: 0 -2px 2px -20px;
`

const FEEDBACK_OPTIONS = [
  'Technical issues',
  'Too expensive',
  'Switching to another product',
  'Shutting down the company',
  'Not sure how to use the data & tools',
  'Missing features I need'
]

const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`

const CheckLabel = styled.p`
  font-size: 14px;
  margin: 0 0 0 6px;
`

const FeedbackHeader = styled.h2`
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  text-align: center;
`

const FeedbackSubHeader = styled.p`
  font-size: 18px;
  color: #000;
  margin-bottom: 14px;
  text-align: center;
`

const FeedbackEmoji = styled.p`
  font-size: 70px;
  text-align: center;
  margin: -20px 0 0 0;
`

const FeedbackModal = ({isOpen, toggle, terminateSubscription}) => {
  const [ feedbackData, setFeedbackData ] = useState({selectedFeedback: []})
  const [ busy, setBusy ] = useState(false)

  const _terminateSubscription = async () => {
    setBusy(true)
    await terminateSubscription(feedbackData)
    setBusy(false)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} style={{paddingTop: 50}}>
      <ModalBody style={{padding: '15px 30px'}}>
        <FeedbackEmoji>&#128546;</FeedbackEmoji>
        <FeedbackHeader>
          We're sad to see you go...
        </FeedbackHeader>
        <FeedbackSubHeader>
          Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps!  
        </FeedbackSubHeader>
        {FEEDBACK_OPTIONS.map((option, index) => (
          <CheckContainer>
            <BigCheckbox 
              itemValue={option}
              checked={feedbackData.selectedFeedback.includes(option)}
              onChange={event => {
                let updatedFeedback = {...feedbackData}
                if (event.target.checked) {
                  updatedFeedback.selectedFeedback.push(option)
                } else {
                  updatedFeedback.selectedFeedback = updatedFeedback.selectedFeedback.filter(item => item !== option)
                }
                setFeedbackData(updatedFeedback)
              }}
              labelStyles={{margin: 0}}
            />
            <CheckLabel>
              {option}
            </CheckLabel>
          </CheckContainer>
        ))}
        <CheckContainer>
          <BigCheckbox 
            itemValue={feedbackData.other}
            checked={feedbackData.other}
            onChange={event => {
              let updatedFeedback = {...feedbackData}
              updatedFeedback.other = event.target.checked
              setFeedbackData(updatedFeedback)
            }}
            labelStyles={{margin: 0}}
          />
          <CheckLabel>
            Other
          </CheckLabel>
        </CheckContainer>
        <Input
          style={{height: 100, marginTop: 16}}
          placeholder="Anything you want to share? (Optional)"
          type="textarea"
          disabled={!feedbackData.other}
          value={feedbackData.otherText || ''}
          onChange={e => {
            let updatedFeedback = {...feedbackData}
            updatedFeedback.otherText = e.target.value || ''
            setFeedbackData(updatedFeedback)
          }}
        />
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 24}}>
          <BusyButton
            busy={busy}
            color="danger"
            onClick={_terminateSubscription}
            style={{width: '40%'}}
          >
            Cancel Account
          </BusyButton>
          <Button onClick={toggle} color='link'>Nevermind, I don't want to cancel.</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

const LossesCardContent = () => {
  return (
    <>
      <StyledCardTitle>
        Here's what you’ll lose access to:
      </StyledCardTitle>
      <InfoCardRow>
        <InfoCardCol>
          <StyledLineItem>
            <StyledClose />
            Clients & Locations
          </StyledLineItem>
          <StyledLineItem>
          <StyledClose />
            Walkthroughs
          </StyledLineItem>
          {WalkthroughLosses.map((item) => (
            <StyledLineItem key={item} indent={10}>
              <StyledClose />
              {item}
            </StyledLineItem>
          ))}
        </InfoCardCol>
        <InfoCardCol>
          <StyledLineItem>
          <StyledClose />
            Proposals
          </StyledLineItem>
          <StyledLineItem indent={10}>
          <StyledClose />
            Customized
          </StyledLineItem>
          {ProposalLosses.map((item) => (
            <StyledLineItem key={item} indent={20}>
              <StyledClose />
              {item}
            </StyledLineItem>
          ))}
        </InfoCardCol>
      </InfoCardRow>
    </>
  )
}

const DataCardContent = () => {
  return (
    <>
      <StyledCardTitle>
        Our Customers Report:
      </StyledCardTitle>
      <InfoCardRow>
        <InfoCardCol>
          <StatisticContainer>
            <StatisticIcon>
              <IoMdArrowRoundForward />
            </StatisticIcon>
            <div>
              <StyledSubTitle>
                70% 
              </StyledSubTitle>
              <StyledLineItem>
                Increase Profits
              </StyledLineItem>
            </div>
          </StatisticContainer>
          <StatisticContainer>
            <StatisticIcon>
              <IoMdArrowRoundForward />
            </StatisticIcon>
            <div>
              <StyledSubTitle>
                1-4 Hours 
              </StyledSubTitle>
              <StyledLineItem>
                Saved per Proposal
              </StyledLineItem>
            </div>
          </StatisticContainer>
        </InfoCardCol>
        <InfoCardCol>
          <StatisticContainer>
            <StatisticIcon>
              <IoMdArrowRoundForward />
            </StatisticIcon>
            <div>
              <StyledSubTitle>
                40% More
              </StyledSubTitle>
              <StyledLineItem>
                Deals won
              </StyledLineItem>
            </div>
          </StatisticContainer>
          <StatisticContainer>
            <StatisticIcon>
              <IoMdArrowRoundForward />
            </StatisticIcon>
            <div>
              <StyledSubTitle>
                40% More
              </StyledSubTitle>
              <StyledLineItem>
                More Customers
              </StyledLineItem>
            </div>
          </StatisticContainer>
        </InfoCardCol>
      </InfoCardRow>
    </>
  )
}

const CancelationModal = ({isOpen, cancelSubscription, closeModal}) => {
  const [ showFeedbackModal, setShowFeedbackModal ] = useState(false)

  const _toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal)
    closeModal()
  }
  
  return (
    <Modal size='lg' isOpen={isOpen}>
      {showFeedbackModal && (
        <FeedbackModal isOpen={showFeedbackModal} toggle={_toggleFeedbackModal} terminateSubscription={cancelSubscription} />
      )}
      <ModalBody style={{paddingBottom: '2rem'}}>
        <ImageContainer>
          <img src={RouteLogo} alt="Route Logo" />
        </ImageContainer>
        <FarewellSpan>
          We're sorry to see you go. <strong>Are you sure you want to cancel?</strong>
        </FarewellSpan>
        <CardRow>
          <StyledInfoCard>
            <LossesCardContent />
          </StyledInfoCard>
          <StyledInfoCard>
            <DataCardContent />
          </StyledInfoCard>
        </CardRow>
        <ButtonRow>
          <Button onClick={closeModal} color="primary" size="lg" style={{borderRadius: 30, marginRight: 50}}>
            Nevermind - Keep My Subscription
          </Button>
          <Button onClick={() => setShowFeedbackModal(true)} color="danger" size="lg" style={{borderRadius: 30}}>
            Cancel Subscription
          </Button>
        </ButtonRow>
      </ModalBody>
    </Modal>
  );
}

export default CancelationModal;