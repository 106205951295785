import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  ListGroup,
  ListGroupItem,
  Button,
  ButtonGroup,
  Card,
} from 'reactstrap'
import { toast } from 'react-toastify'
import { connectConfirmation } from 'components/confirm'
import moment from 'moment'

import * as actions from 'api'

import networkErrorToast from 'utils/networkErrorToast'

import DataScreen from 'components/DataScreen'
import SelectClientLocationModal from 'screens/Dashboard/components/modals/SelectClientLocationModal'
import { fullName } from 'utils'

class EntityLists extends Component {
  state = {
    showLocationModal: false,
    locationFormRedirect: null,
    showWonProposals: false
  }

  componentDidMount() {
    const { entity } = this.props
    let clientId
    let locationId

    if (entity.type === 'client') {
      clientId = entity.data._id
    } else if (entity.type === 'location') {
      clientId = entity.data.client
      locationId = entity.data._id
    }

    this.setState({
      clientId,
      locationId
    })
  }

  _createWalkthrough = () => {
    const { clientId, locationId } = this.state

    this.props.history.push('/dashboard/new-job/Walkthrough', {
      clientId,
      locationId
    })
  }

  _createInspection = () => {
    const { clientId, locationId } = this.state

    this.props.history.push('/dashboard/new-job/Inspection', {
      clientId,
      locationId
    })
  }

  _createEstimate = values => {
    const { clientId, locationId } = this.state
    if (values) {
      this.props.history.push('/dashboard/new-estimate', {
        clientId: values.client,
        locationId: values.location
      })
    } else {
      this.props.history.push('/dashboard/new-estimate', {
        clientId,
        locationId
      })
    }
  }

  _createProposal = async values => {
    const { company } = this.props
    const { clientId, locationId } = this.state

    let proposal = {
      merchant: company
    }

    if (values) {
      proposal.client = values.client
      proposal.location = values.location
    } else {
      proposal.client = clientId
      proposal.location = locationId
    }

    const result = await this.props.createProposal(proposal)
    if (result.error) {
      return networkErrorToast(result, 'Unable to Create Proposal')
    }

    if (result.payload && result.payload.data) {
      this.props.history.push(
        `/dashboard/edit-proposal/${result.payload.data._id}`,
        {
          proposal: result.payload.data
        }
      )
    }
    toast('Proposal Created')
  }

  _toggleLocationModal = redirectTo => {
    const { showLocationModal } = this.state
    this.setState({
      showLocationModal: !showLocationModal,
      locationFormRedirect: redirectTo
    })
  }

  _onLocationSubmit = values => {
    const { locationFormRedirect } = this.state
    switch (locationFormRedirect) {
      case 'proposal':
        this._createProposal(values)
        break
      case 'estimate':
        this._createEstimate(values)
        break
      default:
        return
    }
  }

  render() {
    const {
      showLocationModal,
      locationId,
      showWonProposals
    } = this.state
    const { entity, showAddress } = this.props

    const renderItem = (item, index, entityType, link) => {

      let score = null
      let letsImproveAreas = 0
      let greatAreas = 0
      
      if (entityType === 'Inspection' && item.areas && item.areas.length > 0) {
        item.areas.forEach(area => {
          if (area.rating === 1) {
            letsImproveAreas++
          } else if (area.rating === 3) {
            greatAreas++
          }
        })
        score = Math.floor(
          (greatAreas / (letsImproveAreas + greatAreas)) * 100
        )
      }

      return (
        <ListGroupItem
          tag="a"
          key={index}
          action
          href={`${link}${item._id}`}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <span>
              {item.code}
              {item.name && ' - ' + item.name}
              {showAddress &&
                item.location &&
                item.location.address &&
                item.location.address.fullAddress &&
                ' - ' + item.location.address.fullAddress}
            </span>
            {entityType === 'Inspection' && score ? 
              (
                <span>
                  <strong>Score:</strong> {score}%
                </span>
              ) : null
            }
            <span>
              <strong>Created:</strong> {moment(item.createdAt).format('l')}
            </span>
            <span>
              <strong>Created by:</strong>{' '}
              {item.createdBy &&
                item.createdBy.name &&
                fullName(item.createdBy.name)}
            </span>
          </div>
        </ListGroupItem>
      )
    }

    const emptyString = (type, addItem) => {
      return (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          No {type} exist for this client.{' '}
          <Button color="link" onClick={() => addItem()}>
            Create one now!
          </Button>
        </span>
      )
    }

    const RenderList = ({
      data,
      title,
      buttonLabel,
      addFunction,
      loadFunction,
      link,
      entityType
    }) => (
      <Card body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginBottom: 16
          }}
        >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            marginBottom: 16
          }}
        >
          <h5>{title}</h5>
          {entityType === 'Proposal' ? (
            <ButtonGroup style={{marginLeft: 40}}>
              <Button
                color="primary"
                size='sm'
                outline
                onClick={() => this.setState({ showWonProposals: false })}
                active={showWonProposals === false}
              >
                Open
              </Button>
              <Button
                color="primary"
                size='sm'
                outline
                onClick={() => this.setState({ showWonProposals: true })}
                active={showWonProposals === true}
              >
                Signed
              </Button>
            </ButtonGroup>
          ) : null}
        </div>
          <Button size="sm" color="primary" onClick={addFunction}>
            {buttonLabel || 'Create New'}
          </Button>
        </div>
        <ListGroup>
          {data && data.length
            ? data.map((item, index) =>
                renderItem(item, index, entityType, link)
              )
            : emptyString(`${title.toLowerCase()}`, addFunction)}
        </ListGroup>
        {loadFunction && (
          <Button color="link" style={{ marginTop: 8 }} onClick={loadFunction}>
            Load more...
          </Button>
        )}
      </Card>
    )

    return (
      <>
        {!locationId && (
          <SelectClientLocationModal
            showModal={showLocationModal}
            toggle={this._toggleLocationModal}
            onSubmit={this._onLocationSubmit}
            initialValues={{ client: entity.data._id }}
          />
        )}
        <DataScreen
          usePages={false}
          url={`/api/jobs/find?type=Walkthrough&limit=5&${entity.type +
            '=' +
            entity.data._id}`}
          entity="Walkthrough"
          hideEmpty
          hideBaseContainer
          component={
            <RenderList
              title="Walkthroughs"
              buttonLabel="Add a walkthrough"
              addFunction={this._createWalkthrough}
              link="/dashboard/walkthroughs/"
            />
          }
        />
        <DataScreen
          usePages={false}
          url={`/api/estimates/find?limit=5&${entity.type +
            '=' +
            entity.data._id}`}
          entity="Estimate"
          hideEmpty
          hideBaseContainer
          component={
            <RenderList
              title="Estimates"
              buttonLabel="Add an estimate"
              addFunction={() =>
                locationId
                  ? this._createEstimate()
                  : this._toggleLocationModal('estimate')
              }
              link="/dashboard/edit-estimate/"
            />
          }
        />
        <DataScreen
          usePages={false}
          url={`/api/proposals/find?limit=5&${entity.type +
            '=' +
            entity.data._id}&filters={"won":${showWonProposals}}`}
          entity="Proposal"
          hideEmpty
          hideBaseContainer
          component={
            <RenderList
              title="Proposals"
              entityType={'Proposal'}
              buttonLabel="Add a proposal"
              addFunction={() =>
                locationId
                  ? this._createProposal()
                  : this._toggleLocationModal('proposal')
              }
              link="/dashboard/edit-proposal/"
            />
          }
        />
        <DataScreen
          usePages={false}
          url={`/api/jobs/find?type=Inspection&limit=5&${entity.type +
            '=' +
            entity.data._id}`}
          entity="Inspection"
          hideEmpty
          hideBaseContainer
          component={
            <RenderList
              title="Inspections"
              entityType="Inspection"
              buttonLabel="Add an inspection"
              addFunction={this._createInspection}
              link="/dashboard/inspections/"
            />
          }
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  company: state.user.currentCompany,
  currentEmployment: state.user.currentEmployment
})

export default connect(
  mapStateToProps,
  actions
)(connectConfirmation(EntityLists))
