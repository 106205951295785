import React from 'react'
import { IoMdClose } from 'react-icons/io'

export const ToastCloseButton = ({ closeToast }) => {
  return (
    <IoMdClose
      size={20}
      onClick={closeToast}
    />
  )
}