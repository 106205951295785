import React, { Component } from 'react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { Card } from 'reactstrap'
import { CloseButton, MiniButton } from 'components/lib/Button'
import { InputField, DropdownField, ColorPickerField } from 'components/fields'
import { required } from 'utils'
import chargeTypes from 'data/chargeTypes.json'
import { TaskFrequencies } from 'data'

const showAlphaFeatures = process.env.REACT_APP_SHOW_ALPHA_FEATURES === '1'

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
`

const TasksComponent = ({
  fields,
  buttonLabel,
  meta: { error, submitFailed }
}) => {
  return (
    <>
      <p>Tasks:</p>
      <Card body>
        <table className="table table-sm">
          <thead>
            <tr>
              <th>Task</th>
              <th>Frequency</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {fields.map((line, index) => (
              <tr key={index} style={{ padding: 0 }}>
                <td>
                  <Field
                    name={`${line}.name`}
                    type="text"
                    component={InputField}
                    props={{ bsSize: 'sm' }}
                    style={{ height: 37.5 }}
                    validate={[required]}
                  />
                </td>
                <td>
                  <Field
                    name={`${line}.frequency`}
                    type="text"
                    component={DropdownField}
                    data={TaskFrequencies}
                    props={{ bsSize: 'sm' }}
                    validate={[required]}
                  />
                </td>
                <td>
                  <CloseButton
                    onClick={() => fields.remove(index)}
                    style={{ marginTop: 4 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <MiniButton
          color="primary"
          style={{ maxWidth: 90 }}
          onClick={() => fields.push({})}
        >
          {buttonLabel || 'Add Item'}
        </MiniButton>
      </Card>
    </>
  )
}

export const Item = ({ item }) => {
  if (item) {
    return <span>{item.name && item.name}</span>
  } else {
    return null
  }
}

class ServiceTemplateForm extends Component {
  static propTypes = {
    _id: PropTypes.string,
    defaultValues: PropTypes.object
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          name="name"
          component={InputField}
          type="text"
          label="Service Name"
          validate={required}
          warn={required}
        />
        <Field
          name="labelColor"
          component={ColorPickerField}
          label="Label Color"
          props={{
            onChange:data => {
              console.log(data)
            }
          }}
          useHex
          compact
        />
        <Field
          name="chargeType"
          component={DropdownField}
          data={chargeTypes}
          itemComponent={Item}
          type="select"
          label="Charge Type"
          textField="name"
          normalize={value => (value ? value.id : null)}
          valueField="id"
        />
        <FieldArray
          name="tasks"
          component={TasksComponent}
          buttonLabel="+ Add Task"
        />
        {showAlphaFeatures && (
          <Field
            name="rate"
            component={InputField}
            type="text"
            label="Hourly Rate"
          />
        )}
        <SubmitContainer>
          <button type="submit" className="btn btn-primary">
            Done
          </button>
        </SubmitContainer>
      </form>
    )
  }
}

export default reduxForm({ form: 'ServiceTemplate' })(ServiceTemplateForm)
