import numeral from "numeral"
import moment from "moment"

export const calculateRevenueStats = (proposals) => {
  if (!proposals?.length) return {}

  let yearlyStats = {
    newSales: 0,
    salesByLocation: {
      cities: {},
      states: {},
      countries: {}
    },
    salesByService: {},
    averageContractValue: 0,
    salesLeaderboard: {},
    salesTrend: {},
    totalSqFt: 0,
    salesByLocationType: {}
  }

  proposals?.forEach(proposal => {
    if (!proposal?.revenueData) return
    const yearlyTotal = proposal?.revenueData?.yearlyTotal
    const city = proposal?.location?.address?.city
    const state = proposal?.location?.address?.state
    const country = proposal?.location?.address?.country
    const locationType = proposal?.location?.type

    // new sales
    yearlyStats.newSales += yearlyTotal

    // sales by location
    if (city) {
      if (yearlyStats.salesByLocation.cities[city]) {
        yearlyStats.salesByLocation.cities[city] += yearlyTotal
      } else {
        yearlyStats.salesByLocation.cities[city] = yearlyTotal
      }
    }

    if (state) {
      if (yearlyStats.salesByLocation.states[state]) {
        yearlyStats.salesByLocation.states[state] += yearlyTotal
      } else {
        yearlyStats.salesByLocation.states[state] = yearlyTotal
      }
    }

    if (country) {
      if (yearlyStats.salesByLocation.countries[country]) {
        yearlyStats.salesByLocation.countries[country] += yearlyTotal
      } else {
        yearlyStats.salesByLocation.countries[country] = yearlyTotal
      }
    }

    if (locationType) {
      if (yearlyStats.salesByLocationType[locationType]) {
        yearlyStats.salesByLocationType[locationType] += yearlyTotal
      } else {
        yearlyStats.salesByLocationType[locationType] = yearlyTotal
      }
    }

    // sales by service
    const proposalServiceTotals = proposal?.revenueData?.serviceTotals
    if (proposalServiceTotals) {
      const services = Object.keys(proposalServiceTotals)
      services?.forEach(service => {
        if (yearlyStats.salesByService[service]) {
          yearlyStats.salesByService[service] += proposalServiceTotals[service]?.yearlyTotal
        } else {
          yearlyStats.salesByService[service] = proposalServiceTotals[service]?.yearlyTotal
        }
      })
    }

    // sales leaderboard

    const employeeId = proposal?.createdBy?._id
    const employeeName = `${proposal?.createdBy?.name?.first} ${proposal?.createdBy?.name?.last?.charAt(0)}`

    if (yearlyStats.salesLeaderboard[employeeId]) {
      let prevSales = yearlyStats.salesLeaderboard[employeeId].sales
      yearlyStats.salesLeaderboard[employeeId] = {
        name: employeeName,
        sales: prevSales + yearlyTotal
      }
    } else {
      yearlyStats.salesLeaderboard[employeeId] = {
        name: employeeName,
        sales: yearlyTotal
      }
    }

    // salesTrend
    const proposalSignedDate = proposal?.signatureData?.merchantSignature?.dateSigned
    if (proposalSignedDate) {
      const proposalDate = moment(proposalSignedDate).startOf('day').format('L')

      if (yearlyStats.salesTrend[proposalDate]) {
        yearlyStats.salesTrend[proposalDate].sales = yearlyStats.newSales
      } else {
        yearlyStats.salesTrend[proposalDate] = {
          date: proposalDate,
          sales: yearlyStats.newSales
        }
      }
    }

    //totalSqFt
    const proposalSqFt = proposal?.revenueData?.totalSqFt
    if (proposalSqFt) {
      yearlyStats.totalSqFt += parseInt(proposalSqFt)
    }
  })

  // average contract value
  yearlyStats.averageContractValue = yearlyStats.newSales / proposals.length

  //format some numbers
  yearlyStats.averageContractValue = numeral(yearlyStats.averageContractValue).format('0,0[.]00')
  yearlyStats.newSales = numeral(yearlyStats.newSales).format('0,0[.]00')

  return {
    yearlyStats
  }
}
