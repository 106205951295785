export default [
  "Airport",
  "Apartment",
  "Bank",
  "Bar Restaurant",
  "Church",
  "Commercial Office Building",
  "Data Center",
  "Dealership",
  "Healthcare Facility",
  "Fitness Center",
  "Library",
  "Office",
  "Other",
  "Residential Complex",
  "Retail Store",
  "School",
  "Single Family Home",
  "Theatre",
  "Warehouse"
]
