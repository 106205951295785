import React from 'react'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: ${props => (props.size ? props.size + 20 + 'px' : '42px')};
  height: ${props => (props.size ? props.size + 20 + 'px' : '42px')};
  border-radius: 50px;
`

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`

const MaterialSpinner = ({ size, color, wrapped, ...rest }) => {
  const spinner = (
    <SpinnerWrapper>
      <Spinner
        size={size || 30}
        color={color || '#0b64eb'}
        spinnerWidth={3}
        visible={true}
        {...rest}
      />
    </SpinnerWrapper>
  )
  if (wrapped) {
    return <Wrapper>{spinner}</Wrapper>
  }
  return <>{spinner}</>
}

export default MaterialSpinner
