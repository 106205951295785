import React from 'react'

import Field from '../field'
import { PrimaryButton, Question, Tooltip } from '../ui'
import { InputState } from '../../../../../../hooks'

export const CommercialFloorStage = ({
  current,
  id,
  label,
  max,
  min,
  onClick,
  step,
  type
}) => {
  let initialValue = 5

  if (current && current.percentHardFloors) {
    initialValue = current.percentHardFloors / 10
  }

  const [value, setValue] = InputState(initialValue)
  const percentHardFloors = value * 10
  const percentSoftFloors = 100 - percentHardFloors

  const onNext = event =>
    onClick(event, { percentHardFloors, percentSoftFloors })

  return (
    <Question>
      <Tooltip>
        Hard floor surfaces need sweeping and mopping, which means they take a
        little longer to clean. Hard floors are typically VCT, concrete, tile
        and wood while soft floor surfaces are typically carpet.
      </Tooltip>
      <Field
        id={id}
        label={label}
        labelStyle={{}}
        max={max}
        maxColor={'#0b64eb'}
        maxText={`${percentHardFloors}% Hard Floors`}
        midText={``}
        min={min}
        minColor={'#0b64eb'}
        minText={`${percentSoftFloors}% Soft Floors`}
        name={id}
        onChange={setValue}
        step={step}
        style={{ marginTop: '2rem' }}
        type={type}
        value={value}
      />

      <PrimaryButton onClick={onNext} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
}

export default CommercialFloorStage
