import React from 'react'
import Number from './knobs/Number'
import Color from './knobs/Color'

export default ({ element, onChange }) => (
  <>
    <Number
      showSteppers={false}
      min={1}
      field="height"
      label="Spacer Height"
      data={element.data}
      onChange={onChange}
    />
    <Color
      field="color"
      label="Spacer Color"
      data={element.data}
      onChange={onChange}
      style={{ marginTop: 8}}
    />
  </>
)
