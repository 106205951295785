import styled from 'styled-components'

export const Button = styled.button`
  align-items: center;
  background-color: #0a5ad1;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.25rem;
  justify-content: center;
  line-height: 1.5;
  outline: none;
  padding: calc(0.5rem + 1px) 0.75rem;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  aria-disabled: true;

  :active,
  :focus,
  :hover {
    opacity: 0.9;
  }

  &[disabled] {
    background-color: #aaa;
  }
`

export const PrimaryButton = styled(Button)`
  border-radius: 2rem;
  font-size: 1.5rem;
  margin: 3rem auto 0 auto;
  max-width: 200px;
  transition: transform 0.4s ease;
  width: 100%;

  :active,
  :focus,
  :hover {
    transform: translateY(-4px);
  }
`

export const BackButton = styled(Button)`
  background-color: transparent;
  border: 2px solid #0a5ad1;
  border-radius: 2rem;
  color: #0a5ad1;
  font-size: 1rem;
  font-weight: 500;
  left: 0;
  padding: calc(0.5rem + 1px) 1.5rem;
  position: absolute;
  top: 0;

  @media screen and (min-width: 32rem) {
    left: 10%;
    font-size: 1.25rem;
    top: 0;
  }
`

export const RestartButton = styled(Button)`
  background-color: transparent;
  border: 2px solid #ff4141;
  border-radius: 2rem;
  color: #ff4141;
  font-size: 1rem;
  font-weight: 500;
  padding: calc(0.5rem + 1px) 0.75rem;
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (min-width: 32rem) {
    right: 10%;
    font-size: 1.25rem;
    top: 0;
  }
`

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 6rem;
  position: relative;
  width: 100%;
`
