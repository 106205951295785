import styled from 'styled-components'

export const RadioGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const RadioInput = styled.input`
  font-size: 1.25rem;
  margin-right: 0.5rem;
`

export const RadioLabel = styled.label`
  font-size: 1.25rem;

  :first-child {
    margin-right: 2rem;
  }
`
