import React from 'react'
import {
  FiEdit,
  FiImage,
  FiLayout,
  FiMinus,
  FiType,
  FiPenTool,
  FiTrello,
  FiList
} from 'react-icons/fi'
import { FaFileInvoiceDollar, FaRegCalendarTimes, FaCalendarAlt } from 'react-icons/fa'
import { FaExpand } from 'react-icons/fa'
import { capitalize } from 'utils'
import legal from 'data/legal.js'

import AreasRenderer from './components/renderers/Areas'
import ChargesRenderer from './components/renderers/Charges'
import DateRangeRenderer from './components/renderers/DateRange'
import DividerRenderer from './components/renderers/Divider'
import HeadingRenderer from './components/renderers/Heading'
import ImageRenderer from './components/renderers/Image'
import LetterheadRenderer from './components/renderers/Letterhead'
import RichTextRenderer from './components/renderers/RichText'
import ScopeOfWorkRenderer from './components/renderers/ScopeOfWork'
import ServiceFrequencyRenderer from './components/renderers/ServiceFrequency'
import SignaturesRenderer from './components/renderers/Signatures'

export default [
  {
    type: 'rich_text',
    label: 'Rich Text',
    tooltip:
      'A multi purpose text editor that supports titles, paragraphs, bullet points and tables.',
    allowCustomElements: true,
    icon: FiEdit,
    initialData: {
      markdown: `Add your content here.`
    },
    render: ({ element }) => <RichTextRenderer element={element} />,
    print: element => {
      return element.data.markdown
    }
  },
  {
    type: 'heading',
    label: 'Heading',
    tooltip:
      'Adds larger title text with customizable size, color and alignment.',
    allowCustomElements: true,
    icon: FiType,
    initialData: {
      text: 'Heading',
      type: {
        label: 'Heading 2',
        size: 'h2'
      }
    },
    render: ({ element }) => <HeadingRenderer element={element} />
  },
  {
    type: 'divider',
    label: 'Divider',
    allowCustomElements: true,
    tooltip: 'Add a thin line between elements with customizable thickness.',
    icon: FiMinus,
    initialData: {
      color: 'black',
      height: 1
    },
    render: ({ element }) => <DividerRenderer element={element} />
  },
  {
    type: 'spacer',
    label: 'Spacer',
    tooltip: 'Add a space between elements with customizable height.',

    allowCustomElements: true,
    icon: FaExpand,
    initialData: {
      height: 100
    },
    render: ({ element }) => {
      return (
        <div
          style={{
            height: element.data.height || 20,
            backgroundColor: element.data.color || 'white'
          }}
        />
      )
    }
  },
  {
    type: 'image',
    label: 'Image',
    allowCustomElements: true,
    tooltip:
      'Use any image in your proposal. Customizable cropping, width and alignment.',

    icon: FiImage,
    initialData: {
      image: {
        url: 'https://via.placeholder.com/800x400?text=Choose+Image'
      },
      size: 100
    },
    render: ({ element }) => <ImageRenderer element={element} />
  },
  {
    type: 'areas',
    label: 'Areas',
    tooltip: 'Pull in area information from your walkthrough or location data.',
    allowCustomElements: false,
    icon: FiLayout,
    create: (proposal, options = {}) => {
      let areas = []
      let source
      if (proposal.job && proposal.job.areas && proposal.job.areas.length) {
        areas = [...proposal.job.areas]
        source = 'Walkthrough'
      } else if (
        proposal.location &&
        proposal.location.areas &&
        proposal.location.areas.length
      ) {
        areas = [...proposal.location.areas]
        source = 'Location'
      }
      return {
        areas,
        source,
        showHeading: true,
        includeSquareFootage: true,
        includeFixtures: true,
        includeImages: true,
        includeNotes: true,
        showFixtureAggregate: true,
        showSquareFootageTotals: true
      }
    },
    render: ({ element, proposal }) => (
      <AreasRenderer element={element} proposal={proposal} />
    )
  },

  {
    type: 'signatures',
    label: 'Signatures',
    tooltip: 'Adds preset lines to capture signatures in a paper proposal.',
    allowCustomElements: true,
    icon: FiPenTool,
    initialData: {},
    create: (proposal, options = {}) => {
      let serviceProvider = proposal.merchant.name
      let client = proposal.client.name
      return { serviceProvider, client, showHeading: true }
    },
    render: ({ element, proposal }) => (
      <SignaturesRenderer element={element} proposal={proposal} />
    )
  },

  {
    type: 'letterhead',
    label: 'Letterhead',
    tooltip:
      'Creates a customizable cover page with your company and client info.',
    allowCustomElements: true,
    icon: FiTrello,
    initialData: {
      title: 'Service Proposal'
    },
    create: (proposal, options = {}) => {
      if (proposal) {
        let title = 'Service Proposal'
        let code = (proposal && proposal.code) || ''
        let merchantName =
          (proposal && proposal.merchant && proposal.merchant.name) || ''
        let merchantAddress =
          (proposal &&
            proposal.merchant &&
            proposal.merchant.address &&
            proposal.merchant.address.fullAddress) ||
          ''
        let merchantLogo =
          proposal && proposal.merchant && proposal.merchant.logo
        let clientName =
          (proposal && proposal.client && proposal.client.name) || ''
        let clientAddress =
          // (proposal &&
          //   proposal.client &&
          //   proposal.client.address &&
          //   proposal.client.address.fullAddress) ||
          (proposal &&
            proposal.location &&
            proposal.location.address &&
            proposal.location.address.fullAddress) ||
          ''
        let clientLogo = proposal && proposal.client && proposal.client.logo
        let proposalDate = proposal && proposal.createdAt
        return {
          title,
          code,
          merchantName,
          merchantAddress,
          merchantLogo,
          clientName,
          clientAddress,
          clientLogo,
          showClientLogo: true,
          showMerchantLogo: true,
          showProposalCode: true,
          proposalDate,
          showProposalDate: true,
          letterheadHeight: 50
        }
      }
      return {}
    },
    render: ({ element, proposal }) => (
      <LetterheadRenderer element={element} proposal={proposal} />
    )
  },

  {
    type: 'scope_of_work',
    label: 'Scope of Work',
    tooltip:
      'Completely editable scope of work (section or area tasks and frequencies) with built in templates based on your services.',
    allowCustomElements: true,
    icon: FiList,
    initialData: {
      taskSections: [
        {
          name: 'Task Section',
          tasks: [
            {
              name: 'Add tasks',
              frequency: 'Daily'
            }
          ]
        }
      ],
      showHeading: true,
      textSize: 12
    },

    render: ({ element, proposal }) => (
      <ScopeOfWorkRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'charges',
    label: 'Charges',
    tooltip: 'Completely editable service charges and line items.',
    allowCustomElements: false,
    icon: FaFileInvoiceDollar,
    create: (proposal, options = {}) => {
      const estimateServices =
        proposal && proposal.estimate && proposal.estimate.services
      let chargeSections = []

      if (estimateServices && estimateServices.length) {
        estimateServices.forEach((service, index) => {
          if (service.result) {
            chargeSections.push({
              name: capitalize(service.service.name),
              id: `service${index}`,
              interval: service.result.jobCount.interval,
              charges: [
                {
                  name: 'Base Rate',
                  price: service.result.charges.baseRate
                },
                {
                  name: 'Supplies and Materials',
                  price: service.result.charges.suppliesAndMaterials
                }
              ]
            })
          }
        })
      }

      return { title: 'Service Charges', showHeading: true, chargeSections, textSize: 12 }
    },
    render: ({ element, proposal }) => (
      <ChargesRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'service_frequency',
    label: 'Service Frequency',
    tooltip: 'A simple component to render a weekly or custom job schedule.',
    icon: FaRegCalendarTimes,
    initialData: {
      showHeading: true,
      serviceFrequency: 'Daily',
      selectedDays: {}
    },
    render: ({ element, proposal }) => (
      <ServiceFrequencyRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'service_dates',
    label: 'Service Dates',
    tooltip: 'Select start and end dates for the proposed services.',
    icon: FaCalendarAlt,
    initialData: {
      showHeading: true
    },
    render: ({ element, proposal }) => (
      <DateRangeRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'rich_text',
    label: 'Service Agreement',
    tooltip: 'A standard service agreement.',
    allowCustomElements: true,
    icon: FiEdit,
    render: ({ element }) => <RichTextRenderer element={element} />,
    print: element => {
      return element.data.markdown
    },
    create: (proposal, options = {}) => {
      let { client, merchant, location } = proposal
      let data = {}

      let markdown = ''

      const customer =
        (client && client.name && client.name.trim()) || 'YOUR CUSTOMER NAME HERE'
      const contractor =
        (merchant && merchant.name && merchant.name.trim()) ||
        'YOUR COMPANY NAME HERE'
      const customerAddress =
        (client &&
          client.address &&
          client.address.fullAddress) ||
        'YOUR CUSTOMER ADDRESS HERE'

      const locationAddress = location?.address?.fullAddress || 'YOUR LOCATION ADDRESS HERE'

      markdown =
        `The Undersigned customer, **${customer}** (hereafter referred to as "CUSTOMER") hereby accepts the proposal of **${contractor}** (hereafter referred to as "CONTRACTOR") for CUSTOMER's premises located at ***${locationAddress}***.\n\n` +
        'Upon the following terms:\n\n' +
        legal +
        '\n\n'

      data.markdown = markdown
      data.headingText = 'Service Agreement'
      data.showHeading = true
      return data
    }
  },

]
