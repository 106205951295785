import React from 'react'
import styled from 'styled-components'

const Base = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-left: auto;
  margin-right: auto;
  min-height: 64px; */
  color: white;
`

export default ({ left, right, ...rest }) => (
  <Base {...rest}>
    <div>{left}</div>
    <div>{right}</div>
  </Base>
)
