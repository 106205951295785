import React from 'react'
import numeral from 'numeral'
import { capitalize } from 'utils'

export default ({ element, proposal }) => {
  const { data } = element
  let { chargeSections } = data

  let chargeSectionsNew = structuredClone(chargeSections)

  const showHeading = data && data.showHeading
  const headingText = (data && data.headingText) || 'Charges'
  const textSize = (element && element.data && element.data.textSize) || 12

  let monthlyTotal = 0
  let weeklyTotal = 0
  let biweeklyTotal = 0
  let yearlyTotal = 0
  let oneTimeTotal = 0

  if (chargeSections && chargeSections.length > 0) {


    for (const section of chargeSectionsNew) {

      let sectionTotal = 0

      let { charges } = section

      for (const charge of charges) {

        if (!charge) continue

        if (charge.price !== 0) sectionTotal += parseFloat(charge.price)

      }

      section.total = sectionTotal
      if (section.interval === 'month') {
        monthlyTotal += sectionTotal
      } else if (section.interval === 'week') {
        weeklyTotal += sectionTotal
      } else if (section.interval === 'biweekly') {
        biweeklyTotal += sectionTotal
      } else if (section.interval === 'year') {
        yearlyTotal += sectionTotal
      } else if (section.interval === 'once') {
        oneTimeTotal += sectionTotal
      }

    }

  }

  const renderSection = (section, index) => (
    <div className="charge-section-container" key={`render-section-${index}`}>
      <h5 style={{ marginBottom: 8, fontSize: textSize + 4 }}>
        {section.name}
        <span style={{ float: 'right' }}>
          {section.interval
            ? (section.interval === 'once' || section.interval === 'biweekly')
              ? capitalize(section.interval)
              : 'per ' + capitalize(section.interval)
            : ''}
        </span>
      </h5>

      {section.charges && section.charges.length && (
        <table style={{ width: '100%', margin: 0 }}>
          <tbody>
            {section.charges.map((charge, index) => (
              <tr className="charge-item-row" key={`charge-item-${index}`}>
                <td className="charge-item-name" style={{ fontSize: textSize }}>
                  {charge.name || 'Line item'}
                </td>
                <td className="charge-item-spacer" />
                <td className="charge-item-price" style={{ fontSize: textSize }}>
                  {charge.price && charge.price !== 0
                    ? numeral(charge.price).format('$0, 0.00')
                    : '$0'}
                </td>
              </tr>
            ))}

            <tr className="charge-total-row">
              <td className="charge-total-name" style={{ fontSize: textSize }}>Total</td>
              <td className="charge-total-spacer" />
              <td className="charge-total-price" style={{ fontSize: textSize }}>
                {section.total && section.total !== 0
                  ? numeral(section.total).format('$0, 0.00')
                  : '$0'}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {section.spacer ? <div style={{ height: section.spacer }} /> : null}
    </div>
  )

  return (
    // <span>Test</span>
    <div style={{ paddingBottom: 20 }}>
      {showHeading && <h2 className="section-title">{headingText}</h2>}
      {chargeSectionsNew && chargeSectionsNew.length > 0
        ? chargeSectionsNew.map((section, index) => renderSection(section, index))
        : null}
      {element.data.showTotals && (
        <>
          {weeklyTotal && weeklyTotal !== 0 ? (
            <>
              <h5 style={{ margin: 0, fontSize: textSize + 4 }}>
                Weekly grand total
                <span style={{ float: 'right' }}>
                  {numeral(weeklyTotal).format('$0, 0.00')}
                </span>
              </h5>
              {biweeklyTotal || monthlyTotal || yearlyTotal || oneTimeTotal ? (
                <hr style={{ margin: '4px 0' }} />
              ) : null}
            </>
          ) : null}
          {biweeklyTotal && biweeklyTotal !== 0 ? (
            <>
              <h5 style={{ margin: 0, fontSize: textSize + 4 }}>
                Biweekly grand total
                <span style={{ float: 'right' }}>
                  {numeral(biweeklyTotal).format('$0, 0.00')}
                </span>
              </h5>
              {monthlyTotal || yearlyTotal || oneTimeTotal ? (
                <hr style={{ margin: '4px 0' }} />
              ) : null}
            </>
          ) : null}
          {monthlyTotal && monthlyTotal !== 0 ? (
            <>
              <h5 style={{ margin: 0, fontSize: textSize + 4 }}>
                Monthly grand total
                <span style={{ float: 'right' }}>
                  {numeral(monthlyTotal).format('$0, 0.00')}
                </span>
              </h5>
              {yearlyTotal || oneTimeTotal ? (
                <hr style={{ margin: '4px 0' }} />
              ) : null}
            </>
          ) : null}
          {yearlyTotal && yearlyTotal !== 0 ? (
            <>
              <h5 style={{ margin: 0, fontSize: textSize + 4 }}>
                Yearly grand total
                <span style={{ float: 'right' }}>
                  {numeral(yearlyTotal).format('$0, 0.00')}
                </span>
              </h5>
              {oneTimeTotal ? <hr style={{ margin: '4px 0' }} /> : null}
            </>
          ) : null}
          {oneTimeTotal && oneTimeTotal !== 0 ? (
            <h5 style={{ margin: 0, fontSize: textSize + 4 }}>
              One time grand total
              <span style={{ float: 'right' }}>
                {numeral(oneTimeTotal).format('$0, 0.00')}
              </span>
            </h5>
          ) : null}
        </>
      )}
    </div>
  )
}
