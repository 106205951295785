import React from 'react'
import { UncontrolledPopover, PopoverBody, Badge } from 'reactstrap'

const LabelColumn = props => {
  const { id, services, labels } = props
  if (!id) {
    return null
  }
  const targetId = `labels-${id}`
  let droplets = []
  let badges = []

  const createDropletAndBadge = (name, color, index) => {
    droplets.push(
      <div
        key={`droplet-${index}`}
        style={{
          padding: 4,
          margin: 1,
          backgroundColor: color || 'lightgrey',
          borderRadius: 2
        }}
      >
        {' '}
      </div>
    )

    badges.push(
      <Badge
        pill
        key={`badge-${index}`}
        style={{
          marginTop: 0,
          marginRight: 2,
          backgroundColor: color || 'lightgrey',
          height: 'auto'
        }}
      >
        {name}
      </Badge>
    )
  }

  services && services.length && services.forEach((s, index) => {
    if (!s) {
      return null
    }
    let service = s.service || s
    createDropletAndBadge(service.name, service.labelColor, index)
  })

  labels && labels.length && labels.forEach((label, index) => {
    if (!label) {
      return null
    }
    if (services && services.length) {
      createDropletAndBadge(label.name, label.color, services.length + index)
    } else {
      createDropletAndBadge(label.name, label.color, index)
    }
  })

  return (
    <div
      id={targetId}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0
        }}
      >
        {droplets}
      </div>
      <UncontrolledPopover
        target={targetId}
        trigger="hover"
        placement="top"
        hideArrow
        //modifiers necessary to avoid a popover crash as of reactstrap 8.0.1
        modifiers={{ flip: { behavior: ['top'] } }}
      >
        <PopoverBody>
          {badges}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  )
}

export default LabelColumn