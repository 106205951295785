import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import {
  CropAndUploadField,
  GooglePlacesField,
  InputField
} from 'components/fields'
import ErrorMessage from 'components/ErrorMessage'
import { image, required } from 'utils'

class CompanyForm extends Component {
  static propTypes = {
    initialize: PropTypes.func,
    company: PropTypes.object.isRequired,
    uploads: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    uploads: []
  }

  async componentDidMount() {
    await this.initializeCompany()
  }

  initializeCompany = () => {
    let { company, initialize } = this.props

    const initializedCompany = {
      logo: company && company.logo ? company.logo : null,
      name: company ? company.name : '',
      legalName: company ? company.legalName : '',
      domain: company ? company.domain : '',
      headQuarters: company && company.headQuarters ? company.headQuarters : '',
      phone: company && company.phone ? company.phone.number : ''
    }

    return initialize(initializedCompany)
  }

  render() {
    let { error } = this.props

    let errorMessage = null
    if (error) {
      errorMessage = <ErrorMessage error={error} />
    }

    return (
      <div className="card">
        <div className="card-body">
          <form onSubmit={this.props.handleSubmit}>
            <Field
              name="logo"
              component={CropAndUploadField}
              type="file"
              label="Logo"
              multiple={false}
              validate={image}
            />
            <Field
              name="name"
              component={InputField}
              type="text"
              label="Company Name"
              validate={required}
              warn={required}
            />
            <Field
              name="legalName"
              component={InputField}
              type="text"
              label="Legal Name"
              // validate={[required]}
              // warn={required}
            />
            <Field
              name="domain"
              component={InputField}
              type="text"
              label="Domain"
              // validate={[required]}
              // warn={required}
            />
            <Field
              name="headQuarters"
              component={GooglePlacesField}
              type="text"
              label="Headquarters"
              validate={required}
              warn={required}
              onBlur={this.props.onBlur}
            />
            <Field
              name="phone"
              component={InputField}
              type="phone"
              label="Phone"
              // validate={[required]}
              // warn={required}
            />

            <div
              className="form-group"
              style={{ marginTop: '4rem', textAlign: 'right' }}
            >
              {errorMessage}
              <Button
                type="button"
                onClick={this.props.onCancel}
                style={{ marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Done
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default reduxForm({ form: 'Company' })(CompanyForm)
