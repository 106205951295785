import React from 'react'

import ImageKnob from './knobs/Image'
import Slider from './knobs/Slider'
import Alignment from './knobs/Alignment'
import Text from './knobs/Text'

export default ({ element, proposal, onChange }) => {
  if (!element || !element.data) {
    return null
  }

  return (
    <div>
      <Text
        placeholder="Element Name"
        field="name"
        data={element.data}
        onChange={onChange}
      />
      <Alignment
        label="Alignment"
        data={element.data}
        field="alignment"
        onChange={onChange}
      />
      <ImageKnob
        label="Pick Image"
        field="image"
        data={element.data}
        onChange={onChange}
      />
      <Slider
        label="Size (%)"
        field="size"
        data={element.data}
        onChange={onChange}
      />
    </div>
  )
}
