import objectToQueryString from 'utils/objectToQueryString'

export const getInspections = options => dispatch => {
  let finalOptions = { ...options }

  finalOptions.type = 'Inspection'
  if (!finalOptions.limit) {
    finalOptions.limit = 10000
  }

  var queryString = objectToQueryString(finalOptions)

  return dispatch({
    type: 'FETCH_INSPECTIONS',
    payload: {
      request: {
        url: `/api/jobs/find?${queryString}`
      }
    }
  })
}

export const submitInspection = inspection => {
  if (inspection._id) {
    return updateInspection(inspection)
  } else {
    return createInspection(inspection)
  }
}

export const createInspection = inspection => dispatch =>
  dispatch({
    type: 'CREATE_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs`,
        method: 'post',
        data: inspection
      }
    }
  })

export const cloneInspection = (inspectionId, includePictures) => dispatch =>
  dispatch({
    type: 'CLONE_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspectionId}/clone`,
        method: 'post',
        data: { includePictures }
      }
    }
  })

export const getInspection = inspectionId => dispatch =>
  dispatch({
    type: 'FETCH_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspectionId}`
      }
    }
  })

export const updateInspection = inspection => dispatch =>
  dispatch({
    type: 'UPDATE_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspection._id}`,
        method: 'patch',
        data: inspection
      }
    }
  })

export const cancelInspection = inspectionId => dispatch =>
  dispatch({
    type: 'CANCEL_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspectionId}/cancel`,
        method: 'patch'
      }
    }
  })

export const startInspection = inspectionId => dispatch =>
  dispatch({
    type: 'START_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspectionId}/start`,
        method: 'patch'
      }
    }
  })

export const completeInspection = inspectionId => dispatch =>
  dispatch({
    type: 'COMPLETE_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspectionId}/finish`,
        method: 'patch'
      }
    }
  })

export const archiveInspection = inspectionId => dispatch =>
  dispatch({
    type: 'ARCHIVE_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspectionId}/delete`,
        method: 'patch'
      }
    }
  })
  
export const unarchiveInspection = inspection => dispatch =>
  dispatch({
    type: 'UNARCHIVE_INSPECTION',
    payload: {
      request: {
        url: `/api/jobs/${inspection._id}/unarchive`,
        method: 'patch'
      }
    }
  })

export const emailInspectionReport = (inspection, data) => dispatch => {
  return dispatch({
    type: 'EMAIL_INSPECTION_REPORT',
    payload: {
      request: {
        url: `api/jobs/${inspection._id}/ir-email`,
        data: data,
        method: 'post',
        timeout: 1000 * 45 // Higher timeout as the operation may take longer than others.
      }
    }
  })
}
