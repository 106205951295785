import React from 'react'
import {
  Badge,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { FiTag } from 'react-icons/fi'
import moment from 'moment'
import Attachments from 'components/Attachments'
import LazyImage from 'components/LazyImage'
import BusyButton from 'components/lib/Button/BusyButton'
import classnames from 'classnames'
import ActionsCard from 'components/ActionsCard'
import CustomInfo from 'components/CustomInfo'
import CustomInfoMiniView from 'components/CustomInfoMiniView'
import TemplateClientInfo from 'components/TemplateClientInfo'
import SearchBar from 'components/SearchBar'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import * as acl from 'utils/acl.service'
import styled from 'styled-components'

const CardLink = styled.label`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const LocationCard = ({ location, onRemove, history }) => {
  return (
    <Card body>
      <div>
        <Badge pill color="primary">
          {location.type}
        </Badge>
      </div>
      <CardLink
        onClick={() =>
          history.push(`/dashboard/location-details/${location._id}`)
        }
      >
        {location.name ? (
          <span>
            <strong>{location.name}</strong>
            <span className="text-muted">{'  ・  '}</span>
          </span>
        ) : null}
        {location.address.fullAddress}
      </CardLink>
      <CustomInfoMiniView data={location.customInfo} />
      <Attachments files={location.attachments} />
      <div style={{ marginTop: 10 }}>
        <Button
          size="sm"
          color="primary"
          style={{ marginRight: 5 }}
          onClick={() => history.push(`/dashboard/locations/${location._id}`)}
        >
          Edit
        </Button>
        {acl.isAllowed('locations', 'delete') && (
          <Button
            size="sm"
            color="danger"
            onClick={() => onRemove(location._id)}
          >
            Remove
          </Button>
        )}
      </div>
    </Card>
  )
}

class ClientCard extends React.Component {
  state = {
    activeTab: '1',
    locationSearchTerm: ''
  }

  toggle = number => {
    this.setState({
      activeTab: number
    })
  }

  activeSwitch = async () => {
    const { client, onSubmit } = this.props

    this.setState({activeSwitchBusy: true})
    await onSubmit({
      _id: client._id,
      active: client.active ? false : true
    })
    this.setState({activeSwitchBusy: false})
  }

  render() {
    const props = this.props
    if (!props.client) {
      return null
    }

    const { client, actions } = props
    const { activeSwitchBusy } = this.state

    return (
      <ActionsCard actions={actions} className="company-card">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
          >
            <div>
              <h3>
                {client.logo && (
                  <LazyImage
                    alt={client.name + 'logo'}
                    height="48"
                    src={client.logo.url}
                    style={{ marginRight: 20, borderRadius: 2 }}
                  />
                )}
                {client.name || 'Unnamed Client'}
              </h3>
              {!!client.contact && (
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                  <TemplateClientInfo
                    title="Primary Contact Information"
                    name={client.contact.name}
                    phone={client.contact.phone}
                    email={client.contact.email}
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 12
                }}
              > 
                <BusyButton
                  small
                  disabled={activeSwitchBusy}
                  busy={activeSwitchBusy}
                  size='sm'
                  style={{ marginRight: 8 }}
                  color={client.active ? 'success' : 'warning'}
                  onClick={this.activeSwitch}
                >
                  {client.active ? 'Active' : 'Inactive'}
                </BusyButton>
                <UncontrolledDropdown>
                  <DropdownToggle
                    block
                    style={{ textAlign: 'left', height: 32}}
                    size='sm'
                  >
                    <FiTag /> Labels
                  </DropdownToggle>
                  <DropdownMenu>
                    <LabelEditor
                      data={client.labels}
                      onChange={async data =>
                        this.props.onSubmit({
                          _id: client._id,
                          labels: data
                        })
                      }
                      refreshEntity={this.props.refreshClient}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
                {client &&
                  client.labels &&
                  client.labels.length > 0 &&
                  client.labels.map(label => {
                    return (
                      <Label
                        key={label._id}
                        color={label.color}
                        style={{ marginLeft: 8 }}
                      >
                        {label.name}
                      </Label>
                    )
                  })
                }
              </div>
              <CustomInfo
                data={client.customInfo}
                onEdit={data =>
                  this.props.onSubmit({ ...client, customInfo: data })
                }
                style={{marginTop: 8}}
              />

              <Nav tabs style={{ marginBottom: 30 }}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '1'
                    })}
                    onClick={() => {
                      this.toggle('1')
                    }}
                  >
                    Locations
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this._renderLocationsTab(client.locations, props)}
                </TabPane>
              </TabContent>

              <div className="text-right text-muted small">
                created: {moment(client.createdAt).fromNow()}
              </div>
            </div>
          </div>
        </div>
      </ActionsCard>
    )
  }

  _filterLocation = locations => {
    let searchTerm = this.state.locationSearchTerm
    if (!searchTerm || !searchTerm.trim().length) return locations

    searchTerm = this.state.locationSearchTerm.toLowerCase()

    // Searching by name and address both. Kinda universal search
    return locations.filter(
      location =>
        (location.name && location.name.toLowerCase().includes(searchTerm)) ||
        // Basic assumption that each location will have at least the formatted address
        // to reduce conditional load
        location.address.fullAddress.toLocaleLowerCase().includes(searchTerm)
    )
  }

  _renderLocations = (locations, removeLocation, history) => {
    const _removeLocation = locationId => {
      removeLocation(locationId)
    }

    let result = <span className="text-muted">No Locations</span>
    if (locations && locations.length) {
      result = locations.map((location, index) => {
        return (
          <LocationCard
            key={index}
            location={location}
            onRemove={_removeLocation}
            history={history}
          />
        )
      })
    }

    return result
  }

  _renderLocationsTab = (locations, props) => {
    let { removeLocation, history } = props

    return (
      <div>
        <SearchBar
          style={{ marginBottom: 20 }}
          background={'light'}
          onChange={this._onSearchLocationChange}
          value={this.state.locationSearchTerm}
        />
        {this._renderLocations(
          this._filterLocation(locations),
          removeLocation,
          history
        )}
      </div>
    )
  }

  _onSearchLocationChange = value => {
    this.setState({
      locationSearchTerm: value
    })
  }
}

export default ClientCard
