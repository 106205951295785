import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { Button } from 'reactstrap'
import * as actions from 'api'
import { jobStatus } from 'utils'

import { DropdownField } from 'components/fields'
import { required } from 'utils/validators'
import SelectClientLocation from 'screens/Dashboard/components/forms/SelectClientLocation'

export const JobItem = ({ item }) => {
  if (item) {
    return (
      <span>
        {item.code && item.code + ' - '}
        {item.client && item.client.name}
      </span>
    )
  } else {
    return null
  }
}

class NewProposalSetupForm extends Component {
  render() {
    const {
      walkthroughs,
      estimates,
      selectedField,
      onSubmit,
      goBack
    } = this.props
    const completedWalkthroughs = walkthroughs.filter(
      d => jobStatus(d) === 'completed' && !d.archived && !d.cancelled
    )

    return (
      <form onSubmit={this.props.handleSubmit}>
        {selectedField !== 'client' && (
          <>
            {selectedField === 'walkthrough' && (
              <Field
                name="job"
                component={DropdownField}
                data={completedWalkthroughs}
                itemComponent={JobItem}
                type="select"
                label="Walkthrough"
                textField={item => (item ? item.code : null)}
                valueField="_id"
                normalize={value => (value ? value._id : null)}
                validate={[required]}
                warn={required}
              />
            )}
            {selectedField === 'estimate' && (
              <Field
                name="estimate"
                component={DropdownField}
                data={estimates}
                type="select"
                label="Estimate"
                textField={item => (item ? 'Estimate ' + item.code : null)}
                // valueField="_id"
                // normalize={value => (value ? value : null)}
                validate={[required]}
                warn={required}
              />
            )}
            <Button
              block
              type="submit"
              color="primary"
              style={{ marginTop: 24 }}
            >
              Start
            </Button>
            <Button
              block
              type="button"
              color="secondary"
              style={{ marginTop: 4 }}
              onClick={goBack}
            >
              Back
            </Button>
          </>
        )}
        {selectedField === 'client' && (
          <SelectClientLocation
            formName="NewProposal"
            onSubmit={onSubmit}
            goBack={goBack}
          />
        )}
      </form>
    )
  }
}

const mapStateToProps = state => ({
  values: state.form.NewProposal ? state.form.NewProposal.values : null,
  walkthroughs: state.walkthroughs.walkthroughs
})

export default compose(
  reduxForm({ form: 'NewProposal' }),
  connect(
    mapStateToProps,
    { ...actions, change }
  )
)(NewProposalSetupForm)
