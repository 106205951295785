import React from 'react'
import { capitalize } from 'utils'
import Avatar from '../lib/Avatar'

export const MerchantEmployeeItem = ({ item }) => {
  if (item) {
    const renderEmployeeSkills = skills =>
      skills && skills.length ? skills.join(', ') : 'No Skills Listed'

    const renderEmployeeOption = ({ name, user, role, skills }) => {
      return (
        <>
          <Avatar
            url={user?.avatar?.url || ''}
            size={24}
            name={name?.full || user?.name?.full}
          />
          &nbsp;&nbsp;
          {name?.full || user?.name?.full} ({capitalize(role)}) &nbsp;&nbsp;
          <span className="small text-muted">
            {renderEmployeeSkills(skills)}
          </span>
        </>
      )
    }
    return <span>{renderEmployeeOption(item)}</span>
  } else {
    return null
  }
}
