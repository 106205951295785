import _ from 'lodash'
import { handlerErrors } from 'utils'

const inititalState = {
  employees: [],
  loading: false,
  error: null
}

const replaceEmployee = function(employees, employee) {
  let newList = employees.slice(0)
  let index = _.findIndex(newList, {
    _id: employee._id
  })
  if (index > -1) {
    newList[index] = employee
  } else {
    newList.push(employee)
  }
  return newList
}

const employees = (state = inititalState, action) => {
  switch (action.type) {
    case 'GET_EMPLOYEES': {
      return { ...state, loading: true }
    }
    case 'GET_EMPLOYEES_SUCCESS': {
      let employees = action.payload.data

      employees.forEach(e => {
        if (!e.user) {
          return
        }

        if (!e.name) {
          e.name = {}
        }

        if (!e.name.first) {
          e.name.first = e.user.name.first
        }
        if (!e.name.last) {
          e.name.last = e.user.name.last
        }
        if (!e.name.full) {
          e.name.full = e.user.name.full
        }
      })

      return {
        ...state,
        employees,
        loading: false,
        error: null
      }
    }
    case 'GET_EMPLOYEES_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Getting Employees failed.')
      }
    }
    case 'UPDATE_EMPLOYEE_SUCCESS': {
      return {
        ...state,
        employees: replaceEmployee(state.employees, action.payload)
      }
    }

    case 'REMOVE_EMPLOYEE_SUCCESS': {
      return {
        ...state,
        employees: state.employees.filter(
          employee => employee._id !== action.payload.data._id
        ),
        loading: false,
        error: null
      }
    }
    case 'REMOVE_EMPLOYEE_FAIL': {
      return {
        ...state,
        loading: false,
        error: handlerErrors(action.error.message, 'Removing Employee failed.')
      }
    }
    case 'UNARCHIVE_EMPLOYEE_SUCCESS': {
      return {
        ...state,
        employees: [...state.employees, action.payload]
      }
    }
    case 'CONNECT_SWEPT_SUCCESS': {
      return {
        ...state,
        employees: replaceEmployee(state.employees, action.payload.data)
      }
    }
    case 'DISCONNECT_SWEPT_SUCCESS': {
      return {
        ...state,
        employees: replaceEmployee(state.employees, action.payload.data)
      }
    }
    default:
      return state
  }
}

export default employees
