import React from 'react'
import styled from 'styled-components'


const StatsCard = styled.div`
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${props => props.theme.dashboard.cardBackground};
  ${({containerStyles}) => containerStyles ? containerStyles : ''}
`

const StatTitleContainer = styled.div`
  background-color: ${props => props.theme.dashboard.cardHeaderBackground};
  padding: 10px 0;
  text-align: center;
  height: 40px;
`

const StatTitle = styled.h2`
  margin-bottom: 0;
  color: ${props => props.theme.dashboard.cardTitle};
  font-size: 16px;
  font-weight: 500;
`

const StatCard = ({ title, children, containerStyles, faqComponent }) => {
  return (
    <StatsCard containerStyles={containerStyles}>
      <StatTitleContainer>
        <StatTitle>{title}
          {faqComponent}
        </StatTitle>
      </StatTitleContainer>
      {children}
    </StatsCard>
  )
}

export default StatCard
