import React, { Component } from 'react'
import { connect } from 'react-redux'
import { axiosClient } from 'store'

import { updateProfile } from 'api'
import CompanyForm from './CompanyForm'

class Company extends Component {
  state = {
    companyId: null,
    company: {},
    ready: false
  }

  componentDidMount() {
    this._load()
  }

  _load = async () => {
    const companyId = this.props.match.params.companyId
    if (companyId) {
      const result = await axiosClient.get(`/api/companies/${companyId}`)
      this.setState({
        companyId,
        company: result.data,
        ready: true
      })
    } else {
      this.setState({
        ready: true,
        company: {},
        companyId: null
      })
    }
  }

  _save = async values => {
    let company = values
    if (this.state.companyId) {
      company._id = this.state.companyId
    }

    //    await this.props.submitCompany(company)
    await this.props.updateProfile()
    this.props.history.goBack()
  }

  _onCancel = () => {
    this.props.history.goBack()
  }

  render() {
    let { company, ready } = this.state
    if (!ready) {
      return null
    }

    const title = company ? `Update ${company.name}` : 'New Company'

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <h1 className="text-light">{title}</h1>
        <CompanyForm
          onSubmit={this._save}
          onCancel={this._onCancel}
          company={company}
        />
      </div>
    )
  }
}

export default connect(
  null,
  { updateProfile }
)(Company)
