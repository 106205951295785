import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

// TODO: Baaaad!
import ClientForm from '../../Clients/Simple/ClientForm'

export default ({ isOpen, toggle, submitClient, initialValues, _id }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" autoFocus={false}>
      <ModalHeader toggle={toggle}>
        {_id ? 'Edit Client' : 'New Client'}
      </ModalHeader>
      <ModalBody>
        <ClientForm
          onSubmit={submitClient}
          initialValues={initialValues}
          _id={_id}
          closeModal={toggle}
        />
      </ModalBody>
    </Modal>
  )
}
