import React from 'react'
import { CustomInput } from 'reactstrap'
import Label from './Label'

export default ({
  label,
  data,
  field,
  onChange,
  min,
  max,
  step,
  id,
  bottomLabels,
  ...rest
}) => (
  <div className="slider-knob" style={{ marginBottom: 8 }} {...rest}>
    <Label text={label} />
    <CustomInput
      id={id || 'slider-knob'}
      style={{ width: '100%' }}
      type="range"
      min={min || 0}
      max={max || 100}
      step={step || 1}
      value={data[field]}
      onChange={event => {
        let newData = {
          ...data
        }
        newData[field] = event.target.value
        onChange(newData)
      }}
    />
    {bottomLabels && bottomLabels.left && bottomLabels.right && (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>{bottomLabels.left}</p>
        <p>{bottomLabels.right}</p>
      </div>
    )}
  </div>
)
