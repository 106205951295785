import React, { useState, useEffect } from 'react'
import { axiosClient } from 'store'
import styled from 'styled-components'
import { DatePicker, DropdownList } from 'react-widgets'
import moment from 'moment'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { capitalize } from 'utils'
import { CSVLink } from "react-csv";
import Select2 from 'components/select2/select2'

const Container = styled.div`
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 6px 12px #000;
  margin: 40px 20px;
  padding: 20px;
  width: auto;
  height: 80vh;
  overflow: auto;
`

const FiltersContainer = styled.div`
  padding : 0px 40px;
  display : flex;
  justify-content : space-between;
  align-items : end;
  column-gap : 1rem;
`

const InputsContainer = styled.div`
  flex-grow : 1;
  display : flex;
  column-gap : 1rem;
`

const SearchButtonContainer = styled.div`
  /* flex-grow : 1; */
`

const MerchantsFilterContainer = styled.div`

`

const CSVLinkContainer = styled.div`
  width: 100%;
  padding-left: 40px;
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Label = styled.span`
  margin-right: 4px;
`

const DataCardGrid = styled.div`
  display: grid;
  width: 100%;
  /* grid-template-columns: 1fr 1fr 1fr; */
  gap: 16px;
  padding: 40px;
  margin-top: 6rem;
`

const DataCard = styled.div`
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0, 0, 0, 0.4);
`

const DataCardHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`

const COMPANY_INFO = ['state', 'city', 'employees', 'clients', 'locations', 'companyCreationDate']

export const AdminDashboard = () => {

  const [startDate, setStartDate] = useState(moment().subtract(1, 'years').toISOString())
  const [endDate, setEndDate] = useState(moment().toISOString())
  const [data, setData] = useState([])
  const [dataFetching, setDataFetching] = useState(false)
  const [merchantsFetching, setMerchantsFetching] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [selectedMerchant, setSelectedMerchant] = useState(null)
  const [csvData, setCsvData] = useState([[]])

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        setMerchantsFetching(true)
        const { data } = await axiosClient.get(`/admin/merchant-list`)
        setMerchantsFetching(false)

        if (data) {
          const firstOption = {
            _id: null,
            name: 'All'
          }
          data.unshift(firstOption)
          setMerchants(data)
        }
      } catch (err) {
        toast(err.message)
      }
    }
    fetchMerchants()
  }, [])

  useEffect(() => {

    let csv = [[
      'Company Name',
      'Company ID',
      'Owner First Name',
      'Owner Last Name',
      'Email',
      'State',
      'City',
      'Phone',
      'Company Creation Date',
      'Walkthroughs',
      'Inspections',
      'Proposals',
      'Estimates'
    ]]

    data.forEach(merchant => {
      const { state, city, companyCreationDate } = merchant
      const owner = merchant.owner
      const firstName = owner && owner.name && owner.name.first
      const lastName = owner && owner.name && owner.name.last
      const email = owner && owner.email
      const phone = owner && owner.phone

      csv.push([
        merchant.name,
        merchant.id,
        firstName,
        lastName,
        email,
        state,
        city,
        phone,
        companyCreationDate,
        merchant.walkthroughs,
        merchant.inspections,
        merchant.proposals,
        merchant.estimates
      ])
    })
    setCsvData(csv)
  }, [data])

  const totals = data[0]
  const totalsKeys = totals && Object.keys(totals)

  const getData = async () => {
    if (!startDate || !endDate) {
      toast('Need start and end dates')
      return
    }

    setDataFetching(true)

    try {
      let url = `/admin/info?startDate=${startDate}&endDate=${endDate}`
      const merchantId = selectedMerchant && selectedMerchant._id
      if (merchantId) {
        url += `&merchant=${merchantId}`
      }
      const { data } = await axiosClient.get(url, {
        timeout: 1000 * 120,
      })

      setData(data)
      setDataFetching(false)
    } catch (ex) {
      console.error(ex)
      setDataFetching(false)
    }
  }

  let RenderedTotals = () => (<p>No totals to display</p>)

  if (dataFetching) {
    RenderedTotals = () => (<p>Fetching Data...</p>)
  } else if (totalsKeys && totalsKeys.length) {
    RenderedTotals = ({ type, component }) => {

      let companyInfo = {}
      let totalsInfo = {}

      for (const key in totals) {

        if (key != 'owner' && key != 'name' && key != 'id') {
          if (COMPANY_INFO.includes(key)) {
            companyInfo = { ...companyInfo, [key]: totals[key] }
          } else {
            totalsInfo = { ...totalsInfo, [key]: totals[key] }
          }
        }
      }

      if (type === 'totals') {
        return (
          <div key={type}>
            {

              Object.keys(totalsInfo).map(key => {
                return (
                  <RowInfo key={key} keyValue={key} totals={totalsInfo[key]} />
                )
              })
            }
          </div>
        )
      } else {
        return (
          <div key={type}>
            {
              Object.keys(companyInfo).map(key => {
                return (
                  <RowInfo key={key} keyValue={key} totals={companyInfo[key]} />
                )
              })
            }
          </div>
        )
      }
    }
  }



  const handleClick = (event) => {
    const inputElement = event.target

    if (inputElement.tagName === 'INPUT') {
      const spanElement = inputElement.nextElementSibling
      const datePickerElement = spanElement.querySelector("button[title='Select date']")

      if (datePickerElement !== null) {
        datePickerElement.click()
      }
    }
  }

  const handleKeyDown = (event) => {

    event.preventDefault()


  }

  return (
    <Container>
      <FiltersContainer>
        <InputsContainer>
          <div className='col-4 p-0'>
            <h5>Merchants</h5>
            <Select2
              data={merchants}
              handleChange={value => setSelectedMerchant(value)}
              name={'name'}
              placeholder="All"
              filter='contains'
            />
          </div>
          <div className='col-7 p-0 d-flex justify-content-around'>
            <DateContainer>
              <h5>Date Start:</h5>
              <DatePicker
                format={'LL'}
                value={moment(startDate).toDate()}
                onKeyPress={e => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                onChange={value => setStartDate(value)}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
              />
            </DateContainer>
            <DateContainer>
              <h5>Date End:</h5>
              <DatePicker
                format={'LL'}
                value={moment(endDate).toDate()}
                onKeyPress={e => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                onChange={value => setEndDate(value)}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
              />
            </DateContainer>
          </div>
          {/* <DropdownList
          data={merchants}
          dataKey='_id'
          textField='name'
          busy={merchantsFetching}
          onChange={value => setSelectedMerchant(value)}
          value={selectedMerchant}
          placeholder="Merchants"
          style={{width: '100%'}}
        /> */}

        </InputsContainer>
        <SearchButtonContainer>
          <Button onClick={getData}>Go</Button>
        </SearchButtonContainer>

      </FiltersContainer>
      <div style={{ display: 'flex' }}>
        <DataCardGrid>
          <DataCard>
            <DataCardHeader>
              <h3>Company Info</h3>
              <CSVLink data={csvData} filename='route-data.csv'>Download CSV</CSVLink>
            </DataCardHeader>
            <hr />
            <RenderedTotals type='company' />
          </DataCard>
        </DataCardGrid>

        <DataCardGrid>
          <DataCard>
            <DataCardHeader>
              <h3>Totals</h3>
              {/* <CSVLink data={csvData} filename='route-data.csv'>Download CSV</CSVLink> */}
            </DataCardHeader>
            <hr />
            <RenderedTotals type='totals' />
          </DataCard>
        </DataCardGrid>
      </div>
    </Container>
  )
}


const RowInfo = ({ keyValue, totals }) => {
  let element = keyValue == 'companyCreationDate'
    ? <p key={keyValue} style={{ marginBottom: 4 }}><strong>{'Company creation date'}:</strong> {totals}</p>
    : <p key={keyValue} style={{ marginBottom: 4 }}><strong>{capitalize(keyValue)}:</strong> {totals}</p>
  return (
    <>
      {element}
    </>
  )
}