import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'

import {
  CropAndUploadField,
  InputField,
  TimezoneField
} from 'components/fields'

import { image, required } from 'utils'

class ProfileForm extends Component {
  static propTypes = {
    initialize: PropTypes.func,
    profile: PropTypes.object.isRequired
  }

  static defaultProps = {
    role: 'user'
  }

  async componentDidMount() {
    let { profile } = this.props

    if (profile) {
      await this.initializeProfile()
    }
  }

  initializeProfile = () => {
    let { profile, initialize } = this.props

    return initialize(profile)
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form onSubmit={this.props.handleSubmit}>
            <Field
              name="email"
              component={InputField}
              type="text"
              label="Email Address"
              validate={required}
              warn={required}
            />
            <Field
              name="phone"
              component={InputField}
              type="phone"
              label="Phone"
              // validate={[required]}
              // warn={required}
            />
            <Field
              name="timezone"
              component={TimezoneField}
              type="select"
              label="Timezone"
              normalize={value => (value ? value.name : null)}
            />
            <Field
              name="avatar"
              component={CropAndUploadField}
              type="file"
              label="Avatar"
              multiple={false}
              validate={image}
            />
            <div className="form-group" style={{ marginTop: '4rem' }}>
              <button type="submit" className="btn btn-primary">
                Done
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm({ form: 'Profile' }),
  connect(null)
)(ProfileForm)
