export const jobStatus = job => {
  let status = 'open'

  if (job.team && job.team.length) {
    status = 'assigned'
  }
  if (job.startedAt) {
    status = 'in progress'
  }
  if (job.completedAt) {
    status = 'completed'
  }
  if (job.cancelled) {
    status = 'cancelled'
  }

  return status
}