import React, { Component } from 'react'
import { connect } from 'react-redux'

import TasksForm from './TasksForm'
import AreaTasksForm from './AreaTasksForm'

class TasksField extends Component {
  state = {
    isNewJob: true
  }

  _getValues = () => {
    return this.props.values
  }

  _setIsNewToFalse = () => {
    this.setState({
      isNewJob: false
    })
  }

  renderTypesOfAreas = (areas, frequencies) => {
    let areaTypeCounts = {}
    ;(areas || []).forEach(area => {
      if (areaTypeCounts[area.type]) {
        areaTypeCounts[area.type]++
      } else {
        areaTypeCounts[area.type] = 1
      }
    })

    let areaTypes = []

    Object.keys(areaTypeCounts).forEach(areaType => {
      areaTypes.push({
        tasks: [{ frequency: frequencies[0] }],
        name: areaType,
        count: areaTypeCounts[areaType]
      })
    })

    return (
      <AreaTasksForm
        initialValues={{ areaTypes }}
        handleKeyDown={this._handleKeyDown}
        getValues={this._getAreaTypesValues}
        isNewJob={this.state.isNewJob}
        setIsNewToFalse={this._setIsNewToFalse}
        frequencies={this.props.frequencies}
        areaTypeCounts={areaTypeCounts}
        {...this.props}
      />
    )
  }

  _getAreaTypesValues = () => {
    return this.props.areaTasksvalues
  }

  render() {
    const { frequencies, job, label, type } = this.props
    let initialTaskValues

    if (frequencies) {
      // for Proposal tasks with frequencies
      const jobTasks =
        job &&
        job.tasks &&
        job.tasks.map(task => ({ ...task, frequency: frequencies[0] }))
      initialTaskValues =
        job && job.tasks
          ? {
              tasks: [...jobTasks, { frequency: frequencies[0] }]
            }
          : { tasks: [{ frequency: frequencies[0] }] }
    } else {
      initialTaskValues =
        job && job.tasks
          ? { tasks: [...this.props.job.tasks, {}] }
          : { tasks: [{}] }
    }

    return (
      <>
        {label && <label>{label}</label>}
        {job && job.areas && type === 'areaTasks' ? (
          this.renderTypesOfAreas(job.areas, frequencies)
        ) : (
          <TasksForm
            initialValues={initialTaskValues}
            handleKeyDown={this._handleKeyDown}
            getValues={this._getValues}
            isNewJob={this.state.isNewJob}
            setIsNewToFalse={this._setIsNewToFalse}
            frequencies={frequencies}
            {...this.props}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  values: state.form.Tasks ? state.form.Tasks.values : null,
  areaTasksvalues: state.form.AreaTasks ? state.form.AreaTasks.values : null
})

export default connect(
  mapStateToProps,
  {}
)(TasksField)
