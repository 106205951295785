import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'reactstrap'
import _ from 'lodash'

import { MultiSelectField } from 'components/fields'

export const ServiceItem = ({ item }) => {
  if (item) {
    return <span>{item.name && item.name}</span>
  } else {
    return null
  }
}

const exists = (value, allValues, props) => {
  if (!value) {
    return
  }

  const result =
    value && props && props.services
      ? _.differenceBy(value, props.services, '_id')
      : null

  return result && result.length
    ? `${
        result.length
      } of the selected walkthrough's services do not match your templates. Please select them manually.`
    : undefined
}

class SelectServiceForm extends Component {
  render() {
    const { goBack, services } = this.props

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          name="services"
          component={MultiSelectField}
          data={services}
          itemComponent={ServiceItem}
          type="select"
          label="Services"
          textField={item => (item && item.name ? item.name : null)}
          normalize={value => (value ? value : null)}
          validate={exists}
          forceValidate={true}
          filterInputs={true}
        />
        <Button block type="submit" color="primary" style={{ marginTop: 24 }}>
          Next
        </Button>
        <Button
          block
          type="button"
          color="secondary"
          style={{ marginTop: 4 }}
          onClick={goBack}
        >
          Back
        </Button>
      </form>
    )
  }
}

export default reduxForm({
  form: 'NewEstimate',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(SelectServiceForm)
