
export const renderCustomPieChartLabel = ({ cx, cy, x, y, midAngle, innerRadius, outerRadius, percent, value, index, ...props }) => {
  if (percent < 0.02) return null

  return (
    <text x={x} y={y} fontSize={14} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${parseInt(percent * 100)}%`}
    </text>
  );
};

export const renderCustomPieChartLabelLine = ({points, percent}) => {
  let path = ''
  if (percent < 0.02) return null
  points.forEach((point, index) => {
    path += `${point.x},${point.y} `
  })

  return (
    <polyline points={path} stroke={'white'} fill="none" />
  );
}
