import placeSchema from './placeSchema'

export const googlePlaceToAddress = (place) => {
  let countryCode
  let number

  if (!place.geometry) {
    return
  }

  if (place.hasOwnProperty('formatted_phone_number')) {
    countryCode = '+1'
    number = place.formatted_phone_number.replace('+1', '')
  } else {
    countryCode = ''
    number = ''
  }

  const latitude = place.geometry.location.lat()
  const longitude = place.geometry.location.lng()

  let address = formatLocation(
    place.address_components.reduce(
      (loc, component) => ({
        ...loc,
        id: place.place_id,
        formattedAddress: place.formatted_address,
        latitude,
        longitude,
        countryCode,
        number,
        googleMapLink: place.url,
        [component.types[0]]: component.short_name
      }),
      {}
    )
  )

  return address
}

const formatLocation = formattedPlace =>
  Object.keys(formattedPlace).reduce(
    (formatted, key) => ({
      ...formatted,
      ...{ [placeSchema[key] || key]: formattedPlace[key] }
    }),
    {}
  )