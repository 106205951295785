import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import {
  Alert,
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import { axiosClient } from 'store'
import { connectConfirmation } from 'components/confirm'
import { BusyButton } from 'components/lib/Button'
import { toast } from 'react-toastify'

import SelectPlan from './SelectPlan'
import CancelationModal from './components/CancelationModal'

const PLAN_FEATURES = {
  starter: [
    '3 employees',
    'Unlimited Walkthroughs',
    'Unlimited Estimates',
    '5 Proposals per month'
  ],
  growth: [
    'Employees',
    'Walkthroughs',
    'Estimates',
    'Proposals',
  ]
}

const PaymentLinkWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`

const PlanCard = ({planName, changePlan}) => {
  console.log(changePlan)
  return (
    <Card body>
      <div style={{display : 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
        <div className="small text-muted">Current Plan</div>
        <Button color='link' onClick={changePlan}>Change Plan</Button>
      </div>
      <div>
        <h4 style={{ textTransform: 'capitalize' }}>{planName} Plan</h4>
      </div>
      <hr style={{border: '1px solid black', marginTop: '0.5rem'}} />
      {PLAN_FEATURES[planName] ? (
        <ul>
          {PLAN_FEATURES[planName].map((feature, index) => (
            <li key={`feature-${index}`}>{feature}</li>
          ))}
        </ul>
      ) : null}
    </Card>
  )
}

class Billing extends Component {
  state = {
    data: null,
    fetching: true,
    showCancelModal: false
  }

  async componentDidMount() {
    const { data } = await axiosClient.get('/api/companies/billing')
    if (data) {
      this.setState({
        data,
        fetching: false
      })
    }
  }

  toggleModal = () => {
    this.setState({
      showUndoCancelModal: !this.state.showUndoCancelModal
    })
  }

  terminateSubscription = async (feedback) => {
    const { currentEmployment } = this.props
    let cancelData = {}
    cancelData.email = currentEmployment && currentEmployment.email
    cancelData.feedback = feedback
    if (cancelData.email) {
      const res = await axiosClient.post('/api/companies/billing/cancel-subscription', cancelData)
      
      if (res && res.data) {
        const { data } = await axiosClient.get('/api/companies/billing')
        if (data) {
          this.setState({
            data,
            fetching: false
          })
        }
        toast('Subscription successfully cancelled.')
      }
    }
  }

  undoSubscriptionCancel = async () => {
    const { currentEmployment } = this.props
    let email = currentEmployment && currentEmployment.email

    if (email) {
      this.setState({
        undeoCancelBusy: true
      })
      
      const res = await axiosClient.post('/api/companies/billing/undo-cancellation', {email})
      if (res && res.data) {
        await this.setState({
          undeoCancelBusy: false,
          showUndoCancelModal: false
        })
        const { data } = await axiosClient.get('/api/companies/billing')
        if (data) {
          this.setState({
            data,
            fetching: false
          })
        }
        toast('Subscription cancellation successfully undone.')
      }
    }
  }

  _togglePlanModal = () => {
    const { currentEmployment } = this.props
    const isOpen = this.state.planModalOpen
    if (!isOpen && currentEmployment.role !== 'owner') {
      return toast('Only company owner can change plans.')
    }
    this.setState({
      planModalOpen: !isOpen
    })
  }

  _changePaymentMethod = async (customerId, subscriptionId) => {
    const { currentEmployment } = this.props
    if (currentEmployment.role !== 'owner') {
      return toast('Only company owner can change plans.')
    }
    const session = await axiosClient.post('/api/companies/billing/checkout-session', {
      customerId,
      subscriptionId
    })
    if (session?.data?.url) {
      window.location.replace(session.data.url)
    }
  }

  _toggleCancelModal = () => {
    const isOpen = this.state.showCancelModal

    this.setState({
      showCancelModal: !isOpen
    })
  }

  _redirectToTOS = () => {
    window.location.href = 'https://getroute.com/termsandconditions'
  }

  _redirectToPP = () => {
    window.location.href = 'https://www.iubenda.com/privacy-policy/32463973'
  }

  render() {
    const { data, fetching, showUndoCancelModal, planModalOpen, showCancelModal } = this.state
    const { currentEmployment } = this.props
    console.log('showCancelModal', showCancelModal)
    if (fetching) {
      return null
    }

    let stripe
    let subscription
    let customer
    let plan
    let planName
    let trialEnds
    let isInTrial
    let cancelAt
    let willCancel
    let employeeEmail = currentEmployment.email || currentEmployment.user.email
    let customerEmail
    let paymentMethod

    if (data) {
      stripe = data.stripe
      subscription = stripe?.subscription
      customer = stripe?.customer
      if (subscription?.plan) {
        plan = subscription.plan
        if (plan) {
          planName = plan.nickname
          if (plan?.metadata?.tier) {
            planName = plan.metadata.tier
          }
        }
      }

      paymentMethod = customer?.invoice_settings?.default_payment_method?.card || customer?.default_source

      if (customer?.email) {
        customerEmail = customer.email
      }
      if (subscription) {
        isInTrial = subscription.status === 'trialing'
        if (subscription.trial_end) {
          trialEnds = moment.unix(subscription.trial_end).format("LL")
        }
        willCancel = subscription.cancel_at_period_end
        if (subscription.cancel_at) {
          cancelAt = moment.unix(subscription.trial_end).format("LL")
        }
      }
    }

    let renderedCancelOrTrialDate = null

    if (willCancel) {
      renderedCancelOrTrialDate = (
        <>
          <div className="small text-muted">Subscription ending on</div>
          <h4>{cancelAt}</h4>
        </>
      )
    } else if (isInTrial) {
      renderedCancelOrTrialDate = (
        <>
          <div className="small text-muted">Trial Ends</div>
          <h4>{trialEnds}</h4>
        </>
      )
    }

    return (
      <Container style={{ marginTop: 20 }}>
        {!stripe && (
          <Alert color="danger">
            Your billing details could not be found. Please contact{' '}
            <a className="alert-link" href="mailto:support@getroute.com">
              customer support
            </a>{' '}
            to get this sorted out.
          </Alert>
        )}
        {stripe && (
          <Row>
            {planName && (
              <Col>
                <PlanCard planName={planName} changePlan={this._togglePlanModal}/>
                {/* <Button color='link' onClick={this._togglePlanModal}>Change Plan</Button> */}
              </Col>
            )}
            {plan && (
              <Col>
                <Card body>
                  <div className="small text-muted">Current Bill</div>
                  <h4>
                    {Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(plan.amount / 100)} / {plan.interval}
                  </h4>
                </Card>
                {/* <Button color='link' onClick={this._togglePlanModal}>Change Plan</Button> */}
                <Button color='link' onClick={this._redirectToTOS}>Terms of Service</Button>
                <Button color='link' onClick={this._redirectToPP}>Privacy Policy</Button>
              </Col>
            )}
            {isInTrial && (
              <Col>
                <Card body>
                  {renderedCancelOrTrialDate}
                </Card>
              </Col>
            )}
          </Row>
        )}
        {paymentMethod && (
          <Row>
            <Col>
              <Card body>
                <div className="small text-muted">Payment Method</div>
                <h4>
                  {paymentMethod.brand}{' '}
                  {paymentMethod.last4 ? '••••' + paymentMethod.last4 : null}
                </h4>
                {paymentMethod.exp_month && (
                  <div className="small text-muted">
                    Expires: {paymentMethod.exp_month}/{paymentMethod.exp_year}
                  </div>
                )}
                <PaymentLinkWrapper>
                  <Button color='link' onClick={() => this._changePaymentMethod(customer?.id, subscription?.id)}>Change Payment Method</Button>
                </PaymentLinkWrapper>
              </Card>
            </Col>
            <Col />
          </Row>
        )}

        {/* TODO: display some of this info nicely
        <Card body>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Card> 
        */}

        {(employeeEmail === customerEmail) && (
          <Card style={{ width: '30%' }} body>
            {willCancel
              ? (                
                <Button color="warning" onClick={this.toggleModal}>
                  Undo subscription cancellation
                </Button>
              ) : (
                <Button color="danger" onClick={this._toggleCancelModal}>
                  Terminate Route Subscription
                </Button>
              )
            }

          </Card>
        )}
        {showCancelModal ? (
          <CancelationModal
            isOpen={showCancelModal}
            cancelSubscription={this.terminateSubscription}
            closeModal={this._toggleCancelModal}
          />
        ) : null}
        {showUndoCancelModal ? 
          <Modal isOpen={showUndoCancelModal} style={{paddingTop: 200}}>
            <ModalHeader
              toggle={this.toggleModal}
            >
              Undo Subscription Cancellation
            </ModalHeader>
            <ModalBody>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: 16}}>
                <BusyButton
                  busy={this.state.undeoCancelBusy}
                  color="warning"
                  onClick={this.undoSubscriptionCancel}
                >
                  Undo Cancellation
                </BusyButton>
              </div>
            </ModalBody>
          </Modal>
        : null}
        {planModalOpen ?
          <Modal size='lg' isOpen={planModalOpen}>
            <ModalHeader
              toggle={this._togglePlanModal}
            >
              Select Plan
            </ModalHeader>
            <ModalBody>
              <SelectPlan stripeCustomer={customer} stripeSubscription={subscription} onCard />
            </ModalBody>
          </Modal>
        : null}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: state.user.currentCompany,
  currentEmployment: state.user.currentEmployment
})

export default connect(mapStateToProps)(connectConfirmation(Billing))
export const SelectPlanPage = SelectPlan
