import React from 'react'
import LogoDropZone from '../../../../components/LogoDropZone'

import Text from './knobs/Text'
import Color from './knobs/Color'
import Boolean from './knobs/Boolean'
import Number from './knobs/Number'

export default ({ element, proposal, onChange }) => {
  if (!element || !element.data) {
    return null
  }

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <Boolean
          label="Company Logo"
          field="showMerchantLogo"
          data={element.data}
          onChange={onChange}
        />
        <LogoDropZone
          style={{ backgroundColor: '#fff' }}
          value={element.data.merchantLogo}
          onDrop={logo => {
            return onChange({
              ...element.data,
              merchantLogo: logo
            })
          }}
        />
      </div>
      <div>
        <Text
          label="Title"
          field="title"
          data={element.data}
          onChange={onChange}
        />
        <Boolean
          label="Show Proposal Code"
          field="showProposalCode"
          data={element.data}
          onChange={onChange}
        />
        {element.data.showProposalCode && (
          <Text
            label="Custom Proposal Code"
            field="customProposalCode"
            data={element.data}
            onChange={onChange}
          />
        )}
        <Boolean
          label="Show Proposal Date"
          field="showProposalDate"
          data={element.data}
          onChange={onChange}
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <Boolean
          label="Client logo:"
          field="showClientLogo"
          data={element.data}
          onChange={onChange}
        />
        <LogoDropZone
          style={{ backgroundColor: '#fff' }}
          value={element.data.clientLogo}
          onDrop={logo => {
            return onChange({
              ...element.data,
              clientLogo: logo
            })
          }}
        />
      </div>
      <div>
        <Text
          label="Client Address"
          field="clientAddress"
          data={element.data}
          onChange={onChange}
        />
      </div>

      <div>
        <Color
          label="Background Color"
          field="headerColor"
          data={element.data}
          onChange={onChange}
        />
      </div>
      <div>
        <Color
          label="Text Color"
          field="headerTextColor"
          data={element.data}
          onChange={onChange}
        />
      </div>
      <Number
        label="Height"
        data={element.data}
        step={1}
        min={0}
        max={400}
        field="letterheadHeight"
        onChange={onChange}
      />
    </div>
  )
}
