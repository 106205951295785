import { useState } from 'react';
import { Button } from 'reactstrap';
import { ItemRow, ItemValueContainer, StyledDropdown, ErrorMessageText } from './ListStyles';
import GooglePlaceInput from '../../../../../../../components/GooglePlaceInput';
import { LocationTypeArray } from '../../../../../../../data';

export const LocationRejectedItem = ({ item, onSubmit, index }) => {
  const [itemAddress, setItemAddress] = useState(item.address);
  const [itemLocationType, setItemLocationType] = useState(item.locationType);
  
  const handleSave = () => {
    onSubmit({
      ...item,
      address: item.address || itemAddress,
      locationType: item.locationType || itemLocationType,
    }, index);
  }

  const disableSave = !itemAddress || !itemLocationType;
  const randId = Math.random().toString(20).substring(2, 6)
  return (
    <ItemRow>
      <ItemValueContainer width='200px'>{item.locationName}</ItemValueContainer>
      <ItemValueContainer width='400px'>
      {!item.address ? (
        <>
          <GooglePlaceInput
            value={item.address}
            onChange={(value) => {
              setItemAddress(value);
            }}
            placeholder={itemAddress?.formattedAddress || 'Enter address'}
            inputId={`address${randId}`}
          />
          {!itemAddress ? (
            <ErrorMessageText>Enter a valid address</ErrorMessageText>
          ) : null}
        </>
      ) : (
        <>{item.address.formattedAddress}</>
      )}
      </ItemValueContainer>
      <ItemValueContainer width='250px'>
        {item.locationType ? (
          <>{item.locationType}</>
        ) : (
          <>
            <StyledDropdown
              name="locationType"
              data={LocationTypeArray}
              placeholder="Select location type"
              value={itemLocationType}
              onChange={(value) => {
                setItemLocationType(value);
              }}
            />
            {!itemLocationType ? (
              <ErrorMessageText>Select a location type</ErrorMessageText>
            ) : null}
          </>
        )}
      </ItemValueContainer>
      <ItemValueContainer width='100px'>
        <Button onClick={handleSave} disabled={disableSave}>Save</Button>
      </ItemValueContainer>
    </ItemRow>
  )
}