import React from 'react'

export default props => (
  <svg width={16} height={18} {...props}>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Walkthrough-Icon">
        <g id="Group">
          <path
            d="M8.00526316,0 L8.00526316,3.03157895 C8.00526316,3.13622237 8.09009342,3.22105263 8.19473684,3.22105263 L9.14210526,3.22105263 C9.24674869,3.22105263 9.33157895,3.13622237 9.33157895,3.03157895 L9.33157895,0 L16.0578947,0 C16.188699,-2.40283569e-17 16.2947368,0.106037822 16.2947368,0.236842105 L16.2947368,12.0319063 C16.2646295,12.0117531 16.2284241,12 16.1894737,12 L11.5947368,12 C11.4900934,12 11.4052632,12.0848303 11.4052632,12.1894737 L11.4052632,13.1368421 C11.4052632,13.2414855 11.4900934,13.3263158 11.5947368,13.3263158 L16.1894737,13.3263158 C16.2284241,13.3263158 16.2646295,13.3145627 16.2947368,13.2944095 L16.2947368,17.7631579 C16.2947368,17.8939622 16.188699,18 16.0578947,18 L5.31052632,18 L5.31052632,13.4526316 C5.31052632,13.3479882 5.22569606,13.2631579 5.12105263,13.2631579 L4.45789474,13.2631579 C4.35325131,13.2631579 4.26842105,13.3479882 4.26842105,13.4526316 L4.26842105,18 L0.236842105,18 C0.106037822,18 5.15619266e-16,17.8939622 4.99600361e-16,17.7631579 L2.87607775e-16,10.3263158 L8.05263158,10.3263158 C8.07775817,10.3263158 8.10174241,10.3214248 8.12368421,10.312543 C8.14562601,10.3214248 8.16961025,10.3263158 8.19473684,10.3263158 L9.14210526,10.3263158 C9.24674869,10.3263158 9.33157895,10.2414855 9.33157895,10.1368421 L9.33157895,7.2 C9.33157895,7.09535657 9.24674869,7.01052632 9.14210526,7.01052632 L8.19473684,7.01052632 C8.09009342,7.01052632 8.00526316,7.09535657 8.00526316,7.2 L8.00526316,9 L2.22044605e-16,9 L0,0.236842105 C-1.60189046e-17,0.106037822 0.106037822,-3.36794126e-16 0.236842105,-3.60822483e-16 L8.00526316,0 Z"
            id="Combined-Shape"
            fill={props.color}
          />
          <path
            d="M5.25,18 C5.25,15.4100752 7.34954888,13.3105263 9.93947368,13.3105263 L9.93947368,18 L5.25,18 Z"
            id="Doorway"
            fill="#D5D5D5"
            transform="translate(7.594737, 15.655263) scale(-1, 1) translate(-7.594737, -15.655263) "
          />
        </g>
      </g>
    </g>
  </svg>
)
