import styled from 'styled-components'

// export const Questions = styled.ol`
//   list-style: none;
//   width: 90%;

//   @media screen and (min-width: 32rem) {
//     width: 80%;
//   }
// `

export const Questions = styled.ol`
  list-style: none;
  width: 100%;
`

export const Question = styled.li`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  fieldset {
    text-align: center;
  }
`
