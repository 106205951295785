import moment from 'moment'

const getTimezone = async (latitude, longitude) => {
  console.log('is timezone running')
  if (!latitude || !longitude) {
    return null
  }

  const timestamp = moment().format('X')
  const TIMEZONE_API_URL = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  }`

  const timezone = await fetch(TIMEZONE_API_URL)

  if (timezone.status === 'OK' || 200) {
    delete timezone.status
  } else {
    console.error('timezone error: ', timezone)
    return
  }
  return timezone.json()
}

export default getTimezone