import React from 'react'
import { Badge, Card, CardTitle } from 'reactstrap'
import _ from 'lodash'

const BadgeSection = ({
  data,
  filteredData,
  setFilterData,
  filterField,
  sectionTitle,
  colorField,
  switchSection
}) => {
  if (!data || !data.length) {
    return null
  }

  return (
    <Card body>
      {sectionTitle ? <CardTitle>{sectionTitle}</CardTitle> : null}
      <div style={{ display: 'flexwrap', flexDirection: 'row' }}>
        {data.map((item, index) => {
          const badgeColor = item[colorField] || 'grey'
          const isFiltered = _.includes(filteredData, item[filterField])
          return (
            <Badge
              pill
              key={`badge-${index}`}
              style={{
                marginTop: 0,
                marginRight: 4,
                border: `1px solid ${badgeColor}`,
                color: isFiltered ? 'white' : badgeColor,
                backgroundColor: isFiltered ? badgeColor : 'white',
                height: 'auto',
                cursor: 'pointer'
              }}
              onClick={() => {
                let newFilteredData = switchSection ? [] : [...filteredData]
                if (isFiltered) {
                  newFilteredData = _.filter(
                    newFilteredData,
                    d => d !== item[filterField]
                  )
                } else {
                  newFilteredData.push(item[filterField])
                }
                setFilterData(newFilteredData)
              }}
            >
              {item.name}
            </Badge>
          )
        })}
      </div>
    </Card>
  )
}

export default BadgeSection
