import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as Fa from 'react-icons/fa'
import * as Md from 'react-icons/md'
import * as Ti from 'react-icons/ti'
import * as Go from 'react-icons/go'
import * as Io from 'react-icons/io'
import * as Tb from "react-icons/tb"

const packs = { Fa, Go, Io, Md, Ti, Tb }

export const StyledIcon = styled.span`
  display: inline-block;
  background: ${props => (props.background ? props.background : 'transparent')};
  margin: ${props => (props.margin ? props.margin : '0')};
  padding: ${props => (props.padding ? props.padding : '0')};
  text-align: center;
`

const Icon = props => {
  const { icon } = props
  if (!icon) {
    return null
  }
  let pack = icon.slice(0, 2)
  let IconComponent = packs[pack][icon]

  if (!IconComponent) {
    return null
  }

  return (
    <StyledIcon
      background={props.background}
      margin={props.margin}
      padding={props.padding}
      style={props.style}
      {...props}
    >
      <IconComponent size={props.size} color={props.color} />
    </StyledIcon>
  )
}

Icon.propTypes = {
  StyledIcon: PropTypes.object,
  icon: PropTypes.string.isRequired,
  background: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string
}

Icon.defaultProps = {
  color: 'rgba(2, 205, 194, 1)',
  size: '24px'
}

export default Icon
