export const pieChartColors = [
  '#0088FE', 
  '#00C49F', 
  '#F47A1F',
  '#FDBB2F',
  '#377B2B',
  '#7AC142',
  '#007CC3',
  '#00529B',
  '#ff3311',
  '#9a9738',
  '#aa5412',
  '#5187a0',
  '#1c3382',
  '#664488',
  '#9aa097',
  '#aeb2c0',
  '#bec0af',
  '#d0c4d3'
]