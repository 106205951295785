import hoistStatics from 'hoist-non-react-statics'
import React from 'react'
import PropTypes from 'prop-types'

export default function connectConfirmation(WrappedComponent) {
  const ConfirmationComponent = ({ forwardRef, ...otherProps }, context) => {
    return <WrappedComponent ref={forwardRef} {...otherProps} confirm={context.confirm} />
  }

  const ConnectedConfirmationComponent = React.forwardRef((props, ref) => (
    <ConfirmationComponent {...props} forwardRef={ref} />
  ))

  ConfirmationComponent.contextTypes = {
    confirm: PropTypes.func
  }

  return hoistStatics(ConnectedConfirmationComponent, WrappedComponent)
}
