import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactDOMServer from 'react-dom/server'
import FileDownload from '../../Dashboard/Proposals/components/FileDownload'

import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, ButtonGroup } from 'reactstrap'
import { isMobile } from 'react-device-detect'

import { handlerErrors } from 'utils'
import { axiosClient } from 'store'

import Elements from 'screens/Dashboard/Proposals/Proposal2/Elements'
import SignatureForm from '../../Dashboard/components/forms/SignatureForm'

const initialState = {
  submitError: null
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${!isMobile ? 'padding-bottom: 20px;' : null}
`

const ScrollableContainer = styled.div`
  max-height: calc(100vh - ${isMobile ? '50px' : '60px'});
  overflow-y: auto;
  overflow-x: hidden;
`

const ProposalContainer = styled.div`
  background-color: #fff;
  margin: 0;
  padding: 20px;
  max-width: 768px;
`

const ElementPreview = styled.div`
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${isMobile ? '5px' : '20px'} 0;
  width: 90%;
  max-width: 768px;
`

const ProposalReview = ({ match, history }) => {
  const [proposal, setProposal] = useState(null)
  const [signatureFormOpen, setSignatureFormOpen] = useState(false)
  const signatureToken = match.params?.signatureToken
  let clientType = (proposal != undefined) ? proposal.client.type : ''
  let clientAccessToken = (proposal != undefined) ? proposal.signatureData.clientAccessToken : ''


  if (!match.params.proposalId || !signatureToken) {
    return <Redirect to="/" />
  }

  useEffect(() => {
    const fetchProposal = async () => {
      const result = await axiosClient.get(`/api/proposals/${match.params.proposalId}/proposalPortal`)
      if (result.error) {
        let message = handlerErrors(result.error, 'Proposal could not be found.')
        toast(message)
        return
      }
      const proposalRes = result?.data
      const clientAccessToken = proposalRes?.signatureData?.clientAccessToken

      if (signatureToken === clientAccessToken) {
        setProposal(proposalRes)
        if (!proposalRes?.viewed) {
          const viewedRes = await axiosClient.patch(`/api/proposals/${proposalRes._id}/proposalViewed/${clientAccessToken}`)
          setProposal(viewedRes?.data)
        }
        return
      }

      toast('Invalid access token.')
      history.push('/')
      return
    }
    fetchProposal()
  }, [])

  const _submitSignature = async (signature) => {
    const data = {
      accessToken: signatureToken,
      signature
    }

    const result = await axiosClient.patch(`/api/proposals/${proposal._id}/clientSigned`, data)

    if (result.error) {
      let message = handlerErrors(result.error, 'There was an error submitting signature.')
      toast(message)
      return
    }

    toast('Signed Proposal Received.')
    setProposal(result?.data)
    setSignatureFormOpen(false)
  }

  const _downloadFile = async () => {
    let str = ReactDOMServer.renderToString(
      proposal?.data?.elements?.map((e, i) => {
        let master = Elements.find(m => m.type === e.type)
        if (master && master.print) {
          // Don't know why but this needs to replace closing tags first then opening tags for the markdown text to remain intact.
          return master
            .print(e)
            .replace(/<\/.*>/g, '')
            .replace(/<.*>/g, '')
        } else {
          return _renderElementContent(e, i)
        }
      })
    )

    let prefixUrl = `api/proposals`
    let url = (clientType === 'Client') ? `${prefixUrl}/pdfClient` : `${prefixUrl}/pdf`
    const result = await axiosClient({
      url,
      method: 'post',
      data: {
        proposal: str,
        showPages: proposal?.data?.options?.showPageNumbers,
        clientAccessToken
      },
      responseType: 'blob',
      timeout: 100000
    })

    const fileName = `Proposal-${proposal?.merchant?.name || ''
      }-${proposal?.client?.name || ''
      }.pdf`

    if (!result.error) {
      FileDownload(result.data, fileName, 'application/pdf')
    } else {
      console.error(result.error)
    }
  }


  const _renderElementContent = (element, index) => {
    return (
      (Elements.find(e => e.type === element.type) &&
        Elements.find(e => e.type === element.type).render({
          element,
          proposal
        })) || <div />
    )
  }

  const _renderElement = (element, index, totalCount) => {
    return (
      <ElementPreview
        key={`proposal-element-preview-${index}`}
      >
        {_renderElementContent(element, index)}
      </ElementPreview>
    )
  }

  const toggleSignatureForm = () => {
    setSignatureFormOpen(!signatureFormOpen)
  }

  const alreadySigned = proposal?.signatureData?.clientSignature

  return (
    <PageContainer className="full-height">
      {signatureFormOpen ? (
        <SignatureForm
          isOpen={signatureFormOpen}
          toggle={toggleSignatureForm}
          onSubmitSignature={_submitSignature}
          proposalId={proposal?._id}
          accessToken={signatureToken}
        />
      ) : null}
      <ButtonContainer>
        <ButtonGroup>
          {proposal && !alreadySigned ? (
            <Button onClick={toggleSignatureForm} style={{ width: 150 }} color='primary'>
              Sign
            </Button>
          ) : null}
          {!isMobile ? <Button onClick={_downloadFile} >Download PDF</Button> : null}
        </ButtonGroup>
      </ButtonContainer>
      <ScrollableContainer>
        <ProposalContainer className='proposal-new'>
          {proposal?.data?.elements?.map((item, index) => _renderElement(
            item,
            index,
            proposal?.data?.elements?.length
          ))}
        </ProposalContainer>
      </ScrollableContainer>
    </PageContainer>
  )
}

export default ProposalReview
