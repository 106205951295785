import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Card, ListGroup, ListGroupItem } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import { axiosClient } from 'store'
import SearchBar from './SearchBar'
import MaterialSpinner from './MaterialSpinner'

const DropdownCard = styled(Card)`
  position: absolute !important;
  top: 48px;
  left: 40px;

  width: 100%;
  padding: 0;
  max-height: 300px !important;
  overflow-y: auto !important;

  .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .list-group-item:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .list-group:last-child .list-group-item:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .list-group-item:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ${props => (props.visible ? null : `display: none !important`)};
`

const SearchResultItem = styled(ListGroupItem)`
  padding: 4px 16px !important;
`

const StyledSpan = styled.span`
  color: #b0b0b2;
  font-style: italic;
  white-space: pre;
`

const TYPE_LINKS = {
  'Employee': '/dashboard/employees/',
  'Client': '/dashboard/client-details/',
  'Estimate': '/dashboard/edit-estimate/',
  'Proposal': '/dashboard/edit-proposal/',
  'Location': '/dashboard/location-details/',
  'Walkthrough': '/dashboard/walkthroughs/',
  'Inspection': '/dashboard/inspections/'
}

const SearchResult = ({ data }) => {
  const type = data.type

  let content

  switch (type) {
    case 'Employee':
      content = (
        <p>
          Employee:{' '}
          <strong>
            {data.name.first} {data.name.last}
          </strong>
      {data.invite ? <StyledSpan> (invited)</StyledSpan> : null}
        </p>
      )
      break
    case 'Client':
      content = (
        <p>
          Client: <strong>{data.name}</strong>
        </p>
      )
      break
    case 'Estimate':
      content = (
        <p>
          Estimate: <strong>{data.code}{data.name ? ' - ' + data.name : ''}</strong>
        </p>
      )
      break
    case 'Proposal':
      content = (
        <p>
          Proposal: <strong>{data.code}{data.name ? ' - ' + data.name : ''}</strong>
        </p>
      )
      break
    case 'Location':
      console.log('location data: ', data)
      content = (
        <p>
          Location: <strong>{data.address && (data.address.fullAddress || data.address.formattedAddress)}</strong>
        </p>
      )
      break
    case 'Walkthrough':
      content = (
        <p>
          Walkthrough: <strong>{data.code}{data.name ? ' - ' + data.name : ''}</strong>
        </p>
      )
      break
    case 'Inspection':
      content = (
        <p>
          Inspection: <strong>{data.code}{data.name ? ' - ' + data.name : ''}</strong>
        </p>
      )
      break
    default:
      content = <p>Search Result</p>
      break
  }

  const link = TYPE_LINKS[type] + data._id

  return (
    <SearchResultItem tag="a" href={link} action>
      {content}
    </SearchResultItem>
  )
}

class UniversalSearch extends React.Component {
  state = {
    busy: false,
    fetched: false,
    searchText: '',
    results: []
  }
  _performSearch = _.debounce(async () => {
    const { busy, searchText } = this.state

    if (busy) {
      return
    }

    if (!searchText || !searchText.length) {
      this.setState({
        searchText: '',
        fetched: false,
        results: []
      })
      return
    }

    this.setState({
      busy: true
    })

    try {
      const { data } = await axiosClient.get(`/api/search?text=${searchText}`)

      this.setState({
        busy: false,
        fetched: true,
        results: data
      })
    } catch (ex) {
      console.error(ex)
    }
  }, 500)

  _updateSearchText = value => {
    this.setState({
      searchText: value
    })
    this._performSearch(value)
  }

  _handleEnter = (evt) => {
    if (evt.key === 'Enter') {
      const firstResult = this.state.results[0]
      if (!!firstResult) {
        const { type, _id } = firstResult
        const link = TYPE_LINKS[type] + _id
        this.props.history.push(link)
        this.setState({
          fetched: false,
          results: [],
          searchText: ''
        })
        evt.target.blur()
      }
    }
  }
  render() {
    const { style } = this.props
    const { busy, fetched, searchText, results } = this.state
    return (
      <div style={{ position: 'relative' }}>
        <SearchBar
          disabled={this.state.busy}
          value={searchText}
          onChange={this._updateSearchText}
          style={style}
          onKeyPress={this._handleEnter}
        />
        <DropdownCard visible={searchText.length}>
          {busy && (
            <div
              style={{
                padding: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <MaterialSpinner />
            </div>
          )}
          {fetched && !busy && !results.length && (
            <p style={{ padding: 16 }}>No results found.</p>
          )}
          {!busy && !!results.length && (
            <ListGroup style={{ border: 'none' }}>
              {results.map(result => (
                <SearchResult data={result} key={result._id} />
              ))}
            </ListGroup>
          )}
        </DropdownCard>
      </div>
    )
  }
}

export default withRouter(UniversalSearch)
