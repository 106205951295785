import React from 'react'
import { CompactPicker } from 'react-color'
import Label from './Label'
import { Button } from 'reactstrap'

export default ({ label, data, field, onChange, ...rest }) => (
  <div style={{ marginBottom: 8 }} {...rest}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Label text={label} />
      <div>
        <CompactPicker
          color={data[field] || ''}
          onChangeComplete={color => {
            let newData = {
              ...data
            }
            newData[field] = color.hex
            return onChange(newData)
          }}
        />
      </div>
      <div style={{marginTop: 4}}>
        <Button
          size="sm"
          onClick={() => {
            let newData = {
              ...data
            }
            newData[field] = ''
            return onChange(newData)
          }}
        >
          Clear Color
        </Button>
      </div>
    </div>
  </div>
)
