import React from 'react'

import { daysOfWeek } from 'data'

export default ({ element, proposal }) => {
  if (!element || !element.data) {
    return null
  }

  let renderedContent = null
  const showHeading = element.data.showHeading
  let headingText = 'Service Frequency'

  if (element.data.serviceFrequency === 'Daily') {
    const selectedDays = element.data.selectedDays || {}
    headingText = 'Service Days'
    renderedContent = (
      <div>
        <div className="service-days"></div>
        <table className="service-days-table">
          <tbody>
            <tr>
              {daysOfWeek.map((day, index) => (
                <th key={`day-${index}`}>{day.slice(0, 3)}</th>
              ))}
            </tr>
            <tr>
              {daysOfWeek.map((day, index) => (
                <td
                  key={`selected-day-${index}`}
                  className="service-days-selected-cell"
                >
                  {selectedDays[day] ? 'X' : ''}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  } else if (element.data.serviceFrequency === 'Once') {
    renderedContent = <div>One time service.</div>
  } else if (element.data.serviceFrequency !== 'Other') {
    renderedContent = <div>{element.data.serviceFrequency} service.</div>
  } else if (
    element.data.serviceFrequency === 'Other' &&
    element.data.frequencyDescription
  ) {
    renderedContent = (
      <div>
        <p style={{whiteSpace: 'pre-wrap'}}>
          {element.data.frequencyDescription}
        </p>
      </div>
    )
  }

  return (
    <div style={{ paddingBottom: 20 }}>
      {showHeading && (
        <h2 className="section-title">
          {element.data.headingText || headingText}
        </h2>
      )}
      {renderedContent}
    </div>
  )
}