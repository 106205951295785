import { useEffect, useState } from 'react';
import { ListBox, ItemValueContainer, HeaderRow } from './ListStyles';
import { UploadText } from '../ImportContentStyles';
import { ClientRejectedItem } from './ClientRejectedItem';
import { LocationRejectedItem } from './LocationRejectedItem';
import { rejectedClientColHeaders, rejectedLocationColHeaders } from './colHeaders';

export const RejectedItemList = ({ importType, data, updateItem }) => {
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    setBusy(true)
    setBusy(false)
  }, [data])

  let renderedList = null

  if (importType === 'location') {
    renderedList = (
      <div>
        {data?.map((item, index) => (
          <LocationRejectedItem item={item} key={index} onSubmit={updateItem} index={index} />
        ))}
      </div>
    )
  } else if (importType === 'client') {
    renderedList = (
      <div>
        {data?.map((item, index) => (
          <ClientRejectedItem item={item} key={index} onSubmit={updateItem} index={index} />
        ))}
      </div>
    )
  }
  const colHeaders = importType === 'client' ? rejectedClientColHeaders : rejectedLocationColHeaders
  return (
    <div>
      <HeaderRow>
        {colHeaders.map((header, index) => (
          <ItemValueContainer key={index} width={header.width}>
            <UploadText style={{margin: 0}}>
              {header.name}
            </UploadText>
          </ItemValueContainer>
        ))}
      </HeaderRow>
      <ListBox>
        {!busy ? renderedList : null}
      </ListBox>
    </div>
  )
}