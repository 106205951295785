import React from 'react'
import { Card } from 'reactstrap'
import Boolean from './inspectors/knobs/Boolean'

export default ({options, onChange}) => (
  <Card body>
    <Boolean
      data={options}
      field="showPageNumbers"
      label="Page Numbers in PDF"
      onChange={val => onChange(val)}
    />
  </Card>
)
