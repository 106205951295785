export const US_STATES = {
  alabama: {
    name: 'Alabama, AL',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  alaska: {
    name: 'Alaska, AK',
    hourly: 10.19,
    commercial: {
      office: 0.2,
      restaurant: 0.2,
      other: 0.2,
      healthcare: 0.2
    }
  },
  arizona: {
    name: 'Arizona, AZ',
    hourly: 12.0,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  arkansas: {
    name: 'Arkansas, AR',
    hourly: 10.0,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  california: {
    name: 'California, CA',
    hourly: 13.0,
    commercial: {
      office: 0.21,
      restaurant: 0.21,
      other: 0.21,
      healthcare: 0.21
    }
  },
  colorada: {
    name: 'Colorado, CO',
    hourly: 12.0,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  connecticut: {
    name: 'Connecticut, CT',
    hourly: 11.0,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  delaware: {
    name: 'Delaware, DE',
    hourly: 9.25,
    commercial: {
      office: 0.16,
      restaurant: 0.16,
      other: 0.16,
      healthcare: 0.16
    }
  },
  district_of_columbia: {
    name: 'District of Columbia, DC',
    hourly: 14.0,
    commercial: {
      office: 0.21,
      restaurant: 0.21,
      other: 0.21,
      healthcare: 0.21
    }
  },
  florida: {
    name: 'Florida, FL',
    hourly: 8.56,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  georgia: {
    name: 'Georgia. GA',
    hourly: 7.25,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  hawaii: {
    name: 'Hawaii, HI',
    hourly: 10.1,
    commercial: {
      office: 0.25,
      restaurant: 0.25,
      other: 0.25,
      healthcare: 0.25
    }
  },
  idaho: {
    name: 'Idaho, ID',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  illinois: {
    name: 'Illinois, IL',
    hourly: 9.25,
    commercial: {
      office: 0.16,
      restaurant: 0.16,
      other: 0.16,
      healthcare: 0.16
    }
  },
  indiana: {
    name: 'Indiana, IN',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  iowa: {
    name: 'Iowa, IA',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  kansas: {
    name: 'Kansas, KS',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  kentucky: {
    name: 'Kentucky, KY',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  louisiana: {
    name: 'Louisiana, LA',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  maine: {
    name: 'Maine, ME',
    hourly: 12.0,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  maryland: {
    name: 'Maryland, MD',
    hourly: 11.0,
    commercial: {
      office: 0.28,
      restaurant: 0.28,
      other: 0.28,
      healthcare: 0.28
    }
  },
  massachusetts: {
    name: 'Massachusetts, MA',
    hourly: 12.75,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  michigan: {
    name: 'Michigan, MI',
    hourly: 9.65,
    commercial: {
      office: 0.19,
      restaurant: 0.19,
      other: 0.19,
      healthcare: 0.19
    }
  },
  minnesota: {
    name: 'Minnesota, MN',
    hourly: 10.0,
    commercial: {
      office: 0.16,
      restaurant: 0.16,
      other: 0.16,
      healthcare: 0.16
    }
  },
  mississippi: {
    name: 'Mississippi, MS',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  missouri: {
    name: 'Missouri, MO',
    hourly: 9.45,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  montana: {
    name: 'Montana, MT',
    hourly: 8.65,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  nebraska: {
    name: 'Nebraska, NE',
    hourly: 9.0,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  nevada: {
    name: 'Nevada, NV',
    hourly: 8.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  new_hampshire: {
    name: 'New Hampshire, NH',
    hourly: 7.25,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  new_jersey: {
    name: 'New Jersey, NJ',
    hourly: 11.0,
    commercial: {
      office: 0.13,
      restaurant: 0.13,
      other: 0.13,
      healthcare: 0.13
    }
  },
  new_mexico: {
    name: 'New Mexico, NM',
    hourly: 9.0,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  new_york: {
    name: 'New York, NY',
    hourly: 15.0,
    commercial: {
      office: 0.22,
      restaurant: 0.22,
      other: 0.22,
      healthcare: 0.22
    }
  },
  north_carolina: {
    name: 'North Carolina, NC',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  north_dakota: {
    name: 'North Dakota, ND',
    hourly: 7.25,
    commercial: {
      office: 0.22,
      restaurant: 0.22,
      other: 0.22,
      healthcare: 0.22
    }
  },
  ohio: {
    name: 'Ohio, OH',
    hourly: 8.7,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  oklahoma: {
    name: 'Oklahoma, OK',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  oregon: {
    name: 'Oregon, OR',
    hourly: 11.25,
    commercial: {
      office: 0.17,
      restaurant: 0.17,
      other: 0.17,
      healthcare: 0.17
    }
  },
  pennsylvania: {
    name: 'Pennsylvania, PA',
    hourly: 7.25,
    commercial: {
      office: 0.19,
      restaurant: 0.19,
      other: 0.19,
      healthcare: 0.19
    }
  },
  rhode_island: {
    name: 'Rhode Island, RI',
    hourly: 10.5,
    commercial: {
      office: 0.19,
      restaurant: 0.19,
      other: 0.19,
      healthcare: 0.19
    }
  },
  south_carolina: {
    name: 'South Carolina, SC',
    hourly: 7.25,
    commercial: {
      office: 0.17,
      restaurant: 0.17,
      other: 0.17,
      healthcare: 0.17
    }
  },
  south_dakota: {
    name: 'South Dakota, SD',
    hourly: 9.3,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  tennessee: {
    name: 'Tennessee, TN',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  texas: {
    name: 'Texas, TX',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  utah: {
    name: 'Utah, UT',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  vermont: {
    name: 'Vermont, VT',
    hourly: 10.96,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  virginia: {
    name: 'Virginia, VA',
    hourly: 7.25,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  washington: {
    name: 'Washington, WA',
    hourly: 13.5,
    commercial: {
      office: 0.17,
      restaurant: 0.17,
      other: 0.17,
      healthcare: 0.17
    }
  },
  west_virginia: {
    name: 'West Virginia, WV',
    hourly: 8.75,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  wisconsin: {
    name: 'Wisconsin, WI',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  wyoming: {
    name: 'Wyoming, WY',
    hourly: 5.15,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  }
}
