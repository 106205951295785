import { readableColor, mix } from 'polished'
import { daysOfWeek } from 'data'
import { isImage } from 'utils/imageHelpers'
import _ from 'lodash'

export const newline = '\n\n'
export const linebreak = `<br />${newline}`
export const nbsp = howMany => {
  let res = ''
  for (let i = 0; i < howMany; ++i) {
    res += '&nbsp; '
  }
  return res
}
export const pagebreak = '<div style="page-break-after: always;"></div>'

const formatCharge = number => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(number)
}

export const provider = currentCompany => {
  if (!currentCompany) {
    return ''
  }

  const address = currentCompany.address && currentCompany.address.fullAddress
  const city = currentCompany.address && currentCompany.address.city

  const streetAddress = address
    .split(city)[0]
    .split(',')[0]
    .trim()
  const restAddress = city + address.split(city)[1].trim()

  return (
    `**${currentCompany.name}**\n` +
    `**${streetAddress}**\n` +
    `**${restAddress}**\n`
  )
}

const toRGBA = value => {
  if (!value) {
    return 'white'
  }

  return `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`
}

const hr = color => {
  return `<div style="height: 1px; background-color: ${color ||
    'black'}; opacity: 0.3"></div>`
}

export const header = ({ values, job, currentCompany, client, code }) => {
  values = values || {}
  let headerColor =
    values && values.headerColor
      ? toRGBA(values.headerColor)
      : `rgba(225, 225, 225, 1)`

  let headerBG = `background-color: ${headerColor};`
  let headerText =
    values && values.headerTextColor
      ? toRGBA(values.headerTextColor)
      : readableColor(headerColor)

  let headerTextLight = mix(0.8, headerText, readableColor(headerText))

  let title = values.title || 'Service Proposal'

  let merchantName = ''
  if (currentCompany) {
    merchantName = currentCompany.name
  }

  let merchantLogoBit
  if (values && values.showProviderLogo) {
    const providerLogoUrl = values.providerLogo && values.providerLogo.url
    if (providerLogoUrl) {
      merchantLogoBit = `<img src="${providerLogoUrl}" class="logo" width="42" height="42" alt="merchant logo"/>`
    }
  }

  let clientLogoBit
  if (values && values.showClientLogo) {
    const clientLogoUrl = values.clientLogo && values.clientLogo.url
    if (clientLogoUrl) {
      clientLogoBit = `<img src="${clientLogoUrl}" class="logo"  width="64" height="64" alt="client logo"/>`
    }
  }

  let merchantAddress = ''
  if (currentCompany) {
    merchantAddress = currentCompany.address.fullAddress
  }

  let clientName = ''
  if (client) {
    clientName = client.name
  }

  let clientAddress = ''
  if (values && values.clientAddress) {
    clientAddress = values.clientAddress
  }

  let result = ''

  result += `<div class="header-merchant-area">`
  result += `<div class="logo-placement">`
  if (merchantLogoBit) {
    result += merchantLogoBit
  }
  result += `<div>`
  result += `<div style="font-size: 20px; color: black">${merchantName}</div>`
  result += `<div style="font-size: 10px; margin-top: -8px; color: #767676"><em>${merchantAddress}</em></div>`
  result += `</div>`
  result += `</div>`
  result += `</div>`

  result += `<div class="header-background" style="${headerBG}">`
  result += `<div class="header-content" style="color: ${headerText}">`

  result += `<div style="font-size: 30px; font-weight: bold">${title}</div>`

  result += hr(headerText)

  if (code) {
    result += `<div style="font-size: 16px; text-align: right; color: ${headerTextLight}"><em>Proposal Code: ${code}</em></div>`
  }
  result += '<div style="height: 100px; width: 100%"></div>'

  result += `<span style="font-size: 14px; color: ${headerTextLight}">prepared for</span><br />`
  result += `<div class="logo-placement">`
  if (clientLogoBit) {
    result += clientLogoBit
  }
  result += `<div style="padding-top: 10px">`
  result += `<div style="font-size: 20px; font-weight: bold">${clientName}</div>`
  result += `<div style="font-size: 10px; margin-top: -8px; color: ${headerTextLight}"><em>${clientAddress}</em></div>`
  result += `</div>`
  result += `</div>`
  result += `</div>`

  result += '</div>'
  result += '</div>'

  result += pagebreak
  return result
}

export const serviceFrequency = values => {
  let result = ''

  if (values.serviceFrequency === 'Daily') {
    result += `<h2 class="section-title">Service Days</h2> ${newline}`

    result += `<div class="service-days"></div>${newline}`
    const selectedDays = (values && values.selectedDays) || []
    const cells =
      '|' +
      daysOfWeek
        .map(day => {
          if (selectedDays.includes(day)) {
            return '  X  |'
          } else {
            return '     |'
          }
        })
        .join('')

    result += `
| Sun | Mon | Tue | Wed | Thu | Fri | Sat |
|:---:|:---:|:---:|:---:|:---:|:---:|:---:|
${cells}${newline}${linebreak}`
  } else if (values.serviceFrequency === 'Once') {
    result += `<h2 class="section-title">Service Frequency</h2> ${newline}`
    result += `One time service.${newline}${linebreak}`
  } else if (values.serviceFrequency !== 'Other') {
    result += `<h2 class="section-title">Service Frequency</h2> ${newline}`
    result += `${values.serviceFrequency} service.${newline}${linebreak}`
  } else if (
    values.serviceFrequency === 'Other' &&
    values.frequencyDescription
  ) {
    result += `<h2 class="section-title">Service Frequency</h2> ${newline}`
    result += `${values.frequencyDescription}${newline}${linebreak}`
  }

  return result
}

export const legalTerms = values =>
  `<h2 class="section-title">Service Agreement</h2> ${newline}` +
  `${values.legal}${newline}${linebreak}`

export const signatureLine = (
  customer,
  contractor,
  proposalName
) => `${pagebreak}
<h2 class="section-title">Signatures</h2>
<p>IN WITNESS WHEREOF, the parties hereto have executed this ${proposalName ||
  'Service Proposal'} Agreement by their duly authorized representatives on the dates set forth below.</p>
<div style="width: 100%">
    <div style="width: 40%; display: inline-block">
      <p class="company-signature">${customer}</p>
      <hr class="horizontal-line">
      <i>Signature</i>
      <hr class="horizontal-line">
      <i>Print Name and Title</i>
      <hr class="horizontal-line">
      <i>Service Start Date</i>
    </div>
    <div style="width: 40%; display: inline-block; margin-left: 32px">
      <p class="company-signature">${contractor}</p>
      <hr class="horizontal-line">
      <i>Signature</i>
      <hr class="horizontal-line">
      <i>Print Name and Title</i>
      <hr class="horizontal-line">
      <i>Service Start Date</i>
    </div>
  </div>`

export const taskSections = values => {
  let result = '<h2 class="section-title">Scope of Work</h2>' + newline
  if (!values.taskSections || !values.taskSections.length) {
    return result
  }

  values.taskSections.forEach(section => {
    result += '<div class="task-section">'
    result +=
      '<h5 style="margin-bottom: 5px">' +
      (section.name || 'Undefined') +
      '</h5>'

    if (section.tasks && section.tasks.length) {
      result += '<table style="width: 100%">'
      result += '<thead><tr>'
      result += '<th>Task</th><th style="width: 20%;">Frequency</th>'
      result += '</tr></thead>'
      result += '<tbody>'
      section.tasks.forEach(task => {
        if (task && task.name && task.frequency) {
          result += '<tr>'
          result += `<td>${task.name}</td><td style="width: 20%">${
            task.frequency
          }</td>`
          result += '</tr>'
        }
      })
      result += '</tbody></table>'
    }

    result += '</div>'
  })

  result += pagebreak

  return result
}

export const areas = (
  areas,
  includeAreaImages,
  includeAreaNotes,
  includeAreaFixtures,
  includeAreaSqFt
) => {
  let result = ''
  const jobAreas = areas.reduce((list, area) => {
    const areaDimensions =
      area.dimensions &&
      area.dimensions.set &&
      area.dimensions.set.map(
        dim =>
          `<span class="dimension">${parseFloat(dim.width).toFixed(
            2
          )} ft. x ${parseFloat(dim.depth).toFixed(2)} ft.</span>`
      )
    let imageAttachments = area.attachments.filter(d => isImage(d.type))
    const renderPhotos =
      imageAttachments.length < 6
        ? (imageAttachments || []).map(
            image =>
              `<img src=${image.url} alt="area photos" class="area-image" />`
          )
        : imageAttachments
            .slice(0, 5)
            .map(
              image =>
                `<img src=${image.url} alt="area photos" class="area-image" />`
            )
    const morePhotos =
      imageAttachments.length > 5
        ? `<span style="padding-left: 8px">+ ${imageAttachments.length -
            5} more</span>`
        : ''

    const renderFixtures = area.fixtures.length
      ? `<table style="width: 100%">
        <tbody>
        ${area.fixtures
          .map(
            fixture =>
              `<tr><td>${fixture.type}</td><td style="width: 20%">${
                fixture.quantity
              }</td></tr>`
          )
          .join('')}
        </tbody></table>`
      : ''

    const floorAreas =
      (includeAreaSqFt &&
        area.dimensions &&
        area.dimensions.set &&
        !!area.dimensions.set.length &&
        `<div class="area-item"><span class="item-title">FLOOR AREA: </span>${areaDimensions}</div>${newline}`) ||
      ''
    const floorType =
      (area.floorType &&
        `<div class="area-item"><span class="item-title">FLOOR TYPE: </span>${
          area.floorType
        }</div>${newline}`) ||
      ''
    const notes =
      (includeAreaNotes &&
        area.notes &&
        `<div class="area-item"><span class="item-title">NOTES: </span>${
          area.notes
        }</div>${newline}`) ||
      ''
    const photos =
      (includeAreaImages &&
        area.attachments &&
        `<div class="area-item">${renderPhotos}${morePhotos}</div>${newline}`) ||
      ''

    const fixtures =
      (includeAreaFixtures &&
        area.fixtures &&
        area.fixtures.length &&
        `<div class="area-item"><span class="item-title">FIXTURES:</span>${renderFixtures}</div>`) ||
      ''

    return (
      list +
      `<div class="area"><p class="area-name">${
        area.name
      }<span class="area-type">(<i style="margin-right: 2px;">${
        area.type
      }</i>)${
        includeAreaSqFt && area.squareFootage
          ? ' ' + parseFloat(area.squareFootage).toFixed(2) + ' SF'
          : ''
      }</span></p></div>${newline}${floorAreas}${floorType}${notes}${fixtures}${photos}`
    )
  }, '')
  result += `<h2 class="section-title">Areas</h2>${newline}${jobAreas}${pagebreak}`

  return result
}

export const fixtures = areas => {
  let fixtures = []

  let fixturesGrouped = _.groupBy(
    _.flatten(_.map(areas, 'fixtures')),
    fixture => fixture.type.toLowerCase().trim()
  )

  let fixturesGroupedKeys = Object.keys(fixturesGrouped)
  fixturesGroupedKeys.forEach(key => {
    const quantity = fixturesGrouped[key].reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.quantity, 10),
      0
    )
    fixtures.push({ key: _.capitalize(key), quantity })
  })

  fixtures = _.orderBy(fixtures, 'key', 'asc')

  let result = ''

  if (fixtures.length) {
    result = `<h3>Fixtures</h3>`
    result += '<table style="width: 100%">'
    result += '<thead><tr>'
    result += '<th>Fixture</th><th style="width: 20%;">Quantity</th>'
    result += '</tr></thead>'
    result += '<tbody>'

    fixtures.forEach(fixture => {
      result += '<tr>'
      result += `<td>${fixture.key}</td><td style="width: 20%">${
        fixture.quantity
      }</td>`
      result += '</tr>'
    })
    result += '</tbody></table>'
  }

  return result
}

const renderService = service => {
  let result = ''
  if (service.showService) {
    result += `<h5 style="margin-bottom: 8px;">${
      service.name
    }<span style="float: right;">${
      service.interval
        ? service.interval === 'once'
          ? service.interval
          : 'per ' + service.interval
        : ''
    }</span></h5>`

    result += '<table style="width: 100%;">'
    result += '<tbody>'
    if (service.charges) {
      service.charges.forEach(charge => {
        if (!charge) {
          return
        }
        result += '<tr style="background-color: #fff;">'
        result += `<td style="border: none; text-align: left; margin: 0px; padding: 0px; white-space: nowrap; text-align: left;">${charge.name ||
          'Line item'}</td>`
        result +=
          '<td style="border: none; margin: 0px; padding: 0px; white-space: nowrap; text-align: center; border-bottom: 1px dotted; width: 100%;"></td>'
        result += `<td style="border: none; text-align: right; margin: 0px; padding: 0px; white-space: nowrap;">${
          charge.price && charge.price > 0 ? formatCharge(charge.price) : '$0'
        }</td>`
        result += '</tr>'
      })

      let serviceTotal = service.total
      result += '<tr style="border-top: 1px solid;">'
      result += `<td style="border: none; text-align: left; margin: 0px; padding: 0px; white-space: nowrap; text-align: left;"><strong>Total</strong></td>`
      result +=
        '<td style="border: none; margin: 0px; padding: 0px; white-space: nowrap; text-align: center; width: 100%;"></td>'
      result += `<td style="border: none; text-align: right; margin: 0px; padding: 0px; white-space: nowrap;"><strong>${
        serviceTotal && serviceTotal > 0 ? formatCharge(serviceTotal) : '$0'
      }</strong></td>`
      result += '</tr>'
      result += '</tbody></table>'
    }
  }

  return result
}

export const estimateCharges = (estimateCharges, showTotals) => {
  const shownServices = estimateCharges.filter(service => service.showService)

  /*
  const monthlyCharges = shownServices.filter(
    service => service.interval === 'month'
  )

  const oneTimeCharges = _.filter(
    estimateCharges,
    service => service.showService && service.interval === 'once'
  )
  */

  const yearlyCharges = shownServices.filter(
    service => service.interval === 'year'
  )

  let finalTotalInterval =
    yearlyCharges && yearlyCharges.length ? 'yearly' : 'monthly'
  let finalTotal = 0
  let oneTimeTotal = 0

  let result = `<h2 class="section-title">Charges</h2> ${newline}`

  shownServices.forEach(service => {
    let serviceTotal = 0
    service.charges.forEach(charge => {
      if (!charge) {
        return
      }
      if (charge.price > 0) {
        serviceTotal += parseFloat(charge.price)
      }
    })

    if (serviceTotal > 0) {
      if (service.interval === 'month') {
        if (finalTotalInterval === 'monthly') {
          finalTotal += parseFloat(serviceTotal)
        } else if (finalTotalInterval === 'yearly') {
          finalTotal += parseFloat(serviceTotal * 12)
        }
      } else if (service.interval === 'year') {
        finalTotal += parseFloat(serviceTotal)
      } else if (service.interval === 'once') {
        oneTimeTotal += parseFloat(serviceTotal)
      }
    }

    service.total = serviceTotal
  })

  shownServices.forEach(service => (result += renderService(service)))

  if (showTotals) {
    if (finalTotal > 0) {
      result += `<h4>Grand ${finalTotalInterval} total<span style="font-size: 1em; float: right;">${formatCharge(
        finalTotal
      )}</span></h4>`
    }

    if (oneTimeTotal > 0) {
      result += `<h4 style="margin: 14px 0 8px;">One time charge total<span style="font-size: 1em; float: right;">${formatCharge(
        oneTimeTotal
      )}</span></h4>`
    }
  }

  result += `${newline}`
  return result
}

export const charges = values =>
  `<h2 class="section-title">Other Charges</h2> ${newline}` +
  `${values.charges}${newline}${linebreak}`

export const additionalTerms = values =>
  `<h2 class="section-title">Additional Terms</h2> ${newline}` +
  `${values.additionalTerms}${newline}${linebreak}`
