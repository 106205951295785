import React, { useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import { Modal, ModalBody } from 'reactstrap'
import { CloseButton } from 'components/lib/Button'
import { Button } from 'reactstrap'
import { FiDownload, FiMail } from 'react-icons/fi'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`

export default ({ isOpen, toggle, pdf, download, email }) => {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="big-modal"
      backdrop="static"
      autoFocus={false}
    >
      <ModalBody>
        <div style={{ justifyContent: 'space-between' }}>
          <>
            <Button
              size="sm"
              style={{ marginRight: 6 }}
              onClick={() => download()}
              disabled={!download}
            >
              <FiDownload size={18} />
            </Button>
            <Button size="sm" color="primary" onClick={() => email()}>
              <FiMail size={18} />
            </Button>
          </>
          <CloseButton onClick={toggle} />
        </div>
        <div style={{ marginTop: 24, height: 600, overflowY: 'auto' }}>
          <Document
            className="pdf-preview-document"
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                className="pdf-preview-page"
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </ModalBody>
    </Modal>
  )
}
