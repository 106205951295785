import React from 'react'
import styled from 'styled-components'
import { Card } from 'reactstrap'
import moment from 'moment'
import Icon from 'components/icons'
import { IconButton } from 'components/lib/Button'
import TinyTeam from 'components/TinyTeam'
import Shiitake from 'shiitake'

import JobStatus from './JobStatus'

const JobContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const JobSchedule = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  margin-right: 1rem;
`

const JobTypeArea = styled.div`
  position: absolute;
  left: 0;
  top: 16px;
  width: 120px;
  background-color: ${props =>
    props.type === 'Walkthrough' ? '#FFEBCB' : '#D5E8FF'};
  font-size: 0.75rem;
  padding: 4px;
  text-align: center;
  border-radius: 0 12px 12px 0;
  text-transform: uppercase;
  font-weight: 600;
  color: ${props => (props.type === 'Walkthrough' ? '#FF660C' : '#4A90E2')};
`

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const JobType = ({ type }) => {
  return <JobTypeArea type={type}>{type}</JobTypeArea>
}

const JobArea = styled.div`
  position: relative;
`

const JobDate = styled.div`
  font-weight: 800;
  font-size: 1.6em;
  text-transform: uppercase;
  color: #46afbd;
  margin-top: 10px;
`

const JobTime = styled.div`
  margin-bottom: 0;
  color: #3d3e3e;
  letter-spacing: 1px;
`

const LighterText = styled.span`
  color: #959595;
`

const JobCardFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 120px;
`

const JobActions = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  .btn {
    margin-bottom: 4px;
  }
`

const NewArea = styled.div`
  position: absolute;
  left: -42px;
  bottom: 30px;
  width: 64px;
  background-color: #4a9ade;
  border-radius: 8px 8px 0 0;
  color: white;
  text-align: center;
  transform: rotate(-90deg);
`

const New = () => {
  return (
    <NewArea>
      <span className="small">NEW</span>
    </NewArea>
  )
}

const Job = props => {
  const { team, location } = props.data

  const isNew = moment().diff(props.data.createdAt, 'days') < 1

  let renderedTeam
  if (team && team.length) {
    renderedTeam = <TinyTeam size={32} team={team} />
  }

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <JobArea>
        <JobStatus
          key={props.data.status && props.data.status.label}
          status={props.data.status}
        />
        <Card body className="job-card">
          {isNew && <New />}
          <JobContainer>
            <JobSchedule>
              <JobType type={props.data.type} />
              <JobDate style={{ marginTop: '2rem' }}>
                {moment(props.data.scheduledAt).format('MMM DD')}
              </JobDate>
              <JobTime>
                {moment(props.data.scheduledAt).format('h:mma')}
              </JobTime>
            </JobSchedule>
            <JobDetails>
              {location && (
                <div>
                  <LighterText>
                    <Icon
                      icon={'GoLocation'}
                      background={'#f97a8c'}
                      color={'white'}
                      padding={'4px'}
                      size={22}
                      style={{
                        borderRadius: '5px',
                        marginRight: '8px'
                      }}
                    />
                    {location.address.fullAddress}
                  </LighterText>
                </div>
              )}
              <div className="job-card-description">
                <span style={{ cursor: 'pointer' }} onClick={props.onOpen}>
                  {props.data.name || 'Job'}
                </span>
              </div>

              <Shiitake lines={2}>{props.data.description}</Shiitake>
              <div className="job-card-category">
                {props.data.categories && (
                  <LighterText>{props.data.categories.join(', ')}</LighterText>
                )}
                {renderedTeam && <LighterText> ● assigned to </LighterText>}
                {!renderedTeam && <LighterText> ● unassigned</LighterText>}
                {renderedTeam}
              </div>
            </JobDetails>
            <JobCardFooter>
              <div
                style={{
                  alignSelf: 'flex-end',
                  textAlign: 'right',
                  marginBottom: 10
                }}
              >
                <LighterText>
                  created: {moment(props.data.createdAt).fromNow()}
                </LighterText>
              </div>
              <JobActions>
                <IconButton
                  onClick={props.onEdit}
                  size="sm"
                  color="primary"
                  style={{ marginRight: '0.5rem' }}
                >
                  <Icon icon={'FaEdit'} color={'white'} size={14} /> Edit
                </IconButton>

                <IconButton
                  onClick={props.onClone}
                  size="sm"
                  color="warning"
                  style={{ marginRight: '0.5rem' }}
                >
                  <Icon icon={'FaClone'} color={'white'} size={14} /> Clone
                </IconButton>
                <IconButton onClick={props.onRemove} size="sm" color="danger">
                  <Icon icon={'FaTrash'} color={'white'} size={14} /> Archive
                </IconButton>
              </JobActions>
            </JobCardFooter>
          </JobContainer>
        </Card>
      </JobArea>
    </div>
  )
}

export default Job
