import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import DateWithTooltip from 'components/DateWithTooltip'
import styled from 'styled-components'

import * as actions from 'api'
import networkErrorToast from 'utils/networkErrorToast'

// import SearchBar from 'components/SearchBar'
import LevelContainer from 'components/LevelContainer'
import Avatar from 'components/lib/Avatar'
import LockIcon from 'components/icons/LockIcon.js'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from 'reactstrap'
import { FiMoreHorizontal } from 'react-icons/fi'
import DataScreen from 'components/DataScreen'

import { connectConfirmation } from 'components/confirm'
import * as acl from 'utils/acl.service'
import stopEventBubbling from 'utils/stopEventBubbling'
import { ActionButtons } from 'components/lib/Button'
import LabelColumn from '../../components/LabelColumn'
import FilterForm from '../../components/forms/FilterForm'

const ActionContainer = styled(LevelContainer)`
  margin-bottom: 20px;
`

class Employees extends Component {
  state = {
    viewMode: 'table',
    showPlanLimitReachedModal: false
  }

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  async componentDidMount() {
    await this.props.getPlanControlInfo('users.create')
  }

  createEmployee = () => {
    // checking PCL to ensure the action is valid within plan
    if (!this.props.createUser.isAllowed) {
      // if it's not we show a modal asking to upgrade
      this._togglePlanLimitModal()
      return
    }
    return this.props.history.push('/dashboard/new-employee/')
  }

  _togglePlanLimitModal = () => {
    this.setState({
      showPlanLimitReachedModal: !this.state.showPlanLimitReachedModal
    })
  }

  editEmployee = id =>
    this.props.history.push(`/dashboard/employees/${id}/edit`)

  _showDetailedView = employee =>
    this.props.history.push(`/dashboard/employees/${employee._id}`)

  removeEmployee = employee => {
    this.props.confirm(
      'Confirm Archive',
      `Are you sure you want to archive ${
        employee.name && employee.name.first
          ? employee.name.first
          : 'this employee'
      }?`,
      async () => {
        let removed = await this.props.removeEmployee(employee._id)

        if (removed.error) {
          return networkErrorToast(removed)
        } else {
          if (this.dataScreen.current) {
            this.dataScreen.current.forceFetch()
          }
        }
      }
    )
  }

  _clockOut = async employee => {
    if (!employee.currentShift) {
      return
    }

    let timeSinceStart
    let confirmationMessage
    if (employee.currentShift.timer && employee.currentShift.timer.start) {
      timeSinceStart = moment(employee.currentShift.timer.start).fromNow()
    }

    if (timeSinceStart) {
      confirmationMessage = `${
        employee?.name?.first
          ? employee.name.first
          : 'This employee '
      } clocked in ${timeSinceStart}. Are you sure you want to clock them out? There is a  possibility of their work being lost.`
    } else {
      confirmationMessage = `Are you sure you want to clock ${
        employee?.name?.first
          ? employee.name.first
          : 'this employee'
      } out? There is a possibility of their work being lost.`
    }

    this.props.confirm('Confirm Clock Out', confirmationMessage, async () => {
      const clockOutResult = await this.props.clockOut(employee._id)
      if (clockOutResult.error) {
        alert('Clocking out was not successful. Try again later')
      } else {
        toast('Employee was successfully clocked out.')
        if (this.dataScreen.current) {
          this.dataScreen.current.forceFetch()
        }
      }
    })
  }

  renderEmployees = columns => {
    switch (this.state.viewMode) {
      case 'table': {
        return (
          <>
            <DataScreen
              ref={this.dataScreen}
              entity="Active Employee"
              url="/api/employees/find"
              onRowClick={this._showDetailedView}
              columns={columns}
              filterData={this.state.filterData}
            />
          </>
        )
      }
      // case 'cards': {
      //   return (
      //     <CardView
      //       employees={[...employeesSortedByLastName, ...employeesWithNoName]}
      //       edit={this.editEmployee}
      //       remove={this.removeEmployee}
      //       reinvite={this._reinviteEmployee}
      //       detailedView={this._showDetailedView}
      //     />
      //   )
      // }
      default: {
        return (
          <>
            <DataScreen
              ref={this.dataScreen}
              entity="Employee"
              url="/api/employees/find"
              onRowClick={this._showDetailedView}
              columns={columns}
              filterData={this.state.filterData}
            />
          </>
        )
      }
    }
  }

  _toggleViewMode = viewMode => {
    this.setState({
      viewMode
    })
  }

  _showArchivedEmployees = () => {
    this.props.history.push('/dashboard/employees/archived/')
  }

  render() {
    const activeColumns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          return <LabelColumn id={item._id} labels={item.labels} />
        }
      },
      {
        render: employee => (
          <>
            <Avatar
              id={`tooltip-${employee._id}`}
              border
              size={44}
              url={
                employee.user && employee.user.avatar
                  ? employee.user.avatar.url
                  : ''
              }
              active={employee.currentShift}
              name={
                employee.name
                  ? employee.name.full
                  : 'none'
              }
            />
            {employee.currentShift && employee.currentShift.timer && (
              <UncontrolledTooltip target={`tooltip-${employee._id}`}>
                {`Clocked in since ${moment(
                  employee.currentShift.timer.start
                ).format('lll')}`}
              </UncontrolledTooltip>
            )}
          </>
        )
      },
      { header: 'First Name', accessor: 'name.first', sortable: true },
      { header: 'Last Name', accessor: 'name.last', sortable: true },
      { header: 'Email', accessor: 'email', sortable: true },
      //      { header: 'Position', accessor: 'position' },
      { header: 'Role', accessor: 'role', sortable: true },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortable: true,
        sortField: 'createdAt'
      },
      {
        header: acl.isAllowed('employees', 'update') ? 'Options' : null,
        render: acl.isAllowed('employees', 'update')
          ? employee => (
              <UncontrolledDropdown>
                <DropdownToggle
                  color="link"
                  onClick={e => {
                    stopEventBubbling(e)
                  }}
                >
                  <FiMoreHorizontal size={24} color="white" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={e => {
                      stopEventBubbling(e)
                      this.editEmployee(employee._id)
                    }}
                  >
                    Edit
                  </DropdownItem>
                  {employee.currentShift && (
                    <DropdownItem
                      onClick={e => {
                        stopEventBubbling(e)
                        this._clockOut(employee)
                      }}
                    >
                      Clock Out
                    </DropdownItem>
                  )}
                  {employee?.role !== 'owner' ? (
                    <>
                      <DropdownItem divider />
                      <DropdownItem
                        className="text-danger"
                        onClick={e => {
                          stopEventBubbling(e)
                          this.removeEmployee(employee)
                        }}
                      >
                        Archive
                      </DropdownItem>
                    </>
                  ) : null}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          : null
      }
    ]

    // const viewSwitcher = (
    //   <ButtonGroup style={{ marginLeft: 10, marginRight: 10 }}>
    //     <Button
    //       color="dark"
    //       onClick={() => this._toggleViewMode('table')}
    //       active={this.state.viewMode === 'table'}
    //     >
    //       <FaTable />
    //     </Button>
    //     <Button
    //       color="dark"
    //       onClick={() => this._toggleViewMode('card')}
    //       active={this.state.viewMode === 'card'}
    //     >
    //       <FaTh />
    //     </Button>
    //   </ButtonGroup>
    // )

    let renderedEmployees = this.renderEmployees(activeColumns)

    return (
      <Container fluid style={{ marginTop: 20 }}>
        <ActionContainer>
          <div />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FilterForm
              applyFilters={data =>
                this.setState({
                  filterData: data
                })
              }
              employeeFilters
            />
            <ActionButtons
              buttons={[
                ...(acl.isAllowed('employees', 'update')
                  ? [
                      {
                        title: 'Add Employee',
                        color: this.props.createUser.isAllowed
                          ? 'primary'
                          : 'secondary',
                        icon: this.props.createUser.isAllowed ? null : (
                          <LockIcon color={'#FFF'} style={{ marginRight: 8 }} />
                        ),
                        onClick: this.createEmployee
                      }
                    ]
                  : [])
              ]}
              options={[
                ...(acl.isAllowed('employees', 'archive')
                  ? [
                      {
                        title: 'See archived employees',
                        onClick: this._showArchivedEmployees
                      }
                    ]
                  : [])
              ]}
            />
          </div>
        </ActionContainer>
        {renderedEmployees}
        <Modal
          size="sm"
          isOpen={this.state.showPlanLimitReachedModal}
          toggle={this._togglePlanLimitModal}
        >
          <ModalHeader toggle={this._togglePlanLimitModal}>
            <strong>Plan Limit Reached.</strong>
          </ModalHeader>
          <ModalBody>
            <div>
              <p>
                Your plan only allows{' '}
                <strong>{this.props.createUser.maxAllowed}</strong> users.
              </p>
              <p>Upgrade to add more.</p>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  createUser: state.pcl.users.create
})

export default connect(mapStateToProps, actions)(connectConfirmation(Employees))
