import React, { Component } from 'react'
import { NavLink as Link, withRouter } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  DropdownItem
} from 'reactstrap'
import styled from 'styled-components'

import { persistor } from 'store'

import Logo from 'assets/img/route-logo-full.png'

const DemoLink = styled.a`
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.8em;
  color: #0b64eb;
  text-decoration: none;
  background-color: transparent;
  display: block;
  padding: 0.5rem;
`

const StyledLogo = styled.img`
  height: 20px;
  margin-top: -6px;
  background-color: black;
  border-radius: 5px;
  padding: 2px 5px;
`

class PublicHeader extends Component {
  state = {
    isOpen: false
  }

  _homePage = () => {
    this.props.history.push('/dashboard')
  }

  useCompany = async company => {
    if (
      company &&
      company.company &&
      company.company._id !== this.props.company._id
    ) {
      await this.props.refreshToken(company.company._id)
      window.location.reload()
    }
  }

  logout = async () => {
    this.props.logout()
    this.props.history.push('/login')
    await persistor.flush()
    await persistor.purge()
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  isActive = () => this.location.pathname === this.props.to

  renderTeams = employment => {
    if (!employment) {
      return null
    }

    const renderedEmployment = employment.map(e => {
      return (
        e &&
        e.company &&
        e.company._id && (
          <DropdownItem onClick={() => this.useCompany(e)} key={e.company._id}>
            {e.company.name}
          </DropdownItem>
        )
      )
    })

    return <>{renderedEmployment}</>
  }

  renderVerificationWarning = () =>
    this.props.profile &&
    !this.props.profile.verified && (
      <p className={'text-center text-danger'} style={{ marginTop: '2rem' }}>
        Please check your email to verify your account.
      </p>
    )

  render() {
    return (
      <>
        <Navbar
          className="public-navbar"
          color={'white'}
          fixed={'top'}
          expand="md"
        >
          <div className="container-fluid">
            <NavbarBrand onClick={this._homePage}>
              <StyledLogo
                className={'logo'}
                src={Logo}
                alt={`Route Logo`}
              />
            </NavbarBrand>
            {/* This is just to align center */}
            <div>&nbsp;&nbsp;</div>
            <NavbarToggler hidden={true} onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <>
                  <NavItem>
                    <NavLink tag={Link} activeClassName={'active'} to="/login/">
                      Sign In
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <DemoLink
                      // tag={Link}
                      // activeClassName={'active'}
                      // to="/signup/"
                      href="https://getroute.com/questionnaire"
                    >
                      Sign Up
                    </DemoLink>
                  </NavItem>
                </>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </>
    )
  }
}

export default withRouter(PublicHeader)
