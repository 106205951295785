export default [
  {
    usn: '100989',
    sku: 'REN02923-MS',
    mfg: 'Renown',
    mfg_number: '111479',
    name: 'Renown 2.5 Gal. Multi Speed Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/100989',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '101020',
    sku: 'PGC32987',
    mfg: 'P&G',
    mfg_number: '003700032987',
    name: 'Comet 21 oz. Original Powder Deodorizing Can',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/101020',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '102476',
    sku: 'GPT89440',
    mfg: 'Georgia-Pacific',
    mfg_number: '89440',
    name: 'enMotion 8 in. 1-Ply Brown Recycled Towel Roll (700 ft./6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/102476',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '102682',
    sku: 'SPA4046-05',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '404605',
    name: 'The Fixx 5 Gallon Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/102682',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '103364',
    sku: 'REN03065-FR',
    mfg: 'Renown',
    mfg_number: 'EBC72PHSCB6/REN03065',
    name: 'Renown Cotton Blossom Deodorant Bowl Clip Solid Air Freshener',
    category: 'ODOR CONTROL',
    subcategory: 'URINAL & TOILET DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/103364',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '103522',
    sku: 'KCC13254',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '13254',
    name: 'Kleenex White Scott fold Multi-Fold Paper Towels Absorbency Pockets (25-Packs/Case, 120 Towels/Pack, 3000 Towels/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/103522',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '104187',
    sku: 'KCC13217',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '13217',
    name: 'Scott 100% Recycled Fiber Bulk Toilet Paper 2-PLY Standard Rolls, White (506 Sheets / Roll, 80 Rolls / Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/104187',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '107443',
    sku: 'REN02703-MS',
    mfg: 'Renown',
    mfg_number: '111377',
    name: 'Renown 32 oz. Spray Cleaner Degreaser',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/107443',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '107445',
    sku: 'REN02706-MS',
    mfg: 'Renown',
    mfg_number: '111380',
    name: 'Renown Super Finish II, 640 oz., 5 Gal. Pail Multi-Surface Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/107445',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '107450',
    sku: 'REN02810-MS',
    mfg: 'Renown',
    mfg_number: '111419',
    name: 'Renown 128 oz. Pine Disinfectant Cleaner',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/107450',
    favoriteOf: ['commercial', 'disinfection', 'residential']
  },
  {
    usn: '107452',
    sku: 'REN02813-MS',
    mfg: 'Renown',
    mfg_number: '111424',
    name: 'Renown 32 oz. HD23 Acid Bowl Cleaner (12 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/107452'
  },
  {
    usn: '107453',
    sku: 'REN02815-MS',
    mfg: 'Renown',
    mfg_number: '111428',
    name: 'Renown Floor Mildew Stain Remover, 1 Qt., 12 per case',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/107453'
  },
  {
    usn: '107455',
    sku: 'REN02819-MS',
    mfg: 'Renown',
    mfg_number: '111433',
    name: 'Renown High-Gloss Floor Finish, 1 Gal., 4 per case',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/107455'
  },
  {
    usn: '107456',
    sku: 'REN02820-MS',
    mfg: 'Renown',
    mfg_number: '111435',
    name: 'Renown High-Gloss Floor Finish, 5 Gal., 1 pail',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/107456'
  },
  {
    usn: '107458',
    sku: 'REN02823-MS',
    mfg: 'Renown',
    mfg_number: '111437',
    name: 'Renown Multi Speed Floor Finish, 5 Gal., 1 pail',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/107458'
  },
  {
    usn: '107460',
    sku: 'REN02825-MS',
    mfg: 'Renown',
    mfg_number: '111439',
    name: 'Renown 32 oz. Mint 9 Bowl Cleaner',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/107460'
  },
  {
    usn: '107467',
    sku: 'REN02843-MS',
    mfg: 'Renown',
    mfg_number: '111457',
    name: 'Renown Neutral Floor Cleaner, 1 Gal., 4 per case',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/107467'
  },
  {
    usn: '107470',
    sku: 'REN02852-MS',
    mfg: 'Renown',
    mfg_number: '111466',
    name: 'Renown Floor Stripper, 5 Gal., 1 pail',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/107470'
  },
  {
    usn: '107471',
    sku: 'REN02903-MS',
    mfg: 'Renown',
    mfg_number: '111472',
    name: 'Renown 128 oz. Fresh Citrus Disinfectant Cleaner',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/107471'
  },
  {
    usn: '107556',
    sku: 'REN02831-MS',
    mfg: 'Renown',
    mfg_number: '111443',
    name: 'Renown 1 Gal. Citrus Cleaner Degreaser (4 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/107556'
  },
  {
    usn: '107557',
    sku: 'REN02804-MS',
    mfg: 'Renown',
    mfg_number: '111412',
    name: 'Renown 128 oz. Non-Ammoniated Glass Cleaner (4 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'GLASS CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/107557'
  },
  {
    usn: '107558',
    sku: 'REN02812-MS',
    mfg: 'Renown',
    mfg_number: '111422',
    name: 'Renown 32 oz. Non-Ammonia Glass Cleaner (12 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'GLASS CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/107558'
  },
  {
    usn: '107559',
    sku: 'REN02836-MS',
    mfg: 'Renown',
    mfg_number: '111450',
    name: 'Renown 32 oz. Non-Acid Bowl Cleaner',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/107559'
  },
  {
    usn: '107560',
    sku: 'REN02814-MS',
    mfg: 'Renown',
    mfg_number: '111426',
    name: 'Renown 32 oz. Bacterial Digestant (12-Pack)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/107560'
  },
  {
    usn: '107802',
    sku: 'REN02613-IB',
    mfg: 'Renown',
    mfg_number: 'REN02613-IB',
    name: 'Renown 24 in. x 5 in. Dust Mop Frame Clamp On',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/107802'
  },
  {
    usn: '107804',
    sku: 'REN02615-IB',
    mfg: 'Renown',
    mfg_number: 'REN02615-IB',
    name: 'Renown 36 in. x 5 in. Dust Mop Frame Clamp On',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/107804'
  },
  {
    usn: '108206',
    sku: 'REN02100',
    mfg: 'Renown',
    mfg_number: 'REN02100',
    name: 'Renown 20 in. Super Hog Hair Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/108206'
  },
  {
    usn: '108208',
    sku: 'GPT18280-01',
    mfg: 'Georgia Pacific Corp.',
    mfg_number: '19448/01',
    name: 'Preference 2-Ply White Embossed Bathroom Tissue Toilet Paper (80-Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/108208'
  },
  {
    usn: '108211',
    sku: 'REN02835-MS',
    mfg: 'Renown',
    mfg_number: '111448',
    name: 'Renown PHOS20 1 Qt. Acid Bowl Cleaner',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/108211'
  },
  {
    usn: '108948',
    sku: 'REN63314-CA',
    mfg: 'Renown',
    mfg_number: 'REN63314-CA',
    name: 'Renown 33 Gal. 1.5 mil 33 in. x 39 in. Black Can Liner (25 per Roll, 4-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/108948'
  },
  {
    usn: '109402',
    sku: 'REN66024-CA',
    mfg: 'Renown',
    mfg_number: 'REN66024-CA',
    name: 'Renown 60 Gal. 2 mil 38 in. x 58 in. Black Can Liner (10 Per Roll, 10-Roll Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/109402'
  },
  {
    usn: '109635',
    sku: 'REN02319-IB',
    mfg: 'Renown',
    mfg_number: 'REN02319-IB',
    name: 'Renown Blended Dust Mop Mop Head',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/109635'
  },
  {
    usn: '109636',
    sku: 'REN02321-IB',
    mfg: 'Renown',
    mfg_number: 'REN02321-IB',
    name: 'Renown Blended Dust Mop Head',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/109636'
  },
  {
    usn: '109640',
    sku: 'REN02271-IB',
    mfg: 'Renown',
    mfg_number: 'REN02271-IB',
    name: 'Renown 24X5 2-PLY WHITE PREMIUM TWIST COTTON DUST MOP',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/109640'
  },
  {
    usn: '109641',
    sku: 'REN02273-IB',
    mfg: 'Renown',
    mfg_number: 'REN02273-IB',
    name: 'Renown 36X5 2-PLY WHITE PREMIUM TWIST COTTON DUST MOP',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/109641'
  },
  {
    usn: '109874',
    sku: 'REN64530-CA',
    mfg: 'Renown',
    mfg_number: 'REN64530-CA',
    name: 'Renown 45 Gal. 2 mil 40 in. x 46 in. Black Can Liner (10 Per Roll, 10-Roll Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/109874'
  },
  {
    usn: '109990',
    sku: 'REN03881-IB',
    mfg: 'Renown',
    mfg_number: 'REN03881-IB',
    name: 'Renown FLOOR SCRAPER, 48 IN.',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SQUEEGEES, WASHERS & SCRAPERS',
    picture: 'http://www.supplyworks.com/ProductImage/109990'
  },
  {
    usn: '109991',
    sku: 'REN03880-IB',
    mfg: 'Renown',
    mfg_number: 'REN03880-IB',
    name: 'Renown 4 IN. SHORT HANDLE SCRAPER',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SQUEEGEES, WASHERS & SCRAPERS',
    picture: 'http://www.supplyworks.com/ProductImage/109991'
  },
  {
    usn: '110059',
    sku: 'PTE107109',
    mfg: 'ProTeam',
    mfg_number: '107109',
    name: 'ProTeam Super CoachVac 10 Qt. Commercial Backpack Vacuum Cleaner with Xover Multi-Surface 2-Piece Wand Tool Kit',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/110059'
  },
  {
    usn: '110759',
    sku: 'RCP264600BK',
    mfg: 'Rubbermaid',
    mfg_number: 'RCP264600BK',
    name: 'Rubbermaid Commercial Products Tandem Dolly 45 in. L x 20.2 in. W x 8 in. H in Black',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE MANAGEMENT TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/110759'
  },
  {
    usn: '115251',
    sku: 'REN03110',
    mfg: 'Renown',
    mfg_number: 'REN03110',
    name: 'Renown DUST CLOTH TREATED 24X24 IN. YELLOW',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/115251'
  },
  {
    usn: '129502',
    sku: 'REN02522',
    mfg: 'Renown',
    mfg_number: 'REN02522',
    name: 'Renown EFM 1250 ml Foam Handwash Clear and Mild',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/129502'
  },
  {
    usn: '129503',
    sku: 'REN02525',
    mfg: 'Renown',
    mfg_number: 'REN02525',
    name: 'Renown EFM 1250 ml Foam Hand, Hair and Body Wash',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/129503'
  },
  {
    usn: '129504',
    sku: 'REN02527',
    mfg: 'Renown',
    mfg_number: 'REN02527',
    name: 'Renown EFM 1250 ml Foam Hand Soap Dark Green',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/129504'
  },
  {
    usn: '129905',
    sku: 'GOJ8705-04',
    mfg: 'GOJO INDUSTRIES',
    mfg_number: '8705-04',
    name: 'GOJO INDUSTRIES PURELL ADVANCED INSTANT FOAM HAND SANITIZER REFILL, 700 ML PER REFILL',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/129905'
  },
  {
    usn: '129906',
    sku: 'GOJ8805-03',
    mfg: 'GOJO INDUSTRIES',
    mfg_number: '8805-03',
    name: 'GOJO INDUSTRIES PURELL ADVANCED ORIGINAL INSTANT HAND SANITIZER FOAM 3/1200ML/CS',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/129906'
  },
  {
    usn: '130339',
    sku: 'PGC82074',
    mfg: 'P&G',
    mfg_number: '003700082074',
    name: 'Swiffer Microfiber Dusters Extender Starter Kit',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/130339'
  },
  {
    usn: '132607',
    sku: 'REN02535',
    mfg: 'Renown',
    mfg_number: 'REN02535',
    name: 'Renown EFA 700 ml Foam Hand Soap Pomeberry',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: ''
  },
  {
    usn: '132611',
    sku: 'REN02466',
    mfg: 'Renown',
    mfg_number: 'REN02466',
    name: 'Renown 1,200 ml Clear and Mild EFA Foam Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/132611'
  },
  {
    usn: '132612',
    sku: 'REN02467',
    mfg: 'Renown',
    mfg_number: 'REN02467',
    name: 'Renown 1,200 ml Pomeberry EFA Foam Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/132612'
  },
  {
    usn: '132776',
    sku: 'GOJ1904-02',
    mfg: 'GOJO Industries',
    mfg_number: '1904-02',
    name: 'Purell 1200 ml Advanced Instant Hand Sanitizer',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/132776'
  },
  {
    usn: '132777',
    sku: 'GOJ1905-02',
    mfg: 'GOJO Industries',
    mfg_number: '1905-02',
    name: 'GOJO INDUSTRIES PURELL HAND SANITIZER FOAM 1200 ML INSTANT',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/132777'
  },
  {
    usn: '133425',
    sku: 'REN02469',
    mfg: 'Renown',
    mfg_number: 'REN02469',
    name: 'Renown 2300 ml Foam Soap Cleanser',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/133425'
  },
  {
    usn: '134579',
    sku: 'PTE107252',
    mfg: 'ProTeam',
    mfg_number: '107252',
    name: 'ProTeam Proforce 1500XP Upright Vacuum Cleaner with On-Board Tools',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/134579'
  },
  {
    usn: '159890',
    sku: 'KCC25702',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '25702',
    name: 'Scott White Hard Roll Paper Towels Absorbency Pockets for Dispenser (1150/Roll, 6-Rolls/Case, 6,900 ft.)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/159890'
  },
  {
    usn: '274667',
    sku: 'REN02635',
    mfg: 'Renown',
    mfg_number: 'REN02635',
    name: 'Renown 54 in. Plastic Flat Mop Handle and Frame System',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'WET MOPS & HARDWARE',
    picture: 'http://www.supplyworks.com/ProductImage/274667'
  },
  {
    usn: '281719',
    sku: 'REN06180-WB',
    mfg: 'Renown',
    mfg_number: 'REN06180-WB',
    name: 'Renown Natural 8 in. Controlled High-Capacity Hardwound Paper Towels (1,000 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/281719'
  },
  {
    usn: '282281',
    sku: 'PTE107310',
    mfg: 'ProTeam',
    mfg_number: '107310',
    name: 'ProTeam Super Coach Pro 6, 6 Qt. Backpack Vacuum with Xover Multi-Surface and Telescoping Wand Kit',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/282281'
  },
  {
    usn: '282287',
    sku: 'PTE107303',
    mfg: 'ProTeam',
    mfg_number: '107303',
    name: 'ProTeam Super Coach Pro 10, 10 Qt. Backpack Vacuum with Xover Multi-Surface and Telescoping Wand Tool Kit',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/282287'
  },
  {
    usn: '302970',
    sku: 'KCC01840',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '01840',
    name: 'Scott Absorbency Pockets White Multi-Fold Paper Towels (16 Clips/Case, 250-Sheets/Clip, 4,000 Towels/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/302970'
  },
  {
    usn: '303585',
    sku: 'CLO31036',
    mfg: 'Clorox',
    mfg_number: '31036',
    name: 'Clorox 32 oz. Urine Remover for Stains and Odors Spray',
    category: 'ODOR CONTROL',
    subcategory: 'ODOR NEUTRALIZERS & DISINFECTANTS',
    picture: ''
  },
  {
    usn: '558688',
    sku: 'REN03882',
    mfg: 'Renown',
    mfg_number: 'REN03882',
    name: 'Renown 4in. Floor Scraper Carbon Steel Replacement Blades (10-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SQUEEGEES, WASHERS & SCRAPERS',
    picture: 'http://www.supplyworks.com/ProductImage/558688'
  },
  {
    usn: '559004',
    sku: '559004',
    mfg: 'U.S. Pumice Company',
    mfg_number: 'JAN-12',
    name: 'PUMIE Pumice Scouring Stick',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/559004'
  },
  {
    usn: '880162',
    sku: 'CLO00031',
    mfg: 'The Clorox Company',
    mfg_number: '4460000031',
    name: 'Clorox 24 oz. Toilet Bowl Cleaner',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/880162'
  },
  {
    usn: '880165',
    sku: 'CLO01593',
    mfg: 'The Clorox Company',
    mfg_number: '4460001593',
    name: 'Clorox 35-Count Fresh Scent Bleach Free Disinfecting Wipes',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/880165'
  },
  {
    usn: '880175',
    sku: 'CLO15948',
    mfg: 'The Clorox Company',
    mfg_number: '15948',
    name: 'Clorox 75-Count Lemon Fresh Scented Bleach Free Disinfecting Wipes',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/880175'
  },
  {
    usn: '880182',
    sku: 'CLO35417',
    mfg: 'CLOROX COMPANY',
    mfg_number: 'CLO35417EA',
    name: 'Clorox Clean-Up 32 oz. Bleach Disinfectant Cleaner Spray',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/880182'
  },
  {
    usn: '880257',
    sku: 'REN05002-AM',
    mfg: 'Renown',
    mfg_number: '05002 / A0124',
    name: 'Renown 19 oz. Heavy-Duty Glass Cleaner Aerosol',
    category: 'CHEMICALS',
    subcategory: 'GLASS CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/880257'
  },
  {
    usn: '880262',
    sku: 'REN05005-AM',
    mfg: 'Renown',
    mfg_number: 'REN05005-AM',
    name: 'Renown 15 oz. Oil Based Aerosol Stainless Steel Cleaner',
    category: 'CHEMICALS',
    subcategory: 'SURFACE WAXES & POLISHES',
    picture: 'http://www.supplyworks.com/ProductImage/880262'
  },
  {
    usn: '880263',
    sku: 'REN05006-AM',
    mfg: 'Renown',
    mfg_number: 'REN05006-AM',
    name: 'Renown Stainless Steel 18 oz. Cleaner Water Based Aerosol',
    category: 'CHEMICALS',
    subcategory: 'SURFACE WAXES & POLISHES',
    picture: 'http://www.supplyworks.com/ProductImage/880263'
  },
  {
    usn: '880269',
    sku: 'REN05012-AM',
    mfg: 'Renown',
    mfg_number: 'REN05012-AM',
    name: 'Renown 6 oz. Gum Remover II Aerosol',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/880269'
  },
  {
    usn: '880271',
    sku: 'REN05014-AM',
    mfg: 'Renown',
    mfg_number: 'REN05014-AM',
    name: 'Renown 19 oz. Foam Disinfectant Cleaner Aerosol',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/880271'
  },
  {
    usn: '880282',
    sku: 'REN05052-AM',
    mfg: 'Renown',
    mfg_number: '05052 / A0207',
    name: 'Renown 10 oz. Vanilla Odor Neutralizer Plus Air Freshener Spray',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/880282'
  },
  {
    usn: '880328',
    sku: 'SMP13005',
    mfg: 'Simple Green',
    mfg_number: '2710200613005',
    name: 'Simple Green ALL PURPOSE CONCENTRATED CLEANER, GALLON, SASSAFRAS SCENT',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/880328'
  },
  {
    usn: '880339',
    sku: '880339',
    mfg: 'Gojo Industries, Inc',
    mfg_number: '9652-12',
    name: 'GOJO INDUSTRIES 8 oz. Instant Hand Sanitizer Pump Bottles',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/880339'
  },
  {
    usn: '880372',
    sku: 'GOJ5392-02',
    mfg: 'GOJO Industries Inc',
    mfg_number: '5392-02',
    name: 'Purell Advanced 1200 ml Clean Scent Dye Free Foam Hand Sanitizer TFX Dispenser Refill',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/880372'
  },
  {
    usn: '880373',
    sku: 'REN02548',
    mfg: 'Renown',
    mfg_number: 'REN02548',
    name: 'Renown 1200 ml Hand Foam Soap Refill',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880373'
  },
  {
    usn: '880377',
    sku: 'GOJ2156-08',
    mfg: 'GOJO Industries',
    mfg_number: '2156-08',
    name: 'Purell 1000 ML Instant Hand Sanitizer Refill',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/880377'
  },
  {
    usn: '880388',
    sku: 'REN02526',
    mfg: 'GOJO',
    mfg_number: '5163-03-B4W00LG',
    name: 'Renown 1250 ml Foam Shower Cleanser Refill',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880388'
  },
  {
    usn: '880391',
    sku: 'REN02498',
    mfg: 'Renown',
    mfg_number: 'REN02498',
    name: 'Renown 2000ml Pink Foam Lotion Hand Soap Refill',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880391'
  },
  {
    usn: '880392',
    sku: 'REN02500',
    mfg: 'Renown',
    mfg_number: 'REN02500',
    name: 'Renown 2000 ml Foam Shower Cleanser',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880392'
  },
  {
    usn: '880393',
    sku: 'REN02544',
    mfg: 'Renown',
    mfg_number: 'REN02544',
    name: 'Renown ANTIBACTERIAL FOAM HAND SOAP, TOUCH-FREE, 1,200ML',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880393'
  },
  {
    usn: '880394',
    sku: 'REN02543',
    mfg: 'Renown',
    mfg_number: 'REN02543',
    name: 'Renown ULTRA-MILD FOAM HAND SOAP, TOUCH-FREE, 1,200ML',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: ''
  },
  {
    usn: '880400',
    sku: 'SCJ695155',
    mfg: 'SC Johnson',
    mfg_number: '90139',
    name: 'Windex COPY 0 32 oz Glass Cleaner with Ammonia-D',
    category: 'CHEMICALS',
    subcategory: 'GLASS CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/880400'
  },
  {
    usn: '880401',
    sku: 'SCJ696503',
    mfg: 'SC Johnson',
    mfg_number: '696503',
    name: 'Windex 128 oz Glass Cleaner with Ammonia-D',
    category: 'CHEMICALS',
    subcategory: 'GLASS CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/880401'
  },
  {
    usn: '880632',
    sku: '880632',
    mfg: 'GOJO',
    mfg_number: 'REN02502',
    name: 'Renown 1 Gal. Pink Bulk Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880632'
  },
  {
    usn: '880633',
    sku: 'REN02503',
    mfg: 'GOJO Industries',
    mfg_number: 'REN02503',
    name: 'Renown 1 Gal. per Container Lotion Hand Soap White (4 Containers per Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/880633'
  },
  {
    usn: '880642',
    sku: 'TMS33-2502TMCA',
    mfg: 'Zep Inc.',
    mfg_number: '1042771',
    name: 'ZEP 6.6 oz. Clean and Fresh Automatic Air Freshener Spray Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/880642'
  },
  {
    usn: '880795',
    sku: 'REN05259',
    mfg: 'Renown',
    mfg_number: 'REN05259',
    name: 'Renown 30 in. to 45 in. Lambs Wool Extendable Duster',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/880795'
  },
  {
    usn: '880951',
    sku: 'REN03033-BD',
    mfg: 'Renown',
    mfg_number: '1616AN',
    name: 'Renown 1 Gal. Fresh Scent Water Soluble Deodorant (4 Per Case)',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/880951'
  },
  {
    usn: '881017',
    sku: 'APP15500',
    mfg: 'Appeal',
    mfg_number: 'APP15500',
    name: 'Appeal 35 Qt. Mop Bucket Combination with Side Press',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BUCKETS, WRINGER & PAILS',
    picture: 'http://www.supplyworks.com/ProductImage/881017'
  },
  {
    usn: '881018',
    sku: 'APP15501',
    mfg: 'Appeal',
    mfg_number: 'APP15501',
    name: 'Appeal 35 Qt. Yellow Capacity Down-Press Mop Bucket Combo',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BUCKETS, WRINGER & PAILS',
    picture: 'http://www.supplyworks.com/ProductImage/881018'
  },
  {
    usn: '881027',
    sku: 'REN06001-WB',
    mfg: 'Renown',
    mfg_number: 'REN06001-WB',
    name: 'Renown White Perforated 2-Ply Paper Towel-Roll (84-Sheets/Roll, 30-Rolls/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/881027'
  },
  {
    usn: '881050',
    sku: 'REN05143',
    mfg: 'Renown',
    mfg_number: 'REN05143',
    name: 'Renown Amazing Stain and Mark Sponge Eraser',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/881050'
  },
  {
    usn: '881071',
    sku: 'REN05260-IB',
    mfg: 'Renown',
    mfg_number: 'REN05260-IB',
    name: 'Renown DUSTER EXTENDED POLYWOOL 52-84 IN.',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/881071'
  },
  {
    usn: '881100',
    sku: 'REN08025',
    mfg: 'Sanitaire',
    mfg_number: 'REN08025',
    name: 'Renown 12 in. Single Motor Upright Vacuum Cleaner',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/881100'
  },
  {
    usn: '881122',
    sku: 'REN11005-CA',
    mfg: 'Renown',
    mfg_number: 'REN11005',
    name: 'Renown Fits 10 Gal. Natural 8 mic 24 in. x 24 in. Can Liner (50 Per Roll, 20-Roll Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881122'
  },
  {
    usn: '881123',
    sku: 'REN11510-CA',
    mfg: 'Renown',
    mfg_number: 'REN11510',
    name: 'Renown 24 in. x 33 in. 15 Gal. 8 mic Natural Institutional Can Liner (50 per Roll, 20-Rolls per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881123'
  },
  {
    usn: '881124',
    sku: 'REN12500-CA',
    mfg: 'Renown',
    mfg_number: 'REN12500',
    name: 'Renown 25 Gal. 30 in. x 37 in. 10 mic Natural Can Liner (25/Roll, 20-Roll/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881124'
  },
  {
    usn: '881125',
    sku: 'REN14516-CA',
    mfg: 'Renown',
    mfg_number: 'REN14516',
    name: 'Renown 45 Gal. 40 in. x 48 in. 14 mic Natural Can Liner (25 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881125'
  },
  {
    usn: '881129',
    sku: 'REN21512-CA',
    mfg: 'Renown',
    mfg_number: 'REN21512IB',
    name: 'Renown Fits 12-16 Gal. 0.45 mil 24 in. x 32 in. White Can Liner (50 Per Roll, 10-Roll Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881129'
  },
  {
    usn: '881136',
    sku: 'REN24544-CA',
    mfg: 'Renown',
    mfg_number: 'REN24544IB',
    name: 'Renown 45 Gal. 1.2 mil 40 in. x 46 in. Black Can Liner (20 per Roll, 5-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881136'
  },
  {
    usn: '881138',
    sku: 'REN25621-CA',
    mfg: 'Renown',
    mfg_number: 'REN25621IB',
    name: 'Renown 56 Gal. 1.7 mil 43 in. x 47 in. Black Can Liner (10 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881138'
  },
  {
    usn: '881140',
    sku: 'REN26016-CA',
    mfg: 'Renown',
    mfg_number: 'REN26016IB',
    name: 'Renown 60 Gal. 1.2 mil 38 in. x 58 in. Black Can Liner (20 per Roll, 5-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881140'
  },
  {
    usn: '881141',
    sku: 'REN26026-CA',
    mfg: 'Renown',
    mfg_number: 'REN26026IB',
    name: 'Renown 60 Gal. Black 1.7 mil 38 in. x 58 in. Can Liner (10 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881141'
  },
  {
    usn: '881179',
    sku: 'PGC02287',
    mfg: 'P&G',
    mfg_number: '003700002287',
    name: 'Comet 32 oz. Cleaner with Bleach Spray Bottle',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/881179'
  },
  {
    usn: '881181',
    sku: 'PGC45112',
    mfg: 'P&G',
    mfg_number: '003700045112',
    name: 'Dawn Professional 38 oz. Original Scent Dishwashing Liquid (8-Case)',
    category: 'CHEMICALS',
    subcategory: 'DISHWASHING DETERGENTS & ADDITIVES',
    picture: 'http://www.supplyworks.com/ProductImage/881181'
  },
  {
    usn: '881515',
    sku: 'REN06004-WB',
    mfg: 'Renown',
    mfg_number: 'REN06004-WB',
    name: 'Renown Natural Hardwound Paper Towels (800 ft. Per Roll, 6 Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/881515'
  },
  {
    usn: '881517',
    sku: 'REN06104-WB',
    mfg: 'Renown',
    mfg_number: 'REN06104-WB',
    name: 'Renown Single Roll 2-Ply 4.5 in. x 3.75 in. Toilet Paper (500 Sheets per Roll 96 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/881517'
  },
  {
    usn: '881520',
    sku: 'REN06003-WB',
    mfg: 'Renown',
    mfg_number: 'REN06003-WB',
    name: 'Renown Natural Multifold Paper Towels (250 Sheets Per Pack 16 Packs Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/881520'
  },
  {
    usn: '881590',
    sku: 'GPT19375',
    mfg: 'Georgia-Pacific',
    mfg_number: '19375',
    name: 'Compact 2-Ply White Coreless Bath Tissue Toilet Paper (36-Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/881590'
  },
  {
    usn: '881592',
    sku: 'GPT19880-01',
    mfg: 'Georgia-Pacific',
    mfg_number: '19880-01',
    name: 'Georgia-Pacific 2-Ply Bathroom Tissue, Toilet Paper, White (80-Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/881592'
  },
  {
    usn: '881652',
    sku: 'CROPP-RB-200',
    mfg: 'Crown Products',
    mfg_number: 'PP-RB-200',
    name: 'Poopy Pouch Universal Pet Waste Disposal Replacement Bags (200 Bags per Roll, 10 Rolls per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'SPECIAL USE BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881652'
  },
  {
    usn: '881712',
    sku: 'REN05118',
    mfg: 'Renown',
    mfg_number: '5681(REN05118)',
    name: 'Renown 12.25 in. Chemical Resistant Trigger Sprayer with 9-7/8 in. Tube',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'PUMPS, SPRAYERS & BOTTLES',
    picture: 'http://www.supplyworks.com/ProductImage/881712'
  },
  {
    usn: '881716',
    sku: 'REN05127',
    mfg: 'Renown',
    mfg_number: '1525(REN05127)',
    name: 'Renown Orchard Zing Disposable Urinal Floor Mat',
    category: 'ODOR CONTROL',
    subcategory: 'URINAL & TOILET DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/881716'
  },
  {
    usn: '881747',
    sku: 'REN05147-IB',
    mfg: 'Renown',
    mfg_number: 'REN05147-IB',
    name: 'Renown DELUXE BUCKET, GRAY, 10 QUART, HEAVY-DUTY',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BUCKETS, WRINGER & PAILS',
    picture: 'http://www.supplyworks.com/ProductImage/881747'
  },
  {
    usn: '883002',
    sku: 'REN05124',
    mfg: 'Renown',
    mfg_number: 'REN05124',
    name: 'Renown 11 in. Black Upright Dust Pan',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BROOMS, BRUSHES & DUST PANS',
    picture: 'http://www.supplyworks.com/ProductImage/883002'
  },
  {
    usn: '883075',
    sku: 'REN08002-VP',
    mfg: 'Renown',
    mfg_number: 'VN2015(REN08002)',
    name: 'Renown 20in. Low Speed Floor Machine with Pad Driver',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINES & BURNISHERS',
    picture: 'http://www.supplyworks.com/ProductImage/883075'
  },
  {
    usn: '883076',
    sku: 'REN08006-VP',
    mfg: 'Renown',
    mfg_number: 'VN1500(REN08006)',
    name: 'Renown 20 in. High-Speed Electric Floor Polisher Commercial Grade, 1500RPM',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINES & BURNISHERS',
    picture: 'http://www.supplyworks.com/ProductImage/883076'
  },
  {
    usn: '883639',
    sku: 'REN08050',
    mfg: 'Renown',
    mfg_number: 'REN08050',
    name: 'Renown Vacuum Bag for Windsor Sensor, Equivalent to 5300 (10-Bags/Pack)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUM BAGS & ACCESSORIES',
    picture: 'http://www.supplyworks.com/ProductImage/883639'
  },
  {
    usn: '883640',
    sku: 'REN08051',
    mfg: 'Renown',
    mfg_number: 'REN08051',
    name: 'Renown Vacuum Bag for ProTeam Mega Vac Equivalent to OEM# 100331 (10-Bags/Pack)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUM BAGS & ACCESSORIES',
    picture: 'http://www.supplyworks.com/ProductImage/883640'
  },
  {
    usn: '2462443',
    sku: 'REN07109-MS',
    mfg: 'Renown',
    mfg_number: '111523',
    name: 'Renown 128 oz. Lavender Multi-Purpose Cleaner',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/2462443'
  },
  {
    usn: '2464996',
    sku: 'APP17100-04',
    mfg: 'Appeal',
    mfg_number: '51228-04',
    name: 'Appeal 1 L Appeal Foaming General Purpose Hand Soap Cartridge, 4 per case',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/2464996'
  },
  {
    usn: '2464998',
    sku: 'APP17102-04',
    mfg: 'Appeal',
    mfg_number: '01830-04',
    name: 'Appeal 1 l Foaming Hand Sanitizer Alcohol Cartridge (4-Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/2464998'
  },
  {
    usn: '2464999',
    sku: 'APP17103-04',
    mfg: 'Appeal',
    mfg_number: '55830-04',
    name: 'Appeal 1 l Appeal Foaming Hand Sanitizer Non-Alcohol Cartridge (4-Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/2464999'
  },
  {
    usn: '2468567',
    sku: 'REN06007-SP',
    mfg: 'Renown',
    mfg_number: 'REN06007-SP',
    name: 'Renown 2-Ply Bright White Premium Kitchen-Roll Towel',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/2468567'
  },
  {
    usn: '2468570',
    sku: 'REN06109-SP',
    mfg: 'Renown',
    mfg_number: 'REN06109-SP',
    name: 'Renown Premium Junior 3.35 in. x 1000 ft. 2-Ply Jumbo Soft Superior Quality White LEED Certified (12-Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/2468570'
  },
  {
    usn: '2468571',
    sku: 'REN06110-SP',
    mfg: 'Renown',
    mfg_number: 'REN06110-SP',
    name: 'Renown Premium Junior 3.35 in. x 850 ft. 2-Ply Soft Superior Quality Bright White LEED Certified (12-Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/2468571'
  },
  {
    usn: '2470349',
    sku: 'CCX911062-G4',
    mfg: 'CLEAN CONTROL CORPORATION',
    mfg_number: 'ODO911062G4',
    name: 'OdoBan 1 Gal.  Concentrated Odor Eliminator, Eucalyptus, Bottle, 4/Carton',
    category: 'ODOR CONTROL',
    subcategory: 'ODOR NEUTRALIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/2470349'
  },
  {
    usn: '2472214',
    sku: 'REN06122-WB',
    mfg: 'Renown',
    mfg_number: 'REN06122-WB',
    name: 'Renown High-Capacity OptiCore 2-Ply Toilet Paper (2,000 Sheets per Roll 12 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/2472214'
  },
  {
    usn: '2473137',
    sku: 'SAR26821',
    mfg: 'Solaris Paper',
    mfg_number: '26821',
    name: 'LoCor 2-Ply Premium High Capacity White Bath Tissue/Toilet Paper (36 Rolls per Pack)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/2473137'
  },
  {
    usn: '2473469',
    sku: 'WIN1.012-050.0',
    mfg: 'Karcher',
    mfg_number: '1.012-050.0',
    name: 'Karcher Versamatic 14 in. Upright Vacuum Cleaner',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/2473469'
  },
  {
    usn: '2480492',
    sku: 'JWP95115958',
    mfg: 'DIVERSEY INC',
    mfg_number: '95115958',
    name: 'Bravo 5 Gal. Heavy-Duty Floor Stripper',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/2480492'
  },
  {
    usn: '2480496',
    sku: 'JWP93063390',
    mfg: 'Diversey',
    mfg_number: '93063390',
    name: 'STRIDE 5 Liter RTD Citrus Neutral Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/2480496'
  },
  {
    usn: '2480497',
    sku: 'JWP95032360',
    mfg: 'National Brand Alternative',
    mfg_number: '95032360',
    name: 'Diversey Pro Strip 5 Gal. Heavy-Duty Floor Stripper',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/2480497'
  },
  {
    usn: '2481047',
    sku: 'REN07137-SB',
    mfg: 'Renown',
    mfg_number: '116638',
    name: 'Renown 80 oz. Neutral Disinfectant Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/2481047'
  },
  {
    usn: '2481222',
    sku: 'JWP904716',
    mfg: 'Diversey',
    mfg_number: '904716',
    name: 'STRIDE Accumix 2.5 l Citrus Hc J-Fill Neutral Cleaner (2 per Case)',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/2481222'
  },
  {
    usn: '2489930',
    sku: 'SPA2200-04-C',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '220004',
    name: 'Spartan SC-200 1 Gal. Industrial Degreaser (4 per Pack)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/2489930'
  },
  {
    usn: '2489944',
    sku: 'SPA7118-12-C',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '711803',
    name: 'Spartan SparCling 1 Qt. Mint Scent 1-Step Disinfectant Toilet Bowl Cleaner (12 per Pack)',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/2489944'
  },
  {
    usn: '2490969',
    sku: 'REN03119',
    mfg: 'Renown',
    mfg_number: 'REN03119',
    name: 'Renown Wave 3D Spiced Apple Urinal Screen',
    category: 'ODOR CONTROL',
    subcategory: 'URINAL & TOILET DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/2490969'
  },
  {
    usn: '2490971',
    sku: 'REN03121',
    mfg: 'Renown',
    mfg_number: 'REN03121',
    name: 'Renown Wave 3D Mango Urinal Screen',
    category: 'ODOR CONTROL',
    subcategory: 'URINAL & TOILET DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/2490971'
  },
  {
    usn: '2490973',
    sku: 'REN03123',
    mfg: 'Renown',
    mfg_number: 'REN03123',
    name: 'Renown Wave 3D Cotton Blossom Urinal Screen',
    category: 'ODOR CONTROL',
    subcategory: 'URINAL & TOILET DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/2490973'
  },
  {
    usn: '3553470',
    sku: 'MMM55654-W',
    mfg: '3M',
    mfg_number: '55654W',
    name: '3M 8 in x 6 in Easy Trap Sweep and Dust Sheets (250 Sheets per Roll) (1 Roll per Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/3553470'
  },
  {
    usn: '3553471',
    sku: 'MMM55655-W',
    mfg: '3M',
    mfg_number: '55655',
    name: '3M 5 in. x 6 in. Easy Trap Sweep and Dust Sheets (250 Sheets per Roll) (2 Rolls per Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/3553471'
  },
  {
    usn: '3559267',
    sku: 'RCP1966719',
    mfg: 'Rubbermaid',
    mfg_number: '1966719',
    name: 'Rubbermaid Commercial Products 24 Gal. Vinyl Janitorial Cleaning Cart Bag',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'CLEANING CARTS & CADDIES',
    picture: 'http://www.supplyworks.com/ProductImage/3559267'
  },
  {
    usn: '3559928',
    sku: 'HOOCH90040',
    mfg: 'Hoover Company',
    mfg_number: 'CH90040',
    name: 'HOOVER Hushtone M-Pwer 40-Volt Battery',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUM BAGS & ACCESSORIES',
    picture: 'http://www.supplyworks.com/ProductImage/3559928'
  },
  {
    usn: '3570803',
    sku: 'REN08008-VP',
    mfg: 'Renown',
    mfg_number: 'REN08008-VP',
    name: 'Renown 3-Speed Blower',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'BLOWERS & AIR MOVERS',
    picture: 'http://www.supplyworks.com/ProductImage/3570803'
  },
  {
    usn: '3575363',
    sku: 'REN02563',
    mfg: 'Renown',
    mfg_number: 'REN02563',
    name: 'Renown 1250 ml Antibacterial EFM Foam Handwash Manual Refill Plum',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3575363'
  },
  {
    usn: '3575365',
    sku: 'REN02565',
    mfg: 'Renown',
    mfg_number: 'REN02565',
    name: 'Renown 1200 ml Antibacterial EFA Foam Handwash Touch Free Refill Plum',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3575365'
  },
  {
    usn: '3577410',
    sku: 'APP17201-04',
    mfg: 'Appeal',
    mfg_number: 'APP17201-04',
    name: 'Appeal 1 l Cartridge Antibacterial Foam Hand Soap (4-Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577410'
  },
  {
    usn: '3577818',
    sku: 'REN03047',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03047',
    name: 'Renown 3000 Series 2 oz. Orange Citrus Odor Neutralizer Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577818'
  },
  {
    usn: '3577819',
    sku: 'REN03048',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03048',
    name: 'Renown 3000 Series 2 oz. Spring Flowers Odor Neutralizer Aerosol Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577819'
  },
  {
    usn: '3577820',
    sku: 'REN03049',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03049',
    name: 'Renown 3000 Series 2 oz. Ripe Mango Odor Neutralizer Aerosol Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577820'
  },
  {
    usn: '3577823',
    sku: 'REN03076',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03076',
    name: 'Renown 3000 Series 2 oz. Cotton Fresh Odor Neutralizer Aerosol Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577823'
  },
  {
    usn: '3577824',
    sku: 'REN03077',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03077',
    name: 'Renown 6 oz. Orange Citrus Odor Neutralizer Aerosol Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577824'
  },
  {
    usn: '3577825',
    sku: 'REN03078',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03078',
    name: 'Renown 6 oz. Spring Flowers Odor Neutralizer Aerosol Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577825'
  },
  {
    usn: '3577826',
    sku: 'REN03079',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03079',
    name: 'Renown 6 oz. Ripe Mango Odor Neutralizer Aerosol Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577826'
  },
  {
    usn: '3577829',
    sku: 'REN03082',
    mfg: 'Rubbermaid',
    mfg_number: 'REN03082',
    name: 'Renown 6 oz. Cotton Fresh Odor Neutralizer Dispenser Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577829'
  },
  {
    usn: '3577830',
    sku: 'REN03083',
    mfg: 'Renown',
    mfg_number: 'REN03083',
    name: 'Renown Comfresh 1.6 oz. Orange Citrus Continuous Air Freshener/Neutralizer Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577830'
  },
  {
    usn: '3577831',
    sku: 'REN03084',
    mfg: 'Renown',
    mfg_number: 'REN03084',
    name: 'Renown Comfresh 1.6 oz. Spring Flowers Continuous Air Freshener/Neutralizer Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577831'
  },
  {
    usn: '3577832',
    sku: 'REN03085',
    mfg: 'Renown',
    mfg_number: 'REN03085',
    name: 'Renown Comfresh 1.6 oz. Ripe Mango Continuous Air Freshener/Neutralizer Refill',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577832'
  },
  {
    usn: '3577835',
    sku: 'REN03092',
    mfg: 'Renown',
    mfg_number: 'REN03092',
    name: 'Renown Comfresh 1.6 oz. Cotton Fresh Continuous Plug-In Air Freshener Refill (1-Pack)',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577835'
  },
  {
    usn: '3581840',
    sku: 'REN02566',
    mfg: 'GOJO',
    mfg_number: 'REN02566',
    name: 'Renown Select RB8 1.2 l Clear and Mild Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3581840'
  },
  {
    usn: '3581841',
    sku: 'REN02567',
    mfg: 'GOJO',
    mfg_number: 'REN02567',
    name: 'Renown Select RB8 1.2 l Pomeberry Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3581841'
  },
  {
    usn: '3581842',
    sku: 'REN02568',
    mfg: 'GOJO',
    mfg_number: 'REN02568',
    name: 'Renown Select RB8 1.2 l Plum Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3581842'
  },
  {
    usn: '3581843',
    sku: 'REN02569',
    mfg: 'GOJO',
    mfg_number: 'REN02569',
    name: 'Renown Select RB6 1.2 l Clear and Mild Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3581843'
  },
  {
    usn: '3581844',
    sku: 'REN02570',
    mfg: 'GOJO',
    mfg_number: 'REN02570',
    name: 'Renown Select RB6 1.2 l Pomeberry Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3581844'
  },
  {
    usn: '3581845',
    sku: 'REN02571',
    mfg: 'GOJO',
    mfg_number: 'REN02571',
    name: 'Renown Select RB6 1.2 l Plum Hand Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3581845'
  },
  {
    usn: '3582836',
    sku: 'REN02573',
    mfg: 'Renown',
    mfg_number: 'REN02573',
    name: 'Renown RB6 1200 ml Clean Release Tech Foaming Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3582836'
  },
  {
    usn: '3582837',
    sku: 'REN02574',
    mfg: 'Renown',
    mfg_number: 'REN02574',
    name: 'Renown 5.72 oz. RB8 Clean Release Tech Foaming Soap',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/3582837'
  },
  {
    usn: '307892351',
    sku: '307892351',
    mfg: 'Renown',
    mfg_number: 'REN08031-VP',
    name: 'Renown 16 in. Self Contained Upright Carpet Extractor',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'CARPET & UPHOLSTERY CLEANING EQUIPMENT',
    picture: 'http://www.supplyworks.com/ProductImage/307892351'
  },
  {
    usn: '309116312',
    sku: '309116312',
    mfg: 'Renown',
    mfg_number: 'REN06310WB',
    name: 'Renown Single Roll 2-Ply 4 in. x 3.75 in. Toilet Paper (500 Sheets Per Roll, 96 Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/309116312'
  },
  {
    usn: '309255847',
    sku: '309255847',
    mfg: 'Adamax',
    mfg_number: 'REN01212-BLZ',
    name: 'Renown 12 in. x 12 in. General Purpose Microfiber Cleaning Cloth, Blue (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255847'
  },
  {
    usn: '309255848',
    sku: '309255848',
    mfg: 'Adamax',
    mfg_number: 'REN01212-GRZ',
    name: 'Renown 12 in. x 12 in. General Purpose Microfiber Cleaning Cloth, Green (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255848'
  },
  {
    usn: '309255850',
    sku: '309255850',
    mfg: 'Adamax',
    mfg_number: 'REN01818-BGZ',
    name: 'Renown 18 in. x 18 in. Glass Cleaning Microfiber Cloth, Blue (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255850'
  },
  {
    usn: '309255851',
    sku: '309255851',
    mfg: 'Adamax',
    mfg_number: 'REN01616-BPZ',
    name: 'Renown 16 in. x 16 in. Premium Microfiber Cleaning Cloth, Blue (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255851'
  },
  {
    usn: '309255852',
    sku: '309255852',
    mfg: 'Adamax',
    mfg_number: 'REN01616-YPZ',
    name: 'Renown 16 in. x 16 in. Premium Microfiber Cleaning Cloth, Yellow (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255852'
  },
  {
    usn: '309255855',
    sku: '309255855',
    mfg: 'Adamax',
    mfg_number: 'REN01616-REZ',
    name: 'Renown 16 in. x 16 in. General Purpose Microfiber Cleaning Cloth, Red (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255855'
  },
  {
    usn: '309255857',
    sku: '309255857',
    mfg: 'Adamax',
    mfg_number: 'REN01616-YEZ',
    name: 'Renown 16 in. x 16 in. General Purpose Microfiber Cleaning Cloth, Yellow (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255857'
  },
  {
    usn: '309255859',
    sku: '309255859',
    mfg: 'Adamax',
    mfg_number: 'REN01616-BLZ',
    name: 'Renown 16 in. x 16 in. General Purpose Microfiber Cleaning Cloth, Blue (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255859'
  },
  {
    usn: '309255860',
    sku: '309255860',
    mfg: 'Adamax',
    mfg_number: 'REN01616-GRZ',
    name: 'Renown 16 in. x 16 in. General Purpose Microfiber Cleaning Cloth in Green (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255860'
  },
  {
    usn: '309255861',
    sku: '309255861',
    mfg: 'Adamax',
    mfg_number: 'REN01212-REZ',
    name: 'Renown 12 in. x 12 in. General Purpose Microfiber Cleaning Cloth in Red (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255861'
  },
  {
    usn: '309255863',
    sku: '309255863',
    mfg: 'Adamax',
    mfg_number: 'REN01212-YEZ',
    name: 'Renown 12 in. x 12 in. General Purpose Microfiber Cleaning Cloth in Yellow (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255863'
  },
  {
    usn: '309330283',
    sku: '309330283',
    mfg: 'Renown',
    mfg_number: 'HFTSC-V',
    name: 'Renown Half-Fold Toilet Seat Paper Cover-Virgin',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET SEAT COVERS',
    picture: 'http://www.supplyworks.com/ProductImage/309330283'
  },
  {
    usn: '309330284',
    sku: '309330284',
    mfg: 'Renown',
    mfg_number: 'HFTSC-R',
    name: 'Renown Half-Fold Toilet Seat Paper Cover-Recycled',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET SEAT COVERS',
    picture: 'http://www.supplyworks.com/ProductImage/309330284'
  },
  {
    usn: '310413436',
    sku: '310413436',
    mfg: 'Renown',
    mfg_number: 'REN06449WB',
    name: 'Renown White Advanced Multi-Fold Paper Towels (250 Sheets per Pack, 16-Packs per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/310413436'
  },
  {
    usn: '310413445',
    sku: '310413445',
    mfg: 'Renown',
    mfg_number: 'REN06484WB',
    name: 'Renown Premium 8 in. Bright White Luxury Controlled Hardwound Paper Towels (600 ft. per Roll, 6 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/310413445'
  },
  {
    usn: '310602128',
    sku: '310602128',
    mfg: 'Royal Appliance Mfg. Co.',
    mfg_number: 'CH93619',
    name: 'HOOVER MPWR Cordless Backpack Vacuum Cleaner',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/310602128'
  },
  {
    usn: '311422334',
    sku: '311422334',
    mfg: 'P&G',
    mfg_number: '003700073041',
    name: 'Febreze Air Effects 8.8 oz. Morning and Dew Scent Air Freshener Spray (2-Pack)',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/311422334'
  },
  {
    usn: '311523650',
    sku: '311523650',
    mfg: 'National Brand Alternative',
    mfg_number: '8565-04-B4W00LG',
    name: 'Renown Select ThruCounter 1500 ml Fragrance Free, Green-Certified- Mild Foam Handwash Refill for CXM/CXI/CXT Dispensers',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/311523650'
  },
  {
    usn: '311523651',
    sku: '311523651',
    mfg: 'GOJO',
    mfg_number: '8561-04-B4W00LG',
    name: 'Renown Select ThruCounter 1500 ml Fresh Scent, Green-Certified- Lotion Foam Handwash Refill for CXM/CXI/CXT Dispensers',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/311523651'
  },
  {
    usn: '312255407',
    sku: '312255407',
    mfg: 'Renown',
    mfg_number: 'REN06446WB',
    name: 'Renown 8 in. White Advanced Controlled Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/312255407'
  },
  {
    usn: '312255408',
    sku: '312255408',
    mfg: 'Renown',
    mfg_number: 'REN06447WB',
    name: 'Renown 7.5 in. White Advanced Controlled Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/312255408'
  },
  {
    usn: '109063',
    sku: 'REN66016-CA',
    mfg: 'Renown',
    mfg_number: 'REN66016-CA',
    name: 'Renown 60 Gal. 1.5 mil 38 in. x 58 in. Black Can Liner (10 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/109063'
  },
  {
    usn: '109065',
    sku: 'REN64516-CA',
    mfg: 'Renown',
    mfg_number: 'REN64516-CA',
    name: 'Renown Fits 45 Gal. 1.5 mil 40 in. x 46 in. Black Can Liner (10 Per Roll, 10-Roll Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/109065'
  },
  {
    usn: '110321',
    sku: 'TYCVLH4048-16N',
    mfg: 'Berry Plastics',
    mfg_number: 'VLH4048-16N',
    name: 'Berry Plastics 44 Gal. 14 mic 40 in. x 46 in. Natural High-Density Trash Bags (25/Roll, 10-Rolls/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/110321'
  },
  {
    usn: '110805',
    sku: 'NTINT1025-X-00015',
    mfg: 'NORTHERN TECHNOLOGIES',
    mfg_number: 'NT1025-X-00015',
    name: 'Natur-Bag 55 gal. Compostable Trash Bags, 42 in. x 48 in., 1.0 MIL, Green, 20/Roll, 5 Rolls/Case',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/110805'
  },
  {
    usn: '115606',
    sku: 'TYCHH304314BL',
    mfg: 'Berry Plastics',
    mfg_number: 'HH304314BL',
    name: 'Berry Plastics 20 Gal. High-Density Blue Trash Bags (25/Roll, 10 Rolls/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'HAZARDOUS MATERIAL CONTAINMENT BAGS',
    picture: 'http://www.supplyworks.com/ProductImage/115606'
  },
  {
    usn: '115610',
    sku: 'TYCHH384614BL',
    mfg: 'Berry Plastics',
    mfg_number: 'HH384614BL',
    name: 'Berry Plastics 32 Gal. High-Density Blue Trash Bags (25/Roll, 10 Rolls/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'HAZARDOUS MATERIAL CONTAINMENT BAGS',
    picture: 'http://www.supplyworks.com/ProductImage/115610'
  },
  {
    usn: '136807',
    sku: '760827',
    mfg: 'Crown Products',
    mfg_number: 'PP-13GAL-BAGS',
    name: 'Poopy Pouch Poopy Pouch 13 Gal. Trash Bags for Pet Waste Station Receptacles',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'SPECIAL USE BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/136807'
  },
  {
    usn: '152626',
    sku: 'NTINT1025-X-00014',
    mfg: 'NORTHERN TECHNOLOGIES',
    mfg_number: 'NT1025-X-00014',
    name: 'Natur-Bag 33 Gal. 33 in. x 40 in. 0.8 mil Green Compostable Trash Bags Slim Liner (25/Roll, 8 Rolls/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/152626'
  },
  {
    usn: '202593573',
    sku: '202593573',
    mfg: 'Cordova',
    mfg_number: 'EHF10S',
    name: 'Cordova Bulldog Safety Glasses Single Wrap Around Clear Scratch Resistant Lens',
    category: 'PERSONAL PROTECTION',
    subcategory: 'EYE & FACE PROTECTION',
    picture: 'http://www.supplyworks.com/ProductImage/202593573'
  },
  {
    usn: '214266',
    sku: '214266',
    mfg: 'National Brand Alternative',
    mfg_number: '214266',
    name: '32 in. Easy Reach Pick-Up Tool Trash Picker',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE MANAGEMENT TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/214266'
  },
  {
    usn: '2470668',
    sku: '2470668',
    mfg: 'Crown Products',
    mfg_number: 'PP-H-200',
    name: 'Crown Products Poopy Pouch Pet Waste Header Bags (200 Bags per Header, 12 Headers per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'SPECIAL USE BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/2470668'
  },
  {
    usn: '2472476',
    sku: 'SPA4803',
    mfg: 'SPARTAN CHEMICAL COMPANY',
    mfg_number: '480302',
    name: 'Xcelente Xcelente 2 Liter Lavender Scent Multi-Purpose Cleaner',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/2472476'
  },
  {
    usn: '2474750',
    sku: 'DII710301',
    mfg: 'Commercial ZoneÂ®',
    mfg_number: '710301',
    name: "COMMERCIAL ZONE Smoker's Outpost Site Saver 1.25 Gal. Black Cigarette Receptacle Outdoor Ashtray",
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'SMOKING MANAGEMENT',
    picture: 'http://www.supplyworks.com/ProductImage/2474750'
  },
  {
    usn: '2496863',
    sku: '2496863',
    mfg: 'Justrite Mfg',
    mfg_number: '26800D',
    name: 'Justrite Mfg JUSTRITE ORIGINAL CEASE-FIRE CIGARETTE BUTT RECEPTACLE, 4 GALLON CAPACITY, BLACK',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'SMOKING MANAGEMENT',
    picture: 'http://www.supplyworks.com/ProductImage/2496863'
  },
  {
    usn: '294286',
    sku: '294286',
    mfg: 'Honeywell International, Inc',
    mfg_number: 'NSPA610S',
    name: 'North Uvex A600 Economical Goggle',
    category: 'PERSONAL PROTECTION',
    subcategory: 'EYE & FACE PROTECTION',
    picture: 'http://www.supplyworks.com/ProductImage/294286'
  },
  {
    usn: '295864',
    sku: '295864',
    mfg: 'Kimberly-Clark',
    mfg_number: '25679',
    name: 'KLEENGUARD Black KleenGuard Nemesis Safety Glasses with Clear Anti-Fog Lens',
    category: 'PERSONAL PROTECTION',
    subcategory: 'EYE & FACE PROTECTION',
    picture: 'http://www.supplyworks.com/ProductImage/295864'
  },
  {
    usn: '309255862',
    sku: '309255862',
    mfg: 'Adamax',
    mfg_number: 'REN01616-GPZ',
    name: 'Renown 16 in. x 16 in. Premium Microfiber Cleaning Cloth in Green (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255862'
  },
  {
    usn: '309255864',
    sku: '309255864',
    mfg: 'Adamax',
    mfg_number: 'REN01616-RPZ',
    name: 'Renown Premium 16 in. x 16 in. Microfiber Cloth, Red (12-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/309255864'
  },
  {
    usn: '309583515',
    sku: '309583515',
    mfg: 'Berry Global',
    mfg_number: 'HSP3339HPR',
    name: 'Hospi-Tuff 33 Gal. Red Biohazard Can Liner (200 per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'SPECIAL USE BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/309583515'
  },
  {
    usn: '309793095',
    sku: '309793095',
    mfg: '3M',
    mfg_number: '47212H1-VDC-PS',
    name: '3M Scotchgard Protector Gray/Red Anti-Fog Goggles with Clear Lens',
    category: 'PERSONAL PROTECTION',
    subcategory: 'EYE & FACE PROTECTION',
    picture: 'http://www.supplyworks.com/ProductImage/309793095'
  },
  {
    usn: '310451948',
    sku: '310451948',
    mfg: 'TORK',
    mfg_number: '424824',
    name: 'TORK Advanced White 3-Panel Multi-Fold Paper Towels (250-Sheets/Pack)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/310451948'
  },
  {
    usn: '310650025',
    sku: '310650025',
    mfg: 'Bioesque Solutions',
    mfg_number: 'BBDS5G',
    name: 'BIOESQUE 5 Gal. Botanical Disinfectant Solution Pail',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/310650025'
  },
  {
    usn: '310650026',
    sku: '310650026',
    mfg: 'Bioesque Solutions',
    mfg_number: 'BBDS55G',
    name: 'BIOESQUE 55 Gal. Botanical Disinfectant Solution Drum',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/310650026'
  },
  {
    usn: '310650027',
    sku: '310650027',
    mfg: 'Bioesque Solutions',
    mfg_number: 'BBDSQ',
    name: 'BIOESQUE 1 Qt. Botanical Disinfectant Solution',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/310650027'
  },
  {
    usn: '310650028',
    sku: '310650028',
    mfg: 'Bioesque Solutions',
    mfg_number: 'BBDSG',
    name: 'BIOESQUE 1 Gal. Botanical Disinfectant Solution',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/310650028'
  },
  {
    usn: '312063249',
    sku: '312063249',
    mfg: 'Cordova',
    mfg_number: 'EOB10S',
    name: 'Cordova Catalyst Clear Safety Glasses with Black Frame',
    category: 'PERSONAL PROTECTION',
    subcategory: 'EYE & FACE PROTECTION',
    picture: 'http://www.supplyworks.com/ProductImage/312063249'
  },
  {
    usn: '313585361',
    sku: '313585361',
    mfg: 'Diversey, INc.',
    mfg_number: '101104055',
    name: 'Diversey Oxivir TB 5 Gal. Cherry Almond RTU Disinfectant Pail',
    category: 'CHEMICALS',
    subcategory: 'SURFACE CLEANERS & DEGREASERS',
    picture: 'http://www.supplyworks.com/ProductImage/313585361'
  },
  {
    usn: '3558126',
    sku: 'SPA4856-02',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '485602',
    name: 'SPARTAN CHEMICAL COMPANY BNC-15 2 Liter Floral Scent One Step Cleaner/Disinfectant (4 per Pack)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/3558126'
  },
  {
    usn: '3577691',
    sku: 'PXN76X94X.005',
    mfg: 'POLY PLASTIC PRODUCTS',
    mfg_number: '',
    name: 'POLY PLASTIC PRODUCTS GRN TINT LINER 76X94',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/3577691'
  },
  {
    usn: '881072',
    sku: 'REN66030-CA',
    mfg: 'Renown',
    mfg_number: 'REN66030-CA',
    name: 'Renown Fits 60 Gal. Black 3 mil 38 in. x 58 in. Trash Can Liner (5 Per Roll, 10-Rolls Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881072'
  },
  {
    usn: '881217',
    sku: '760671',
    mfg: 'National Brand Alternative',
    mfg_number: '1DK200',
    name: 'Fits 0.90 mil 13 Gal. 24.5 in. x 27 in. White Drawstring Can Liner (25 per Roll, 8 Rolls per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881217'
  },
  {
    usn: '881650',
    sku: '454355',
    mfg: 'Crown Products',
    mfg_number: 'PP-SD-01-2R400',
    name: 'Crown Products Poopy Pouch Pet Waste Disposal System with Channel Post',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE CONTAINERS & LIDS',
    picture: 'http://www.supplyworks.com/ProductImage/881650'
  },
  {
    usn: '881653',
    sku: '454356',
    mfg: 'Crown Products',
    mfg_number: 'SD-6-400',
    name: 'POOPY POUCH Replacement Pet Waste Bags (400 Bags Per Roll, 6 Rolls Per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'SPECIAL USE BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/881653'
  },
  {
    usn: 'GOJ9118-02',
    sku: 'GOJ9118-02',
    mfg: 'GOJO Industries',
    mfg_number: '9118-02',
    name: 'Purell Clear Lemon Scent Hand Sanitizing Wipes (1200 Wipes per Pouch) (2 Pouches per Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SANITIZER',
    picture: 'http://www.supplyworks.com/ProductImage/GOJ9118-02'
  },
  {
    usn: 'GPT19378',
    sku: 'GPT19378',
    mfg: 'Georgia-Pacific',
    mfg_number: '19378',
    name: 'Compact White Coreless 2-Ply High Capacity Toilet Paper (18-Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/GPT19378'
  },
  {
    usn: 'GPT89420',
    sku: 'GPT89420',
    mfg: 'Georgia-Pacific',
    mfg_number: '89420',
    name: 'enMotion 8 in. White 1-Ply Towel Roll (6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/GPT89420'
  },
  {
    usn: 'GPT89430',
    sku: 'GPT89430',
    mfg: 'Georgia-Pacific',
    mfg_number: '89430',
    name: 'enMotion 8 in. 1-Ply White Recycled Towel Roll',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/GPT89430'
  },
  {
    usn: 'GPT89460',
    sku: 'GPT89460',
    mfg: 'Georgia-Pacific',
    mfg_number: '89460',
    name: 'enMotion 10 in. White Hardwound Paper Towel Roll (6-Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/GPT89460'
  },
  {
    usn: 'GPT89480',
    sku: 'GPT89480',
    mfg: 'Georgia-Pacific',
    mfg_number: '89480',
    name: 'enMotion Brown High Capacity Hardwound Paper Towel Roll (6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/GPT89480'
  },
  {
    usn: 'HOSHS-6141',
    sku: 'HOSHS-6141',
    mfg: 'Hopsital Specialty Co.',
    mfg_number: 'HS-6141',
    name: 'Hospital Specialty Co. 9 in. x 10 in. x 3-1/4 in. Waxed Kraft Liners (Case of 250)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'FEMININE CARE RECEPTACLE LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/HOSHS-6141'
  },
  {
    usn: 'HOSKL-260',
    sku: 'HOSKL-260',
    mfg: 'HOSPECO',
    mfg_number: 'KL',
    name: '7-1/2 in. x  3-1/2 in. x  10 in. Kraft Waxed Paper Liners for Sanitary Napkin Receptacles Bags Brown (500 Per Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'FEMININE CARE RECEPTACLE LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/HOSKL-260'
  },
  {
    usn: 'HOSMT-4',
    sku: 'HOSMT-4',
    mfg: 'National Brand Alternative',
    mfg_number: 'MT-4',
    name: 'Maxithin Pad Folded Vending Box (250-Case)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'FEMININE CARE PRODUCTS',
    picture: 'http://www.supplyworks.com/ProductImage/HOSMT-4'
  },
  {
    usn: 'HOST500',
    sku: 'HOST500',
    mfg: 'Hopsital Specialty Co.',
    mfg_number: 'T500',
    name: 'Hospital Specialty Co. Tampons (500-Count)',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'FEMININE CARE PRODUCTS',
    picture: 'http://www.supplyworks.com/ProductImage/HOST500'
  },
  {
    usn: 'JWP04329',
    sku: 'JWP04329',
    mfg: 'Diversey',
    mfg_number: '04329.',
    name: 'Diversey J-Fill Virex II 256 Chemical Disinfectant, 84.5 oz.',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP04329'
  },
  {
    usn: 'JWP04578',
    sku: 'JWP04578',
    mfg: 'Diversey, Inc.',
    mfg_number: '04578',
    name: 'Crew 32 oz. Clinging Toilet Bowl Cleaner Disinfectant (12 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP04578'
  },
  {
    usn: 'JWP04743',
    sku: 'JWP04743',
    mfg: 'DIVERSEY INC',
    mfg_number: '04743',
    name: 'Virex 1 Qt. Lemon Disinfectant Cleaner (12 per Case)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP04743'
  },
  {
    usn: 'JWP3063437',
    sku: 'JWP3063437',
    mfg: 'Diversey, Inc.',
    mfg_number: '3063437',
    name: 'Crew RTD 52.7 oz. Restroom Floor and Surface Non-Acidic Disinfectant/Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP3063437'
  },
  {
    usn: 'JWP3350727',
    sku: 'JWP3350727',
    mfg: 'Diversey',
    mfg_number: '3350727',
    name: 'DIVERSEY INC Rtd Alpha-Hp 1.5 l Multi-Surface Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP3350727'
  },
  {
    usn: 'JWP4277285',
    sku: 'JWP4277285',
    mfg: 'DIVERSEY INC',
    mfg_number: '4277285',
    name: 'Oxivir TB 1 Qt. Disinfectant Cleaner (12 per Case)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP4277285'
  },
  {
    usn: 'JWP4599516',
    sku: 'JWP4599516',
    mfg: 'DIVERSEY INC',
    mfg_number: '4599516',
    name: 'Diversey Oxivir TB 6 in. x 7 in. Disinfecting Wipes (160-Count)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/JWP4599516'
  },
  {
    usn: 'JWP5104731',
    sku: 'JWP5104731',
    mfg: 'DIVERSEY INC',
    mfg_number: '5104731',
    name: 'Carefree 5 Gal. Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP5104731'
  },
  {
    usn: 'JWP5104950',
    sku: 'JWP5104950',
    mfg: 'DIVERSEY INC',
    mfg_number: '5104950',
    name: 'Signature 5 Gal. Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP5104950'
  },
  {
    usn: 'JWP5105047',
    sku: 'JWP5105047',
    mfg: 'DIVERSEY INC',
    mfg_number: '5105047',
    name: 'Vectra 5 Gal. High-Performance Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/JWP5105047'
  },
  {
    usn: 'KCC01000',
    sku: 'KCC01000',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '01000',
    name: 'Scott White High Capacity Hard Roll Paper Towels (12-Paper Towel Rolls/Case, 1,000 ft./Roll, 12,000 ft./Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/KCC01000'
  },
  {
    usn: 'KCC01080',
    sku: 'KCC01080',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '01080',
    name: 'Scott White Hard Roll Paper Towels with Premium Absorbency Pockets (12-Rolls/Case, 5,100 ft.)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/KCC01080'
  },
  {
    usn: 'KCC01804',
    sku: 'KCC01804',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '01804',
    name: 'Scott multi-fold Paper Towels with Fast-Drying Absorbency Pockets White (16-Packs/Case, 250 multi-fold Towels/Pack)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/KCC01804'
  },
  {
    usn: 'KCC01890',
    sku: 'KCC01890',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '01890',
    name: 'Kleenex multi-fold Paper Towels White (16-Packs/Case, 150 Tri-Fold Paper Towels/Pack, 2,400 Towels/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/KCC01890'
  },
  {
    usn: 'KCC02000',
    sku: 'KCC02000',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '02000',
    name: 'Scott 1.75 in. Core White Hard Roll Paper Towels (950 ft./Roll, 6-Rolls/Convenience Case, 5,700 ft./Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/KCC02000'
  },
  {
    usn: 'KCC04007',
    sku: 'KCC04007',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '04007',
    name: 'Scott 2-Ply Standard Rolls Coreless Toilet Paper (36-Rolls/Case, 1,000-Sheets/Roll, 36,000-Sheets/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC04007'
  },
  {
    usn: 'KCC04460',
    sku: 'KCC04460',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '04460',
    name: 'Scott 2-Ply White Individually Wrapped Standard Rolls Bulk Toilet Paper (80-Rolls/Case, 550-Sheets/Roll)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC04460'
  },
  {
    usn: 'KCC05701',
    sku: 'KCC05701',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '05701',
    name: 'WYPALL L40 Disposable Cleaning Drying Towels Limited Use, White (18-Packs/Case, 56-Sheets/Pack, 1,008-Sheets Total)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/KCC05701'
  },
  {
    usn: 'KCC05812',
    sku: 'KCC05812',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '05812',
    name: 'WYPALL L30 DRC Towels Strong and Soft Wipes, White (12-Packs/Case, 90 Towels/Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/KCC05812'
  },
  {
    usn: 'KCC07006',
    sku: 'KCC07006',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '07006',
    name: 'Scott 2-Ply White Jumbo Roll Junior Coreless Toilet Paper (12-Rolls/Case, 1,150 ft./Roll)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC07006'
  },
  {
    usn: 'KCC07223',
    sku: 'KCC07223',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '07223',
    name: 'Scott 1-Ply White Jumbo Roll (JR) Commercial Toilet Paper (12-Rolls/Case, 2,000 ft./Roll)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC07223'
  },
  {
    usn: 'KCC07805',
    sku: 'KCC07805',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '07805',
    name: 'Scott 2-Ply White 1000 Jumbo Roll Commercial Toilet Paper (12-Rolls/Case, 1000 ft./Roll)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC07805'
  },
  {
    usn: 'KCC07827',
    sku: 'KCC07827',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '07827',
    name: 'Scott 2-Ply White Jumbo Roll (JR) Commercial Toilet Paper (6-Rolls/Case, 2,000 ft./Roll)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC07827'
  },
  {
    usn: 'KCC17713',
    sku: 'KCC17713',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '17713',
    name: '2-Ply White Cottonelle Professional Bulk Standard Toilet Paper Rolls for Business (451-Sheets/Roll, 60-Rolls/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC17713'
  },
  {
    usn: 'KCC21400',
    sku: 'KCC21400',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '21400',
    name: 'Kleenex Facial Flat Tissue Boxes (36-Boxes/Case, 100 Tissues/Box)',
    category: 'PAPER PRODUCTS',
    subcategory: 'FACIAL TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/KCC21400'
  },
  {
    usn: 'KCC34790',
    sku: 'KCC34790',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '34790',
    name: 'WypAll X60 White Pop-Up Wipers (126-Count)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SHOP TOWELS, CLOTHS & WIPES',
    picture: 'http://www.supplyworks.com/ProductImage/KCC34790'
  },
  {
    usn: 'KCC50606',
    sku: 'KCC50606',
    mfg: 'Kimberly-Clark Professional',
    mfg_number: '50606',
    name: 'Scott Essential 3,600 ft. Plus Hard Roll Paper Towels with Premium Absorbency Pockets in White (6-Rolls/Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/KCC50606'
  },
  {
    usn: 'MMM02590',
    sku: 'MMM02590',
    mfg: '3M',
    mfg_number: '70071506136',
    name: '3M 14 in. x 20 in. Surface Preparation Floor Pad (10-Pack)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM02590'
  },
  {
    usn: 'MMM08278',
    sku: 'MMM08278',
    mfg: '3M',
    mfg_number: '08278',
    name: '3M 20 in. High Productivity Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM08278'
  },
  {
    usn: 'MMM08382',
    sku: 'MMM08382',
    mfg: '3M',
    mfg_number: 'MMM08382',
    name: '3M 20 in. Black Stripping Pads (5 Per Carton)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM08382'
  },
  {
    usn: 'MMM08484',
    sku: 'MMM08484',
    mfg: '3M',
    mfg_number: 'MMM08484',
    name: '3M 20 in. White SuPer Polish Pads (5 Per Carton)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM08484'
  },
  {
    usn: 'MMM20688',
    sku: 'MMM20688',
    mfg: '3M',
    mfg_number: '74',
    name: 'Scotch-Brite Medium Duty Scrub Sponge (20-Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM20688'
  },
  {
    usn: 'MMM23162',
    sku: 'MMM23162',
    mfg: '3M',
    mfg_number: '2000',
    name: '3M Scotch-Brite 5.5 in. x 3.9 in. Power Pad General Purple Hand Pad',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM23162'
  },
  {
    usn: 'MMM23163',
    sku: 'MMM23163',
    mfg: '3M',
    mfg_number: '3000',
    name: 'Scotch-Brite Medium Duty Sponge Scrubber (1-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM23163'
  },
  {
    usn: 'MMM25858',
    sku: 'MMM25858',
    mfg: '3M',
    mfg_number: '25858',
    name: '3M 20 in. Eraser Burnish Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM25858'
  },
  {
    usn: 'MMM29592',
    sku: 'MMM29592',
    mfg: '3M',
    mfg_number: '70071159324',
    name: '3M Scotch-Brite 20 in. Surface Preparation Floor Pad (10-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM29592'
  },
  {
    usn: 'MMM55658',
    sku: 'MMM55658',
    mfg: '3M',
    mfg_number: '4004CC',
    name: 'Scotch-Brite 2.8 in. x 4.5 in. x 1.25 in. Easy Erasing Pad (4-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/MMM55658'
  },
  {
    usn: 'PGC11763',
    sku: 'PGC11763',
    mfg: 'P&G',
    mfg_number: '003700036551',
    name: 'Febreze Professional 1 Gal. Closed Loop Linen and Sky Scent Fabric Freshener from Concentrate',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/PGC11763'
  },
  {
    usn: 'PTE100331',
    sku: 'PTE100331',
    mfg: 'ProTeam',
    mfg_number: '100331',
    name: 'ProTeam 10 Qt. Intercept Micro Filter for Select ProTeam Cylindrical Vacs (10-Pack)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUM BAGS & ACCESSORIES',
    picture: 'http://www.supplyworks.com/ProductImage/PTE100331'
  },
  {
    usn: 'RCP263200GY',
    sku: 'RCP263200GY',
    mfg: 'Rubbermaid',
    mfg_number: 'FG 2632 GRA',
    name: 'Rubbermaid Commercial Products Brute 32 Gal. Grey Round Vented Trash Can',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE CONTAINERS & LIDS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP263200GY'
  },
  {
    usn: 'RCP264000BK',
    sku: 'RCP264000BK',
    mfg: 'Rubbermaid',
    mfg_number: 'FG264000 BLA',
    name: 'Rubbermaid Commercial Products Brute Trash Can Dolly',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE MANAGEMENT TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP264000BK'
  },
  {
    usn: 'RCP264200YL',
    sku: 'RCP264200YL',
    mfg: 'Rubbermaid',
    mfg_number: 'FG264200YEL',
    name: 'Rubbermaid Commercial Products Brute Trash Can Caddy Bag',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'CLEANING CARTS & CADDIES',
    picture: 'http://www.supplyworks.com/ProductImage/RCP264200YL'
  },
  {
    usn: 'RCP264360GY',
    sku: 'RCP264360GY',
    mfg: 'Rubbermaid',
    mfg_number: '2031187',
    name: 'Rubbermaid Commercial Products Brute 44 Gal. Grey Round Vented Trash Can',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE CONTAINERS & LIDS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP264360GY'
  },
  {
    usn: 'RCP264900GY',
    sku: 'RCP264900GY',
    mfg: 'Rubbermaid',
    mfg_number: 'FG264900GRAY',
    name: 'Rubbermaid Commercial Products Brute 44 Gal. Maid Caddy',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'CLEANING CARTS & CADDIES',
    picture: 'http://www.supplyworks.com/ProductImage/RCP264900GY'
  },
  {
    usn: 'RCP265500GY',
    sku: 'RCP265500GY',
    mfg: 'Rubbermaid',
    mfg_number: 'RCP265500GY',
    name: 'Rubbermaid Commercial Products 33.2 in. H, 26.4 in. Dia 55 Gal. Capacity Round Gray Waste Container',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE CONTAINERS & LIDS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP265500GY'
  },
  {
    usn: 'RCP295600BK',
    sku: 'RCP295600BK',
    mfg: 'Rubbermaid',
    mfg_number: 'FG295600BLA',
    name: 'Rubbermaid Commercial Products 7 Gal. Black Rectangular Trash Can',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE CONTAINERS & LIDS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP295600BK'
  },
  {
    usn: 'RCP354060GY',
    sku: 'RCP354060GY',
    mfg: 'Rubbermaid Commercial Products',
    mfg_number: 'RCP354060GY',
    name: 'Rubbermaid Commercial Products Slim Jim 23 Gal. Gray Rectangular Vented Trash Can',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE CONTAINERS & LIDS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP354060GY'
  },
  {
    usn: 'RCP617388BK',
    sku: 'RCP617388BK',
    mfg: 'Rubbermaid',
    mfg_number: 'FG617388BLA',
    name: 'Rubbermaid Commercial Products Plastic Cleaning Cart with Zippered Yellow Vinyl Bag',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'CLEANING CARTS & CADDIES',
    picture: 'http://www.supplyworks.com/ProductImage/RCP617388BK'
  },
  {
    usn: 'RCP758088YL',
    sku: 'RCP758088YL',
    mfg: 'Rubbermaid',
    mfg_number: 'FG758088YEL',
    name: 'Rubbermaid Commercial Products Wave Brake 35 Qt. Yellow Side-Press Combo Mop Bucket and Wringer System',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BUCKETS, WRINGER & PAILS',
    picture: 'http://www.supplyworks.com/ProductImage/RCP758088YL'
  },
  {
    usn: 'REN02009',
    sku: 'REN02009',
    mfg: 'Renown',
    mfg_number: 'REN02009',
    name: 'Renown 20 in. High Performance Stripping Floor Pad',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02009'
  },
  {
    usn: 'REN02018',
    sku: 'REN02018',
    mfg: 'Renown',
    mfg_number: 'REN02018',
    name: 'Renown 20 in. Black Stripping Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02018'
  },
  {
    usn: 'REN02023',
    sku: 'REN02023',
    mfg: 'Renown',
    mfg_number: 'REN02023',
    name: 'Renown 20 in. Green Scrubbing Floor Pad',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02023'
  },
  {
    usn: 'REN02038',
    sku: 'REN02038',
    mfg: 'Renown',
    mfg_number: 'REN02038',
    name: 'Renown 20 in. Blue Cleaning Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02038'
  },
  {
    usn: 'REN02045',
    sku: 'REN02045',
    mfg: 'Renown',
    mfg_number: 'REN02045',
    name: 'Renown 17 in. Red Buffing Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02045'
  },
  {
    usn: 'REN02048',
    sku: 'REN02048',
    mfg: 'Renown',
    mfg_number: 'REN02048',
    name: 'Renown 20 in. Red Buffing Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02048'
  },
  {
    usn: 'REN02068',
    sku: 'REN02068',
    mfg: 'Renown',
    mfg_number: 'REN02068',
    name: 'Renown 20 in. White Polishing Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02068'
  },
  {
    usn: 'REN02088',
    sku: 'REN02088',
    mfg: 'Renown',
    mfg_number: 'REN02088',
    name: 'Renown 20 in. Tan Burnishing Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02088'
  },
  {
    usn: 'REN02093',
    sku: 'REN02093',
    mfg: 'Renown',
    mfg_number: 'REN02093',
    name: 'Renown 20 in. White Burnishing Floor Pad (5-Count)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'FLOOR MACHINE PADS & BONNETS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02093'
  },
  {
    usn: 'REN02097',
    sku: 'REN02097',
    mfg: 'Renown',
    mfg_number: '6472ASB',
    name: 'Renown 3.75 in. Swivel Pad Holder',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'WET MOPS & HARDWARE',
    picture: 'http://www.supplyworks.com/ProductImage/REN02097'
  },
  {
    usn: 'REN02107',
    sku: 'REN02107',
    mfg: 'Renown',
    mfg_number: '8440AS',
    name: 'Renown 4-5/8 in. x 10 in. Utility Pads in White',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'WET MOPS & HARDWARE',
    picture: 'http://www.supplyworks.com/ProductImage/REN02107'
  },
  {
    usn: 'REN02108',
    sku: 'REN02108',
    mfg: 'Renown',
    mfg_number: 'REN02108',
    name: 'Renown Brown Utility Pad',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'WET MOPS & HARDWARE',
    picture: 'http://www.supplyworks.com/ProductImage/REN02108'
  },
  {
    usn: 'REN02110',
    sku: 'REN02110',
    mfg: 'Renown',
    mfg_number: 'REN02110',
    name: 'Renown 7.7 in. Stainless Steel Scrubber Brush (Box of 12)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02110'
  },
  {
    usn: 'REN02114',
    sku: 'REN02114',
    mfg: 'Renown',
    mfg_number: 'REN02114',
    name: 'Renown Medium Scour Pad (Case of 20)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02114'
  },
  {
    usn: 'REN02115',
    sku: 'REN02115',
    mfg: 'Renown',
    mfg_number: 'REN02115',
    name: 'Renown Light-Duty Scour Pad (20-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02115'
  },
  {
    usn: 'REN02116',
    sku: 'REN02116',
    mfg: 'Renown',
    mfg_number: 'REN02116',
    name: 'Renown Heavy-Duty Scouring Pad',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02116'
  },
  {
    usn: 'REN02117',
    sku: 'REN02117',
    mfg: 'Renown',
    mfg_number: 'REN02117',
    name: 'Renown Light-Duty Scrub Sponge (20-Pack)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02117'
  },
  {
    usn: 'REN02118',
    sku: 'REN02118',
    mfg: 'Renown',
    mfg_number: 'REN02118',
    name: 'Renown Medium Scrub Sponge',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02118'
  },
  {
    usn: 'REN02119',
    sku: 'REN02119',
    mfg: 'Renown',
    mfg_number: 'REN02119',
    name: 'Renown 6-1/4 in. x 3-3/8 in. x 1 in. Cellulose Utility Sponge, Yellow, Small',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02119'
  },
  {
    usn: 'REN02120',
    sku: 'REN02120',
    mfg: 'Renown',
    mfg_number: 'REN02120',
    name: 'Renown Cellulose Utility Sponge Medium Yellow',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SPONGES & SCOURING PADS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02120'
  },
  {
    usn: 'REN02506',
    sku: 'REN02506',
    mfg: 'Renown',
    mfg_number: 'REN02506',
    name: 'Renown 2000 ml Industrial Hand Soap Dispensing System Citrus Scent',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02506'
  },
  {
    usn: 'REN02508',
    sku: 'REN02508',
    mfg: 'Renown',
    mfg_number: 'REN02508',
    name: 'Renown 2000 ml Industrial Hand Soap Dispensing System Herbal Floral',
    category: 'SKIN & PERSONAL CARE',
    subcategory: 'HAND SOAP & CLEANSERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02508'
  },
  {
    usn: 'REN02765-MS',
    sku: 'REN02765-MS',
    mfg: 'Renown',
    mfg_number: '111397',
    name: 'Renown 128 oz. Dishwashing Detergent (4 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'DISHWASHING DETERGENTS & ADDITIVES',
    picture: 'http://www.supplyworks.com/ProductImage/REN02765-MS'
  },
  {
    usn: 'REN02808-MS',
    sku: 'REN02808-MS',
    mfg: 'Renown',
    mfg_number: '111416',
    name: 'Renown High Performance Floor Stripper, 1 Gal., 4 per case',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02808-MS'
  },
  {
    usn: 'REN02809-MS',
    sku: 'REN02809-MS',
    mfg: 'Renown',
    mfg_number: '111418',
    name: 'Renown High Performance Floor Stripper, 5 Gal., 1 pail',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02809-MS'
  },
  {
    usn: 'REN02839-MS',
    sku: 'REN02839-MS',
    mfg: 'Renown',
    mfg_number: '111452',
    name: 'Renown 32 oz. Blue 9 HCL Bowl Cleaner (12 Per Case)',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02839-MS'
  },
  {
    usn: 'REN02846-MS',
    sku: 'REN02846-MS',
    mfg: 'Renown',
    mfg_number: '111462',
    name: 'Renown 128 oz. Citrus Quat 64 Disinfectant Cleaner',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/REN02846-MS'
  },
  {
    usn: 'REN03020-FR',
    sku: 'REN03020-FR',
    mfg: 'Renown',
    mfg_number: '6-14-SS-LE-P/REN03020-FR',
    name: 'Renown Super-Sorb 12 oz. Odor Absorbent and Eater',
    category: 'ODOR CONTROL',
    subcategory: 'ODOR NEUTRALIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03020-FR'
  },
  {
    usn: 'REN03865',
    sku: 'REN03865',
    mfg: 'Renown',
    mfg_number: 'REN03865',
    name: 'Renown 18 in. Stainless Steel Window Squeegee Complete',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SQUEEGEES, WASHERS & SCRAPERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03865'
  },
  {
    usn: 'REN03878',
    sku: 'REN03878',
    mfg: 'Renown',
    mfg_number: 'REN03878',
    name: 'Renown 14 in. Window Combination Squeegee and Washer',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'SQUEEGEES, WASHERS & SCRAPERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03878'
  },
  {
    usn: 'REN03894',
    sku: 'REN03894',
    mfg: 'Renown',
    mfg_number: 'REN03894',
    name: 'Renown 36 in. Nifty Pick-Up Tool',
    category: 'WASTE CONTAINMENT & DISPOSAL',
    subcategory: 'WASTE MANAGEMENT TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03894'
  },
  {
    usn: 'REN03954',
    sku: 'REN03954',
    mfg: 'Renown',
    mfg_number: 'REN03954',
    name: 'Renown 10 in. Blue Deck Scrub Brush (12 per Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BROOMS, BRUSHES & DUST PANS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03954'
  },
  {
    usn: 'REN03966',
    sku: 'REN03966',
    mfg: 'Renown',
    mfg_number: 'REN03966',
    name: 'Renown 7-1/2 in. Pivoting Head Black Grout Brush (12-Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BROOMS, BRUSHES & DUST PANS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03966'
  },
  {
    usn: 'REN03979',
    sku: 'REN03979',
    mfg: 'Renown',
    mfg_number: 'REN03979',
    name: 'Renown 24 in. Polypropylene Broom Fine Sweep Flagged with 3 in. Trim Grey (12-Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BROOMS, BRUSHES & DUST PANS',
    picture: 'http://www.supplyworks.com/ProductImage/REN03979'
  },
  {
    usn: 'REN05116',
    sku: 'REN05116',
    mfg: 'Renown',
    mfg_number: 'REN05116',
    name: 'Renown 12.25 in. Contour Trigger Sprayer in Blue',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'PUMPS, SPRAYERS & BOTTLES',
    picture: 'http://www.supplyworks.com/ProductImage/REN05116'
  },
  {
    usn: 'REN05121',
    sku: 'REN05121',
    mfg: 'Renown',
    mfg_number: 'REN05121',
    name: 'Renown 32 oz. Plastic Spray Bottle with Graduations',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'PUMPS, SPRAYERS & BOTTLES',
    picture: 'http://www.supplyworks.com/ProductImage/REN05121'
  },
  {
    usn: 'REN05257',
    sku: 'REN05257',
    mfg: 'Renown',
    mfg_number: 'REN05257',
    name: 'Renown 28 in. Lambswool Duster',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/REN05257'
  },
  {
    usn: 'REN05258',
    sku: 'REN05258',
    mfg: 'Renown',
    mfg_number: 'REN05258',
    name: 'Renown Lambswool 30 in. to 60 in. Telescoping Duster',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'DUST MOPS & DUSTING TOOLS',
    picture: 'http://www.supplyworks.com/ProductImage/REN05258'
  },
  {
    usn: 'REN05648-US',
    sku: 'REN05648-US',
    mfg: 'Renown',
    mfg_number: 'REN05648-US',
    name: 'Renown 50 lbs. Powdered Citrus Scented Laundry Detergent for Institutional Laundry Use Only',
    category: 'CHEMICALS',
    subcategory: 'LAUNDRY DETERGENTS & CHEMICALS',
    picture: 'http://www.supplyworks.com/ProductImage/REN05648-US'
  },
  {
    usn: 'REN06002-WB',
    sku: 'REN06002-WB',
    mfg: 'Renown',
    mfg_number: 'REN06002-WB',
    name: 'Renown White Multifold Paper Towels (250 Sheets per Pack 16 Packs per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/REN06002-WB'
  },
  {
    usn: 'REN06005-WB',
    sku: 'REN06005-WB',
    mfg: 'Renown',
    mfg_number: 'REN06005-WB',
    name: 'Renown White Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/REN06005-WB'
  },
  {
    usn: 'REN06125-WB',
    sku: 'REN06125-WB',
    mfg: 'Renown',
    mfg_number: 'REN06125-WB',
    name: 'Renown OptiCore 2-Ply Toilet Paper (865 Sheets per Roll 36 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/REN06125-WB'
  },
  {
    usn: 'REN06126-WB',
    sku: 'REN06126-WB',
    mfg: 'Renown',
    mfg_number: 'REN06126-WB',
    name: 'Renown Single Roll Banded 2-Ply 3.75 in. x 4 in. Toilet Paper (616 Sheets per Roll 48 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/REN06126-WB'
  },
  {
    usn: 'REN06127-WB',
    sku: 'REN06127-WB',
    mfg: 'Renown',
    mfg_number: 'REN06127-WB',
    name: 'Renown Premium OptiCore 2-Ply Toilet Paper (800 Sheets per Roll 36 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/REN06127-WB'
  },
  {
    usn: 'REN06130-WB',
    sku: 'REN06130-WB',
    mfg: 'Renown',
    mfg_number: 'REN06130-WB',
    name: 'Renown Natural 8 in. Controlled Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/REN06130-WB'
  },
  {
    usn: 'REN06131-WB',
    sku: 'REN06131-WB',
    mfg: 'Renown',
    mfg_number: 'REN06131-WB',
    name: 'Renown Natural White 8 in. Controlled Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/REN06131-WB'
  },
  {
    usn: 'REN06132-WB',
    sku: 'REN06132-WB',
    mfg: 'Renown',
    mfg_number: 'REN06132-WB',
    name: 'Renown Natural 7.5 in. Controlled Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/REN06132-WB'
  },
  {
    usn: 'REN06133-WB',
    sku: 'REN06133-WB',
    mfg: 'Renown',
    mfg_number: 'REN06133-WB',
    name: 'Renown Natural White 7.5 in. Controlled Hardwound Paper Towels (800 ft. per Roll, 6-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/REN06133-WB'
  },
  {
    usn: 'REN06148-WB',
    sku: 'REN06148-WB',
    mfg: 'Renown',
    mfg_number: 'REN06148-WB',
    name: 'Renown Single Roll Advanced 2-Ply 4 in. x 3.75 in. Toilet Paper (500 Sheets per Roll 80 Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/REN06148-WB'
  },
  {
    usn: 'REN08012-VP',
    sku: 'REN08012-VP',
    mfg: 'Renown',
    mfg_number: 'REN08012-VP',
    name: 'Renown 18 Gal. Wet/Dry Vacuum Tank Shovel Nose Design',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/REN08012-VP'
  },
  {
    usn: 'REN11000-CA',
    sku: 'REN11000-CA',
    mfg: 'Renown',
    mfg_number: 'REN11000',
    name: 'Renown 10 Gal. 6 mic 24 in. x 24 in. Natural Can Liner(50 per Roll, 20-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN11000-CA'
  },
  {
    usn: 'REN11500-CA',
    sku: 'REN11500-CA',
    mfg: 'Renown',
    mfg_number: 'REN11500',
    name: 'Renown Natural Institutional 15 Gal. 6 mic 24 in. x 33 in. Trash Can Liner (1,000 per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN11500-CA'
  },
  {
    usn: 'REN11506-CA',
    sku: 'REN11506-CA',
    mfg: 'Renown',
    mfg_number: 'REN11506',
    name: 'Renown 15 Gal. 6 mic 24 in. x 33 in. Black Can Liner (50 per Roll, 20-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN11506-CA'
  },
  {
    usn: 'REN12506-CA',
    sku: 'REN12506-CA',
    mfg: 'Renown',
    mfg_number: 'REN12506',
    name: 'Renown 25 Gal. in Natural Can Liner 30 in. x 37 in. 13 Mic (25/Roll, 20-Roll/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN12506-CA'
  },
  {
    usn: 'REN12511-CA',
    sku: 'REN12511-CA',
    mfg: 'Renown',
    mfg_number: 'REN12511',
    name: 'Renown 25 Gal. in Natural Can Liner 30 in. x 37 in. 8 Mic (25/Roll, 20-Roll/Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN12511-CA'
  },
  {
    usn: 'REN12512-CA',
    sku: 'REN12512-CA',
    mfg: 'Renown',
    mfg_number: 'REN12512',
    name: 'Renown 27 Gal. 16 mic 30 in. x 37 in. Natural Can Liner (25 per Roll, 20-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN12512-CA'
  },
  {
    usn: 'REN13315-CA',
    sku: 'REN13315-CA',
    mfg: 'Renown',
    mfg_number: 'REN13315',
    name: 'Renown 33 Gal. 16 mic 33 in. x 40 in. Natural Can Liner (25 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN13315-CA'
  },
  {
    usn: 'REN14512-CA',
    sku: 'REN14512-CA',
    mfg: 'Renown',
    mfg_number: 'REN14512',
    name: 'Renown 45 Gal. 12 mic 40 in. x 48 in. Natural Can Liner (25 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14512-CA'
  },
  {
    usn: 'REN14515-CA',
    sku: 'REN14515-CA',
    mfg: 'Renown',
    mfg_number: 'REN14515',
    name: 'Renown 45 Gal. 13 mic 40 in. x 48 in. Natural Can Liner (25 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14515-CA'
  },
  {
    usn: 'REN14524-CA',
    sku: 'REN14524-CA',
    mfg: 'Renown',
    mfg_number: 'REN14524',
    name: 'Renown 45 Gal. 12 mic 40 in. x 48 in. Black Can Liner (25 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14524-CA'
  },
  {
    usn: 'REN14535-CA',
    sku: 'REN14535-CA',
    mfg: 'Renown',
    mfg_number: 'REN14535',
    name: 'Renown 45 Gal. 16 mic 40 in. x 48 in. Black Can Liner (25 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14535-CA'
  },
  {
    usn: 'REN14540-CA',
    sku: 'REN14540-CA',
    mfg: 'Renown',
    mfg_number: 'REN14540',
    name: 'Renown 45 Gal. 16 mic 40 in. x 48 in. Natural Can Liner (25-Count, 10-Rolls per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14540-CA'
  },
  {
    usn: 'REN14555-CA',
    sku: 'REN14555-CA',
    mfg: 'Renown',
    mfg_number: 'REN14555',
    name: 'Renown 45 Gal. 22 mic 40 in. x 48 in. Black Can Liner (25 per Roll, 6-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14555-CA'
  },
  {
    usn: 'REN14556-CA',
    sku: 'REN14556-CA',
    mfg: 'Renown',
    mfg_number: 'REN14556',
    name: 'Renown 45 Gal. 22 mic 40 in. x 48 in. Natural Can Liner (25 per Roll, 6-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN14556-CA'
  },
  {
    usn: 'REN15602-CA',
    sku: 'REN15602-CA',
    mfg: 'Renown',
    mfg_number: 'REN15602',
    name: 'Renown 56 Gal. 16 mic 43 in. x 48 in. Natural Can Liner (25 per Roll, 8-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN15602-CA'
  },
  {
    usn: 'REN15610-CA',
    sku: 'REN15610-CA',
    mfg: 'Renown',
    mfg_number: 'REN15610',
    name: 'Renown Institutional 56 Gal. 22 mic 43 in. x 48 in. Black Trash Can Liner (25 per Roll, 6-Rolls per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN15610-CA'
  },
  {
    usn: 'REN16001-CA',
    sku: 'REN16001-CA',
    mfg: 'Renown',
    mfg_number: 'REN16001',
    name: 'Renown 60 Gal. 14 mic 38 in. x 60 in. Natural Can Liner (25 per Roll, 8-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN16001-CA'
  },
  {
    usn: 'REN16004-CA',
    sku: 'REN16004-CA',
    mfg: 'Renown',
    mfg_number: 'REN16004',
    name: 'Renown 60 Gal. 16 mic 38 in. x 60 in. Natural Can Liner (25 per Roll, 8-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN16004-CA'
  },
  {
    usn: 'REN21533-CA',
    sku: 'REN21533-CA',
    mfg: 'Renown',
    mfg_number: 'REN21533RL',
    name: 'Renown 15 Gal. 0.70 mil 24 in. x 32 in. Black Can Liner (50 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN21533-CA'
  },
  {
    usn: 'REN22527-CA',
    sku: 'REN22527-CA',
    mfg: 'Renown',
    mfg_number: 'REN22527',
    name: 'Renown 25 Gal. 0.74 mil 30 in. x 36 in. White Can Liner (25 per Roll, 8-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN22527-CA'
  },
  {
    usn: 'REN23314-CA',
    sku: 'REN23314-CA',
    mfg: 'Renown',
    mfg_number: 'REN23314',
    name: 'Renown 33 Gal. 0.74 mil 33 in. x 39 in. White Can Liner (25 per Roll, 6-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN23314-CA'
  },
  {
    usn: 'REN24512-CA',
    sku: 'REN24512-CA',
    mfg: 'Renown',
    mfg_number: 'REN24512',
    name: 'Renown 45 Gal. 0.74 mil 40 in. x 46 in. White Can Liner (25 per Roll, 4-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN24512-CA'
  },
  {
    usn: 'REN24543-CA',
    sku: 'REN24543-CA',
    mfg: 'Renown',
    mfg_number: 'REN24543',
    name: 'Renown 45 Gal. 1.2 mil 40 in. x 46 in. Natural Can Liner (20 per Roll, 5-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN24543-CA'
  },
  {
    usn: 'REN26002-CA',
    sku: 'REN26002-CA',
    mfg: 'Renown',
    mfg_number: 'REN26002-CA',
    name: 'Renown 60 Gal. 0.74 mil 38 in. x 58 in. White Can Liner (25 per Roll, 4-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN26002-CA'
  },
  {
    usn: 'REN26018-CA',
    sku: 'REN26018-CA',
    mfg: 'Renown',
    mfg_number: 'REN26018-CA',
    name: 'Renown 60 Gal. 1.3 mil 38 in. x 58 in. Natural Can Liner (100 per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN26018-CA'
  },
  {
    usn: 'REN26019-CA',
    sku: 'REN26019-CA',
    mfg: 'Renown',
    mfg_number: 'REN26019',
    name: 'Renown 60 Gal. 1.3 mil 38 in. x 58 in. Gray Can Liner (20 per Roll, 5-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN26019-CA'
  },
  {
    usn: 'REN61500-CA',
    sku: 'REN61500-CA',
    mfg: 'Renown',
    mfg_number: 'REN61500-CA',
    name: 'Renown 16 Gal. 1 mil 24 in. x 32 in. Black Can Liner (50 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN61500-CA'
  },
  {
    usn: 'REN64512-CA',
    sku: 'REN64512-CA',
    mfg: 'Renown',
    mfg_number: 'REN64512',
    name: 'Renown 45 Gal. 1.25 mil 40 in. x 46 in. Black Can Liner (20 per Roll, 5-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN64512-CA'
  },
  {
    usn: 'REN65613-CA',
    sku: 'REN65613-CA',
    mfg: 'Renown',
    mfg_number: 'REN65613RL',
    name: 'Renown 56 Gal. 1.5 mil 43 in. x 47 in. Black Can Liner (10 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN65613-CA'
  },
  {
    usn: 'REN66005-CA',
    sku: 'REN66005-CA',
    mfg: 'Renown',
    mfg_number: 'REN66005-CA',
    name: 'Renown 60 Gal. 1.25 mil 38 in. x 58 in. Black Can Liner (10 per Roll, 10-Roll per Case)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'ALL-PURPOSE TRASH BAGS & LINERS',
    picture: 'http://www.supplyworks.com/ProductImage/REN66005-CA'
  },
  {
    usn: 'SCA290088',
    sku: 'SCA290088',
    mfg: 'TORK',
    mfg_number: '290088',
    name: 'TORK Universal Natural Matic Hardwound Paper Towels (884 Sheets Per Roll 6 Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/SCA290088'
  },
  {
    usn: 'SCA290089',
    sku: 'SCA290089',
    mfg: 'TORK',
    mfg_number: '290089',
    name: 'TORK Advanced White Matic Hardwound Paper Towels (884 Sheets Per Roll 6 Rolls Per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'PAPER TOWELS',
    picture: 'http://www.supplyworks.com/ProductImage/SCA290089'
  },
  {
    usn: 'SCATM1616S',
    sku: 'SCATM1616S',
    mfg: 'TORK',
    mfg_number: 'TM1616S',
    name: 'TORK 2-Ply Universal Single Roll Toilet Paper (500-Sheets per Roll, 96-Rolls per Case)',
    category: 'PAPER PRODUCTS',
    subcategory: 'TOILET TISSUE',
    picture: 'http://www.supplyworks.com/ProductImage/SCATM1616S'
  },
  {
    usn: 'SPA0035-04',
    sku: 'SPA0035-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '003504',
    name: 'SPARTAN CHEMICAL COMPANY Clean by Peroxy 1 Gallon Fresh Spring Rain Scent Multi-Purpose Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA0035-04'
  },
  {
    usn: 'SPA0084-05',
    sku: 'SPA0084-05',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '008405',
    name: 'SPARTAN CHEMICAL COMPANY Shineline Emulsifier Plus 5 Gallon Fresh Scent Floor Finish Remover',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA0084-05'
  },
  {
    usn: 'SPA1021-12',
    sku: 'SPA1021-12',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '102103',
    name: 'SPARTAN CHEMICAL COMPANY TB-Cide Quat 1 Quart Lemon Scent One Step Cleaner/Disinfectant',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA1021-12'
  },
  {
    usn: 'SPA1062-04',
    sku: 'SPA1062-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '106204',
    name: 'DMQ DMQ 1 Gallon Lemon Scent One Step Cleaner/Disinfectant (4 per pack)',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA1062-04'
  },
  {
    usn: 'SPA3016-04',
    sku: 'SPA3016-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '301604',
    name: 'Damp Mop 1 Gallon Lemon Scent Neutral Floor Cleaner (4 per pack)',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3016-04'
  },
  {
    usn: 'SPA3067-04',
    sku: 'SPA3067-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '306704',
    name: 'Spartan Chemical Co. Airlift Tropical 1 Gallon Tropical Scent Air Freshener',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3067-04'
  },
  {
    usn: 'SPA3068-12',
    sku: 'SPA3068-12',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '306803',
    name: 'Spartan Chemical Co. Airlift Tropical 1 Quart Tropical Scent Air Freshener',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3068-12'
  },
  {
    usn: 'SPA3086-12',
    sku: 'SPA3086-12',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '308603',
    name: 'Spartan Chemical Co. Airlift Smoke & Odor Eliminator 1 Quart Air Neutralizer',
    category: 'ODOR CONTROL',
    subcategory: 'AIR FRESHENERS & DEODORIZERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3086-12'
  },
  {
    usn: 'SPA3097-04',
    sku: 'SPA3097-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '309704',
    name: 'Consume 1 Gal. Vanilla Scented Enzyme Digestant Odor Absorber',
    category: 'ODOR CONTROL',
    subcategory: 'ODOR NEUTRALIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3097-04'
  },
  {
    usn: 'SPA3197-12',
    sku: 'SPA3197-12',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '319703',
    name: 'Consume Consume 1 Quart Organic Digestor',
    category: 'ODOR CONTROL',
    subcategory: 'ODOR NEUTRALIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3197-12'
  },
  {
    usn: 'SPA3297-04',
    sku: 'SPA3297-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '329704',
    name: 'Spartan Chemical Consume Eco-Lyzer 1 Gallon Floral Scent Disinfectant/Deodorant',
    category: 'CHEMICALS',
    subcategory: 'SURFACE SANITIZERS & DISINFECTANTS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA3297-04'
  },
  {
    usn: 'SPA4055-05',
    sku: 'SPA4055-05',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '405505',
    name: 'iShine 5 Gallon Floor Finish',
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4055-05'
  },
  {
    usn: 'SPA4073-05',
    sku: 'SPA4073-05',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '407305',
    name: "On an' On 5 Gallon Floor Finish",
    category: 'CHEMICALS',
    subcategory: 'FLOOR CLEANERS & MAINTAINERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4073-05'
  },
  {
    usn: 'SPA4702',
    sku: 'SPA4702',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '470202',
    name: 'SPARTAN CHEMICAL COMPANY hdqC2 2 Liter Citrus Scent One Step Cleaner/Disinfectant (4-Case)',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4702'
  },
  {
    usn: 'SPA4716',
    sku: 'SPA4716',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '471602',
    name: 'Spartan NABC Concentrate 2 l Floral Scent Restroom Disinfectant (4 per Pack)',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4716'
  },
  {
    usn: 'SPA4820',
    sku: 'SPA4820',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '482002',
    name: 'SPARTAN CHEMICAL COMPANY 2 Liter Clean by Peroxy Spring Rain Scent Multi-Purpose Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4820'
  },
  {
    usn: 'SPA4830',
    sku: 'SPA4830',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '483002',
    name: 'SPARTAN CHEMICAL COMPANY TriBase 2 Liter Multi Purpose Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4830'
  },
  {
    usn: 'SPA4835',
    sku: 'SPA4835',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '483502',
    name: 'Spartan Chemical Co. BioRenewables 2 Liter Waterfall Scent Glass Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CHEMICAL PROPORTIONING SYSTEMS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA4835'
  },
  {
    usn: 'SPA7003-04',
    sku: 'SPA7003-04',
    mfg: 'SPARTAN CHEMICAL COMPANY',
    mfg_number: '700304',
    name: 'SPARTAN CHEMICAL COMPANY Clothesline Fresh 1 Gallon Laundry Detergent',
    category: 'CHEMICALS',
    subcategory: 'LAUNDRY DETERGENTS & CHEMICALS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA7003-04'
  },
  {
    usn: 'SPA7116-12',
    sku: 'SPA7116-12',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '711603',
    name: 'NABC NABC 1 Quart Floral Scent Restroom Disinfectant',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA7116-12'
  },
  {
    usn: 'SPA7496-04',
    sku: 'SPA7496-04',
    mfg: 'Spartan Chemical Co., Inc.',
    mfg_number: '749604',
    name: 'NABC NABC 1 Gallon Floral Scent Restroom Disinfectant',
    category: 'CHEMICALS',
    subcategory: 'BATHROOM CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/SPA7496-04'
  },
  {
    usn: 'SX-0457538',
    sku: 'REN03944',
    mfg: 'Renown',
    mfg_number: 'REN03944',
    name: 'Renown 54 in. Duo-Sweep Flagged Angle Broom (12-Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BROOMS, BRUSHES & DUST PANS',
    picture: 'http://www.supplyworks.com/ProductImage/SX-0457538'
  },
  {
    usn: 'SX-0457546',
    sku: 'REN03964',
    mfg: 'Renown',
    mfg_number: 'REN03964',
    name: 'Renown 8 in. Blue Swivel Scrub Brush (12 per Case)',
    category: 'CLEANING TOOLS & SUPPLIES',
    subcategory: 'BROOMS, BRUSHES & DUST PANS',
    picture: 'http://www.supplyworks.com/ProductImage/SX-0457546'
  },
  {
    usn: 'SX-0463849',
    sku: 'REN07001-MS',
    mfg: 'Renown',
    mfg_number: '111494',
    name: 'Renown 128 oz. Carpet Extraction Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CARPET & UPHOLSTERY CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/SX-0463849'
  },
  {
    usn: 'SX-0463854',
    sku: 'REN07005-MS',
    mfg: 'Renown',
    mfg_number: '111501',
    name: 'Renown 128 oz. Liquid Defoamer Cleaner',
    category: 'CHEMICALS',
    subcategory: 'CARPET & UPHOLSTERY CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/SX-0463854'
  },
  {
    usn: 'SX-0463855',
    sku: 'REN07020-MS',
    mfg: 'Renown',
    mfg_number: '111506',
    name: 'Renown 1 Qt. Multi-Enzyme Spotter Deodorant Cherry Almond',
    category: 'CHEMICALS',
    subcategory: 'CARPET & UPHOLSTERY CLEANERS',
    picture: 'http://www.supplyworks.com/ProductImage/SX-0463855'
  },
  {
    usn: 'TYCCP4046HPR',
    sku: 'TYCCP4046HPR',
    mfg: 'Berry Plastics',
    mfg_number: 'LHCP4046HPR',
    name: 'Berry Plastics 36 Gal. Low-Density Red Trash Bags (60/Roll)',
    category: 'CAN LINERS & TRASH BAGS',
    subcategory: 'HAZARDOUS MATERIAL CONTAINMENT BAGS',
    picture: 'http://www.supplyworks.com/ProductImage/TYCCP4046HPR'
  },
  {
    usn: 'WIN5300CS',
    sku: 'WIN5300CS',
    mfg: 'karcher',
    mfg_number: '9.840-643.0',
    name: 'Karcher Sensor and Versamatic Bags Parts in Black (10-Pack)',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUM BAGS & ACCESSORIES',
    picture: 'http://www.supplyworks.com/ProductImage/WIN5300CS'
  },
  {
    usn: 'WINSRS12',
    sku: 'WINSRS12',
    mfg: 'WINDSOR',
    mfg_number: 'SRS12',
    name: 'Karcher Sensor S Commercial Upright Vacuum Cleaner',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/WINSRS12'
  },
  {
    usn: 'WINSRXP12',
    sku: 'WINSRXP12',
    mfg: 'WINDSOR',
    mfg_number: 'SRXP12',
    name: 'Karcher Windsor Sensor XP 12 46 in. Upright Vacuum with Tools',
    category: 'EQUIPMENT & EQUIPMENT PARTS',
    subcategory: 'VACUUMS',
    picture: 'http://www.supplyworks.com/ProductImage/WINSRXP12'
  }
]
