import React from 'react'
import ReactDOM from 'react-dom'
import { FormGroup, Label, Input } from 'reactstrap'

class IndeterminateCheckbox extends React.Component {
  componentDidMount() {
    if (this.props.indeterminate === true) {
      this._setIndeterminate(true);
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.indeterminate !== this.props.indeterminate) {
      this._setIndeterminate(this.props.indeterminate);
    }
  }

  _setIndeterminate(indeterminate) {
    const node = ReactDOM.findDOMNode(this);
    node.indeterminate = indeterminate;
  }

  render() {
    const { indeterminate, type, ...props } = this.props;
    return <Input type="checkbox" {...props} />;
  }
}

export default ({ input, name, label, defaultChecked, style, indeterminate }) => {
  let checked = input ? input.value : false
  
  if (defaultChecked || defaultChecked === false) {
    checked = defaultChecked
  }

  return (
    <FormGroup check style={{...style}}>
      <Label check style={{ verticalAlign: 'top'}}>
        <IndeterminateCheckbox
          checked={checked}
          onChange={input ? input.onChange : null}
          indeterminate={indeterminate}
        />

        {label}
      </Label>
    </FormGroup>
  )
}
