import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'

const Tick = <FaCheck color="#24CC0B" size={12} style={{ marginRight: 12 }} />
const Cross = <FaTimes color="red" size={12} style={{ marginRight: 12 }} />
export default ({ feature: { isIncluded, label }, ...rest }) => {
  let has = true
  if (isIncluded === false) {
    has = false
  }

  return (
    <div {...rest}>
      {has ? Tick : Cross}
      <span>{label}</span>
    </div>
  )
}
