import React, { PureComponent } from 'react'
import { GoogleApiWrapper } from '../utils/GoogleApiComponent'

import { placeSchema } from 'utils'
import { formatText } from 'utils/formatters'

class GooglePlaceInput extends PureComponent {
  constructor(props) {
    super(props)
    this.autocomplete = null
    this.state = { error: '' }
    this.renderAutocomplete = this.renderAutocomplete.bind(this)
    this.formatLocation = this.formatLocation.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  // const valor = 10;
  componentDidMount() {
    const { google } = this.props
    if (!google) {
      return
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteRef,
      { types: ['geocode'] }
    )
    this.autocomplete.addListener('place_changed', this.renderAutocomplete)
  }

  async renderAutocomplete() {
    const place = await this.autocomplete.getPlace()
    let countryCode
    let number

    if (!place.geometry) {
      return
    }

    if (place.hasOwnProperty('formatted_phone_number')) {
      countryCode = '+1'
      number = place.formatted_phone_number.replace('+1', '')
    } else {
      countryCode = ''
      number = ''
    }

    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()

    let location = this.formatLocation(
      place.address_components.reduce(
        (loc, component) => ({
          ...loc,
          id: place.id,
          formattedAddress: place.formatted_address,
          latitude,
          longitude,
          countryCode,
          number,
          googleMapLink: place.url,
          [component.types[0]]: component.short_name
        }),
        {}
      )
    )

    await this.props.onChange(location)
  }

  formatLocation = location =>
    Object.keys(location).reduce(
      (formatted, key) => ({
        ...formatted,
        ...{ [placeSchema[key] || key]: location[key] }
      }),
      {}
    )

  onChange() {
    if (this.props.error) {
      this.setState({ error: `${this.props.meta.error}` })
    } else {
      this.setState({ error: '' })
    }
  }

  toggleAutocomplete = event => (event.target.autocomplete = 'hidden')

  toggleField = event => {
    const { inputId } = this.props
    event.preventDefault()
    const formattedAddressId = inputId || 'formattedAddress'
    const autocompleteId = `${inputId || ''}autocomplete`
    let formattedAddress = document.getElementById(formattedAddressId)
    let autocomplete = document.getElementById(autocompleteId)
    event.target.hidden = true
    event.target.style.visibility = 'hidden'

    if (event.target === formattedAddress) {
      // console.log(event.target === formattedAddress, 'autocomplete showing')
      autocomplete.hidden = false
      autocomplete.style.visibility = 'visible'
      return autocomplete.focus()
    }

    if (event.target === autocomplete) {
      // console.log(event.target === autocomplete, 'formattedAddress showing')
      formattedAddress.hidden = false
      formattedAddress.style.visibility = 'visible'
      return formattedAddress
    }
  }

  render() {
    const { name, value, inputId } = this.props

    const placeholder = this.props.placeholder || 'Enter address'

    console.log('GooglePlaceInput value', value)
    return (
      <>
          <input
            id={inputId || 'formattedAddress'}
            autoComplete={'hidden'}
            className="form-control"
            onFocus={this.toggleField}
            placeholder={placeholder}
            readOnly
            style={{ background: 'white' }}
            type={this.props.type}
            value={this.props.value?.formattedAddress || ''}
            hidden={false}
            onKeyPress={e => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
          <input
            {...this.props}
            id={`${inputId || ''}autocomplete`}
            autoComplete={'hidden'}
            className="form-control"
            name={name}
            loaded={this.props.loaded.toString()}
            onChange={this.onChange}
            placeholder={placeholder}
            ref={ref => (this.autocompleteRef = ref)}
            type={this.props.type}
            error={this.state.error}
            onFocus={this.toggleAutocomplete}
            onBlur={this.toggleField}
            style={{ visibility: 'hidden' }}
            hidden={true}
            onKeyPress={e => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
      </>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places']
})(GooglePlaceInput)
