import React, { Component } from 'react'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'

import { Card } from 'reactstrap'

import { InputField, UploadField } from 'components/fields'

import ErrorMessage from 'components/ErrorMessage'
import { image, required } from 'utils'
import LazyImage from 'components/LazyImage'

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const min3 = value => (value && value.length >= 3 ? undefined : 'Too short')

class TaskForm extends Component {
  render() {
    const { error, images, onImageClicked } = this.props
    let gallery = null
    if (images && images.length) {
      gallery = images.map((m, index) => (
        <LazyImage
          src={m.url}
          alt={m.name}
          width={300}
          height={300}
          key={index}
          onClick={() => onImageClicked(m.url)}
        />
      ))
    }

    let errorMessage = null
    if (error) {
      errorMessage = <ErrorMessage error={error} />
    }

    return (
      <Card body>
        <form onSubmit={this.props.handleSubmit}>
          <Field
            name="name"
            placeholder="Task Name"
            component={InputField}
            type="text"
            label="Task Name"
            validate={[required, min3]}
            warn={required}
          />
          <Field
            name="notes"
            component={InputField}
            type="textarea"
            label="Notes"
          />
          <Field
            name="attachments"
            component={UploadField}
            type="file"
            label="Attach Photos"
            multiple
            validate={image}
          />
          <SubmitContainer>
            {errorMessage}
            <button type="submit" className="btn btn-primary">
              Done
            </button>
          </SubmitContainer>
        </form>
        {gallery}
      </Card>
    )
  }
}

export default reduxForm({
  form: 'Task'
})(TaskForm)
