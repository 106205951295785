export const fetchACL = async dispatch => {
  try {
    await dispatch({
      type: 'ACL_LOAD',
      payload: {
        request: {
          url: '/api/info/acl',
          method: 'get'
        }
      }
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
