import CryptoJS from 'crypto-js'

const PASS = process.env.REACT_APP_ENCRYPTION_KEY

export const encryptJsonWithAES = (data) => {
  const b64 = CryptoJS.AES.encrypt(JSON.stringify(data), PASS).toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
};

export const decryptWithAES = (ciphertext) => {
  var reb64 = CryptoJS.enc.Hex.parse(ciphertext);
  var bytes = reb64.toString(CryptoJS.enc.Base64);
  const decrypted = CryptoJS.AES.decrypt(bytes, PASS);
  const originalText = decrypted.toString(CryptoJS.enc.Utf8);

  const data = JSON.parse(originalText);
  return data;
};