import React from 'react'

const renderError = error =>
  error && <span className="small text-danger">{error}</span>
const renderWarning = warning =>
  warning && <span className="small text-warning">{warning}</span>

const FieldSet = ({
  children,
  label,
  labelStyles,
  checkValidation,
  fieldSetStyles,
  style,
  htmlFor,
  meta: { touched, error, warning }
}) => (
  <fieldset className="form-group" style={fieldSetStyles || style}>
    {label && (
      <label htmlFor={htmlFor} style={labelStyles}>
        {label}
      </label>
    )}
    {children}
    {(touched || checkValidation) &&
      (renderError(error) || renderWarning(warning))}
  </fieldset>
)

export default FieldSet
