import React from 'react'
import styled from 'styled-components'
import UserMiniCard from './UserMiniCard'

const TeamArea = styled.span`
  flex-direction: row;
`

export default ({ team, size, ...rest }) => {
  if (!team || !team.length) {
    return null
  }

  const renderedTeam = team.map((employee, index) => {
    const isLast = index === team.length - 1
    return (
      <UserMiniCard
        size={size || 24}
        employee={employee}
        key={index}
        style={{
          display: 'inline-block',
          marginRight: isLast ? 0 : -10
        }}
      />
    )
  })

  return <TeamArea {...rest}>{renderedTeam}</TeamArea>
}
