import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Icon from 'components/icons'
import { axiosClient } from 'store'

import 'react-image-crop/dist/ReactCrop.css'
import LazyImage from 'components/LazyImage'

const StyledDropzone = styled.div`
  align-items: center;
  border: 2px dotted rgba(200, 200, 200, 0.5);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 4px;
  justify-content: center;
  text-align: center;
  ${props => props.isDragActive && StyledActive};
  ${props => props.isDragAccept && StyledAccept};
  ${props => props.isDragReject && StyledReject};
`

const StyledActive = css`
  background: rgba(230, 230, 230, 0.2);
  border: 2px solid rgba(2, 205, 194, 1);
`

const StyledAccept = css`
  background: rgba(230, 230, 230, 0.2);
  border: 2px solid #45eba5;
`

const StyledReject = css`
  background: rgba(230, 230, 230, 0.2);
  border: 2px solid #dc3545;
`

const AcceptedFilesText = styled.p`
  font-size: .5rem;
  margin: 0;
`

const DeleteFileButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
`

const Progress = props => {
  return (
    <div
      style={{
        background: 'rgba(2, 205, 194, 1)',
        border: '3px solid rgba(2, 205, 194, 1)',
        borderRadius: '5px',
        width: '100%'
      }}
    >
      <p style={{ display: 'none', color: 'white', textAlign: 'center' }}>
        {props.progress}%
      </p>
    </div>
  )
}

class CropAndUploadField extends PureComponent {
  state = {
    showModal: false,
    file: null,

    name: null,
    src: null,
    crop: {
      x: 25,
      y: 0,
      aspect: 1 / 1,
      width: 50,
      height: 50
    },
    progress: 0,
    loading: false
  }

  static propTypes = {
    onChange: PropTypes.func,
    onDrop: PropTypes.func,
    validate: PropTypes.func,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    value: PropTypes.array
  }

  static defaultProps = {
    multiple: false,
    name: 'Upload File',
    type: 'file'
  }

  onProgress = progressEvent => {
    const content = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')

    if (content !== null) {
      this.setState({
        loading: true,
        progress: Math.round((progressEvent.loaded * 100) / content)
      })
    }
  }

  renderWarning = warning =>
    warning && <span className="small text-warning">{warning}</span>

  renderUploadErrors = errors => (
    <ul>
      {errors.map((error, i) => (
        <li key={i} className="small text-danger">
          Failed: {`${error || error.name}`}
        </li>
      ))}
    </ul>
  )

  renderStringErrors = error => (
    <span className="small text-danger">{error}</span>
  )

  renderError = error =>
    error && Array.isArray(error)
      ? this.renderUploadErrors(error)
      : this.renderStringErrors(error)

  upload = async file =>
    await axiosClient.post('/api/upload', file, {
      timeout: 30000,
      onUploadProgress: progressEvent => this.onProgress(progressEvent)
    })

  toggleModal = () => {
    return this.setState(prevState => ({
      showModal: !prevState.showModal
    }))
  }

  onImageLoaded = async (image, pixelCrop) => {
    const aspectCrop = makeAspectCrop(
      {
        x: 25,
        y: 0,
        aspect: 1 / 1,
        width: 50,
        height: 50
      },
      image.naturalWidth / image.naturalHeight
    )

    this.setState(
      {
        image,
        imageWidth: image.naturalWidth,
        imageHeight: image.naturalHeight,
        crop: aspectCrop,
        pixelCrop
      },
      () => this.createCanvas(pixelCrop)
    )
  }

  getCroppedImg = () => {
    const { name } = this.state
    const canvas = this.canvas

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob(file => {
          if (!file) {
            return
          }

          file.name = name

          this.setState({ file })

          resolve(file)
        }, 'image/png')
      } catch (error) {
        console.error(error)
        reject('blob was rejected.')
      }
    })
  }

  onCropComplete = (crop, pixelCrop) => {
    this.setState(
      {
        crop,
        pixelCrop
      },
      () => {
        this.createCanvas(pixelCrop)
      }
    )
  }

  onCropChange = (crop, pixelCrop) => {
    this.setState({ crop, pixelCrop }, () => {
      this.createCanvas(pixelCrop)
    })
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader()
      let name = acceptedFiles[0].name

      reader.addEventListener(
        'load',
        () =>
          this.setState(prevState => ({
            name,
            showModal: !prevState.showModal,
            src: reader.result,
            loading: false,
            progress: 0
          })),
        false
      )

      reader.readAsDataURL(acceptedFiles[0])
    } else {
      console.log('all files rejected: ', rejectedFiles)
    }
  }

  uploadFile = async () => {
    let blob = await this.getCroppedImg()

    let accepted = new FormData()
    accepted.append('blob', blob, this.state.name)

    try {
      let uploadResult = await this.upload(accepted)

      this.setState({
        showModal: false,
        loading: false,
        progress: 100
      })
      return this.props.input.onChange(uploadResult.data[0])
    } catch (error) {
      console.error(error)

      this.setState({ showModal: false, loading: false, progress: 0 })

      // showing a toast with the error message
      toast(
        (error.response && error.response.data) ||
          'Psst...There was some error.'
      )
      return
      // TODO: remove below line in case we don't need it. I think we don't.
      //return this.props.input.onChange(this.state.file)
    }
  }

  createCanvas = pixelCrop => {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas')
    }

    this.canvas.width = Math.min(pixelCrop.width, 256)
    this.canvas.height = Math.min(pixelCrop.height, 256)
    const ctx = this.canvas.getContext('2d')

    ctx.drawImage(
      this.state.image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      this.canvas.width,
      this.canvas.height
    )
  }

  removeFile = e => {
    console.log('e: ', e)
    e.cancelBubble = true
    if (e.stopPropagation) {
      e.stopPropagation()
    }
    this.props.input.onChange(null)
    this.setState({
      file: null
    })
  }

  render() {
    let { input, label, name, meta, size, border } = this.props
    let progressBar
    if (this.state.loading) {
      progressBar = <Progress progress={this.state.progress} />
    }

    return (
      <>
        <div className="form-group">
          {!!label && <label htmlFor={name}>{label}</label>}
          <Dropzone
            accept="image/jpg, image/png, image/jpeg"
            name={name}
            onDrop={this.onDrop}
            style={{
              width: size ? size : 200,
              height: size ? size : 200
            }}
            value={input.value}
            {...this.props}
          >
            {({
              isDragActive,
              isDragAccept,
              isDragReject,
              acceptedFiles,
              rejectedFiles
            }) => (
              <StyledDropzone
                isDragActive={isDragActive}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                acceptedFiles={acceptedFiles}
                rejectedFiles={rejectedFiles}
                style={{
                  width: size ? size : 200,
                  height: size ? size : 200,
                  border: border ? border : '2px dotted rgba(200,200,200,0.5)'
                }}
              >
                {input && input.value && (
                  <div>
                    <LazyImage
                      alt={input.value.url}
                      src={input.value.url}
                      style={{ width: '100%' }}
                    />
                    <DeleteFileButton
                      size="sm"
                      color="link"
                      onClick={this.removeFile}
                    >
                      <Icon icon="FaTimes" color="red" />
                    </DeleteFileButton>
                  </div>
                )}
                {!input.value && (
                  <div className="small text-secondary">
                    Drop Image Here
                    <AcceptedFilesText>(Accepts jpeg or png)</AcceptedFilesText>
                  </div>
                )}
              </StyledDropzone>
            )}
          </Dropzone>
          {progressBar}
          {this.state.files &&
            Array.isArray(this.state.files) &&
            (this.renderError(meta.error) || this.renderWarning(meta.warning))}
        </div>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          size={'sm'}
        >
          <ModalHeader toggle={this.toggleModal}>Crop Logo</ModalHeader>
          <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
            {this.state.src && (
              <ReactCrop
                name={this.state.name}
                src={this.state.src}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Close
            </Button>
            <Button color="primary" onClick={this.uploadFile}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default CropAndUploadField
