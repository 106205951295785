export const getLocations = () => dispatch =>
  dispatch({
    type: 'GET_LOCATIONS',
    payload: {
      request: {
        url: `/api/locations`
      }
    }
  })

export const submitLocation = location => {
  if (location._id) {
    return updateLocation(location)
  } else {
    return createLocation(location)
  }
}

export const getLocation = locationId => dispatch =>
  dispatch({
    type: 'GET_LOCATION',
    payload: {
      request: {
        url: `/api/locations/${locationId}`
      }
    }
  })

export const createLocation = location => dispatch =>
  dispatch({
    type: 'CREATE_LOCATION',
    payload: {
      request: {
        url: `/api/locations`,
        method: 'post',
        data: location
      }
    }
  })

export const updateLocation = location => dispatch =>
  dispatch({
    type: 'UPDATE_LOCATION',
    payload: {
      request: {
        url: `/api/locations/${location._id}`,
        method: 'patch',
        data: location
      }
    }
  })

export const closeLocation = locationId => dispatch =>
  dispatch({
    type: 'CLOSE_LOCATION',
    payload: {
      request: {
        url: `/api/locations/${locationId}/delete`,
        method: 'patch'
      }
    }
  })
