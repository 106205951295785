import React from 'react'

import Field from './field'
import { US_STATES } from '../data'
import { PrimaryButton, Question } from './ui'
import { InputState } from '../../../../../hooks'

export const PlaceStage = ({ current, onPlace }) => {
  const states = Object.keys(US_STATES).map(state => US_STATES[state].name)
  const [value, setValue] = InputState(current || states[0])

  return (
    <Question>
      <Field
        id={'place'}
        label={'In which state is your job located?'}
        labelStyle={{}}
        name={'place'}
        onChange={setValue}
        placeholder={`Select state`}
        style={{ marginTop: '2rem' }}
        type={'select'}
        value={value}
        options={states}
      />

      {value.length > 0 && (
        <PrimaryButton onClick={event => onPlace(event, value)} type={'button'}>
          Next
        </PrimaryButton>
      )}
    </Question>
  )
}

export default PlaceStage
