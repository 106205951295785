import React from 'react'
import styled from 'styled-components'

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const MessageText = styled.p`
  color: ${props => props.theme.colors.light2};
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: center;
`

const MotivationalMessage = ({message}) => {
  return (
    <MessageContainer>
      <MessageText>
        {message}
      </MessageText>
    </MessageContainer>
  )
}

export default MotivationalMessage