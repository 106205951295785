import React from 'react'
import styled from 'styled-components'

const TouchLayer = styled.div`
  min-width: 40px;
  width: 100%;
  background-color: transparent;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const Node = styled.div`
  width: 40px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  background-color: ${props =>
    props.active ? props.theme.colors.clarity : 'transparent'};
`

const NodeText = styled.span`
  font-size: 14px;
  color: ${props =>
    props.active ? props.theme.timebar.activeLabel : props.theme.timebar.label};
`

const TimeNode = ({ active, label, onItemPress, textProps, ...rest }) => (
  <TouchLayer {...rest} onClick={() => onItemPress && onItemPress(label)}>
    <Node active={active}>
      <NodeText active={active} {...textProps}>
        {label}
      </NodeText>
    </Node>
  </TouchLayer>
)

TimeNode.defaultProps = {
  active: false
}

export default TimeNode
