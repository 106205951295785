import React from 'react'
import { Table } from 'reactstrap'

const levels = ['members', 'managers', 'administrators', 'owner*']

const permissionsData = [
  {
    label: 'See Scheduled Walkthroughs & Inspections',
    values: [true, true, true, true]
  },
  // {
  //   label: 'Clock in / clock out of jobs',
  //   values: [true, true, true, true]
  // },
  // {
  //   label: 'Complete tasks in jobs',
  //   values: [true, true, true, true]
  // },
  {
    label: 'Perform assigned walkthroughs and inspections',
    values: [true, true, true, true]
  },
  {
    label: 'Manage Walkthroughs & Inspections',
    values: [false, true, true, true]
  },
  {
    label: 'Manage Clients',
    values: [false, true, true, true]
  },
  {
    label: 'Manage Employees ',
    values: [false, true, true, true]
  },
  {
    label: 'View Route BID Plan & Billing Information',
    values: [false, false, true, true]
  },
  {
    label: 'Change Route plan and billing information',
    values: [false, false, false, true]
  }
]

const YesIcon = () => <span className="text-success">✓</span>
const NoIcon = () => <span className="text-danger">✕</span>

export default () => (
  <div>
    <Table borderless size="sm">
      <thead>
        <tr>
          <th style={{ width: '*' }} />
          {levels.map((level, index) => (
            <th
              style={{
                textTransform: 'capitalize',
                textAlign: 'center'
              }}
              className="small"
              key={index}
            >
              {level}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {permissionsData.map((p, pi) => (
          <tr key={pi}>
            <td className="small">{p.label}</td>
            {p.values.map((value, vi) => (
              <td key={vi} style={{ textAlign: 'center' }}>
                {value ? <YesIcon /> : <NoIcon />}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
    <p className="text-muted small">
      * Only one person per company can be listed as an owner. The current owner
      can transfer ownership if needed.
    </p>
  </div>
)
