import { applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import axiosMiddleware from 'redux-axios-middleware'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'

import analytics from './analytics'
import sentry from './sentry'
import { clientsApi, employeesApi, estimatesApi, inspectionsApi, locationssApi, proposalsApi, walkthroughsApi } from 'store/apis'

export default axiosClient => {
  const middleware = [thunk, axiosMiddleware(axiosClient), analytics, sentry,
    proposalsApi.middleware, estimatesApi.middleware, inspectionsApi.middleware,
    walkthroughsApi.middleware, locationssApi.middleware, clientsApi.middleware,
    employeesApi.middleware]
  if (process.env.NODE_ENV !== 'production') {
    if (
      !process.env.REACT_APP_USE_REDUX_LOGGER ||
      process.env.REACT_APP_USE_REDUX_LOGGER !== '0'
    ) {
      middleware.push(createLogger())
    }

    return composeWithDevTools(applyMiddleware(...middleware))
  } else {
    return applyMiddleware(...middleware)
  }
}
