import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button
} from 'reactstrap'
import {
  FaArrowCircleRight,
  FaEdit,
  FaClone,
  FaTrash,
  FaBan,
  FaCheckSquare,
  FaCloudDownloadAlt,
  FaFileAlt
} from 'react-icons/fa'
import { FiTag } from 'react-icons/fi'

import { IoIosMail } from 'react-icons/io'
import { toast } from 'react-toastify'
import { connectConfirmation } from 'components/confirm'
import Icon from 'components/icons'
import UserMiniCard from 'components/UserMiniCard'
import TinyTeam from 'components/TinyTeam'
import FullScreenGallery from 'components/FullScreenGallery'
import MediaImage from 'components/MediaImage'
import MarkdownViewer from 'components/MarkdownViewer'
import { CloseButton, IconButton } from 'components/lib/Button'
import BigCheckbox from 'components/fields/BigCheckbox'
import * as api from 'api'
import * as acl from 'utils/acl.service'
import { axiosClient } from 'store'

import AreaForm from './AreaForm'
import TaskListItem from './TaskListItem'
import TaskForm from './TaskForm'
import SimpleAreaView from './SimpleAreaView'
import Empty from 'components/Empty'
import TableView from 'components/TableView'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import { isImage } from 'utils/imageHelpers'
import floorTypeSubtotals from 'utils/floorTypeSubtotals'
import fixtureCounter from 'utils/fixtureCounter'
import ReorderAreasModal from './ReorderAreasModal'

import EmailForm from '../../components/forms/EmailForm'
import { JobDetailedView2 } from 'screens/Dashboard/Inspections/Components/JobDetailedView2'


const DVWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`
const DVHeader = styled.h6`
  margin-top: 30px;
  margin-bottom: 4px;
  font-weight: bold;
  color: #656565;
`
const RatingView = styled.div`
  align-self: flex-end;
  margin-bottom: 2px;
  padding-left: 5px;
`

const AddButton = styled.a`
  color: #156e7b;
  margin-left: 10px;
  align-self: flex-end;
  margin-bottom: 4px;
`

const CheckLabel = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 0 4px;
`

const SimpleTaskView = ({ tasks, ...rest }) => {
  const renderedTasks = (tasks || []).map((task, index) => {
    return <TaskListItem key={`task-${index}`} task={task} {...rest} />
  })
  return (
    <Card body style={{ paddingTop: 0, paddingBottom: 0 }}>
      <ListGroup flush>{renderedTasks}</ListGroup>
    </Card>
  )
}

const DateDisplay = ({ date }) => {
  const year = moment(date).year()
  const currentYear = moment().year()
  const dateFormat = year < currentYear ? 'MM/DD/YYYY h:mma' : 'MM/DD h:mma'
  return (
    <span style={{ fontWeight: 'bold' }}>
      {moment(date).format(dateFormat)}
    </span>
  )
}

class JobDetailedView extends React.Component {
  state = {
    showGallery: false,
    galleryImages: [],
    showAreaModal: false,
    showTaskModal: false,
    showReportEmailModal: false,
    showAllFixturesModal: false,
    showLabelsModal: false,
    showCloneModal: false,
    includePicturesWithClone: false,
    downloadButtonBusy: false,
    job: {},
    ready: false
  }

  async componentDidMount() {
    const { jobId } = this.props
    let result = await this.props.getJob(jobId)

    if (!result.error) {
      this.setState({
        ready: true,
        job: result.payload.data
      })
    } else {
      console.error(result.error)
    }
  }

  onUpdateJob = async job => {
    await this.props.updateJob(job)
    this.setState({
      job: { ...this.state.job, ...this.props.updatedJob }
    })
  }

  _openGallery = url => {
    const job = this.state.job

    let galleryImages = (job.attachments || [])
      .filter(file => isImage(file.type))
      .map(image => {
        return {
          original: image.url,
          thumbnail: image.url
        }
      })

    let areas = job.areas || []
    areas.forEach(area => {
      area.attachments
        .filter(file => isImage(file.type))
        .forEach((image, index) => {
          galleryImages.push({
            original: image.url,
            thumbnail: image.url,
            originalTitle: area.name,
            description: area.name + ' Image ' + (index + 1),
            note: image.note
          })
        })
    })

    const startIndex = _.findIndex(galleryImages, { original: url })

    this.setState({
      showGallery: true,
      galleryImages,
      galleryStartIndex: startIndex > 0 ? startIndex : 0
    })
  }

  _onAreaDeleted = area => {
    if (!area) {
      return
    }

    let areas = _.pull(this.state.job.areas, area)
    this.onUpdateJob({
      _id: this.state.job._id,
      areas
    })
  }

  _removeEmptyAreas = () => {
    const { areas } = this.state.job
    if (!areas) {
      return
    }
    let newAreas = []

    areas.map(area => {
      // TODO: This logic needs to be better. Won't work well if we add new fields to Areas.
      // Such as fixtures etc.
      let noAttachments =
        (area.attachments && area.attachments.length === 0) || !area.attachments
      let noDimensions =
        (area.dimensions &&
          area.dimensions.set &&
          area.dimensions.set.length === 0) ||
        (area.dimensions && !area.dimensions.set) ||
        !area.dimensions

      if (
        noAttachments &&
        noDimensions &&
        !area.notes &&
        !area.squareFootage &&
        !area.floorType
      ) {
        return null
      } else {
        return newAreas.push(area)
      }
    })

    this.onUpdateJob({
      _id: this.state.job._id,
      areas: newAreas
    })

    return toast('Removed all empty areas.')
  }

  _onAreaEdited = area => {
    if (!area) {
      return
    }
    area.squareFootage = 0
    if (area.dimensions && area.dimensions.set && area.dimensions.set.length) {
      area.dimensions.set.forEach(
        dimension => (area.squareFootage += dimension.width * dimension.depth)
      )
    }
    let areas = [...this.state.job.areas]
    if (area._id) {
      const index = _.findIndex(areas, a => {
        return a._id === area._id
      })
      areas[index] = area
    } else {
      areas.push(area)
    }

    this.onUpdateJob({
      _id: this.state.job._id,
      areas
    })
  }

  _toggleReorderModal = () => {
    this.setState({
      showReorderModal: !this.state.showReorderModal
    })
  }

  _areasReordered = areas => {
    this.onUpdateJob({
      _id: this.state.job._id,
      areas
    })
  }

  _onAreaSubmitted = area => {
    area.squareFootage = 0
    if (area.dimensions && area.dimensions.set.length) {
      area.dimensions.set.forEach(
        dimension => (area.squareFootage += dimension.width * dimension.depth)
      )
    }
    let areas = [...this.state.job.areas]
    areas.push(area)

    this.onUpdateJob({
      _id: this.state.job._id,
      areas
    })
  }

  _addArea = () => {
    this.setState({
      showAreaModal: true
    })
  }

  _toggleAreaModal = () => {
    this.setState({
      showAreaModal: false
    })
  }

  _toggleCloneModal = () => {
    const showCloneModal = this.state?.showCloneModal
    this.setState({
      showCloneModal: !showCloneModal
    })
  }

  _onAreaSubmit = async values => {
    await this._onAreaSubmitted(values)
    this.setState({
      showAreaModal: false
    })
  }

  _onTaskDeleted = task => {
    if (!task) {
      return
    }

    let tasks = _.pull(this.state.job.tasks, task)
    this.onUpdateJob({
      _id: this.state.job._id,
      tasks
    })
  }

  _onTaskSubmitted = task => {
    let tasks = [...this.state.job.tasks]
    tasks.push(task)

    this.onUpdateJob({
      _id: this.state.job._id,
      tasks
    })
  }

  _addTask = () => {
    this.setState({
      showTaskModal: true
    })
  }

  _showAllFixtures = () => {
    this.setState({
      showAllFixturesModal: true
    })
  }

  _toggleTaskModal = () => {
    this.setState({
      showTaskModal: false
    })
  }

  _toggleAllFixturesModal = () => {
    this.setState({
      showAllFixturesModal: false
    })
  }

  _onTaskSubmit = async values => {
    await this._onTaskSubmitted(values)
    this.setState({
      showTaskModal: false
    })
  }

  _toggleReportEmailModal = () => {
    this.setState({
      showReportEmailModal: !this.state.showReportEmailModal
    })
  }

  _emailReport = async values => {
    this.setState({
      emailButtonBusy: true
    })

    const { job } = this.state
    const formData = new FormData()
    let emailList = []

    if (values.otherEmails) {
      emailList = emailList.concat(values.otherEmails.split(','))
    }

    emailList = emailList
      .map(email => (email = email.trim()))
      .filter(email => email.length > 0)
    values.emailList = emailList

    for (let key in values) {
      formData.append(key, values[key])
    }

    // remove the buggy attachment key
    formData.delete('attachments')

    // if there are attachments, then insert in FormData correctly
    if (values.attachments) {
      values.attachments.forEach(file => {
        formData.append('attachments', file)
      })
    }

    await axiosClient.post(`api/jobs/${job._id}/ir-email`, formData, {
      timeout: 1000 * 45
    })

    this.setState({
      emailButtonBusy: false
    })
    this._toggleReportEmailModal()
  }

  _onDownloadPress = async () => {
    const { job } = this.state

    this.setState({
      downloadButtonBusy: true
    })
    if (job.type === 'Inspection') {
      await this.props.onDownloadReport(this.props.job)
    } else {
      await this.props.onPDF()
    }
    this.setState({
      downloadButtonBusy: false
    })
  }

  _renderFixtures = fixtures => {
    if (!fixtures || !fixtures.length) {
      return <Empty message="No fixtures found." />
    }

    const columns = [
      { header: 'Name', accessor: 'key' },
      {
        header: 'Quantity',
        render: fixture => {
          return parseInt(fixture.quantity)
        }
      }
    ]

    return (
      <>
        <TableView
          data={fixtures}
          columns={columns}
          style={{ marginTop: 20 }}
        />
      </>
    )
  }

  _handleAreaCloneDone = async (area, areaIndex, numberOfClones) => {
    if (!area) {
      return null
    }

    let newAreas = []

    for (let i = 0; i < numberOfClones; i++) {
      let newArea = _.cloneDeep(area)
      delete newArea._id
      delete newArea.completed
      newAreas.push(newArea)
    }

    let areas = [
      ...this.state.job.areas.slice(0, areaIndex + 1),
      ...newAreas,
      ...this.state.job.areas.slice(areaIndex + 1)
    ]

    this.onUpdateJob({
      _id: this.state.job._id,
      areas
    })
  }

  _jobCloneSubmit = async () => {
    const includePictures = this.state?.includePicturesWithClone
    const { onClone } = this.props

    await onClone(includePictures)
    this.setState({ showCloneModal: false })
  }

  render() {
    const {
      job,
      ready,
      showReorderModal,
      showLabelsModal,
      includePicturesWithClone
    } = this.state

    const {
      onCancel,
      onComplete,

      onStart,
      onEdit,
      onRemove,
      onUpdateLocationAreas,
      onCreateProposal,
      onCreateEstimate,
      showOptions
    } = this.props

    if (!ready) {
      return null
    }
    let renderedTeam
    if (job && job.team && job.team.length) {
      renderedTeam = <TinyTeam team={job.team} size={32} />
    }

    let fixtures = []

    if (job && job.areas) {
      const pullFixtures = fixtureCounter(job.areas)
      fixtures = Object.keys(pullFixtures.types).map(key => {
        return { key: key, quantity: pullFixtures.types[key] }
      })
    }

    let totalSquareFootage = 0
    let floorSubtotals = {}

    if (job) {
      const calcTotals = floorTypeSubtotals(job.areas)
      totalSquareFootage = calcTotals.total
      floorSubtotals = calcTotals.types
    }

    totalSquareFootage = totalSquareFootage.toFixed(2)

    let subtotals = Object.keys(floorSubtotals).map((type, index) => (
      <div key={index}>
        {type}: {floorSubtotals[type].toFixed(2)} Sq. Ft.
      </div>
    ))

    let renderedMedia = (job.attachments || []).map((image, index) => {
      const url = image.url
      const isImageAttachment = isImage(image.type)
      const renderItem = !isImageAttachment ? (
        <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
          <Icon
            size={128}
            icon="FaFilePdf"
            color="black"
            style={{ marginRight: 10 }}
          />
        </a>
      ) : (
        <MediaImage
          onClick={() => this._openGallery(url)}
          src={url}
          key={index}
        />
      )
      return renderItem
    })

    console.log({ job })

    const isInspectionV2 = job?.type === 'Inspection' && job?.version == 2

    return (
      <div className="small">
        <Modal
          isOpen={this.state.showAreaModal}
          toggle={this._toggleAreaModal}
          className="big-modal"
          fade={false}
        >
          <ModalHeader toggle={this._toggleAreaModal}>
            <strong>Add New Area</strong>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
            <AreaForm
              onImageClicked={this._openGallery}
              onSubmit={this._onAreaSubmit}
              images={null}
              initialValues={{}}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showTaskModal}
          toggle={this._toggleTaskModal}
          className="big-modal"
          fade={false}
        >
          <ModalHeader toggle={this._toggleTaskModal}>
            <strong>Add New Task</strong>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
            <TaskForm
              onImageClicked={this._openGallery}
              onSubmit={this._onTaskSubmit}
              images={null}
              initialValues={{}}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showAllFixturesModal}
          toggle={this._toggleAllFixturesModal}
          size="md"
          fade={false}
        >
          <ModalHeader toggle={this._toggleAllFixturesModal}>
            <strong>Fixtures</strong>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
            {this._renderFixtures(fixtures)}
          </ModalBody>
        </Modal>

        <FullScreenGallery
          isOpen={this.state.showGallery}
          items={this.state.galleryImages}
          startIndex={this.state.galleryStartIndex}
          onClose={() =>
            this.setState({
              showGallery: false
            })
          }
        />

        <Modal
          isOpen={this.state.showReportEmailModal}
          toggle={this._toggleReportEmailModal}
          style={{ paddingTop: 50 }}
        >
          <ModalHeader style={{ justifyContent: 'center' }}>
            {'Email Inspection Report'}
          </ModalHeader>
          <ModalBody>
            <EmailForm
              onSubmit={this._emailReport}
              busy={this.state.emailButtonBusy}
              initialValues={{ ccSelf: true }}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showCloneModal}
          toggle={this._toggleCloneModal}
          className="big-modal"
          fade={false}
          style={{ maxWidth: 400, marginTop: 200 }}
        >
          <ModalHeader toggle={this._toggleCloneModal}>
            <strong>Clone {job.type}</strong>
          </ModalHeader>
          <ModalBody className="bg-light1">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <BigCheckbox
                  itemValue={includePicturesWithClone}
                  checked={includePicturesWithClone}
                  onChange={event =>
                    this.setState({
                      includePicturesWithClone: !includePicturesWithClone
                    })
                  }
                />
                <CheckLabel>
                  Include existing areas with pictures in clone?
                </CheckLabel>
              </div>
              <Button onClick={this._jobCloneSubmit} style={{ marginLeft: 8 }}>
                Submit
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {showReorderModal && (
          <ReorderAreasModal
            areas={job.areas}
            areasReordered={this._areasReordered}
            toggleReorderModal={this._toggleReorderModal}
          />
        )}


        <Row>
          {isInspectionV2
            ?
            <Col xs={'10'}>
              <JobDetailedView2 job={job} updateInspection={this.onUpdateJob} />
            </Col>
            :
            <Col>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 32 }}>
                  <DVHeader>Team</DVHeader>
                  {renderedTeam}
                </div>
                <div>
                  <DVHeader>Labels</DVHeader>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {job &&
                      job.labels &&
                      job.labels.length > 0 &&
                      job.labels.map(label => {
                        return (
                          <Label
                            key={label._id}
                            color={label.color}
                            style={{ marginRight: 8 }}
                          >
                            {label.name}
                          </Label>
                        )
                      })}
                  </div>
                </div>
              </div>

              {job.type === 'Inspection' && job.rating && (
                <DVWrapper>
                  <DVHeader>Rating: </DVHeader>
                  <RatingView>{job.rating}</RatingView>
                </DVWrapper>
              )}

              <DVHeader>Notes</DVHeader>
              <MarkdownViewer value={job.notes || 'No Notes.'} color={'white'} />

              <div>
                <DVWrapper>
                  <DVHeader style={{ paddingBottom: 2 }}>Tasks</DVHeader>
                  {acl.isAllowed('jobs', 'update') && showOptions && (
                    <AddButton onClick={this._addTask} href="#">
                      Add
                    </AddButton>
                  )}
                </DVWrapper>
                {job.tasks && job.tasks.length > 0 && (
                  <>
                    <SimpleTaskView
                      onImageClicked={this._openGallery}
                      onTaskDeleted={this._onTaskDeleted}
                      tasks={job.tasks}
                    />
                  </>
                )}
              </div>

              <div>
                <DVWrapper>
                  <DVHeader style={{ paddingBottom: 2 }}>Areas</DVHeader>
                  {acl.isAllowed('jobs', 'update') && showOptions && (
                    <>
                      <AddButton onClick={this._addArea} href="#">
                        Add
                      </AddButton>
                      {job.areas && job.areas.length > 0 && (
                        <>
                          <AddButton onClick={this._toggleReorderModal} href="#">
                            Reorder Areas
                          </AddButton>
                          <AddButton
                            onClick={() =>
                              this.props.confirm(
                                'Confirm Area Removal',
                                'Are you sure you want to remove all empty areas from this job? This will remove any area that simply contains a name and a type with no additional information.',
                                this._removeEmptyAreas
                              )
                            }
                            href="#"
                          >
                            Remove Empty Areas
                          </AddButton>
                        </>
                      )}
                    </>
                  )}
                </DVWrapper>
                {job.areas && job.areas.length > 0 && (
                  <>
                    <SimpleAreaView
                      onImageClicked={this._openGallery}
                      onAreaDeleted={this._onAreaDeleted}
                      onAreaEdited={this._onAreaEdited}
                      submitClone={this._handleAreaCloneDone}
                      areas={job.areas}
                      jobType={job.type}
                      showOptions={showOptions}
                      areasRearranged={this._areasRearranged}
                    />
                    {subtotals}
                    <div>{`TOTAL: ${totalSquareFootage} Sq. Ft`}</div>
                  </>
                )}
              </div>

              {renderedMedia && renderedMedia.length > 0 && (
                <div>
                  <DVHeader>Attachments</DVHeader>
                  {renderedMedia}
                </div>
              )}

              <DVHeader>Activity</DVHeader>
              {job.createdAt && (
                <div>
                  Created at <DateDisplay date={job.createdAt} /> by{' '}
                  <UserMiniCard
                    employee={job.createdBy ? job.createdBy : null}
                    showName={true}
                  />
                </div>
              )}
              {job.scheduledAt && (
                <div>
                  Scheduled for <DateDisplay date={job.scheduledAt} />
                </div>
              )}
              {job.startedAt && (
                <div>
                  Started at <DateDisplay date={job.startedAt} />
                </div>
              )}
              {job.completedAt && (
                <div>
                  Completed at <DateDisplay date={job.completedAt} /> by{' '}
                  <UserMiniCard
                    employee={job.completedBy ? job.completedBy : null}
                    showName={true}
                  />
                </div>
              )}
            </Col>
          }
          {acl.isAllowed('jobs', 'update') && showOptions && (
            <Col xs={'2'}>
              {!job.completedAt && !job.cancelled && (
                <>
                  <DVHeader>Change Status</DVHeader>
                  {job.status &&
                    job.status.label &&
                    job.status.label !== 'in progress' && (
                      <IconButton
                        block
                        onClick={onStart}
                        size="sm"
                        color="primary"
                      >
                        <FaArrowCircleRight />
                        In Progress
                      </IconButton>
                    )}
                  <IconButton
                    block
                    onClick={onComplete}
                    size="sm"
                    color="success"
                  >
                    <FaCheckSquare /> Complete
                  </IconButton>
                  <IconButton
                    block
                    onClick={() =>
                      this.props.confirm(
                        'Confirm Cancel',
                        'Are you sure you want to cancel this job?',
                        onCancel
                      )
                    }
                    size="sm"
                    color="danger"
                  >
                    <FaBan /> Cancel
                  </IconButton>
                </>
              )}

              {job.type === 'Walkthrough' && job.completedAt && (
                <>
                  <hr />
                  <IconButton
                    onClick={() => onCreateEstimate(job)}
                    block
                    size="sm"
                    color="primary"
                  >
                    <FaFileAlt /> Estimate
                  </IconButton>
                  <IconButton
                    onClick={() => onCreateProposal(job._id)}
                    block
                    size="sm"
                    color="primary"
                  >
                    <FaFileAlt /> Proposal
                  </IconButton>
                </>
              )}
              {job.type === 'Inspection' && job.completedAt && (
                <>
                  <hr />
                  <IconButton
                    block
                    size="sm"
                    color="primary"
                    onClick={() => this._toggleReportEmailModal()}
                    busy={this.state.emailButtonBusy}
                  >
                    <IoIosMail />
                    Send Report
                  </IconButton>
                </>
              )}

              <hr />

              <Dropdown
                isOpen={showLabelsModal}
                toggle={() =>
                  this.setState({
                    showLabelsModal: !showLabelsModal
                  })
                }
              >
                <DropdownToggle block style={{ textAlign: 'left' }} size="sm">
                  <FiTag /> Labels
                </DropdownToggle>
                <DropdownMenu right={true}>
                  <LabelEditor
                    data={job.labels}
                    onChange={data =>
                      this.onUpdateJob({
                        _id: job._id,
                        labels: data
                      })
                    }
                    refreshEntity={async () => {
                      let res = await this.props.getJob(job._id)

                      if (res && res.payload && res.payload.data) {
                        this.setState({
                          job: res.payload.data
                        })
                      }
                    }}
                  />
                </DropdownMenu>
              </Dropdown>

              <hr />

              {!isInspectionV2 ? 
                <IconButton block onClick={() => onEdit(job)} size="sm">
                  <FaEdit /> Edit
                </IconButton>
              : null}
              <IconButton block onClick={this._toggleCloneModal} size="sm">
                <FaClone /> Clone
              </IconButton>
              <hr />
              <IconButton
                block
                onClick={this._onDownloadPress}
                size="sm"
                busy={this.state.downloadButtonBusy}
              >
                <FaCloudDownloadAlt /> Download
              </IconButton>

              {job.completedAt ? (
                <IconButton
                  block
                  onClick={() =>
                    this.props.confirm(
                      'Confirm Update Areas',
                      'Are you sure you want to update the areas in the location?',
                      () => onUpdateLocationAreas(job)
                    )
                  }
                  size="sm"
                >
                  Update Areas
                </IconButton>
              ) : (
                ''
              )}

              {job.areas && job.areas.length > 0 && (
                <IconButton block onClick={this._showAllFixtures} size="sm">
                  All Fixtures
                </IconButton>
              )}
              <hr />
              <IconButton
                block
                onClick={() =>
                  this.props.confirm(
                    'Confirm Archive',
                    'Are you sure you want to archive this job?',
                    onRemove
                  )
                }
                size="sm"
                color="danger"
              >
                <FaTrash /> Archive
              </IconButton>
            </Col>
          )}
        </Row>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  updatedJob: state.jobs.updatedJob
})

export default connect(
  mapStateToProps,
  api
)(connectConfirmation(JobDetailedView))
