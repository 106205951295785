import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, ListGroupItem } from 'reactstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default ({ areas, areasReordered, toggleReorderModal, ...rest }) => {
  const [elements, setElements] = useState([])

  useEffect(() => {
    if (areas.length !== elements.length) {
      setElements(areas)
    }
  })

  const onDragEnd = async result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newElements = reorder(
      elements,
      result.source.index,
      result.destination.index
    )

    setElements(newElements)
    areasReordered(newElements)
  }

  const renderedAreas = (elements || []).map((area, index) => (
    <Draggable key={area.id} draggableId={area.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListGroupItem style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
            <span><strong>Name: </strong>{area.name}</span>
            <span><strong>Type: </strong>{area.type}</span>
          </ListGroupItem>
        </div>
      )}
    </Draggable>
  ))

  return (
    <Modal
      isOpen={true}
      toggle={toggleReorderModal}
      fade={false}
      style={{ maxWidth: 600, marginTop: 100 }}
      body
    >
      <ModalHeader toggle={toggleReorderModal}>
        <strong>Reorder Areas</strong>
      </ModalHeader>
      <ModalBody className="bg-light1">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="simple-draggable-area">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {renderedAreas}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter />
    </Modal>
  )
}
