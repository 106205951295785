import React from 'react'
import Dropzone from 'react-dropzone'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled, { css } from 'styled-components'
import { axiosClient } from 'store'
import LazyImage from 'components/LazyImage'

const DropZoneArea = styled(Dropzone)`
  width: 48px;
  height: 48px;
  border-radius: 2px;
  margin-top: -8px;
  margin-bottom: -6px;
  overflow: hidden;
  ${props =>
    props.empty
      ? css`
          border: 1px dashed #888;
          background-color: #333;
        `
      : null};
`

class LogoDropZone extends React.Component {
  state = {
    showModal: false,
    file: null,

    name: null,
    src: null,
    crop: {
      x: 25,
      y: 0,
      aspect: 1 / 1,
      width: 50,
      height: 50
    }
  }

  toggleModal = () => {
    return this.setState(prevState => ({
      showModal: !prevState.showModal
    }))
  }

  onImageLoaded = async (image, pixelCrop) => {
    const aspectCrop = makeAspectCrop(
      {
        x: 25,
        y: 0,
        aspect: 1 / 1,
        width: 50,
        height: 50
      },
      image.naturalWidth / image.naturalHeight
    )

    this.setState(
      {
        image,
        imageWidth: image.naturalWidth,
        imageHeight: image.naturalHeight,
        crop: aspectCrop,
        pixelCrop
      },
      () => this.createCanvas(pixelCrop)
    )
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader()
      let name = acceptedFiles[0].name

      reader.addEventListener(
        'load',
        () =>
          this.setState(prevState => ({
            name,
            showModal: !prevState.showModal,
            src: reader.result,
            loading: false,
            progress: 0
          })),
        false
      )

      reader.readAsDataURL(acceptedFiles[0])
    } else {
      console.error('Files rejected: ', rejectedFiles)
    }
  }

  onCropComplete = (crop, pixelCrop) => {
    this.setState(
      {
        crop,
        pixelCrop
      },
      () => {
        this.createCanvas(pixelCrop)
      }
    )
  }

  onCropChange = (crop, pixelCrop) => {
    this.setState({ crop, pixelCrop }, () => {
      this.createCanvas(pixelCrop)
    })
  }

  createCanvas = pixelCrop => {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas')
    }

    this.canvas.width = Math.min(pixelCrop.width, 256)
    this.canvas.height = Math.min(pixelCrop.height, 256)
    const ctx = this.canvas.getContext('2d')

    ctx.drawImage(
      this.state.image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      this.canvas.width,
      this.canvas.height
    )
  }

  getCroppedImg = () => {
    const { name } = this.state
    const canvas = this.canvas

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob(file => {
          if (!file) {
            return
          }

          file.name = name

          this.setState({ file })

          resolve(file)
        }, 'image/png')
      } catch (error) {
        console.error(error)
        reject('blob was rejected.')
      }
    })
  }

  upload = async file =>
    await axiosClient.post('/api/upload', file, {
      timeout: 30000
    })

  uploadFile = async () => {
    let blob = await this.getCroppedImg()

    let accepted = new FormData()
    accepted.append('blob', blob, this.state.name)

    try {
      let uploadResult = await this.upload(accepted)

      this.setState({
        showModal: false,
        loading: false
      })

      return this.props.onDrop(uploadResult.data[0])
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { value } = this.props
    let isEmpty = 'yes'
    if (value && value.url) {
      isEmpty = undefined
    }

    return (
      <>
        <DropZoneArea
          accept="image/jpg, image/png, image/jpeg"
          empty={isEmpty}
          onDrop={this.onDrop}
          style={{ ...this.props.style }}
        >
          {value && value.url && (
            <LazyImage
              style={{
                maxWidth: '100%',
                maxHeight: '100%'
              }}
              src={value.url}
            />
          )}
        </DropZoneArea>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          size={'sm'}
        >
          <ModalHeader toggle={this.toggleModal}>Crop Logo</ModalHeader>
          <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
            {this.state.src && (
              <ReactCrop
                name={this.state.name}
                src={this.state.src}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Close
            </Button>
            <Button color="primary" onClick={this.uploadFile}>
              Finish
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default LogoDropZone
