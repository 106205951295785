import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Card } from 'reactstrap'
import { getClients } from 'api'

import {
  DropdownField,
  GooglePlacesField,
  InputField,
  UploadField
} from 'components/fields'

import { ClientItem } from 'components/items'

import { LocationItem } from 'components/items'
import ErrorMessage from 'components/ErrorMessage'
import { image, required } from 'utils'

import { LocationTypes } from 'data'
import { normalizePhone } from 'utils/normalizers'

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

class LocationForm extends Component {
  state = {}

  static propTypes = {
    location: PropTypes.object
  }

  async UNSAFE_componentWillMount() {
    if (!this.props.initialValues || !this.props.initialValues.client) {
      await this.props.getClients()
    }
  }

  render() {
    let { error, initialValues, clients } = this.props

    let errorMessage = null
    if (error) {
      errorMessage = <ErrorMessage error={error} />
    }

    return (
      <Card body>
        <form onSubmit={this.props.handleSubmit}>
          {(!initialValues || !initialValues.client) &&
            <Field
              name="client"
              component={DropdownField}
              data={clients}
              itemComponent={ClientItem}
              type="select"
              label="Client"
              textField={item => (item ? item.name : null)}
              valueField="_id"
              normalize={value => (value ? value._id : null)}
              validate={[required]}
              warn={required}
              filter={'contains'}
            />
          }
          <Field
            name="name"
            component={InputField}
            type="text"
            label="Location Name"
            validate={required}
            warn={required}
          />

          <Field
            name="address"
            component={GooglePlacesField}
            type="text"
            label="Location Address"
            validate={required}
            warn={required}
          />

          <Field
            name="contact.phone"
            component={InputField}
            type="text"
            label="Contact Phone"
            normalize={normalizePhone}
          />

          <Field
            name="type"
            component={DropdownField}
            data={LocationTypes}
            itemComponent={LocationItem}
            type="select"
            label="Location Type"
            textField="name"
            normalize={value => {
              if (!value) {
                return null
              }
              return value.name
            }}
            validate={[required]}
            warn={required}
          />

          <Field
            name="notes"
            component={InputField}
            type="textarea"
            label="Notes"
          />

          <Field
            name="attachments"
            component={UploadField}
            type="file"
            label="Add Files"
            multiple
            validate={image}
          />
          <SubmitContainer>
            {errorMessage}
            <button type="submit" className="btn btn-primary">
              Done
            </button>
          </SubmitContainer>
        </form>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.clients.clients
})

export default compose(
  reduxForm({ form: 'Location' }),
  connect(
    mapStateToProps,
    { getClients }
  )
)(LocationForm)
