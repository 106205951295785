const renderTasks = tasks => {
  return tasks.map((task) => `<tr><td>${task.name || ''}</td><td>${task.frequency || ''}</td></tr>`
  ).join('')
}

const renderSection = (section) => {
  const tasks = section.tasks && section.tasks.length && section.tasks
  return `<div><h4>${section.name || 'No section name'}</h4>${tasks ? `<table><thead><tr><th>Task</th><th>Frequency</th></tr></thead><tbody>${renderTasks(tasks)}</tbody></table>` : ''}</div>`
}

export const elementString = (element) => {
  const data = element && element.data
  const taskSections = data && data.taskSections
  const heading = data && data.headingText
  if (!taskSections) {
    return null
  }
  const elementString = `<div>${heading ? `<h3>${heading}</h3>` : ''}${taskSections.map((section) => renderSection(section)).join('')}</div>`
  return elementString
}

export const scopeOfWorkToString = (elements) => {
  const scopeOfWorkElements = elements.filter(el => el.type === 'scope_of_work')
  if (!scopeOfWorkElements.length) {
    return null
  }
  return scopeOfWorkElements.map(element => elementString(element)).join('')
}