import React from 'react'
import MarkdownViewer from 'components/MarkdownViewer'
import styled from 'styled-components'

const MdContainer = styled.div`
  padding: 24px 26px;
  #viewMarkdown {
    * {
      font-size: 12px;
      font-family: Arial, sans-serif;
    }

    .header-merchant-area {
      margin-bottom: 10px;
    }

    .header-background {
      width: 100%;
      margin-bottom: 32px;
    }

    .header-content {
      padding: 20px 30px;
    }

    .logo-placement > img {
      display: inline-block;
    }

    .logo-placement > div {
      display: inline-block;
    }

    .logo {
      border-radius: 2px;
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
    }

    .CodeMirror {
      font-family: Arial, sans-serif;
    }

    *:not(table) {
      line-height: 160%;
      box-sizing: content-box;
    }

    i,
    cite,
    em,
    var,
    address,
    dfn {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    p {
      margin: 10px 0;
      color: #555;
    }

    h1:first-of-type,
    div > div:first-of-type h1 {
      margin-top: 14px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      border-bottom: none;
    }

    h1 {
      font-size: 1.6rem;
      line-height: 28px;
      margin: 52px 0 15px 0;
      padding-bottom: 7px;
      color: #000;
    }

    h2 {
      font-size: 1.3rem;
      line-height: 23px;
      margin: 30px 0 13px 0;
      color: #333;
    }

    .section-title {
      text-align: center;
      text-transform: uppercase;
      border-top: 2px solid #333;
      border-bottom: 2px solid #333;
      margin: 0;
      margin-bottom: 32px;
      padding: 0;
      padding-bottom: 0;
      line-height: 2;
    }

    h3,
    h4 {
      font-size: 1.2rem;
      line-height: 18px;
      margin: 20px 0 2px;
      color: #333;
    }

    h5,
    h6 {
      font-size: 1rem;
      line-height: 17px;
      margin: 10px 0 -4px;
      color: #333;
    }

    blockquote {
      margin: 15px 0;
    }

    blockquote {
      border-left: 4px solid #dddddd;
      padding: 0 15px;
      color: #777777;
    }

    blockquote > :first-child {
      margin-top: 0;
    }

    blockquote > :last-child {
      margin-bottom: 0;
    }

    pre,
    code {
      font-family: Consolas, Courier, 'Apple SD 산돌고딕 Neo', -apple-system,
        'Lucida Grande', 'Apple SD Gothic Neo', '맑은 고딕', 'Malgun Gothic',
        'Segoe UI', '돋움', dotum, sans-serif;
      border: 0;
      border-radius: 0;
    }

    pre {
      margin: 2px 0 8px;
      padding: 18px;
      background-color: #f5f7f8;
    }

    code {
      color: #c1788b;
      padding: 4px 4px 2px 0;
      letter-spacing: -0.3px;
    }

    pre code {
      padding: 0;
      color: inherit;
      white-space: pre-wrap;
      background-color: transparent;
    }

    pre.addon {
      border: 1px solid #e8ebed;
      background-color: #fff;
    }

    .img {
      margin: 4px 0 10px;
      box-sizing: border-box;
      vertical-align: top;
      max-width: 100%;
    }

    table {
      margin: 2px 0 14px;
      color: #555;
      width: auto;
      border-collapse: collapse;
      box-sizing: border-box;
    }

    table th,
    table td {
      height: auto;
      padding: 2px 6px;
    }

    table td {
      border: 1px solid #eaeaea;
    }

    tbody tr:nth-child(even) {
      background-color: #f6f6f6;
    }

    table th {
      border: 1px solid #72777b;
      border-top: 0;
      background-color: #7b8184;
      font-weight: 300;
      color: #fff;
      padding-top: 6px;
      text-align: left;
    }

    ul,
    menu,
    ol,
    dir {
      display: block;
      list-style-type: disc;
      padding-left: 17px;
      margin: 6px 0 10px;
      color: #555;
    }

    ol {
      list-style-type: decimal;
    }

    ul ul,
    ul ol,
    ol ol,
    ol ul {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    ul li {
      position: relative;
    }

    ul p,
    ol p {
      margin: 0;
    }

    ul li.task-list-item:before,
    pre ul li:before {
      content: '';
    }

    hr {
      background-color: transparent;
      border: none;
      height: 1px;
      border-bottom: 1px solid #aaa;
    }

    a {
      text-decoration: underline;
      color: #5286bc;
    }

    a:hover {
      color: #007cff;
    }


    .task-section,
    table,
    tr,
    td,
    th,
    tbody,
    thead,
    tfoot {
      page-break-inside: avoid !important;
    }

    .task-list-item {
      border: 0;
      list-style: none;
      padding-left: 22px;
      margin-left: -22px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 0 2px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAADdJREFUKBVjvHv37n8GMgALSI+SkhJJWu/du8fARJIOJMWjGpECA505GjjoIYLEB6dVUNojFQAA/1MJUFWet/4AAAAASUVORK5CYII=');
    }

    .task-list-item.checked {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAMpJREFUKBVjjJ/64D8DGYCJDD1gLbTVyM3OxJDiJMzAxcYIdyALnIWDAdJU7i/OICfCxsDMxMgwc88bwk5F1vTs/W+GFUffwY2H+1FBlI2hLliCQYCbGSyJrqlzwwuGj9//YWoMtRBgUBJnZ6gMEGeQFWaFOw9kE7omkG5GWDyCPF7mJ86gIMbO8P//fwZGRkYGXJpAGuFO/fbrP0PXppcMD179JKgJRSOIA9N8/NZXrM4DqYEBjOgAaYYFOUwRNhruVGyS+MTI1ggAx8NTGcUtFVQAAAAASUVORK5CYII=');
    }

    .task-list-item input[type='checkbox'],
    .task-list-item .task-list-item-checkbox {
      margin-left: -17px;
      margin-right: 3.8px;
      margin-top: 3px;
    }

    .task {
      margin-bottom: 16px;
    }

    .task-name {
      width: 80%;
      display: inline-block;
    }

    .frequency {
      float: right;
      width: 4.5em;
      text-align: center;
    }

    .area-type {
      color: #999;
      margin-left: 2px;
    }

    .area-item {
      margin-left: 18px;
      font-size: 0.8em;
      color: #666;
      max-width: 500px;
    }

    .item-title {
      color: #aaa;
    }

    .dimension {
      padding: 0 0 0 6px;
    }

    .area-image {
      display: inline-block;
      height: 170px;
      margin-right: 8px;
    }

    .column-header {
      font-size: 1.1em;
      font-weight: bold;
      color: #333;
    }

    .company-signature {
      font-size: 1.1em;
      font-weight: bold;
      text-decoration: underline;
    }

    .horizontal-line {
      margin-top: 36px;
      margin-bottom: 3px;
    }

    .footer {
      text-align: right;
    }

    .service-days + table {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export default ({ preview }) => (
  <MdContainer>
    <MarkdownViewer value={preview} />
  </MdContainer>
)
