import React from 'react'
import { CardBody, ListGroup, ListGroupItem } from 'reactstrap'

import DarkCard from './DarkCard'

const listStyle = {
  backgroundColor: 'transparent',
  color: '#808390',
  borderColor: 'rgba(255,255,255,0.1)',
  paddingLeft: 5,
  paddingRight: 5,
  display: 'flex',
  flexDirection: 'row'
}

const Info = ({
  currentEmployment, profile, timezone, employment
}) => {

  let phoneEmployment = employment?.phone || ''
  let addressEmployment = employment?.address?.formattedAddress || ''
  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <DarkCard>
        <CardBody>
          <ListGroup flush style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Company</span>
              <span style={{ flex: 5, color: 'white' }}>
                {currentEmployment.company.name}
              </span>
            </ListGroupItem>
            {/*
              <ListGroupItem style={listStyle}>
                <span style={{ flex: 1 }}>Position</span>
                <span style={{ flex: 5, color: 'white' }}>
                  {currentEmployment.position}
                </span>
              </ListGroupItem>

              */}
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Address</span>
              <span style={{ flex: 5, color: 'white' }}>
                {profile.address
                  ? profile.address.fullAddress
                  : addressEmployment || 'Not Available'}
              </span>
            </ListGroupItem>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Email</span>
              <span style={{ flex: 5, color: 'white' }}>{profile.email}</span>
            </ListGroupItem>
            <ListGroupItem style={listStyle}>
              <span style={{ flex: 1 }}>Phone</span>
              <span style={{ flex: 5, color: 'white' }}>
                {profile.phone ? profile.phone : phoneEmployment || 'Not Available'}
              </span>
            </ListGroupItem>
          </ListGroup>
          {(!phoneEmployment || !addressEmployment) ?
            <span style={{ paddingLeft: '10px' }}>If any info are "Not available", please go to the Employees to updated.</span> : false}
        </CardBody>
      </DarkCard>
    </div>
  )
}

export default Info