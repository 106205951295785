export const getEmployees = () => dispatch =>
  dispatch({
    type: 'GET_EMPLOYEES',
    payload: {
      request: {
        url: `/api/employees`
      }
    }
  })

export const submitEmployee = employee => {
  if (employee._id) {
    return updateEmployee(employee)
  } else {
    return createEmployee(employee)
  }
}

export const createEmployee = employee => dispatch =>
  dispatch({
    type: 'CREATE_EMPLOYEE',
    payload: {
      request: {
        url: `/api/employees`,
        method: 'post',
        data: employee
      }
    }
  })

export const getEmployee = employeeId => dispatch =>
  dispatch({
    type: 'GET_EMPLOYEE',
    payload: {
      request: {
        url: `/api/employees/${employeeId}`
      }
    }
  })

export const updateEmployee = employee => dispatch =>
  dispatch({
    type: 'UPDATE_EMPLOYEE',
    payload: {
      request: {
        url: `/api/employees/${employee._id}`,
        method: 'patch',
        data: employee
      }
    }
  })

export const removeEmployee = employeeId => dispatch =>
  dispatch({
    type: 'ARCHIVE_EMPLOYEE',
    payload: {
      request: {
        url: `/api/employees/${employeeId}/archive`,
        method: 'patch'
      }
    }
  })

export const unArchiveEmployee = employee => dispatch =>
  dispatch({
    type: 'UNARCHIVE_EMPLOYEE',
    payload: {
      request: {
        url: `/api/employees/${employee._id}/unarchive`,
        method: 'patch'
      }
    }
  })

export const reinviteEmployee = employeeId => dispatch =>
  dispatch({
    type: 'REINVITE_EMPLOYEE',
    payload: {
      request: {
        url: `/api/employees/${employeeId}/reinvite`,
        method: 'patch'
      }
    }
  })

export const connectSweptAccount = (employeeId, values) => dispatch => {
  let res = dispatch({
    type: 'CONNECT_SWEPT',
    payload: {
      request: {
        url: `/api/employees/${employeeId}/connect-swept`,
        method: 'post',
        data: values
      }
    }
  })
  return res
}

export const disconnectSweptAccount = (employeeId, values) => dispatch => {
  let res = dispatch({
    type: 'DISCONNECT_SWEPT',
    payload: {
      request: {
        url: `/api/employees/${employeeId}/swept-signout`,
        method: 'patch',
        data: values
      }
    }
  })
  return res
}
