import React, { Component } from 'react'
import styled from 'styled-components'

import TimeNode from './TimeNode'

const Metrics = {
  barHeight: 36
}

const BackBar = styled.div`
  overflow: hidden;
  width: 400px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${Metrics.barHeight}px;
  background-color: ${props => props.theme.timebar.background};
  border-radius: ${Metrics.barHeight / 2}px;
`

export default class TimeBar extends Component {
  constructor(props) {
    super(props)

    let currentSelected = this.props.data
      .map(d => d.name)
      .indexOf(this.props.defaultValue && this.props.defaultValue.name)

    this.state = {
      currentSelected
    }
  }

  handler = interval => {
    let index = this.props.data.map(d => d.name).indexOf(interval)
    this.props.onChange(this.props.data[index])

    this.setState({
      currentSelected: index
    })
  }

  render() {
    return (
      <BackBar>
        {this.props.data.map((interval, i) => (
          <TimeNode
            label={interval.name}
            onItemPress={this.handler}
            active={this.state.currentSelected === i}
            key={`TimeNode${i.toString()}`}
          />
        ))}
      </BackBar>
    )
  }
}
