import React from 'react'
import { Button, ButtonGroup } from 'reactstrap'
// import styled from 'styled-components'
import Label from './Label'
import { FiAlignCenter, FiAlignLeft, FiAlignRight } from 'react-icons/fi'

export default ({ label, data, field, onChange, ...rest }) => {
  const { alignment } = data
  const alignments = ['left', 'center', 'right']

  const renderButtons = alignments.map(a => (
    <Button
      key={`alignment-${a}`}
      onClick={() => {
        let newData = {
          ...data
        }
        newData[field] = a
        return onChange(newData)
      }}
      active={alignment === a}
    >
      {a === 'left' && <FiAlignLeft />}
      {a === 'center' && <FiAlignCenter />}
      {a === 'right' && <FiAlignRight />}
    </Button>
  ))

  return (
    <div style={{ marginBottom: 8 }} {...rest}>
      <Label text={label} />
      <ButtonGroup>{renderButtons}</ButtonGroup>
    </div>
  )
}
