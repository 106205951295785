import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Card } from 'reactstrap'

import { BusyButton } from 'components/lib/Button'
import { CheckboxField, InputField, UploadField } from 'components/fields'

class EmailForm extends Component {
  render() {
    const { busy, handleSubmit, clientContact, values, hideAttachments, hideCC, hideSubject } = this.props
    const emailsExist =
      (values && values.emailContact) ||
      (values && values.otherEmails && values.otherEmails.length > 0)

    return (
      <Card body>
        <p>Send To:</p>
        <div style={{ marginLeft: 16 }}>
          {clientContact && (
            <Field
              name="emailContact"
              component={CheckboxField}
              label={`${clientContact.name ? clientContact.name + ': ' : null}${
                clientContact.email
              }`}
            />
          )}
          <Field
            name="otherEmails"
            placeholder="email@provider.com, another.email@provider.com"
            component={InputField}
            type="text"
            label="Add Emails"
          />
          {!hideCC ? (
            <Field name="ccSelf" component={CheckboxField} label="CC Myself" />
          ) : null}
        </div>
        {!hideSubject ? (
          <Field
            name="subject"
            component={InputField}
            type="text"
            label="Subject (optional)"
          />
        ) : null}
        <Field
          name="message"
          component={InputField}
          type="textarea"
          label="Message (optional)"
        />
        {!hideAttachments ? (
          <Field
            name="attachments"
            component={UploadField}
            type="file"
            label="Other Attachments"
            multiple
            persistLocally
          />
        ) : null}
        <BusyButton
          color="primary"
          onClick={handleSubmit}
          busy={busy}
          disabled={!emailsExist}
        >
          Send
        </BusyButton>
        <p><strong>Email will be sent from noreply@getroute.com</strong></p>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  values:
    state.form.Email && state.form.Email.values ? state.form.Email.values : null
})

export default compose(
  reduxForm({
    form: 'Email'
  }),
  connect(
    mapStateToProps,
    null
  )
)(EmailForm)
