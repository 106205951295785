import legal from 'data/legal.js'

export default [
  {
    label: 'Blank',
    elements: []
  },
  {
    label: 'Standard Proposal',
    elements: [
      {
        type: 'letterhead'
      },
      {
        type: 'charges'
      },
      {
        type: 'service_frequency'
      },
      {
        type: 'scope_of_work'
      },
      {
        type: 'areas'
      },
      {
        type: 'rich_text',
        create: (proposal) => {
          let { client, merchant, location } = proposal
          let data = {}

          let markdown = ''

          const customer =
            (client && client.name && client.name.trim()) || 'YOUR CUSTOMER NAME HERE'
          const contractor =
            (merchant && merchant.name && merchant.name.trim()) ||
            'YOUR COMPANY NAME HERE'
          const customerAddress =
            (client &&
              client.address &&
              client.address.fullAddress) ||
            'YOUR CUSTOMER ADDRESS HERE'

          const locationAddress = location?.address?.fullAddress || 'YOUR LOCATION ADDRESS HERE'

          markdown =
            `The Undersigned customer, **${customer}** (hereafter referred to as "CUSTOMER") hereby accepts the proposal of **${contractor}** (hereafter referred to as "CONTRACTOR") for CUSTOMER's premises located at ***${locationAddress}***.\n\n` +
            'Upon the following terms:\n\n' +
            legal +
            '\n\n'

          data.markdown = markdown
          data.headingText = 'Service Agreement'
          data.showHeading = true
          return data
        }
      },
      {
        type: 'signatures'
      }
    ]
  },
  {
    label: 'Scope of Work Doc',
    elements: [
      {
        type: 'letterhead'
      },
      {
        type: 'service_frequency'
      },
      {
        type: 'scope_of_work'
      },
      {
        type: 'areas'
      }
    ]
  }
]
