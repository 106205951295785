import React from 'react'
import styled from 'styled-components'
import IconButton from './IconButton'
import logo from 'assets/img/swept-logo.svg'

const StyledButton = styled(IconButton)`
  &&& {
    width: 100%;
    height: auto;
    flex-flow: column wrap !important;
    justify-content: flex- !important;
    p {
      align-content: left;
      position: relative; // Add this line to establish a new positioning context for the image
    }
    div {
      max-width: wrap-content;
      align-content: center;
    }
    svg,
    img {
      max-width: 70%; // Ensure image does not exceed button size
      max-height: 100%;
      padding: 5px;
      margin-left: 6%;
    }
  }
`

const SweptButton = ({
  className,
  onClick,
  height,
  width,
  isUpdate,
  busy,
  color,
  ...props
}) => (
  <StyledButton
    className={className || `cta btn`}
    onClick={onClick}
    busy={busy}
    size={'md'}
    color={color}
  >
    <div>
      <img src={logo} />
    </div>
  </StyledButton>
)

export default SweptButton
