export const getAllJobs = (startDate, endDate) => dispatch =>
  dispatch({
    type: 'FETCH_ALL_JOBS',
    payload: {
      request: {
        url: `/api/jobs?${startDate ? '&startDate=' + startDate : ''}${
          endDate ? '&endDate=' + endDate : ''
        }`
      }
    }
  })

export const getJobs = () => dispatch =>
  dispatch({
    type: 'FETCH_JOBS',
    payload: {
      request: {
        url: `/api/jobs?type=Job`
      }
    }
  })

export const submitJob = job => {
  if (job._id) {
    return updateJob(job)
  } else {
    return createJob(job)
  }
}

export const createJob = job => dispatch =>
  dispatch({
    type: 'CREATE_JOB',
    payload: {
      request: {
        url: `/api/jobs`,
        method: 'post',
        data: job
      }
    }
  })

export const cloneJob = jobId => dispatch =>
  dispatch({
    type: 'CLONE_JOB',
    payload: {
      request: {
        url: `/api/jobs/${jobId}/clone`,
        method: 'post'
      }
    }
  })

export const getJob = jobId => dispatch =>
  dispatch({
    type: 'FETCH_JOB',
    payload: {
      request: {
        url: `/api/jobs/${jobId}`
      }
    }
  })

export const updateJob = job => dispatch =>
  dispatch({
    type: 'UPDATE_JOB',
    payload: {
      request: {
        url: `/api/jobs/${job._id}`,
        method: 'patch',
        data: job
      }
    }
  })

export const cancelJob = jobId => dispatch =>
  dispatch({
    type: 'CANCEL_JOB',
    payload: {
      request: {
        url: `/api/jobs/${jobId}/cancel`,
        method: 'patch'
      }
    }
  })

export const startJob = jobId => dispatch =>
  dispatch({
    type: 'START_JOB',
    payload: {
      request: {
        url: `/api/jobs/${jobId}/start`,
        method: 'patch'
      }
    }
  })

export const completeJob = jobId => dispatch =>
  dispatch({
    type: 'COMPLETE_JOB',
    payload: {
      request: {
        url: `/api/jobs/${jobId}/finish`,
        method: 'patch'
      }
    }
  })

export const archiveJob = jobId => dispatch =>
  dispatch({
    type: 'ARCHIVE_JOB',
    payload: {
      request: {
        url: `/api/jobs/${jobId}/delete`,
        method: 'patch'
      }
    }
  })

export const unarchiveJob = job => dispatch =>
  dispatch({
    type: 'UNARCHIVE_JOB',
    payload: {
      request: {
        url: `/api/jobs/${job._id}/unarchive`,
        method: 'patch'
      }
    }
  })
