import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button } from 'reactstrap'
import { updateCompany, updateProfile, connectPulseAccount } from 'api'
import CheckedDone from 'components/CheckedDone'
import CustomInfo from 'components/CustomInfo'
import MediaImage from 'components/MediaImage'
import * as acl from '../../../../utils/acl.service'

class CompanyProfile extends Component {
  state = {
    animation: false
  }

  _onSubmit = async values => {
    await this.props.updateCompany(values)
    await this.props.updateProfile()
  }

  _editMerchant = () => {
    this.props.history.push('/dashboard/library/company')
  }

  _connectPulse = async () => {
    await this.props.connectPulseAccount()
  }

  render() {
    const { currentCompany } = this.props
    return (
      <div style={{ maxWidth: 1000, margin: 'auto' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 20,
            paddingRight: 80
          }}
        >
          {acl.isAllowed('billing.update') && (
            <Button
              className={`cta btn ${currentCompany.pulseAccountId ? 'disabled' : ''}`}
              size={'md'}
              onClick={currentCompany.pulseAccountId ? null : this._connectPulse}
              color="danger"
              style={{ marginRight: 10 }}
            >
              {currentCompany?.pulseAccountId ? 'Connected to Pulse' : 'Connect Pulse Account'}
            </Button>
          )}
          {acl.isAllowed('merchant.update') && (
            <Button
              className="cta btn"
              size={'md'}
              onClick={this._editMerchant}
              color="primary"
              style={{ marginRight: 10 }}
            >
              Edit Company
            </Button>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'middle',
            flex: 1,
            paddingLeft: 80,
            paddingRight: 80
          }}
        >
          <Card
            body
            style={{
              marginTop: 20,
              paddingLeft: 50,
              paddingTop: 40,
              paddingRight: 50
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <h3 className="text-dark" style={{ marginBottom: 30 }}>
                  {currentCompany.name}
                </h3>
                {currentCompany.address && currentCompany.address.fullAddress && (
                  <>
                    <h4>Address</h4>
                    <p>{currentCompany.address.fullAddress}</p>
                  </>
                )}
                <h4>Timezone</h4>
                <p>{currentCompany && currentCompany.timezone}</p>
              </div>
              {currentCompany &&
                currentCompany.logo &&
                currentCompany.logo.url && (
                  <MediaImage size={200} src={currentCompany.logo.url} />
                )}
            </div>
            <CustomInfo
              data={currentCompany.customInfo}
              onEdit={data =>
                this._onSubmit({ ...currentCompany, customInfo: data })
              }
            />
          </Card>
          {this.state.animation && (
            <CheckedDone complete={this.finishAnimation} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: state.user.currentCompany
})

export default connect(
  mapStateToProps,
  { updateCompany, updateProfile, connectPulseAccount }
)(CompanyProfile)
